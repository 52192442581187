import React from 'react';

export function NotifyError() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.72164 4.22189C9.01717 -0.073637 15.9823 -0.073637 20.2779 4.22189C24.5734 8.51741 24.5734 15.4826 20.2779 19.7781C15.9823 24.0736 9.01717 24.0736 4.72164 19.7781C0.426119 15.4826 0.426119 8.51741 4.72164 4.22189ZM18.8636 5.6361C15.3492 2.12162 9.65033 2.12162 6.13586 5.6361C2.62138 9.15058 2.62138 14.8494 6.13586 18.3639C9.65033 21.8784 15.3492 21.8784 18.8636 18.3639C22.3781 14.8494 22.3781 9.15058 18.8636 5.6361ZM8.96471 8.46382C9.35526 8.07333 9.98843 8.07339 10.3789 8.46395L12.4998 10.5852L14.6206 8.46395C15.0111 8.07339 15.6442 8.07333 16.0348 8.46382C16.4254 8.85431 16.4254 9.48748 16.0349 9.87804L13.9138 11.9995L16.0349 14.1209C16.4254 14.5115 16.4254 15.1447 16.0348 15.5352C15.6442 15.9257 15.0111 15.9256 14.6206 15.535L12.4998 13.4138L10.3789 15.535C9.98843 15.9256 9.35526 15.9257 8.96471 15.5352C8.57415 15.1447 8.57409 14.5115 8.96458 14.1209L11.0857 11.9995L8.96458 9.87804C8.57409 9.48748 8.57415 8.85431 8.96471 8.46382Z'
        fill='#f45725'
      />
    </svg>
  );
}
