export interface ISlotEmployee {
  active: boolean;
  date: Date | null;
  highlighted?: boolean;
  places?: string;
  userId?: string;
}

export interface IEmployeeScheduleDay {
  day: string;
  date: string;
  time: string;
  timeEnd: string;
  start: Date | null;
  end: Date | null;
}

export interface IEmployeeScheduleQuery {
  date: string;
  timeend: string;
  timestart: string;
}
const hourSlot = (active: boolean): ISlotEmployee => ({
  active,
  date: null
});
const day = [
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false),
  hourSlot(false)
];

export const hours = ['nan', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

const initialEmloyeeSchedule: ISlotEmployee[][] = [[...day], [...day], [...day], [...day], [...day], [...day], [...day]];

export default initialEmloyeeSchedule;
