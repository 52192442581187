export const PaymentIcon25 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.875 9.56445L18.4301 15.1196L23.9852 9.56445'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M18.5303 15.0169V1.125'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M10.0625 6.75H3.03125V30.6562H33.9688V6.75H26.9375'
        stroke='currentColor'
      />
      <path
        d='M28.3438 25.0312H17.0938'
        stroke='currentColor'
      />
      <path
        d='M14.2812 25.0312H8.65625'
        stroke='currentColor'
      />
    </svg>
  );
};
