import React from 'react';

export function UserDropdown() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.49993 5.07578C6.78865 5.07578 6.21205 5.65238 6.21205 6.36366C6.21205 7.07493 6.78865 7.65153 7.49993 7.65153C8.2112 7.65153 8.78781 7.07493 8.78781 6.36366C8.78781 5.65238 8.2112 5.07578 7.49993 5.07578ZM4.84841 6.36366C4.84841 4.89926 6.03554 3.71214 7.49993 3.71214C8.96432 3.71214 10.1514 4.89926 10.1514 6.36366C10.1514 7.82805 8.96432 9.01517 7.49993 9.01517C6.03554 9.01517 4.84841 7.82805 4.84841 6.36366Z'
        fill='#496FFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 0C5.42912 0 3.55319 0.840213 2.1967 2.1967C0.840213 3.55319 0 5.42912 0 7.5C0 9.57088 0.840213 11.4468 2.1967 12.8033C3.55319 14.1598 5.42912 15 7.5 15C9.57088 15 11.4468 14.1598 12.8033 12.8033C14.1598 11.4468 15 9.57088 15 7.5C15 5.42912 14.1598 3.55319 12.8033 2.1967C11.4468 0.840213 9.57088 0 7.5 0ZM10.82 12.6617C9.90541 11.9431 8.75313 11.5152 7.49999 11.5152C6.24686 11.5152 5.09458 11.943 4.17999 12.6617C5.13712 13.2787 6.27646 13.6364 7.5 13.6364C8.72354 13.6364 9.86287 13.2787 10.82 12.6617ZM3.16094 3.16094C4.27215 2.04973 5.8053 1.36364 7.5 1.36364C9.1947 1.36364 10.7279 2.04973 11.8391 3.16094C12.9503 4.27215 13.6364 5.8053 13.6364 7.5C13.6364 9.16561 12.9736 10.6752 11.8959 11.7815C10.716 10.7661 9.17923 10.1515 7.49999 10.1515C5.82076 10.1515 4.28403 10.7661 3.10408 11.7815C2.02637 10.6752 1.36364 9.1656 1.36364 7.5C1.36364 5.8053 2.04973 4.27215 3.16094 3.16094ZM4.84841 6.36366C4.84841 4.89926 6.03554 3.71214 7.49993 3.71214C8.96432 3.71214 10.1514 4.89926 10.1514 6.36366C10.1514 7.82805 8.96432 9.01517 7.49993 9.01517C6.03554 9.01517 4.84841 7.82805 4.84841 6.36366ZM7.49993 5.07578C6.78865 5.07578 6.21205 5.65238 6.21205 6.36366C6.21205 7.07493 6.78865 7.65153 7.49993 7.65153C8.2112 7.65153 8.78781 7.07493 8.78781 6.36366C8.78781 5.65238 8.2112 5.07578 7.49993 5.07578Z'
        fill='#496FFF'
      />
    </svg>
  );
}
