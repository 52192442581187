import { useFormik } from 'formik';
import { FC, SyntheticEvent } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import { NewInput } from '../../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { useApplyPromocode } from '../../../../../../store/redux/tarif/hooks/useTarifMutation';
import { IconNew, EIcon } from '../../../../../icons/medium-new-icons/icon';
import { ApplyPromocodeModalForm, ApplyPromocodeModalFormBtn, ApplyPromocodeModalWrapper, ModalHeader } from './ApplyPromocodeModal.styled';

interface IProps {
  closeModal: () => void;
}
const ApplyPromocodeModal: FC<IProps> = ({ closeModal }) => {
  const { mutateAsync: applyPromocode } = useApplyPromocode();
  const { t } = useTranslation();

  const handleApplyPromocode = async (e: SyntheticEvent) => {
    e.preventDefault();
    const res = await applyPromocode(formik.values.promocode);
    if (res.status === 'ok') {
      closeModal();
      toast.success('Промокод применен');
    } else {
      toast.error(res.description || 'Ошибка при применении промокода');
    }
  };
  const initialValues = {
    promocode: ''
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {}
  });
  return (
    <ApplyPromocodeModalWrapper $column>
      <ModalHeader
        $align='center'
        $gap='16px'
      >
        <NewButton
          onClick={closeModal}
          typeBtn='close'
        >
          <IconNew name={EIcon.close} />
        </NewButton>
        <NewText
          $tag={TextType.H3}
          $customSize={TextCustomType.T28}
          color={'title'}
        >
          {t('Применить промокод')}
        </NewText>
      </ModalHeader>
      <ApplyPromocodeModalForm onSubmit={handleApplyPromocode}>
        <NewInput
          name='promocode'
          value={formik.values.promocode}
          onChange={formik.handleChange}
          label='Промокод'
        />
        <ApplyPromocodeModalFormBtn
          typeBtn='black'
          disabled={!formik.values.promocode || formik.values.promocode === ''}
        >
          {t('Применить')}
        </ApplyPromocodeModalFormBtn>
      </ApplyPromocodeModalForm>
    </ApplyPromocodeModalWrapper>
  );
};

export default ApplyPromocodeModal;
