export function ShareOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.9964 3.72537C10.8807 3.8041 9.99998 4.73418 9.99998 5.86997L9.99898 7.98704L9.97855 7.99194C5.44626 8.93093 2.09998 12.9471 2.09998 17.6854C2.09998 18.0297 2.11758 18.3727 2.15241 18.713C2.23543 19.5242 3.26807 19.8157 3.76299 19.1676L3.9498 18.9312C5.40912 17.1447 7.48682 15.9728 9.78549 15.6658L9.99898 15.64L9.99998 18.1344C9.99998 18.615 10.1612 19.082 10.4578 19.4604C11.1903 20.395 12.5417 20.5588 13.4763 19.8263L21.2999 13.6942C21.4362 13.5874 21.559 13.4646 21.6658 13.3283C22.3983 12.3938 22.2345 11.0424 21.2999 10.3099L13.4763 4.1778C13.0978 3.88117 12.6308 3.71997 12.15 3.71997L11.9964 3.72537ZM12.15 5.51997C12.2283 5.51997 12.3043 5.54621 12.3659 5.5945L20.1896 11.7266C20.3417 11.8458 20.3684 12.0658 20.2491 12.218C20.2317 12.2401 20.2117 12.2601 20.1896 12.2775L12.3659 18.4096C12.2137 18.5288 11.9937 18.5022 11.8745 18.35C11.8262 18.2884 11.8 18.2124 11.8 18.1341L11.799 14.6868C11.7988 14.184 11.3869 13.7789 10.8842 13.7872L10.651 13.791L10.3088 13.8075C8.03472 13.9527 5.91208 14.7947 4.19027 16.1734L4.01598 16.317L4.03977 16.1817C4.68259 12.7662 7.4747 10.0763 11.0081 9.64542C11.4596 9.59037 11.7991 9.20712 11.7992 8.75229L11.8 5.87022C11.8 5.67667 11.9567 5.51997 12.15 5.51997Z'
        fill='currentColor'
      />
    </svg>
  );
}
