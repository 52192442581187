import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { chatAPI, ChatMessageType, IChatListData, IChatMessageData } from './chat-api';
import { messagesChatList, messagesChatMessage, messagesRecords, statusChanged, StatusType } from './chat-slice';
import { Dispatch } from '@reduxjs/toolkit';

let _newMessageHandler: ((messages: ChatMessageType) => void) | null = null;
const newMessageHandlerCreator = (dispatch: Dispatch) => {
  if (_newMessageHandler === null) {
    _newMessageHandler = (messages) => {
      messages.type === 'chat_list'
        ? dispatch(messagesChatList(messages.data as IChatListData[]) as any)
        : messages.type === 'chat_messages'
        ? dispatch(messagesChatMessage(messages.data as IChatMessageData[]) as any)
        : messages.type === 'records'
        ? dispatch(messagesRecords(messages.data as IChatMessageData[]) as any)
        : [];
    };
  }

  return _newMessageHandler;
};

let _statusChangedHandler: ((status: StatusType) => void) | null = null;

const statusChangedHandlerCreator = (dispatch: Dispatch) => {
  if (_statusChangedHandler === null) {
    _statusChangedHandler = (status) => {
      dispatch(statusChanged(status) as any);
    };
  }
  return _statusChangedHandler;
};
export const startMessagesListening = createAsyncThunk('SN/chat/startMessagesListening', async (userFio: string | undefined, { dispatch }) => {
  chatAPI.start(userFio || '');
  chatAPI.subscribe('messages-received', newMessageHandlerCreator(dispatch));
  chatAPI.subscribe('status-changed', statusChangedHandlerCreator(dispatch));
});

export const stopMessagesListening = createAsyncThunk('SN/chat/stopMessagesListening', async (id: string | undefined, { dispatch }) => {
  chatAPI.unsubscribe('messages-received', newMessageHandlerCreator(dispatch));
  chatAPI.unsubscribe('status-changed', statusChangedHandlerCreator(dispatch));
  chatAPI.stop();
});

export const sendMessage = createAsyncThunk('SN/chat/sendMessage', async (message: string) => {
  chatAPI.sendMessage(message);
});

export const setChatRoomId = createAction<string>('webSocket/setChatRoomId');

export const sendGetRecordsRequest = createAsyncThunk(
  'SN/chat/sendGetRecordsRequest',
  async ({ filialName, filialId, userRole }: { filialName: string; filialId: string; userRole: string }) => {
    try {
      if (userRole === 'admin' && filialId) {
        const requestData = {
          type: 'get_records',
          data: {
            filialId: filialId
          }
        };
        sendMessage(JSON.stringify(requestData));
      } else {
        console.log('У пользователя нет необходимой роли для отправки запроса get_records.');
      }
    } catch (error) {
      console.error('Ошибка при получении данных пользователя:', error);
      throw error;
    }
  }
);
