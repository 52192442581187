import dayjs from 'dayjs';
import { ISelectItem } from '../pages/EmployeesPage/ui/employee-timetable/ui/EmployeeTimeTablePage';
import { IMonth } from '../store/redux/calendar/calendar.interface';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

export const fullHd = 48;
export const laptop = 42;
const events = [{ title: 'fdsf', start: getDate('YEAR-MONTH-03') }];
function getDate(dayString: string) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = '0' + month;
  }

  return dayString.replace('YEAR', year).replace('MONTH', month);
}

export default events;

export const range = (keyCount: any) => Array.from({ length: keyCount }, (_, i) => i);

export const timeStringToNumber = (timeString: string) => {
  const [hours, minutes] = timeString.split(':').map(Number);

  return hours;
};
export const calculateEventWidth = (startTime: number, endTime: number) => {
  const windowWidth = window.innerWidth;
  const minutesDifference = endTime - startTime;
  const baseWidth = windowWidth > 1500 ? fullHd : laptop;
  const additionalWidth = Math.max(0, minutesDifference) * (baseWidth + 11) - (baseWidth + 11);

  return minutesDifference === 1 ? baseWidth : baseWidth + additionalWidth;
};

export const addDateBy = (date: Date, count: number) => {
  const d = new Date(date);

  return new Date(d.setDate(d.getDate() + count));
};

export const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit' };
  const date = new Date(dateString);

  return date.toLocaleDateString(undefined, options).replace(/\//g, '.');
};

export const optionsDateInModal: any = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
};

export const generateDaysArray = (startDate: Date, endDate: Date) => {
  const dates = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    dates.push(currentDate.toDateString());
    currentDate = addDateBy(currentDate, 1);
  }

  return dates;
};

export const getDaysAsISOStrings = (currentDate: Date): ISelectItem[] => {
  const currentYear = currentDate.getUTCFullYear();
  const currentMonth = currentDate.getUTCMonth();

  const days: ISelectItem[] = [];
  const daysInMonth = dayjs(new Date(currentYear, currentMonth, 1)).daysInMonth();

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(Date.UTC(currentYear, currentMonth, day));
    days.push({
      value: date.toISOString(),
      label: dayjs(date).locale('ru').format('D MMMM YYYY')
    });
  }

  return days;
};

// Функция для получения массива дат начала месяцев в формате ISO
export const getMonthsAsISOStrings = (year: number): ISelectItem[] => {
  const currentYear = year ? year : dayjs().year();
  const months: IMonth[] = [];
  for (let month = 0; month < 12; month++) {
    const date = new Date(Date.UTC(currentYear, month, 1));
    months.push({ value: date.toISOString(), label: dayjs(date).locale('ru').format('MMMM YYYY') });
  }
  return months;
};

export interface IWeek {
  value: string;
  label: string;
  weekNum?: number;
}

// Функция для получения списка недель текущего года
export const getWeeksOfYear = (year: number): IWeek[] => {
  const firstDayOfYear = dayjs(new Date(year, 0, 1)).startOf('isoWeek');

  const weeks: IWeek[] = [];
  let currentWeekStart = firstDayOfYear;

  while (currentWeekStart.year() <= year) {
    const currentWeekEnd = currentWeekStart.endOf('isoWeek');
    if (currentWeekStart.year() === year || currentWeekEnd.year() === year) {
      weeks.push({
        value: currentWeekStart.toISOString(),
        label: `${currentWeekStart.format('D MMMM')} - ${currentWeekEnd.format('D MMMM')}`,
        weekNum: currentWeekStart.isoWeek()
      });
    }
    currentWeekStart = currentWeekStart.add(1, 'week');
  }

  return weeks;
};
