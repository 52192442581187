import styled from 'styled-components';

import { FlexWithAlign } from '../../../../../../utils/styleUtils';

export const ModalWrapper = styled(FlexWithAlign)`
  padding: 24px;
  width: 100%;
`;

export const ModalContent = styled(FlexWithAlign)`
  width: 100%;
`;
