import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { Form } from './UpdatePhoneForm.styled';

import { EndLine, FlexWithAlign } from '../../../../../utils/styleUtils';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { Input } from '../../../../../shared/ui';

interface IProps {
  modalPayload: any;
}

const UpdatePhoneForm: React.FC<IProps> = ({ modalPayload }) => {
  const { profileUser } = useTypedSelector((state1) => state1.user);
  const { updateUserAsync, closeModal } = useActions();
  const { t } = useTranslation();

  const initialValues = {
    phone: ''
  };

  const formik = useFormik({
    initialValues: modalPayload ? modalPayload : initialValues,
    onSubmit: () => {}
  });

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    updateUserAsync({ ...formik.values, id: profileUser.id });
    closeModal();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FlexWithAlign $column>
        <Input
          label={t('Телефон')}
          value={formik.values.phone}
          onChange={formik.handleChange}
          type={'tel'}
          name={'phone'}
        />
        <EndLine />
        <FlexWithAlign $align={'center'}>
          <CommonButton
            typeBtn={'flat'}
            className={'unset'}
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            <span>{t('Отменить')}</span>
          </CommonButton>
          <CommonButton
            typeBtn={'accent'}
            className={'h50'}
            disabled={!formik.isValid}
          >
            <span>{t('Сохранить')}</span>
          </CommonButton>
        </FlexWithAlign>
      </FlexWithAlign>
    </Form>
  );
};

export default UpdatePhoneForm;
