import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { FULFILLED, PENDING, REJECTED, STATE } from '../../../utils/state';
import { ISubproduct, ISubproductsAll } from './subproducts.interface';
import { SubproductsService } from '../../../shared/api/model/service/subproducts.service';

export const setSubproducts = createAction<ISubproduct[]>('subproducts/setSubproducts');
export const setSubproductsAll = createAction<ISubproductsAll[]>('subproducts/setSubproductsAll');
export const setSubproductsArray = createAction<ISubproduct[]>('subproducts/setSubproductsArray');
export const setState = createAction<STATE>('subproducts/setState');

export const fetchSubproductsAsync = createAsyncThunk(
  'subproducts/fetchSubproductsAsync',
  async (id: string, { dispatch }: { dispatch: any }) => {
    try {
      dispatch(setState(PENDING));

      const response = await SubproductsService.fetchSubproducts(id);

      if (response.data) {
        const subproducts = response.data.subproduct || [];
        dispatch(setSubproducts(subproducts));
        dispatch(setState(FULFILLED));
        return subproducts;
      } else {
        dispatch(setState(REJECTED));
        console.error('Invalid response format');
        return [];
      }
    } catch (e) {
      dispatch(setState(REJECTED));
      console.error(e);
      throw e;
    }
  }
);

export const fetchSubproductsAllAsync = createAsyncThunk('subproducts/fetchSubproductsAllAsync', async (ids: string[], { dispatch }) => {
  try {
    dispatch(setState(PENDING));

    const subProductsArr: ISubproductsAll[] = await SubproductsService.fetchSubproductsAll(ids);

    dispatch(setSubproductsAll(subProductsArr));
    dispatch(setSubproductsArray(subProductsArr.flatMap((item) => item.data)));
    dispatch(setState(FULFILLED));

    return subProductsArr; // Возвращаем данные из функции
  } catch (e) {
    dispatch(setState(REJECTED));
    console.error(e);
    throw e; // Пробрасываем ошибку, если произошла
  }
});
