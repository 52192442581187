import React from 'react';

export function CompanyGray() {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M31 29C31 29.2652 30.8946 29.5196 30.7071 29.7071C30.5196 29.8946 30.2652 30 30 30H10C9.73478 30 9.48043 29.8946 9.29289 29.7071C9.10536 29.5196 9 29.2652 9 29C9.00105 28.8255 9.04776 28.6543 9.13547 28.5034C9.22319 28.3525 9.34886 28.2273 9.5 28.14V12.5C9.5 11.837 9.76339 11.2011 10.2322 10.7322C10.7011 10.2634 11.337 10 12 10H16.75C17.413 10 18.0489 10.2634 18.5178 10.7322C18.9866 11.2011 19.25 11.837 19.25 12.5V28H20.75V14.7C20.75 14.5143 20.8238 14.3363 20.955 14.205C21.0863 14.0737 21.2643 14 21.45 14H28C28.663 14 29.2989 14.2634 29.7678 14.7322C30.2366 15.2011 30.5 15.837 30.5 16.5V28.14C30.6511 28.2273 30.7768 28.3525 30.8645 28.5034C30.9522 28.6543 30.9989 28.8255 31 29Z'
        fill='currentColor'
      />
    </svg>
  );
}
