import React, { FC, memo } from 'react';

import { AvatarRounded, AvatarSize, IAvatar } from './Avatar.props';

import { IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import { firstTwoLetters, getColorForSymbol } from '../../../../pages/MessengerPage/lib/messenger.helper';
import { CircleWrapper } from '../../../../styles/circles.styled';
import { cn } from '../../../lib';

export const Avatar: FC<IAvatar> = memo((props: IAvatar) => {
  const {
    avatar,
    title,
    svg,
    onMouseEnter,
    onMouseLeave,
    children,
    randomBg,
    onClick,
    background,
    size = AvatarSize.XS,
    rounded = AvatarRounded.FULL,
    className
  } = props;

  return (
    <CircleWrapper
      size={size}
      className={cn(children && 'hovered', 'avatar', className && `${className}`)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      $background={background}
      style={randomBg ? { background: `${getColorForSymbol(firstTwoLetters(title))}` } : undefined}
      $rounded={rounded}
    >
      {avatar ? (
        <img
          src={avatar}
          alt={title}
        />
      ) : svg ? (
        <IconInstance name={svg} />
      ) : (
        firstTwoLetters(title)
      )}
      {children}
    </CircleWrapper>
  );
});

Avatar.displayName = 'Avatar';
