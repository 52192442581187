import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { fetchMonthDays, getMonday, getMonthFirstAndLastDays } from './calendar.actions';
import { CalendarState, IChooseView, IMonth } from './calendar.interface';

import { addDateBy, generateDaysArray, IWeek } from '../../../utils/date-events';
import { getRouteCalendarEmployee } from '../../../shared/const';

const initialState: CalendarState = {
  activeDate: new Date().toString(),
  financeActiveDate: new Date().toString(),
  chosenWeek: generateDaysArray(getMonday(new Date()), addDateBy(getMonday(new Date()), 6)),
  chosenMonth: generateDaysArray(getMonthFirstAndLastDays(new Date())[0], getMonthFirstAndLastDays(new Date())[1]),
  trigger: false,
  currView: { value: 'month', label: 'Месяц' },
  currViewTimetable: { value: 'month', label: 'Месяц' },
  currRecordingView: { value: getRouteCalendarEmployee(), label: 'Все сотрудники', onlyMe: false },
  chosenDates: generateDaysArray(getMonthFirstAndLastDays(new Date())[0], getMonthFirstAndLastDays(new Date())[1]),
  chosenWeekList: [],
  disabledBtnPrev: false,
  disabledBtnNext: false,
  startInterval: new Date().toString(),
  endInterval: new Date().toString()
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setActiveDate: (state, action: PayloadAction<string>) => {
      state.activeDate = action.payload;
    },
    setTrigger: (state, action: PayloadAction<boolean>) => {
      state.trigger = action.payload;
    },
    setWeek: (state, action: PayloadAction<string[]>) => {
      state.chosenWeek = action.payload;
    },
    setFinanceActiveDate: (state, action: PayloadAction<string>) => {
      state.financeActiveDate = action.payload;
    },
    setNextDay: (state) => {
      state.activeDate = dayjs(state.activeDate).add(1, 'day').toISOString();
    },
    setPrevDay: (state) => {
      state.activeDate = dayjs(state.activeDate).subtract(1, 'day').toISOString();
    },
    setFinanceNextDay: (state) => {
      state.financeActiveDate = dayjs(state.financeActiveDate).add(1, 'day').toISOString();
    },
    setFinancePrevDay: (state) => {
      state.financeActiveDate = dayjs(state.financeActiveDate).subtract(1, 'day').toISOString();
    },
    setMonthDays: (state, action: PayloadAction<string[]>) => {
      state.chosenMonth = action.payload;
    },
    setCurrView: (state, action: PayloadAction<IMonth>) => {
      state.currView = action.payload;
    },
    setCurrViewTimetable: (state, action: PayloadAction<IMonth>) => {
      state.currViewTimetable = action.payload;
    },
    setChosenDates: (state, action: PayloadAction<string[]>) => {
      state.chosenDates = action.payload;
    },
    updateChosenWeekList: (state, action: PayloadAction<IWeek>) => {
      const { value } = action.payload;
      if (state.chosenWeekList.find((week) => week.value === value)) {
        state.chosenWeekList = [...state.chosenWeekList.filter((week) => week.value !== value)];
      } else {
        state.chosenWeekList = [...state.chosenWeekList, action.payload];
      }
    },
    clearChosenWeekList: (state) => {
      state.chosenWeekList = [];
    },
    setDisabledBtnPrev: (state, action: PayloadAction<boolean>) => {
      state.disabledBtnPrev = action.payload;
    },
    setDisabledBtnNext: (state, action: PayloadAction<boolean>) => {
      state.disabledBtnNext = action.payload;
    },
    setStartInterval: (state, action: PayloadAction<string>) => {
      state.startInterval = action.payload;
    },
    setEndInterval: (state, action: PayloadAction<string>) => {
      state.endInterval = action.payload;
    },
    setCurrRecordingView: (state, action: PayloadAction<IChooseView>) => {
      state.currRecordingView = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonthDays.fulfilled, (state, action) => {
      state.chosenMonth = action.payload;
    });
  }
});

export const { reducer, actions } = calendarSlice;
export const { setActiveDate, setWeek, setFinanceActiveDate, setNextDay, setPrevDay, setFinanceNextDay, setFinancePrevDay, setMonthDays } =
  actions;
