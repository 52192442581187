import { styled } from 'styled-components';
import themelight from '../../../../app/providers/ThemeProvider/ui/theme';

export const CategoriesWrapper = styled.div``;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 24px;
    left: 22.5px;
    bottom: 24px;
    width: 1px;
    background-color: ${themelight.newColor.base08};
  }
`;

export const HeadItem = styled.li`
  display: flex;
  align-items: center;
  gap: 15.5px;
  padding: 12px 10.5px;
  cursor: pointer;
  transition: all 0.3s linear;
  &.active {
    span {
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

export const ArrowWrap = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid ${themelight.newColor.ghost};
  color: ${themelight.newColor.primary};
  background-color: ${themelight.newColor.base01};
  z-index: 1;
`;

export const Item = styled.li`
  position: relative;
  padding: 12px 10.5px 12px 50px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s linear;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 22.5px;
    height: 1px;
    width: 24px;
    background-color: ${themelight.newColor.base08};
  }
  &.active {
    span {
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

export const CountLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${themelight.newColor.bg};
  font-size: 12px;
  color: ${themelight.newColor.secondary};
  // transition: all 0.3s linear;
`;
