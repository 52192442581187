import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { WrapperCalendar } from './CommonCalendar.tsyled';

import { useActions } from '../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../shared/lib/hooks/useMatchMedia';
import { useTypedSelector } from '../../../shared/lib/hooks/useTypedSelector';
import { fetchMonthDays, getMonday } from '../../../store/redux/calendar/calendar.actions';
import { addDateBy, generateDaysArray } from '../../../utils/date-events';
import { EIcon, IconNew as IconInstance } from '../../icons/medium-new-icons/icon';

import { Dispatch, SetStateAction } from 'react';

interface IProps {
  currentView?: 'resourceTimeGridDay' | 'resourceTimeGridWeek' | 'resourceDayGridMonth' | 'listWeek';
  sliderRef?: any;
  setPrev?: any;
  setNext?: any;
  setChosen?: any;
  setOldSlide?: Dispatch<SetStateAction<number>>;
  setActiveSlide2?: Dispatch<SetStateAction<number>>;
  showNeighboringMonth?: boolean;
}

const CommonCalendar: React.FC<IProps> = (props) => {
  const { currentView, setActiveSlide2, setOldSlide, setNext, setPrev, sliderRef, setChosen, showNeighboringMonth } = props;
  const { setActiveDate, setTrigger, setWeek, setMonthDays } = useActions();
  const { activeDate, chosenWeek, chosenMonth } = useTypedSelector((state) => state.calendar);
  const { t } = useTranslation();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const isDate = (date: any): date is Date => date instanceof Date;
  const generateDateArray = (startDate: Date, endDate: Date) => {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(currentDate.toDateString());
      currentDate = addDateBy(currentDate, 1);
    }

    return dates;
  };

  const days = generateDateArray(getMonday(new Date(activeDate)), addDateBy(getMonday(new Date(activeDate)), 6));
  const currentDate = isDate(activeDate) ? activeDate : new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const month = Array.from({ length: 6 }, (_, i) => addDateBy(firstDayOfMonth, i).toDateString());
  const changeDate = (value: any) => {
    const newActiveDate = dayjs(value).toISOString();
    setActiveDate(newActiveDate);
    setWeek(generateDaysArray(getMonday(new Date(newActiveDate)), addDateBy(getMonday(new Date(newActiveDate)), 6)));

    if (isMobile) {
      setTrigger(true);
      setTimeout(() => {
        setTrigger(false);
      }, 100);
    }
  };
  //@ts-ignore
  const onActiveStartDateChangeHandler = ({ activeStartDate, value, view }: any) => {
    const newActiveDate = dayjs(activeStartDate).toISOString();
    setActiveDate(newActiveDate);
    setWeek(generateDaysArray(getMonday(new Date(newActiveDate)), addDateBy(getMonday(new Date(newActiveDate)), 6)));
    fetchMonthDays();
  };

  const tileClassNameFunction = ({ date }: { date: Date; view: string }) => {
    if (currentView === 'resourceTimeGridDay') {
      return undefined;
    } else if (currentView === 'resourceTimeGridWeek' || currentView === 'listWeek') {
      const currentDate = dayjs(date);

      const isCurrentDateSelected = days.some(
        (selectedDate) => dayjs(date).format('YYYY-DD-MM') === dayjs(selectedDate).format('YYYY-DD-MM')
      );

      if (isCurrentDateSelected) {
        const firstDayIndex = dayjs(days[0]).day();
        const lastDayIndex = dayjs(days[days.length - 1]).day();
        const currentDateIndex = currentDate.day();

        if (currentDateIndex === firstDayIndex) {
          return 'react-calendar__tile--active first-half';
        } else if (currentDateIndex === lastDayIndex) {
          return 'react-calendar__tile--active second-half';
        } else {
          return 'selected';
        }
      }
    } else if (currentView === 'resourceDayGridMonth') {
      return month.some((selectedDate) => {
        const selectedDateObj = new Date(selectedDate);

        return (
          date.getFullYear() === selectedDateObj.getFullYear() &&
          date.getMonth() === selectedDateObj.getMonth() &&
          date.getDate() === selectedDateObj.getDate()
        );
      })
        ? 'month'
        : undefined;
    }
  };

  const calculateValue = (): any => {
    if (currentView === 'resourceTimeGridDay') {
      return new Date(activeDate);
    } else if (currentView === 'resourceTimeGridWeek' || currentView === 'listWeek') {
      return days;
    } else if (currentView === 'resourceDayGridMonth') {
      return null;
    }

    return undefined;
  };

  return (
    <WrapperCalendar>
      <Calendar
        nextLabel={<IconInstance name={EIcon.arrownextnew} />}
        prevLabel={<IconInstance name={EIcon.arrowbacknew} />}
        maxDetail={currentView === 'resourceDayGridMonth' ? 'year' : 'month'}
        prevAriaLabel={t('Предыдущий месяц')}
        nextAriaLabel={t('Следующий месяц')}
        navigationLabel={({ date, label, locale, view }) =>
          view === 'month' ? dayjs(date).locale('ru').format('MMMM YYYY') : view === 'year' && dayjs(date).format('YYYY')
        }
        onClickMonth={(val) => changeDate(val)}
        defaultView={currentView === 'resourceDayGridMonth' ? 'year' : 'month'}
        tileClassName={tileClassNameFunction}
        showNeighboringMonth={showNeighboringMonth}
        onClickDay={(val) => changeDate(val)}
        value={calculateValue()}
        onActiveStartDateChange={onActiveStartDateChangeHandler}
      />
    </WrapperCalendar>
  );
};

export default CommonCalendar;
