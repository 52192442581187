export const SettingUpService = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2547_12596)'>
        <g clipPath='url(#clip1_2547_12596)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.625 12.1875C3.55698 12.1875 4.3125 12.943 4.3125 13.875C4.3125 14.807 3.55698 15.5625 2.625 15.5625C1.69302 15.5625 0.9375 14.807 0.9375 13.875C0.9375 12.943 1.69302 12.1875 2.625 12.1875ZM2.625 13.3125C2.31434 13.3125 2.0625 13.5643 2.0625 13.875C2.0625 14.1857 2.31434 14.4375 2.625 14.4375C2.93566 14.4375 3.1875 14.1857 3.1875 13.875C3.1875 13.5643 2.93566 13.3125 2.625 13.3125ZM16.5 13.3125C16.8107 13.3125 17.0625 13.5643 17.0625 13.875C17.0625 14.1598 16.8509 14.3951 16.5763 14.4324L16.5 14.4375H6C5.68934 14.4375 5.4375 14.1857 5.4375 13.875C5.4375 13.5902 5.64912 13.3549 5.92367 13.3176L6 13.3125H16.5ZM2.625 7.6875C3.55698 7.6875 4.3125 8.44302 4.3125 9.375C4.3125 10.307 3.55698 11.0625 2.625 11.0625C1.69302 11.0625 0.9375 10.307 0.9375 9.375C0.9375 8.44302 1.69302 7.6875 2.625 7.6875ZM2.625 8.8125C2.31434 8.8125 2.0625 9.06434 2.0625 9.375C2.0625 9.68566 2.31434 9.9375 2.625 9.9375C2.93566 9.9375 3.1875 9.68566 3.1875 9.375C3.1875 9.06434 2.93566 8.8125 2.625 8.8125ZM16.5 8.8125C16.8107 8.8125 17.0625 9.06434 17.0625 9.375C17.0625 9.65977 16.8509 9.89512 16.5763 9.93237L16.5 9.9375H6C5.68934 9.9375 5.4375 9.68566 5.4375 9.375C5.4375 9.09023 5.64912 8.85488 5.92367 8.81763L6 8.8125H16.5ZM2.625 3.1875C3.55698 3.1875 4.3125 3.94302 4.3125 4.875C4.3125 5.80698 3.55698 6.5625 2.625 6.5625C1.69302 6.5625 0.9375 5.80698 0.9375 4.875C0.9375 3.94302 1.69302 3.1875 2.625 3.1875ZM2.625 4.3125C2.31434 4.3125 2.0625 4.56434 2.0625 4.875C2.0625 5.18566 2.31434 5.4375 2.625 5.4375C2.93566 5.4375 3.1875 5.18566 3.1875 4.875C3.1875 4.56434 2.93566 4.3125 2.625 4.3125ZM16.5 4.3125C16.8107 4.3125 17.0625 4.56434 17.0625 4.875C17.0625 5.15977 16.8509 5.39512 16.5763 5.43236L16.5 5.4375H6C5.68934 5.4375 5.4375 5.18566 5.4375 4.875C5.4375 4.59023 5.64912 4.35488 5.92367 4.31764L6 4.3125H16.5Z'
            fill='currentColor'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2547_12596'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_2547_12596'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
