export function ExternalLinkOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.14628 3.63424C5.90843 3.22664 6.6581 3.09998 8.22775 3.09998H10.1C10.597 3.09998 11 3.50292 11 3.99998C11 4.49703 10.597 4.89998 10.1 4.89998H8.22775C6.74108 4.89998 6.35714 5.02791 5.99516 5.2215C5.65931 5.40112 5.40112 5.65931 5.2215 5.99516C5.02791 6.35714 4.89998 6.74108 4.89998 8.22775V15.7722C4.89998 17.2589 5.02791 17.6428 5.2215 18.0048C5.40112 18.3406 5.65931 18.5988 5.99516 18.7784C6.35714 18.972 6.74108 19.1 8.22775 19.1H15.7722C17.2589 19.1 17.6428 18.972 18.0048 18.7784C18.3406 18.5988 18.5988 18.3406 18.7784 18.0048C18.972 17.6428 19.1 17.2589 19.1 15.7722V13.9C19.1 13.4029 19.5029 13 20 13C20.497 13 20.9 13.4029 20.9 13.9V15.7722C20.9 17.3419 20.7733 18.0915 20.3657 18.8537C20.0183 19.5032 19.5032 20.0183 18.8537 20.3657C18.0915 20.7733 17.3419 20.9 15.7722 20.9H8.22775C6.6581 20.9 5.90843 20.7733 5.14628 20.3657C4.49675 20.0183 3.98161 19.5032 3.63424 18.8537C3.22664 18.0915 3.09998 17.3419 3.09998 15.7722V8.22775C3.09998 6.6581 3.22664 5.90843 3.63424 5.14628C3.98161 4.49675 4.49675 3.98161 5.14628 3.63424Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 3.99998C14 3.50292 14.4029 3.09998 14.9 3.09998H20C20.4971 3.09998 20.9 3.50292 20.9 3.99998V9.09998C20.9 9.59703 20.4971 9.99998 20 9.99998C19.5029 9.99998 19.1 9.59703 19.1 9.09998V6.17277L12.5364 12.7364C12.1849 13.0878 11.6151 13.0878 11.2636 12.7364C10.9121 12.3849 10.9121 11.8151 11.2636 11.4636L17.8272 4.89998H14.9C14.4029 4.89998 14 4.49703 14 3.99998Z'
        fill='currentColor'
      />
    </svg>
  );
}
