import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import AnimateHeight, { Height } from './AuthHeight';

interface IProps {
  children: ReactNode;
}
const DinamycHeight: FC<IProps> = ({ children, ...props }) => {
  const [height, setHeight] = useState<Height>('auto');
  const contentDiv = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const element = contentDiv.current;

    if (element) {
      const resizeObserver = new ResizeObserver(() => {
        setHeight(element.clientHeight);
      });

      resizeObserver.observe(element);

      return () => resizeObserver.disconnect();
    }
  }, [contentDiv.current]);
  return (
    <AnimateHeight
      {...props}
      height={height}
      duration={200}
      contentClassName='auto-content'
      contentRef={contentDiv}
      disableDisplayNone
    >
      {children}
    </AnimateHeight>
  );
};

export default DinamycHeight;
