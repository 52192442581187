import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { ButtonsWrapper, CloseBtn, UserCardWrapper, Wrapper } from './AddNoteModal.styled';

import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { NewTextArea } from '../../../../../shared/new-ui/NewTextArea/NewTextArea';
import { TextType } from '../../../../../shared/ui';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import { useUpdateClient } from '../../../../../store/redux/clients/hooks/useClientsMutation';
import { MODAL_GAP } from '../../../../../styles';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { validationClientAddSchema } from '../../../../../utils/validation-input';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
  setisNoteOpen: any;
}

const AddNoteModal: React.FC<IProps> = (props) => {
  const { closeModal, modalPayload, setisNoteOpen } = props;
  const { t } = useTranslation();
  const [note, setNote] = useState(modalPayload?.comment);
  const { mutateAsync: updateClient } = useUpdateClient();

  const initialValue = useMemo(() => {
    return {
      id: modalPayload?.id,
      balance: modalPayload?.balance,
      state: modalPayload?.state,
      email: modalPayload?.email,
      classificator: modalPayload?.classificator,
      birthday: modalPayload?.birthday,
      phone: modalPayload?.phone,
      name: modalPayload?.name,
      filial: modalPayload?.id,
      sex: modalPayload?.sex,
      dopphone: modalPayload?.dopphone,
      comment: modalPayload?.comment,
      commethod: modalPayload.commethod,
      comminterval: modalPayload?.comminterval,
      telegram: modalPayload?.telegram,
      viber: modalPayload?.viber,
      whatsapp: modalPayload?.whatsapp,
      avatar: modalPayload?.avatar,
      tg_chat_id: modalPayload?.tg_chat_id
    };
  }, [modalPayload]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: validationClientAddSchema,
    onSubmit: () => {},
    validateOnBlur: true,
    enableReinitialize: false
  });

  const editClient = async (values: any, type: string) => {
    const res = await updateClient({
      ...values,
      comment: type == 'edit' ? note : type == 'delete' ? '' : ''
    });
    if (res?.status == 'ok' && type == 'edit') {
      toast.success('Заметка успешно изменена');
    }
    if (res?.status == 'ok' && type == 'detele') {
      toast.success('Заметка успешно удалена');
    }
    if (res?.status !== 'ok') {
      toast.error('Произошла ошибка');
    }
  };

  const deleteNote = async () => {
    await editClient(formik.values, 'delete');
    setisNoteOpen(false);
  };

  const sendRenameNote = async () => {
    await editClient(formik.values, 'edit');
    setisNoteOpen(false);
  };

  useEffect(() => {
    formik.setFieldValue('comment', note);
  }, [note]);

  return (
    <Wrapper>
      <FlexWithAlign
        className='form-wrapper'
        $column
      >
        <FlexWithAlign
          className='top-wrapper'
          $gap={'12px'}
          $align={'center'}
        >
          <CloseBtn
            className='flex closebtn'
            onClick={() => setisNoteOpen(false)}
          >
            <IconNew name={EIcon.blackclose} />
          </CloseBtn>
          <NewText
            $tag={TextType.H1}
            fontWeight={600}
            className='title'
          >
            {t('Заметка')}
          </NewText>
        </FlexWithAlign>
        <FlexWithAlign
          $column
          $gap={MODAL_GAP}
        >
          <UserCardWrapper
            $column
            $gap={'0px'}
          >
            <NewTextArea
              className={note ? (note.length > 0 ? 'note-input not-trim' : 'note-input') : 'note-input'}
              label={'Заметка по клиенту (видна только сотрудникам)'}
              value={note}
              onChange={(e: any) => setNote(e.target.value)}
            ></NewTextArea>
          </UserCardWrapper>
          <FlexWithAlign
            $gap={'94px'}
            $align={'center'}
            $justify={'space-between'}
          >
            <CommonButton
              className={'messenger-edit-button-trash'}
              size={'M'}
              onClick={(e) => {
                e.stopPropagation();
                deleteNote();
              }}
            >
              <IconNew name={EIcon.trashred} />
            </CommonButton>
            <ButtonsWrapper>
              <NewButton onClick={() => setisNoteOpen(false)}>{t('Отмена').toUpperCase()}</NewButton>
              <NewButton
                typeBtn={'black'}
                onClick={sendRenameNote}
              >
                {t('Сохранить').toUpperCase()}
              </NewButton>
            </ButtonsWrapper>
          </FlexWithAlign>
        </FlexWithAlign>
      </FlexWithAlign>
    </Wrapper>
  );
};

export default AddNoteModal;
