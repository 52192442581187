import { FC, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';

import { HeaderInfo, PencilContainer, SettingsButton, StatusContainer } from './MessengerHeader.styled';
import LogoImg from '../../../../../assets/images/logo.png';
import OfflineDot from '../../../../../assets/images/offlineDot.png';
import OnlineDot from '../../../../../assets/images/onlineDot.png';
import TelegramImg from '../../../../../assets/images/telegram.png';

import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { firstTwoLetters, getGreyColorForSymbol } from '../../../lib/messenger.helper';
import { EIcon, IconNew as IconInstance } from '../../../../../components/icons/medium-new-icons/icon';
import { useOutside } from '../../../../../shared/lib/hooks/useOutside';
import { cn } from '../../../../../shared/lib';
import { ModalSize, ModalType, Text, TextPType, TextType } from '../../../../../shared/ui';
import { CircleWrapper } from '../../../../../styles/circles.styled';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTranslation } from 'react-i18next';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';

const MessengerHeader: FC = () => {
  const { roomId } = useParams();
  const { t } = useTranslation();
  const { userChats } = useTypedSelector((state) => state.chat);
  const findChat = userChats.find((item) => item.chat_id === roomId);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const { data: activeFilialMessenger } = useFilialQuery();
  const { ref, isShow } = useOutside(false);
  const { openModal } = useActions();
  const onShowModal = useCallback(() => {
    openModal({ modalName: 'CREATE_CHAT', modalType: ModalType.TOP, modalSize: ModalSize.MESSENGER_EDITOR });
  }, []);

  function getCountUsers() {
    let number = findChat ? findChat.users.length : 0;
    number = Math.abs(number) % 100;
    const remainder = number % 10;
    const styles = { fontSize: '14px', fontWeight: '400' };

    if (findChat?.is_tech_support === true || roomId === 'support' || roomId === 'telebot') {
      return <span style={styles}> </span>;
    }

    if ((remainder > 1 && remainder < 5) || (remainder > 22 && remainder < 25)) {
      return <span style={styles}>{number} участника</span>;
    }

    if (remainder === 1 || remainder === 21) {
      return <span style={styles}>{number} участник</span>;
    }

    return <span style={styles}>{number} участников</span>;
  }

  const techChatSupport = userChats.find((item) => item.is_tech_support)?.chat_id;

  return (
    <>
      <HeaderInfo>
        <CircleWrapper
          size={'40'}
          color={'grey'}
          style={{
            background: `${
              findChat ? getGreyColorForSymbol(firstTwoLetters(findChat.chat_name)) : 'linear-gradient(225deg, #EEEEEE 0%, #D8D8D8 100%)'
            }`
          }}
          className={'header'}
        >
          {findChat?.is_tech_support === true || roomId === 'support' ? (
            <img
              alt={'telebon-supprot'}
              src={LogoImg}
            />
          ) : roomId === 'telebot' ? (
            <img
              alt={'telegram-avatar'}
              src={TelegramImg}
            />
          ) : findChat ? (
            <NewText className={'messenger-avatar'} fontWeight={400} $customSize={TextCustomType.T16}
            
            >
              {firstTwoLetters(findChat.chat_name)}
            </NewText>
          ) : (
            ''
          )}
        </CircleWrapper>
        <div>
          <Text
            $tag={TextType.P}
            $pSize={isMobile ? TextPType.P16 : TextPType.P16}
            fontWeight={700}
            color={'grey'}
          >
            {findChat?.is_tech_support === true || roomId === 'support' || roomId === techChatSupport
              ? 'Служба поддержки'
              : roomId === 'telebot'
              ? 'Telegram Bot'
              : findChat
              ? findChat.chat_name
              : null}
          </Text>
          <StatusContainer>
            <Text
              $tag={TextType.P}
              $pSize={isMobile ? TextPType.P14 : TextPType.P14}
              fontWeight={400}
              color={'grey'}
            >
              {roomId === 'support' || roomId === techChatSupport || roomId === 'telebot' ? (activeFilialMessenger?.tgbot ? t('Мы онлайн') : t('Не подключен')) : ''}
              {roomId === 'support' || roomId === 'telebot' ? '' : getCountUsers()}
            </Text>
            {roomId === 'support' || roomId === techChatSupport || roomId === 'telebot' ? <img src={activeFilialMessenger?.tgbot ? OnlineDot : OfflineDot}></img> : ''}
          </StatusContainer>
        </div>
        {isMobile && (
          <button>
            <Link to={'/messenger-list'}>
              <IconInstance name={EIcon.arrowleftoutline} />
            </Link>
          </button>
        )}
      </HeaderInfo>
      {roomId === 'telebot' || roomId === 'support' || roomId === techChatSupport ? null : (
        <PencilContainer ref={ref}>
          <SettingsButton
            onClick={onShowModal}
            className={cn(isShow ? 'open' : 'close')}
          >
            <IconInstance name={EIcon.pencil} />
          </SettingsButton>
        </PencilContainer>
      )}
    </>
  );
};

export default MessengerHeader;
