export function ShareExternalOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.6364 2.26361C12.4676 2.09482 12.2387 2 12 2C11.7613 2 11.5324 2.09482 11.3636 2.2636L7.16363 6.46358C6.81216 6.81505 6.81216 7.3849 7.16363 7.73637C7.5151 8.08784 8.08495 8.08784 8.43642 7.73637L11.1 5.07278V14.1C11.1 14.597 11.503 15 12 15C12.4971 15 12.9 14.597 12.9 14.1V5.0728L15.5636 7.73637C15.915 8.08784 16.4849 8.08784 16.8364 7.73637C17.1878 7.3849 17.1878 6.81505 16.8364 6.46358L12.6364 2.26361Z'
        fill='currentColor'
      />
      <path
        d='M5.8 13.9C5.8 13.4029 5.39706 13 4.9 13C4.40295 13 4 13.4029 4 13.9V15.3377C3.99999 16.1457 3.99998 16.8066 4.04386 17.3437C4.08928 17.8996 4.18617 18.4016 4.42508 18.8705C4.79898 19.6044 5.39561 20.201 6.12944 20.5749C6.59833 20.8138 7.10041 20.9107 7.65632 20.9561C8.19337 21 8.85419 21 9.6622 21H14.3377C15.1457 21 15.8066 21 16.3437 20.9561C16.8996 20.9107 17.4017 20.8138 17.8705 20.5749C18.6044 20.201 19.201 19.6044 19.5749 18.8705C19.8138 18.4016 19.9107 17.8996 19.9561 17.3437C20 16.8066 20 16.1457 20 15.3377V13.9C20 13.4029 19.597 13 19.1 13C18.6029 13 18.2 13.4029 18.2 13.9V15.3C18.2 16.1549 18.1993 16.742 18.1621 17.1971C18.1258 17.6417 18.0592 17.8805 17.9711 18.0534C17.7698 18.4485 17.4485 18.7698 17.0534 18.9711C16.8805 19.0592 16.6417 19.1258 16.1971 19.1621C15.742 19.1993 15.1549 19.2 14.3 19.2H9.7C8.84507 19.2 8.258 19.1993 7.80289 19.1621C7.3583 19.1258 7.11947 19.0592 6.94662 18.9711C6.55148 18.7698 6.23022 18.4485 6.02889 18.0534C5.94082 17.8805 5.87421 17.6417 5.83789 17.1971C5.8007 16.742 5.8 16.1549 5.8 15.3V13.9Z'
        fill='currentColor'
      />
    </svg>
  );
}
