import styled from 'styled-components';

import themelight from '../../../../app/providers/ThemeProvider/ui/theme';
import NewButton from '../../../../shared/new-ui/NewButton/ui/NewButton';
import { opacity } from '../../../../styles';
import { FlexWithAlign } from '../../../../utils/styleUtils';

export const Wrapper = styled.div`
  padding: 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 26px;
  &.full {
    max-width: 976px;
    padding: 24px 0;
  }
  &.paste {
    min-width: 976px;
    max-width: 976px;
    padding: 24px;
  }
  .dropdown-export-clients {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    animation: ${opacity} 0.3s ease;
    .custom-select__value-container {
      padding: 0.8rem 1rem;
    }
  }
  .title-first {
    max-width: 300px;
  }
`;

export const TopWrapper = styled(FlexWithAlign)``;
export const TopTableWrapper = styled(FlexWithAlign)`
  padding: 0 24px;
  &.paste {
    padding: 0;
  }
`;

export const TableWrapper = styled.div`
  overflow-y: auto;
  padding: 0 12px;
  margin-top: -24px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 8px;
  }
  min-height: 400px;
`;
export const ExportTextarea = styled.textarea`
  height: 700px;
  border: 1px solid #eaebee;
  border-radius: 8px;
  padding: 8px 16px;
  resize: none;
  font-size: 16px;
  background: #f5f6f8;
  &:focus-within {
    border-color: #8f969e;
  }
  &:focus {
    outline: none !important;
    border-color: #8f969e;
    background: #fff;
    &::placeholder {
      opacity: 0;
    }
  }
`;

export const ExportSelectWrap = styled.div`
  position: relative;
  padding-top: 30px;

  .filter-btn {
    position: absolute;
    top: 0;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
export const ExportSelect = styled.div`
  position: relative;

  height: 56px;
  width: 320px;
  min-width: 320px;
  > div {
    height: 56px;
    width: 320px;
  }
`;
export const ExportText = styled.p`
  color: rgba(108, 110, 124, 1);
  font-size: 13px;
`;
export const ExportFileWrapper = styled.div`
  position: relative;
  #inputGroupFile {
    position: absolute;
    width: 100%;
    opacity: 0;
    height: 100%;
    inset: 0;
  }
  label[for='inputGroupFile'] {
    vertical-align: middle;
    cursor: pointer;
    width: 100%;
    background: rgba(249, 249, 249, 1);
    border-radius: 8px;
    height: 150px;
    border: 2px dashed ${(props) => props.theme.color.secondaryDark};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    h5,
    svg {
      text-align: center;
      width: 320px;
      color: rgba(108, 110, 124, 1);
    }
  }
  label[for='inputGroupFile'] span {
    margin-left: 10px;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  svg {
    width: 32px;
    height: 32px;
  }
`;

export const ChooseItemWrapper = styled.div`
  margin-top: 44px;
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const ChooseItem = styled(FlexWithAlign)`
  position: relative;
  padding: 24px 26px;
  min-height: 210px;
  max-width: 196px;
  width: 100%;
  background-color: ${themelight.newColor.greyF9};
  text-align: center;
  cursor: pointer;
`;

export const LabelIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: ${themelight.newColor.base01};
  box-shadow: 0px 4px 12px -3px #00000033;
`;

export const StyledButton = styled(NewButton)`
  padding: 10px 16px;
  min-height: 34px;
  font-size: 12px;
`;

export const InputFile = styled.input`
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 100%;
  inset: 0;
  cursor: pointer;
`;

export const ButtonsWrap = styled(FlexWithAlign)`
  margin-top: 20px;
  padding: 0 24px;
  &.paste {
    margin-top: 0px;
  }
`;
