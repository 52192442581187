export const EmptyTransactions = () => {
  return (
    <svg
      width='158'
      height='64'
      viewBox='0 0 158 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.3'>
        <path
          d='M126.069 41.4587H31.9413C30.1615 41.4587 28.7188 42.9015 28.7188 44.6813V58.9424C28.7188 60.7221 30.1615 62.1649 31.9413 62.1649H126.069C127.849 62.1649 129.292 60.7221 129.292 58.9424V44.6813C129.292 42.9015 127.849 41.4587 126.069 41.4587Z'
          fill='white'
          stroke='#BFBFBF'
          strokeWidth='0.739506'
        />
        <path
          d='M112.703 49.1267H53.3257C52.8309 49.1267 52.4297 49.5279 52.4297 50.0227C52.4297 50.5176 52.8309 50.9188 53.3257 50.9188H112.703C113.198 50.9188 113.599 50.5176 113.599 50.0227C113.599 49.5279 113.198 49.1267 112.703 49.1267Z'
          fill='#EAEAEA'
        />
        <path
          d='M97.3168 53.4277H53.3257C52.8309 53.4277 52.4297 53.8289 52.4297 54.3238C52.4297 54.8186 52.8309 55.2198 53.3257 55.2198H97.3168C97.8116 55.2198 98.2128 54.8186 98.2128 54.3238C98.2128 53.8289 97.8116 53.4277 97.3168 53.4277Z'
          fill='#EAEAEA'
        />
        <path
          d='M38.9119 55.5957V48.0957H42.4915C42.9662 48.0957 43.4214 48.265 43.757 48.5664C44.0927 48.8678 44.2812 49.2766 44.2812 49.7028C44.2812 50.1291 44.0927 50.5379 43.757 50.8393C43.4214 51.1407 42.9662 51.31 42.4915 51.31H37.7188M42.4915 53.4528H37.7188M48.5 51.8457C48.5 55.9878 45.1421 59.3457 41 59.3457C36.8579 59.3457 33.5 55.9878 33.5 51.8457C33.5 47.7036 36.8579 44.3457 41 44.3457C45.1421 44.3457 48.5 47.7036 48.5 51.8457Z'
          stroke='#BFBFBF'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <g opacity='0.7'>
        <path
          d='M138.569 23.7107H20.9095C18.6848 23.7107 16.8813 25.5142 16.8813 27.7389V46.3047C16.8813 48.5294 18.6848 50.3329 20.9095 50.3329H138.569C140.794 50.3329 142.597 48.5294 142.597 46.3047V27.7389C142.597 25.5142 140.794 23.7107 138.569 23.7107Z'
          fill='white'
          stroke='#BFBFBF'
          strokeWidth='0.739506'
        />
        <path
          d='M121.835 33.5701H47.6695C47.0355 33.5701 46.5215 34.0841 46.5215 34.7181V34.7261C46.5215 35.3602 47.0355 35.8742 47.6695 35.8742H121.835C122.469 35.8742 122.983 35.3602 122.983 34.7261V34.7181C122.983 34.0841 122.469 33.5701 121.835 33.5701Z'
          fill='#EAEAEA'
        />
        <path
          d='M102.602 39.0994H47.6695C47.0355 39.0994 46.5215 39.6134 46.5215 40.2474V40.2554C46.5215 40.8895 47.0355 41.4035 47.6695 41.4035H102.602C103.236 41.4035 103.75 40.8895 103.75 40.2554V40.2474C103.75 39.6134 103.236 39.0994 102.602 39.0994Z'
          fill='#EAEAEA'
        />
      </g>
      <path
        opacity='0.8'
        d='M28.9943 41.8457V32.8457H33.2898C33.8594 32.8457 34.4057 33.0489 34.8084 33.4106C35.2112 33.7722 35.4375 34.2628 35.4375 34.7743C35.4375 35.2858 35.2112 35.7763 34.8084 36.138C34.4057 36.4997 33.8594 36.7028 33.2898 36.7028H27.5625M33.2898 39.2743H27.5625M40.5 37.3457C40.5 42.3163 36.4706 46.3457 31.5 46.3457C26.5294 46.3457 22.5 42.3163 22.5 37.3457C22.5 32.3751 26.5294 28.3457 31.5 28.3457C36.4706 28.3457 40.5 32.3751 40.5 37.3457Z'
        stroke='#BFBFBF'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M152.49 1.3457H6.51034C3.7432 1.3457 1.5 3.60005 1.5 6.38094V30.3277C1.5 33.1086 3.7432 35.363 6.51034 35.363H152.49C155.257 35.363 157.5 33.1086 157.5 30.3277V6.38094C157.5 3.60005 155.257 1.3457 152.49 1.3457Z'
        fill='white'
        stroke='#BFBFBF'
        strokeWidth='0.739506'
      />
      <path
        d='M130.476 13.9453H39.3366C38.5555 13.9453 37.9224 14.5878 37.9224 15.3804V15.4544C37.9224 16.247 38.5555 16.8894 39.3366 16.8894H130.476C131.257 16.8894 131.891 16.247 131.891 15.4544V15.3804C131.891 14.5878 131.257 13.9453 130.476 13.9453Z'
        fill='#EAEAEA'
      />
      <path
        d='M106.84 21.0117H39.3366C38.5555 21.0117 37.9224 21.6542 37.9224 22.4468V22.5208C37.9224 23.3134 38.5555 23.9558 39.3366 23.9558H106.84C107.621 23.9558 108.254 23.3134 108.254 22.5208V22.4468C108.254 21.6542 107.621 21.0117 106.84 21.0117Z'
        fill='#EAEAEA'
      />
      <path
        d='M15.0759 23.7663V12.5663H20.3437C21.0423 12.5663 21.7123 12.8192 22.2062 13.2693C22.7002 13.7193 22.9777 14.3298 22.9777 14.9663C22.9777 15.6028 22.7002 16.2133 22.2062 16.6634C21.7123 17.1135 21.0423 17.3663 20.3437 17.3663H13.3199M20.3437 20.5663H13.3199M29.1862 18.1663C29.1862 24.3519 24.2446 29.3663 18.1488 29.3663C12.053 29.3663 7.11133 24.3519 7.11133 18.1663C7.11133 11.9807 12.053 6.96631 18.1488 6.96631C24.2446 6.96631 29.1862 11.9807 29.1862 18.1663Z'
        stroke='#BFBFBF'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
