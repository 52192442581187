import { SyntheticEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { Box, ButtonInner, Form, Title, Wrapper } from './CreateClientSidebar.styled';

import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import { apiPost, apiPut } from '../../../../../../utils/apiInstance';
import { getNumberCompleteYears } from '../../../../../../utils/helperFunctions';
import { Padding } from '../../../../../../utils/styleUtils';
import { validationClientAddSchema } from '../../../../../../utils/validation-input';
import Dropdown from '../../../../../../shared/ui/Dropdown/ui/Dropdown';
import { Input, InputType } from '../../../../../../shared/ui';

interface IProps {
  closeSidebar?: () => void;
  edit?: boolean;
  sidebarPayload?: any;
}

const statusList = [
  { value: 1, label: 'Первичный' },
  { value: 2, label: 'Вторичный' }
];

const communicationMethods = [
  { value: 1, label: 'Любой' },
  { value: 2, label: 'Только смс + мессенджеры' }
];
const communicationIntervals = [
  { value: '9-12', label: '9-12' },
  { value: '12-15', label: '12-15' },
  { value: '15-18', label: '15-18' },
  { value: '18-21', label: '18-21' }
];
const CreateClientSidebar: React.FC<IProps> = (props) => {
  const { closeSidebar, edit, sidebarPayload } = props;
  const [pending, setPending] = useState(false);
  const { fetchClientsAsync } = useActions();
  const [formValid, setFormValid] = useState(false);
  const [isWhatsApp, setIsWhatsApp] = useState(false);
  const [isViber, setIsViber] = useState(false);
  const [isTelegram, setIsTelegram] = useState(false);
  const [ageClient, setAgeClient] = useState('');
  const { activeFilial, filials } = useTypedSelector((state) => state.filial);

  useEffect(() => {
    if (sidebarPayload) {
      setAgeClient(getNumberCompleteYears(sidebarPayload.birthday));
    }
  }, []);

  const initialValues = {
    email: '',
    birthday: '',
    phone: '',
    name: '',
    filial: activeFilial?.name,
    status: '',
    sex: false,
    dopphone: '',
    comment: '',
    commethod: '',
    comminterval: '',
    telegram: false,
    viber: false,
    whatsapp: false
  };
  const formik = useFormik({
    initialValues: sidebarPayload
      ? {
          ...sidebarPayload,
          comminterval:
            communicationIntervals.find(
              (item) => item.value.includes(String(sidebarPayload.comstart)) && item.value.includes(String(sidebarPayload.comfinish))
            )?.label || '',
          commethod: communicationMethods.find((item) => item.value === sidebarPayload.commethod)?.label || ''
        }
      : initialValues,
    validationSchema: validationClientAddSchema,
    onSubmit: () => {},
    validateOnBlur: true
  });
  const createClient = async (values: any) => {
    setPending(true);
    const { comminterval, sex, commethod } = values;
    const [comstart, comfinish] = comminterval.split('-');
    const sexBoolean = String(sex) === 'true';
    const commethodNum = communicationMethods.find((item) => item.label === commethod)?.value;
    const res = await apiPut('/client', {
      ...values,
      comstart: Number(comstart),
      comfinish: Number(comfinish),
      sex: sexBoolean,
      commethod: commethodNum,
      filial: activeFilial?.id
    });
    if (res?.status === 200) {
      setPending(false);
      closeSidebar?.();
      toast.success('Клиент успешно добавлен');
      await fetchClientsAsync();
    } else {
      setPending(false);
      toast.error(res.data.description);
    }
  };
  const editClient = async (values: any) => {
    setPending(true);
    const { comminterval, sex, commethod, birthday } = values;
    const [comstart, comfinish] = comminterval.split('-');

    const sexBoolean = String(sex) === 'true';
    const commethodNum = communicationMethods.find((item) => item.label === commethod)?.value;
    const res = await apiPost(`/client/${values.id}`, {
      ...values,
      comstart: Number(comstart),
      comfinish: Number(comfinish),
      sex: sexBoolean,
      commethod: commethodNum,
      filial: activeFilial?.id,
      birthday: birthday && birthday.length > 0 ? birthday : ' '
    });
    if (res?.status === 200) {
      setPending(false);
      closeSidebar?.();
      fetchClientsAsync();
      toast.success('Данные о клиенте успешно обновлены');
    } else {
      setPending(false);
      toast.error(res.data.description);
    }
  };
  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (edit) {
      editClient(formik.values);
    } else {
      createClient(formik.values);
    }
  };

  const handleClose = () => {
    closeSidebar?.();
  };
  useEffect(() => {
    if (formik.values.name && formik.values.phone && formik.values.filial) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formik.values]);

  return (
    <Wrapper>
      <Title>{edit ? sidebarPayload.name : 'Новый клиент'}</Title>
      <Form onSubmit={handleSubmit}>
        <Box className={'form'}>
          <Input
            size={InputType.M}

            label={'ФИО'}
            value={formik.values.name}
            onChange={formik.handleChange}
            name='name'
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors.name}
          >
            {ageClient !== '' && <div className='age'>{ageClient}</div>}
          </Input>
          <Input
            size={InputType.M}

            label={'Телефон'}
            name='phone'
            value={formik.values.phone}
            onChange={formik.handleChange}
            type={'tel'}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && formik.errors.phone}
          />

          <Dropdown
            size={InputType.M}

            onChange={(option: any) => {
              formik.setFieldValue('status', option.label);
            }}
            options={statusList}
            currentValue={statusList[0].label}
            disabled
          />
          <Dropdown
            size={InputType.M}

            onChange={(option: any) => {
              formik.setFieldValue('filial', option.label);
            }}
            options={filials}
            currentValue={filials[0].name}
            disabled
          />
          <Input
            size={InputType.M}

            label={'Дата рождения'}
            value={formik.values.birthday}
            onChange={formik.handleChange}
            name='birthday'
            type='date'
            max={new Date().toISOString().split('T')[0]}
          />
          <Input
            size={InputType.M}

            label={'Email'}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && formik.errors.email}
            name='email'
          />
          <div>
            <div>Пол: </div>
            <div
              className='flex sex-input-wrap'
              role='group'
              aria-labelledby='my-radio-group'
            >
              <label>
                <span>Мужской</span>
                <input
                  onBlur={formik.handleBlur}
                  value={'false'}
                  onChange={formik.handleChange}
                  name='sex'
                  type='radio'
                  defaultChecked={formik.values.sex === false}
                />
              </label>
              <label>
                <span>Женский</span>
                <input
                  onBlur={formik.handleBlur}
                  value={'true'}
                  onChange={formik.handleChange}
                  name='sex'
                  type='radio'
                  defaultChecked={formik.values.sex === true}
                />
              </label>
            </div>
          </div>
          <Input
            label={'Дополнительный телефон'}
            name='dopphone'
            value={formik.values.dopphone}
            onChange={formik.handleChange}
            type={'tel'}
            onBlur={formik.handleBlur}
            error={formik.touched.dopphone && formik.errors.dopphone}
          />
          <Input
            label={'Комментарий'}
            onBlur={formik.handleBlur}
            value={formik.values.comment}
            onChange={formik.handleChange}
            name='comment'
          />
          <div className='messangers flex'>
            <label>
              <input
                type='checkbox'
                name='whatsapp'
                value={formik.values.whatsapp}
                onChange={formik.handleChange}
              />
              <button
                className={formik.values.whatsapp ? 'whatsapp-btn active' : 'whatsapp-btn'}
                onClick={() => setIsWhatsApp(!isWhatsApp)}
                type='button'
              ></button>
            </label>
            <label>
              <input
                type='checkbox'
                name='viber'
                value={formik.values.viber}
                onChange={formik.handleChange}
              />
              <button
                className={formik.values.viber ? 'active viber-btn' : 'viber-btn'}
                type='button'
                onClick={() => setIsViber(!isViber)}
              ></button>
            </label>
            <label>
              <input
                type='checkbox'
                name='telegram'
                value={formik.values.telegram}
                onChange={formik.handleChange}
              />

              <button
                className={formik.values.telegram ? 'active telegram-btn' : 'telegram-btn'}
                type='button'
                onClick={() => setIsTelegram(!isTelegram)}
              ></button>
            </label>
          </div>
          <Dropdown
            size={InputType.M}

            onChange={(option: any) => {
              formik.setFieldValue('commethod', option.label);
            }}
            options={communicationMethods}
            currentValue={communicationMethods.find((item) => item.label === formik.values.commethod)?.label || formik.values.commethod}
            placeholder='Способ связи'
          />
          <Dropdown
            size={InputType.M}

            onChange={(option: any) => {
              formik.setFieldValue('comminterval', option.label);
            }}
            options={communicationIntervals}
            currentValue={
              communicationIntervals.find((item) => item.label === formik.values.comminterval)?.label || formik.values.comminterval
            }
            placeholder='Время связи'
          />
        </Box>
        <Box className={'controls'}>
          <CommonButton
            typeBtn='success'
            type='submit'
            fullWidth
            disabled={!formValid || !formik.isValid}
          >
            <ButtonInner>
              <span>Сохранить</span>
            </ButtonInner>
          </CommonButton>
          <Padding $size='24px' />
          <CommonButton
            typeBtn='danger'
            onClick={handleClose}
            fullWidth
          >
            <ButtonInner>
              <span>Отмена</span>
            </ButtonInner>
          </CommonButton>
        </Box>
      </Form>
    </Wrapper>
  );
};

export default CreateClientSidebar;
