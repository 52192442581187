export function ArrowTurnLeftOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.1 6C19.597 6 20 6.40294 20 6.9V10.8354C20 11.3653 20 11.8165 19.9697 12.1869C19.9379 12.576 19.8683 12.9546 19.6839 13.3166C19.4059 13.8622 18.9622 14.3059 18.4165 14.5839C18.0546 14.7683 17.676 14.838 17.2869 14.8698C16.9164 14.9 16.4653 14.9 15.9354 14.9H7.12241L8.72609 16.4536C9.0831 16.7994 9.09214 17.3692 8.7463 17.7262C8.40045 18.0832 7.83067 18.0922 7.47366 17.7464L4.27366 14.6464C4.09867 14.4769 3.99988 14.2437 3.99988 14C3.99988 13.7564 4.09866 13.5231 4.27366 13.3536L7.47366 10.2536C7.83066 9.90771 8.40044 9.91675 8.74629 10.2738C9.09214 10.6308 9.0831 11.2005 8.7261 11.5464L7.12238 13.1H15.9C16.4749 13.1 16.8519 13.0993 17.1403 13.0757C17.4181 13.053 17.5335 13.0137 17.5994 12.9801C17.8063 12.8746 17.9746 12.7064 18.0801 12.4994C18.1137 12.4335 18.153 12.3182 18.1757 12.0403C18.1993 11.752 18.2 11.3749 18.2 10.8V6.9C18.2 6.40294 18.6029 6 19.1 6Z'
        fill='currentColor'
      />
    </svg>
  );
}
