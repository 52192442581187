export const ArrowNextNewIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.92981 19.2969C8.73781 19.2969 8.57781 19.2329 8.44981 19.1049C8.32181 18.9769 8.25781 18.8329 8.25781 18.6729C8.25781 18.5129 8.32181 18.4009 8.44981 18.3369L14.3058 12.0969L8.44981 5.95288C8.32181 5.82487 8.25781 5.68087 8.25781 5.52087C8.25781 5.36088 8.32181 5.21688 8.44981 5.08887C8.57781 4.96087 8.72181 4.89687 8.88181 4.89687C9.04181 4.89687 9.18581 4.96087 9.31381 5.08887L15.5538 11.7129C15.6818 11.8409 15.7458 11.9849 15.7458 12.1449C15.7458 12.3049 15.6818 12.4489 15.5538 12.5769L9.31381 19.1049C9.18581 19.2329 9.05781 19.2969 8.92981 19.2969Z'
        fill='currentColor'
      />
    </svg>
  );
};
