export const colors = [
  '#D55227',
  '#EC4A4A',
  '#AB9BD8',
  '#90F239',
  '#7B85C7',
  '#B4E8E6',
  '#8DAFD8',
  '#579AE2',
  '#4651B1',
  '#7A29A5',
  '#6EB37B',
  '#C1C945',
  '#D36C19',
  '#70574A',
  '#5C83F0',
  '#CD7A73',
  '#BD4580',
  '#B60104',
  '#C2EBFC',
  '#8F69AB',
  '#FF7999',
  '#8DB14D',
  '#176A7C',
  '#FFA1EA',
  '#B9205E',
  '#EEBA2B',
  '#FFD702',
  '#AAE4CB',
  '#955636',
  '#497F48',
  '#DA9222',
  '#DFEB6D',
  '#549587'
];
