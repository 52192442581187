export const PaymentIcon28 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.5 24.3281C19.4324 24.3281 20.3266 23.9577 20.9859 23.2984C21.6452 22.6391 22.0156 21.7449 22.0156 20.8125C22.0156 19.8801 21.6452 18.9859 20.9859 18.3266C20.3266 17.6673 19.4324 17.2969 18.5 17.2969C17.5676 17.2969 16.6734 17.6673 16.0141 18.3266C15.3548 18.9859 14.9844 19.8801 14.9844 20.8125C14.9844 21.7449 15.3548 22.6391 16.0141 23.2984C16.6734 23.9577 17.5676 24.3281 18.5 24.3281Z'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M4.4375 10.9688V3.9375H32.5625V10.9688'
        stroke='currentColor'
      />
      <path
        d='M26.9375 32.0625H10.0625V9.5625H26.9375V32.0625Z'
        stroke='currentColor'
      />
    </svg>
  );
};
