import React from 'react';

export function UserFilled() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.8496 7.95005C14.8496 6.37523 13.5744 5.10005 11.9996 5.10005C10.4248 5.10005 9.14961 6.37523 9.14961 7.95005C9.14961 9.52487 10.4248 10.8 11.9996 10.8C13.5744 10.8 14.8496 9.52487 14.8496 7.95005ZM16.6496 7.95005C16.6496 10.519 14.5685 12.6 11.9996 12.6C9.43068 12.6 7.34961 10.519 7.34961 7.95005C7.34961 5.38112 9.43068 3.30005 11.9996 3.30005C14.5685 3.30005 16.6496 5.38112 16.6496 7.95005ZM5.89961 18.4286C5.89961 19.1975 5.80884 19.1 6.2349 19.1H17.7643C18.1904 19.1 18.0996 19.1975 18.0996 18.4286C18.0996 16.536 15.3214 15.4 11.9996 15.4C8.6778 15.4 5.89961 16.536 5.89961 18.4286ZM4.09961 18.4286C4.09961 15.1018 7.7724 13.6 11.9996 13.6C16.2268 13.6 19.8996 15.1018 19.8996 18.4286C19.8996 20.1642 19.2141 20.9 17.7643 20.9H6.2349C4.78515 20.9 4.09961 20.1642 4.09961 18.4286Z'
        fill='#1B1F3B'
        fillOpacity='0.2'
      />
    </svg>
  );
}
