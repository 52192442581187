import styled from 'styled-components';
import { PageTitle } from '../../../../../utils/styleUtils';
import { MODAL_GAP } from '../../../../../styles';

export const Wrapper = styled.div`
  //max-width: 482px;
  //min-width: 482px;
`;

export const Title = styled(PageTitle)`
  margin: 0;
`;
export const Form = styled.form`
  display: flex;
  //max-width: 330px;
  flex-direction: column;
  gap: ${MODAL_GAP};
  width: 100%;
  margin: ${MODAL_GAP} auto 0;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6.25rem;
    border: 0.25rem solid transparent;
    opacity: 0.2;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.base04};
  }
  &:hover::-webkit-scrollbar-track {
    background: transparent;
  }

  &:hover {
    --fix: ;
  }
  .quantity-wrapper {
    height: 44px;
    background: #ecf1f7;
    width: 100%;
    border-radius: 12px;
    input {
      padding-top: 22px;
    }
    h5 {
      position: absolute;
      z-index: 2;
      color: #a5a6a9;
      font-weight: 400;
      left: 13px;
      top: 8px;
    }
  }
  .btnWrapper {
    position: absolute;
    gap: 20px;
    top: 50%;
    transform: translateY(-50%);
    right: 19px;
    button {
      padding: 0;
      
    }
  }
`;
