import React from 'react';

export function DisabledIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.58862 4.77275C7.38427 3.05511 9.81892 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12C22.5 14.1219 21.8391 16.0894 20.712 17.7079C20.5227 17.9797 20.3203 18.2417 20.1057 18.4929C20.1036 18.4953 20.1016 18.4976 20.0996 18.5C19.8824 18.7537 19.6526 18.9965 19.4114 19.2273C17.6157 20.9449 15.1811 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 9.87814 3.16086 7.91063 4.28798 6.29207C4.47726 6.02027 4.67968 5.75831 4.89433 5.50712C4.89635 5.50474 4.89838 5.50237 4.90041 5.5C5.11765 5.24626 5.34737 5.00352 5.58862 4.77275ZM7.118 6.08084C8.54055 4.78616 10.4277 4 12.5 4C16.9183 4 20.5 7.58172 20.5 12C20.5 13.6271 20.016 15.1377 19.1827 16.3999L7.118 6.08084ZM17.882 17.9192L5.81726 7.60007C4.984 8.86233 4.5 10.3729 4.5 12C4.5 16.4183 8.08172 20 12.5 20C14.5723 20 16.4595 19.2138 17.882 17.9192Z'
        fill='currentColor'
      />
    </svg>
  );
}
