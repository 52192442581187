import { IButtonProps } from './NewButton.props';
import { Btn, ButtonWrapper } from './NewButton.styled';

import { cn } from '../../../lib';

const NewButton: React.FC<IButtonProps> = (props) => {
  const { children, className, classNameWrapper, typeBtn, onClick, disabled, type, fullWidth } = props;

  return (
    <ButtonWrapper
      className={cn(classNameWrapper && `${classNameWrapper}`)}
      style={{ width: fullWidth ? '100%' : 'auto' }}
    >
      <Btn
        className={cn(className, typeBtn && `${typeBtn}`, fullWidth && 'fullWidth')}
        onClick={onClick}
        disabled={disabled}
        type={type ? type : 'button'}
      >
        {children}
      </Btn>
    </ButtonWrapper>
  );
};

export default NewButton;
