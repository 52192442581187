import React from 'react';

export function SmallInfo() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 21.7263C17.3717 21.7263 21.7263 17.3717 21.7263 12C21.7263 6.6283 17.3717 2.27368 12 2.27368C6.6283 2.27368 2.27368 6.6283 2.27368 12C2.27368 17.3717 6.6283 21.7263 12 21.7263ZM12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 9.15327C11.128 9.15327 10.4211 8.44635 10.4211 7.57432C10.4211 6.70229 11.128 5.99537 12 5.99537C12.872 5.99537 13.5789 6.70229 13.5789 7.57432C13.5789 8.44635 12.872 9.15327 12 9.15327ZM10.8632 12.5398C10.8632 11.8903 11.3721 11.3638 12 11.3638C12.6279 11.3638 13.1368 11.8903 13.1368 12.5398V17.1351C13.1368 17.7846 12.6279 18.3112 12 18.3112C11.3721 18.3112 10.8632 17.7846 10.8632 17.1351V12.5398Z'
        fill='currentColor'
      />
    </svg>
  );
}
