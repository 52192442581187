export function ArrowEnterSquareOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.5384 3H9.4616C8.5141 2.99999 7.74834 2.99998 7.12792 3.05067C6.48863 3.10291 5.92481 3.21341 5.40244 3.47957C4.57453 3.90141 3.90141 4.57453 3.47957 5.40244C3.21341 5.92481 3.10291 6.48863 3.05067 7.12792C2.99998 7.74834 2.99999 8.51409 3 9.46158V14.5384C2.99999 15.4859 2.99998 16.2517 3.05067 16.8721C3.10291 17.5114 3.21341 18.0752 3.47957 18.5976C3.90141 19.4255 4.57453 20.0986 5.40244 20.5204C5.92481 20.7866 6.48863 20.8971 7.12792 20.9493C7.74834 21 8.51409 21 9.46159 21H14.5384C15.4859 21 16.2517 21 16.8721 20.9493C17.5114 20.8971 18.0752 20.7866 18.5976 20.5204C19.4255 20.0986 20.0986 19.4255 20.5204 18.5976C20.772 18.1038 20.8843 17.5737 20.94 16.9781C20.9864 16.4832 20.6228 16.0445 20.1279 15.9981C19.633 15.9518 19.1942 16.3154 19.1479 16.8103C19.1033 17.2864 19.0254 17.5668 18.9166 17.7804C18.6673 18.2696 18.2696 18.6673 17.7804 18.9166C17.5541 19.0319 17.2535 19.1122 16.7255 19.1553C16.187 19.1993 15.4949 19.2 14.5 19.2H9.5C8.50506 19.2 7.81298 19.1993 7.2745 19.1553C6.74652 19.1122 6.44595 19.0319 6.21963 18.9166C5.7304 18.6673 5.33265 18.2696 5.08338 17.7804C4.96807 17.5541 4.88783 17.2535 4.8447 16.7255C4.8007 16.187 4.8 15.4949 4.8 14.5V9.5C4.8 8.50506 4.8007 7.81298 4.8447 7.2745C4.88783 6.74652 4.96807 6.44595 5.08338 6.21963C5.33265 5.7304 5.7304 5.33265 6.21963 5.08338C6.44595 4.96807 6.74652 4.88783 7.2745 4.8447C7.81298 4.8007 8.50506 4.8 9.5 4.8H14.5C15.4949 4.8 16.187 4.8007 16.7255 4.8447C17.2535 4.88783 17.5541 4.96807 17.7804 5.08338C18.0802 5.23615 18.3457 5.44469 18.5637 5.6959C18.8895 6.07131 19.4579 6.11153 19.8333 5.78574C20.2087 5.45996 20.2489 4.89153 19.9231 4.51613C19.5542 4.09105 19.105 3.73811 18.5976 3.47957C18.0752 3.21341 17.5114 3.10291 16.8721 3.05067C16.2517 2.99998 15.4859 2.99999 14.5384 3Z'
        fill='currentColor'
      />
      <path
        d='M21.0001 8.90012C21.0001 8.40307 20.5972 8.00012 20.1001 8.00012C19.603 8.00012 19.2001 8.40307 19.2001 8.90012V10.9999C19.2001 11.6074 18.7076 12.0999 18.1001 12.0999H11.1224L12.7263 10.546C13.0833 10.2001 13.0924 9.63036 12.7465 9.27336C12.4006 8.91637 11.8309 8.90734 11.4739 9.25321L8.27376 12.3535C8.09877 12.523 7.99999 12.7563 8 12.9999C8.00001 13.2436 8.0988 13.4768 8.27379 13.6463L11.4739 16.7463C11.8309 17.0922 12.4007 17.0831 12.7465 16.7261C13.0924 16.3691 13.0833 15.7993 12.7263 15.4535L11.1226 13.8999H18.1001C19.7017 13.8999 21.0001 12.6015 21.0001 10.9999V8.90012Z'
        fill='currentColor'
      />
    </svg>
  );
}
