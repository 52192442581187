import styled from 'styled-components';

import { Text14 } from '../../../../../styles/text';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .title,
  .count-days {
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    color: #151618;
  }
  .count-days {
    transform: translateY(2px);
  }
  & > div {
    flex-grow: inherit;
  }
  .form-wrapper {
    flex-grow: inherit;

    form {
      flex-grow: inherit;
    }
  }
  .form-content {
    padding-bottom: 14px;
    border-bottom: 1px solid #ebebeb;
  }
  .input-wrap {
    //padding: 0 10px;
    gap: 12px;
    grid-template-columns: 0.8fr 0.8fr 0.5fr;
    align-items: center;
  }
  .is-working-btn {
    min-width: 120px;
  }
  .time-table-input {
    min-width: 130px;
  }
  .break-wrap {
    gap: 12px;
    grid-template-columns: 0.8fr 0.8fr 2.5fr;
    align-items: center;
  }

  h3 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    justify-self: left;
  }
  .title-time-table {
    padding: 0 10px;
  }
  ul.react-datepicker__time-list {
    background-color: ${(props) => props.theme.color.base02};
  }
  .top-wrapper {
    padding: 4px;
    width: 100%;
  }
  .chosen-text {
    font-size: 15px;
    line-height: 20px;
  }
  .reset-btn {
    color: #5089fd;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px; /* 115.385% */
  }
  form {
    gap: 50px;
  }

  .react-datepicker__time-list-item {
    font-size: 12px !important;
  }
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 93px;
  }
  .react-datepicker-popper {
    left: 0 !important;
    top: 40px !important;
  }
  .btns-wrap {
    align-items: stretch;
    margin-top: auto;
    button {
      width: 100%;
      text-align: center;
    }
    & > span {
      color: #292f51;
      text-align: center;
      font-size: 13px;
      line-height: 15px; /* 115.385% */
    }
  }
  .divide-text {
    text-align: center;
  }
  .add-break-btn {
    flex-shrink: 0;
  }
  .delete-break-btn {
    display: flex;
    padding: 0;
    color: ${(props) => props.theme.color.grayBC};
    svg {
      width: 24px;
      height: 24px;
    }
    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      svg {
        color: ${(props) => props.theme.color.danger};
      }
    }
    &:disabled {
      color: #bcbcc2;
    }
  }
`;

export const CloseBtn = styled.button`
  svg {
    width: 37px;
    height: 37px;
  }
`;
export const DurationWrap = styled(FlexWithAlign)`
  color: #bcbcc2;
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #8c8b88;
    flex-shrink: 0;
  }
`;

export const BreakLabel = styled(Text14)`
  display: flex;
  min-width: 120px;
  justify-content: flex-end;
`;
