import React from 'react';

export function SettingPortalIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.4 1.5H9.6C10.7449 1.5 11.5133 1.50117 12.105 1.54952C12.6793 1.59643 12.9539 1.6802 13.135 1.77248C13.6054 2.01217 13.9878 2.39462 14.2275 2.86502C14.3198 3.04615 14.4036 3.32071 14.4505 3.89496C14.4988 4.48671 14.5 5.25514 14.5 6.4V9.6C14.5 10.7449 14.4988 11.5133 14.4505 12.105C14.4036 12.6793 14.3198 12.9539 14.2275 13.135C13.9878 13.6054 13.6054 13.9878 13.135 14.2275C12.9539 14.3198 12.6793 14.4036 12.105 14.4505C11.5133 14.4988 10.7449 14.5 9.6 14.5H6.4C5.25514 14.5 4.48671 14.4988 3.89496 14.4505C3.32071 14.4036 3.04615 14.3198 2.86502 14.2275C2.39462 13.9878 2.01217 13.6054 1.77248 13.135C1.6802 12.9539 1.59643 12.6793 1.54952 12.105C1.50117 11.5133 1.5 10.7449 1.5 9.6V6.4C1.5 5.25514 1.50117 4.48671 1.54952 3.89496C1.59643 3.32071 1.6802 3.04615 1.77248 2.86502C2.01217 2.39462 2.39462 2.01217 2.86502 1.77248C3.04615 1.6802 3.32071 1.59643 3.89496 1.54952C4.48671 1.50117 5.25514 1.5 6.4 1.5ZM0 6.4C0 4.15979 0 3.03969 0.435974 2.18404C0.819467 1.43139 1.43139 0.819467 2.18404 0.435974C3.03969 0 4.15979 0 6.4 0H9.6C11.8402 0 12.9603 0 13.816 0.435974C14.5686 0.819467 15.1805 1.43139 15.564 2.18404C16 3.03969 16 4.15979 16 6.4V9.6C16 11.8402 16 12.9603 15.564 13.816C15.1805 14.5686 14.5686 15.1805 13.816 15.564C12.9603 16 11.8402 16 9.6 16H6.4C4.15979 16 3.03969 16 2.18404 15.564C1.43139 15.1805 0.819467 14.5686 0.435974 13.816C0 12.9603 0 11.8402 0 9.6V6.4ZM5.5 4.75C5.5 4.33579 5.16421 4 4.75 4C4.33579 4 4 4.33579 4 4.75C4 6.95914 5.79086 8.75 8 8.75C10.2091 8.75 12 6.95914 12 4.75C12 4.33579 11.6642 4 11.25 4C10.8358 4 10.5 4.33579 10.5 4.75C10.5 6.13071 9.38071 7.25 8 7.25C6.61929 7.25 5.5 6.13071 5.5 4.75Z'
        fill='currentColor'
      />
    </svg>
  );
}
