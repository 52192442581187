import React from 'react';

export function LogoTLBN() {
  return (
    <svg
      width='59'
      height='18'
      viewBox='0 0 59 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5844 0V3.39429H8.59909V18H4.95898V3.39429H0V0H13.5844Z'
        fill='#292F51'
      />
      <path
        d='M19.2246 14.6057H26.2674V18H15.5845V0H19.2246V14.6057Z'
        fill='#292F51'
      />
      <path
        d='M39.4251 8.66572C41.0429 9.57429 41.8518 10.9457 41.8518 12.78C41.8518 14.3057 41.2979 15.5571 40.1901 16.5343C39.0822 17.5114 37.7194 18 36.1015 18H28.2674V0H35.5476C37.1303 0 38.4579 0.480003 39.5306 1.44C40.6209 2.38286 41.166 3.59143 41.166 5.06571C41.166 6.55714 40.5857 7.75714 39.4251 8.66572ZM35.5476 3.31714H31.9075V7.27714H35.5476C36.1103 7.27714 36.5763 7.08857 36.9456 6.71143C37.3325 6.33429 37.5259 5.86286 37.5259 5.29714C37.5259 4.73143 37.3413 4.26 36.972 3.88286C36.6027 3.50571 36.1279 3.31714 35.5476 3.31714ZM36.1015 14.6829C36.717 14.6829 37.227 14.4857 37.6314 14.0914C38.0359 13.68 38.2381 13.1657 38.2381 12.5486C38.2381 11.9486 38.0359 11.4514 37.6314 11.0571C37.227 10.6457 36.717 10.44 36.1015 10.44H31.9075V14.6829H36.1015Z'
        fill='#292F51'
      />
      <path
        d='M54.5347 0H58.1748V18H55.4052L47.4919 6.99429V18H43.8518V0H46.6215L54.5347 10.98V0Z'
        fill='#292F51'
      />
    </svg>
  );
}
