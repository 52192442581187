import styled from 'styled-components';

export const TelegramBotModalContainer = styled.div`
  display: grid;
  grid-template-columns: 1.81fr 1.3fr;
  height: 707px;
  width: 794px;
  padding: 24px 24px 40px 24px;
`;

export const InstructionContainer = styled.div`
  padding-right: 36px;
  height: 100%;
`;

export const InstructionHeader = styled.div`
  display: flex;
  gap: 16px;
  .arrow {
    margin: 0;
    padding: 0 0 7px 0;
    width: fit-content;
    min-width: fit-content;
  }
`;

export const InstructionContent = styled.div`
  margin-top: 6px;
  .title-text {
    line-height: inherit;
  }
`;

export const Instruction = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 18px;
`;

export const InstructionItem = styled.li`
  display: flex;
  gap: 20px;
  padding-bottom: 17px;
  border-bottom: 1px solid ${(props) => props.theme.color.borderColorSecond};
  align-items: center;
  .blue-text {
    color: ${(props) => props.theme.newColor.linkBlue};
  }
  &:first-child {
    padding-bottom: 36px;
    div:last-child {
      gap: 20px;
    }
  }
  span {
    line-height: 20px;
  }
`;

export const IconWrap = styled.div`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.color.borderColorSecond};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 12.67px;
    height: 11.66px;
  }
`;

export const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Token = styled.form`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  .token-btn {
    width: 100%;
  }
  input::placeholder {
    font-weight: 400;
    color: #00000066;
  }
`;
