export const PaymentIcon31 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.0938 8.15625H31.1562L32.5625 23.625V33.4688H4.4375V23.625L5.84375 8.15625H11.4688'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M4.4375 23.625H32.5625'
        stroke='currentColor'
      />
      <path
        d='M15.6875 28.5469H21.3125'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M10.7656 2.53125V12.375H16.3906V2.53125H10.7656Z'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M26.2344 11.6719V14.4844'
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        d='M21.3125 11.6719V14.4844'
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        d='M26.2344 16.5938V19.4062'
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        d='M21.3125 16.5938V19.4062'
        stroke='currentColor'
        strokeWidth='3'
      />
    </svg>
  );
};
