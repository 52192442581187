import recordsData from '../../../utils/records.json';

export interface IRecord {
  src: string;
  duration: string;
}

export interface IRecordCall {
  id: string | number;
  name: string;
  phone: string;
  record: IRecord;
  created_at: string;
}

export interface CallRecordsState {
  records: IRecordCall[];
}

export const typedRecordsData = recordsData as { records: IRecordCall[] };
