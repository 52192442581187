import toast from 'react-hot-toast';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { IUser, IUserSchedule } from './user.interface';

import { formatUserSchedule } from '../../../shared/api/model/helpers/user.helpers';
import { UserService } from '../../../shared/api/model/service/user.service';

export const fetchUserByIdAsync = createAsyncThunk('user/fetchUserById', async (id: string | undefined) => {
  return await UserService.fetchUserById(id);
});

export const fetchUsersAsync = createAsyncThunk('user/fetchUsers', async () => {
  return await UserService.fetchUsers();
});

export const fetchEmployeesAsync = createAsyncThunk('user/fetchEmployees', async () => {
  return await UserService.fetchEmployees();
});

export const updateUserAsync = createAsyncThunk('user/updateUser', async ({ data }: { data: IUser }) => {
  const response = await UserService.updateUser(data);
  toast.success('Данные успешно обновлены');

  return { data };
});

export const updateUserScheduleAsync = createAsyncThunk(
  'user/updateUserSchedule',
  async ({ id, data }: { id: string; data: IUserSchedule[] }) => {
    const formattedData = formatUserSchedule(data);
    await UserService.updateUserSchedule(id, formattedData);

    return { id, data };
  }
);

export const fetchUserScheduleForPeriodAsync = createAsyncThunk(
  'user/fetchUserScheduleForPeriod',
  async ({ id, dateStart, dateEnd }: { id: string; dateStart: string; dateEnd: string }) => {
    return await UserService.fetchUserScheduleForPeriod({ id, dateStart, dateEnd });
  }
);

export const fetchUserScheduleAsync = createAsyncThunk(
  'user/fetchUserSchedule',
  async ({ ids, dateStart, dateEnd }: { ids: string[]; dateStart: string; dateEnd: string }) => {
    return await UserService.fetchUserSchedule({ ids, dateStart, dateEnd });
  }
);
