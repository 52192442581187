import { FC } from 'react';

import { BottomTab, MobileTabs } from './MobileSide.styled';

import { cn } from '../../../../../shared/lib';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { Portal, Text, TextPType, TextType } from '../../../../../shared/ui';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { useProfileUserQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { getCookie } from '../../../../../utils/cookies';
import { isLinkActive } from '../../lib/sidebar.helpers';
import { useMobileSubmenuData } from '../../lib/sidebar.mobile.data';
import { Icon, MenuLink } from '../Sidebar.styled';
import { getRouteCalendarCabinet } from '../../../../../shared/const';

const MobileSide: FC = () => {
  const { websocketroomId, userChats } = useTypedSelector((state) => state.chat);
  const { data: activeFilial } = useFilialQuery();
  const { data: profileUser } = useProfileUserQuery(getCookie('id'));
  const { submenuMobile } = useMobileSubmenuData();
  const { setIspathname, setActiveDate } = useActions();
  const isTechSupport = profileUser?.Email === 'hello@telebon.ru' && activeFilial?.name === 'Telebon';
  let totalUnread = 0;

  userChats?.forEach((chat) => {
    totalUnread += chat.unread;
  });

  return (
    <Portal>
      <MobileTabs
        className={cn(
          (location.pathname === `/messenger-list/${websocketroomId}` ||
            location.pathname === `/messenger-list/telebot` ||
            location.pathname === `/messenger-list/support`) &&
            'hidden'
        )}
      >
        {submenuMobile.map((item, index) => (
          <BottomTab key={index}>
            <MenuLink
              onClick={() => {
                item.to === getRouteCalendarCabinet() &&
                  location.pathname !== getRouteCalendarCabinet() &&
                  setActiveDate(new Date().toISOString());
                setIspathname(location.pathname);
              }}
              to={item.to}
            />
            {item.mark && isTechSupport && totalUnread !== 0 && (
              <div className={'message-unread'}>
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P11}
                  color={'base01'}
                  fontWeight={600}
                >
                  {totalUnread}
                </Text>
              </div>
            )}
            <Icon>{isLinkActive(item.to, location.pathname) ? item.icon : item.iconGray}</Icon>
            <Text
              className={cn(isLinkActive(item.to, location.pathname) && 'active')}
              $tag={TextType.P}
              $pSize={TextPType.P11}
              small
            >
              {item.title}
            </Text>
          </BottomTab>
        ))}
      </MobileTabs>
    </Portal>
  );
};

export default MobileSide;
