import React from 'react';

export function PayOutlineIcon() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0ZM11 1.8C5.91898 1.8 1.8 5.91898 1.8 11C1.8 16.081 5.91898 20.2 11 20.2C16.081 20.2 20.2 16.081 20.2 11C20.2 5.91898 16.081 1.8 11 1.8ZM12.2529 6.00001C14.107 6.00001 15.61 7.50306 15.61 9.35715C15.61 11.2113 14.107 12.7143 12.2529 12.7143L10 12.714V14H11.5C11.9142 14 12.25 14.3358 12.25 14.75C12.25 15.1642 11.9142 15.5 11.5 15.5H10V16.15C10 16.6195 9.61944 17 9.15 17C8.68056 17 8.3 16.6195 8.3 16.15L8.299 15.5H7.5C7.08579 15.5 6.75 15.1642 6.75 14.75C6.75 14.3358 7.08579 14 7.5 14H8.299V12.714L7.60714 12.7143C7.13376 12.7143 6.75 12.3305 6.75 11.8572C6.75 11.3838 7.13376 11 7.60714 11H8.299L8.3 6.85001C8.3 6.38057 8.68056 6.00001 9.15 6.00001H12.2529ZM12.2857 7.57144H10V11H12.2857C13.2325 11 14 10.2325 14 9.28573C14 8.33895 13.2325 7.57144 12.2857 7.57144Z'
        fill='currentColor'
      />
    </svg>
  );
}
