export const PersonalArea = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2547_12648)'>
        <g clipPath='url(#clip1_2547_12648)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9 9.6875C13.1421 9.6875 16.5 12.8401 16.5 16.729C16.5 17.0167 16.2516 17.25 15.9451 17.25C15.6386 17.25 15.3902 17.0167 15.3902 16.729C15.3902 13.4156 12.5292 10.7295 9 10.7295C5.47081 10.7295 2.60983 13.4156 2.60983 16.729C2.60983 17.0167 2.36139 17.25 2.05492 17.25C1.74844 17.25 1.5 17.0167 1.5 16.729C1.5 12.8401 4.85786 9.6875 9 9.6875ZM8.75348 0.75C10.8767 0.75 12.5979 2.46828 12.5979 4.58788C12.5979 6.70749 10.8767 8.42577 8.75348 8.42577C6.63028 8.42577 4.90909 6.70749 4.90909 4.58788C4.90909 2.46828 6.63028 0.75 8.75348 0.75ZM8.75348 1.80759C7.21537 1.80759 5.96848 3.05237 5.96848 4.58788C5.96848 6.1234 7.21537 7.36818 8.75348 7.36818C10.2916 7.36818 11.5385 6.1234 11.5385 4.58788C11.5385 3.05237 10.2916 1.80759 8.75348 1.80759Z'
            fill='currentColor'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2547_12648'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_2547_12648'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
