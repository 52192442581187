import toast from 'react-hot-toast';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { UserService } from '../../../../shared/api/model/service/user.service';
import { USER_PROFILE_QUERY, USER_SCHEDULE_QUERY, USERS_QUERY, USERS_SCHEDULE_QUERY } from '../../../../shared/const/query.const';
import { getCookie } from '../../../../utils/cookies';
import { IFilialTimeWork } from '../../filial/filial.interface';
import { IUser, IUserChangePassword, IUserInput, IUserSchedule, IUserScheduleTemplate } from '../user.interface';

export function useCreateUser() {
  const queryEmployee = useQueryClient();
  // const { data: activeFilial } = useFilialQuery();
  // const { mutate: updateUserSchedule } = useUpdateUserSchedule();
  // const [isOffline, setIsOffline] = useState('yes');
  // const timeWorkDataArr = transformFetchFilialScheduleData(activeFilial);

  return useMutation({
    mutationFn: (data: IUserInput) => {
      // setIsOffline(data.offline);

      return UserService.createEmployeer(data);
    },

    onSettled: async (data, error) => {
      if (error) {
        console.error(error);
      } else {
        // toast.success(isOffline === 'no' ? 'Приглашение отправлено на указанную почту' : 'Сотрудник добавлен в список без приглашения');

        // if (data?.id) {
        //   updateUserSchedule({ id: data?.id, data: timeWorkDataArr });
        // }
        await queryEmployee.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryEmployee.invalidateQueries({
          queryKey: [USERS_SCHEDULE_QUERY]
        });
        await queryEmployee.invalidateQueries({
          queryKey: [USER_SCHEDULE_QUERY]
        });
      }
    }
  });
}

export const useUserScheduleTemplate = (weeks: number) => {
  const queryEmployee = useQueryClient();
  const user = getCookie('id');
  const { mutateAsync } = useMutation({
    mutationKey: ['update user schedule'],
    mutationFn: (data: IFilialTimeWork) => UserService.updateUserScheduleWeeks(user || '', data, weeks),
    async onSuccess() {
      await queryEmployee.invalidateQueries({
        queryKey: [USER_PROFILE_QUERY, user]
      });
      await queryEmployee.invalidateQueries({ queryKey: [USERS_QUERY] });
    }
  });

  return { mutateAsync };
};

export function useDeleteUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => UserService.deleteEmployeer(id),
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryClient.invalidateQueries({
          queryKey: [USERS_SCHEDULE_QUERY]
        });
      }
    }
  });
}

export function useUpdateProfileUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUser) => UserService.updateUser(data),
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USER_PROFILE_QUERY] });
      }
    }
  });
}

export function useUpdateUserSchedule() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, data }: { id: string; data: IUserSchedule[] }) => UserService.updateUserSchedule(id, data),

    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryClient.invalidateQueries({
          queryKey: [USERS_SCHEDULE_QUERY]
        });
        await queryClient.invalidateQueries({ queryKey: [USER_SCHEDULE_QUERY] });
      }
    }
  });
}

export const useCopyScheduleTemplate = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: ({ id, data }: { id: string; data: IUserScheduleTemplate }) => UserService.updateUserScheduleTemplate(id, data),
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        // await queryClient.invalidateQueries({
        //   queryKey: [USERS_SCHEDULE_QUERY]
        // });
      }
    }
  });

  return { mutateAsync };
};
export function useUserChangePassword() {
  const queryEmployee = useQueryClient();

  return useMutation({
    mutationFn: (data: IUserChangePassword) => UserService.changePassword(data),

    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        toast.success('Пароль изменен');
        await queryEmployee.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryEmployee.invalidateQueries({
          queryKey: [USERS_SCHEDULE_QUERY]
        });
        await queryEmployee.invalidateQueries({
          queryKey: [USER_SCHEDULE_QUERY]
        });
      }
    }
  });
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUser) => UserService.updateUser(data),
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [USERS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USERS_SCHEDULE_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [USER_SCHEDULE_QUERY] });
      }
    }
  });
}
