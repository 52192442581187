import React from 'react';

export function UserIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 11.9999C21.9986 14.4016 21.1329 16.7226 19.5612 18.5385C17.9894 20.3545 15.8166 21.5441 13.44 21.8899C12.4856 22.0366 11.5144 22.0366 10.56 21.8899C8.66341 21.6139 6.88608 20.7987 5.43959 19.5414C3.99311 18.2841 2.93842 16.6375 2.40107 14.7978C1.86373 12.9581 1.86637 11.0028 2.40869 9.16453C2.951 7.3263 4.01014 5.68262 5.46001 4.42919C6.90989 3.17576 8.68942 2.36537 10.5867 2.09451C12.4841 1.82366 14.4193 2.10373 16.162 2.9014C17.9046 3.69907 19.3814 4.98072 20.4164 6.59378C21.4514 8.20684 22.0011 10.0834 22 11.9999ZM17.84 17.46C17.0917 18.2593 16.1872 18.8965 15.1827 19.3321C14.1782 19.7677 13.0949 19.9924 12 19.9924C10.9051 19.9924 9.82179 19.7677 8.81726 19.3321C7.81272 18.8965 6.90829 18.2593 6.15997 17.46C6.76997 15.46 9.15997 14 12 14C14.84 14 17.23 15.46 17.84 17.46ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z'
        fill='currentColor'
        fillOpacity='0.5'
      />
    </svg>
  );
}
