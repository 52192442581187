export const PaymentIcon24 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.0938 3.9375H4.4375V16.5938H17.0938V3.9375Z'
        stroke='currentColor'
      />
      <path
        d='M32.5625 20.8125H21.3125V32.0625H32.5625V20.8125Z'
        stroke='currentColor'
      />
      <path
        d='M31.1562 15.1875H22.7188V6.75H31.1562V15.1875Z'
        stroke='currentColor'
      />
      <path
        d='M15.2543 30.4398V22.4355H7.25V30.4398H15.2543Z'
        stroke='currentColor'
      />
      <path
        d='M15.2588 26.4375H21.3126'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M26.9375 20.8125V15.1875'
        stroke='currentColor'
      />
      <path
        d='M22.7188 10.9688H17.0938'
        stroke='currentColor'
      />
      <path
        d='M10.7656 11.6719H13.5781'
        stroke='currentColor'
      />
    </svg>
  );
};
