export function ArrowCalendarNext() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.9038 25.7275C11.6478 25.7275 11.4345 25.6422 11.2638 25.4715C11.0931 25.3009 11.0078 25.1089 11.0078 24.8955C11.0078 24.6822 11.0931 24.5329 11.2638 24.4475L19.0718 16.1275L11.2638 7.93554C11.0931 7.76487 11.0078 7.57287 11.0078 7.35954C11.0078 7.1462 11.0931 6.9542 11.2638 6.78354C11.4345 6.61287 11.6265 6.52754 11.8398 6.52754C12.0531 6.52754 12.2451 6.61287 12.4158 6.78354L20.7358 15.6155C20.9065 15.7862 20.9918 15.9782 20.9918 16.1915C20.9918 16.4049 20.9065 16.5969 20.7358 16.7675L12.4158 25.4715C12.2451 25.6422 12.0745 25.7275 11.9038 25.7275Z'
        fill='currentColor'
      />
    </svg>
  );
}
