export function Arrow2SquarepathOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.0001 5.9C11.0001 5.40294 11.4031 5 11.9001 5L12.7391 5C13.8261 5 14.6969 5 15.4006 5.0575C16.1233 5.11654 16.7488 5.24068 17.3247 5.53408C18.2467 6.00387 18.9963 6.75347 19.4661 7.67546C19.7595 8.25131 19.8836 8.87687 19.9426 9.59953C20.0001 10.3033 20.0001 11.174 20.0001 12.2611V15.8242L21.4436 14.2845C21.7836 13.9218 22.3531 13.9035 22.7157 14.2434C23.0784 14.5834 23.0967 15.1529 22.7568 15.5156L19.7626 18.7093C19.7542 18.7185 19.7455 18.7275 19.7366 18.7364C19.6627 18.8104 19.5788 18.869 19.4892 18.9119C19.2431 19.0298 18.9557 19.0289 18.7112 18.9119C18.6216 18.869 18.5378 18.8105 18.4639 18.7365C18.455 18.7276 18.4462 18.7185 18.4378 18.7093L15.4436 15.5156C15.1037 15.1529 15.122 14.5834 15.4847 14.2434C15.8473 13.9035 16.4168 13.9218 16.7568 14.2845L18.2001 15.824V12.3C18.2001 11.1651 18.1994 10.368 18.1486 9.74611C18.0987 9.13476 18.0048 8.77245 17.8622 8.49264C17.565 7.90934 17.0908 7.4351 16.5075 7.13789C16.2277 6.99533 15.8654 6.90147 15.254 6.85152C14.6322 6.80071 13.8351 6.80001 12.7001 6.8L11.9001 6.8C11.4031 6.8 11.0001 6.39705 11.0001 5.9Z'
        fill='currentColor'
      />
      <path
        d='M1.24342 8.48446C0.903462 8.84708 0.921835 9.41663 1.28446 9.75659C1.64708 10.0965 2.21663 10.0782 2.55659 9.71555L4.00012 8.17578L4.00012 11.7389C4.00011 12.826 4.00011 13.6967 4.05761 14.4004C4.11665 15.1231 4.24078 15.7487 4.53419 16.3245C5.00397 17.2465 5.75358 17.9961 6.67558 18.4659C7.25142 18.7593 7.87699 18.8834 8.59966 18.9425C9.30342 19 10.1741 19 11.2611 18.9999L13.1001 18.9999C13.5972 18.9999 14.0001 18.597 14.0001 18.0999C14.0001 17.6029 13.5972 17.1999 13.1001 17.1999L11.3001 17.1999C10.1652 17.1999 9.36809 17.1992 8.74623 17.1484C8.13487 17.0985 7.77256 17.0046 7.49276 16.8621C6.90945 16.5649 6.43521 16.0906 6.138 15.5073C5.99544 15.2275 5.90158 14.8652 5.85163 14.2539C5.80082 13.632 5.80012 12.8349 5.80012 11.6999L5.80012 8.17603L7.24342 9.71555C7.58338 10.0782 8.15293 10.0965 8.51555 9.75659C8.87817 9.41663 8.89654 8.84708 8.55659 8.48446L5.56244 5.2907C5.55396 5.28149 5.54524 5.27242 5.53631 5.2635C5.4624 5.18953 5.37857 5.13103 5.28897 5.08813C4.9528 4.92696 4.53936 4.98769 4.2638 5.2634C4.25482 5.27236 4.24607 5.28147 4.23755 5.29072L1.24342 8.48446Z'
        fill='currentColor'
      />
    </svg>
  );
}
