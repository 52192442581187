export const PaymentIcon26 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24801)'>
        <path
          d='M1.25 15.75V18.75C1.25 19.1478 1.40804 19.5294 1.68934 19.8107C1.97064 20.092 2.35218 20.25 2.75 20.25H5.75'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.75 0.75H2.75C2.35218 0.75 1.97064 0.908035 1.68934 1.18934C1.40804 1.47064 1.25 1.85218 1.25 2.25V5.25'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.75 5.20508V2.20508C29.75 1.80725 29.592 1.42572 29.3107 1.14442C29.0294 0.863113 28.6478 0.705078 28.25 0.705078H25.25'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.75 5.25V15'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.25 5.25V15'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.75 5.25V11.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.25 5.25V11.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.25 5.25V11.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.75 5.25V11.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.25 5.25V11.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.75 15V14.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.75 15V14.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.25 15V14.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.25 15V14.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.25 15V14.25'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.25 25.545C19.25 27.3471 19.9659 29.0755 21.2402 30.3498C22.5145 31.6241 24.2429 32.34 26.045 32.34C27.8471 32.34 29.5755 31.6241 30.8498 30.3498C32.1241 29.0755 32.84 27.3471 32.84 25.545C32.84 23.7429 32.1241 22.0145 30.8498 20.7402C29.5755 19.4659 27.8471 18.75 26.045 18.75C24.2429 18.75 22.5145 19.4659 21.2402 20.7402C19.9659 22.0145 19.25 23.7429 19.25 25.545Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.75 35.25L30.875 30.375'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24801'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
