import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientsState, IClient, IClientClassificator } from './clients.interface';
import { FULFILLED, INIT, PENDING, REJECTED } from '../../../utils/state';
import { fetchClientsAsync, setClientClassificators, setClients, setClientsState } from './clients.actions';

const initialState: ClientsState = {
  clients: [],
  clientClassificators: [],
  importResults: [],
  state: INIT
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setImportResults: (state, action: PayloadAction<any>) => {
      state.importResults = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setClients, (state, action: PayloadAction<IClient[]>) => {
        state.clients = action.payload;
      })
      .addCase(setClientClassificators, (state, action: PayloadAction<IClientClassificator[]>) => {
        state.clientClassificators = action.payload;
      })
      .addCase(setClientsState, (state, action: PayloadAction<ClientsState>) => {
        state.state = action.payload.state;
        if (action.payload.clients) {
          state.clients = action.payload.clients;
        }
      })
      .addCase(fetchClientsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchClientsAsync.fulfilled, (state, action) => {
        state.clients = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchClientsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer, actions } = clientsSlice;
export const { setImportResults } = actions;
