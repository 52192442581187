export const ArrowBackNewIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.1698 4.89687C15.2978 4.89687 15.4258 4.96087 15.5538 5.08887C15.6818 5.21688 15.7458 5.36088 15.7458 5.52087C15.7458 5.68087 15.6818 5.82487 15.5538 5.95288L9.69781 12.0969L15.5538 18.3369C15.6818 18.4009 15.7458 18.5129 15.7458 18.6729C15.7458 18.8329 15.6818 18.9769 15.5538 19.1049C15.4258 19.2329 15.2818 19.2969 15.1218 19.2969C14.9618 19.2969 14.8178 19.2329 14.6898 19.1049L8.44981 12.5769C8.32181 12.4489 8.25781 12.3049 8.25781 12.1449C8.25781 11.9849 8.32181 11.8409 8.44981 11.7129L14.6898 5.08887C14.8178 4.96087 14.9778 4.89687 15.1698 4.89687Z'
        fill='currentColor'
      />
    </svg>
  );
};
