import styled from 'styled-components';

import { RADIUS_M } from './assets';
import { DURATION } from './duration';

type CircleProps = {
  size?: '17' | '24' | '32' | '36' | '40' | '48' | '64' | '76' | '80' | '96' | '128' | 'full';
  $font?: string;
  $background?: 'greyGradient' | 'blue' | 'thinBlue' | 'secondary' | 'ghost' | 'success' | 'danger' | 'white' | 'orange' | 'gray';
  color?: 'grey' | 'white' | 'primary' | 'gradient';
  $colorSvg?: 'blue' | 'white' | 'danger' | 'infoFill';
  $rounded?: 'full' | 'small';
};

export const CircleWrapper = styled.div<CircleProps>`
  width: ${(props) => props.size || 40}px;
  min-width: ${(props) => props.size || 40}px;
  height: ${(props) => props.size || 40}px;
  min-height: ${(props) => props.size || 40}px;
  border-radius: ${(props) => {
    switch (props.$rounded) {
      case 'full':
        return props.$rounded ? '50%' : '50%';
      case 'small':
        return props.$rounded ? RADIUS_M : '50%';
      default:
        return '50%';
    }
  }};
  font-weight: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case '36':
          return props.size ? '600' : '700';
        default:
          return '400';
      }
    }
  }};
  //font-size: 16px;
  font-size: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case '128':
          return props.size ? '64px' : '16px';
        case '96':
          return props.size ? '48px' : '16px';
        case '76':
          return props.size ? '28px' : '16px';
        case '64':
          return props.size ? '32px' : '16px';
        case '48':
          return props.size ? '24px' : '16px';
        case '40':
          return props.size ? '16px' : '16px';
        case '36':
          return props.size ? '16px' : '16px';
        case '32':
          return props.size ? '16px' : '16px';
        case '24':
          return props.size ? '12px' : '16px';
        case '17':
          return props.size ? '10px' : '16px';
        default:
          return '16px';
      }
    }
  }};
  display: flex;
  position: relative;
  overflow: hidden;
  user-select: none;
  align-items: center;
  justify-content: center;
  color: ${(props) => {
    if (props.color !== undefined) {
      switch (props.color) {
        case 'white':
          return props.color ? props.theme.color.base01 : 'transparent';
        case 'grey':
          return props.color ? props.theme.newColor.grey06 : 'transparent';
        case 'primary':
          return props.color ? props.theme.text.link : 'transparent';
        default:
          return props.theme.newColor.primary;
      }
    } else {
      return props.theme.newColor.primary;
    }
  }};
  background: ${(props) => {
    if (props.$background !== undefined) {
      switch (props.$background) {
        case 'greyGradient':
          return props.$background ? props.theme.newColor.baseGradient : 'transparent';
        case 'blue':
          return props.$background ? props.theme.color.primary : 'transparent';
        case 'gray':
          return props.$background ? 'linear-gradient(225deg, #EEEEEE 0%, #D8D8D8 100%)' : 'transparent';
        case 'white':
          return props.$background ? props.theme.color.base01 : 'transparent';
        case 'orange':
          return props.$background ? props.theme.color.accent : 'transparent';
        case 'thinBlue':
          return props.$background ? props.theme.status.infoBg : 'transparent';
        case 'secondary':
          return props.$background ? props.theme.color.secondary : 'transparent';
        case 'ghost':
          return 'transparent';
        case 'success':
          return props.$background ? props.theme.status.successBg : 'transparent';
        case 'danger':
          return props.$background ? props.theme.status.errorBg : 'transparent';
        default:
          return 'transparent';
      }
    }
  }};
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > svg {
    height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case '128':
            return props.size ? '64px' : '15px';
          case '96':
            return props.size ? '48px' : '15px';
          case '76':
            return props.size ? '76px' : '15px';
          case '64':
            return props.size ? '32px' : '15px';
          case '48':
            return props.size ? '24px' : '15px';
          case '40':
            return props.size ? '20px' : '15px';
          case '36':
            return props.size ? '36px' : '15px';
          case '32':
            return props.size ? '16px' : '15px';
          case '24':
            return props.size ? '12px' : '15px';
          case 'full':
            return props.size ? '100%' : '15px';
          default:
            return '15px';
        }
      }
    }};
    width: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case '128':
            return props.size ? '64px' : '15px';
          case '96':
            return props.size ? '48px' : '15px';

          case '76':
            return props.size ? '76px' : '15px';
          case '64':
            return props.size ? '32px' : '15px';
          case '48':
            return props.size ? '24px' : '15px';
          case '40':
            return props.size ? '20px' : '15px';
          case '32':
            return props.size ? '16px' : '15px';
          case '24':
            return props.size ? '12px' : '15px';
          default:
            return '15px';
        }
      }
    }};

    color: ${(props) => {
      if (props.$colorSvg !== undefined) {
        switch (props.$colorSvg) {
          // case 'thinBlue':
          //   return props.$background ? props.theme.color.primary : 'transparent';
          case 'danger':
            return props.$background ? props.theme.text.negative : 'transparent';
          case 'white':
            return props.$colorSvg ? props.theme.color.base01 : 'transparent';
          case 'blue':
            return props.$colorSvg ? props.theme.color.primary : 'transparent';
          case 'infoFill':
            return props.$background ? props.theme.color.primary : 'transparent';
          default:
            return props.theme.color.sidebarGray65;
        }
      } else {
        return props.theme.color.sidebarGray65;
      }
    }};
  }

  &.hovered {
    cursor: pointer;
    overflow: visible;
    &:hover {
      background: ${(props) => props.theme.status.infoBgHover};
    }
    > a {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  &.absolute {
    position: absolute;
    bottom: -10px;
    right: -10px;
  }
  /* img:not([src]) {
      display: none;
    } */
`;

export const AvatarWrapper = styled.div`
  position: relative;
  z-index: 6;
  border-radius: 50%;
  cursor: pointer;
  > div {
    background: ${(props) => props.theme.color.base03};

    svg {
      height: 100%;
      width: 100%;
      color: ${(props) => props.theme.color.base04};
    }
  }
  p {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.color.primary};
    display: none;
    z-index: 2;
  }
  &:before {
    content: '';
    border-radius: 50%;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: opacity ${DURATION} ease-in-out;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
    p {
      display: block;
    }
    button {
      display: flex;
    }
  }
  button {
    align-items: center;
    justify-content: center;
    width: 24px;
    display: none;
    height: 24px;
    right: -13px;
    top: -5px;
    position: absolute;
    svg {
      color: ${(props) => props.theme.text.text02};
    }
  }
`;
