import React, { FC, useEffect, useRef, useState } from 'react';

import { Wrapper, Separator } from './ContextMenu.styled';

interface IProps {
  children?: React.ReactNode;
  buttonX: number;
  buttonY: number;
}

const ContextMenu: FC<IProps> = ({ children, buttonX, buttonY }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleWindowMouseMove = (event: any) => {
      setCoords({
        x: event.clientX,
        y: event.clientY
      });
    };
    window.addEventListener('mousemove', handleWindowMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleWindowMouseMove);
    };
  }, []);

  return (
    <div ref={menuRef}>
      <Wrapper
        left={buttonX}
        top={buttonY}
      >
        {React.Children.map(children, (child, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Separator />}
            <div>{child}</div>
          </React.Fragment>
        ))}
      </Wrapper>
    </div>
  );
};

export default ContextMenu;
