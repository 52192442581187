export function ArrowUpRightOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.14945 5.90232C8.14945 5.40526 8.55239 5.00232 9.04945 5.00232H18.1004C18.3391 5.00232 18.5681 5.09719 18.7369 5.26606C18.9057 5.43493 19.0005 5.66395 19.0004 5.90271L18.9965 14.9029C18.9962 15.3999 18.5931 15.8027 18.0961 15.8025C17.599 15.8023 17.1962 15.3991 17.1965 14.9021L17.1994 8.07605L6.53676 18.7387C6.18529 19.0902 5.61544 19.0902 5.26397 18.7387C4.9125 18.3872 4.9125 17.8174 5.26397 17.4659L15.9276 6.80232H9.04945C8.55239 6.80232 8.14945 6.39938 8.14945 5.90232Z'
        fill='currentColor'
      />
    </svg>
  );
}
