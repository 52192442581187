import React from 'react';

export function CalendarAddOutline() {
  return (
    <svg
      width='19'
      height='20'
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.3111 1.00415C16.1641 0.706881 15.8578 0.502441 15.5038 0.502441C15.131 0.502441 14.8111 0.729097 14.6745 1.05212C14.6289 1.1598 14.6038 1.27818 14.6038 1.40244V3.20007H12.905C12.5322 3.20007 12.2124 3.42673 12.0757 3.74975C12.0302 3.85743 12.005 3.97581 12.005 4.10007C12.005 4.59713 12.4079 5.0001 12.905 5.0001H14.6038L14.6038 6.59778C14.6038 7.09483 15.0067 7.49778 15.5038 7.49778C15.7523 7.49778 15.9773 7.39703 16.1402 7.23415C16.303 7.07129 16.4038 6.84631 16.4038 6.5978L16.4038 5.0001H18.1027C18.3512 5.0001 18.5762 4.89935 18.7391 4.73647C18.9019 4.57361 19.0027 4.34863 19.0027 4.10012C19.0027 3.98543 18.9812 3.87571 18.9421 3.77484C18.8117 3.43853 18.485 3.20007 18.1027 3.20007H16.4038V1.40244C16.4038 1.25944 16.3704 1.12422 16.3111 1.00415Z'
        fill='currentColor'
      />
      <path
        d='M5.00071 0.502466C5.49776 0.502466 5.90071 0.90541 5.90071 1.40247V2.00637H9.50388C10.0009 2.00637 10.4039 2.40932 10.4039 2.90637C10.4039 3.40343 10.0009 3.80637 9.50388 3.80637H5.90071V4.59503C5.90071 5.09208 5.49776 5.49503 5.00071 5.49503C4.50365 5.49503 4.10071 5.09208 4.10071 4.59503V3.85693C3.57858 3.92791 3.13091 4.08122 2.7803 4.36336C2.29244 4.75595 1.80001 5.56087 1.8 7.37145L1.80002 8.00497H12.7344C13.2314 8.00497 13.6343 8.40791 13.6343 8.90497C13.6343 9.40202 13.2314 9.80497 12.7344 9.80497H1.80008L1.80026 15.1763C1.80026 16.0776 1.99238 16.8215 2.39918 17.3264C2.77665 17.7948 3.45374 18.1986 4.76046 18.1986H13.0913C14.1972 18.1986 14.9498 17.9059 15.4215 17.4613C15.8848 17.0247 16.1997 16.3257 16.1997 15.2499V9.83606C16.1997 9.339 16.6027 8.93606 17.0997 8.93606C17.5968 8.93606 17.9997 9.339 17.9997 9.83606V15.2499C17.9997 16.6861 17.5682 17.9115 16.6561 18.7712C15.7525 19.623 14.5009 19.9986 13.0913 19.9986H4.76046C3.04859 19.9986 1.79558 19.4461 0.997593 18.4558C0.228949 17.5019 0.000259459 16.2847 0.000255406 15.1764L0 7.37151C0 5.24417 0.588745 3.81651 1.65182 2.96103C2.40357 2.35609 3.29036 2.12486 4.10071 2.04466V1.40247C4.10071 0.90541 4.50365 0.502466 5.00071 0.502466Z'
        fill='currentColor'
      />
      <path
        d='M13.9839 14.5026C13.9839 15.331 13.3123 16.0026 12.4839 16.0026C11.6554 16.0026 10.9839 15.331 10.9839 14.5026C10.9839 13.6741 11.6554 13.0026 12.4839 13.0026C13.3123 13.0026 13.9839 13.6741 13.9839 14.5026Z'
        fill='currentColor'
      />
    </svg>
  );
}
