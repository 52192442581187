import { ChangeEventHandler, FC, memo } from 'react';
import { Content, Controls, Icons, Label, Placeholder, Scrollbar, TextareaWrapper, Wrapper } from './Textarea.styled';
import { cn } from '../../../lib';
export interface ITextareaProps {
  placeholder: string;
  content: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  name?: string;
}
export const Textarea: FC<ITextareaProps> = memo((props: ITextareaProps) => {
  const { placeholder, content, name, onChange } = props;

  return (
    <TextareaWrapper>
      <Label className={cn(content !== '' && 'filled')}>
        <Wrapper>
          <Placeholder className={'placeholder'}>{placeholder}</Placeholder>
          <Scrollbar>
            <Controls>
              <div className='bar'>
                <div className='thumb'></div>
              </div>
            </Controls>
            <Content>
              <div className='input-wrapper'>
                <div className='relative'>
                  <div
                    aria-hidden={true}
                    className='pseudo-content'
                  >
                    <span>{content}</span>
                    <span className='pseudo-content__extra'></span>
                    <span className='caret'></span>
                  </div>
                  <textarea
                    value={content}
                    tabIndex={0}
                    name={name}
                    onChange={onChange}
                  />
                </div>
              </div>
            </Content>
          </Scrollbar>
        </Wrapper>
        <Icons></Icons>
      </Label>
    </TextareaWrapper>
  );
});

Textarea.displayName = 'Textarea';
