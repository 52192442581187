import { useLayoutEffect, useState } from 'react';
import { IMatchedMedia, IMediaQuery } from '../../types/typed';

function useMatchMedia(queries: IMediaQuery, defaultValues: IMatchedMedia = []): IMatchedMedia {
  const initialValues = defaultValues.length ? defaultValues : Array(queries.length).fill(false);

  const mediaQueryLists = queries.map((q) => window.matchMedia(q));
  const getValue = (): IMatchedMedia => {
    const matchedQueries = mediaQueryLists.map((mql) => mql.matches);

    return matchedQueries;
  };

  const [value, setValue] = useState(getValue);

  useLayoutEffect(() => {
    const handler = (): void => setValue(getValue);
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    return (): void => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, []);

  return value;
}

export default useMatchMedia;
