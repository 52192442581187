import styled from 'styled-components';

export const EditFilialTimeWrapper = styled.div`
  height: 100%;
  max-width: 330px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
  }
  .input-wrap {
    //padding: 0 10px;
    gap: 6px;
    grid-template-columns: 1fr 0.17fr 1fr;
    align-items: center;
  }
  .input-divide {
    margin: 0 auto;
    display: inline-block;
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.color.base08};
  }
  .title-time-table {
    padding: 0 10px;
  }
  ul.react-datepicker__time-list {
    background-color: ${(props) => props.theme.color.base02};
  }
  .top-wrapper {
    padding: 16px;
    background-color: #f5f6f8;
    width: 100%;
    height: 44px;
    border-radius: 12px;
  }
  .chosen-text {
    font-size: 15px;
    line-height: 20px;
  }
  .reset-btn {
    color: ${(props) => props.theme.text.link};
    font-size: 15px;
    font-weight: 500;
    line-height: 20px; /* 115.385% */
  }
  form {
    gap: 30px;
  }
  > form {
    height: 100%;
  }

  .btns {
    width: 100%;
    button {
      width: 100%;
    }
  }
  .fitI {
    width: 100%;
    justify-content: space-between;
    position: relative;
    .btn {
      width: 100%;
      height: 40px;
      color: ${(props) => props.theme.color.secondaryDark};
      font-size: 13px;
      font-weight: 400;
      border-radius: 10px;
      border: 1px solid ${(props) => props.theme.color.secondaryDark};
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 15px;
      &.active {
        background: ${(props) => props.theme.color.accent};
        border-color: ${(props) => props.theme.color.accent};
        color: #fff;
      }
    }

    > span {
      width: 18px;
      height: 2px;
      background: #acb9cb;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  svg {
    font-size: 23px;
  }
`;

export const FilialTimeCheck = styled.div`
  display: flex;
  flex-direction: column;
  width: 35px;
  height: 35px;
  .btn,
  .btn.active {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 35px !important;
    height: 35px !important;
    padding-left: 0 !important;
  }
`;
