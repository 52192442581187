export function DoorArrowRightOutline() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5 3C14.5 2.44772 14.9477 2 15.5 2H19.5C20.2957 2 21.0587 2.31607 21.6213 2.87868C22.1839 3.44129 22.5 4.20435 22.5 5V19C22.5 19.7957 22.1839 20.5587 21.6213 21.1213C21.0587 21.6839 20.2957 22 19.5 22H15.5C14.9477 22 14.5 21.5523 14.5 21C14.5 20.4477 14.9477 20 15.5 20H19.5C19.7652 20 20.0196 19.8946 20.2071 19.7071C20.3946 19.5196 20.5 19.2652 20.5 19V5C20.5 4.73478 20.3946 4.48043 20.2071 4.29289C20.0196 4.10536 19.7652 4 19.5 4H15.5C14.9477 4 14.5 3.55228 14.5 3ZM9.79289 6.29289C10.1834 5.90237 10.8166 5.90237 11.2071 6.29289L16.2071 11.2929C16.5976 11.6834 16.5976 12.3166 16.2071 12.7071L11.2071 17.7071C10.8166 18.0976 10.1834 18.0976 9.79289 17.7071C9.40237 17.3166 9.40237 16.6834 9.79289 16.2929L13.0858 13H3.5C2.94772 13 2.5 12.5523 2.5 12C2.5 11.4477 2.94772 11 3.5 11H13.0858L9.79289 7.70711C9.40237 7.31658 9.40237 6.68342 9.79289 6.29289Z'
        fill='currentColor'
      />
    </svg>
  );
}
