export function RepeatOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.00464 12C2.00464 8.74156 4.6471 6.10004 7.90674 6.10004H17.9327L17.3689 5.5364C17.0173 5.18492 17.0173 4.61508 17.3689 4.2636C17.7205 3.91213 18.2905 3.91213 18.6421 4.2636L20.7429 6.36358C21.0945 6.71505 21.0945 7.2849 20.7429 7.63637L18.6421 9.73635C18.2905 10.0878 17.7205 10.0878 17.3689 9.73635C17.0173 9.38488 17.0173 8.81503 17.3689 8.46355L17.9326 7.90004H7.90674C5.64157 7.90004 3.80528 9.73567 3.80528 12V12.0967C3.80528 12.5938 3.40219 12.9967 2.90496 12.9967C2.40773 12.9967 2.00464 12.5938 2.00464 12.0967V12Z'
        fill='currentColor'
      />
      <path
        d='M21.1043 11.0033C21.6016 11.0033 22.0046 11.4063 22.0046 11.9033V12C22.0046 15.2585 19.3622 17.9 16.1025 17.9H6.07414L6.63786 18.4636C6.98945 18.815 6.98945 19.3849 6.63786 19.7363C6.28626 20.0878 5.71621 20.0878 5.36461 19.7363L3.26389 17.6364C2.91229 17.2849 2.91229 16.7151 3.26389 16.3636L5.36461 14.2636C5.71621 13.9121 6.28626 13.9121 6.63786 14.2636C6.98945 14.6151 6.98945 15.1849 6.63786 15.5364L6.07402 16.1H16.1025C18.3677 16.1 20.204 14.2644 20.204 12V11.9033C20.204 11.4063 20.6071 11.0033 21.1043 11.0033Z'
        fill='currentColor'
      />
    </svg>
  );
}
