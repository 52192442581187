import React from 'react';

export function CirclesEmpty() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='10'
        cy='10'
        r='9.95'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.1'
      />
      <circle
        cx='9.92'
        cy='9.92'
        r='8.42'
        fill='#F5F7F9'
      />
      <circle
        cx='10'
        cy='10'
        r='3.75'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </svg>
  );
}
