import styled from 'styled-components';

import {
  DURATION,
  HEIGHT_INPUT_M,
  MODAL_GAP,
  MODAL_TEXT_GAP,
  RADIUS_M,
  SHADOW_INPUT,
  SHADOW_INPUT_HOGER,
  SHADOW_NAVIGATION
} from '../../../../../../../styles';
import { FlexWithAlign } from '../../../../../../../utils/styleUtils';

export const RecordingModalSideWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${MODAL_TEXT_GAP};
`;

export const TableRecordingRow = styled(FlexWithAlign)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  height: 64px;
  padding-inline: 20px;
  position: relative;
  min-height: 64px;
  background: ${(props) => props.theme.color.inverse};
  transition: 0.3s ease;
  @media (max-width: 767px) {
    padding-inline: 0;
    height: 48px;
    min-height: 48px;
  }
  &:before {
    content: '';
    background: ${(props) => props.theme.color.base03};
    width: calc(100% - 40px);
    margin: 0 auto;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.color.secondary};
  }
`;

export const RecordingSideClients = styled(FlexWithAlign)`
  overflow-y: auto;
  width: 100%;
  max-height: calc(64px * 5);
  min-height: calc(64px * 5);
  height: 100%;
  border-radius: ${RADIUS_M};
  gap: 0;
  transition: ${DURATION} ease-in-out;
  &::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6.25rem;
    border: 0.25rem solid transparent;
    opacity: 0.2;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.color.base04};
  }
  &:hover::-webkit-scrollbar-track {
    background: transparent;
  }
  &.client-card {
    padding: 2rem;
    box-shadow: ${SHADOW_NAVIGATION};
    max-height: 270px;
    min-height: 270px;
    @media (max-width: 767px) {
      padding: 1rem;
    }
  }
  @media (max-width: 767px) {
    max-height: calc(64px * 3);
    min-height: calc(64px * 3);
  }
`;

export const Calculate = styled.div`
  width: 100%;
  min-width: 210px;
  max-width: 300px;
  display: flex;
  align-items: center;
  height: ${HEIGHT_INPUT_M};
  justify-content: flex-end;
  gap: 4px;
  .input-clients {
    label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  > div {
    height: ${HEIGHT_INPUT_M};
    justify-content: space-between;
    button {
      position: relative;
      height: 20px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: ${SHADOW_INPUT};
      transition-property: background, box-shadow;
      transition-timing-function: ease-in-out;
      transition-duration: ${DURATION};
      svg {
        width: 20px;
        height: 20px;
        color: ${(props) => props.theme.color.primary};
      }
      &:hover {
        box-shadow: ${SHADOW_INPUT_HOGER};
        background: ${(props) => props.theme.status.infoBgHover};
      }
      background: ${(props) => props.theme.status.infoBg};
      &:first-child {
        border-top-right-radius: ${RADIUS_M};
      }
      &:last-child {
        border-bottom-right-radius: ${RADIUS_M};
      }
    }
  }
`;

export const RecordingSideGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 2.1fr 2fr);
  padding-block: 10px;
  gap: ${MODAL_GAP};
  border-bottom: 1px solid ${(props) => props.theme.color.base03};
  .viber {
    color: #7360f2;
  }
  .telegram {
    color: #496fff;
  }
  .whatsapp {
    color: ${(props) => props.theme.color.success};
  }
`;

export const ClassificatorContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding-inline: 10px;
  border-radius: ${RADIUS_M};
`;

export const ClassificatorClientWrapper = styled(FlexWithAlign)`
  @media (max-width: 767px) {
    max-width: 200px;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
