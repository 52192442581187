import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  .tarif-payment-btn {
    margin-top: auto;
  }
  .label {
    font-weight: 600;
    line-height: 24px;
    color: ${(props) => props.theme.color.secondaryGrey};
  }
  .value {
    font-weight: 600;
    line-height: 24px;
  }
  .users,
  .payment-methods,
  .current-tarif {
    gap: 8px;
  }
`;

export const TarifTopbar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h2 {
    font-size: 32px;
  }
`;

export const TimeEndWrap = styled.div`
  padding: 4px;
  gap: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.color.secondaryBlue};
  width: fit-content;
`;
export const Form = styled.form`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  #countUsers {
    opacity: 0;
    &.active,
    &:focus {
      opacity: 1;
    }
  }
`;

export const ModuleItem = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 0.5fr;
  gap: 12px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.color.secondaryBlue};
`;
export const ModuleLabel = styled.label`
  position: relative;
  width: fit-content;
  justify-self: flex-end;
`;
export const ModuleInput = styled.input`
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  &:checked + .switch-btn {
    background-color: ${(props) => props.theme.color.active};
    border-color: ${(props) => props.theme.color.active};
    .circle {
      transform: translateX(100%);
    }
  }
  &:disabled + .switch-btn {
    opacity: 0.5;
  }
`;
export const TimeEndModule = styled.div`
  width: fit-content;
`;
export const SwitchButton = styled.button`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.color.disabled};
  width: 36px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.color.disabled};
  .circle {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.color.base01};
    transition: all 0.3s ease;
  }
`;

export const Total = styled.div`
  margin-top: 50px;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.color.secondaryBlue};
  width: fit-content;
  span {
    font-size: 22px;
  }
`;
