import React from 'react';

export function UserAdded() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse
        cx='7.4809'
        cy='3.99994'
        rx='2.77778'
        ry='2.99994'
        stroke='#292F51'
        strokeWidth='2'
      />
      <path
        d='M1 15.9996C1 12.686 3.90186 9.99976 7.48148 9.99976C8.66204 9.99976 9.76888 10.2919 10.7222 10.8024'
        stroke='#292F51'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M14.333 17.6769H20.9997M17.6663 13.8V20.9999'
        stroke='#292F51'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
