import { ReactNode } from 'react';

export enum TextType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  SPAN = 'span'
}

export enum TextPType {
  P19 = 'p19',
  P17 = 'p17',
  P16 = 'p16',
  P15 = 'p15',
  P14 = 'p14',
  P13 = 'p13',
  P11 = 'p11',
  P9 = 'p9'
}

export enum TextCustomType {
  T28 = 't28',
  T24 = 't19',
  T18 = 't18',
  T16 = 't16',
  T14 = 't14',
  T12 = 't12',
  T10 = 't10'
}

export interface TextProps {
  children?: ReactNode;
  $tag?: TextType;
  small?: boolean;
  muted?: boolean;
  className?: string;
  fontFamily?: string;
  lineHeight?: string;
  color?: 'primary' | 'accent' | 'text02' | 'text05' | 'base01' | 'grey' | 'black' | 'positive' | 'negative';
  fontWeight?: 100 | 400 | 500 | 600 | 700 | 800 | 900;
  $pSize?: TextPType;
  $customSize?: TextCustomType;
}
