import { ICreateSaleProduct, IUpdateSaleProduct } from '../../../../store/redux/saleproducts/saleproducts.interface';
import { apiDelete, apiGet, apiPost, apiPut } from '../../interceptors';

export const SaleproductsService = {
  async fetchSaleproducts() {
    const response = await apiGet('/saleproducts');

    return response.data && response.data.tovars ? response.data.tovars : [];
  },

  async fetchSaleProductById(id: string) {
    const response = await apiGet(`/saleproduct/${id}`);

    return response.data && response.data.tovars ? response.data.tovars : [];
  },
  async createSaleProduct(values: ICreateSaleProduct) {
    const response = await apiPut('/saleproduct', values);

    return response.data;
  },
  async updateSaleProduct(values: IUpdateSaleProduct) {
    const { id, ...data } = values;
    const response = await apiPost(`/saleproduct/${id}`, data);

    return response.data;
  },
  async deleteSaleProduct(id: string) {
    const response = await apiDelete(`/saleproduct/${id}`);

    return response.data;
  }
};
