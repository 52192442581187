import InputMask from 'react-input-mask';

import styled from 'styled-components';

import themelight from '../../../../app/providers/ThemeProvider/ui/theme';
import { DURATION, RADIUS_M } from '../../../../styles';
import { NEW_INPUT_BORDER_RADIUS, NEW_INPUT_FONT_SIZE, NEW_INPUT_HEIGHT, NEW_INPUT_PADDING } from '../../../../styles/newAssets';

export interface IInputStyles {
  size?: 'l' | 'm' | 'sm' | 's';
}

export const InputContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;
  appearance: none;
  transition: height ${DURATION} ease-in-out;
  border-radius: ${RADIUS_M};
  text-align: left;
  height: ${NEW_INPUT_HEIGHT};
  min-height: ${NEW_INPUT_HEIGHT};
  max-height: ${NEW_INPUT_HEIGHT};
  &.error {
    height: calc(0.25rem + 20px + ${NEW_INPUT_HEIGHT});
    min-height: calc(0.25rem + 20px + ${NEW_INPUT_HEIGHT});
    max-height: calc(0.25rem + 20px + ${NEW_INPUT_HEIGHT});
  }
  &.focused {
    label span::after {
    }
  }
`;
export const InputContent = styled(InputMask)<IInputStyles>`
  padding: ${NEW_INPUT_PADDING};
  background-color: ${themelight.newColor.base01};
  inset: 0;
  margin: 0;
  border: 1px solid ${themelight.newColor.base08};
  border-radius: ${NEW_INPUT_BORDER_RADIUS};
  font-size: ${NEW_INPUT_FONT_SIZE};
  color: ${themelight.newColor.primary};
  line-height: 1.5;
  font-weight: 700;
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  -webkit-text-fill-color: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  text-indent: 0px;
  text-align: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  resize: none;
  transition-property: border-color;
  transition-duration: ${DURATION};
  &.active {
  }
  &.currency {
    padding-left: 48px;
  }
  &.with-icon {
    padding-left: 65px;
  }
  &.with-clear-btn {
    padding-right: 32px;
  }
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  &:focus {
    border-color: ${themelight.newColor.primary};
    &::placeholder {
      opacity: 0;
    }
  }
  &.error {
    border-color: ${themelight.newColor.error};
  }
  &:disabled {
    background-color: ${themelight.newColor.base05};
    color: ${themelight.newColor.grey06};
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  &[type='number']::-webkit-textfield-decoration-container {
  }
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-datetime-edit-year-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field {
    color: transparent;
  }
`;

export const CurrencyPreffix = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  z-index: 4;
  width: 38px;
  height: 24px;
  display: flex;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${themelight.newColor.base08};
  &:before {
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 0;
    background: ${themelight.newText.textGrey};
  }
`;

export const Label = styled.label<IInputStyles>`
  width: 100%;
  max-width: 100%;
  display: block;
  transition-property: background;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  background: transparent;
  color: ${themelight.newText.textGrey};

  position: relative;
  appearance: none;
  border-radius: ${NEW_INPUT_BORDER_RADIUS};
  text-align: left;
  line-height: 1.5;
  height: ${NEW_INPUT_HEIGHT};
  min-height: ${NEW_INPUT_HEIGHT};
  max-height: ${NEW_INPUT_HEIGHT};

  &.error {
    span {
      color: ${themelight.newColor.error};
    }
  }

  &:focus-within {
    span {
      top: 0;
      position: absolute;
      font-size: 10px;
      text-transform: uppercase;
    }
    .currencyRubble {
      visibility: visible;
    }
    .currencySymbol {
      color: ${themelight.newText.textTitle};
    }
  }

  span {
    padding: 0 4px;
    position: absolute;
    top: 50%;
    color: ${themelight.newText.textGrey};
    transform: translateY(-50%);
    left: 1rem;
    pointer-events: none;
    font-size: ${NEW_INPUT_FONT_SIZE};
    line-height: 1.4;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    transition-property: transform, top, font;
    background-color: transparent;

    z-index: 1;
    &::after {
      z-index: -1;
      content: '';
      position: absolute;
      top: 46%;
      left: -3px;
      right: -3px;
      width: calc(100% + 6px);
      height: 3px;
      background-color: ${themelight.newColor.base01};
    }
  }
  &.with-icon {
    span {
      left: 65px;
    }
  }
  &.active,
  &.error {
    span {
      top: 0;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1.4;
      &::after {
        // height: 2px;
      }
    }
    .currencyRubble {
      visibility: visible;
    }
    .currencySymbol {
      color: ${themelight.newText.textTitle};
    }
  }
  &.disabled {
    .currencyRubble::before {
      background-color: ${themelight.newColor.base08};
    }
    .currencySymbol {
      color: ${themelight.newColor.grey06};
    }
    span::after {
      height: 1px;
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 53px;
  margin-left: auto;
  justify-content: center;
  padding: 0 8px;
  outline: none !important;
  z-index: 3;
  align-items: center;
  color: ${themelight.newColor.grey08};
  border-right: 1px solid ${themelight.newColor.base08};
  svg {
    display: flex;
  }
  &.active {
    svg {
    }
  }
  &.active.error,
  &.error {
    svg {
      color: ${themelight.newColor.error};
    }
  }
`;

export const ErrorText = styled.p<IInputStyles>`
  position: relative;
  margin-top: 0.25rem;
  width: 90%;
  white-space: pre-wrap;
  display: block;
  color: ${themelight.newColor.error};
  word-wrap: break-word;
  animation: present 1s infinite;
  left: 5px;
  font-size: 12px;
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  display: flex;
  color: ${themelight.newColor.primary};
  z-index: 1;
  svg {
    width: 16px;
    height: 16px;
  }
`;
