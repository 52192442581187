export function StatisticsOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.09998 19C2.09998 18.5029 2.50292 18.1 2.99998 18.1H21C21.497 18.1 21.9 18.5029 21.9 19C21.9 19.497 21.497 19.9 21 19.9H2.99998C2.50292 19.9 2.09998 19.497 2.09998 19Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.5 5.89998C16.0029 5.89998 15.6 5.49703 15.6 4.99998C15.6 4.50292 16.0029 4.09998 16.5 4.09998H21C21.497 4.09998 21.9 4.50292 21.9 4.99998V9.49998C21.9 9.99703 21.497 10.4 21 10.4C20.5029 10.4 20.1 9.99703 20.1 9.49998V7.17277L14.1364 13.1364C13.7849 13.4878 13.2151 13.4878 12.8636 13.1364L9.49998 9.77277L3.63637 15.6364C3.2849 15.9878 2.71505 15.9878 2.36358 15.6364C2.01211 15.2849 2.01211 14.7151 2.36358 14.3636L8.86358 7.86358C9.21505 7.51211 9.7849 7.51211 10.1364 7.86358L13.5 11.2272L18.8272 5.89998H16.5Z'
        fill='currentColor'
      />
    </svg>
  );
}
