export const PaymentIcon5 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.6875 15.1875H10.0625V9.5625H15.6875V15.1875Z'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M26.9375 15.1875H21.3125V9.5625H26.9375V15.1875Z'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M15.6875 26.4375H10.0625V20.8125H15.6875V26.4375Z'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M4.4375 13.7812V3.9375H14.2812'
        stroke='currentColor'
      />
      <path
        d='M4.4375 22.2188V32.0625H14.2812'
        stroke='currentColor'
      />
      <path
        d='M22.7188 3.9375H32.5625V13.7812'
        stroke='currentColor'
      />
      <path
        d='M22.7188 32.0625H32.5625V22.2188'
        stroke='currentColor'
      />
      <path
        d='M28.6953 26.4375H21.3125V19.4062'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M26.9375 19.4062V22.2188'
        stroke='currentColor'
        strokeWidth='3'
      />
    </svg>
  );
};
