import {
  maskitoAddOnFocusPlugin,
  maskitoCaretGuard,
  maskitoDateOptionsGenerator,
  maskitoEventHandler,
  maskitoNumberOptionsGenerator,
  maskitoPrefixPostprocessorGenerator,
  maskitoRemoveOnBlurPlugin,
  maskitoTimeOptionsGenerator
} from '@maskito/kit';
import { MaskitoOptions, MaskitoPreprocessor, maskitoUpdateElement } from '@maskito/core';

export const timeMask = maskitoTimeOptionsGenerator({
  mode: 'HH:MM'
});
const dateMask: MaskitoOptions = maskitoDateOptionsGenerator({
  mode: 'dd/mm/yyyy'
});
const decimalMask: MaskitoOptions = maskitoNumberOptionsGenerator({
  decimalSeparator: ',',
  thousandSeparator: '.',
  precision: 2
});

export const postfix = '₽';
export const { plugins, ...numberOptions } = maskitoNumberOptionsGenerator({
  postfix,
  precision: 2
});

export const currencyMask: MaskitoOptions = {
  ...numberOptions,
  plugins: [
    ...plugins,
    maskitoAddOnFocusPlugin(' ₽'),
    maskitoRemoveOnBlurPlugin(' ₽'),

    maskitoCaretGuard((value) => [0, value.length - 1]),
    maskitoEventHandler('blur', (element) => {
      const postfix = ' ₽';
      if (element.value === postfix) {
        maskitoUpdateElement(element, `0${postfix}`);
      }
    })
  ]
};

export const phoneMask = {
  mask: ['+', '7', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
  postprocessors: [
    // non-removable country prefix
    maskitoPrefixPostprocessorGenerator('+7 ')
  ],
  preprocessors: [createCompletePhoneInsertionPreprocessor()],
  plugins: [
    maskitoAddOnFocusPlugin('+7 '),
    maskitoRemoveOnBlurPlugin('+7 '),
    // Forbids to put caret before non-removable country prefix
    // But allows to select all value!
    maskitoCaretGuard((value, [from, to]) => [from === to ? '+7 '.length : 0, value.length])
  ]
} as MaskitoOptions;

// Paste "89123456789" => "+7 (912) 345-67-89"
function createCompletePhoneInsertionPreprocessor(): MaskitoPreprocessor {
  const trimPrefix = (value: string): string => value.replace(/^(\+?7?\s?8?)\s?/, '');
  const countDigits = (value: string): number => value.replace(/\D/g, '').length;

  return ({ elementState, data }) => {
    const { value, selection } = elementState;

    return {
      elementState: {
        selection,
        value: countDigits(value) > 11 ? trimPrefix(value) : value
      },
      data: countDigits(data) >= 11 ? trimPrefix(data) : data
    };
  };
}
