import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalContent, ModalWrapper } from './InputOutputCashModal.styled';

import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { IModalProps } from '../../../../../../store/redux/modal/modal.interface';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';
import { EIcon, IconNew } from '../../../../../icons/medium-new-icons/icon';
import NewRadioBtns from '../../../../../../shared/new-ui/NewRadioBtns/NewRadioBtns';
import { ioCashModalOptions } from './InputOutputCashModal.data';
import InputCashForm from './libs/input-cash-form/InputCashForm';
import OutputCashForm from './libs/output-cash-form/OutputCashForm';

const InputOutputCashModal: FC<IModalProps> = ({ closeModal }) => {
  const [selectedRadio, setSelectedRadio] = useState<string>('input');

  const handleRadioChange = (value: string) => {
    setSelectedRadio(value);
  };
  const { t } = useTranslation();

  return (
    <ModalWrapper
      $column
      $gap='46px'
    >
      <FlexWithAlign
        $gap='16px'
        $align='center'
      >
        <NewButton
          typeBtn={'close'}
          onClick={() => {
            closeModal();
          }}
        >
          <IconNew name={EIcon.close} />
        </NewButton>
        <NewText
          $tag={TextType.H2}
          fontWeight={600}
          color='title'
        >
          {t('Ввод / вывод средств')}
        </NewText>
      </FlexWithAlign>
      <ModalContent
        $column
        $gap='40px'
      >
        <NewRadioBtns
          options={ioCashModalOptions}
          name='IOCash'
          onChange={handleRadioChange}
          selectedValue={selectedRadio}
        />
        {selectedRadio === 'input' ? <InputCashForm closeModal={closeModal} /> : <OutputCashForm closeModal={closeModal} />}
      </ModalContent>
    </ModalWrapper>
  );
};

export default InputOutputCashModal;
