import { FC } from 'react';

import { TextProps, TextType } from './NewText.props';
import { StyledText } from './NewText.styled';

const NewText: FC<TextProps> = ({ $tag = TextType.SPAN, color, fontWeight, className, children, $customSize, ...props }: TextProps) => {
  return (
    <StyledText
      as={$tag}
      color={color}
      fontWeight={fontWeight}
      className={className}
      $tag={$tag}
      $customSize={$customSize}
      {...props}
    >
      {children}
    </StyledText>
  );
};

export default NewText;
