import { styled } from 'styled-components';
import themelight from '../../../../app/providers/ThemeProvider/ui/theme';

export const CheckboxWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border: 2px solid ${themelight.newColor.base08};
  border-radius: 6px;
  flex-shrink: 0;
  background-color: transparent;
  transition: all 0.2s ease;
  cursor: pointer;
  input:checked + span {
    opacity: 1;
    visibility: visible;
  }
  &:hover:not(.disabled):not(.error) {
  }
  &.active {
    border-color: ${themelight.newColor.primary};
    background-color: ${themelight.newColor.primary};
  }
  &.disabled {
    cursor: default;
    border-color: ${themelight.newColor.base08};
    background-color: ${themelight.newColor.grey01};

    input {
      cursor: default;
    }
  }
`;

export const CheckBox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

export const CustomCheckbox = styled.span`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  color: ${themelight.newColor.base01};
  border-radius: 4.5px;

  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
`;
