export const ArrowDownNewIcon = () => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.79922 9.23616C4.79922 9.10816 4.86322 8.98016 4.99122 8.85216C5.11922 8.72416 5.26322 8.66016 5.42322 8.66016C5.58322 8.66016 5.72722 8.72416 5.85522 8.85216L11.9992 14.6122L18.1432 8.85216C18.2712 8.72416 18.4152 8.66016 18.5752 8.66016C18.7352 8.66016 18.8792 8.72416 19.0072 8.85216C19.1352 8.98016 19.1992 9.12416 19.1992 9.28416C19.1992 9.44416 19.1352 9.58816 19.0072 9.71616L12.3832 15.9562C12.2552 16.0842 12.1112 16.1482 11.9512 16.1482C11.7912 16.1482 11.6792 16.0842 11.6152 15.9562L4.99122 9.71616C4.86322 9.58816 4.79922 9.42816 4.79922 9.23616Z'
        fill='currentColor'
      />
    </svg>
  );
};
