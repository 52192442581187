import React from 'react';

export function TelebonIcon() {
  return (
    <svg
      width='95'
      height='17'
      viewBox='0 0 95 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M94.9972 15.4705C94.9972 15.6708 94.9535 15.8581 94.866 16.0323C94.7883 16.1978 94.6814 16.3458 94.5454 16.4765C94.4093 16.5984 94.2442 16.6942 94.0498 16.7639C93.8652 16.8336 93.666 16.8684 93.4523 16.8684C93.2385 16.8684 93.0296 16.8336 92.8256 16.7639C92.6312 16.6942 92.4612 16.5984 92.3154 16.4765C92.1697 16.3458 92.0531 16.1978 91.9657 16.0323C91.8782 15.8581 91.8345 15.6708 91.8345 15.4705V10.6103C91.8345 10.5407 91.8393 10.4884 91.8491 10.4536C91.8296 10.0268 91.7325 9.62612 91.5576 9.25159C91.3827 8.87706 91.1446 8.55044 90.8434 8.27172C90.5422 7.993 90.1875 7.77525 89.7794 7.61847C89.3811 7.45299 88.939 7.37024 88.4531 7.37024C87.9576 7.37024 87.5009 7.45299 87.0831 7.61847C86.6653 7.77525 86.3058 7.99736 86.0046 8.28479C85.7131 8.57221 85.4799 8.9119 85.305 9.30385C85.1398 9.68709 85.0572 10.1008 85.0572 10.545V15.4705C85.0572 15.6708 85.0184 15.8581 84.9406 16.0323C84.8629 16.1978 84.7512 16.3458 84.6054 16.4765C84.4694 16.5984 84.3091 16.6942 84.1245 16.7639C83.9398 16.8336 83.7358 16.8684 83.5123 16.8684C83.2986 16.8684 83.0945 16.8336 82.9002 16.7639C82.7059 16.6942 82.531 16.5984 82.3755 16.4765C82.2297 16.3458 82.1131 16.1978 82.0257 16.0323C81.9383 15.8581 81.8945 15.6708 81.8945 15.4705V10.545C81.8945 9.70886 82.0597 8.92932 82.3901 8.2064C82.7301 7.47476 83.1917 6.83894 83.7747 6.29892C84.3674 5.75019 85.0621 5.31905 85.8588 5.00549C86.6653 4.69194 87.5301 4.53516 88.4531 4.53516C89.3859 4.53516 90.2507 4.69194 91.0475 5.00549C91.8442 5.31905 92.5341 5.75019 93.1171 6.29892C93.7 6.83894 94.1567 7.47476 94.4871 8.2064C94.8272 8.92932 94.9972 9.70886 94.9972 10.545V15.4705Z'
        fill='url(#paint0_linear_983_6047)'
      />
      <path
        d='M69.5807 10.7671C69.5807 11.2287 69.6778 11.6686 69.8722 12.0867C70.0762 12.496 70.3483 12.8575 70.6884 13.1711C71.0381 13.4759 71.4414 13.7198 71.8981 13.9027C72.3547 14.0769 72.8454 14.164 73.3701 14.164C73.8948 14.164 74.3855 14.0769 74.8422 13.9027C75.3085 13.7198 75.7118 13.4759 76.0518 13.1711C76.3919 12.8575 76.6591 12.496 76.8535 12.0867C77.0478 11.6686 77.145 11.2287 77.145 10.7671C77.145 10.2968 77.0478 9.85693 76.8535 9.44756C76.6591 9.02948 76.3919 8.66802 76.0518 8.36317C75.7118 8.05833 75.3085 7.8188 74.8422 7.6446C74.3855 7.4617 73.8948 7.37024 73.3701 7.37024C72.8551 7.37024 72.3644 7.4617 71.8981 7.6446C71.4414 7.8188 71.0381 8.05833 70.6884 8.36317C70.3483 8.66802 70.0762 9.02948 69.8722 9.44756C69.6778 9.85693 69.5807 10.2968 69.5807 10.7671ZM66.418 10.7671C66.418 9.90483 66.6026 9.09916 66.9718 8.35011C67.341 7.59234 67.8414 6.93039 68.473 6.36424C69.1046 5.7981 69.8382 5.35389 70.6738 5.03162C71.5191 4.70065 72.4179 4.53516 73.3701 4.53516C74.332 4.53516 75.2357 4.70065 76.081 5.03162C76.9263 5.35389 77.6599 5.7981 78.2818 6.36424C78.9134 6.93039 79.4089 7.59234 79.7684 8.35011C80.1279 9.09916 80.3077 9.90483 80.3077 10.7671C80.3077 11.6207 80.1279 12.4264 79.7684 13.1841C79.4089 13.9419 78.9134 14.6038 78.2818 15.17C77.6599 15.7361 76.9263 16.1847 76.081 16.5157C75.2357 16.8379 74.332 16.9991 73.3701 16.9991C72.4179 16.9991 71.5191 16.8379 70.6738 16.5157C69.8382 16.1847 69.1046 15.7361 68.473 15.17C67.8414 14.6038 67.341 13.9419 66.9718 13.1841C66.6026 12.4264 66.418 11.6207 66.418 10.7671Z'
        fill='url(#paint1_linear_983_6047)'
      />
      <path
        d='M51.543 1.47634C51.543 1.2673 51.5818 1.07568 51.6596 0.901479C51.747 0.71857 51.8588 0.561791 51.9948 0.431142C52.1405 0.291783 52.3106 0.187263 52.5049 0.117583C52.6992 0.0391945 52.9081 0 53.1316 0C53.3648 0 53.5737 0.0391945 53.7583 0.117583C53.9527 0.187263 54.1178 0.291783 54.2539 0.431142C54.3996 0.561791 54.5114 0.71857 54.5891 0.901479C54.6668 1.07568 54.7057 1.2673 54.7057 1.47634V5.55259C55.2595 5.23032 55.8522 4.98209 56.4838 4.80789C57.1251 4.62498 57.7955 4.53352 58.4951 4.53352C59.457 4.53352 60.3558 4.69901 61.1914 5.02999C62.0368 5.35226 62.7704 5.79646 63.3922 6.36261C64.0238 6.92005 64.5193 7.57765 64.8788 8.33541C65.2481 9.09318 65.4327 9.9032 65.4327 10.7655C65.4327 11.6191 65.2481 12.4247 64.8788 13.1825C64.5193 13.9403 64.0238 14.6022 63.3922 15.1684C62.7704 15.7345 62.0368 16.1831 61.1914 16.514C60.3558 16.8363 59.457 16.9974 58.4951 16.9974C57.5429 16.9974 56.6441 16.8363 55.7988 16.514C54.9535 16.1831 54.215 15.7345 53.5834 15.1684C52.9519 14.6022 52.4515 13.9403 52.0822 13.1825C51.7227 12.4247 51.543 11.6191 51.543 10.7655V1.47634ZM54.7057 10.7655C54.7057 11.2271 54.8028 11.667 54.9972 12.085C55.2012 12.4944 55.4733 12.8559 55.8134 13.1694C56.1632 13.4743 56.5664 13.7182 57.0231 13.9011C57.4797 14.0753 57.9704 14.1624 58.4951 14.1624C59.0198 14.1624 59.5105 14.0753 59.9672 13.9011C60.4238 13.7182 60.8222 13.4743 61.1623 13.1694C61.5121 12.8559 61.7841 12.4944 61.9785 12.085C62.1728 11.667 62.27 11.2271 62.27 10.7655C62.27 10.2951 62.1728 9.8553 61.9785 9.44593C61.7841 9.02785 61.5121 8.66639 61.1623 8.36154C60.8222 8.05669 60.4238 7.81717 59.9672 7.64297C59.5105 7.46006 59.0198 7.36861 58.4951 7.36861C57.9704 7.36861 57.4797 7.46006 57.0231 7.64297C56.5664 7.81717 56.1632 8.05669 55.8134 8.36154C55.4733 8.66639 55.2012 9.02785 54.9972 9.44593C54.8028 9.8553 54.7057 10.2951 54.7057 10.7655Z'
        fill='url(#paint2_linear_983_6047)'
      />
      <path
        d='M39.2185 12.0344C39.3448 12.3567 39.5197 12.6528 39.7432 12.9228C39.9764 13.1841 40.2436 13.4106 40.5448 13.6022C40.846 13.7851 41.1764 13.9288 41.5359 14.0333C41.8954 14.1292 42.2743 14.1771 42.6727 14.1771C43.6735 14.1771 44.4605 13.9419 45.0338 13.4716C45.2281 13.3496 45.4176 13.2625 45.6022 13.2103C45.7868 13.1493 45.9617 13.1188 46.1269 13.1188C46.399 13.1188 46.6419 13.1841 46.8556 13.3148C47.0694 13.4367 47.2443 13.5978 47.3803 13.7982C47.4775 13.9375 47.5455 14.09 47.5844 14.2554C47.633 14.4122 47.6427 14.5734 47.6135 14.7389C47.5941 14.9043 47.5358 15.0698 47.4386 15.2353C47.3512 15.3921 47.2151 15.5358 47.0305 15.6665C46.8168 15.8407 46.5739 16.0105 46.3018 16.176C46.0297 16.3328 45.7139 16.4721 45.3544 16.5941C45.0046 16.716 44.6063 16.8118 44.1593 16.8815C43.7221 16.9599 43.2265 16.9991 42.6727 16.9991C41.7108 16.9991 40.8071 16.8379 39.9618 16.5157C39.1165 16.1847 38.378 15.7361 37.7464 15.17C37.1149 14.6038 36.6145 13.9462 36.2452 13.1972C35.876 12.4394 35.6914 11.6294 35.6914 10.7671C35.6914 9.90483 35.876 9.09481 36.2452 8.33704C36.6145 7.57928 37.1149 6.92168 37.7464 6.36424C38.378 5.7981 39.1165 5.35389 39.9618 5.03162C40.8071 4.70065 41.7108 4.53516 42.6727 4.53516C43.6249 4.53516 44.5188 4.69629 45.3544 5.01856C46.1998 5.34083 46.9382 5.78068 47.5698 6.33811C48.2111 6.88684 48.7163 7.53573 49.0856 8.28479C49.4645 9.03384 49.6588 9.83515 49.6686 10.6887C49.6686 11.0894 49.5228 11.416 49.2313 11.6686C48.9495 11.9125 48.5754 12.0344 48.1091 12.0344H39.2185ZM46.4184 9.68273C46.3115 9.32562 46.1415 9.00336 45.9083 8.71593C45.6848 8.4285 45.4127 8.18462 45.0921 7.98429C44.7715 7.78396 44.4119 7.63154 44.0136 7.52702C43.6152 7.4225 43.1925 7.37024 42.7456 7.37024C42.2986 7.37024 41.8759 7.42686 41.4776 7.54009C41.0889 7.6446 40.7343 7.80138 40.4136 8.01042C40.1027 8.21075 39.8355 8.45463 39.612 8.74206C39.3885 9.02078 39.2233 9.33433 39.1165 9.68273H46.4184Z'
        fill='url(#paint3_linear_983_6047)'
      />
      <path
        d='M33.2656 14.0056C33.4891 14.0056 33.698 14.0448 33.8923 14.1232C34.0867 14.2016 34.2519 14.3104 34.3879 14.4498C34.5239 14.5804 34.6308 14.7329 34.7085 14.9071C34.7863 15.0813 34.8251 15.2642 34.8251 15.4558C34.8251 15.8564 34.6794 16.1874 34.3879 16.4487C34.0964 16.71 33.7223 16.8407 33.2656 16.8407C32.6049 16.8407 31.9831 16.7405 31.4001 16.5402C30.8268 16.3398 30.3215 16.0611 29.8843 15.704C29.4471 15.3469 29.1021 14.9158 28.8495 14.4106C28.5969 13.9054 28.4608 13.348 28.4414 12.7383V1.42407C28.4414 1.22375 28.4803 1.03648 28.558 0.862284C28.6455 0.688085 28.7572 0.540016 28.8932 0.418076C29.0293 0.287427 29.1896 0.187263 29.3742 0.117583C29.5588 0.0391945 29.758 0 29.9718 0C30.1952 0 30.4041 0.0391945 30.5985 0.117583C30.7928 0.187263 30.9628 0.287427 31.1086 0.418076C31.264 0.540016 31.3855 0.688085 31.4729 0.862284C31.5604 1.03648 31.6041 1.22375 31.6041 1.42407V12.6468C31.6041 13.0998 31.7596 13.4394 32.0705 13.6659C32.3814 13.8924 32.7798 14.0056 33.2656 14.0056Z'
        fill='url(#paint4_linear_983_6047)'
      />
      <path
        d='M16.2029 12.0344C16.3292 12.3567 16.5041 12.6528 16.7276 12.9228C16.9607 13.1841 17.2279 13.4106 17.5292 13.6022C17.8304 13.7851 18.1607 13.9288 18.5202 14.0333C18.8797 14.1292 19.2587 14.1771 19.6571 14.1771C20.6579 14.1771 21.4449 13.9419 22.0182 13.4716C22.2125 13.3496 22.402 13.2625 22.5866 13.2103C22.7712 13.1493 22.9461 13.1188 23.1113 13.1188C23.3833 13.1188 23.6262 13.1841 23.84 13.3148C24.0538 13.4367 24.2287 13.5978 24.3647 13.7982C24.4619 13.9375 24.5299 14.09 24.5687 14.2554C24.6173 14.4122 24.627 14.5734 24.5979 14.7389C24.5785 14.9043 24.5202 15.0698 24.423 15.2353C24.3355 15.3921 24.1995 15.5358 24.0149 15.6665C23.8011 15.8407 23.5582 16.0105 23.2862 16.176C23.0141 16.3328 22.6983 16.4721 22.3388 16.5941C21.989 16.716 21.5906 16.8118 21.1437 16.8815C20.7064 16.9599 20.2109 16.9991 19.6571 16.9991C18.6951 16.9991 17.7915 16.8379 16.9462 16.5157C16.1008 16.1847 15.3624 15.7361 14.7308 15.17C14.0992 14.6038 13.5988 13.9462 13.2296 13.1972C12.8604 12.4394 12.6758 11.6294 12.6758 10.7671C12.6758 9.90483 12.8604 9.09481 13.2296 8.33704C13.5988 7.57928 14.0992 6.92168 14.7308 6.36424C15.3624 5.7981 16.1008 5.35389 16.9462 5.03162C17.7915 4.70065 18.6951 4.53516 19.6571 4.53516C20.6093 4.53516 21.5032 4.69629 22.3388 5.01856C23.1841 5.34083 23.9226 5.78068 24.5542 6.33811C25.1955 6.88684 25.7007 7.53573 26.0699 8.28479C26.4489 9.03384 26.6432 9.83515 26.6529 10.6887C26.6529 11.0894 26.5072 11.416 26.2157 11.6686C25.9339 11.9125 25.5598 12.0344 25.0934 12.0344H16.2029ZM23.4028 9.68273C23.2959 9.32562 23.1258 9.00336 22.8927 8.71593C22.6692 8.4285 22.3971 8.18462 22.0765 7.98429C21.7558 7.78396 21.3963 7.63154 20.9979 7.52702C20.5996 7.4225 20.1769 7.37024 19.7299 7.37024C19.283 7.37024 18.8603 7.42686 18.4619 7.54009C18.0733 7.6446 17.7186 7.80138 17.398 8.01042C17.0871 8.21075 16.8199 8.45463 16.5964 8.74206C16.3729 9.02078 16.2077 9.33433 16.1008 9.68273H23.4028Z'
        fill='url(#paint5_linear_983_6047)'
      />
      <path
        d='M3.16271 4.6909H8.23471C8.45819 4.6909 8.66709 4.7301 8.86142 4.80849C9.05575 4.87817 9.22579 4.97833 9.37154 5.10898C9.51728 5.23963 9.62902 5.39205 9.70676 5.56625C9.7942 5.74045 9.83793 5.92772 9.83793 6.12804C9.83793 6.31966 9.7942 6.50257 9.70676 6.67677C9.62902 6.84226 9.51728 6.99033 9.37154 7.12098C9.22579 7.24292 9.05575 7.34308 8.86142 7.42147C8.66709 7.49115 8.45819 7.52599 8.23471 7.52599H3.16271V10.8314C3.16271 11.293 3.25988 11.7285 3.45421 12.1379C3.65825 12.5386 3.93031 12.8913 4.27039 13.1962C4.61047 13.4923 5.00884 13.7275 5.46552 13.9017C5.93191 14.0759 6.42745 14.163 6.95214 14.163C7.41853 14.163 7.85091 14.1063 8.24929 13.9931C8.64766 13.8712 9.01689 13.6752 9.35696 13.4052C9.52214 13.2658 9.70676 13.1526 9.9108 13.0655C10.1148 12.9697 10.3286 12.9218 10.5521 12.9218C10.9116 12.9218 11.2371 13.0394 11.5286 13.2746C11.7035 13.4139 11.8249 13.5794 11.893 13.771C11.9707 13.9626 11.9998 14.163 11.9804 14.372C11.9707 14.5723 11.9173 14.7683 11.8201 14.9599C11.7229 15.1515 11.5869 15.3214 11.412 15.4695C10.829 15.9746 10.1586 16.3579 9.40069 16.6192C8.6428 16.8718 7.82662 16.998 6.95214 16.998C5.99021 16.998 5.08657 16.8369 4.24124 16.5146C3.40562 16.1924 2.67203 15.7525 2.04046 15.1951C1.40889 14.6377 0.90849 13.9844 0.539264 13.2354C0.179755 12.4776 0 11.6676 0 10.8053V2.41761C0 2.21728 0.0437241 2.03002 0.131172 1.85582C0.218621 1.68162 0.335218 1.5292 0.480965 1.39855C0.626713 1.2679 0.796751 1.16773 0.99108 1.09805C1.18541 1.01966 1.38946 0.980469 1.60322 0.980469C1.81698 0.980469 2.01617 1.01966 2.20078 1.09805C2.39511 1.16773 2.56029 1.2679 2.69632 1.39855C2.84207 1.5292 2.95381 1.68162 3.03154 1.85582C3.11899 2.03002 3.16271 2.21728 3.16271 2.41761V4.6909Z'
        fill='url(#paint6_linear_983_6047)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_983_6047'
          x1='87.611'
          y1='-3.75846'
          x2='82.1975'
          y2='25.8049'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4F4F4' />
          <stop
            offset='1'
            stopColor='#7F8084'
          />
        </linearGradient>
        <linearGradient
          id='paint1_linear_983_6047'
          x1='72.4778'
          y1='-3.84632'
          x2='67.2468'
          y2='26.1189'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4F4F4' />
          <stop
            offset='1'
            stopColor='#7F8084'
          />
        </linearGradient>
        <linearGradient
          id='paint2_linear_983_6047'
          x1='57.6028'
          y1='-11.4301'
          x2='48.1156'
          y2='28.4211'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4F4F4' />
          <stop
            offset='1'
            stopColor='#7F8084'
          />
        </linearGradient>
        <linearGradient
          id='paint3_linear_983_6047'
          x1='41.7894'
          y1='-3.84632'
          x2='36.5892'
          y2='26.1299'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4F4F4' />
          <stop
            offset='1'
            stopColor='#7F8084'
          />
        </linearGradient>
        <linearGradient
          id='paint4_linear_983_6047'
          x1='31.2265'
          y1='-11.3247'
          x2='14.2787'
          y2='21.699'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4F4F4' />
          <stop
            offset='1'
            stopColor='#7F8084'
          />
        </linearGradient>
        <linearGradient
          id='paint5_linear_983_6047'
          x1='18.7738'
          y1='-3.84632'
          x2='13.5736'
          y2='26.1299'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4F4F4' />
          <stop
            offset='1'
            stopColor='#7F8084'
          />
        </linearGradient>
        <linearGradient
          id='paint6_linear_983_6047'
          x1='5.22939'
          y1='-9.79069'
          x2='-4.43362'
          y2='27.3795'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F4F4F4' />
          <stop
            offset='1'
            stopColor='#7F8084'
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
