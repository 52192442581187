import styled from 'styled-components';

interface IDrawerProps {
  $full?: boolean;
}
export const DrawerContainer = styled.div<IDrawerProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  .sheet {
    z-index: 199;
    position: fixed;
    height: ${(props) => (props.$full ? 'calc(100vh - 100px)' : 'fit-content')};
    max-height: 90vh;
    width: 100vw;
    border-radius: 12px 12px 0;
    touch-action: none;
    padding: 1rem;
    background: ${(props) => props.theme.color.base01};
  }
  &.open {
    display: flex;
    visibility: visible;
  }
  &.close {
    display: none;
    visibility: hidden;
  }
`;
export const DrawerHead = styled.div`
  position: relative;
  width: 100%;
  //height: 40px;
  left: 50%;
  transform: translateX(-50%);
  > div {
    padding-block: 20px;
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 19px;
    line-height: 1.65;
    font-weight: 600;
    color: ${(props) => props.theme.text.text01};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &.only-before {
      padding-block: 10px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: ${(props) => props.theme.text.text01};
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: ${(props) => props.theme.color.base04};
    width: 40px;
    height: 4px;
    border-radius: 4px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 16px;
    transform: translatey(-100%);
  }
`;
export const DrawerContentWrapper = styled.div`
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export const DrawerButtons = styled.div``;
