import React from 'react';

export function Question() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0ZM5.83333 5.44444C5.83333 4.80011 6.35567 4.27778 7 4.27778C7.64433 4.27778 8.16667 4.80011 8.16667 5.44444C8.16667 6.08878 7.64433 6.61111 7 6.61111C6.67783 6.61111 6.41667 6.87228 6.41667 7.19444V7.97222C6.41667 8.29439 6.67783 8.55556 7 8.55556C7.32217 8.55556 7.58333 8.29439 7.58333 7.97222V7.70427C8.58972 7.44524 9.33333 6.53168 9.33333 5.44444C9.33333 4.15578 8.28866 3.11111 7 3.11111C5.71134 3.11111 4.66667 4.15578 4.66667 5.44444C4.66667 5.76661 4.92783 6.02778 5.25 6.02778C5.57217 6.02778 5.83333 5.76661 5.83333 5.44444ZM7.58333 9.91667C7.58333 9.5945 7.32217 9.33333 7 9.33333C6.67783 9.33333 6.41667 9.5945 6.41667 9.91667V10.3056C6.41667 10.6277 6.67783 10.8889 7 10.8889C7.32217 10.8889 7.58333 10.6277 7.58333 10.3056V9.91667Z'
        fill='#5089FD'
      />
    </svg>
  );
}
