import React from 'react';

export function StarOutline() {
  return (
    <svg
      width='15'
      height='15'
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 0C5.42912 0 3.55321 0.84019 2.1967 2.1967C0.84019 3.55321 0 5.42912 0 7.5C0 9.57088 0.84019 11.4468 2.1967 12.8033C3.55321 14.1598 5.42912 15 7.5 15C9.57088 15 11.4468 14.1598 12.8033 12.8033C14.1598 11.4468 15 9.57088 15 7.5C15 5.42912 14.1598 3.55321 12.8033 2.1967C11.4468 0.84019 9.57088 0 7.5 0ZM3.13258 3.13258C4.25102 2.01413 5.79423 1.32353 7.5 1.32353C9.20577 1.32353 10.749 2.01413 11.8674 3.13258C12.9859 4.25102 13.6765 5.79423 13.6765 7.5C13.6765 9.20577 12.9859 10.749 11.8674 11.8674C10.749 12.9859 9.20577 13.6765 7.5 13.6765C5.79423 13.6765 4.25102 12.9859 3.13258 11.8674C2.01413 10.749 1.32353 9.20577 1.32353 7.5C1.32353 5.79423 2.01413 4.25102 3.13258 3.13258ZM8.39084 3.61872C8.00627 2.91356 6.99374 2.91356 6.60917 3.61872L5.78328 5.13308L4.08783 5.45058C3.29834 5.59843 2.98545 6.5614 3.53726 7.14506L4.72229 8.39848L4.50032 10.1091C4.39697 10.9056 5.21612 11.5008 5.94173 11.1563L7.5 10.4166L9.05828 11.1563C9.78388 11.5008 10.603 10.9056 10.4997 10.1091L10.2777 8.39848L11.4627 7.14506C12.0146 6.5614 11.7017 5.59843 10.9122 5.45058L9.21673 5.13308L8.39084 3.61872ZM6.64443 6.31835L7.5 4.74956L8.35557 6.31835L10.112 6.64727L8.88435 7.94575L9.11429 9.71782L7.5 8.95153L5.88572 9.71782L6.11566 7.94575L4.88803 6.64727L6.64443 6.31835Z'
        fill='currentColor'
      />
    </svg>
  );
}
