export function CartTarifIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 18C8.82843 18 9.5 18.6716 9.5 19.5C9.5 20.3284 8.82843 21 8 21C7.17157 21 6.5 20.3284 6.5 19.5C6.5 18.6716 7.17157 18 8 18Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M17 18.0001C17.8284 18.0001 18.5 18.6716 18.5 19.5001C18.5 20.3285 17.8284 21.0001 17 21.0001C16.1716 21.0001 15.5 20.3285 15.5 19.5001C15.5 18.6716 16.1716 18.0001 17 18.0001Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M11.5 10.8L12.6429 12L15.5 9'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 3L2.76121 3.09184C4.0628 3.54945 4.7136 3.77826 5.08584 4.32298C5.45808 4.86771 5.45808 5.59126 5.45808 7.03836V9.76C5.45808 12.7016 5.52132 13.6723 6.38772 14.5862C7.25412 15.5 8.64857 15.5 11.4375 15.5H12.5M16.7404 15.5C18.3014 15.5 19.0819 15.5 19.6336 15.0504C20.1853 14.6008 20.3429 13.8364 20.658 12.3075L21.1578 9.88275C21.5049 8.14369 21.6784 7.27417 21.2345 6.69708C20.7906 6.12 19.2738 6.12 17.5888 6.12H11.5235M5.45808 6.12H7.5'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
}
