import React from 'react';

export function PlusChatIcon() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_536_1595)'>
        <path
          d='M20.9415 10.2946H11.7015V1.0546C11.7015 0.878595 11.6282 0.73193 11.4815 0.614595C11.3348 0.497263 11.1735 0.438595 10.9975 0.438595C10.8215 0.438595 10.6748 0.497263 10.5575 0.614595C10.4402 0.73193 10.3815 0.878595 10.3815 1.0546V10.2946H1.1415C0.906833 10.2946 0.730833 10.3679 0.6135 10.5146C0.496167 10.6613 0.4375 10.8226 0.4375 10.9986C0.4375 11.1746 0.496167 11.3359 0.6135 11.4826C0.730833 11.6293 0.906833 11.6733 1.1415 11.6146H10.3815V20.8546C10.3815 21.0893 10.4402 21.2653 10.5575 21.3826C10.6748 21.4999 10.8215 21.5586 10.9975 21.5586C11.1735 21.5586 11.3348 21.4999 11.4815 21.3826C11.6282 21.2653 11.7015 21.0893 11.7015 20.8546V11.6146H20.9415C21.1175 11.6146 21.2642 11.5559 21.3815 11.4386C21.4988 11.3213 21.5575 11.1746 21.5575 10.9986C21.5575 10.8226 21.4988 10.6613 21.3815 10.5146C21.2642 10.3679 21.1175 10.2946 20.9415 10.2946Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_536_1595'>
          <rect
            width='22'
            height='22'
            fill='currentColor'
            transform='matrix(1 0 0 -1 0 22)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
