import React from 'react';

import { DefaultTelegramBackground } from '../../../../../../components/icons/medium-new-icons/unsorted/DefaultTelegramBackground';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';

const TelegramBotOnline = () => {
  return (
    <div className={'bot-online'}>
      <DefaultTelegramBackground></DefaultTelegramBackground>
      <div style={{ paddingTop: '19px' }}>
        <NewText
          $customSize={TextCustomType.T16}
          fontWeight={700}
        >
          Здесь появятся уведомления и сообщения из вашего бота онлайн-записи
        </NewText>
      </div>
    </div>
  );
};

export default TelegramBotOnline;
