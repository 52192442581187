import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import {
  ChatItem,
  ChatList,
  DialogAvatar,
  DialogTitle,
  DislogSubtitle,
  SidebarContent,
  SidebarHeadder,
  SidebarSlider,
  SidebarSliderItem,
  TableBody
} from './MessengerSide.styled';
import MessengerOptions from './options/MessengerOptions';

import LogoImg from '../../../../assets/images/logo.png';
import TelegramImg from '../../../../assets/images/telegram.png';
import { cn } from '../../../../shared/lib';
import { useActions } from '../../../../shared/lib/hooks/useActions';
import useMatchMedia from '../../../../shared/lib/hooks/useMatchMedia';
import { useOutside } from '../../../../shared/lib/hooks/useOutside';
import { useTypedSelector } from '../../../../shared/lib/hooks/useTypedSelector';
import NewSearchInput from '../../../../shared/new-ui/NewSearchInput/ui/NewSearchInput';
import NewText from '../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../shared/new-ui/NewText/ui/NewText.props';
import { Text, TextPType, TextType } from '../../../../shared/ui';
import { useFilialQuery } from '../../../../store/redux/filial/hooks/useFilialQuery';
import { useProfileUserQuery } from '../../../../store/redux/user/hooks/useUsersQuery';
import { sendMessage } from '../../../../store/redux/websocket/chat-reducer';
import { getCookie } from '../../../../utils/cookies';
import { firstTwoLetters, getGreyColorForSymbol } from '../../lib/messenger.helper';
import ContextMenu from '../ContextMenu/ContextMenu';

const MessengerSide: FC = () => {
  const { roomId } = useParams();
  const { t } = useTranslation();
  const { setChatRoomId, setCloseMassages, setOpendialog } = useActions();
  const [value, setValue] = useState('');
  const [selectedChatId, setSelectedChatId] = useState('');
  const { data: profileUser } = useProfileUserQuery(getCookie('id'));
  const { data: activeFilial } = useFilialQuery();
  const { ref, isShow, setIsShow } = useOutside(false);
  const { userChats, websocketroomId } = useTypedSelector((state) => state.chat);
  const dispatch = useDispatch();
  const [lastAnimatedIndex, setLastAnimatedIndex] = useState(-1);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const messagesRecords = useTypedSelector((state) => state.chat);

  const calculateAnimationDelay = (index: number) => {
    return index * 0.05;
  };
  const sendMessageHandler = (chat_id: string) => {
    isMobile && setCloseMassages();
    dispatch(sendMessage(JSON.stringify({ type: 'get_chat_messages', data: { chat_id } })) as any);
    setChatRoomId(chat_id);
  };

  const sendDeleteChat = () => {
    dispatch(sendMessage(JSON.stringify({ type: 'delete_chat', data: { chat_id: selectedChatId } })) as any);
    setIsShow(false);
  };
  const sendMessageBot = () => {
    isMobile && setCloseMassages();
    if (activeFilial) {
      setChatRoomId('telebot');
      dispatch(sendMessage(JSON.stringify({ type: 'get_records', data: { filial_id: activeFilial.id } })) as any);
    }
  };
  const [buttonX, setButtonX] = useState(0);
  const [buttonY, setButtonY] = useState(0);

  const handleContextMenu = (e: React.MouseEvent, chat_id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setIsShow(true);
    setButtonX(e.clientX);
    setButtonY(e.clientY);
    setSelectedChatId(chat_id);
  };

  const filteredChats = userChats?.filter((chat) => {
    const chatName = chat.chat_name.toLowerCase();

    return chatName.includes(value.toLowerCase());
  });

  const userAgent = navigator.userAgent;

  const isTechSupport = profileUser?.Email === 'hello@telebon.ru' && activeFilial?.name === 'Служба Поддержки';

  return (
    <TableBody>
      <SidebarSlider>
        <SidebarSliderItem>
          <NewText $tag={TextType.H1}>Чаты</NewText>
          {!isMobile && (
            <SidebarHeadder>
              <NewSearchInput
                className={'greyInput'}
                name={'messenger-search'}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder={t('Поиск')}
              />
            </SidebarHeadder>
          )}
          <SidebarContent>
            <div className={'chatlist-conteiner'}>
              <div className={'connection-status-bottom'}>
                <div className='tabs-container'>
                  <div className='scrollabel'>
                    <ChatList>
                      <ul>
                        {userChats
                          ?.filter((item) => item.is_tech_support)
                          ?.map((room) => (
                            <ChatItem
                              key={room.chat_id}
                              className={
                                room.unread !== 0
                                  ? 'unread-msg'
                                  : (isTechSupport ? websocketroomId === room.chat_id : roomId === 'support')
                                  ? 'active'
                                  : ''
                              }
                              // className={cn(
                              //   (isTechSupport ? websocketroomId === room.chat_id : !isMobile && roomId === 'support') && 'active'////sfsdf
                              // )}
                            >
                              <Link
                                onClick={() => {
                                  sendMessageHandler(room.chat_id);
                                }}
                                to={isTechSupport && websocketroomId ? `/messenger/${websocketroomId}` : `/messenger/support`}
                              />
                              <DislogSubtitle>
                                <div className='dialog-subtitle-flex title'>
                                  <div className={'content'}>
                                    {room.lastest_message ? (
                                      <div dangerouslySetInnerHTML={{ __html: room.lastest_message }} />
                                    ) : (
                                      'пока сообщений нет'.toUpperCase()
                                    )}
                                  </div>
                                </div>
                              </DislogSubtitle>
                              <DialogTitle>
                                <div className={'user-title title'}>
                                  <NewText
                                    $tag={TextType.P}
                                    color={'title'}
                                    // fontWeight={/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream ? 500 : 700}
                                  >
                                    {/* {isTechSupport ? room.chat_name.split(' ').slice(4, -1).join(' ') : 'Служба поддержки'} */}
                                    {isTechSupport
                                      ? profileUser?.id == '6686be7821ca69fc50d76931' || profileUser?.id == '65a8d81df5353df12690729f'
                                        ? room.chat_name?.split(' ').slice(4, -1).join(' ')
                                        : 'Служба поддержки'
                                      : 'Служба поддержки'}
                                  </NewText>
                                </div>
                                <div className='dialog-title-details'>
                                  <NewText
                                    $tag={TextType.P}
                                    $customSize={TextCustomType.T12}
                                    color={'greySecondary'}
                                  >
                                    {room?.lastest_message
                                      ? dayjs(room.updated_at).utcOffset(6).format('DD.MM.YY')
                                      : dayjs(room.created_at).utcOffset(6).format('DD.MM.YY')}
                                  </NewText>
                                  {isTechSupport && room?.unread && room?.unread !== 0 ? (
                                    <div className={'message-unread'}>
                                      <Text
                                        $tag={TextType.P}
                                        $pSize={TextPType.P13}
                                        color={'base01'}
                                        fontWeight={600}
                                      >
                                        {room.unread}
                                      </Text>
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              </DialogTitle>
                              {isTechSupport ? (
                                <DialogAvatar
                                  style={{
                                    background: `${getGreyColorForSymbol(
                                      firstTwoLetters(room.chat_name.split(' ').slice(4, -1).join(' '))
                                    )}`
                                  }}
                                >
                                  {firstTwoLetters(room.chat_name.split(' ').slice(4, -1).join(' '))}
                                </DialogAvatar>
                              ) : (
                                <DialogAvatar>
                                  <img
                                    alt={'telebon-supprot'}
                                    src={LogoImg}
                                  />
                                </DialogAvatar>
                              )}
                            </ChatItem>
                          ))}
                        <ChatItem className={cn(!isMobile && roomId === 'telebot' && 'active')}>
                          <Link
                            onClick={() => {
                              sendMessageBot();
                              isMobile && setOpendialog(true);
                            }}
                            to={isMobile ? '/messenger-list/telebot' : `/messenger/telebot`}
                          />
                          <DislogSubtitle>
                            <div className='dialog-subtitle-flex title'>
                              {/*<span>{room.lastest_message ? room.lastest_message : ''}</span>*/}
                            </div>
                          </DislogSubtitle>
                          <DialogTitle>
                            <div className={'user-title title'}>
                              <NewText
                                $tag={TextType.P}
                                color={'title'}
                                // fontWeight={/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream ? 500 : 700}
                              >
                                Telegram Bot
                              </NewText>
                            </div>
                            <div className='dialog-title-details'>
                              {/*<div className='message-time'>{dayjs(room.updated_at).format('HH.mm')}</div>*/}
                            </div>
                          </DialogTitle>
                          <DialogAvatar>
                            <img
                              alt={'telegram-avatar'}
                              src={TelegramImg}
                            />
                          </DialogAvatar>
                        </ChatItem>
                        {filteredChats
                          ?.filter((item) => !item.is_tech_support)
                          ?.map((room, index) => {
                            const shouldAnimate = index > lastAnimatedIndex;

                            const onAnimationEnd = () => {
                              if (shouldAnimate) {
                                setLastAnimatedIndex(index);
                              }
                            };

                            return (
                              <ChatItem
                                onAnimationEnd={onAnimationEnd}
                                style={{ animationDuration: `${calculateAnimationDelay(index)}s` }}
                                key={room.chat_id}
                                className={room.unread !== 0 ? 'unread-msg' : !isMobile && roomId === room.chat_id ? 'active' : ''}
                              >
                                <Link
                                  onClick={() => {
                                    sendMessageHandler(room.chat_id);
                                  }}
                                  onContextMenu={(e) => handleContextMenu(e, room.chat_id)}
                                  to={isMobile ? `/messenger-list/${room.chat_id}` : `/messenger/${room.chat_id}`}
                                />
                                <DislogSubtitle>
                                  <div className='dialog-subtitle-flex title'>
                                    <div className={'content'}>
                                      {room.unread !== 0 ? (
                                        `НЕПРОЧИТАННЫХ СООБЩЕНИЙ: ${room.unread}`
                                      ) : room.lastest_message ? (
                                        <div dangerouslySetInnerHTML={{ __html: room.lastest_message }} />
                                      ) : (
                                        'пока сообщений нет'.toUpperCase()
                                      )}
                                    </div>
                                  </div>
                                </DislogSubtitle>
                                <DialogTitle>
                                  <div className={'user-title title'}>
                                    <Text
                                      $tag={TextType.P}
                                      $pSize={TextPType.P14}
                                      fontWeight={/iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream ? 500 : 400}
                                    >
                                      {room.chat_name}
                                    </Text>
                                  </div>
                                  <div className='dialog-title-details'>
                                    <Text
                                      $tag={TextType.P}
                                      $pSize={TextPType.P11}
                                      color={'text02'}
                                    >
                                      {room?.lastest_message
                                        ? dayjs(room.updated_at).utcOffset(6).format('DD.MM.YY')
                                        : dayjs(room.created_at).utcOffset(6).format('DD.MM.YY')}
                                    </Text>
                                  </div>
                                </DialogTitle>
                                <DialogAvatar>{firstTwoLetters(room.chat_name)}</DialogAvatar>
                                {!isMobile && isShow && (
                                  <div ref={ref}>
                                    <ContextMenu
                                      buttonX={buttonX}
                                      buttonY={buttonY}
                                    >
                                      <p
                                        onClick={() => {
                                          sendDeleteChat();
                                        }}
                                      >
                                        Удалить чат
                                      </p>
                                    </ContextMenu>
                                  </div>
                                )}
                              </ChatItem>
                            );
                          })}
                      </ul>
                    </ChatList>
                  </div>
                </div>
              </div>
            </div>
            <MessengerOptions />
          </SidebarContent>
        </SidebarSliderItem>
      </SidebarSlider>
    </TableBody>
  );
};

export default MessengerSide;
