export function ChevronUpSmall() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.20711 15.7039C6.81658 16.0987 6.18342 16.0987 5.79289 15.7039C5.40237 15.3092 5.40237 14.6691 5.79289 14.2744L11.7929 8.29607C12.1834 7.90131 12.8166 7.90131 13.2071 8.29607L19.2071 14.2744C19.5976 14.6691 19.5976 15.3092 19.2071 15.7039C18.8166 16.0987 18.1834 16.0987 17.7929 15.7039L12.5 10.4404L7.20711 15.7039Z'
        fill='currentColor'
      />
    </svg>
  );
}
