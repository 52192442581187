export function ArrowRightCircleOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.3089 8.2213C12.6837 7.89485 13.2522 7.93405 13.5786 8.30887L16.2786 11.4089C16.5738 11.7477 16.5738 12.2523 16.2786 12.5911L13.5786 15.6911C13.2522 16.0659 12.6837 16.1051 12.3089 15.7786C11.9341 15.4522 11.8948 14.8837 12.2213 14.5089L13.622 12.9H8.39998C7.88647 12.9 7.53023 12.4871 7.49998 12C7.49998 11.5029 7.90292 11.1 8.39998 11.1H13.622L12.2213 9.49108C11.8828 9.1024 11.9633 8.56568 12.3089 8.2213Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.99962 4.99962C6.79018 3.20906 9.26641 2.09998 12 2.09998C14.7335 2.09998 17.2098 3.20906 19.0003 4.99962C20.7909 6.79018 21.9 9.26641 21.9 12C21.9 14.7335 20.7909 17.2098 19.0003 19.0003C17.2098 20.7909 14.7335 21.9 12 21.9C9.26641 21.9 6.79018 20.7909 4.99962 19.0003C3.20906 17.2098 2.09998 14.7335 2.09998 12C2.09998 9.26641 3.20906 6.79018 4.99962 4.99962ZM12 3.89998C9.76297 3.89998 7.73921 4.80561 6.27241 6.27241C4.80561 7.73921 3.89998 9.76297 3.89998 12C3.89998 14.237 4.80561 16.2607 6.27241 17.7275C7.73921 19.1943 9.76297 20.1 12 20.1C14.237 20.1 16.2607 19.1943 17.7275 17.7275C19.1943 16.2607 20.1 14.237 20.1 12C20.1 9.76297 19.1943 7.73921 17.7275 6.27241C16.2607 4.80561 14.237 3.89998 12 3.89998Z'
        fill='currentColor'
      />
    </svg>
  );
}
