import { IToggleButtonProps } from './NewToggleButton.props';
import { Btn, ButtonWrapper } from './NewToggleButton.styled';

import { cn } from '../../../lib';

const NewToggleButton: React.FC<IToggleButtonProps> = (props) => {
  const { className, isActive, disabled, children, onClick } = props;

  return (
    <ButtonWrapper className={cn(className && `${className}`)}>
      <Btn
        className={cn(isActive && 'active')}
        type='button'
        disabled={disabled}
        onClick={onClick}
      >
        <span className='circle'></span>
      </Btn>
      {children}
    </ButtonWrapper>
  );
};

export default NewToggleButton;
