import React, { useState, useRef, useEffect } from 'react';
import {
  MultiSelectWrapper,
  Label,
  Placeholder,
  Wrapper,
  OptionsContainer,
  Option,
  SelectedOptions,
  SelectedOption,
  SelectArrow
} from './MultiSelect.styled';
import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../lib';
import { IMultiSelectProps, IOption } from './MultiSelect.props';
import DinamycHeight from '../../AutoHeight/DinamycHeight';
import { ErrorText } from '../../Input/ui/Input.styled';

export const MultiSelect: React.FC<IMultiSelectProps> = ({
  placeholder,
  direction,
  size,
  optionPosition,
  value,
  setValue,
  options,
  error = null,
  styles,
  className
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsFocused(false);
        setOptionsVisible(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleLabelClick = () => {
    setIsFocused(true);
    setOptionsVisible(!optionsVisible);
  };

  const handleOptionClick = (event: React.MouseEvent, option: IOption) => {
    event.stopPropagation();
    if (value.some((selected) => selected.id === option.id)) {
      setValue(value.filter((selected) => selected.id !== option.id));
    } else {
      setValue([...value, option]);
    }
  };

  const handleRemoveOption = (event: React.MouseEvent, option: IOption) => {
    event.stopPropagation();
    setValue(value.filter((selected) => selected.id !== option.id));
  };

  const handleOptionContainerBlur = () => {
    if (!optionsVisible) {
      setIsFocused(false);
    }
  };

  const handleSelectAll = () => {
    if (value.length === options.length) {
      setValue([]);
    } else {
      setValue([...options]);
    }
  };

  return (
    <MultiSelectWrapper
      ref={wrapperRef}
      size={size}
      className={cn({ error: !!error })}
    >
      <Label
        className={cn(isFocused && 'focused', className, { error: !!error })}
        size={size}
        onClick={handleLabelClick}
      >
        {value.length === 0 && <Placeholder size={size}>{placeholder}</Placeholder>}
        <DinamycHeight>
          <Wrapper
            size={size}
            $direction={direction}
          >
            {value.map((selectedOption) => (
              <SelectedOptions
                size={size}
                key={selectedOption.id}
                style={styles}
              >
                <div className={'tag'}>
                  <SelectedOption size={size}>{selectedOption.name || selectedOption.fio}</SelectedOption>
                  <button onClick={(e) => handleRemoveOption(e, selectedOption)}>
                    <IconInstance name={EIcon.close} />
                  </button>
                </div>
              </SelectedOptions>
            ))}
          </Wrapper>
        </DinamycHeight>
        <SelectArrow
          onClick={(e) => {
            e.preventDefault();
          }}
          size={size}
          className={cn(optionsVisible && 'active')}
        >
          <IconInstance name={EIcon.chevrondownsmall} />
        </SelectArrow>
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : null}

      <OptionsContainer
        className={cn(optionsVisible && 'active')}
        $position={optionPosition}
        ref={optionsContainerRef}
        onBlur={handleOptionContainerBlur}
      >
        {options.map((option) => (
          <Option
            size={size}
            key={option?.id}
            onClick={(e) => handleOptionClick(e, option)}
          >
            <div>
              <input
                type='checkbox'
                onChange={() => {}}
                checked={value.some((selected) => selected.id === option?.id)}
                className='inviteemployeer-input'
              />
              <span className={cn(value.some((selected) => selected.id === option.id) && 'active')}>
                <IconInstance name={EIcon.success} />
              </span>
            </div>
            <p>{option?.name || option?.fio}</p>
          </Option>
        ))}
      </OptionsContainer>
    </MultiSelectWrapper>
  );
};
