export const PaymentIcon4 = () => {
  return (
    <svg
      width='35'
      height='34'
      viewBox='0 0 35 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.4888 1.30924e-05H17.4921H17.4888ZM17.4888 1.30924e-05C13.1041 -0.00533057 8.82276 1.73187 5.68975 4.75238L17.4888 1.30924e-05ZM17.4921 0.500013L17.4927 0.500013C21.0267 0.495558 24.523 1.66348 27.342 3.76145L24.8487 5.59827C21.1233 3.18764 16.3916 2.75801 12.2913 4.45922C9.07608 5.79318 6.47394 8.3478 5.07451 11.4946L5.0745 11.4946C3.57434 14.868 3.52448 18.7849 4.94054 22.1951C6.26135 25.3759 8.79938 27.9977 11.9796 29.4103L12.1826 28.9533L11.9797 29.4103C15.3605 30.9119 19.2678 30.9637 22.6853 29.546C25.8998 28.2126 28.5031 25.6566 29.9035 22.5078L29.9035 22.5077C30.7007 20.7149 31.042 18.8259 31.0694 16.9057L33.8641 14.8471C34.2489 17.842 33.8135 20.93 32.5849 23.6973C30.8851 27.526 27.7147 30.6346 23.8061 32.2536C19.6568 33.9724 14.8924 33.9096 10.7892 32.0896C6.92717 30.3764 3.832 27.1793 2.23101 23.3182L1.76914 23.5097L2.231 23.3182C-0.333456 17.1338 1.19137 9.76389 6.03602 5.11308L6.03679 5.11234C9.07678 2.1815 13.2336 0.494828 17.4882 0.500013H17.4888L17.4921 0.500013ZM11.5161 17.0716V13.3701L17.2268 16.9482L17.5151 17.1288L17.7889 16.927L30.8476 7.30455C31.4263 8.09961 31.9329 8.94464 32.3615 9.82962L17.4696 20.8017L11.5161 17.0716Z'
        fill='white'
        stroke='currentColor'
      />
    </svg>
  );
};
