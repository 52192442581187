import React from 'react';

export function StarService() {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.20281 1.96562C9.10905 1.91726 8.97181 1.94265 8.90335 2.08761L7.118 5.86796C6.87215 6.38851 6.38807 6.76535 5.80954 6.8532L1.81736 7.45941C1.77367 7.46605 1.72758 7.48831 1.68857 7.53014C1.58149 7.64493 1.58467 7.84065 1.69316 7.95116L4.58192 10.8938C4.98611 11.3055 5.16323 11.8887 5.07057 12.4533L4.38863 16.6083C4.3774 16.6767 4.38853 16.7459 4.41766 16.8038C4.4887 16.945 4.62526 16.9679 4.71897 16.9164L8.28967 14.9546C8.80201 14.6732 9.41786 14.6732 9.9302 14.9546L13.5009 16.9164C13.544 16.94 13.59 16.9468 13.6334 16.9391C13.6964 16.9278 13.7653 16.8873 13.8156 16.8175C13.864 16.7505 13.8806 16.6771 13.8703 16.6105L13.1493 12.4533C13.0566 11.8887 13.2338 11.3055 13.6379 10.8938L16.5267 7.95116C16.5668 7.9103 16.5972 7.85195 16.6068 7.78322C16.6327 7.59704 16.5072 7.47531 16.4025 7.45941L12.4103 6.8532C11.8318 6.76535 11.3477 6.38851 11.1019 5.86796L9.31652 2.08761C9.28882 2.02895 9.24668 1.98824 9.20281 1.96562ZM7.547 1.44704C7.96086 0.570737 9.00685 0.176811 9.89037 0.632481C10.2357 0.810563 10.5085 1.09903 10.6729 1.44704L12.4582 5.22739C12.4998 5.31546 12.5713 5.36046 12.6355 5.3702L16.6277 5.97641C17.6015 6.12428 18.2233 7.04896 18.0925 7.98981C18.0396 8.36987 17.8678 8.7263 17.5971 9.00199L14.7084 11.9446C14.6479 12.0061 14.6129 12.1044 14.6291 12.2079L15.3501 16.3653C15.5108 17.3444 14.8251 18.2494 13.898 18.4155C13.5137 18.4844 13.1197 18.4184 12.7786 18.231L9.20793 16.2693C9.1454 16.235 9.07447 16.235 9.01194 16.2693L5.44124 18.231C4.57031 18.7095 3.51376 18.3448 3.07767 17.4779C2.90485 17.1344 2.8463 16.7439 2.90843 16.3653L3.59038 12.2103C3.60751 12.1059 3.5724 12.0066 3.51152 11.9446L0.622753 9.00199C-0.0491608 8.31755 -0.0619514 7.20772 0.591678 6.50699C0.855583 6.22406 1.20641 6.03499 1.59217 5.97641L5.58434 5.3702C5.64853 5.36046 5.72006 5.31546 5.76165 5.22739L7.547 1.44704Z'
        fill='#BCBCC2'
      />
    </svg>
  );
}
