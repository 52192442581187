import { createAsyncThunk } from '@reduxjs/toolkit';
import { NotesService } from '../../../shared/api/model/service/notes.service';

export const fetchNotesAsync = createAsyncThunk('notes/fetchNotes', async () => {
  return await NotesService.fetchNotes();
});

export const createNotesAsync = createAsyncThunk('notes/createNotes', async (data: { text: string }, { dispatch }) => {
  await NotesService.createNotes(data);
  dispatch(fetchNotesAsync());
});

export const deleteNoteAsync = createAsyncThunk('notes/deleteNote', async (data: { id: string }, { dispatch }) => {
  await NotesService.deleteNote(data);
  dispatch(fetchNotesAsync());
});
