import React from 'react';

export function WarningIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 12C1.5 5.92472 6.42472 1 12.5 1C18.5753 1 23.5 5.92472 23.5 12C23.5 18.0753 18.5753 23 12.5 23C6.42472 23 1.5 18.0753 1.5 12ZM12.5 3C7.52928 3 3.5 7.02928 3.5 12C3.5 16.9707 7.52928 21 12.5 21C17.4707 21 21.5 16.9707 21.5 12C21.5 7.02928 17.4707 3 12.5 3ZM12.5 7C13.0523 7 13.5 7.44772 13.5 8V12C13.5 12.5523 13.0523 13 12.5 13C11.9477 13 11.5 12.5523 11.5 12V8C11.5 7.44772 11.9477 7 12.5 7ZM11.5 16C11.5 15.4477 11.9477 15 12.5 15H12.51C13.0623 15 13.51 15.4477 13.51 16C13.51 16.5523 13.0623 17 12.51 17H12.5C11.9477 17 11.5 16.5523 11.5 16Z'
        fill='currentColor'
      />
    </svg>
  );
}
