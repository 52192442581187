import React, { FC, useEffect } from 'react';
import MessengerChat from '../chat/MessengerChat';
import {
  sendGetRecordsRequest,
  sendMessage,
  startMessagesListening,
  stopMessagesListening
} from '../../../../store/redux/websocket/chat-reducer';
import { useTranslation } from 'react-i18next';
import { useActions } from '../../../../shared/lib/hooks/useActions';
import { useFilialQuery } from '../../../../store/redux/filial/hooks/useFilialQuery';
import { useTypedSelector } from '../../../../shared/lib/hooks/useTypedSelector';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MobileMessengerWrapper, Notify } from '../content/MessengerContent.styled';
import { LoadingAnimation } from '../content/LoadingAnimation';
import { Loader, Text, TextPType, TextType } from '../../../../shared/ui';
import { getCookie } from '../../../../utils/cookies';

const MobileChat: FC = () => {
  const { roomId } = useParams();
  const { t } = useTranslation();
  const { setChatRoomId } = useActions();
  const { data: activeFilial, isLoading } = useFilialQuery();
  const { fetchUserByIdAsync } = useActions();
  const { status, userChats, messages } = useTypedSelector((state) => state.chat);
  const { profileUser, state: userState } = useTypedSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserByIdAsync(getCookie('id'));
  }, []);
  const userRole = profileUser.role;

  const techChatSupport = userChats.find((item) => item.is_tech_support)?.chat_id;

  useEffect(() => {
    const handleStartMessagesListening = async () => {
      await dispatch(startMessagesListening(profileUser?.fio) as any);
    };

    handleStartMessagesListening();

    return () => {
      dispatch(stopMessagesListening() as any);
    };
  }, []);

  useEffect(() => {
    if (roomId === 'telebot') {
      const userRole = profileUser.role;
      if (activeFilial) {
        setInterval(() => {
          dispatch(sendGetRecordsRequest({ filialId: activeFilial.id || '', filialName: activeFilial.name || '', userRole }) as any);
        }, 5 * 60 * 10);
        setChatRoomId('telebot');
        dispatch(sendMessage(JSON.stringify({ type: 'get_records', data: { filial_id: activeFilial.id } })) as any);
      }
    } else if (status === 'ready') {
      setChatRoomId((roomId === 'support' ? techChatSupport : roomId) || '');
      console.log(techChatSupport);
      dispatch(
        sendMessage(
          JSON.stringify({ type: 'get_chat_messages', data: { chat_id: roomId === 'support' ? techChatSupport : roomId } })
        ) as any
      );
    }
  }, [status]);

  useEffect(() => {
    dispatch(startMessagesListening(profileUser?.fio) as any);
    dispatch(sendMessage(JSON.stringify({ type: 'get_chat_messages', data: { chat_id: roomId === 'support' && techChatSupport } })) as any);
    console.log(techChatSupport);
  }, []);

  return (
    <>
      {status === 'error' && (
        <Notify $align={'center'}>
          <LoadingAnimation />
          <Text
            $tag={TextType.P}
            $pSize={TextPType.P15}
          >
            Восстановление соединения
          </Text>
        </Notify>
      )}
      {status === 'pending' ? (
        <Loader />
      ) : (
        <MobileMessengerWrapper>
          <MessengerChat />
        </MobileMessengerWrapper>
      )}
    </>
  );
};

export default MobileChat;
