import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISaleProductCategory } from './saleproductscategory.interface';

const initialState = {
  saleproductcategory: [] as ISaleProductCategory[],
  activeFilter: 'Все товары'
};

export const saleproductscategorySlice = createSlice({
  name: 'saleproductcategory',
  initialState,
  reducers: {
    setSaleroductCategory: (state, action: PayloadAction<any>) => {
      state.saleproductcategory = action.payload;
    },
    setActiveFilterCategory: (state, action: PayloadAction<any>) => {
      state.activeFilter = action.payload;
    }
  }
});

export const { reducer, actions } = saleproductscategorySlice;

export const { setSaleroductCategory } = actions;