import React from 'react';

export function ImportIcon() {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V13.0142L12.736 10.2038C13.0376 9.91996 13.5123 9.93435 13.7962 10.236C14.08 10.5376 14.0657 11.0123 13.764 11.2962L9.51402 15.2962C9.22524 15.568 8.77477 15.568 8.48598 15.2962L4.23598 11.2962C3.93435 11.0123 3.91996 10.5376 4.20385 10.236C4.48774 9.93435 4.96239 9.91996 5.26402 10.2038L8.25 13.0142V1.25C8.25 0.835786 8.58579 0.5 9 0.5ZM0 17.75C0 17.3358 0.335786 17 0.75 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5H0.75C0.335786 18.5 0 18.1642 0 17.75Z'
        fill='#6C6E7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V13.0142L12.736 10.2038C13.0376 9.91996 13.5123 9.93435 13.7962 10.236C14.08 10.5376 14.0657 11.0123 13.764 11.2962L9.51402 15.2962C9.22524 15.568 8.77477 15.568 8.48598 15.2962L4.23598 11.2962C3.93435 11.0123 3.91996 10.5376 4.20385 10.236C4.48774 9.93435 4.96239 9.91996 5.26402 10.2038L8.25 13.0142V1.25C8.25 0.835786 8.58579 0.5 9 0.5ZM0 17.75C0 17.3358 0.335786 17 0.75 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5H0.75C0.335786 18.5 0 18.1642 0 17.75Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V13.0142L12.736 10.2038C13.0376 9.91996 13.5123 9.93435 13.7962 10.236C14.08 10.5376 14.0657 11.0123 13.764 11.2962L9.51402 15.2962C9.22524 15.568 8.77477 15.568 8.48598 15.2962L4.23598 11.2962C3.93435 11.0123 3.91996 10.5376 4.20385 10.236C4.48774 9.93435 4.96239 9.91996 5.26402 10.2038L8.25 13.0142V1.25C8.25 0.835786 8.58579 0.5 9 0.5ZM0 17.75C0 17.3358 0.335786 17 0.75 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5H0.75C0.335786 18.5 0 18.1642 0 17.75Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V13.0142L12.736 10.2038C13.0376 9.91996 13.5123 9.93435 13.7962 10.236C14.08 10.5376 14.0657 11.0123 13.764 11.2962L9.51402 15.2962C9.22524 15.568 8.77477 15.568 8.48598 15.2962L4.23598 11.2962C3.93435 11.0123 3.91996 10.5376 4.20385 10.236C4.48774 9.93435 4.96239 9.91996 5.26402 10.2038L8.25 13.0142V1.25C8.25 0.835786 8.58579 0.5 9 0.5ZM0 17.75C0 17.3358 0.335786 17 0.75 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5H0.75C0.335786 18.5 0 18.1642 0 17.75Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V13.0142L12.736 10.2038C13.0376 9.91996 13.5123 9.93435 13.7962 10.236C14.08 10.5376 14.0657 11.0123 13.764 11.2962L9.51402 15.2962C9.22524 15.568 8.77477 15.568 8.48598 15.2962L4.23598 11.2962C3.93435 11.0123 3.91996 10.5376 4.20385 10.236C4.48774 9.93435 4.96239 9.91996 5.26402 10.2038L8.25 13.0142V1.25C8.25 0.835786 8.58579 0.5 9 0.5ZM0 17.75C0 17.3358 0.335786 17 0.75 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5H0.75C0.335786 18.5 0 18.1642 0 17.75Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V13.0142L12.736 10.2038C13.0376 9.91996 13.5123 9.93435 13.7962 10.236C14.08 10.5376 14.0657 11.0123 13.764 11.2962L9.51402 15.2962C9.22524 15.568 8.77477 15.568 8.48598 15.2962L4.23598 11.2962C3.93435 11.0123 3.91996 10.5376 4.20385 10.236C4.48774 9.93435 4.96239 9.91996 5.26402 10.2038L8.25 13.0142V1.25C8.25 0.835786 8.58579 0.5 9 0.5ZM0 17.75C0 17.3358 0.335786 17 0.75 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5H0.75C0.335786 18.5 0 18.1642 0 17.75Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0.5C9.41421 0.5 9.75 0.835786 9.75 1.25V13.0142L12.736 10.2038C13.0376 9.91996 13.5123 9.93435 13.7962 10.236C14.08 10.5376 14.0657 11.0123 13.764 11.2962L9.51402 15.2962C9.22524 15.568 8.77477 15.568 8.48598 15.2962L4.23598 11.2962C3.93435 11.0123 3.91996 10.5376 4.20385 10.236C4.48774 9.93435 4.96239 9.91996 5.26402 10.2038L8.25 13.0142V1.25C8.25 0.835786 8.58579 0.5 9 0.5ZM0 17.75C0 17.3358 0.335786 17 0.75 17H17.25C17.6642 17 18 17.3358 18 17.75C18 18.1642 17.6642 18.5 17.25 18.5H0.75C0.335786 18.5 0 18.1642 0 17.75Z'
        fill='black'
        fillOpacity='0.2'
      />
    </svg>
  );
}
