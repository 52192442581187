import dayjs from 'dayjs';

import { AnalyticsState, ICabinetLessonsData, ICabinetWorkingHours } from '../../../../store/redux/analytics/analytics.interface';

export const getGroupLessons = (state: AnalyticsState) => {
  const prevDate = dayjs().subtract(1, 'month');
  const dataLessons = state.lessons;
  if (dataLessons.length > 0) {
    state.groupLessons = dataLessons.filter((lesson: any) => lesson.SubproductFull.group === 'yes' && dayjs(lesson.end).isBefore(dayjs()));
    state.groupLessonIndexData.seatsAll = state.groupLessons.reduce((acc, current) => acc + current.seats, 0);
    state.groupLessonIndexData.seats = state.groupLessons.reduce(
      (acc, current) => acc + current.ClientId?.filter((item: any) => item.confirmation === 3).length || 0,
      0
    );
    state.groupLessonIndexData.seatsEmpty = state.groupLessonIndexData.seatsAll - state.groupLessonIndexData.seats;

    state.groupLessonIndexData.timeall = Number(
      state.groupLessons
        .filter((lesson: any) => prevDate.isBefore(lesson.start))
        .reduce((acc, current) => acc + dayjs(current.end).diff(current.start, 'hour', true), 0)
        .toFixed(2)
    );
  } else {
    state.groupLessonIndexData = { seats: 0, seatsAll: 10, seatsEmpty: 10, timeall: 0 };
    state.groupLessons = [];
  }
};

export const getIndividualLessons = (state: AnalyticsState) => {
  const prevDate = dayjs().subtract(1, 'month');
  const dataLessons = state.lessons;
  if (dataLessons.length > 0) {
    state.individualLessons = dataLessons.filter(
      (lesson: any) => lesson.SubproductFull.group === 'no' && dayjs(lesson.end).isBefore(dayjs())
    );
    state.individualLessonsData.count = state.individualLessons.length;
    state.individualLessonsData.timeall = Number(
      state.individualLessons
        .filter((lesson: any) => prevDate.isBefore(lesson.start))
        .reduce((acc, current) => acc + dayjs(current.end).diff(current.start, 'hour', true), 0)
        .toFixed(2)
    );
  } else {
    state.individualLessons = [];
  }

  return state.individualLessons;
};

export const getWorkingHours = (state: AnalyticsState): number => {
  const workingHours: ICabinetWorkingHours[] = [];

  let cabinetsData = [];

  if (state.cabinets.length > 0) {
    cabinetsData = state.cabinets.map((cabinet) => {
      let prevDate = dayjs().subtract(1, 'month');
      let cabinetWorkingHours = 0;
      cabinet.timecreate !== '' && prevDate.isBefore(dayjs(cabinet.timecreate)) ? (prevDate = dayjs(cabinet.timecreate)) : prevDate;
      const intervalLength = dayjs().diff(prevDate, 'day');

      for (let i = 0; i < intervalLength; i++) {
        const currDate = prevDate.add(i, 'day');
        if (state.filials.length > 0) {
          if (state.filials[0].timework?.length) {
            const filialTime = state.filials[0]?.timework[0];
            const weekDayObj = filialTime[currDate.locale('en').format('dddd').toLocaleLowerCase()];
            if (weekDayObj && weekDayObj.timestart && weekDayObj.timestart !== '') {
              cabinetWorkingHours += Math.abs(Number(weekDayObj.timeend.split(':')[0]) - Number(weekDayObj.timestart.split(':')[0]));
            }
          }
        }
      }
      workingHours.push({ id: cabinet.id, workingTimeAll: cabinetWorkingHours === 0 ? 8 : cabinetWorkingHours });
      state.cabinetWorkingHours = workingHours;

      return cabinetWorkingHours !== 0 ? cabinetWorkingHours : 8;
    });
    state.filialsWorkingHours = cabinetsData.reduce((acc: number, current) => acc + current, 0);
  }
  if (state.filialsWorkingHours === 0) {
    state.filialsWorkingHours = 1;
  }

  return state.filialsWorkingHours;
};

export const getCabinetLessons = (state: AnalyticsState): ICabinetLessonsData[] => {
  const prevDate = dayjs().subtract(1, 'month');
  const dataLessons = state.lessons;
  if (dataLessons.length > 0 && state.cabinets.length > 0) {
    const dataCabinetsIds = state.cabinets.map((cabinet) => cabinet.id);
    state.cabinetLessons = dataCabinetsIds.map((id: string) => ({
      id: id,
      name: state.cabinets.find((cabinet) => cabinet.id === id)?.name || 'кабинет',
      timeall: Number(
        dataLessons
          .filter((lesson) => lesson.resourceId === id && prevDate.isBefore(lesson.start) && dayjs(lesson.end).isBefore(dayjs()))
          .reduce((acc: number, current) => acc + dayjs(current.end).diff(current.start, 'hour', true), 0)
          .toFixed(2)
      ),
      workingTimeAll: state.cabinetWorkingHours.find((item) => item.id === id)?.workingTimeAll || 0
    }));
  } else {
    state.cabinetLessons = [];
  }

  return state.cabinetLessons;
};
