export function ArrowUpOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3681 3.25911C11.7185 2.91363 12.2815 2.91363 12.6319 3.25911L19.7319 10.2591C20.0858 10.6081 20.0899 11.1779 19.7409 11.5319C19.3919 11.8858 18.8221 11.8899 18.4681 11.5409L12.9 6.05118V20.1C12.9 20.5971 12.4971 21 12 21C11.5029 21 11.1 20.5971 11.1 20.1V6.05119L5.53187 11.5409C5.17791 11.8899 4.60808 11.8858 4.25911 11.5319C3.91014 11.1779 3.91418 10.6081 4.26813 10.2591L11.3681 3.25911Z'
        fill='currentColor'
      />
    </svg>
  );
}
