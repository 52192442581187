import { CallRecordsState, IRecordCall, typedRecordsData } from './callRecords.interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: CallRecordsState = {
  records: typedRecordsData.records,
};

export const callRecordsSlice = createSlice({
  name: 'callRecords',
  initialState,
  reducers: {
    setRecords: (state, action: PayloadAction<IRecordCall[]>) => {
      state.records = action.payload;
    },
  },
});

export const  {reducer} = callRecordsSlice