import React from 'react';
import { useParams } from 'react-router-dom';

import LogoImg from '../../../../../../assets/images/logo.png';
import TelegramImg from '../../../../../../assets/images/telegram.png';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import { useProfileUserQuery } from '../../../../../../store/redux/user/hooks/useUsersQuery';
import { IUser } from '../../../../../../store/redux/user/user.interface';
import { IChatListData, IChatMessageData } from '../../../../../../store/redux/websocket/chat-api';
import { CircleWrapper } from '../../../../../../styles/circles.styled';
import { getCookie } from '../../../../../../utils/cookies';
import { firstTwoLetters, getGreyColorForSymbol } from '../../../../lib/messenger.helper';
import { DialogAvatar } from '../../../side/MessengerSide.styled';

interface IProps {
  isSupportChat: IChatListData | undefined;
  message: IChatMessageData;
}

const MessegeSenderAvatar: React.FC<IProps> = (props) => {
  const { message, isSupportChat } = props;
  const { userChats } = useTypedSelector((state) => state.chat);
  const { roomId } = useParams();
  const findChat = userChats.find((item) => item.chat_id === roomId);
  const { user } = useTypedSelector((state) => state.user);
  const { data: profileUser } = useProfileUserQuery(getCookie('id'));

  return (
    <CircleWrapper
      size={'40'}
      $background={'greyGradient'}
    >
      {findChat?.is_tech_support === false ? (
        <DialogAvatar
          className='chat-avatar'
          style={{
            background: `${getGreyColorForSymbol(firstTwoLetters(user?.find((item) => item.id === message.sender)?.fio))}`
          }}
        >
          <img
            alt={'user-avatar'}
            src={user?.find((item) => item.id === message.sender)?.avatar}
          />
        </DialogAvatar>
      ) : roomId === 'support' && profileUser?.id !== ('6686be7821ca69fc50d76931' || '65a8d81df5353df12690729f') ? (
        message.sender !== profileUser?.id ? (
          <DialogAvatar className='chat-avatar'>
            <img
              alt={'telebon-supprot'}
              src={LogoImg}
            />
          </DialogAvatar>
        ) : (
          <DialogAvatar
            className='avatar-msg-side'
            style={{
              background: 'linear-gradient(225deg, #EEEEEE 0%, #D8D8D8 100%)'
            }}
          >
            {user?.find((item) => item.id === message.sender)?.avatar ? (
              <img
                alt={'user-avatar'}
                src={user?.find((item) => item.id === message.sender)?.avatar}
              />
            ) : (
              firstTwoLetters(user?.find((item: IUser) => item.id === message.sender)?.fio)
            )}
          </DialogAvatar>
        )
      ) : roomId === 'support' && (profileUser?.id === '6686be7821ca69fc50d76931' || profileUser?.id === '65a8d81df5353df12690729f') ? (
        message.sender == profileUser?.id ? (
          <DialogAvatar className='chat-avatar'>
            <img
              alt={'telebon-supprot'}
              src={LogoImg}
            />
          </DialogAvatar>
        ) : (
          <DialogAvatar
            className='avatar-msg-side'
            style={{
              background: 'linear-gradient(225deg, #EEEEEE 0%, #D8D8D8 100%)'
            }}
          >
            {firstTwoLetters(user?.find((item: IUser) => item.id === message.sender)?.fio)}
          
            {/* {user?.find((item) => item.id === message.sender)?.avatar ? (
              <img
                alt={'user-avatar'}
                src={user?.find((item) => item.id === message.sender)?.avatar}
              />
            ) : (
              firstTwoLetters(user?.find((item: IUser) => item.id === message.sender)?.fio)
            )} Когда появится аватар у пользователей, если мы находимся в аккаунте поддержки*/}
          </DialogAvatar>
        )
        // <DialogAvatar className='chat-avatar'>
        //   <img
        //     alt={'telebon-supprot'}
        //     src={LogoImg}
        //   />
        // </DialogAvatar>
      ) : roomId === 'telebot' ? (
        <img
          alt={'telegram-avatar'}
          src={user?.find((item) => item.id === message.sender) ? user?.find((item) => item.id === message.sender)?.avatar : TelegramImg}
        />
      ) : (
        ''
      )}
    </CircleWrapper>
  );
};

export default MessegeSenderAvatar;
