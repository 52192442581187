import { PaymentsState } from './payments.interface';
import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { createSlice } from '@reduxjs/toolkit';
import { fetchPaymentsAsync } from './payments.actions';

const initialState: PaymentsState = {
  payments: [],
  state: PENDING
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchPaymentsAsync.fulfilled, (state, action) => {
        state.payments = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchPaymentsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const {reducer} = paymentsSlice