import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ClientsState, IClient, IClientClassificator } from './clients.interface';
import { ClientsService } from '../../../shared/api/model/service/clients.service';

export const setClients = createAction<IClient[]>('clients/setClients');
export const setClientsState = createAction<ClientsState>('clients/setClientsState');
export const setClientClassificators = createAction<IClientClassificator[]>('clients/setClientClassificators');

export const fetchClientsAsync = createAsyncThunk('clients/fetchClients', async () => {
  return await ClientsService.fetchClients();
});
