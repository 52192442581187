import { ITask } from '../../../../store/redux/tasks/tasks.interface';
import { apiDelete, apiPost, apiPut } from '../../../../utils/apiInstance';
import { apiGet } from '../../interceptors';

export const TasksService = {
  async fetchTasks() {
    const response = await apiGet('/tasker/columns');

    return response.data && response.data.taskcolomns ? response.data.taskcolomns : [];
  },
  async createColumn() {
    const response = await apiPut('/tasker/columns', { name: 'Новая колонка' });

    return response.data;
  },
  async updateColumn({ listId, name }: { listId: string; name: string }) {
    return await apiPost(`/tasker/columns/${listId}`, { name });
  },
  async moveTask({ columnid, order, taskId }: { columnid: string; order: number; taskId: string }) {
    return await apiPost(`/tasker/task/${taskId}`, { columnid, order });
  },
  async updateTask({
    data,
    taskId
  }: {
    data: Omit<ITask, 'timecreate'> | { content: any; employeetaskid: any; ownertaskid: any; order: any };
    taskId: string;
  }) {
    return await apiPost(`/tasker/task/${taskId}`, data);
  },
  async createTask(data: Omit<ITask, 'timecreate'>) {
    return await apiPut('/tasker/task', data);
  },
  async deleteTask(taskId: string) {
    return apiDelete(`/tasker/task/${taskId}`);
  }
};
