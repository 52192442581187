import { styled } from 'styled-components';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const ModalWrapper = styled(FlexWithAlign)`
  padding: 24px;
`;

export const ModalContent = styled(FlexWithAlign)`
  width: 100%;
`;

export const TextWrap = styled(FlexWithAlign)`
  padding: 0 10px;
  width: 100%;
  text-align: center;
`;
export const ButtonsWrap = styled(FlexWithAlign)`
  width: 100%;
`;
