import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchFilialsAsync, setActiveFilial } from './filial.actions';
import { FilialState } from './filial.interface';

import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';

const initialState: FilialState = {
  filials: [],
  activeFilial: undefined,
  state: PENDING,
  error: null,
  filialAddress: '',
  filialComment: '',
  filialModal: false,
  filialModalDay: '',
  filialModalEnd: '',
  filialModalStart: '',
  filialTimework: [
    {
      monday: {
        timestart: '10:00',
        timeend: '20:00',
        break: []
      },
      tuesday: {
        timestart: '10:00',
        timeend: '20:00',
        break: []
      },
      wednesday: {
        timestart: '10:00',
        timeend: '20:00',
        break: []
      },
      thursday: {
        timestart: '10:00',
        timeend: '20:00',
        break: []
      },
      friday: {
        timestart: '10:00',
        timeend: '20:00',
        break: []
      },
      saturday: {
        timestart: '10:00',
        timeend: '20:00',
        break: []
      },
      sunday: {
        timestart: '',
        timeend: '',
        break: []
      }
    }
  ]
};
export const filialSlice = createSlice({
  name: 'filial',
  initialState,
  reducers: {
    setFilialAddress: (state, action: PayloadAction<string>) => {
      state.filialAddress = action.payload;
    },
    setFilialComment: (state, action: PayloadAction<string>) => {
      state.filialComment = action.payload;
    },
    setFilialModal: (state, action: PayloadAction<boolean>) => {
      state.filialModal = action.payload;
    },
    setFilialModalDay: (state, action: PayloadAction<string>) => {
      state.filialModalDay = action.payload;
    },
    setFilialModalStart: (state, action: PayloadAction<string>) => {
      state.filialModalStart = action.payload;
    },
    setFilialModalEnd: (state, action: PayloadAction<string>) => {
      state.filialModalEnd = action.payload;
    },

    updateFilialDayTimework: (
      state,
      action: PayloadAction<{
        day: string;
        start: string;
        end: string;
        breaks: { breakstart: string; breakfinish: string }[];
      }>
    ) => {
      const { day, start, end, breaks } = action.payload;
      const dayKey = day.toLowerCase();
      const timework = state.filialTimework[0];
      if (timework[dayKey as keyof typeof timework]) {
        timework[dayKey as keyof typeof timework].timestart = start;
        timework[dayKey as keyof typeof timework].timeend = end;
        timework[dayKey as keyof typeof timework].break = breaks;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilialsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchFilialsAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
        state.filials = action.payload;
      })
      .addCase(fetchFilialsAsync.rejected, (state, action) => {
        state.state = REJECTED;
        state.error = action.error.message || null;
      })
      .addCase(setActiveFilial, (state, action: PayloadAction<string>) => {
        const id = action.payload;
        state.activeFilial = state.filials.find((filial) => filial.id === id);
        localStorage.setItem('currentFilialId', id);
      });
  }
});

export const { reducer } = filialSlice;
