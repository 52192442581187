export const PaymentIcon11 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.65625 3.9375L5.84375 9.5625V12.375C5.84375 14.7049 7.73255 16.5938 10.0625 16.5938C12.3924 16.5938 14.2812 14.7049 14.2812 12.375C14.2812 14.7049 16.17 16.5938 18.5 16.5938C20.83 16.5938 22.7188 14.7049 22.7188 12.375C22.7188 14.7049 24.6075 16.5938 26.9375 16.5938C29.2675 16.5938 31.1562 14.7049 31.1562 12.375V9.5625L28.3438 3.9375H8.65625Z'
        stroke='currentColor'
      />
      <path
        d='M9.35938 20.8125V32.0625H27.6406V20.8125'
        stroke='currentColor'
      />
      <path
        d='M15.6875 26.4375H21.3125'
        stroke='currentColor'
      />
    </svg>
  );
};
