import React from 'react';

export function Pencil() {
  return (
    <svg
      width='25.63'
      height='25.63'
      viewBox='0 0 25.63 25.63'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.992 1.22359C18.6747 0.540928 19.5067 0.199594 20.488 0.199594C21.4694 0.199594 22.344 0.49826 23.112 1.09559L24.776 2.75959C25.4587 3.44226 25.8 4.2956 25.8 5.3196C25.8 6.3436 25.5014 7.19693 24.904 7.87959L8.90403 23.7516L1.86403 25.7996C1.43736 25.8849 1.03203 25.7569 0.648031 25.4156C0.264031 25.0743 0.114697 24.6903 0.200031 24.2636L2.12003 17.2236L2.24803 16.9676L17.992 1.22359ZM3.27203 18.8876L1.73603 24.2636L7.11203 22.7276L3.27203 18.8876ZM17.48 3.91159L3.91203 17.4796L8.52003 22.0876L22.088 8.51959L17.48 3.91159ZM22.088 2.3756C21.7467 1.94893 21.2774 1.73559 20.68 1.73559C20.0827 1.73559 19.5707 1.90626 19.144 2.24759L18.632 2.75959L23.24 7.3676L23.624 6.98359C24.0507 6.55693 24.2854 6.06626 24.328 5.51159C24.3707 4.95693 24.2214 4.46626 23.88 4.03959L22.088 2.3756Z'
        fill='#2A2C32'
      />
    </svg>
  );
}
