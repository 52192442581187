export function RefreshOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.2742 4.04221C9.91666 3.6973 9.90761 3.12907 10.2539 2.77304C10.6003 2.417 11.1709 2.40799 11.5284 2.7529L14.2258 5.35535C14.4011 5.52442 14.5 5.75703 14.5 6.00001C14.5 6.243 14.4011 6.4756 14.2258 6.64467L11.5284 9.2471C11.1708 9.59201 10.6003 9.58299 10.2539 9.22696C9.90761 8.87092 9.91666 8.30269 10.2742 7.95778L11.4488 6.8245C10.5714 6.90282 9.71437 7.16779 8.93756 7.60909C7.68308 8.32173 6.71642 9.44956 6.20408 10.7983C5.69175 12.147 5.66582 13.6322 6.13077 14.998C6.59573 16.3638 7.52245 17.5247 8.75129 18.2807C9.98013 19.0366 11.4341 19.3404 12.8629 19.1396C14.2916 18.9388 15.6056 18.246 16.5784 17.1806C17.5513 16.1152 18.1221 14.7439 18.1926 13.3028C18.2603 11.9178 17.8618 10.5517 17.0633 9.42185C16.7765 9.01593 16.873 8.45431 17.2789 8.16745C17.6848 7.88058 18.2464 7.9771 18.5333 8.38302C19.5636 9.84089 20.0779 11.6036 19.9904 13.3908C19.8995 15.2502 19.1629 17.0196 17.9076 18.3944C16.6523 19.7691 14.9569 20.663 13.1134 20.9221C11.2699 21.1812 9.39371 20.7892 7.80811 19.8138C6.22251 18.8383 5.02675 17.3404 4.42681 15.5781C3.82687 13.8158 3.86032 11.8994 4.52139 10.1591C5.18247 8.4188 6.42979 6.96353 8.04847 6.04399C9.05374 5.47292 10.1632 5.13068 11.2988 5.03074L10.2742 4.04221Z'
        fill='currentColor'
      />
    </svg>
  );
}
