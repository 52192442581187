export const PaymentIcon14 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.73408 5.34375C3.27283 7.4194 3.99166 11.5375 7.76643 11.6687'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M11.4688 15.8906H17.7969'
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        d='M24.8281 15.8906V18.7031'
        stroke='currentColor'
        strokeWidth='3'
      />
      <path
        d='M5.84375 15.8906V32.0625H11.4688L12.875 28.5469H19.9062L21.3125 32.0625H26.9375V27.9152C31.0198 26.8473 32.5806 24.6344 33.2656 22.9219V17.2969H30.4531L29.4991 12.5266C29.2362 11.2119 28.0819 10.2656 26.7412 10.2656H24.8281V4.64062C20.8906 4.64062 18.7344 8.39063 18.5 10.2656H11.4688C8.36215 10.2656 5.84375 12.784 5.84375 15.8906Z'
        stroke='currentColor'
        strokeWidth='2'
      />
    </svg>
  );
};
