import React from 'react';

export function BaloonTransparent() {
  return (
    <svg
      width='16'
      height='19'
      viewBox='0 0 16 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.3494 3.72021C10.0069 1.34847 6.21306 1.34847 3.87065 3.72021C1.52312 6.09714 1.52312 9.95472 3.87065 12.3316L8.11 16.6241L12.3494 12.3316C14.6969 9.95472 14.6969 6.09714 12.3494 3.72021ZM8.11 17.6914L7.57634 18.2184L2.80341 13.3857C-0.121136 10.4245 -0.121136 5.62734 2.80341 2.66617C5.73308 -0.300182 10.4869 -0.300182 13.4166 2.66617C16.3411 5.62734 16.3411 10.4245 13.4166 13.3857L8.64424 18.2178L8.11 17.6914ZM8.11 17.6914L8.64424 18.2178C8.50333 18.3605 8.31053 18.4414 8.11 18.4414C7.90947 18.4414 7.71725 18.3611 7.57634 18.2184L8.11 17.6914ZM8.11 5.94141C7.28157 5.94141 6.61 6.61298 6.61 7.44141C6.61 8.26983 7.28157 8.94141 8.11 8.94141C8.93843 8.94141 9.61 8.26983 9.61 7.44141C9.61 6.61298 8.93843 5.94141 8.11 5.94141ZM5.11 7.44141C5.11 5.78455 6.45315 4.44141 8.11 4.44141C9.76686 4.44141 11.11 5.78455 11.11 7.44141C11.11 9.09826 9.76686 10.4414 8.11 10.4414C6.45315 10.4414 5.11 9.09826 5.11 7.44141Z'
        fill='#BCBCC2'
      />
    </svg>
  );
}
