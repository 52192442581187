import styled from 'styled-components';

import themelight from '../../../../../app/providers/ThemeProvider/ui/theme';

export const Wrapper = styled.div`
  display: flex;
  padding: 27px 24px 24px 24px;
  .search {
    width: 100%;
    background-color: ${themelight.newColor.base05};
  }
  .title {
    line-height: 33px;
  }
  .cancel {
    font-weight: 700;
  }
`;
export const Form = styled.form`
  width: 100%;
`;

export const CloseBtn = styled.div`
  padding-left: 10px;
  padding-top: 5px;
  cursor: pointer;
`;

export const FilialsContanier = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  max-height: 378px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 378px;
  height: 100%;
`;

export const Filial = styled.div`
  max-width: 375px;
  width: 100%;
  max-height: 94px;
  height: 100%;
  border: 1px solid ${(props) => props.theme.color.base03};
  border-radius: 8px;
  padding: 19px 25px;
  display: flex;
  flex-direction: column;
  .filial-name {
    line-height: 29px;
  }
  .filial-address {
    line-height: 12px;
  }
`;

export const Status = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 8px;
  &.not-active-status span {
    color: ${(props) => props.theme.newColor.red};
  }
  &.active-status span {
    color: ${(props) => props.theme.color.toggleBtnBg};
  }
  .status-text {
    line-height: 12px;
  }
`;

export const StatusDot = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  &.not-active-dot {
    background-color: ${(props) => props.theme.newColor.red};
  }
  &.active-dot {
    background-color: ${(props) => props.theme.color.toggleBtnBg};
  }
`;

export const LoginNameContainer = styled.div`
  display: flex;
  gap: 16px;
  .system{
    line-height: 28px;
  }
  .profile-fio{
    line-height: 11px;
  }
`;

export const LoginAvatar = styled.div``;

export const LoginName = styled.div`
  display: flex;
  flex-direction: column;
`;
