import { IClassificator } from '../../../../store/redux/classificators/classificators.interface';
import { apiDelete, apiGet, apiPut } from '../../interceptors';

export const ClassificatorsService = {
  async fetchClassificators() {
    const response = await apiGet('/classificator');

    return response.data && response.data.classificator ? response.data.classificator : [];
  },
  async addClassificator(data: Omit<IClassificator, 'id'>) {
    const response = await apiPut('/classificator', data);

    return response.data;
  },
  async deleteClassificator(id: string) {
    const response = await apiDelete(`/classificator/${id}`);

    return response.data;
  }
};
