import styled from 'styled-components';

import themelight from '../../../../app/providers/ThemeProvider/ui/theme';
import { RADIUS_M, DURATION, Z_INDEX } from '../../../../styles';
import { NEW_INPUT_HEIGHT } from '../../../../styles/newAssets';
import { collapsIn, FlexWithAlign } from '../../../../utils/styleUtils';
import { IInputStyles } from '../../../ui/Input/ui/Input.styled';
export const InputContainer = styled.div<IInputStyles>`
  width: 100%;
  max-width: 140px;
  display: block;
  position: relative;
  appearance: none;
  border-radius: ${RADIUS_M};
  text-align: left;
  height: ${NEW_INPUT_HEIGHT};
  min-height: ${NEW_INPUT_HEIGHT};
  max-height: ${NEW_INPUT_HEIGHT};
`;

export const TimeListWrapper = styled.div`
  max-height: 8.5rem;
  height: 8rem;
  position: relative;
  z-index: 5;
  &::after {
    content: '';
    position: absolute;
    top: 6px;
    left: 16px;
    width: 12px;
    height: 12px;
    transform: rotate(-45deg);
    z-index: 10;
    background-color: ${themelight.newColor.base01};
  }
`;

export const TimeList = styled.div<IInputStyles>`
  padding: 16px 0;
  width: 100%;
  transform-origin: top;
  animation: ${collapsIn} 0.3s ease-in-out;
  background: ${(props) => props.theme.newColor.base01};
  max-height: 8.5rem;
  height: 8.5rem;
  overflow: hidden;
  position: absolute;
  z-index: ${Z_INDEX};
  margin-top: 0.75rem;
  border-radius: 8px;
  box-shadow: 0px 16px 48px 0px #3e35472e;
  isolation: isolate;
  pointer-events: auto;
  z-index: 100;

  .scrollable-wrapper {
    padding-bottom: 12px;
    display: flex;
    margin-right: 0;
    flex-direction: column;
    max-height: 8.5rem;
    justify-content: flex-start;
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${themelight.newColor.grey01};
      border-radius: 20px;
      width: 6px;
      height: 30px;
    }
    > div {
      padding: 0 30px 0 24px;
      color: ${themelight.newColor.primary};
      display: flex;
      align-items: center;
      box-sizing: border-box;
      font-size: 14px;
      cursor: pointer;
      span {
        display: flex;
        width: 100%;
        height: 100%;
        min-height: 43px;
        align-items: center;

        border-bottom: 1px solid ${themelight.newColor.base05};
      }
      &.selected-time {
        background-color: ${themelight.newColor.base08};
      }

      transition-timing-function: ease-in-out;
      transition-property: background;
      transition-duration: ${DURATION};
      &:hover {
        background: ${themelight.newColor.base07};
      }
    }
  }
`;
export const InputContent = styled.input<IInputStyles>`
  padding: 10px 26px 10px 16px;
  inset: 0;
  transition: box-shadow 0.01s;
  margin: 0;
  border-width: 0;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border: 1px solid ${(props) => props.theme.newColor.base08};
  background: ${(props) => props.theme.newColor.base01};
  color: ${(props) => props.theme.text.text01};
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  -webkit-text-fill-color: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  text-indent: 0px;
  text-align: center;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  resize: none;

  &.active {
    border-color: transparent;
  }

  &.active .error,
  &.error {
    border-color: ${(props) => props.theme.color.danger};
    &:focus + label {
      color: ${(props) => props.theme.color.danger};
    }
  }

  &:focus {
    & ~ div {
      svg {
        color: ${(props) => props.theme.color.mainLight};
      }
    }
    &[type='date'] {
      &::-webkit-datetime-edit-year-field,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field {
        color: ${(props) => props.theme.color.mainDark};
      }
    }
  }
  &:disabled {
    background-color: ${themelight.newColor.base05};
    color: ${themelight.newColor.grey06};
  }
  &.error {
    border: 1.5px solid #ffe1e1;
    background: #ffe1e1;
  }
  &[type='date']::-webkit-calendar-picker-indicator {
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  &[type='number']::-webkit-textfield-decoration-container {
  }
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-datetime-edit-year-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field {
    color: transparent;
  }
`;
export const Label = styled.label<IInputStyles>`
  width: 100%;
  max-width: 100%;
  user-select: none;
  display: block;
  transition-property: border-color, background;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  background: ${(props) => props.theme.newColor.base01};
  color: ${(props) => props.theme.text.text01};
  position: relative;
  appearance: none;
  border-radius: 5px;
  text-align: left;
  line-height: 1.5;
  height: ${NEW_INPUT_HEIGHT};
  min-height: ${NEW_INPUT_HEIGHT};
  max-height: ${NEW_INPUT_HEIGHT};

  &:hover:not(.disabled) {
    border-color: ${(props) => props.theme.text.text05};
  }
  &.disabled {
    opacity: 0.4;
    input {
      pointer-events: none;
    }
  }

  &:focus-within {
    span {
      top: 0;
      position: absolute;
      left: 1rem;
      z-index: 1;
    }
    &:after {
      border-width: 2px;
      color: ${(props) => props.theme.color.primary};
    }
  }
  span {
    display: flex;
    padding: 0 4px;
    background-color: ${themelight.newColor.base01};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    pointer-events: none;
    line-height: inherit;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    transition-property: transform, top;
    color: ${(props) => props.theme.text.text01};
    z-index: 1;
  }
  &.active,
  &.error {
    span {
      top: 0;
      border-radius: 4px;
      z-index: 2;
    }
  }
  &.error {
    span {
      color: ${(props) => props.theme.color.danger};
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 1rem;
  height: 100%;
  width: fit-content;
  margin-left: auto;
  justify-content: flex-end;
  border: none !important;
  outline: none !important;
  z-index: 3;
  align-items: center;
  cursor: pointer;
  & .age {
    padding: 4px;
    background-color: ${(props) => props.theme.color.base01};
    position: absolute;
    top: -32px;
    right: -10px;
    color: ${(props) => props.theme.color.mainLight};
    white-space: nowrap;
    &.record {
      top: 0;
      right: -24px;
    }
  }

  svg {
    display: block;
    width: 10px;
    height: 5px;
  }
  &.active {
  }
  &.active.error,
  &.error {
    svg {
      color: ${themelight.newColor.error};
    }
    .age {
      opacity: 0;
    }
  }
`;

export const ErrorText = styled.p<IInputStyles>`
  position: absolute;
  top: 95%;
  left: 5px;
  color: ${(props) => props.theme.color.danger};
`;

export const TimeListSheet = styled(FlexWithAlign)`
  width: 100%;
  justify-content: center;
  .scrollable-wrapper-mobile {
    width: 100%;
    height: 85%;
    overflow-y: auto;
    > div {
      border-bottom: 1px solid ${(props) => props.theme.color.base04};
      width: 100%;
      text-align: center;
      padding-block: 12px;
      &.selected-time {
        background-color: ${(props) => props.theme.color.secondary};
      }
    }
  }
`;
