import React from 'react';

export function CloseFill() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.20757 7.29304C9.01897 7.11088 8.76637 7.01009 8.50417 7.01237C8.24197 7.01465 7.99116 7.11981 7.80575 7.30522C7.62034 7.49063 7.51517 7.74144 7.5129 8.00364C7.51062 8.26584 7.61141 8.51844 7.79357 8.70704L11.0866 12L7.79357 15.293C7.69806 15.3853 7.62188 15.4956 7.56947 15.6176C7.51706 15.7396 7.48947 15.8709 7.48832 16.0036C7.48717 16.1364 7.51247 16.2681 7.56275 16.391C7.61303 16.5139 7.68728 16.6255 7.78117 16.7194C7.87507 16.8133 7.98672 16.8876 8.10962 16.9379C8.23251 16.9881 8.36419 17.0134 8.49697 17.0123C8.62975 17.0111 8.76097 16.9836 8.88297 16.9311C9.00498 16.8787 9.11532 16.8026 9.20757 16.707L12.5006 13.414L15.7936 16.707C15.9822 16.8892 16.2348 16.99 16.497 16.9877C16.7592 16.9854 17.01 16.8803 17.1954 16.6949C17.3808 16.5095 17.486 16.2586 17.4882 15.9964C17.4905 15.7342 17.3897 15.4816 17.2076 15.293L13.9146 12L17.2076 8.70704C17.3897 8.51844 17.4905 8.26584 17.4882 8.00364C17.486 7.74144 17.3808 7.49063 17.1954 7.30522C17.01 7.11981 16.7592 7.01465 16.497 7.01237C16.2348 7.01009 15.9822 7.11088 15.7936 7.29304L12.5006 10.586L9.20757 7.29304Z'
        fill='currentColor'
      />
    </svg>
  );
}
