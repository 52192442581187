import styled from 'styled-components';

export const Wrapper = styled.div`
  .title {
    text-align: center;
  }
  @media (max-width: 767px) {
    .title {
      font-size: 18px;
    }
  }
`;
