export function ArrowLeft() {
  return (
    <svg
      width='26'
      height='20'
      viewBox='0 0 26 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_300_619)'>
        <path
          d='M0.845359 9.468L8.82536 1.108C8.97736 0.955996 9.15469 0.879995 9.35736 0.879995C9.56003 0.879995 9.73736 0.955996 9.88936 1.108C10.0414 1.26 10.1174 1.43733 10.1174 1.64C10.1174 1.84266 10.0414 2.01999 9.88936 2.172L3.20136 9.24H24.2534C24.456 9.24 24.6334 9.316 24.7854 9.468C24.9374 9.62 25.0134 9.79733 25.0134 10C25.0134 10.2027 24.9374 10.38 24.7854 10.532C24.6334 10.684 24.456 10.76 24.2534 10.76H3.20136L9.88936 17.828C10.0414 17.98 10.1174 18.1573 10.1174 18.36C10.1174 18.5627 10.0414 18.74 9.88936 18.892C9.73736 19.044 9.56003 19.12 9.35736 19.12C9.15469 19.12 8.97736 19.044 8.82536 18.892L0.845359 10.532C0.744026 10.38 0.693359 10.2027 0.693359 10C0.693359 9.79733 0.744026 9.62 0.845359 9.468Z'
          fill='#151618'
        />
      </g>
      <defs>
        <clipPath id='clip0_300_619'>
          <rect
            width='25.63'
            height='19'
            fill='white'
            transform='matrix(1 0 0 -1 0 19.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
