export const InfoIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3929_16392)'>
        <g clipPath='url(#clip1_3929_16392)'>
          <g clipPath='url(#clip2_3929_16392)'>
            <path
              d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z'
              fill='#F2F2F2'
            />
            <path
              d='M8.22222 6.85704C8.44908 6.85704 8.63245 7.02773 8.63245 7.23797V10.3491H8.88888C9.11575 10.3491 9.29912 10.5198 9.29912 10.7301C9.29912 10.9404 9.11575 11.111 8.88888 11.111H7.55555C7.32868 11.111 7.14531 10.9404 7.14531 10.7301C7.14531 10.5198 7.32868 10.3491 7.55555 10.3491H7.81198V7.61897H7.50428C7.27741 7.61897 7.09396 7.44828 7.09396 7.23797C7.09396 7.02773 7.27741 6.85704 7.50428 6.85704H8.22222ZM8.11046 5.22266C8.23469 5.22266 8.35657 5.27135 8.44444 5.35599C8.53226 5.44063 8.58285 5.55804 8.58285 5.67772C8.58285 5.79785 8.5323 5.91481 8.44444 5.9999C8.35657 6.08454 8.23469 6.13278 8.11046 6.13278C7.98622 6.13278 7.86434 6.08454 7.77647 5.9999C7.68866 5.91526 7.63806 5.79785 7.63806 5.67772C7.63806 5.55804 7.68861 5.44063 7.77647 5.35599C7.86434 5.27135 7.98622 5.22266 8.11046 5.22266Z'
              fill='#B5B6BD'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_3929_16392'>
          <rect
            width='16'
            height='16'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_3929_16392'>
          <rect
            width='16'
            height='16'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip2_3929_16392'>
          <rect
            width='16'
            height='16'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
