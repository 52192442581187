export function NotFoundError() {
  return (
    <svg
      width='170'
      height='167'
      viewBox='0 0 170 167'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_300_577)'>
        <g clipPath='url(#clip1_300_577)'>
          <g clipPath='url(#clip2_300_577)'>
            <path
              d='M157.075 28.333C163.938 28.333 169.501 33.8966 169.501 40.7597V130.232C169.501 137.095 163.938 142.658 157.075 142.658H97.9239V152.599H112.339C118.435 152.599 123.436 157.378 123.756 163.439L123.767 163.733L123.792 165.523H47.2232V164.032C47.2232 157.718 52.3417 152.599 58.6557 152.599H72.0765V142.658H12.9257C6.16506 142.658 0.665391 137.259 0.502727 130.538L0.499023 130.232V40.7597C0.499023 33.8966 6.06262 28.333 12.9257 28.333H157.075ZM112.339 155.582H58.6557C54.498 155.582 51.0416 158.585 50.3369 162.54L120.658 162.541L120.614 162.311C119.835 158.544 116.55 155.702 112.592 155.586L112.339 155.582ZM94.9415 142.658H75.0589V152.599H94.9415V142.658ZM166.519 127.249H3.48142V130.232C3.48142 135.358 7.56521 139.53 12.6568 139.672L12.9257 139.676H157.075C162.291 139.676 166.519 135.447 166.519 130.232V127.249ZM157.075 31.3154H12.9257C7.70975 31.3154 3.48142 35.5437 3.48142 40.7597V124.267H166.519V40.7597C166.519 35.5437 162.291 31.3154 157.075 31.3154Z'
              fill='#D9D9D9'
            />
            <path
              d='M85.0003 48.7125C98.4518 48.7125 109.356 37.8078 109.356 24.3562C109.356 10.9047 98.4518 0 85.0003 0C71.5487 0 60.644 10.9047 60.644 24.3562C60.644 37.8078 71.5487 48.7125 85.0003 48.7125Z'
              fill='#EB0043'
            />
            <path
              d='M85 33.3035C86.3177 33.3035 87.3959 34.329 87.48 35.6254L87.4853 35.7888V37.7771C87.4853 39.1497 86.3726 40.2624 85 40.2624C83.6823 40.2624 82.6041 39.2369 82.5199 37.9405L82.5146 37.7771V35.7888C82.5146 34.4162 83.6274 33.3035 85 33.3035ZM85 7.45605C86.3177 7.45605 87.3959 8.48154 87.48 9.77797L87.4853 9.94138V27.8358C87.4853 29.2084 86.3726 30.3211 85 30.3211C83.6823 30.3211 82.6041 29.2956 82.5199 27.9992L82.5146 27.8358V9.94138C82.5146 8.56877 83.6274 7.45605 85 7.45605Z'
              fill='white'
            />
          </g>
        </g>
      </g>
      <path
        d='M63.6221 93.9492V88.6982H51.4873V84.4492L61.5625 68.6035H69.0029V83.9482H72.2686V88.6982H69.0029V93.9492H63.6221ZM63.6221 73.3906L56.7754 83.9482H63.6221V73.3906ZM75.5713 87.6035C74.8662 85.6738 74.5137 83.571 74.5137 81.2949C74.5137 79.0189 74.8662 76.916 75.5713 74.9863C76.2764 73.0566 77.4453 71.4486 79.0781 70.1621C80.7233 68.8757 82.6963 68.2324 84.9971 68.2324C87.2979 68.2324 89.2585 68.8757 90.8789 70.1621C92.4993 71.4486 93.6683 73.0566 94.3857 74.9863C95.1156 76.916 95.4805 79.0189 95.4805 81.2949C95.4805 83.571 95.1156 85.6738 94.3857 87.6035C93.6683 89.5332 92.4993 91.1536 90.8789 92.4648C89.2585 93.7637 87.2979 94.4131 84.9971 94.4131C82.6963 94.4131 80.7233 93.7637 79.0781 92.4648C77.4453 91.1536 76.2764 89.5332 75.5713 87.6035ZM88.7637 87.3438C89.5677 85.8223 89.9697 83.806 89.9697 81.2949C89.9697 78.7839 89.5677 76.7799 88.7637 75.2832C87.972 73.7741 86.7165 73.0195 84.9971 73.0195C83.2777 73.0195 82.0098 73.7741 81.1934 75.2832C80.3893 76.7799 79.9873 78.7839 79.9873 81.2949C79.9873 83.806 80.3893 85.8223 81.1934 87.3438C82.0098 88.8652 83.2777 89.626 84.9971 89.626C86.7165 89.626 87.972 88.8652 88.7637 87.3438ZM109.86 93.9492V88.6982H97.7256V84.4492L107.801 68.6035H115.241V83.9482H118.507V88.6982H115.241V93.9492H109.86ZM109.86 73.3906L103.014 83.9482H109.86V73.3906Z'
        fill='#C0C0C0'
      />
      <defs>
        <clipPath id='clip0_300_577'>
          <rect
            width='170'
            height='166.02'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_300_577'>
          <rect
            width='170'
            height='166.02'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip2_300_577'>
          <rect
            width='169.997'
            height='166.02'
            fill='white'
            transform='translate(0.00195312)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
