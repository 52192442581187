import styled from 'styled-components';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const MobileSheetOptionsWrapper = styled.div`
  display: flex;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    &.chevron {
      gap: 11px;
      svg{
        color: ${(props) => props.theme.text.text01};
      }
      h5{
        text-transform: capitalize;
      }
    }
  }
  &.full {
    width: 100%;
  }
`;
export const MobileSheetOptionsRecording = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  button.text02{
    opacity: .5;
  }
  &.full {
    width: 100%;
  }
`;
export const MobileOptionRecordingWrapper = styled.div`
  width: 100%;
`;
export const SheetTabs = styled(FlexWithAlign)`
  padding-bottom: 1rem;
  > button {
    display: flex;
    width: 80px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 6px;
    &.active {
      p,
      svg {
        color: ${(props) => props.theme.color.primary};
      }
    }
  }
`;
