import React from 'react';

import { List, PersonalAreaWrapper, PortalTextWrapper } from './PolicyPage.styled';

import NewPageHeader from '../../../shared/new-ui/NewPageHeader/ui/NewPageHeader';
import NewText from '../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../shared/new-ui/NewText/ui/NewText.props';
import { Padding } from '../../../utils/styleUtils';

const PolicyPage = () => {
  return (
    <PersonalAreaWrapper $column>
      <NewPageHeader
        backLink={'/portal'}
        title='Политика Конфиденциальности'
      />
      <PortalTextWrapper>
        <List>
          <Padding $size='56px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ООО «ГРУППА КОМПАНИЙ «БЕЛЫЙ МЕДВЕДЬ»
          </NewText>
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            Редакция 2023.12.10 от «10» Декабря 2023
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            1. Общие положения
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            1.1. Политика в отношении обработки персональных данных ООО «Группа компаний «Белый Медведь» (далее — «Политика») определяет
            основные цели, принципы и условия обработки персональных данных, обязанности ООО «Группа компаний «Белый Медведь» (далее —
            Общество, Компания) при обработке персональных данных, права субъектов персональных данных, а также применяемые в ООО «Группа
            компаний «Белый Медведь» меры по защите персональных данных. <br />
            <br />
            1.2. Политика разработана в соответствии с требованиями законодательства в области обработки и защиты персональных данных,
            основанного на Конституции Российской Федерации и состоящего из Федерального закона от 27 июля 2006 г. №152-ФЗ «О персональных
            данных», иных федеральных законах и подзаконных нормативных правовых актах, регулирующих вопросы обработки и защиты персональных
            данных. <br />
            <br />
            1.3. Политика регулирует отношения между Общестом и: • физическим лицом (соискателем, работником Оператора, клиентом,
            представителем клиента, контрагентом, иным субъектом) по обработке и защите персональных данных; • физическим лицом, являющимся
            пользователем сайта Оператора и (или) их поддоменов, находящихся по адресам в сети Интернет: https://telebon.ru/, по обработке и
            защите персональных данных, получаемых и обрабатываемых с использованием сайтов. <br />
            <br />
            1.4. В целях реализации положений Политики ООО «Группа компаний «Белый Медведь» разрабатываются локальные нормативные акты и
            иные документы, регламентирующие вопросы обработки персональных данных в Обществе.
            <br />
            <br />
            1.5. Общество периодически актуализирует настоящую Политику и вправе в одностороннем порядке в любой момент времени изменять ее
            положения. Общество рекомендует регулярно проверять содержание настоящей Политики на предмет ее возможных изменений.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            2. Термины и определения
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            2.1. Термины и определения, содержащиеся в настоящей Политике, используются в следующем значении. <br />
            <span className='font-weight'>Персональные данные</span> — любая информация, относящаяся к прямо или косвенно определенному или
            определяемому физическому лицу (субъекту персональных данных)
            <br />
            <span className='font-weight'>Персональные данные, разрешенные субъектом персональных данных для распространения</span> —
            персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных путем дачи согласия
            на обработку персональных данных, разрешенных субъектом персональных данных для распространения в порядке, предусмотренном
            Федеральным законом от 27.07.2006 №152 ФЗ «О персональных данных» <br />
            <span className='font-weight'>Обработка персональных данных</span> — любое действие (операция) или совокупность действий
            (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка
            персональных данных включает в себя, в том числе: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
            изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
            удаление, уничтожение. <br />
            <span className='font-weight'>Автоматизированная обработка персональных данных</span> — обработка персональных данных
            (далее-ПДн) с помощью средств вычислительной техники (далее — СВТ) <br />
            <span className='font-weight'>Биометрические персональные данные</span> — сведения, которые характеризуют физиологические и
            биологические особенности человека, на основании которых можно установить его
            личностьикоторыеиспользуютсяоператоромдляустановления личностисубъекта персональных данных <br />
            <span className='font-weight'>Блокирование персональных данных</span> — Временное прекращение обработки персональных данных (за
            исключением случаев, если обработка необходима для уточнения персональных данных) <br />
            <span className='font-weight'>Информация</span> — сведения (сообщения, данные) независимо от формы их представления <br />
            <span className='font-weight'>Информационная система персональных данных (ИСПДН)</span> — Совокупность содержащихся в базах
            данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств <br />
            <span className='font-weight'>Обезличивание персональных данных</span> — Действия, в результате которых невозможно определить
            без использования дополнительной информации принадлежность персональных данных конкретному субъекту персональных данных <br />
            <span className='font-weight'>Обработка персональных данных</span> — любое действие (операция) или совокупность действий
            (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными,
            включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
            передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных
            <br />
            <span className='font-weight'>Оператор персональных данных (Оператор)</span> — Общество с ограниченной ответственностью «Группа
            компаний «Белый Медведь» (далее по тексту —, Общество, Организация, Компания) — лицо, самостоятельно или совместно с другими
            лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных
            данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными; <br />
            <span className='font-weight'>Субъекты персональных данных (Субъект)</span> — физическое лицо (в том числе, но не исключительно,
            работники, в связи с трудовыми отношениями с Организацией, кандидаты на заключение трудового договора с Организацией, иные
            физические лица или представители юридического лица, заключившие или планирующие заключение с Организацией договоры в
            соответствии с гражданским законодательством, физические лица, входящие в органы управления Организации, их представители,
            физические лица, являющиеся Участниками Организации, посетители сайта), персональные данные которых обрабатываются Оператором.{' '}
            <br />
            <span className='font-weight'>Категории персональных данных</span> — общие персональные данные (базовые личные данные),
            специальные персональные данные (информация о личности человека), биометрические персональные данные (физиологические или
            биологические особенности человека, которые используют для установления его личности), иные персональные данные).
            <br />
            <span className='font-weight'>Предоставление персональных данных</span> — действия, направленные на раскрытие персональных
            данных определенному лицу или определенному кругу лиц <br />
            <span className='font-weight'>Распространение персональных данных</span> — действия, направленные на раскрытие персональных
            данных неопределенному кругу лиц <br />
            <span className='font-weight'>Трансграничная передача персональных данных</span> — передача персональных данных на территорию
            иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу,
            обеспечивающих адекватную защиту прав субъектов персональных данных <br />
            <span className='font-weight'>Уничтожение персональных данных</span> — действия, в результате которых невозможно восстановить
            содержание персональных данных в информационной системе персональных данных <br />
            <span className='font-weight'>Файлы cookies</span> — текстовой файл небольшого размера, который направляется веб-сервером и
            сохраняется на компьютере, мобильном телефоне или любом другом устройстве, имеющем доступ в сеть Интернет, при посещении
            веб-сайта или мобильного приложения. <br />
            <span className='font-weight'>Аффилированные лица</span> — связанные между собой взаимным контролем. Связь может быть
            обусловлена долевым владением, договорами, а также участием в организациях с одним руководством.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            3. Принципы, цели, категории субъектов персональных данных и правовые основания обработки персональных данных
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            3.1. Общество осуществляет обработку персональных данных руководствуясь принципами, изложенными в статье 5 Федерального закона
            от 27 июля 2006 г. №152-ФЗ «О персональных данных». <br />
            <br />
            3.2. Общество осуществляет обработку персональных данных субъектов в целях, изложенных ниже. Конкретные цели обработки
            устанавливаются в согласии на обработку персональных, которое дает субъект. Подбор кадров, кадровое делопроизводство —
            Взаимодействие с работниками Компании Кандидаты на вакантные позиции — Подбор и согласование персонала на вакантные должности
            Работники Компании — Управление персоналом и кадровое администрирование, включая ведение кадрового и бухгалтерского учета,
            составление налоговой отчетности, обеспечение соблюдения требований законодательства, оформление и регулирование трудовых
            отношений и иных непосредственно связанных с ними отношений между субъектом и Компанией, исполнение субъектом и Компанией
            обязательств, предусмотренных локальными нормативными актами Компании, стандартами и политиками компаний группы, включая
            контроль за их соблюдением субъектом, в т. ч. при общении с клиентами Компании, осуществление комплаенс-контроля и
            противодействия коррупции, содействия субъектам в трудоустройстве, получении образования и продвижении по службе в т. ч.
            контроля количества и качества выполняемой работы и исполнения должностных обязанностей. Организация перемещений (поездок,
            перевозок, командировок и т. д.) субъекта, включая визовую и миграционную поддержку, содействие при перелетах и переездах, в том
            числе за рубеж или в пределах одной страны, организацию, участия субъекта в мероприятиях (конференциях, семинарах, опросах,
            мастер-классах, встречах, праздниках и др.). Обеспечение здравоохранения субъекта, включая оформление, заключение, исполнение,
            изменение, расторжение Компанией договора добровольного медицинского страхования, договора на организацию лабораторной
            диагностики и исследований (в т.ч. диагностики Covid-19), исполнения Компанией требований законодательства в сфере
            санитарно-эпидемиологического благополучия, в т. ч. постановлений главного государственного санитарного врача РФ и иных
            требований. Информационное обеспечение деятельности Компании, включая размещение информации на сайтах Компании, в корпоративных
            адресных и контактных справочниках, используемых Компанией, предоставление ПД субъекта контрагентам, в т. ч. потенциальным
            клиентам, обеспечение субъекта визитными карточками, оформление доверенностей на имя субъекта. Реализация Компанией финансовых
            обязательств перед субъектом, включая организацию оформления зарплатного проекта, выплату субъекту заработной платы, компенсаций
            и премий, осуществление пенсионных и налоговых отчислений, возврат НДФЛ и иные выплаты субъекту. Обеспечение безопасности
            субъекта и Компании, включая обеспечение личной безопасности субъекта, соблюдение пропускного режима и правил нахождения и
            поведения на территории Компании, использования корпоративных устройств, сохранности материальных и иных ценностей, находящихся
            в ведении Компании и субъекта, в т. ч. путем осуществления видеонаблюдения на территории Компании. Предоставление субъекту
            возможности использования корпоративных преимуществ — корпоративных программ, акций и привилегий, в т.ч. участия субъекта в
            организуемых третьими лицами программах обучения, вебинарах и образовательных мероприятиях, изучения иностранных языков и
            посещения спортивных организаций. Родственники работников — Осуществление кадрового учета в соответствии с требованиями
            законодательства РФ, а также, оформление выплат в случаях, предусмотренных ТК РФ и иными актами. Взаимодействие с контрагентами
            Компании, оказание услуг Контрагенты Компании (существующие и потенциальные) — Согласование, заключение, исполнение, изменение,
            расторжение договоров. Исполнение требований законодательства. Предоставление доступа в ИТ-системы Компании, контроль
            использования Субъекты, взаимодействующие с клиентами Компании (пользователи сайтов, виджетов, получатели рассылок,
            потенциальные и существующие клиенты, получатели рекламы и т. д.) — Предоставление клиентам Компании услуг по сквозной аналитике
            данных о пользователях, с которыми клиент взаимодействует, возможность использования виджетов и др. сервисов. Компания действует
            как обработчик по поручению клиента (оператора ПД) Управление делами Подписанты документов, учредители, директоры — Соблюдение
            требований корпоративного законодательства Доверитель, поверенный — Выдача, учет доверенностей Получатели / отправители
            корреспонденции — Учет и рассмотрение обращений, жалоб и иных требований (корреспонденции), получаемых Компанией. Иные
            бизнес-процессы Посетители офиса — Обеспечение безопасности на территории компании (пропускной режим, видеонаблюдение)
            Пользователи сайтов Компании — Предоставление доступа к сайтам, возможностям его использования. Анализ посещения сайта,
            оптимизация функционала Представители клиентов, потенциальных клиентов и контрагентов Компании (в т. ч. контрагенты — ИП и
            физические лица) — Взаимодействие с потенциальными клиентами и контрагентами. Продвижение услуг Компании. Направление сообщений
            регистрационного, информационного и рекламного характера. Проведение маркетинговых мероприятий и вебинаров рассылка уведомлений
            о мероприятии. <br />
            <br />
            3.3. Правовыми основаниями обработки персональных данных являются: • Конституция Российской Федерации; • Трудовой договор; •
            Гражданский кодекс Российской Федерации; • Федеральный закон от 27 июля 2006 г. №149-ФЗ «Об информации, информационных
            технологиях и о защите информации»; • Федеральный закон от 26 декабря 2008 г. №294-ФЗ «О защите прав юридических лиц и
            индивидуальных предпринимателей при осуществлении государственного контроля (надзора) и муниципального контроля»; • уставные
            документы Оператора; • договоры, заключаемые между Оператором и субъектами персональных данных; • договоры, заключаемые между
            Оператором и третьими лицами; • согласия субъектов персональных данных на обработку персональных данных; • иные основания.
            <br />
            <br />
            3.4. Общество обрабатывает персональные данные только при наличии хотя бы одного из указанных ниже условий в течение следующих
            сроков: (1) С согласия субъекта персональных данных на обработку его персональных данных В течение срока, на который было дано
            согласие на обработку персональных данных — В течение срока, на который было дано согласие на обработку персональных данных (2)
            Для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения
            возложенных законодательством Российской Федерации на оператора функций, полномочий и обязанностей В течение срока,
            установленного соответствующими международными договорами или законами — В течение срока, установленного соответствующими
            международными договорами или законами (3) При необходимости обработки персональных данных, подлежащих опубликованию или
            обязательному раскрытию в соответствии с федеральным законом В течение срока, установленного соответствующими законами — В
            течение срока, установленного соответствующими законами (4) Для исполнения судебного акта, акта другого органа или должностного
            лица, подлежащих исполнению в соответствии с законодательством Российской Федерации об исполнительном производстве В течение
            срока, необходимо для исполнения соответствующего акта — В течение срока, необходимо для исполнения соответствующего акта (5) В
            связи с участием лица в конституционном, гражданском, административном, уголовном судопроизводстве, судопроизводстве в
            арбитражных судах В течение срока участия в соответствующем судопроизводстве, включая сроки обжалования (оспаривания) судебных
            актов, кроме случаев, когда более длительный срок обработки персональных данных установлен действующим законодательством
            Российской Федерации — В течение срока участия в соответствующем судопроизводстве, включая сроки обжалования (оспаривания)
            судебных актов, кроме случаев, когда более длительный срок обработки персональных данных установлен действующим
            законодательством Российской Федерации (6) Для исполнения договора, стороной которого либо выгодоприобретателем или поручителем,
            по которому является субъект персональных данных, а также для заключения договора по инициативе субъекта персональных данных или
            договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем В течение срока действия
            такого договора, кроме случаев, когда более длительный срок обработки персональных данных установлен действующим
            законодательством Российской Федерации — В течение срока действия такого договора, кроме случаев, когда более длительный срок
            обработки персональных данных установлен действующим законодательством Российской Федерации (7) Для защиты жизни, здоровья или
            иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно До
            момента, когда получение согласия субъекта персональных данных станет возможным или когда соответствующие основания, угрожающие
            жизни, здоровью или иным жизненно важным интересам, отпадут (в зависимости от того, какое обстоятельство наступит раньше) — До
            момента, когда получение согласия субъекта персональных данных станет возможным или когда соответствующие основания, угрожающие
            жизни, здоровью или иным жизненно важным интересам, отпадут (в зависимости от того, какое обстоятельство наступит раньше) (8)
            Для осуществления прав и законных интересов оператора или третьих лиц при условии, что при этом не нарушаются права и свободы
            субъекта персональных данных В течение срока, необходимого для осуществления прав и обеспечения законных интересов. Конкретный
            срок определяется Обществом с учётом положений настоящей Политики, внутренних документов и локальных нормативных актов Общества,
            а также принципов обработки персональных данных и требований действующего законодательства Российской Федерации, в том числе в
            части прекращения обработки персональных данных при достижении конкретных, заранее определенных и законных целей такой обработки
            — В течение срока, необходимого для осуществления прав и обеспечения законных интересов. Конкретный срок определяется Обществом
            с учётом положений настоящей Политики, внутренних документов и локальных нормативных актов Общества, а также принципов обработки
            персональных данных и требований действующего законодательства Российской Федерации, в том числе в части прекращения обработки
            персональных данных при достижении конкретных, заранее определенных и законных целей такой обработки <br />
            <br />
            3.5. При сборе персональных данных, в том числе посредством информационно-телекоммуникационной сети «Интернет», обеспечивается
            запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных граждан
            Российской Федерации с использованием баз данных, находящихся на территории Российской Федерации.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            4. Объем и категории обрабатываемых персональных данных
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            4.1. Состав обрабатываемых персональных данных физических лиц, состоящих или ранее состоявших в трудовых, иных договорных и иных
            гражданско-правовых отношениях с Обществом, и (или) их законных представителей, в частности работников, уволенных работников,
            членов семьи и родственников работников и (или) уволенных работников, а также кандидатов на замещение вакантных должностей
            содержит следующие категории персональных данных: фамилия, имя, отчество, год рождения, месяц рождения, дата рождения, место
            рождения, адрес, семейное положение, социальное положение, имущественное положение, образование, профессия, доходы, паспортные
            данные, сведения о гражданстве, данные пенсионного, медицинского страхования, ИНН, СНИЛС, сведения о воинском учете, сведения о
            наградах, поощрениях, почетных званиях, контактные данные, степень родства, фотография, иные данные в соответствии с
            требованиями законодательства Российской Федерации. <br />
            <br />
            4.2. Состав обрабатываемых персональных данных клиентов/контрагентов и их представителей; пользователей Интернет-сервисов и
            приложений; заявителей с обращениями содержит следующие категории персональных данных, фамилия, имя, отчество, контактные данные
            (телефон, e-mail), должность, место работы, паспортные данные, содержание сообщений (в т.ч. медиафайлы), файлы «Cookies»
            посетителей сайта и техническая информация о пользователях (дата регистрации, параметры устройства и веб-приложений, IP-адрес,
            история сообщений, геолокация, параметры сессии). Более подробно о собираемой технической информации указано в Политике
            использования файлов cookie. <br />
            <br />
            4.3. Общество осуществляет обработку специальных категорий персональных данных только в случаях, когда такая обработка
            допускается Законом о персональных данных: субъект персональных данных дал согласие в письменной форме на обработку своих
            персональных данных, обработка персональных данных осуществляется в соответствии с законодательством Российской Федерации,
            обработка персональных данных необходима для установления или осуществления прав субъекта персональных данных или третьих лиц.
            Обработка специальных категорий персональных данных незамедлительно прекращается, когда достигнуты цели, для достижения которых
            осуществлялась их обработка, если иное не установлено законодательством Российской Федерации. <br />
            <br />
            4.4. Полный перечень персональных данных, обрабатываемых в Обществе, определяется в соответствии с локальными нормативными
            актами Общества с учетом целей обработки персональных данных.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            5. Порядок и условия обработки персональных данных
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            5.1. Общество осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ), извлечение, блокирование, обезличивание, удаление,
            уничтожение персональных данных. <br />
            <br />
            5.2. Обработка персональных данных осуществляется с применением: • автоматизированной обработки персональных данных с передачей
            по сети Интернет или без таковой; • неавтоматизированной обработки персональных данных; • смешанных способов обработки. <br />
            <br />
            5.3. Обработка персональных данных субъекта персональных данных осуществляется с его согласия на обработку персональных данных,
            а также без такового, если Обработка персональных данных необходима для исполнения договора, стороной которого либо
            выгодоприобретателем или поручителем по которому является субъект персональных данных, а также для заключения договора по
            инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться
            выгодоприобретателем или поручителем или в иных случаях, предусмотренных Законодательством о персональных данных. <br />
            <br />
            5.4. Передача персональных данных третьему лицу осуществляется только с согласия субъекта персональных данных или в случаях,
            прямо предусмотренных законодательством Российской Федерации. <br />
            <br />
            5.5. Общество раскрывает информацию третьим лицам только в случаях, предусмотренных настоящей Политикой или применимым
            законодательством, и только в объёме, необходимом исходя из цели раскрытия.
            <br />
            <br />
            5.6. Общество вправе поручить обработку персональных данных другому лицу и (или) осуществлять обработку персональных данных по
            поручению другого лица в порядке и на условиях, предусмотренных требованиями частей 3−6 статьи 6 Федерального закона от 27 июля
            2006 г. №152-ФЗ «О персональных данных». <br />
            <br />
            5.7. Лицам, получающим доступ к персональным данным по поручению, а равно третьим лицам, которым Общество предоставляет доступ
            по иным законным основаниям, передаётся строго ограниченный набор данных, необходимый для исполнения их функций. <br />
            <br />
            5.8. Общество принимает все зависящие меры, чтобы обеспечить конфиденциальность передачи данных. Обработчики и третьи лица
            обязуются соблюдать принципы и правила обработки персональных данных, предусмотренные Федеральным законом №152-ФЗ «О
            персональных данных» (включая ст. 18.1 и ч. 5 ст. 18), иными законами и подзаконными актами. Для каждого обработчика в договоре
            определены: перечень обрабатываемых персональных данных; цели их обработки; перечень действий (операций), которые будут
            совершаться с персональными данными обработчиком; обязанности обработчика по соблюдению конфиденциальности и обеспечению
            безопасности персональных данных при их обработке, а также перечень принимаемых обработчиком мер по обеспечению защиты
            обрабатываемых им персональных данных, включая требование об уведомлении Общества об инцидентах с персональными данными;
            обязанность по запросу Общества в течение срока действия поручения на обработку персональных данных предоставлять Обществу
            документы и иную информацию, подтверждающие принятие мер и соблюдение в целях исполнения поручения Общества требований,
            установленных Федеральным законом от 27.07.2006№152 ФЗ"О персональных данных". <br />
            <br />
            5.10. Перечень лиц, обрабатывающих персональные данные субъекта по поручению Общества или получающих данные субъекта от Общества
            подлежит раскрытию в соответствующем согласии на обработку персональных данных.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            6. Хранение персональных данных
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            6.1. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем
            этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен законодательством
            Российской Федерации либо договором, стороной которого является субъект персональных данных. <br />
            <br />
            6.2. Хранение персональных данных осуществляется с учетом обеспечения режима их конфиденциальности. <br />
            <br />
            6.3. Оператор обеспечивает сбор персональных данных и их обработку с использованием баз данных, находящихся на территории
            Российской Федерации. <br />
            <br />
            6.4. Персональные данные передаются на архивное хранение в соответствии с законодательством Российской Федерации об архивном
            деле, уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей,
            если иное не предусмотрено законодательством Российской Федерации либо договором, стороной которого является субъект
            персональных данных.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            7. Трансграничная передача персональных данных
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            7.1. Общество осуществляет трансграничную передачу персональных данных в соответствии с требованиями действующего
            законодательства Российской Федерации. Трансграничная передача персональных данных на территории иностранных государств,
            являющихся сторонами Конвенции Совета Европы о защите физических лиц при автоматизированной обработке персональных данных, а
            также иных иностранных государств, обеспечивающих адекватную защиту прав субъектов персональных данных, осуществляется в
            соответствии с требованиями действующего законодательства Российской Федерации. <br />
            <br />
            7.2. Трансграничная передача персональных данных на территории иностранных государств, не обеспечивающих адекватной защиты прав
            субъектов персональных данных, осуществляется в соответствии с требованиями действующего законодательства Российской Федерации.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            8. Меры, принимаемые для обеспечения выполнения обязанностей оператора при обработке персональных данных
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            8.1. Общество принимает необходимые правовые, организационные и технически меры, позволяющие обеспечить безопасность
            персональных данных, а также соблюдение прав субъектов персональных данных, требований законодательства Российской Федерации и
            локальных актов Общества в области обработки и защиты персональных данных. <br />
            <br />
            8.2. Для обеспечения выполнения обязанностей оператора, предусмотренных законодательством Российской Федерации в области
            персональных данных, Общество принимает следующие меры: • физическую защиту помещений, где производится обработка персональных
            данных; назначение лица, ответственного за организацию обработки персональных данных в Обществе; • принятие локальных
            нормативных актов и иных документов по вопросам обработки и защиты персональных данных; • ознакомление работников Общества,
            непосредственно осуществляющих обработку персональных данных, с положениями законодательства Российской Федерации о персональных
            данных, в том числе требованиями к защите персональных данных, локальными нормативными актами и иными документами Общества по
            вопросам обработки персональных данных; • разработка и утверждение плана внутренних проверок режима защиты персональных данных;
            • получение согласий субъектов персональных данных на обработку их персональных данных, за исключением случаев, предусмотренных
            Российской Федерации; • обособление персональных данных, обрабатываемых без использования средств автоматизации, от иной
            информации; • обеспечение раздельного хранения персональных данных и их материальных носителей, обработка которых осуществляется
            в разных целях и которые содержат разные категории персональных данных; • хранение материальных носителей персональных данных с
            соблюдением условий, обеспечивающих сохранность персональных данных и исключающих несанкционированный доступ к ним; •
            осуществление внутреннего контроля соответствия обработки персональных данных Федеральному закону и принятым в соответствии с
            ним нормативным правовым актам, требованиям к защите персональных данных, настоящей Политике, локальным нормативным актам
            Общества; • распределение обязанностей по обеспечению и реализации мер защиты между структурными подразделениями. <br />
            <br />
            8.3. Обеспечение безопасности персональных данных достигается, в частности, путем создания системы защиты персональных данных,
            основанной на следующих принципах: • определением угроз безопасности персональных данных при их обработке в информационных
            системах персональных данных субъектов; • применением организационных и технических мер по обеспечению безопасности персональных
            данных субъектов при их обработке в информационных системах персональных данных, необходимых для выполнения требований к защите
            персональных данных субъектов, исполнение которых обеспечивает установленные Правительством Р Ф уровни защищенности персональных
            данных; • применением прошедших в установленном порядке процедуру оценки соответствия средств защиты информации; • оценкой
            эффективности принимаемых мер по обеспечению безопасности персональных данных до ввода в эксплуатацию информационной системы
            персональных данных; • учетом машинных носителей персональных данных; • обнаружением фактов несанкционированного доступа к
            персональным данным и принятием мер, в том числе мер по обнаружению, предупреждению и ликвидации последствий компьютерных атак
            на информационные системы персональных данных и по реагированию на компьютерные инциденты в них; • восстановлением персональных
            данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним; • установлением правил доступа к
            персональным данным, обрабатываемым в информационной системе персональных данных, а также обеспечением регистрации и учета всех
            действий, совершаемых с персональными данными в информационной системе персональных данных; • контролем за принимаемыми мерами
            по обеспечению безопасности персональных данных и уровня защищенности информационных систем персональных данных: • применением
            иных мер.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            9. Порядок взаимодействия с субъектами персональных данных
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            9.1. субъект, персональные данные которого обрабатываются в Обществе, вправе отозвать ранее данное согласие на обработку
            персональных данных, а равно получить доступ к своим персональным данным, в том числе к следующей информации: • подтверждение
            факта обработки персональных данных; • правовые основания и цели обработки персональных данных; • цели и применяемые способы
            обработки персональных данных; • наименование и место нахождения оператора, сведения о лицах (за исключением работников
            оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора
            с оператором или на основании законодательства Российской Федерации; • перечень обрабатываемых персональных данных, относящиеся
            к соответствующему субъекту, и источник их получения; • сроки обработки персональных данных и сроки их хранения; • информацию об
            осуществлённой или о предполагаемой трансграничной передаче данных; • наименование лица, осуществляющего обработку персональных
            данных по поручению оператора, в случае если обработка поручена третьему лицу, либо сведения о трансграничной передачи. <br />
            <br />
            9.2. Если иной порядок взаимодействия Общества и субъекта персональных данных не предусмотрен соответствующим документом между
            ними (например, договором или текстом согласия на обработку персональных данных), для реализации указанных прав субъекту
            персональных данных необходимо направить в адрес Общества соответствующее требование: • в письменной форме и подписанное
            собственноручной подписью — по адресу: 610050, г. Киров, ул. Московская, д. 199 • в форме электронного документа — на
            электронную почту: kirov004@yandex.ru <br />
            <br />
            9.3. Заявление лица должно содержать описание требований, а также информацию, способную его идентифицировать как субъекта
            персональных данных, чьи данные обрабатывает Общество. <br />
            <br />
            9.4. Во избежание избыточной обработки персональных данных при реагировании на запросы субъекта Общество руководствуется
            принципом соразмерности предоставленных субъектом для идентификации обрабатываемых сведений, что подразумевает: • в случае, если
            субъектом персональных данных дано согласие в соответствии с ч. 4 ст. 9 Федеральным законом от 27.07.2006 №152 ФЗ «О
            персональных данных», а именно путем проставления собственноручной подписи на бумажном носителе, содержащем согласие на
            обработку персональных данных, а равно в форме электронного документа, подписанного аналогом собственноручной подписи, то запрос
            должен содержать следующие реквизиты (a) ФИО субъекта персональных данных; (b) номер основного документа, удостоверяющего
            личность субъекта персональных данных или его представителя, (с) сведения о дате выдачи указанного документа и выдавшем его
            органе, ЛИБО иные данные, позволяющие однозначно идентифицировать субъекта персональных данных; (d) сведения, подтверждающие
            участие субъекта персональных данных в отношениях с Обществом, ЛИБО сведения, иным способом подтверждающие факт обработки
            персональных данных Обществом: (e) рукописная подпись субъекта персональных данных или его представителя на бумажном носителей,
            а равно электронная подпись; • во всех иных случаях субъект персональных данных для своей идентификации указывает те сведения,
            которые им были оставлены в соответствующем согласии на обработку персональных данных (например, электронная почта и номер
            телефона). <br />
            <br />
            9.5. Общество рассматривает обращения, дает разъяснения и предпринимает меры по защите данных в десятидневный срок. В случае
            претензий и жалоб со стороны субъекта персональных данных, Общество принимает все необходимые меры для устранения возможных
            нарушений, установления виновных лиц и урегулирования спорных ситуаций в досудебном порядке. <br />
            <br />
            9.6. субъект персональных данных вправе обжаловать действия (бездействия) и решения Общества, нарушающие его права при обработке
            персональных данных, в уполномоченный орган по защите прав субъектов персональных данных (Роскомнадзор) и в суд в порядке,
            установленном законодательством Российской Федерации. <br />
            <br />
            9.7. В случае отзыва субъектом персональных данных согласия на обработку персональных данных Общество вправе продолжить
            обработку персональных данных без согласия субъекта персональных данных при наличии оснований, предусмотренных законодательством
            Российской Федерации.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            10. Заключительные положения
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            10.1. Иные права и обязанности Общества как Оператора персональных данных определяются законодательством Российской Федерации в
            области персональных данных. Общество, его должностные лица и Работники несут гражданско-правовую, административную и иную
            ответственность за несоблюдение принципов и условий обработки персональных данных, а также за разглашение или незаконное
            использование персональных данных в соответствии с законодательством Российской Федерации <br />
            <br />
            10.2. Реквизиты и контактная информация Оператора персональных данных:
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={600}
            color={'black'}
          >
            ООО «Группа компаний «Белый Медведь»
          </NewText>
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            610050, г. Киров, улица Московская, дом 199, этаж 2 <br />
            ИНН 4345410051 <br />
            КПП 434501001 <br />
            Тел: 8 812 507-63-33
          </NewText>
        </List>
      </PortalTextWrapper>
    </PersonalAreaWrapper>
  );
};

export default PolicyPage;
