import React from 'react';

export function EmployeesTwo() {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.11 2.94141C5.72929 2.94141 4.61 4.06069 4.61 5.44141C4.61 6.82212 5.72929 7.94141 7.11 7.94141C8.49071 7.94141 9.61 6.82212 9.61 5.44141C9.61 4.06069 8.49071 2.94141 7.11 2.94141ZM3.11 5.44141C3.11 3.23227 4.90086 1.44141 7.11 1.44141C9.31914 1.44141 11.11 3.23227 11.11 5.44141C11.11 7.65055 9.31914 9.44141 7.11 9.44141C4.90086 9.44141 3.11 7.65055 3.11 5.44141ZM12.36 2.19141C12.36 1.77719 12.6958 1.44141 13.11 1.44141C15.3191 1.44141 17.11 3.23227 17.11 5.44141C17.11 7.65055 15.3191 9.44141 13.11 9.44141C12.6958 9.44141 12.36 9.10562 12.36 8.69141C12.36 8.27719 12.6958 7.94141 13.11 7.94141C14.4907 7.94141 15.61 6.82212 15.61 5.44141C15.61 4.06069 14.4907 2.94141 13.11 2.94141C12.6958 2.94141 12.36 2.60562 12.36 2.19141ZM1.11 17.0664C1.11 13.9598 3.6284 11.4414 6.735 11.4414H7.485C10.5916 11.4414 13.11 13.9598 13.11 17.0664C13.11 18.3781 12.0467 19.4414 10.735 19.4414H3.485C2.17332 19.4414 1.11 18.3781 1.11 17.0664ZM6.735 12.9414C4.45683 12.9414 2.61 14.7882 2.61 17.0664C2.61 17.5497 3.00175 17.9414 3.485 17.9414H10.735C11.2182 17.9414 11.61 17.5497 11.61 17.0664C11.61 14.7882 9.76318 12.9414 7.485 12.9414H6.735ZM12.61 12.1914C12.61 11.7772 12.9458 11.4414 13.36 11.4414H13.485C16.5916 11.4414 19.11 13.9598 19.11 17.0664C19.11 18.3781 18.0467 19.4414 16.735 19.4414H15.11C14.6958 19.4414 14.36 19.1056 14.36 18.6914C14.36 18.2772 14.6958 17.9414 15.11 17.9414H16.735C17.2183 17.9414 17.61 17.5497 17.61 17.0664C17.61 14.7882 15.7632 12.9414 13.485 12.9414H13.36C12.9458 12.9414 12.61 12.6056 12.61 12.1914Z'
        fill='#BCBCC2'
      />
    </svg>
  );
}
