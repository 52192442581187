export function ArrowUturnRightOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.7636 3.2636C14.1151 2.91213 14.6849 2.91213 15.0364 3.2636L19.1364 7.3636C19.3052 7.53239 19.4 7.76131 19.4 8C19.4 8.2387 19.3052 8.46762 19.1364 8.6364L15.0364 12.7364C14.6849 13.0878 14.115 13.0878 13.7636 12.7364C13.4121 12.3849 13.4121 11.815 13.7636 11.4636L16.3272 8.9H12C9.73561 8.9 7.89998 10.7356 7.89998 13C7.89998 15.2644 9.73561 17.1 12 17.1H16.1C16.597 17.1 17 17.5029 17 18C17 18.4971 16.597 18.9 16.1 18.9H12C8.7415 18.9 6.09998 16.2585 6.09998 13C6.09998 9.74152 8.7415 7.1 12 7.1H16.3272L13.7636 4.5364C13.4121 4.18492 13.4121 3.61508 13.7636 3.2636Z'
        fill='currentColor'
      />
    </svg>
  );
}
