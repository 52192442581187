import { KassaState } from './finance.interface';
import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { createSlice } from '@reduxjs/toolkit';
import { fetchListKassaAsync } from './finance.actions';

const initialState: KassaState = {
  kassa: [],
  state: PENDING
};

export const financeSlice = createSlice({
  name: 'finance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListKassaAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchListKassaAsync.fulfilled, (state, action) => {
        state.kassa = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchListKassaAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = financeSlice;
