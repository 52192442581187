import { FC } from 'react';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { CloseBtn, ColorsBtn, ColorsBtnsWrap, ColorsDefaultBtn, Wrapper } from './ColorModal.styled';
import { EIcon, IconNew as IconInstance, IconNew } from '../../../../icons/medium-new-icons/icon';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { useTranslation } from 'react-i18next';
import { colors } from './ColorModal.data';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';

interface IProps {
  closeModal?: () => void;
  modalPayload?: any;
}
const ColorModal: FC<IProps> = (props) => {
  const { closeModal, modalPayload } = props;
  const { color: chosenColor } = useTypedSelector((state) => state.service);
  const { setColor } = useActions();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <FlexWithAlign $gap='16px'>
        <CloseBtn
          typeBtn={'close'}
          onClick={closeModal}
        >
          <IconInstance name={EIcon.close} />
        </CloseBtn>
        <NewText
          $tag={TextType.H2}
          fontWeight={600}
          color='title'
        >
          {t('Цвет услуги')}
        </NewText>
      </FlexWithAlign>
      <FlexWithAlign $gap='28px'>
        <ColorsDefaultBtn onClick={() => setColor('default')}>
          <IconInstance name={EIcon.colorbigicon} />
          {chosenColor === 'default' ? (
            <span className={'chosen-icon'}>
              <IconNew name={EIcon.chosentimecell} />
            </span>
          ) : null}
        </ColorsDefaultBtn>
        <FlexWithAlign
          $column
          $gap='0px'
        >
          <NewText
            $customSize={TextCustomType.T14}
            color='primary'
          >
            {t('Цвет по-умолчанию')}
          </NewText>
          <NewText
            $customSize={TextCustomType.T14}
            color='grey'
          >
            {t('Telebon автоматически назначит цвет после сохранения')}
          </NewText>
        </FlexWithAlign>
      </FlexWithAlign>
      <ColorsBtnsWrap>
        {colors.map((color) => (
          <ColorsBtn
            style={{ backgroundColor: color }}
            key={color}
            onClick={() => setColor(color)}
          >
            {chosenColor === color ? (
              <span className={'chosen-icon'}>
                <IconNew name={EIcon.chosentimecell} />
              </span>
            ) : null}
          </ColorsBtn>
        ))}
      </ColorsBtnsWrap>
    </Wrapper>
  );
};

export default ColorModal;
