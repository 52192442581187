export const PaymentIcon6 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24570)'>
        <path
          d='M4.25 12.75H26.75C26.75 12.75 29.75 12.75 29.75 15.75V30.75C29.75 30.75 29.75 33.75 26.75 33.75H4.25C4.25 33.75 1.25 33.75 1.25 30.75V15.75C1.25 15.75 1.25 12.75 4.25 12.75Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5 15.75H8C8 15.75 8.75 15.75 8.75 16.5V19.5C8.75 19.5 8.75 20.25 8 20.25H5C5 20.25 4.25 20.25 4.25 19.5V16.5C4.25 16.5 4.25 15.75 5 15.75Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.25 24.75H11.75'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.25 27.75H10.25'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.75 24C23.324 24.0005 22.9031 24.0918 22.5151 24.2677C22.1272 24.4435 21.7811 24.7 21.5 25.02C21.2189 24.7 20.8728 24.4435 20.4849 24.2677C20.0969 24.0918 19.676 24.0005 19.25 24C18.4544 24 17.6913 24.3161 17.1287 24.8787C16.5661 25.4413 16.25 26.2044 16.25 27C16.25 27.7957 16.5661 28.5587 17.1287 29.1213C17.6913 29.6839 18.4544 30 19.25 30C19.676 29.9995 20.0969 29.9082 20.4849 29.7324C20.8728 29.5565 21.2189 29.3 21.5 28.98C21.8311 29.3569 22.2515 29.6448 22.7226 29.8172C23.1938 29.9897 23.7007 30.0412 24.1969 29.9671C24.6931 29.893 25.1628 29.6956 25.563 29.3931C25.9632 29.0905 26.2811 28.6924 26.4877 28.2352C26.6943 27.778 26.7829 27.2763 26.7454 26.776C26.7079 26.2757 26.5456 25.7927 26.2732 25.3714C26.0009 24.95 25.6272 24.6037 25.1864 24.3642C24.7456 24.1246 24.2517 23.9994 23.75 24Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5 25.0195C21.8483 25.3985 22.0868 25.8652 22.19 26.3695'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M32.75 21.75C33.5456 21.75 34.3087 21.4339 34.8713 20.8713C35.4339 20.3087 35.75 19.5456 35.75 18.75V5.25C35.75 4.45435 35.4339 3.69129 34.8713 3.12868C34.3087 2.56607 33.5456 2.25 32.75 2.25H10.25C9.45435 2.25 8.69129 2.56607 8.12868 3.12868C7.56607 3.69129 7.25 4.45435 7.25 5.25V9.75'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.25 8.25H35.75'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24570'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
