import { useQuery } from '@tanstack/react-query';
import { CashRegisterService } from '../../../../shared/api/model/service/cashregister.service';
import { CASH_REGISTER, CASH_REGISTER_ALL } from '../../../../shared/const/query.const';
import { ICashRegisterByIdResponse, ICashRegisterResponse } from '../cashRegister.interface';

export const useCashRegisterAllQuery = () => {
  return useQuery({
    queryKey: [CASH_REGISTER_ALL],
    queryFn: () => CashRegisterService.fetchCashRegisters(),
    staleTime: 1800000000,
    select: (data: ICashRegisterResponse) => data.kassa || []
  });
};

export const useCashRegisterByIdQuery = (id: string | undefined) => {
  return useQuery({
    queryKey: [CASH_REGISTER, id],
    queryFn: () => CashRegisterService.fetchCashRegisterById(id),
    staleTime: 1800000000,
    enabled: !!id,
    select: (data: ICashRegisterByIdResponse) => data.kassa || {}
  });
};
