import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ClientsService } from '../../../../shared/api/model/service/clients.service';
import { CLIENTS_QUERY, CLIENT_QUERY } from '../../../../shared/const/query.const';
import { IClient } from '../clients.interface';

export function useCreateClient() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: Omit<IClient, 'id'>) => ClientsService.createClient(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CLIENTS_QUERY] });
      }
    }
  });
}

export function useUpdateClient() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: IClient) => ClientsService.updateClient(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CLIENTS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [CLIENT_QUERY] });
      }
    }
  });
}

export function useDeleteClient() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => ClientsService.deleteClient(id),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CLIENTS_QUERY] });
      }
    }
  });
}
