import React from 'react';

export function ExitIcon() {
  return (
    <svg
      width='19'
      height='20'
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.47675 19.4082C8.00475 19.4082 6.62875 19.0882 5.34875 18.4482C4.06875 17.8082 2.99675 16.9282 2.13275 15.8082C1.26875 14.6882 0.708747 13.4402 0.452747 12.0642C0.196747 10.6882 0.292747 9.3442 0.740747 8.0322C1.18875 6.7202 1.89275 5.5522 2.85275 4.5282C3.81275 3.5042 4.99675 2.7682 6.40475 2.3202C6.59675 2.3202 6.74075 2.3522 6.83675 2.4162C6.93275 2.4802 7.02875 2.5922 7.12475 2.7522C7.22075 2.9122 7.23675 3.0722 7.17275 3.2322C7.10875 3.3922 7.01275 3.5042 6.88475 3.5682C5.60475 3.9522 4.53275 4.5602 3.66875 5.3922C2.80475 6.2242 2.19675 7.2162 1.84475 8.3682C1.49275 9.5202 1.44475 10.6882 1.70075 11.8722C1.95675 13.0562 2.45275 14.1282 3.18875 15.0882C3.92475 16.0482 4.83675 16.8002 5.92475 17.3442C7.01275 17.8882 8.19675 18.1602 9.47675 18.1602C10.7567 18.1602 11.9407 17.8882 13.0287 17.3442C14.1167 16.8002 15.0287 16.0482 15.7647 15.0882C16.5007 14.1282 16.9967 13.0402 17.2527 11.8242C17.5087 9.9042 17.1727 8.1762 16.2447 6.6402C15.3167 5.1042 13.9247 4.0162 12.0687 3.3762C11.9407 3.3762 11.8447 3.3122 11.7807 3.1842C11.7167 3.0562 11.7007 2.9282 11.7327 2.8002C11.7647 2.6722 11.8607 2.5762 12.0207 2.5122C12.1807 2.4482 12.3567 2.4482 12.5487 2.5122C13.9567 2.9602 15.1407 3.6802 16.1007 4.6722C17.0607 5.6642 17.7647 6.8322 18.2127 8.1762C18.6607 9.5202 18.7567 10.8802 18.5007 12.2562C18.2447 13.6322 17.6847 14.8642 16.8207 15.9522C15.9567 17.0402 14.8847 17.8882 13.6047 18.4962C12.3247 19.1042 10.9487 19.4082 9.47675 19.4082ZM9.47675 9.3282C9.34875 9.3282 9.22075 9.2642 9.09275 9.1362C8.96475 9.0082 8.90075 8.8482 8.90075 8.6562V1.2642C8.90075 1.0722 8.96475 0.912203 9.09275 0.784203C9.22075 0.656202 9.34875 0.592203 9.47675 0.592203C9.60475 0.592203 9.73275 0.656202 9.86075 0.784203C9.98875 0.912203 10.0527 1.0722 10.0527 1.2642V8.6562C10.0527 9.1042 9.86075 9.3282 9.47675 9.3282Z'
        fill='#BCBCC2'
      />
    </svg>
  );
}
