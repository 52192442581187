import React from 'react';

export function ListIcon() {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.167 17.5H4.66699C3.89042 17.5 3.50214 17.5 3.19585 17.3731C2.78747 17.204 2.46302 16.8795 2.29386 16.4711C2.16699 16.1649 2.16699 15.7766 2.16699 15C2.16699 14.2234 2.16699 13.8351 2.29386 13.5289C2.46302 13.1205 2.78747 12.796 3.19585 12.6269C3.50214 12.5 3.89042 12.5 4.66699 12.5H16.3337C17.1102 12.5 17.4985 12.5 17.8048 12.6269C18.2132 12.796 18.5376 13.1205 18.7068 13.5289C18.8337 13.8351 18.8337 14.2234 18.8337 15C18.8337 15.7766 18.8337 16.1649 18.7068 16.4711C18.5376 16.8795 18.2132 17.204 17.8048 17.3731C17.4985 17.5 17.1102 17.5 16.3337 17.5H15.5003'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M2.16699 10C2.16699 9.22343 2.16699 8.83515 2.29386 8.52886C2.46302 8.12048 2.78747 7.79602 3.19585 7.62687C3.50214 7.5 3.89042 7.5 4.66699 7.5H16.3337C17.1102 7.5 17.4985 7.5 17.8048 7.62687C18.2132 7.79602 18.5376 8.12048 18.7068 8.52886C18.8337 8.83515 18.8337 9.22343 18.8337 10C18.8337 10.7766 18.8337 11.1649 18.7068 11.4711C18.5376 11.8795 18.2132 12.204 17.8048 12.3731C17.4985 12.5 17.1102 12.5 16.3337 12.5H4.66699C3.89042 12.5 3.50214 12.5 3.19585 12.3731C2.78747 12.204 2.46302 11.8795 2.29386 11.4711C2.16699 11.1649 2.16699 10.7766 2.16699 10Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
      <path
        d='M8.83366 2.5H16.3337C17.1102 2.5 17.4985 2.5 17.8048 2.62687C18.2132 2.79602 18.5376 3.12048 18.7068 3.52886C18.8337 3.83515 18.8337 4.22343 18.8337 5C18.8337 5.77657 18.8337 6.16485 18.7068 6.47114C18.5376 6.87952 18.2132 7.20398 17.8048 7.37313C17.4985 7.5 17.1102 7.5 16.3337 7.5H4.66699C3.89042 7.5 3.50214 7.5 3.19585 7.37313C2.78747 7.20398 2.46302 6.87952 2.29386 6.47114C2.16699 6.16485 2.16699 5.77657 2.16699 5C2.16699 4.22343 2.16699 3.83515 2.29386 3.52886C2.46302 3.12048 2.78747 2.79602 3.19585 2.62687C3.50214 2.5 3.89042 2.5 4.66699 2.5H5.50033'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <ellipse
        cx='4.66634'
        cy='9.99984'
        rx='0.833333'
        ry='0.833333'
        fill='currentColor'
      />
      <ellipse
        cx='4.66634'
        cy='4.99984'
        rx='0.833333'
        ry='0.833333'
        fill='currentColor'
      />
      <ellipse
        cx='4.66634'
        cy='14.9998'
        rx='0.833333'
        ry='0.833333'
        fill='currentColor'
      />
    </svg>
  );
}
