export const CopyTimeTableArrow = () => {
  return (
    <svg
      width='16'
      height='12'
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_841_7638)'>
        <path
          d='M14.7007 5.80783L10.0927 0.911828C9.99672 0.815828 9.88472 0.767828 9.75672 0.767828C9.62872 0.767828 9.52472 0.807828 9.44472 0.887828C9.36472 0.967828 9.31672 1.07183 9.30072 1.19983C9.28472 1.32783 9.32472 1.43983 9.42072 1.53583L13.3567 5.66383H1.06872C0.940719 5.66383 0.836719 5.70383 0.756719 5.78383C0.676719 5.86383 0.636719 5.96783 0.636719 6.09583C0.636719 6.22383 0.676719 6.32783 0.756719 6.40783C0.836719 6.48783 0.940719 6.52783 1.06872 6.52783H13.3567L9.42072 10.6558C9.32472 10.7518 9.28472 10.8638 9.30072 10.9918C9.31672 11.1198 9.36472 11.2238 9.44472 11.3038C9.52472 11.3838 9.62872 11.4238 9.75672 11.4238C9.88472 11.4238 9.99672 11.3758 10.0927 11.2798L14.7007 6.38383C14.7647 6.31983 14.7967 6.22383 14.7967 6.09583C14.7967 5.96783 14.7647 5.87183 14.7007 5.80783Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_841_7638'>
          <rect
            width='14.75'
            height='12'
            fill='white'
            transform='matrix(1 0 0 -1 0.629883 12)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
