import { ICabinet, ICabinetDelete } from '../../../../store/redux/cabinets/cabinets.interface';
import { apiDelete, apiGet, apiPost, apiPut } from '../../interceptors';

export const CabinetsService = {
  async fetchCabinets(id: string) {
    const response = await apiGet(`/cabinets/${id}`);
    return response.data && response.data.cabinet ? response.data.cabinet : [];
  },
  async createCabinet(values: ICabinet) {
    const { id, ...newValues } = values;
    const response = await apiPut(`/cabinet/${id}`, newValues);

    return response.data;
  },
  async updateCabinet(values: ICabinet) {
    const { id, filial: idfilial, ...newValues } = values;
    const response = await apiPost(`/cabinet/${idfilial}/${id}`, newValues);

    return response.data;
  },

  async deleteCabinet(values: ICabinetDelete) {
    const { idcabinet, idfilial } = values;
    const response = await apiDelete(`/cabinet/${idfilial}/${idcabinet}`);

    return response.data;
  }
};
