import toast from 'react-hot-toast';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { IAuthResponse, IEmailPassword, IRegistration } from './auth.interface';

import ResendEmail from '../../../pages/AuthPage/lib/notify/resend-email/ResendEmail';
import { errorCatch } from '../../../shared/api/api.helpers';
import { AuthService } from '../../../shared/api/model/service/auth.service';
import { toastError } from '../../../utils/toast-error';

// register
export const registration = createAsyncThunk<IAuthResponse, IRegistration>(
  'auth/registry',
  async ({ email, password, confirmpassword, phone }, thunkApi) => {
    try {
      const response = await AuthService.register(email, password, confirmpassword, phone);

      return response.data;
    } catch (error) {
      toastError(error);

      return thunkApi.rejectWithValue(error);
    }
  }
);

// login
export const login = createAsyncThunk<IAuthResponse, IEmailPassword>('auth/login', async ({ username, password }, thunkApi) => {
  try {
    const response = await AuthService.login(username, password);
    if (response.status === 200) {
      return response.data;
    } else {
      if (response.data.description === 'email not valid') {
        toast.error('Неправильно введен email');
      } else if (response.data.description === 'authorization error') {
        toast.error('Неправильно введен пароль');
      } else if (response.data.description === 'Кабинет не активирован') {
        toast.error(<ResendEmail email={username} />);
      }
    }
  } catch (error) {
    toastError(error);

    return thunkApi.rejectWithValue(error);
  }
});

// logout

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthService.logout();
});

// checkAuth
export const checkAuth = createAsyncThunk<IAuthResponse>('auth/check-auth', async (_, thunkApi) => {
  try {
    const response = await AuthService.getUserInfo();

    return response.data;
  } catch (error) {
    if (errorCatch(error) === 'jwt expired') {
      toast.error('Logout');
      thunkApi.dispatch(logout() as any);
    }

    return thunkApi.rejectWithValue(error);
  }
});
