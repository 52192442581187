export function ChevronDownSmall() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.79289 9.29607C6.18342 8.90131 6.81658 8.90131 7.20711 9.29607L12.5 14.5596L17.7929 9.29607C18.1834 8.90131 18.8166 8.90131 19.2071 9.29607C19.5976 9.69083 19.5976 10.3309 19.2071 10.7256L13.2071 16.7039C12.8166 17.0987 12.1834 17.0987 11.7929 16.7039L5.79289 10.7256C5.40237 10.3309 5.40237 9.69083 5.79289 9.29607Z'
        fill='currentColor'
      />
    </svg>
  );
}
