import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AnalyticsState } from './analytics.interface';

import {
  getCabinetLessons,
  getGroupLessons,
  getIndividualLessons,
  getWorkingHours
} from '../../../shared/api/model/helpers/analytics.helpers';
import { FULFILLED, INIT, PENDING, REJECTED } from '../../../utils/state';
import { fetchCabinetsAsync } from '../cabinets/cabinets.actions';
import { fetchFilialsAsync } from '../filial/filial.actions';
import { fetchLessonsAsync } from '../lessons/lessons.actions';
import { IScheduleApp } from '../lessons/lessons.interface';
import { fetchProductsAsync } from '../products/product.actions';
import { fetchSubproductsAllAsync } from '../subproducts/subproducts.actions';
import { fetchUsersAsync } from '../user/user.actions';
import { IUser } from '../user/user.interface';

const initialState: AnalyticsState = {
  cabinets: [],
  employes: [],
  groupLessons: [],
  individualLessons: [],
  individualLessonsData: { count: 0, timeall: 0 },
  lessons: [],
  filials: [],
  groupLessonIndexData: { seats: 0, seatsAll: 10, seatsEmpty: 10, timeall: 0 },
  filialsWorkingHours: 0,
  cabinetLessons: [],
  cabinetWorkingHours: [],
  subproducts: [],
  products: [],
  state: INIT
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setCabinets: (state, action: PayloadAction<IScheduleApp[]>) => {
      state.cabinets = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLessonsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchLessonsAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
        state.lessons = action.payload;
        if (state.lessons.length !== 0) {
          getGroupLessons(state);
          getIndividualLessons(state);
          getCabinetLessons(state);
        } else {
          state.lessons = [];
        }
      })
      .addCase(fetchLessonsAsync.rejected, (state) => {
        state.state = REJECTED;
      })
      .addCase(fetchProductsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchProductsAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
        if (action.payload && action.payload.length > 0) {
          state.products = [...action.payload].sort((a, b) => (a.order > b.order ? 1 : -1));
          fetchSubproductsAllAsync(state.products.map((item) => item.id));
        } else {
          state.products = [];
        }
      })
      .addCase(fetchProductsAsync.rejected, (state) => {
        state.state = REJECTED;
      })
      .addCase(fetchCabinetsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchCabinetsAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
        if (action.payload && action.payload.length > 0) {
          const sorted = action.payload.sort((a: { id: number }, b: { id: number }) => a.id - b.id);
          state.cabinets = sorted;
          getWorkingHours(state);
          getCabinetLessons(state);
        } else {
          state.cabinets = [];
        }
      })
      .addCase(fetchCabinetsAsync.rejected, (state) => {
        state.state = REJECTED;
      })
      .addCase(fetchFilialsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchFilialsAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
        state.filials = action.payload;
      })
      .addCase(fetchFilialsAsync.rejected, (state) => {
        state.state = REJECTED;
      })
      .addCase(fetchUsersAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
        const filtered = action.payload.users.filter((user: IUser) => user.role !== 'admin');
        state.employes = filtered;
      })
      .addCase(fetchUsersAsync.rejected, (state) => {
        state.state = REJECTED;
      })
      .addCase(fetchSubproductsAllAsync.fulfilled, (state, action) => {
        state.subproducts = action.payload.flatMap((item) => item.data);
        state.state = FULFILLED;
      })
      .addCase(fetchSubproductsAllAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchSubproductsAllAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = analyticsSlice;
