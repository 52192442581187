export function ArrowLeftSquareOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.4616 3H14.5384C15.4859 2.99999 16.2517 2.99998 16.8721 3.05067C17.5114 3.10291 18.0752 3.21341 18.5976 3.47957C19.4255 3.90141 20.0986 4.57453 20.5204 5.40244C20.7866 5.92481 20.8971 6.48863 20.9493 7.12792C21 7.74834 21 8.51409 21 9.46159V14.5384C21 15.4859 21 16.2517 20.9493 16.8721C20.8971 17.5114 20.7866 18.0752 20.5204 18.5976C20.0986 19.4255 19.4255 20.0986 18.5976 20.5204C18.0752 20.7866 17.5114 20.8971 16.8721 20.9493C16.2517 21 15.4859 21 14.5384 21H9.46159C8.51409 21 7.74834 21 7.12792 20.9493C6.48863 20.8971 5.92481 20.7866 5.40244 20.5204C4.57453 20.0986 3.90141 19.4255 3.47957 18.5976C3.21341 18.0752 3.10291 17.5114 3.05067 16.8721C2.99998 16.2517 2.99999 15.4859 3 14.5384V9.46159C2.99999 8.51409 2.99998 7.74834 3.05067 7.12792C3.10291 6.48863 3.21341 5.92481 3.47957 5.40244C3.90141 4.57453 4.57453 3.90141 5.40244 3.47957C5.92481 3.21341 6.48863 3.10291 7.12792 3.05067C7.74834 2.99998 8.51409 2.99999 9.4616 3ZM7.2745 4.8447C6.74652 4.88783 6.44595 4.96807 6.21963 5.08338C5.7304 5.33265 5.33265 5.7304 5.08338 6.21963C4.96807 6.44595 4.88783 6.74652 4.8447 7.2745C4.8007 7.81298 4.8 8.50506 4.8 9.5V14.5C4.8 15.4949 4.8007 16.187 4.8447 16.7255C4.88783 17.2535 4.96807 17.5541 5.08338 17.7804C5.33265 18.2696 5.7304 18.6673 6.21963 18.9166C6.44595 19.0319 6.74652 19.1122 7.2745 19.1553C7.81298 19.1993 8.50506 19.2 9.5 19.2H14.5C15.4949 19.2 16.187 19.1993 16.7255 19.1553C17.2535 19.1122 17.5541 19.0319 17.7804 18.9166C18.2696 18.6673 18.6673 18.2696 18.9166 17.7804C19.0319 17.5541 19.1122 17.2535 19.1553 16.7255C19.1993 16.187 19.2 15.4949 19.2 14.5V9.5C19.2 8.50506 19.1993 7.81298 19.1553 7.2745C19.1122 6.74652 19.0319 6.44595 18.9166 6.21963C18.6673 5.7304 18.2696 5.33265 17.7804 5.08338C17.5541 4.96807 17.2535 4.88783 16.7255 4.8447C16.187 4.8007 15.4949 4.8 14.5 4.8H9.5C8.50506 4.8 7.81298 4.8007 7.2745 4.8447Z'
        fill='currentColor'
      />
      <path
        d='M10.3655 8.26395C10.717 7.91247 11.2868 7.91247 11.6383 8.26395C11.9898 8.61542 11.9898 9.18527 11.6383 9.53674L10.0751 11.1L16.0976 11.1C16.5947 11.1 16.9976 11.5029 16.9976 12C16.9976 12.497 16.5947 12.9 16.0976 12.9H10.0751L11.6403 14.4651C11.9917 14.8166 11.9917 15.3864 11.6403 15.7379C11.2888 16.0894 10.7189 16.0894 10.3675 15.7379L7.2659 12.6363C7.09228 12.4627 7.00442 12.2358 7.00233 12.0083C7.00231 12.0055 7.0023 12.0027 7.0023 12C7.0023 11.9972 7.00231 11.9944 7.00233 11.9916C7.00341 11.8726 7.02758 11.7592 7.07059 11.6554C7.11418 11.5501 7.17865 11.4513 7.26399 11.3655'
        fill='currentColor'
      />
      <path
        d='M10.3655 8.26395L7.26632 11.3631L10.3655 8.26395Z'
        fill='currentColor'
      />
    </svg>
  );
}
