import { styled } from 'styled-components';
import themelight from '../../../../../../../../app/providers/ThemeProvider/ui/theme';
import NewButton from '../../../../../../../../shared/new-ui/NewButton/ui/NewButton';
import { FlexWithAlign } from '../../../../../../../../utils/styleUtils';

export const FormWrapper = styled(FlexWithAlign)`
  padding-top: 24px;
  width: 100%;
  border-top: 1px solid ${themelight.newColor.base08};
`;

export const TopWrapper = styled(FlexWithAlign)`
  padding-bottom: 12px;
  border-bottom: 1px solid ${themelight.newColor.base08};
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.3fr;
  gap: 16px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${themelight.newColor.base08};
`;

export const BtnsWrap = styled(FlexWithAlign)`
  margin-top: 10px;
`;

export const AddPositionBtn = styled(NewButton)`
  &.without-border {
    padding: 10px 10px 10px 0;
    color: ${themelight.newText.textTitle};
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const DeletePositionBtn = styled(NewButton)`
  &.only-icon {
    padding: 12px;
    color: ${themelight.newColor.red};
    border: none;
    &:disabled {
      background-color: transparent;
      color: ${themelight.newColor.grey03};
    }
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;
