import React from 'react';

export function SmallTrashIcon() {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.04136 3H0.749878C0.335664 3 -0.00012207 3.33579 -0.00012207 3.75C-0.00012207 4.16421 0.335664 4.5 0.749878 4.5H1.29731L2.03554 11.6983C2.07221 12.0561 2.10403 12.3665 2.15054 12.6219C2.19987 12.8928 2.27471 13.1592 2.42729 13.4122C2.6565 13.7922 2.99297 14.0959 3.39437 14.2851C3.66156 14.4111 3.9342 14.4584 4.20876 14.4798C4.46757 14.5 4.77961 14.5 5.13922 14.5H8.86057C9.22018 14.5 9.53221 14.5 9.79103 14.4798C10.0656 14.4584 10.3382 14.4111 10.6054 14.2851C11.0068 14.0959 11.3433 13.7922 11.5725 13.4122C11.7251 13.1593 11.7999 12.8929 11.8492 12.6219C11.8957 12.3665 11.9276 12.0562 11.9642 11.6984L12.7026 4.5H13.2499C13.6641 4.5 13.9999 4.16421 13.9999 3.75C13.9999 3.33579 13.6641 3 13.2499 3H9.9584C9.72036 1.58114 8.48638 0.5 6.99988 0.5C5.51338 0.5 4.2794 1.58114 4.04136 3ZM5.58523 3H8.41453C8.20861 2.4174 7.65299 2 6.99988 2C6.34677 2 5.79115 2.4174 5.58523 3ZM11.1947 4.5H2.80518L3.52494 11.5183C3.56519 11.9108 3.59152 12.1623 3.62627 12.3532C3.65936 12.5349 3.69079 12.6027 3.7117 12.6374C3.78811 12.7641 3.90027 12.8653 4.03407 12.9284C4.07069 12.9456 4.14134 12.97 4.3255 12.9844C4.51892 12.9995 4.77186 13 5.16633 13H8.83346C9.22793 13 9.48086 12.9995 9.67428 12.9844C9.85844 12.97 9.92909 12.9456 9.96571 12.9284C10.0995 12.8653 10.2117 12.7641 10.2881 12.6374C10.309 12.6027 10.3404 12.5349 10.3735 12.3532C10.4083 12.1623 10.4346 11.9108 10.4748 11.5184L11.1947 4.5ZM5.21243 5.50094C5.62612 5.48025 5.97826 5.79885 5.99894 6.21255L6.24894 11.2125C6.26963 11.6262 5.95103 11.9784 5.53733 11.9991C5.12363 12.0197 4.7715 11.7011 4.75081 11.2875L4.50081 6.28745C4.48013 5.87376 4.79873 5.52162 5.21243 5.50094ZM8.78733 5.50094C9.20103 5.52162 9.51963 5.87376 9.49894 6.28745L9.24894 11.2875C9.22826 11.7011 8.87612 12.0197 8.46243 11.9991C8.04873 11.9784 7.73013 11.6262 7.75081 11.2125L8.00081 6.21255C8.0215 5.79885 8.37363 5.48025 8.78733 5.50094Z'
        fill='currentColor'
      />
    </svg>
  );
}
