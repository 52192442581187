export function ReplyOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.0236 3.72537C13.1393 3.8041 14.02 4.73418 14.02 5.86997L14.021 7.98704L14.0414 7.99194C18.5737 8.93093 21.92 12.9471 21.92 17.6854C21.92 18.0297 21.9024 18.3727 21.8676 18.713C21.7846 19.5242 20.7519 19.8157 20.257 19.1676L20.0702 18.9312C18.6109 17.1447 16.5332 15.9728 14.2345 15.6658L14.021 15.64L14.02 18.1344C14.02 18.615 13.8588 19.082 13.5622 19.4604C12.8297 20.395 11.4783 20.5588 10.5437 19.8263L2.72004 13.6942C2.5838 13.5874 2.46095 13.4646 2.35416 13.3283C1.62167 12.3938 1.78548 11.0424 2.72004 10.3099L10.5437 4.1778C10.9222 3.88117 11.3892 3.71997 11.87 3.71997L12.0236 3.72537ZM11.87 5.51997C11.7917 5.51997 11.7157 5.54621 11.6541 5.5945L3.83042 11.7266C3.67829 11.8458 3.65162 12.0658 3.77086 12.218C3.78825 12.2401 3.80825 12.2601 3.83042 12.2775L11.6541 18.4096C11.8062 18.5288 12.0262 18.5022 12.1455 18.35C12.1938 18.2884 12.22 18.2124 12.22 18.1341L12.221 14.6868C12.2212 14.184 12.6331 13.7789 13.1358 13.7872L13.369 13.791L13.7112 13.8075C15.9853 13.9527 18.1079 14.7947 19.8297 16.1734L20.004 16.317L19.9802 16.1817C19.3374 12.7662 16.5453 10.0763 13.0119 9.64542C12.5604 9.59037 12.2209 9.20712 12.2208 8.75229L12.22 5.87022C12.22 5.67667 12.0633 5.51997 11.87 5.51997Z'
        fill='currentColor'
      />
    </svg>
  );
}
