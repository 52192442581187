export const PaymentIcon13 = () => {
  return (
    <svg
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.094 22.5815L4.75543 30.3302L4.75024 9.66636L9.094 17.4305V22.5815Z'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <path
        d='M23.3486 19.4488V14.3545L27.7083 11.6824L36.0389 11.6747L23.3486 19.4488Z'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <path
        d='M18.4932 16.9958V0.961064L22.823 8.72035L22.8475 19.6713L18.4932 16.9958Z'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <path
        d='M23.259 8.47631L18.9259 0.710459L36.0397 11.1745L27.7071 11.1823L23.259 8.47631Z'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <path
        d='M18.4932 23.2903L22.8485 25.9155V31.2707L18.4957 39.0426L18.4932 23.2903Z'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <path
        d='M36.023 28.3191L27.6971 28.3293L9.52999 17.1864L5.18551 9.42036L36.023 28.3191Z'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <path
        d='M27.697 28.8296L36.0193 28.8194L18.9332 39.2872L23.2851 31.5158L27.697 28.8296Z'
        stroke='currentColor'
        strokeWidth='0.5'
      />
      <path
        d='M13.6515 20.2991L17.8052 22.8477L5.1918 30.5745L9.53046 22.8256L13.6515 20.2991Z'
        stroke='currentColor'
        strokeWidth='0.5'
      />
    </svg>
  );
};
