import React from 'react';

export function PaymentAddIcon() {
  return (
    <svg
      width='22'
      height='20'
      viewBox='0 0 22 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.94339 -9.19088e-05C5.75513 -0.000452541 4.9635 -0.000692719 4.29089 0.196804C2.70396 0.662767 1.46294 1.90379 0.996975 3.49072C0.799478 4.16333 0.799718 4.95495 0.800079 6.14322L0.800111 9.00285C0.800103 10.0788 0.800097 10.941 0.857052 11.6381C0.915551 12.3541 1.03859 12.9748 1.32982 13.5463C1.79576 14.4608 2.53925 15.2043 3.45372 15.6702C4.02528 15.9615 4.64591 16.0845 5.3619 16.143C6.059 16.2 6.92127 16.1999 7.99715 16.1999H18.1273L16.6637 17.6635C16.3122 18.015 16.3122 18.5849 16.6637 18.9363C17.0152 19.2878 17.585 19.2878 17.9365 18.9363L20.9365 15.9363C21.288 15.5849 21.288 15.015 20.9365 14.6635L17.9365 11.6635C17.585 11.3121 17.0152 11.3121 16.6637 11.6635C16.3122 12.015 16.3122 12.5849 16.6637 12.9363L18.1273 14.3999H8.03611C6.91236 14.3999 6.12367 14.3992 5.50848 14.349C4.9038 14.2996 4.54642 14.2068 4.2709 14.0664C3.69512 13.773 3.227 13.3049 2.93363 12.7292C2.79324 12.4536 2.70048 12.0963 2.65107 11.4916C2.60081 10.8764 2.60011 10.0877 2.60011 8.96394V7.19994H19.4001V9.94994C19.4001 10.447 19.8031 10.8499 20.3001 10.8499C20.7972 10.8499 21.2001 10.447 21.2001 9.94994L21.2001 6.14322C21.2005 4.95495 21.2007 4.16333 21.0032 3.49072C20.5373 1.90379 19.2963 0.662767 17.7093 0.196804C17.0367 -0.000692719 16.2451 -0.000452541 15.0568 -9.19088e-05H6.94339ZM19.3968 5.39994H2.60337C2.61138 4.64908 2.63812 4.29053 2.72406 3.99784C3.01745 2.99866 3.79884 2.21728 4.79801 1.92389C5.18885 1.80913 5.69712 1.79994 7.10011 1.79994H14.9001C16.3031 1.79994 16.8114 1.80913 17.2022 1.92389C18.2014 2.21728 18.9828 2.99866 19.2762 3.99784C19.3621 4.29053 19.3888 4.64908 19.3968 5.39994Z'
        fill='currentColor'
      />
    </svg>
  );
}
