import styled from 'styled-components';

import { FlexWithAlign } from '../../../utils/styleUtils';

export const PersonalAreaWrapper = styled(FlexWithAlign)`
  padding: 24px;
  max-width: 968px;
  height: 100%;
  width: 100%;
`;

export const PortalTextWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 12px;
  max-height: 960px;
  overflow-y: scroll;
  padding-inline: 48px;
  .font-weight{
    font-weight: 700;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  overflow: scroll;
  @media (max-width: 767px) {
    width: 87vw;
    height: 70vh;
    padding-left: 7vw;
  }
`;