export const DocumentIcon = () => {
  return (
    <svg
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5189_21404)'>
        <path
          d='M16.9151 0.719297C17.0218 0.719297 17.1551 0.785963 17.3151 0.919298C17.4751 1.05263 17.5551 1.1993 17.5551 1.3593V18.6393C17.5551 18.746 17.4885 18.8793 17.3551 19.0393C17.2218 19.1993 17.0751 19.2793 16.9151 19.2793H1.15514C0.995137 19.2793 0.84847 19.2126 0.715137 19.0793C0.581803 18.946 0.515137 18.7993 0.515137 18.6393V6.9593C0.515137 6.7993 0.56847 6.69263 0.675137 6.6393L6.03514 0.959297C6.08847 0.905964 6.2218 0.879297 6.43514 0.879297H16.9151V0.719297ZM16.2751 1.8393H7.15514V6.8793C7.15514 6.98596 7.1018 7.09263 6.99514 7.1993C6.88847 7.30596 6.72847 7.3593 6.51514 7.3593H1.79514V17.9993H16.2751V1.8393ZM14.1151 14.9593C14.2751 14.9593 14.4085 15.0126 14.5151 15.1193C14.6218 15.226 14.6751 15.3593 14.6751 15.5193V15.7593C14.6751 15.866 14.6218 15.9726 14.5151 16.0793C14.4085 16.186 14.2751 16.2393 14.1151 16.2393H3.87514C3.76847 16.2393 3.6618 16.186 3.55514 16.0793C3.44847 15.9726 3.39514 15.866 3.39514 15.7593V15.5193C3.39514 15.3593 3.44847 15.226 3.55514 15.1193C3.6618 15.0126 3.76847 14.9593 3.87514 14.9593H14.1151ZM14.1151 11.8393C14.2751 11.8393 14.4085 11.8926 14.5151 11.9993C14.6218 12.106 14.6751 12.2393 14.6751 12.3993V12.6393C14.6751 12.746 14.6218 12.8526 14.5151 12.9593C14.4085 13.066 14.2751 13.1193 14.1151 13.1193H3.87514C3.76847 13.1193 3.6618 13.066 3.55514 12.9593C3.44847 12.8526 3.39514 12.746 3.39514 12.6393V12.3993C3.39514 12.2393 3.44847 12.106 3.55514 11.9993C3.6618 11.8926 3.76847 11.8393 3.87514 11.8393H14.1151ZM14.1151 8.7193C14.2751 8.7193 14.4085 8.77263 14.5151 8.8793C14.6218 8.98596 14.6751 9.1193 14.6751 9.2793V9.5193C14.6751 9.62596 14.6218 9.73263 14.5151 9.8393C14.4085 9.94596 14.2751 9.9993 14.1151 9.9993H3.87514C3.76847 9.9993 3.6618 9.94596 3.55514 9.8393C3.44847 9.73263 3.39514 9.62596 3.39514 9.5193V9.2793C3.39514 9.1193 3.44847 8.98596 3.55514 8.8793C3.6618 8.77263 3.76847 8.7193 3.87514 8.7193H14.1151ZM6.03514 2.7193L2.67514 6.2393H6.03514V2.7193Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_5189_21404'>
          <rect
            width='17.45'
            height='20'
            fill='white'
            transform='matrix(1 0 0 -1 0.27002 20)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
