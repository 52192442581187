import { IFilial, IFilialModal } from '../../../../store/redux/filial/filial.interface';
import { apiGet, apiPost, apiPut } from '../../interceptors';

export const FilialService = {
  async fetchFilials() {
    const response = await apiGet('/filials');

    return response.data && response.data.filial ? response.data.filial : [];
  },
  async updateFilial(data: IFilial) {
    const { id, ...values } = data;
    const response = await apiPost(`/filial/${id}`, values);

    return response.data;
  },
  async createFilial(data: IFilialModal) {
    await apiPut('filial', data);
  },
  async fetchFilialById(id: string | undefined) {
    const response = await apiGet(`/filial/${id}`);

    return response;
  }
};
