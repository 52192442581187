export const OnlineRecord = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2547_12666)'>
        <g clipPath='url(#clip1_2547_12666)'>
          <g clipPath='url(#clip2_2547_12666)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M8.16452 2.1875L7.97905 2.19801C5.76204 2.36157 3.86413 3.8669 3.15322 6.00027L3.1419 6.03626L3.00356 6.07205C1.2437 6.55469 -0.00594763 8.19209 2.12913e-05 10.0821L0.00430073 10.2571C0.00413469 12.5993 1.69339 14.54 3.81208 14.54H13.3755C15.9594 14.54 18 12.1865 18 9.3037C18 6.41683 15.9563 4.04264 13.3755 4.04264L13.1442 4.05372C12.8375 4.07674 12.5375 4.13265 12.2471 4.22038L12.2231 4.22779L12.1144 4.0905C11.1869 2.96838 9.84255 2.2778 8.39365 2.19473L8.16452 2.1875ZM8.0298 3.25606L8.1932 3.24581H8.17549C9.5463 3.27166 10.8193 3.98297 11.5834 5.15233C11.7237 5.36715 11.9979 5.45038 12.234 5.34982C12.5999 5.19399 12.9908 5.10974 13.3867 5.10134C15.3334 5.10146 16.9412 6.9693 16.9412 9.3037C16.9412 11.6326 15.3384 13.4812 13.3755 13.4812H3.81208C2.35743 13.4812 1.13673 12.1279 1.06618 10.4018L1.05868 10.0671C1.054 8.54365 2.15399 7.23774 3.63523 7.01939C3.84521 6.98843 4.0164 6.83507 4.07017 6.62974C4.56196 4.75164 6.15447 3.40633 8.0298 3.25606Z'
              fill='currentColor'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2547_12666'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_2547_12666'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip2_2547_12666'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
