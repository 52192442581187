import React, { memo, useEffect, useRef, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field';

import { TextAreaProps } from './NewTextArea.props';
import { CurrencyPreffix, ErrorText, IconContainer, InputContainer, InputContent, Label } from './NewTextArea.styled';

import { cn } from '../../lib';
import NewText from '../NewText/ui/NewText';
import { TextCustomType, TextType } from '../NewText/ui/NewText.props';

export const NewTextArea = memo((props: TextAreaProps) => {
  const { label, type, autofocus, className, children, value, onChange, name, error = null, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus) {
      setIsFocused(true);
      ref.current?.focus();
    }
  }, [autofocus]);

  const onBlur = () => {
    setIsFocused(false);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const handleInputChange = (value: string | undefined, name: string | undefined, values: CurrencyInputOnChangeValues | undefined) => {
    if (name !== undefined) {
      if (value !== undefined) {
        onChange({
          target: {
            value: '0',
            name: name
          }
        } as React.ChangeEvent<HTMLTextAreaElement>);
      }
      onChange({
        target: {
          value: values?.float || '',
          name: name
        }
      } as React.ChangeEvent<HTMLTextAreaElement>);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');
    const digitsOnly = pastedData.replace(/\D/g, '');

    let formattedNumber: string;
    if (digitsOnly.length === 10) {
      formattedNumber = `+7 ${digitsOnly.slice(0, 3)} ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 8)}-${digitsOnly.slice(8, 10)}`;
    } else if (digitsOnly.length === 11 && (digitsOnly.startsWith('7') || digitsOnly.startsWith('8'))) {
      formattedNumber = `+7 ${digitsOnly.slice(1, 4)} ${digitsOnly.slice(4, 7)}-${digitsOnly.slice(7, 9)}-${digitsOnly.slice(9, 11)}`;
    } else {
      formattedNumber = digitsOnly;
    }

    onChange({
      target: {
        value: formattedNumber,
        name: name
      }
    } as React.ChangeEvent<HTMLTextAreaElement>);

    event.preventDefault();
  };

  return (
    <InputContainer
      ref={ref}
      className={cn(isFocused && 'focused', { error: !!error }, className)}
    >
      <Label
        htmlFor={name}
        className={cn(
          {
            active: (value && value.length) || (value && Number(value)) > 0 || (type === 'number' && Number(value) === 0),
            error: !!error
          }
        )}
      >
        {label && <span>{label}</span>}

        {type === 'currency' ? (
          <CurrencyPreffix className={'currencyRubble'}>
            <NewText
              $tag={TextType.H6}
              $customSize={TextCustomType.T14}
              color={'black'}
            >
              ₽
            </NewText>
          </CurrencyPreffix>
        ) : null}
        <InputContent
          className={cn(
            {
              active: (value && value.length) || (value && Number(value)) > 0 || (type === 'number' && Number(value) === 0),
              error: !!error
            },
            type === 'currency' && 'currency'
          )}
          autoComplete={'off'}
          onFocus={onFocus}
          onPaste={type === 'tel' ? handlePaste : () => {}}
          onBlur={onBlur}
          value={value}
          ref={ref}
          onChange={onChange}
          onInput={onChange}
          contentEditable={true}
          id={name}
          {...rest}
        />
      </Label>
      <IconContainer
        className={cn({
          active: value && value.length,
          error: !!error
        })}
      >
        {children}
      </IconContainer>

      {error ? <ErrorText>{error}</ErrorText> : null}
    </InputContainer>
  );
});

NewTextArea.displayName = 'NewTextArea';
