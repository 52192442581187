import { IService, ISubproductDeleteData, ISubproductsAll } from '../../../../store/redux/subproducts/subproducts.interface';
import { apiDelete, apiGet, apiPost, apiPut } from '../../interceptors';

export const SubproductsService = {
  fetchSubproductsAll: async (ids: string[]) => {
    if (!ids || ids.length === 0) {
      return [];
    }

    const subProductsArr: ISubproductsAll[] = [];

    try {
      const promises = ids.map(async (id) => {
        const response = await apiGet(`/subproducts/${id}`);

        if (response.data.subproduct !== null) {
          subProductsArr.push({
            productId: id,
            data: response.data.subproduct
          });
        } else {
          subProductsArr.push({
            productId: id,
            data: []
          });
        }
      });

      await Promise.all(promises);

      return subProductsArr;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },

  fetchSubproducts: async (id: string) => {
    const response = await apiGet(`/subproducts/${id}`);

    if (response.data) {
      return response.data.subproduct || [];
    }

    return [];
  },
  getSubproduct: async (id: string | undefined) => {
    if (!id) return {};
    const response = await apiGet(`/getsubproduct/${id}`);

    if (response.data) {
      return response.data.subproduct || {};
    }

    return {};
  },
  createSubproduct: async (values: IService) => {
    const { id, ...newValues } = values;
    const response = await apiPut(`/subproduct/${id}`, newValues);

    return response.data;
  },
  updateSubproduct: async (values: IService) => {
    const { id, idparents, ...newValues } = values;
    const response = await apiPost(`/subproduct/${idparents}/${id}`, newValues);

    return response.data;
  },
  deleteSubproduct: async (values: ISubproductDeleteData) => {
    const { productid, subproductid } = values;
    const response = await apiDelete(`/subproduct/${productid}/${subproductid}`);

    return response.data;
  },
  getAllSubproducts: async () => {
    const response = await apiGet('/allsubproducts');

    return response.data.subproduct ? response.data.subproduct : response.data;
  }
};
