import styled from 'styled-components';

import { FlexWithAlign } from '../../../utils/styleUtils';

export const PersonalAreaWrapper = styled(FlexWithAlign)`
  padding: 24px;
  max-width: 968px;
  height: 100%;
  width: 100%;
`;

export const PortalTextWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 12px;
  max-height: 960px;
  overflow-y: scroll;
  padding-inline: 48px;
  padding-block: 56px;
  .font-weight {
    font-weight: 700;
  }
  .padding{
    padding-left: 15px;
  }
`;
