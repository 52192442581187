import React from 'react';

export function RecordingIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.24198 8.9001C6.62065 10.0611 7.71216 10.9001 8.99961 10.9001C10.2871 10.9001 11.3786 10.0611 11.7572 8.9001C14.5047 8.9001 17.2522 8.9001 19.9996 8.9001C20.4967 8.9001 20.8996 8.49715 20.8996 8.0001C20.8996 7.50304 20.4967 7.1001 19.9996 7.1001C17.2522 7.1001 14.5047 7.1001 11.7572 7.1001C11.7475 7.06973 11.7605 7.11019 11.7572 7.1001C11.3786 5.93906 10.2871 5.1001 8.99961 5.1001C7.71216 5.1001 6.62065 5.93906 6.24198 7.1001C5.49452 7.1001 4.74706 7.1001 3.99961 7.1001C3.50255 7.1001 3.09961 7.50304 3.09961 8.0001C3.09961 8.49715 3.50255 8.9001 3.99961 8.9001C4.74706 8.9001 5.49452 8.9001 6.24198 8.9001ZM8.99961 6.9001C8.3921 6.9001 7.89961 7.39258 7.89961 8.0001C7.89961 8.60761 8.3921 9.1001 8.99961 9.1001C9.60712 9.1001 10.0996 8.60761 10.0996 8.0001C10.0996 7.39258 9.60712 6.9001 8.99961 6.9001Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.09961 16.0001C3.09961 15.503 3.50255 15.1001 3.99961 15.1001C6.74707 15.1001 9.49452 15.1001 12.242 15.1001C12.6207 13.9391 13.7122 13.1001 14.9996 13.1001C16.2871 13.1001 17.3786 13.9391 17.7572 15.1001C17.7572 15.1001 19.4782 15.1001 19.9996 15.1001C20.4967 15.1001 20.8996 15.503 20.8996 16.0001C20.8996 16.4972 20.4967 16.9001 19.9996 16.9001C19.7774 16.9001 17.7572 16.9001 17.7572 16.9001C17.3786 18.0611 16.2871 18.9001 14.9996 18.9001C13.7122 18.9001 12.6207 18.0611 12.242 16.9001C9.49452 16.9001 6.74707 16.9001 3.99961 16.9001C3.50255 16.9001 3.09961 16.4972 3.09961 16.0001ZM13.8996 16.0001C13.8996 15.3926 14.3921 14.9001 14.9996 14.9001C15.6071 14.9001 16.0996 15.3926 16.0996 16.0001C16.0996 16.6076 15.6071 17.1001 14.9996 17.1001C14.3921 17.1001 13.8996 16.6076 13.8996 16.0001Z'
        fill='currentColor'
      />
    </svg>
  );
}
