export const PaymentIcon15 = () => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.7613 22.9445L14.9247 22.4646L14.4426 22.3079C11.7076 21.419 9.85761 18.8213 9.99487 15.8015L9.99539 15.7902V15.7788C9.99539 12.8853 11.7854 10.2847 14.5163 9.39718L14.9985 9.24047L14.8351 8.76053L13.6554 5.29509L13.4937 4.82014L13.0193 4.98345C8.32102 6.60088 5.23502 10.9189 5.23502 15.8525C5.23502 20.7791 8.31502 25.1781 12.9502 26.7232L13.4215 26.8803L13.5816 26.41L14.7613 22.9445ZM19.267 26.4717L19.4153 26.9463L19.8907 26.8006C24.6752 25.3344 27.9124 20.9355 27.9124 15.8525C27.9124 10.9188 24.7517 6.52348 20.0475 4.98111L19.5747 4.8261L19.4174 5.29811L18.2376 8.83728L18.0783 9.3152L18.5574 9.47091C21.299 10.3619 23.1521 12.8968 23.1521 15.8525C23.1521 18.8118 21.2961 21.4161 18.4871 22.3068L18.0125 22.4573L18.161 22.9325L19.267 26.4717ZM1 16C1 7.42822 7.92822 0.5 16.5 0.5C25.0718 0.5 32 7.42822 32 16C32 24.5718 25.0718 31.5 16.5 31.5C7.92822 31.5 1 24.5718 1 16Z'
        stroke='currentColor'
      />
    </svg>
  );
};
