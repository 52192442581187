export const ArrowClients = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.43988 16.0801C7.27988 16.0801 7.14655 16.0267 7.03988 15.9201C6.93322 15.8134 6.87988 15.6934 6.87988 15.5601C6.87988 15.4267 6.93322 15.3334 7.03988 15.2801L11.9199 10.0801L7.03988 4.96008C6.93322 4.85341 6.87988 4.73341 6.87988 4.60008C6.87988 4.46675 6.93322 4.34675 7.03988 4.24008C7.14655 4.13341 7.26655 4.08008 7.39988 4.08008C7.53322 4.08008 7.65322 4.13341 7.75988 4.24008L12.9599 9.76008C13.0665 9.86674 13.1199 9.98674 13.1199 10.1201C13.1199 10.2534 13.0665 10.3734 12.9599 10.4801L7.75988 15.9201C7.65322 16.0267 7.54655 16.0801 7.43988 16.0801Z'
        fill='#BCBCC2'
      />
    </svg>
  );
};
