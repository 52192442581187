export function ChevronLeftSquareOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.4643 8.26397C12.8158 7.9125 13.3856 7.9125 13.7371 8.26397C14.0885 8.61544 14.0885 9.18529 13.7371 9.53676L11.2739 12L13.739 14.4651C14.0905 14.8166 14.0905 15.3865 13.739 15.7379C13.3876 16.0894 12.8177 16.0894 12.4662 15.7379L9.36467 12.6364C9.0132 12.2849 9.0132 11.7151 9.36467 11.3636L12.4643 8.26397Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.5384 3H9.4616C8.5141 2.99999 7.74834 2.99998 7.12792 3.05067C6.48863 3.10291 5.92481 3.21341 5.40244 3.47957C4.57453 3.90141 3.90141 4.57453 3.47957 5.40244C3.21341 5.92481 3.10291 6.48863 3.05067 7.12792C2.99998 7.74834 2.99999 8.51409 3 9.46158V14.5384C2.99999 15.4859 2.99998 16.2517 3.05067 16.8721C3.10291 17.5114 3.21341 18.0752 3.47957 18.5976C3.90141 19.4255 4.57453 20.0986 5.40244 20.5204C5.92481 20.7866 6.48863 20.8971 7.12792 20.9493C7.74834 21 8.51409 21 9.46159 21H14.5384C15.4859 21 16.2517 21 16.8721 20.9493C17.5114 20.8971 18.0752 20.7866 18.5976 20.5204C19.4255 20.0986 20.0986 19.4255 20.5204 18.5976C20.7866 18.0752 20.8971 17.5114 20.9493 16.8721C21 16.2517 21 15.4859 21 14.5384V9.46159C21 8.51409 21 7.74834 20.9493 7.12792C20.8971 6.48863 20.7866 5.92481 20.5204 5.40244C20.0986 4.57453 19.4255 3.90141 18.5976 3.47957C18.0752 3.21341 17.5114 3.10291 16.8721 3.05067C16.2517 2.99998 15.4859 2.99999 14.5384 3ZM6.21963 5.08338C6.44595 4.96807 6.74652 4.88783 7.2745 4.8447C7.81298 4.8007 8.50506 4.8 9.5 4.8H14.5C15.4949 4.8 16.187 4.8007 16.7255 4.8447C17.2535 4.88783 17.5541 4.96807 17.7804 5.08338C18.2696 5.33265 18.6673 5.7304 18.9166 6.21963C19.0319 6.44595 19.1122 6.74652 19.1553 7.2745C19.1993 7.81298 19.2 8.50506 19.2 9.5V14.5C19.2 15.4949 19.1993 16.187 19.1553 16.7255C19.1122 17.2535 19.0319 17.5541 18.9166 17.7804C18.6673 18.2696 18.2696 18.6673 17.7804 18.9166C17.5541 19.0319 17.2535 19.1122 16.7255 19.1553C16.187 19.1993 15.4949 19.2 14.5 19.2H9.5C8.50506 19.2 7.81298 19.1993 7.2745 19.1553C6.74652 19.1122 6.44595 19.0319 6.21963 18.9166C5.7304 18.6673 5.33265 18.2696 5.08338 17.7804C4.96807 17.5541 4.88783 17.2535 4.8447 16.7255C4.8007 16.187 4.8 15.4949 4.8 14.5V9.5C4.8 8.50506 4.8007 7.81298 4.8447 7.2745C4.88783 6.74652 4.96807 6.44595 5.08338 6.21963C5.33265 5.7304 5.7304 5.33265 6.21963 5.08338Z'
        fill='currentColor'
      />
    </svg>
  );
}
