import React from 'react';

export function NotifySuccess() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5 12C1.5 5.92472 6.42472 1 12.5 1C18.5753 1 23.5 5.92472 23.5 12C23.5 18.0753 18.5753 23 12.5 23C6.42472 23 1.5 18.0753 1.5 12ZM12.5 3C7.52928 3 3.5 7.02928 3.5 12C3.5 16.9707 7.52928 21 12.5 21C17.4707 21 21.5 16.9707 21.5 12C21.5 7.02928 17.4707 3 12.5 3Z'
        fill='#4ac99b'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.669 8.75671C17.0795 9.12617 17.1128 9.75846 16.7433 10.169L12.2433 15.169C12.0653 15.3668 11.8158 15.4854 11.55 15.4988C11.2843 15.5121 11.0242 15.4189 10.8273 15.2399L8.32729 12.9669C7.91865 12.5954 7.88858 11.9629 8.26011 11.5543C8.63164 11.1457 9.26409 11.1156 9.67272 11.4871L11.4291 13.084L15.2567 8.83104C15.6262 8.42053 16.2585 8.38726 16.669 8.75671Z'
        fill='#4ac99b'
      />
    </svg>
  );
}
