import React from 'react';

export function MessageIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.8951 0H4.10486C2.95297 0 2.36572 0.113403 1.7545 0.440284C1.19025 0.742047 0.742048 1.19025 0.440284 1.7545C0.113403 2.36572 0 2.95297 0 4.10486V9.89514C0 11.047 0.113403 11.6343 0.440284 12.2455C0.742048 12.8097 1.19025 13.258 1.7545 13.5597L1.8954 13.6314C2.4124 13.8807 2.94467 13.9827 3.84879 13.9979L4.1 14V16.2149C4.1 16.6467 4.2693 17.0614 4.57155 17.3698L4.68817 17.478C5.33091 18.0196 6.29233 17.9937 6.90488 17.3933L10.366 14H13.8951C15.047 14 15.6343 13.8866 16.2455 13.5597C16.8097 13.258 17.258 12.8097 17.5597 12.2455C17.8866 11.6343 18 11.047 18 9.89514V4.10486C18 2.95297 17.8866 2.36572 17.5597 1.7545C17.258 1.19025 16.8097 0.742047 16.2455 0.440284C15.6343 0.113403 15.047 0 13.8951 0ZM3.91166 1.80107L13.8951 1.8C14.7753 1.8 15.0818 1.85919 15.3966 2.02755C15.6472 2.16155 15.8384 2.35282 15.9725 2.60338C16.1408 2.91818 16.2 3.2247 16.2 4.10486V9.89514L16.1956 10.2628C16.1792 10.8698 16.1149 11.1303 15.9725 11.3966C15.8384 11.6472 15.6472 11.8384 15.3966 11.9725C15.0818 12.1408 14.7753 12.2 13.8951 12.2H10L9.88323 12.2076C9.69068 12.2328 9.51026 12.3198 9.37006 12.4572L5.9 15.857V13.1C5.9 12.6029 5.49706 12.2 5 12.2H4.10486L3.73724 12.1956C3.13019 12.1792 2.86975 12.1149 2.60338 11.9725C2.35282 11.8384 2.16155 11.6472 2.02755 11.3966C1.85919 11.0818 1.8 10.7753 1.8 9.89514V4.10486L1.80439 3.73724C1.8208 3.13019 1.88509 2.86975 2.02755 2.60338C2.16155 2.35282 2.35282 2.16155 2.60338 2.02755C2.89396 1.87214 3.1775 1.80975 3.91166 1.80107Z'
        fill='currentColor'
      />
    </svg>
  );
}
