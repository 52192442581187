export const OpenOptionsIcon = () => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.589 4.09434C27.1864 4.09434 27.7197 4.30768 28.189 4.73434C28.6584 5.16101 28.893 5.71568 28.893 6.39834V25.5983C28.893 26.281 28.6584 26.8357 28.189 27.2623C27.7197 27.689 27.1864 27.9023 26.589 27.9023H7.38903C6.70636 27.9023 6.13036 27.689 5.66103 27.2623C5.1917 26.8357 4.95703 26.281 4.95703 25.5983V6.39834C4.95703 5.71568 5.1917 5.16101 5.66103 4.73434C6.13036 4.30768 6.70636 4.09434 7.38903 4.09434H26.589ZM19.421 5.50234H7.38903C7.13303 5.50234 6.9197 5.58768 6.74903 5.75834C6.57836 5.92901 6.49303 6.14234 6.49303 6.39834V25.5983C6.49303 25.8543 6.57836 26.0677 6.74903 26.2383C6.9197 26.409 7.13303 26.4943 7.38903 26.4943H19.421V5.50234ZM26.589 5.50234H20.957V26.4943H26.589C26.7597 26.4943 26.9304 26.409 27.101 26.2383C27.2717 26.0677 27.357 25.8543 27.357 25.5983V6.39834C27.357 6.14234 27.2717 5.92901 27.101 5.75834C26.9304 5.58768 26.7597 5.50234 26.589 5.50234ZM14.173 10.3663C14.3437 10.1957 14.5144 10.1103 14.685 10.1103C14.8557 10.1103 15.0264 10.1743 15.197 10.3023C15.3677 10.4303 15.4744 10.601 15.517 10.8143C15.5597 11.0277 15.5384 11.177 15.453 11.2623L11.741 15.9983L15.453 20.7343C15.5384 20.905 15.5597 21.097 15.517 21.3103C15.4744 21.5237 15.3677 21.6943 15.197 21.8223C15.0264 21.9503 14.8557 22.0143 14.685 22.0143C14.5144 22.0143 14.3437 21.929 14.173 21.7583L10.205 16.5103C10.1197 16.425 10.077 16.2757 10.077 16.0623C10.077 15.849 10.1197 15.6997 10.205 15.6143L14.173 10.3663Z'
        fill='currentColor'
      />
    </svg>
  );
};
