import styled from 'styled-components';

import themelight from '../../../app/providers/ThemeProvider/ui/theme';
import { DURATION, RADIUS_M } from '../../../styles';
import { NEW_INPUT_BORDER_RADIUS, NEW_INPUT_FONT_SIZE, NEW_INPUT_PADDING } from '../../../styles/newAssets';

export interface IInputStyles {
  size?: 'l' | 'm' | 'sm' | 's';
}

export const InputContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;
  appearance: none;
  transition: height ${DURATION} ease-in-out;
  border-radius: ${RADIUS_M};
  text-align: left;
  height: 142px;
  min-height: 142px;
  max-height: 142px;
  &.error {
    height: calc(0.25rem + 20px + 142px);
    min-height: calc(0.25rem + 20px + 142px);
    max-height: calc(0.25rem + 20px + 142px);
  }
`;
export const InputContent = styled.textarea<IInputStyles>`
  padding: ${NEW_INPUT_PADDING};
  background-color: ${themelight.newColor.base01};
  inset: 0;
  margin: 0;
  border: 1px solid ${themelight.newColor.base08};
  border-radius: ${NEW_INPUT_BORDER_RADIUS};
  font-size: ${NEW_INPUT_FONT_SIZE};
  color: ${themelight.newColor.primary};
  line-height: 1.5;
  font-weight: 700;
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  -webkit-text-fill-color: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  text-indent: 0px;
  text-align: inherit;
  box-sizing: border-box;
  height: 142px;
  overflow: hidden;
  text-transform: inherit;
  resize: none;
  transition-property: border-color;
  transition-duration: ${DURATION};
  &.active {
  }
  &.currency {
    padding-left: 48px;
  }
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    transition: all 0.3s ease;
  }
  &:focus {
    border-color: ${themelight.newColor.primary};
    &::placeholder {
      opacity: 0;
    }
  }
  &.error {
    border-color: ${themelight.newColor.error};
  }
  &:disabled {
    background-color: ${themelight.newColor.base05};
    color: ${themelight.newColor.grey06};
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  &[type='number']::-webkit-textfield-decoration-container {
  }
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-datetime-edit-year-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field {
    color: transparent;
  }
`;

export const CurrencyPreffix = styled.div`
  position: absolute;
  left: 0;
  visibility: hidden;
  z-index: 4;
  width: 38px;
  display: flex;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${themelight.newText.textGrey};
  &:before {
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 0;
    background: ${themelight.newText.textGrey};
  }
`;

export const Label = styled.label<IInputStyles>`
  width: 100%;
  max-width: 100%;
  display: block;
  transition-property: background;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  background: transparent;
  color: ${themelight.newText.textGrey};

  position: relative;
  appearance: none;
  border-radius: ${NEW_INPUT_BORDER_RADIUS};
  text-align: left;
  line-height: 1.5;
  height: 142px;
  min-height: 142px;
  max-height: 142px;

  &.error {
    span {
      color: ${themelight.newColor.error};
    }
  }

  &:focus-within {
    span {
      top: 0;
      position: absolute;
      font-size: 10px;
      text-transform: uppercase;
    }
    .currencyRubble {
      visibility: visible;
    }
  }
  span {
    padding: 0 4px;
    position: absolute;
    top: 24px;
    color: ${themelight.newText.textGrey};
    transform: translateY(-50%);
    left: 1rem;
    pointer-events: none;
    font-size: ${NEW_INPUT_FONT_SIZE};
    line-height: inherit;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    transition-property: transform, top, font;
    background-color: ${themelight.newColor.base01};
    z-index: 1;
  }

  &.active,
  &.error {
    span {
      top: 0;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
    }
    .currencyRubble {
      visibility: visible;
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: fit-content;
  margin-left: auto;
  justify-content: flex-end;
  padding-right: 12px;
  border: none !important;
  outline: none !important;
  z-index: 3;
  align-items: center;
  & .age {
    padding: 4px;
    position: absolute;
    top: -32px;
    right: -10px;
    white-space: nowrap;
    &.record {
      top: 0;
      right: -24px;
    }
  }

  svg {
    display: block;
  }
  &.active {
    svg {
    }
  }
  &.active.error,
  &.error {
    svg {
      color: ${themelight.newColor.error};
    }
    .age {
      opacity: 0;
    }
  }
`;

export const ErrorText = styled.p<IInputStyles>`
  position: relative;
  margin-top: 0.25rem;
  width: 90%;
  white-space: pre-wrap;
  display: block;
  color: ${themelight.newColor.error};
  word-wrap: break-word;
  animation: present 1s infinite;
  left: 5px;
  font-size: 12px;
`;
