import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CashRegisterState, ICashRegister } from './cashRegister.interface';

const initialState: CashRegisterState = {
  currCashRegister: null,
  chosenCashRegisterIcon: ''
};

export const cashRegisterSlice = createSlice({
  name: 'cash-register',
  initialState,
  reducers: {
    setActiveCashRegister: (state, action: PayloadAction<ICashRegister>) => {
      state.currCashRegister = action.payload;
    },
    setChosenCashRegisterIcon: (state, action: PayloadAction<string>) => {
      state.chosenCashRegisterIcon = action.payload;
    }
  }
});

export const { reducer, actions } = cashRegisterSlice;
export const { setActiveCashRegister } = actions;
