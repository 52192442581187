import React from 'react';

export function AvitoIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.515 24.0002C21.0959 24.0002 23.9988 21.0974 23.9988 17.5165C23.9988 13.9356 21.0959 11.0327 17.515 11.0327C13.9341 11.0327 11.0312 13.9356 11.0312 17.5165C11.0312 21.0974 13.9341 24.0002 17.515 24.0002Z'
        fill='#97CF26'
      />
      <path
        d='M5.32665 20.5342C6.99347 20.5342 8.3447 19.1829 8.3447 17.5161C8.3447 15.8493 6.99347 14.498 5.32665 14.498C3.65982 14.498 2.30859 15.8493 2.30859 17.5161C2.30859 19.1829 3.65982 20.5342 5.32665 20.5342Z'
        fill='#A169F7'
      />
      <path
        d='M17.517 9.50185C19.8219 9.50185 21.6903 7.6334 21.6903 5.32856C21.6903 3.02372 19.8219 1.15527 17.517 1.15527C15.2122 1.15527 13.3438 3.02372 13.3438 5.32856C13.3438 7.6334 15.2122 9.50185 17.517 9.50185Z'
        fill='#FF6163'
      />
      <path
        d='M5.32852 10.657C8.27139 10.657 10.657 8.27139 10.657 5.32852C10.657 2.38566 8.27139 0 5.32852 0C2.38566 0 0 2.38566 0 5.32852C0 8.27139 2.38566 10.657 5.32852 10.657Z'
        fill='#00AAFF'
      />
    </svg>
  );
}
