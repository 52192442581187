import React from 'react';

export function DeleteIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM6.28033 5.21967C5.98744 4.92678 5.51256 4.92678 5.21967 5.21967C4.92678 5.51256 4.92678 5.98744 5.21967 6.28033L7.93934 9L5.21967 11.7197C4.92678 12.0126 4.92678 12.4874 5.21967 12.7803C5.51256 13.0732 5.98744 13.0732 6.28033 12.7803L9 10.0607L11.7197 12.7803C12.0126 13.0732 12.4874 13.0732 12.7803 12.7803C13.0732 12.4874 13.0732 12.0126 12.7803 11.7197L10.0607 9L12.7803 6.28033C13.0732 5.98744 13.0732 5.51256 12.7803 5.21967C12.4874 4.92678 12.0126 4.92678 11.7197 5.21967L9 7.93934L6.28033 5.21967Z'
        fill='currentColor'
      />
    </svg>
  );
}
