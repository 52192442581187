import { useQuery } from '@tanstack/react-query';
import { PaymentsService } from '../../../../shared/api/model/service/payments.service';
import { PAYMENTS } from '../../../../shared/const/query.const';
import { IPayments } from '../payments.interface';

export const usePaymentsAllQuery = () => {
  return useQuery({
    queryKey: [PAYMENTS],
    queryFn: () => PaymentsService.fetchPayments(),
    staleTime: 1800000000,
    select: (data: IPayments[]) => data || []
  });
};
