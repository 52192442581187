export const ChangeIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_502_904)'>
        <path
          d='M10.0004 0.399611C11.7604 0.399611 13.3737 0.826277 14.8404 1.67961C16.3071 2.53294 17.4671 3.69294 18.3204 5.15961C19.1737 6.62628 19.6004 8.23961 19.6004 9.99961C19.6004 11.7596 19.1737 13.3729 18.3204 14.8396C17.4671 16.3063 16.3071 17.4663 14.8404 18.3196C13.3737 19.1729 11.7604 19.5996 10.0004 19.5996C8.24039 19.5996 6.62706 19.1729 5.16039 18.3196C3.69372 17.4663 2.53372 16.3063 1.68039 14.8396C0.827057 13.3729 0.400391 11.7596 0.400391 9.99961C0.400391 8.23961 0.827057 6.62628 1.68039 5.15961C2.53372 3.69294 3.69372 2.53294 5.16039 1.67961C6.62706 0.826277 8.24039 0.399611 10.0004 0.399611ZM10.0004 1.59961C8.45372 1.59961 7.04039 1.97294 5.76039 2.71961C4.48039 3.46628 3.46706 4.47961 2.72039 5.75961C1.97372 7.03961 1.60039 8.45294 1.60039 9.99961C1.60039 11.5463 1.97372 12.9596 2.72039 14.2396C3.46706 15.5196 4.48039 16.5329 5.76039 17.2796C7.04039 18.0263 8.45372 18.3996 10.0004 18.3996C11.5471 18.3996 12.9604 18.0263 14.2404 17.2796C15.5204 16.5329 16.5337 15.5196 17.2804 14.2396C18.0271 12.9596 18.4004 11.5463 18.4004 9.99961C18.4004 8.45294 18.0271 7.03961 17.2804 5.75961C16.5337 4.47961 15.5204 3.46628 14.2404 2.71961C12.9604 1.97294 11.5471 1.59961 10.0004 1.59961ZM10.0004 3.35961C10.1604 3.35961 10.3071 3.42628 10.4404 3.55961C10.5737 3.69294 10.6404 3.83961 10.6404 3.99961V9.67961L14.5604 12.4796C14.6671 12.5863 14.7337 12.7063 14.7604 12.8396C14.7871 12.9729 14.7737 13.1196 14.7204 13.2796V13.3596C14.6137 13.4663 14.4804 13.5329 14.3204 13.5596C14.1604 13.5863 14.0004 13.5729 13.8404 13.5196L9.68039 10.4796C9.52039 10.3729 9.41372 10.2129 9.36039 9.99961L9.44039 10.0796V3.99961C9.44039 3.83961 9.49372 3.69294 9.60039 3.55961C9.70706 3.42628 9.84039 3.35961 10.0004 3.35961Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_502_904'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='matrix(1 0 0 -1 0 20)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
