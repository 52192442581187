import React from 'react';

import { EIcon, IconNew as IconInstance } from '../../../icons/medium-new-icons/icon';
import { getRouteCalendarEmployee } from '../../../../shared/const';

export function useMobileSubmenuData() {
  const submenuMobile = [
    {
      icon: <IconInstance name={EIcon.tapbarschedule} />,
      iconGray: <IconInstance name={EIcon.tapbarschedulegray} />,
      to: '/timetable',
      title: 'График',
      click: true
    },
    {
      icon: <IconInstance name={EIcon.tapbarrecording} />,
      iconGray: <IconInstance name={EIcon.tapbarrecordinggray} />,
      to: getRouteCalendarEmployee(),
      title: 'Журнал',
      click: true
    },
    {
      icon: <IconInstance name={EIcon.tapbarchat} />,
      iconGray: <IconInstance name={EIcon.tapbarchatgray} />,
      to: `/messenger-list`,
      title: 'Чат',
      click: true,
      mark: true
    },
    {
      icon: <IconInstance name={EIcon.tapbarmenu} />,
      iconGray: <IconInstance name={EIcon.tapbarmenugray} />,
      to: '/more',
      title: 'Еще',
      click: true
    }
  ];

  const submenu = [
    {
      icon: <IconInstance name={EIcon.user} />,
      to: '/profile',
      click: true,
      title: 'Личный кабинет',
      description: 'Редактирование и настройки профиля'
    },
    {
      title: 'Компания',
      icon: <IconInstance name={EIcon.filial} />,
      to: '/management/summary',
      click: true,
      description: 'Редактирование услуг, информации'
    },
    {
      title: 'Сотрудники',
      icon: <IconInstance name={EIcon.useroutline} />,
      to: '/employees',
      click: true,
      description: 'Добавление, общая информация'
    },
    {
      title: 'Клиенты',
      icon: <IconInstance name={EIcon.users} />,
      to: '/clients',
      click: true,
      description: 'Список клиентов, сегментация'
    },
    {
      title: 'Онлайн-запись',
      icon: <IconInstance name={EIcon.marketing} />,
      to: '/marketing',
      click: true,
      description: 'Персональный Telegram бот для записи'
    },
    {
      title: 'Финансы',
      icon: <IconInstance name={EIcon.finans} />,
      to: '/finance/cash-registers',
      click: true,
      description: 'Поступления, кассы, счета'
    }
  ];

  return { submenuMobile, submenu };
}
