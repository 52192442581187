RoundCheckIcon;

export function RoundCheckIcon() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6027 5.20923C10.6503 5.16226 10.6879 5.10624 10.7135 5.04448C10.739 4.98271 10.7519 4.91645 10.7514 4.84962C10.7508 4.7828 10.7369 4.71675 10.7104 4.65539C10.684 4.59403 10.6454 4.5386 10.5972 4.49238C10.5489 4.44615 10.4918 4.41008 10.4294 4.38628C10.3669 4.36249 10.3004 4.35145 10.2336 4.35383C10.1668 4.35621 10.1011 4.37196 10.0405 4.40014C9.97994 4.42832 9.92561 4.46836 9.88075 4.5179L6.17141 8.39056L4.26275 6.40723C4.1698 6.3163 4.04509 6.26515 3.91506 6.26464C3.78504 6.26412 3.65993 6.31428 3.56627 6.40448C3.4726 6.49467 3.41776 6.6178 3.41337 6.74775C3.40898 6.8777 3.45539 7.00426 3.54275 7.10056L5.81141 9.45923C5.85816 9.50774 5.91424 9.54631 5.97626 9.5726C6.03829 9.5989 6.10499 9.61239 6.17236 9.61227C6.23974 9.61215 6.30639 9.59841 6.36832 9.57188C6.43025 9.54535 6.48618 9.50658 6.53275 9.4579L10.6027 5.20923Z'
        fill='#00BE70'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.00033 0.576172C5.32062 0.576172 3.70971 1.24343 2.52198 2.43116C1.33425 3.61889 0.666992 5.2298 0.666992 6.90951C0.666992 8.58921 1.33425 10.2001 2.52198 11.3878C3.70971 12.5756 5.32062 13.2428 7.00033 13.2428C8.68003 13.2428 10.2909 12.5756 11.4787 11.3878C12.6664 10.2001 13.3337 8.58921 13.3337 6.90951C13.3337 5.2298 12.6664 3.61889 11.4787 2.43116C10.2909 1.24343 8.68003 0.576172 7.00033 0.576172ZM1.66699 6.90951C1.66699 5.49502 2.2289 4.13846 3.22909 3.13827C4.22928 2.13808 5.58584 1.57617 7.00033 1.57617C8.41481 1.57617 9.77137 2.13808 10.7716 3.13827C11.7718 4.13846 12.3337 5.49502 12.3337 6.90951C12.3337 8.32399 11.7718 9.68055 10.7716 10.6807C9.77137 11.6809 8.41481 12.2428 7.00033 12.2428C5.58584 12.2428 4.22928 11.6809 3.22909 10.6807C2.2289 9.68055 1.66699 8.32399 1.66699 6.90951Z'
        fill='#00BE70'
      />
    </svg>
  );
}
