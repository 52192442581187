import React from 'react';

export function DayIcon() {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.83301 5.3335V3.3335'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M23.167 5.3335V3.3335'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M12.5 19.3335L14.5 17.3335V22.6668'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.833 21.3335V18.6668C17.833 17.9304 18.43 17.3335 19.1663 17.3335C19.9027 17.3335 20.4997 17.9304 20.4997 18.6668V21.3335C20.4997 22.0699 19.9027 22.6668 19.1663 22.6668C18.43 22.6668 17.833 22.0699 17.833 21.3335Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M29.167 12H22.667H14.8337M3.16699 12H8.33366'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M19.167 29.3335H13.8337C8.80534 29.3335 6.29119 29.3335 4.72909 27.7714C3.16699 26.2093 3.16699 23.6951 3.16699 18.6668V16.0002C3.16699 10.9718 3.16699 8.45769 4.72909 6.89559C6.29119 5.3335 8.80534 5.3335 13.8337 5.3335H19.167C24.1953 5.3335 26.7095 5.3335 28.2716 6.89559C29.8337 8.45769 29.8337 10.9718 29.8337 16.0002V18.6668C29.8337 23.6951 29.8337 26.2093 28.2716 27.7714C27.4007 28.6423 26.2338 29.0277 24.5003 29.1982'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
}
