import { createSlice } from '@reduxjs/toolkit';

import { checkAuth, login, registration } from './auth.actions';
import { IInitialState } from './auth.interface';

import { getStoreLocal } from '../../../utils/local-storage';

const initialState: IInitialState = {
  auth: getStoreLocal('auth'),
  isLoading: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registration.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.auth = payload.user;
      })
      .addCase(registration.rejected, (state) => {
        state.isLoading = false;
        state.auth = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.auth = payload;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
        state.auth = null;
      })
      .addCase(checkAuth.fulfilled, (state, { payload }) => {
        state.auth = payload;
      });
  }
});

export const { reducer } = authSlice;
