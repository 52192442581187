import { FC } from 'react';
import { Link } from 'react-router-dom';

import { LinkProps } from './Link.props';
import { IconWrapper, LinkItemWrapper } from './Link.styled';

import { FlexWithAlign } from '../../../../utils/styleUtils';
import { cn } from '../../../lib';
import NewText from '../../../new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../new-ui/NewText/ui/NewText.props';

const LinkItem: FC<LinkProps> = ({ className, to, $type, $size, icon, onClick, $vertical, children, title, description }: LinkProps) => {
  return (
    <LinkItemWrapper
      onClick={onClick}
      className={cn(className)}
      $size={$size}
      $type={$type}
      $vertical={$vertical}
    >
      {to && <Link to={to} />}
      <FlexWithAlign
        $gap={'0'}
        $column
      >
        <NewText
          $tag={TextType.P}
          $customSize={TextCustomType.T12}
        >
          {title}
        </NewText>
        {description && (
          <NewText
            $tag={TextType.P}
            $customSize={TextCustomType.T18}
            fontWeight={700}
          >
            {description}
          </NewText>
        )}
      </FlexWithAlign>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </LinkItemWrapper>
  );
};

export default LinkItem;
