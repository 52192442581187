import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setIsTaskId } from './tasks.actions';
import { TaskState } from './tasks.interface';

const initialState: TaskState = {
  columns: [],
  isDragging: false,
  isTaskId: null
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setIsTaskId, (state, action: PayloadAction<string | null>) => {
      state.isTaskId = action.payload;
    });
  }
});

export const { reducer } = tasksSlice;
