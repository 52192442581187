export const SearchInputIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0795 1.72748C11.6155 1.72748 13.0075 2.11148 14.2555 2.87948C15.5035 3.64748 16.5115 4.65549 17.2795 5.90348C18.0475 7.15148 18.4315 8.54348 18.4315 10.0795C18.4315 11.1035 18.2555 12.0795 17.9035 13.0075C17.5515 13.9355 17.0555 14.7515 16.4155 15.4555L22.0795 21.1195C22.2075 21.2475 22.2715 21.4075 22.2715 21.5995C22.2715 21.7915 22.2075 21.9515 22.0795 22.0795C21.9515 22.2075 21.8075 22.2715 21.6475 22.2715C21.4875 22.2715 21.3435 22.2075 21.2155 22.0795L15.4555 16.4155C14.7515 17.0555 13.9355 17.5515 13.0075 17.9035C12.0795 18.2555 11.1035 18.4315 10.0795 18.4315C8.54354 18.4315 7.15154 18.0475 5.90354 17.2795C4.65554 16.5115 3.64754 15.5035 2.87954 14.2555C2.11154 13.0075 1.72754 11.6155 1.72754 10.0795C1.72754 8.54348 2.11154 7.15148 2.87954 5.90348C3.64754 4.65549 4.65554 3.64748 5.90354 2.87948C7.15154 2.11148 8.54354 1.72748 10.0795 1.72748ZM10.0795 3.07148C8.79954 3.07148 7.61554 3.37548 6.52754 3.98348C5.43954 4.59148 4.59154 5.43948 3.98354 6.52748C3.37554 7.61548 3.07154 8.79948 3.07154 10.0795C3.07154 11.3595 3.37554 12.5435 3.98354 13.6315C4.59154 14.7195 5.43954 15.5675 6.52754 16.1755C7.61554 16.7835 8.79954 17.0875 10.0795 17.0875C11.3595 17.0875 12.5435 16.7835 13.6315 16.1755C14.7195 15.5675 15.5675 14.7195 16.1755 13.6315C16.7835 12.5435 17.0875 11.3595 17.0875 10.0795C17.0875 8.79948 16.7835 7.61548 16.1755 6.52748C15.5675 5.43948 14.7195 4.59148 13.6315 3.98348C12.5435 3.37548 11.3595 3.07148 10.0795 3.07148Z'
        fill='currentColor'
      />
    </svg>
  );
};
