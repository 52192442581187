export function ArrowDownOutline() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='7'
      height='9'
      viewBox='0 0 7 9'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.5 0C3.74853 0 3.95 0.197874 3.95 0.441964V6.78514L5.74159 5.12905C5.92256 4.96176 6.20736 4.97024 6.37769 5.14799C6.54802 5.32573 6.53939 5.60544 6.35841 5.77273L3.80841 8.12987C3.63514 8.29004 3.36486 8.29004 3.19159 8.12987L0.641587 5.77273C0.460609 5.60544 0.451979 5.32573 0.622311 5.14799C0.792644 4.97024 1.07744 4.96176 1.25842 5.12905L3.05 6.78514V0.441964C3.05 0.197874 3.25147 0 3.5 0Z'
        fill='currentColor'
      />
    </svg>
  );
}
