import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchOnlineReecordsAsync, fetchScheduleOnlineRecordsAsync } from './personalsite.actions';
import { PersonalSiteNameState } from './personalsite.interface';

import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';

const initialState: PersonalSiteNameState = {
  uniq: '',
  weeks: 8,
  state: PENDING
};

export const personalSiteSlice = createSlice({
  name: 'personalsite',
  initialState,
  reducers: {
    setSiteName: (state, action: PayloadAction<any>) => {
      state.uniq = action.payload;
    },

    setOnlineRecordingPeriod: (state, action: PayloadAction<any>) => {
      state.weeks = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnlineReecordsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchOnlineReecordsAsync.fulfilled, (state, action) => {
        console.log(action.payload)
        state.uniq = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchScheduleOnlineRecordsAsync.fulfilled, (state, action) => {
        console.log(action.payload)
        state.weeks = action.payload.weeks;
        state.state = FULFILLED;
      })
      .addCase(fetchOnlineReecordsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer, actions } = personalSiteSlice;

export const { setSiteName, setOnlineRecordingPeriod } = actions;