export const ChosenTimeCell = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.0004 27.0404L7.68037 19.0404C7.46704 18.8271 7.22704 18.7471 6.96037 18.8004C6.69371 18.8538 6.45371 18.9604 6.24038 19.1204C6.02704 19.2804 5.94704 19.4938 6.00038 19.7604C6.05371 20.0271 6.13371 20.2671 6.24038 20.4804L15.3604 29.2804C15.467 29.3871 15.6804 29.4404 16.0004 29.4404C16.3204 29.4404 16.5337 29.3871 16.6404 29.2804L34.7204 11.6804C34.9337 11.4671 35.0404 11.2271 35.0404 10.9604C35.0404 10.6938 34.9337 10.4804 34.7204 10.3204C34.507 10.1604 34.267 10.0538 34.0004 10.0004C33.7337 9.9471 33.4937 10.0271 33.2804 10.2404L16.0004 27.0404Z'
        fill='currentColor'
      />
    </svg>
  );
};
