import { apiDelete, apiGet, apiPost, apiPut } from '../../interceptors';

export const SaleProductsCategoryService = {
  async fetchSaleProductsCategory() {
    const response = await apiGet('/salecategory');
    return response.data.category;
  },
  async fetchSaleProductsCategoryById(id: string) {
    const response = await apiGet(`/salecategory/${id}`);

    return response.data;
  },
  async createSaleProductsCategory(values: any) {
    const response = await apiPut('/salecategory', values);

    return response.data;
  },
  async updateSaleProductsCategory(values: any) {
    const { id, ...data } = values;
    const response = await apiPost(`/salecategory/${id}`, values);
    return response.data;
  },
  async deleteSaleProductsCategory(id: any) {
    const response = await apiDelete(`/salecategory/${id}`);

    return response.data;
  }
};
