import styled, { keyframes } from 'styled-components';

import { MODAL_TEXT_GAP } from '../styles';

export const fade = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

export const growInput = keyframes`
  0%{
    transform: scale(.5);
    opacity: 0;
  }
  100%{
    transform: scale(1);
    opacity: .6;
  }
`;
export const scaleIn = keyframes`
  0%{
    transform: scale(.5);
    opacity: 0;
  }
  100%{
    transform: scale(1);
    opacity: 1;
  }
`;

export const scaleOut = keyframes`
  0%{
    transform: scale(1);
    opacity: 1;
  }
  100%{
    transform: scale(.5);
    opacity: 0;
  }
`;

export const collapsIn = keyframes`
  0%{
    transform: scaleY(0);
    opacity: 0;
  }
  100%{
    transform: scaleY(1);
    opacity: 1;
  }
`;

export const collapsOut = keyframes`
  0%{
    transform: scaleY(1);
    opacity: 1;
  }
  100%{
    transform: scaleY(0);
    opacity: 0;
  }
`;

export const openDropdownMenu = keyframes`
  0%{
    transform: translateY(-20px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

export const closeDropdownMenu = keyframes`
  0%{
    transform: translateY(0);
    opacity: 1;
  }
  100%{
    transform: translateY(-20px);
    opacity: 0;
  }
`;

export const openDropdownMenuTop = keyframes`
  0%{
    transform: translateY(20px);
    opacity: 0;
    *{
      opacity: 0;
    }
  }
  100%{
    transform: translateY(0);
    opacity: 1;
    *{
      opacity: 1;
    }
  }
`;

export const closeDropdownMenuTop = keyframes`
  0%{
    transform: translateY(0);
    opacity: 1;
    *{
      opacity: 1;
    }
  }
  100%{
    transform: translateY(20px);
    opacity: 0;
    *{
      opacity: 0;
    }
  }
`;

export const checkAnim = keyframes`
  0% {
    height: 0;
  }

  100% {
    height: 10px;
  }
`;

export const Padding = styled.div<{ $size?: string }>`
  height: ${(props) => props.$size};
`;

export const EndLine = styled.div`
  width: 100%;
  height: 1px;
  background: #eff2f6;
  &.dark {
    background: ${(props) => props.theme.color.secondaryDark};
  }
`;

export const MobileButton = styled.button`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    color: ${(props) => props.theme.text.text01};
  }
  &.no-margin {
    margin: 0;
  }
`;

export const MobileButtonWrapper = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  gap: 24px;
  svg {
    color: ${(props) => props.theme.text.text01};
  }
`;
export const MobileHeaderButton = styled.button`
  display: flex;
  align-items: flex-end;
  background: ${(props) => props.theme.color.clear};
  border-radius: 10px;
  padding: 6px 10px;
  gap: 2px;
  svg {
    width: 16px;
    height: 16px;
  }
  p {
    max-width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
export const Empty = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.mobile {
    justify-content: flex-start;
    margin-top: 50px;
    h6 {
      text-align: center;
    }
  }
  button,
  h4 {
    opacity: 0.5;
    color: ${(props) => props.theme.color.primary};
  }
  &.small {
    gap: 12px;
    width: 100%;
    h4 {
      font-size: 14px;
    }
    button {
      font-size: 14px;
    }
  }
`;

export const TextShSm = styled.h5`
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  &.main {
    color: ${(props) => props.theme.color.primary};
  }
  &.white {
    color: #fff;
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
  &.black {
    font-weight: 800;
  }
`;

export const TextXs = styled.h3`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  &.main {
    color: ${(props) => props.theme.color.primary};
  }
  &.white {
    color: #fff;
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.danger {
    color: ${(props) => props.theme.color.danger};
  }
  &.success {
    color: ${(props) => props.theme.color.success};
  }
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
  a {
    span {
      text-decoration: underline;
    }
  }
`;

export const Text2Xs = styled.h5`
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  &.main {
    color: ${(props) => props.theme.color.primary};
  }
  &.white {
    color: #fff;
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.dark {
    color: ${(props) => props.theme.color.mainDarkFull};
  }
  &.gray666 {
    color: #666;
  }
  &.ultraDark {
    color: ${(props) => props.theme.color.dark};
  }
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
  > a {
    color: ${(props) => props.theme.color.primary};
  }
`;

export const TextSm = styled.h5`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  &.dark {
    color: ${(props) => props.theme.color.mainDarkFull};
  }
  &.main {
    color: ${(props) => props.theme.color.primary};
  }
  &.white {
    color: #fff;
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.gray6 {
    color: #6c6f71;
  }
  &.danger {
    color: ${(props) => props.theme.color.danger};
  }
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
  &.black {
    font-weight: 800;
  }
`;

export const PageTextTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  &.dark {
    color: ${(props) => props.theme.color.mainDarkFull};
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.main {
    color: ${(props) => props.theme.color.mainLight};
  }
  &.medium {
    font-weight: 500;
    &.gray {
      color: ${(props) => props.theme.color.secondaryDark};
    }
  }
  &.regular {
    font-weight: 400;
    &.gray {
      color: ${(props) => props.theme.color.secondaryDark};
    }
  }
  &.bold {
    font-weight: 700;
  }
  @media (max-width: 1024px) {
    font-size: 13px;
    line-height: 15px;
  }
`;

export const PageTextLg = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
  &.main {
    color: ${(props) => props.theme.color.mainLight};
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.ultraDark {
    color: #000;
  }
  @media (max-width: 1024px) {
    font-size: 13px;
    line-height: 15px;
  }
`;
export const Text2xl = styled.h2`
  font-size: 22px;
  line-height: 24px;
  &.dark {
    color: ${(props) => props.theme.color.mainDarkFull};
  }
  &.gray666 {
    color: #666;
  }
  &.main {
    color: ${(props) => props.theme.color.mainLight};
  }
  &.white {
    color: #fff;
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
`;
export const PageSubtitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  &.dark {
    color: ${(props) => props.theme.color.mainDarkFull};
  }
  &.black {
    color: ${(props) => props.theme.color.dark};
  }
  &.main {
    color: ${(props) => props.theme.color.mainLight};
  }
  &.white {
    color: #fff;
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
  }
`;

export const Text3xl = styled.h2`
  font-size: 30px;
  line-height: 36px;
  &.dark {
    color: ${(props) => props.theme.color.mainDarkFull};
  }
  &.main {
    color: ${(props) => props.theme.color.mainLight};
  }
  &.white {
    color: #fff;
  }
  &.gray {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.medium {
    font-weight: 400;
  }
  &.regular {
    font-weight: 500;
  }
  &.semi-bold {
    font-weight: 600;
  }
  &.bold {
    font-weight: 700;
  }
`;

export const PageTitle = styled.h1`
  font-size: 32px;
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 34px;
  color: ${(props) => props.theme.color.mainDark};
  &.bold {
    font-weight: 700;
  }
  &.white {
    color: #fff;
  }
  &.ultraDark {
    color: #000;
  }
  @media (max-width: 1280px) {
    font-size: 24px;
  }
`;

export const Textarea = styled.textarea`
  color: ${(props) => props.theme.text.text01};
  background: ${(props) => props.theme.color.base03};
  display: flex;
  padding: 8px 16px;
  border-radius: 8px;
  resize: none;
  font-size: 13px;
  width: 100%;
  height: 108px;
  transition: 0.3s ease;
  background: #ecf1f7;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  textarea::placeholder {
    font-size: 12px;
  }
  textarea:focus {
    outline: none;
    border-color: #8f969e;
    background: #fff;
  }
  p {
    font-weight: 500;
    color: ${(props) => props.theme.text.text01};
  }
`;

export const DividerGrey = styled.div<{ $margin?: string }>`
  width: 100%;
  height: 2px;
  background: ${(props) => props.theme.color.base03};
  margin-top: ${(props) => props.$margin};
  margin-bottom: ${(props) => props.$margin};
  border-radius: 2px;
`;

export const DividerVertical = styled.div<{ $margin?: string }>`
  background: ${(props) => props.theme.color.base03};
  width: 2px;
  margin-left: ${(props) => props.$margin};
  margin-right: ${(props) => props.$margin};
`;

export const MainWrapper = styled.div`
  width: calc(100% - 80px);
  margin: 0 auto;
`;

export const FlexWithAlign = styled.div<{
  $align?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
  $justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
  $gap?: string;
  $column?: boolean;
  $position?: string;
}>`
  display: flex;
  background-color: transparent;
  position: ${(props) => props.$position || 'relative'};
  gap: ${(props) => props.$gap || '24px'};
  flex-direction: ${(props) => (props.$column ? 'column' : 'row')};
  align-items: ${(props) => props.$align};
  justify-content: ${(props) => props.$justify};
  &.xl666 {
    max-width: 666px;
    width: 100%;
    margin: 0 auto;
  }
  &.background {
    height: 70px;
    align-items: center;
    margin: 0 20px;
    > div {
    }
    min-width: 216px;
    > div {
      width: 100%;
    }
    &.no-line {
      &:before {
        content: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      background: ${(props) => props.theme.color.base03};
      left: 0;
      width: 100%;
      height: 1px;
    }
    border-radius: 2px;
  }
  &.full {
    width: 100%;
  }
  &.flex-1 {
    flex: 1;
  }
  &.h-full {
    height: 100%;
  }
  // &.colored {
  //   background: ${(props) => props.theme.color.base02};
  //   height: 50px;
  //   border-radius: 8px;
  //   top: 10px;
  //   padding-right: 23px;
  //   margin-right: 20px;
  // }
  .summary {
    @media (max-width: 1440px) {
      width: 800px;
    }
    @media (max-width: 1024px) {
      width: 500px;
    }
    @media (max-width: 767px) {
      width: auto;
    }
  }
  .marketing {
    height: fit-content;
    .text {
      .arrow_text {
        svg {
          width: 48px;
          height: 48px;
        }
        align-items: center;
        @media (max-width: 1440px) {
          svg {
            height: 32px;
            width: 32px;
          }
        }
        @media (max-width: 767px) {
          svg {
            height: 28px;
            width: 28px;
          }
        }
      }
    }
    @media (max-width: 1440px) {
      img {
        height: 450px;
      }
    }
    @media (max-width: 767px) {
      img {
        height: 100%;
      }
    }
  }
  &.column-reverse {
    flex-direction: column-reverse;
  }
  &.messenger-modal {
    gap: 0;
    padding: 0 0 0 12px;
  }
  &.messenger-modal-button {
    gap: 17px;
    padding: 36px 0 0 0;
  }
`;

export const DividerArrow = styled.span`
  display: flex;
  margin: 0 8px;
  opacity: 0.5;
`;

export const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  &.side {
    max-width: 330px;
  }
  margin: 0 auto;
  gap: 24px;
  .hiddenRecordint {
    animation: ${scaleIn} 0.3s ease;
    > div {
      position: relative;
      background: #ecf1f7;
      border-radius: 8px;
      height: 50px;
      h5 {
        position: absolute;
        z-index: 2;
        left: 15px;
        top: 8px;
        pointer-events: none;
      }
      input {
        position: absolute;
        padding-top: 22px;
        padding-left: 15px;
      }
    }
  }
`;

export const Text = styled.h4`
  font-size: 20px;
  font-weight: 600;
`;

export const IconWrapper = styled.div`
  min-width: 40px;
  width: 40px;
  height: 40px;
  background: ${(props) => props.theme.color.base02};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  svg {
    color: ${(props) => props.theme.color.secondaryDark};
  }
  &.white {
    background: ${(props) => props.theme.color.base01};
  }
  &.main {
    background: ${(props) => props.theme.color.mainLight};
  }
  &.main15 {
    background: ${(props) => props.theme.color.mainLight}15;
    svg {
      color: ${(props) => props.theme.color.mainLight};
    }
  }
  &.medium-gray {
    background: ${(props) => props.theme.color.secondaryLightMedium};
  }
  &.dark-gray {
    background: #c7cacf;
  }
  &.h40 {
    min-width: 40px;
    width: 40px;
    height: 40px;
    svg {
      height: 25px;
    }
  }
  &.h33 {
    min-width: 33px;
    width: 33px;
    height: 33px;
    svg {
      height: 12px;
    }
    &.main-svg {
      svg {
        color: ${(props) => props.theme.color.mainLight};
      }
    }
    &.white-svg {
      svg {
        color: #fff;
      }
    }
  }
  &.h30 {
    min-width: 30px;
    width: 30px;
    height: 30px;
    svg {
      height: 12px;
    }
    &.main-svg {
      svg {
        color: ${(props) => props.theme.color.mainLight};
      }
    }
    &.white-svg {
      svg {
        color: #fff;
      }
    }
  }
  &.h25 {
    min-width: 25px;
    width: 25px;
    height: 25px;
    svg {
      height: 15px;
    }
  }
  &.h22 {
    min-width: 22px;
    width: 22px;
    height: 22px;
    svg {
      height: 12px;
      color: #272e35;
    }
    &.gray {
      svg {
        color: ${(props) => props.theme.color.secondaryDark};
      }
    }
  }
  &.h20 {
    min-width: 15px;
    width: 15px;
    height: 15px;
    svg {
      height: 12px;
      color: #272e35;
    }
    &.main-svg {
      svg {
        color: ${(props) => props.theme.color.mainLight};
      }
    }
  }
  &.hover {
    cursor: pointer;
    svg {
      transition: 0.3s ease;
    }
    &:hover {
      svg {
        transform: scale(1.2);
      }
    }
  }
  &.hover-translate {
    cursor: pointer;
    svg {
      transition: 0.3s ease;
    }
    &:hover {
      svg {
        transform: translateY(3px);
      }
    }
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 50px;
  background: ${(props) => props.theme.color.base02};
  border-radius: 8px;
`;

export const ModalMargin = styled.div`
  margin: 0 auto;
  display: flex;
  width: 666px;
  flex-direction: column;
  gap: 18px;
  &.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  &.overflow {
    overflow-y: auto;
  }
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.color.base03}15;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.color.secondaryDark}15;
    border-radius: 20px;
  }
  &.gap {
    gap: 24px;
  }
  &.gap92 {
    gap: 92px;
  }
  &.gap0 {
    gap: 0;
  }
  &.modal {
    width: 482px;
  }
  &.h451 {
    height: 418px;
  }
  &.h550 {
    height: 550px;
  }
`;

export const BubblesInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100%;
  justify-content: flex-end;
  //max-width: min(100% - 0.8125rem * 2, 728px - 1.625rem);
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
`;

export const FilterWrapper = styled(FlexWithAlign)`
  flex: 5 0 auto;
  max-width: 50%;
  flex-wrap: wrap;
  &.mobile {
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: auto;
    padding-bottom: 5px;
    margin-top: ${MODAL_TEXT_GAP};
    > div {
      min-width: fit-content;
      overflow: hidden;
      max-height: 44px;
      white-space: nowrap;
      text-overflow: ellipsis;
      > p {
        max-height: 44px;
      }
    }
  }
`;
export const SearchWrapper = styled(FlexWithAlign)`
  flex: 1 0 auto;
  justify-content: flex-end;
  flex-wrap: wrap;
  &.mobile {
    width: 100%;
    gap: ${MODAL_TEXT_GAP};
    flex-direction: column-reverse;
    > div {
      width: 100%;
      max-width: 100%;
    }
  }
`;
export const MobileWrapper = styled(FlexWithAlign)`
  flex-direction: column;
  gap: 0;
  width: calc(100% - 2rem);
  margin: 0 auto;
  &.flex {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }
  &.mobile-button-fixed {
    position: fixed;
    bottom: 90px;
    z-index: 8;
    height: 60px;
    left: 50%;
    transform: translateX(-50%);
    background: ${(props) => props.theme.color.base01};
    width: 100%;
    padding: 1rem 1rem 0 1rem;
  }
`;
export const EmptyData = styled(FlexWithAlign)`
  text-align: center;
  padding-block: 30px;
  svg {
    width: 84px;
    height: 84px;
    color: ${(props) => props.theme.text.text02};
  }
  gap: 24px;
  @media (max-width: 767px) {
    gap: 8px;
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;
