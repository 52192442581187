import React from 'react';

import UserListDialog from '../../../../../pages/MessengerPage/ui/dialog/UserListDialog';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';

interface IProps {
  closeModal: () => void;
  edit?: boolean;
  modalPayload?: any;
}

const UpdateChatModal: React.FC<IProps> = (props) => {
  const { closeModal } = props;
  const { user } = useTypedSelector((state) => state.user);

  return (
    <UserListDialog
      onClose={closeModal}
      userData={user}
    />
  );
};

export default UpdateChatModal;
