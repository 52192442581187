export const NewChevronUp = () => {
  return (
    <svg
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6001 11.1083C13.6001 11.2443 13.5661 11.3463 13.4981 11.4143C13.4301 11.4823 13.3281 11.5276 13.1921 11.5503C13.0561 11.5729 12.9428 11.5389 12.8521 11.4483L8.5001 7.30027L4.1481 11.4483C4.05743 11.5389 3.9441 11.5729 3.8081 11.5503C3.6721 11.5276 3.5701 11.4823 3.5021 11.4143C3.4341 11.3463 3.4001 11.2443 3.4001 11.1083C3.4001 10.9723 3.44543 10.8816 3.5361 10.8363L8.2281 6.41627C8.31876 6.32561 8.40943 6.28027 8.5001 6.28027C8.59076 6.28027 8.68143 6.32561 8.7721 6.41627L13.4641 10.8363C13.5548 10.9269 13.6001 11.0176 13.6001 11.1083Z'
        fill='currentColor'
      />
    </svg>
  );
};
