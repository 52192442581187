export const PaymentIcon32 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.38432 2.88438L3.38439 2.88432C4.54117 1.72733 6.06888 1.12078 8.3662 0.811891C10.6779 0.501062 13.6929 0.5 17.78 0.5H19.22C23.3071 0.5 26.3221 0.501062 28.6338 0.811891C30.9311 1.12078 32.4588 1.72733 33.6156 2.88432L33.6157 2.88438C34.7727 4.04117 35.3792 5.56888 35.6881 7.8662C35.9989 10.1779 36 13.1929 36 17.28V18.72C36 22.8071 35.9989 25.8221 35.6881 28.1338C35.3792 30.4311 34.7727 31.9588 33.6157 33.1156L33.6156 33.1157C32.4588 34.2727 30.9311 34.8792 28.6338 35.1881C26.3221 35.4989 23.3071 35.5 19.22 35.5H17.78C13.6929 35.5 10.6779 35.4989 8.3662 35.1881C6.06888 34.8792 4.54117 34.2727 3.38439 33.1157L3.38432 33.1156C2.22733 31.9588 1.62078 30.4311 1.31189 28.1338C1.00106 25.8221 1 22.8071 1 18.72V17.28C1 13.1929 1.00106 10.1779 1.31189 7.8662C1.62078 5.56888 2.22733 4.04117 3.38432 2.88438ZM6.57504 10.4504H6.0645L6.07515 10.9608C6.17436 15.7158 7.41361 19.5881 9.73259 22.2793C11.969 24.8745 15.1637 26.3178 19.1552 26.4285V26.4358H19.6552H20.12H20.62V25.9358V21.1572C23.1312 21.6302 25.0459 23.5709 25.854 26.0886L25.9654 26.4358H26.33H30.5897H31.2456L31.0719 25.8034C30.0005 21.9023 27.3961 19.5338 25.4125 18.3766C27.3193 17.016 29.5847 14.4284 30.1822 11.0376L30.2855 10.4508H29.6897H25.8197H25.4415L25.3386 10.8148C24.9417 12.2183 24.1515 13.565 23.1812 14.5868C22.378 15.4326 21.4829 16.0243 20.62 16.2691V10.9508V10.4508H20.12H16.25H15.75V10.9508V20.6359C13.7297 19.7786 11.3045 17.0321 11.1848 10.9405L11.1752 10.4504H10.6849H6.57504Z'
        stroke='currentColor'
      />
    </svg>
  );
};
