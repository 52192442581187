import React, { createContext, FC, ReactNode, useContext, useMemo, useState, SetStateAction, Dispatch } from 'react';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import themelight from './theme';

import { LOCAL_STORAGE_THEME_KEY } from '../../../../shared/const/localstorage';
import { Theme } from '../../../../shared/const/theme';
import themeDark from '../ui/themeDark';

export interface ThemeContextProps {
  currentTheme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

const fallbackTheme = (JSON.parse(localStorage.getItem(LOCAL_STORAGE_THEME_KEY) || 'null') as Theme) || Theme.LIGHT;

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(fallbackTheme);

  const selectedStyle = currentTheme === Theme.DARK ? themeDark : themelight;
  const setTheme: Dispatch<SetStateAction<Theme>> = (newTheme) => {
    setCurrentTheme(newTheme);
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, JSON.stringify(newTheme));
  };
  const contextValue: ThemeContextProps = useMemo(() => ({ currentTheme, setTheme }), [currentTheme]);

  return (
    <StyledThemeProvider theme={selectedStyle}>
      <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
    </StyledThemeProvider>
  );
};

export function useThemeContext() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return context;
}
