export const PaymentIcon9 = () => {
  return (
    <svg
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 0.5H40.0001V20.1171C40.0001 25.0956 37.3442 29.6961 33.0329 32.1856L20.5 39.4226L7.96722 32.1856L7.71719 32.6186L7.96721 32.1856C3.65585 29.6961 1 25.0956 1 20.1171V0.5Z'
        stroke='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4785 10.5898V16.8467C12.3341 15.8805 13.8897 15.2266 15.6684 15.2266H17.6013V22.5001C17.6013 24.4352 17.0755 26.129 16.2954 27.0606H24.7031C23.9247 26.128 23.4001 24.4364 23.4001 22.5038V15.2266H25.3331C27.1117 15.2266 28.6673 15.8805 29.523 16.8467V10.5898H11.4785Z'
        fill='currentColor'
      />
    </svg>
  );
};
