export function CloudBlueWhite() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='15.5'
        fill='white'
      />
      <rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='15.5'
        stroke='#EBEBEB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 11.5C13.8845 11.5 12.1082 12.9606 11.628 14.9293C11.5481 15.2569 11.2593 15.4909 10.9222 15.5012C9.5777 15.5421 8.5 16.6453 8.5 18C8.5 19.3807 9.61929 20.5 11 20.5H22C22.8284 20.5 23.5 19.8284 23.5 19C23.5 18.1716 22.8284 17.5 22 17.5C21.8037 17.5 21.6179 17.5373 21.4481 17.6046C21.1957 17.7045 20.909 17.6603 20.6985 17.489C20.488 17.3177 20.3864 17.046 20.433 16.7786C20.477 16.5262 20.5 16.2661 20.5 16C20.5 13.5147 18.4853 11.5 16 11.5ZM10.3217 14.0573C11.129 11.6973 13.3655 10 16 10C19.3137 10 22 12.6863 22 16C23.6569 16 25 17.3431 25 19C25 20.6569 23.6569 22 22 22H11C8.79086 22 7 20.2091 7 18C7 16.0221 8.43545 14.3796 10.3217 14.0573Z'
        fill='#2997FF'
      />
    </svg>
  );
}
