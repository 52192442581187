export const USERS_QUERY = 'user';
export const USER_QUERY = 'user_only';
export const USERS_SCHEDULE_QUERY = 'user_all_schedule_query';
export const USER_SCHEDULE_QUERY = 'user_schedule_query';
export const USER_PROFILE_QUERY = 'user_profile';

export const FILIAL_QUERY = 'filial_query';

export const TARIF_QUERY = 'tarif';
export const MYTARIF_QUERY = 'my_tarif';
export const PAYMENT_HISTORY_QUERY = 'payment_history';
export const MYBALANCE_QUERY = 'mybalance_query';

export const SALES_QUERY = 'payment-query';

export const SUBPRODUCTS_QUERY = 'subproducts';
export const SUBPRODUCTS_ALL_QUERY = 'subproducts_all';
export const SUBPRODUCT_QUERY = 'subproduct';

export const PRODUCTS_QUERY = 'products';

export const SALE_PRODUCTS_QUERY = 'sale_products';
export const SALE_PRODUCTS_CATEGORY_QUERY = 'sale_products_category';

export const CLIENTS_QUERY = 'clients';
export const CLIENT_QUERY = 'client';

export const CABINETS_QUERY = 'cabinets';

export const NOTES_QUERY = 'notes';

export const CLASSIFICATORS_QUERY = 'classificators';

export const TELEGRAM_BOT_QUERY = 'telegram_bot';
export const TELEGRAM_FOLLOWERS_QUERY = 'telegram_followers';

export const CASH_REGISTER = 'cash_register';
export const CASH_REGISTER_ALL = 'cash_register_all';

export const PAYMENTS = 'payments';

export const TRANSACTIONS = 'transactions';

export const PERSONAL_SITE_QUERY = 'personalsite';
export const RECODRING_PERIOD_QUERY = 'recordingperiod';