import React from 'react';

export function Close() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.20757 5.29301C7.01897 5.11085 6.76636 5.01006 6.50417 5.01234C6.24197 5.01461 5.99116 5.11978 5.80575 5.30519C5.62034 5.4906 5.51517 5.74141 5.5129 6.00361C5.51062 6.26581 5.61141 6.51841 5.79357 6.70701L11.0866 12L5.79357 17.293C5.69806 17.3853 5.62188 17.4956 5.56947 17.6176C5.51706 17.7396 5.48947 17.8708 5.48832 18.0036C5.48717 18.1364 5.51247 18.2681 5.56275 18.391C5.61303 18.5139 5.68728 18.6255 5.78117 18.7194C5.87507 18.8133 5.98672 18.8876 6.10962 18.9378C6.23251 18.9881 6.36419 19.0134 6.49697 19.0123C6.62975 19.0111 6.76097 18.9835 6.88297 18.9311C7.00498 18.8787 7.11532 18.8025 7.20757 18.707L12.5006 13.414L17.7936 18.707C17.9822 18.8892 18.2348 18.99 18.497 18.9877C18.7592 18.9854 19.01 18.8802 19.1954 18.6948C19.3808 18.5094 19.486 18.2586 19.4882 17.9964C19.4905 17.7342 19.3897 17.4816 19.2076 17.293L13.9146 12L19.2076 6.70701C19.3897 6.51841 19.4905 6.26581 19.4882 6.00361C19.486 5.74141 19.3808 5.4906 19.1954 5.30519C19.01 5.11978 18.7592 5.01461 18.497 5.01234C18.2348 5.01006 17.9822 5.11085 17.7936 5.29301L12.5006 10.586L7.20757 5.29301Z'
        fill='currentColor'
      />
    </svg>
  );
}
