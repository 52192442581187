export function DownloadCloudOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.73804 7.18903C7.73804 5.29702 9.23205 3.79841 11.0321 3.79841C12.5743 3.79841 13.89 4.89574 14.2373 6.40266C14.3337 6.82067 14.7117 7.11258 15.1404 7.10013C15.1689 7.09931 15.1975 7.09889 15.2262 7.09889C16.8481 7.09889 18.1976 8.44973 18.1976 10.1595C18.1976 11.7523 17.0234 13.037 15.5524 13.2019C15.0585 13.2573 14.7029 13.7026 14.7583 14.1965C14.8137 14.6905 15.259 15.046 15.753 14.9907C18.1569 14.7212 19.9976 12.6464 19.9976 10.1595C19.9976 7.68622 18.1743 5.61729 15.7902 5.33235C15.06 3.39207 13.2149 1.99841 11.0321 1.99841C8.19954 1.99841 5.93804 4.34175 5.93804 7.18903C5.93804 7.21238 5.9382 7.2357 5.9385 7.25899C4.77126 8.00423 4.00232 9.32446 4.00232 10.8196C4.00232 13.12 5.83049 15.02 8.12853 15.02H8.37049C8.86755 15.02 9.27049 14.6171 9.27049 14.12C9.27049 13.623 8.86755 13.22 8.37049 13.22H8.12853C6.863 13.22 5.80232 12.1647 5.80232 10.8196C5.80232 9.81357 6.39986 8.96224 7.23323 8.60311C7.60362 8.44349 7.8226 8.05694 7.76909 7.65718C7.74866 7.50457 7.73804 7.34832 7.73804 7.18903ZM12.9 10.9027C12.9 10.4057 12.4971 10.0027 12 10.0027C11.503 10.0027 11.1 10.4057 11.1 10.9027V18.9272L9.53591 17.363C9.18444 17.0116 8.61459 17.0116 8.26312 17.363C7.91164 17.7145 7.91164 18.2844 8.26312 18.6358L11.3636 21.7364C11.7151 22.0878 12.285 22.0878 12.6364 21.7364L15.7408 18.632C16.0923 18.2805 16.0923 17.7107 15.7408 17.3592C15.3893 17.0077 14.8195 17.0077 14.468 17.3592L12.9 18.9272V10.9027Z'
        fill='currentColor'
      />
    </svg>
  );
}
