import React from 'react';

export function RoundClose() {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.14923 6.44986C6.06171 6.36234 5.943 6.31317 5.81923 6.31317C5.69546 6.31317 5.57675 6.36234 5.48923 6.44986C5.40171 6.53738 5.35254 6.65608 5.35254 6.77986C5.35254 6.90363 5.40171 7.02234 5.48923 7.10986L7.3399 8.95986L5.4899 10.8099C5.44656 10.8532 5.41218 10.9046 5.38873 10.9613C5.36528 11.0179 5.35321 11.0786 5.35321 11.1399C5.35321 11.2011 5.36528 11.2618 5.38873 11.3185C5.41218 11.3751 5.44656 11.4265 5.4899 11.4699C5.57742 11.5574 5.69612 11.6065 5.8199 11.6065C5.88118 11.6065 5.94187 11.5945 5.99849 11.571C6.05511 11.5476 6.10656 11.5132 6.1499 11.4699L7.9999 9.61986L9.8499 11.4699C9.93742 11.5574 10.0561 11.6065 10.1799 11.6065C10.3037 11.6065 10.4224 11.5574 10.5099 11.4699C10.5974 11.3823 10.6466 11.2636 10.6466 11.1399C10.6466 11.0161 10.5974 10.8974 10.5099 10.8099L8.6599 8.95986L10.5099 7.10919C10.5532 7.06585 10.5876 7.01441 10.6111 6.95779C10.6345 6.90116 10.6466 6.84048 10.6466 6.77919C10.6466 6.7179 10.6345 6.65722 10.6111 6.6006C10.5876 6.54397 10.5532 6.49253 10.5099 6.44919C10.4666 6.40585 10.4151 6.37148 10.3585 6.34803C10.3019 6.32457 10.2412 6.3125 10.1799 6.3125C10.1186 6.3125 10.0579 6.32457 10.0013 6.34803C9.94468 6.37148 9.89323 6.40585 9.8499 6.44919L7.9999 8.29986L6.14923 6.44919V6.44986Z'
        fill='#8C8B88'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.00033 2.57812C6.32062 2.57813 4.70971 3.24538 3.52198 4.43312C2.33425 5.62085 1.66699 7.23175 1.66699 8.91146C1.66699 10.5912 2.33425 12.2021 3.52198 13.3898C4.70971 14.5775 6.32062 15.2448 8.00033 15.2448C9.68003 15.2448 11.2909 14.5775 12.4787 13.3898C13.6664 12.2021 14.3337 10.5912 14.3337 8.91146C14.3337 7.23175 13.6664 5.62085 12.4787 4.43312C11.2909 3.24538 9.68003 2.57813 8.00033 2.57812ZM2.66699 8.91146C2.66699 7.49697 3.2289 6.14042 4.22909 5.14022C5.22928 4.14003 6.58584 3.57812 8.00033 3.57812C9.41481 3.57812 10.7714 4.14003 11.7716 5.14022C12.7718 6.14042 13.3337 7.49697 13.3337 8.91146C13.3337 10.3259 12.7718 11.6825 11.7716 12.6827C10.7714 13.6829 9.41481 14.2448 8.00033 14.2448C6.58584 14.2448 5.22928 13.6829 4.22909 12.6827C3.2289 11.6825 2.66699 10.3259 2.66699 8.91146Z'
        fill='#8C8B88'
      />
    </svg>
  );
}
