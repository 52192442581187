import styled, { keyframes } from 'styled-components';

export const sidebar = keyframes`
  0% {
    transform: translateX(105%);
  }

  100% {
    transform: translateX(0);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: stretch;
  top: 50px;
  z-index: 5;
  right: 0;
  min-height: calc(100vh - 50px);
  background-color: ${(props) => props.theme.color.base01};
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  animation: ${sidebar} 0.3s ease;
  &.active {
    transform: none;
  }
`;

export const SidebarContent = styled.div``;
