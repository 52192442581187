import { ProfileManagementWrapper } from './ProfileManagementModal.styled';
import { EIcon, IconNew as IconInstance } from '../../../../icons/medium-new-icons/icon';
import { FlexWithAlign, IconWrapper, PageTextLg } from '../../../../../utils/styleUtils';
import UpdatePasswordForm from '../../components/update-password/UpdatePasswordForm';
import UpdatePhoneForm from '../../components/update-phone/UpdatePhoneForm';
import UpdateNameForm from '../../components/update-name/UpdateNameForm';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface IProps {
  closeModal?: () => void;
  modalPayload?: any;
}

const ProfileManagementModal: React.FC<IProps> = ({ closeModal, modalPayload }) => {
  const { t } = useTranslation();

  return (
    <ProfileManagementWrapper>
      <FlexWithAlign
        $align={'center'}
        $gap={'20px'}
      >
        <IconWrapper className={'main h25'}>
          <IconInstance name={EIcon.logomarkwhite} />
        </IconWrapper>
        <PageTextLg className={'regular'}>
          {modalPayload.type === 'fio'
            ? t('Изменить ФИО')
            : modalPayload.type === 'phone'
            ? t('Изменить телефон')
            : modalPayload.type === 'password' && t('Изменить пароль')}
        </PageTextLg>
      </FlexWithAlign>
      <FlexWithAlign
        $column
        className={'xl666'}
      >
        {modalPayload.type === 'password' && (
          <p>
            {t(
              'Требования к паролю - он должен: - включать не менее 8 символов - включать маленькие и большие буквы латинского алфавита -включать числа - специальные символы (типа %^& и т.д.)'
            )}
          </p>
        )}
        {modalPayload.type === 'fio' && <UpdateNameForm modalPayload={modalPayload.info} />}
        {modalPayload.type === 'phone' && <UpdatePhoneForm modalPayload={modalPayload.info} />}
        {modalPayload.type === 'password' && <UpdatePasswordForm />}
      </FlexWithAlign>
    </ProfileManagementWrapper>
  );
};

export default ProfileManagementModal;
