import React, { FC } from 'react';
import { EmojiWrapper } from './Emoji.styled';
import { iconCodes } from './emoji.data';

interface EmojiProps {
  onSelectEmoji: any;
}

const Emoji: FC<EmojiProps> = ({ onSelectEmoji }) => {
  const handleImageClick = (emojiSrc: string, alt: string) => {
    onSelectEmoji(emojiSrc, alt);
  };

  return (
    <EmojiWrapper>
      {iconCodes.map((item) => (
        <img
          key={item.link}
          src={item.link}
          alt={item.alt}
          loading={'lazy'}
          onClick={() => handleImageClick(item.link, item.alt)}
        />
      ))}
    </EmojiWrapper>
  );
};

export default Emoji;
