import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  width: 100%;
  margin: 0 auto;
`;
export const TextareaWrapper = styled.div`
  > div {
    max-height: none;
    min-height: 20rem;
  }
`;
export const Box = styled.div`
  width: 100%;

  &.form {
    display: flex;
    flex-direction: column;
    gap: 28px;
    button {
      width: fit-content;
      align-self: flex-end;
    }
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  & > div {
    width: 50%;
  }
`;

export const ButtonInner = styled.div`
  display: flex;
  gap: 12px;
`;
export const DescriptionTask = styled.textarea`
  background: rgba(248, 248, 251, 0.5) !important;
  border: none !important;
  height: 150px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  padding: 19px;
  resize: none;
  align-items: center;
  &:focus,
  &:focus-within {
    border: none !important;
    outline: none !important;
  }
`;
