export const NewBackArrow = () => {
  return (
    <svg
      width='27'
      height='20'
      viewBox='0 0 27 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2547_13644)'>
        <path
          d='M0.886562 9.44156L9.28656 0.641563C9.44656 0.481564 9.63323 0.401564 9.84656 0.401564C10.0599 0.401564 10.2466 0.481564 10.4066 0.641563C10.5666 0.801563 10.6466 0.98823 10.6466 1.20156C10.6466 1.4149 10.5666 1.60156 10.4066 1.76156L3.36656 9.20156H25.5266C25.7399 9.20156 25.9266 9.28156 26.0866 9.44156C26.2466 9.60156 26.3266 9.78823 26.3266 10.0016C26.3266 10.2149 26.2466 10.4016 26.0866 10.5616C25.9266 10.7216 25.7399 10.8016 25.5266 10.8016H3.36656L10.4066 18.2416C10.5666 18.4016 10.6466 18.5882 10.6466 18.8016C10.6466 19.0149 10.5666 19.2016 10.4066 19.3616C10.2466 19.5216 10.0599 19.6016 9.84656 19.6016C9.63323 19.6016 9.44656 19.5216 9.28656 19.3616L0.886562 10.5616C0.779896 10.4016 0.726562 10.2149 0.726562 10.0016C0.726562 9.78823 0.779896 9.60156 0.886562 9.44156Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2547_13644'>
          <rect
            width='26.97'
            height='20'
            fill='white'
            transform='matrix(1 0 0 -1 0 20)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
