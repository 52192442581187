import React from 'react';

export function Filter() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.24246 8.89998C6.62114 10.061 7.71264 10.9 9.0001 10.9C10.2876 10.9 11.3791 10.061 11.7577 8.89998C14.5052 8.89997 17.2526 8.89998 20.0001 8.89998C20.4972 8.89998 20.9001 8.49703 20.9001 7.99998C20.9001 7.50292 20.4972 7.09998 20.0001 7.09998C17.2526 7.09998 14.5052 7.09998 11.7577 7.09998C11.748 7.06961 11.761 7.11007 11.7577 7.09998C11.3791 5.93893 10.2876 5.09998 9.0001 5.09998C7.71264 5.09998 6.62114 5.93894 6.24246 7.09998C5.49501 7.09998 4.74755 7.09998 4.0001 7.09998C3.50304 7.09998 3.1001 7.50292 3.1001 7.99998C3.1001 8.49703 3.50304 8.89998 4.0001 8.89998C4.74755 8.89998 5.49501 8.89997 6.24246 8.89998ZM9.0001 6.89998C8.39258 6.89998 7.9001 7.39246 7.9001 7.99998C7.9001 8.60749 8.39258 9.09998 9.0001 9.09998C9.60761 9.09998 10.1001 8.60749 10.1001 7.99998C10.1001 7.39246 9.60761 6.89998 9.0001 6.89998Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.1001 16C3.1001 15.5029 3.50304 15.1 4.0001 15.1C6.74755 15.1 9.49501 15.1 12.2425 15.1C12.6211 13.9389 13.7126 13.1 15.0001 13.1C16.2876 13.1 17.3791 13.9389 17.7577 15.1C17.7577 15.1 19.4787 15.1 20.0001 15.1C20.4972 15.1 20.9001 15.5029 20.9001 16C20.9001 16.497 20.4972 16.9 20.0001 16.9C19.7779 16.9 17.7577 16.9 17.7577 16.9C17.3791 18.061 16.2876 18.9 15.0001 18.9C13.7126 18.9 12.6211 18.061 12.2425 16.9C9.49501 16.9 6.74755 16.9 4.0001 16.9C3.50304 16.9 3.1001 16.497 3.1001 16ZM13.9001 16C13.9001 15.3925 14.3926 14.9 15.0001 14.9C15.6076 14.9 16.1001 15.3925 16.1001 16C16.1001 16.6075 15.6076 17.1 15.0001 17.1C14.3926 17.1 13.9001 16.6075 13.9001 16Z'
        fill='currentColor'
      />
    </svg>
  );
}
