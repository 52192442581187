import React from 'react';
import { CSSProperties, memo } from 'react';

import { SkeletonWrapper } from './Skeleton.styled';

import { cn } from '../../../shared/lib';

interface SkeletonProps {
  className?: string;
  height?: string | number;
  width?: string | number;
  border?: string;
  count?: number;
}

export const Skeleton = memo((props: SkeletonProps) => {
  const { className, height, width, border, count } = props;

  const styles: CSSProperties = {
    width,
    height,
    borderRadius: border
  };

  const spans = Array.from({ length: count || 1 }, (_, index) => <span key={index} />);

  return (
    <SkeletonWrapper
      className={cn(className)}
      style={styles}
    >
      {spans}
    </SkeletonWrapper>
  );
});

Skeleton.displayName = 'Skeleton';
