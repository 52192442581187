export const PaymentIcon22 = () => {
  return (
    <svg
      width='35'
      height='33'
      viewBox='0 0 35 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 16.4675C1 7.82944 8.00966 0.777229 16.7796 0.507965V5.46194C10.8248 5.72603 6.06923 10.5444 6.06923 16.4675C6.06923 22.5554 11.1069 27.4898 17.3041 27.4898C23.3329 27.4898 28.2595 22.8708 28.5226 16.9303L33.5978 16.9348C33.3296 25.5487 26.1487 32.4403 17.3041 32.4403C8.28859 32.4403 1 25.2772 1 16.4675ZM10.3883 16.4673C10.3883 12.7188 13.4959 9.66216 17.3419 9.66216C21.1882 9.66216 24.2983 12.719 24.2983 16.4673C24.2983 20.2156 21.1907 23.2724 17.3419 23.2724C13.4931 23.2724 10.3883 20.2185 10.3883 16.4673ZM23.0117 7.21415C23.0117 5.1856 24.6914 3.52672 26.7822 3.52672C28.8734 3.52672 30.5555 5.18602 30.5555 7.21415C30.5555 9.24289 28.8755 10.9042 26.7822 10.9042C24.6896 10.9042 23.0117 9.24082 23.0117 7.21415Z'
        fill='white'
        stroke='currentColor'
      />
    </svg>
  );
};
