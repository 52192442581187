import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileSheetOptionsWrapper } from '../../../../../../pages/Recording/lib/recording-wrapper/mobile-sheet/MobileSheet.styled';
import { EIcon, IconNew as IconInstance } from '../../../../../icons/medium-new-icons/icon';
import { Drawer } from '../../../../../../shared/ui';
import {
  MobileAbsoluteDropdown,
  MobileCenter
} from '../../../../../../pages/EmployeesPage/ui/employee-timetable/ui/EmployeeTimeTablePage.styled';
import RecordingCalendar from '../../../../../../pages/Recording/lib/recording-wrapper/header/header-calendar/RecordingCalendar';
import { DateIntervalWrap, TopDateBox } from '../EmployeeScheduleModal.styled';
import dayjs from 'dayjs';
import { getMonday } from '../../../../../../store/redux/calendar/calendar.actions';
import { addDateBy } from '../../../../../../utils/date-events';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';

interface IProps {
  onlyDay?: boolean;
}
const MobileSheet: FC<IProps> = (props) => {
  const { onlyDay } = props;
  const { t } = useTranslation();
  const { activeDate } = useTypedSelector((state) => state.calendar);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  return (
    <MobileSheetOptionsWrapper>
      <TopDateBox onClick={() => setIsOpen(true)}>
        <div className='date flex'>
          <IconInstance name={EIcon.calendar} />
          <DateIntervalWrap>
            {onlyDay ? (
              <>{t(`${dayjs(activeDate).format('DD MMMM YYYY')}`)}</>
            ) : (
              <>
                {t(
                  `${dayjs(getMonday(new Date(activeDate)))
                    .locale('ru')
                    .format('DD MMMM YYYY')}`
                )}{' '}
                -{' '}
                {t(
                  `${dayjs(addDateBy(getMonday(new Date(activeDate)), 6))
                    .locale('ru')
                    .format('DD MMMM YYYY')}`
                )}
              </>
            )}
          </DateIntervalWrap>
        </div>
      </TopDateBox>
      {isOpen && (
        <Drawer
          lazy
          isOpen={isOpen}
          onClose={onClose}
          header={t('Выберите дату')}
        >
          <MobileAbsoluteDropdown>
            <MobileCenter>
              <RecordingCalendar
                className={'mobile-sheet-calendar'}
                currentView={onlyDay ? 'resourceTimeGridDay' : 'resourceTimeGridWeek'}
              />
            </MobileCenter>
          </MobileAbsoluteDropdown>
        </Drawer>
      )}
    </MobileSheetOptionsWrapper>
  );
};

export default MobileSheet;
