import React from 'react';

export function LogoFirstIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 12C3.79565 12 4.55871 11.6839 5.12132 11.1213C5.68393 10.5587 6 9.79565 6 9C6 8.20435 5.68393 7.44129 5.12132 6.87868C4.55871 6.31607 3.79565 6 3 6C2.20435 6 1.44129 6.31607 0.87868 6.87868C0.316071 7.44129 0 8.20435 0 9C0 9.79565 0.316071 10.5587 0.87868 11.1213C1.44129 11.6839 2.20435 12 3 12ZM3 10C2.73478 10 2.48043 9.89464 2.29289 9.70711C2.10536 9.51957 2 9.26522 2 9C2 8.73478 2.10536 8.48043 2.29289 8.29289C2.48043 8.10536 2.73478 8 3 8C3.26522 8 3.51957 8.10536 3.70711 8.29289C3.89464 8.48043 4 8.73478 4 9C4 9.26522 3.89464 9.51957 3.70711 9.70711C3.51957 9.89464 3.26522 10 3 10ZM15 6C15.7956 6 16.5587 5.68393 17.1213 5.12132C17.6839 4.55871 18 3.79565 18 3C18 2.20435 17.6839 1.44129 17.1213 0.87868C16.5587 0.316071 15.7956 0 15 0C14.2044 0 13.4413 0.316071 12.8787 0.87868C12.3161 1.44129 12 2.20435 12 3C12 3.79565 12.3161 4.55871 12.8787 5.12132C13.4413 5.68393 14.2044 6 15 6ZM15 4C14.7348 4 14.4804 3.89464 14.2929 3.70711C14.1054 3.51957 14 3.26522 14 3C14 2.73478 14.1054 2.48043 14.2929 2.29289C14.4804 2.10536 14.7348 2 15 2C15.2652 2 15.5196 2.10536 15.7071 2.29289C15.8946 2.48043 16 2.73478 16 3C16 3.26522 15.8946 3.51957 15.7071 3.70711C15.5196 3.89464 15.2652 4 15 4ZM15 18C15.7956 18 16.5587 17.6839 17.1213 17.1213C17.6839 16.5587 18 15.7956 18 15C18 14.2044 17.6839 13.4413 17.1213 12.8787C16.5587 12.3161 15.7956 12 15 12C14.2044 12 13.4413 12.3161 12.8787 12.8787C12.3161 13.4413 12 14.2044 12 15C12 15.7956 12.3161 16.5587 12.8787 17.1213C13.4413 17.6839 14.2044 18 15 18ZM15 16C14.7348 16 14.4804 15.8946 14.2929 15.7071C14.1054 15.5196 14 15.2652 14 15C14 14.7348 14.1054 14.4804 14.2929 14.2929C14.4804 14.1054 14.7348 14 15 14C15.2652 14 15.5196 14.1054 15.7071 14.2929C15.8946 14.4804 16 14.7348 16 15C16 15.2652 15.8946 15.5196 15.7071 15.7071C15.5196 15.8946 15.2652 16 15 16Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 10.562L12.66 15.562L13.66 13.83L5 8.83L4 10.562Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 7.83L5 9.562L13.66 4.562L12.66 2.83L4 7.83Z'
        fill='currentColor'
      />
    </svg>
  );
}
