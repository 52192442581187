import styled from 'styled-components';

import themelight from '../../../../app/providers/ThemeProvider/ui/theme';
import { scaleIn } from '../../../../utils/styleUtils';

export const CheckButtonWrapper = styled.div``;

export const CheckButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: linear-gradient(225deg, #eeeeee 0%, #d8d8d8 100%);
  position: relative;
  &.empty-name {
    background: none;
  }
`;

export const CheckButtonLetter = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themelight.newColor.grey06};
  &.empty-name {
    color: ${themelight.newColor.grey02};
  }
`;

export const CheckButtonChecked = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #2a2c32b2;
  z-index: 1;
  color: ${themelight.newColor.base01};
  animation: ${scaleIn} 0.3s ease;
  svg {
    width: 20px;
    height: 20px;
  }
`;
