import React from 'react';

export function PhoneDop() {
  return (
    <svg
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.49506 2.12316C-1.42542 5.33636 0.13449 10.3086 4.42326 14.6171C8.62077 18.834 13.8171 20.4106 16.8922 17.5494C18.5745 15.9841 18.3259 13.2121 16.2476 11.3596C14.3688 9.76306 11.4159 9.48654 9.53401 11.2534C8.89989 10.7197 8.35989 10.0895 7.78007 9.49998C9.40616 7.79406 9.40117 4.82422 7.72464 2.8211C5.83975 0.56901 2.9807 0.488623 1.49506 2.12316ZM6.34619 3.97496C7.40471 5.23969 7.54617 7.25577 6.29694 8.43407C5.6676 9.00021 5.05323 9.33399 7.32822 11.6475C9.78282 14.1437 10.0889 13.268 10.7283 12.5986C11.7327 11.5896 13.6995 11.4964 15.0515 12.7016C16.5467 14.0344 16.3847 15.5661 15.6677 16.2333C13.449 18.2977 9.20005 16.8678 5.69723 13.3488C2.1944 9.82985 0.639852 5.73679 2.82526 3.33236C3.33259 2.77417 4.92364 2.27529 6.34619 3.97496Z'
        fill='currentColor'
      />
      <path
        d='M15.5 0C15.9971 0 16.4 0.402944 16.4 0.9V2.59999H18.1C18.597 2.59999 19 3.00293 19 3.49999C19 3.99704 18.597 4.39999 18.1 4.39999H16.4V6.09998C16.4 6.59703 15.9971 6.99998 15.5 6.99998C15.0029 6.99998 14.6 6.59703 14.6 6.09998V4.39999H12.9C12.403 4.39999 12 3.99704 12 3.49999C12 3.00293 12.403 2.59999 12.9 2.59999H14.6V0.9C14.6 0.402944 15.0029 0 15.5 0Z'
        fill='currentColor'
      />
    </svg>
  );
}
