export function LinkCircle() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.92893 4.92893C6.73759 3.12027 9.23882 2 12 2C14.7612 2 17.2624 3.12027 19.0711 4.92893C20.8797 6.73759 22 9.23882 22 12C22 14.7612 20.8797 17.2624 19.0711 19.0711C17.2624 20.8797 14.7612 22 12 22C9.23882 22 6.73759 20.8797 4.92893 19.0711C3.12027 17.2624 2 14.7612 2 12C2 9.23882 3.12027 6.73759 4.92893 4.92893ZM12 3.8C9.73538 3.8 7.68662 4.71683 6.20172 6.20172C4.71683 7.68662 3.8 9.73538 3.8 12C3.8 14.2646 4.71683 16.3134 6.20172 17.7983C7.68662 19.2832 9.73538 20.2 12 20.2C14.2646 20.2 16.3134 19.2832 17.7983 17.7983C19.2832 16.3134 20.2 14.2646 20.2 12C20.2 9.73538 19.2832 7.68662 17.7983 6.20172C16.3134 4.71683 14.2646 3.8 12 3.8Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99998 8.9C8.99998 8.40294 9.40292 8 9.89998 8H15.1C15.597 8 16 8.40294 16 8.9V14.1C16 14.5971 15.597 15 15.1 15C14.6029 15 14.2 14.5971 14.2 14.1V11.0728L9.53637 15.7364C9.18489 16.0879 8.61505 16.0879 8.26357 15.7364C7.9121 15.3849 7.9121 14.8151 8.26357 14.4636L12.9272 9.8H9.89998C9.40292 9.8 8.99998 9.39706 8.99998 8.9Z'
        fill='currentColor'
      />
    </svg>
  );
}
