import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { fetchSalesAsync } from './sales.actions';
import { ISalesProducts, SalesState } from './sales.interface';

import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { IClient } from '../clients/clients.interface';

const initialState: SalesState = {
  sale: {
    comment: '',
    formpay: [
      {
        id: '',
        summ: 0
      }
    ],
    kassa: '',
    kontragent: '',
    price: 0,
    product: '',
    saleproducts: [],
    type: ''
  },
  chosenTransactionDate: undefined,
  typeDatePeriod: 'day',
  state: PENDING
};

export const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    setNewSale: (state, action: PayloadAction<ISalesProducts>) => {
      if (!state?.sale?.saleproducts) {
        state.sale.saleproducts = [];
      }
      if (!state?.sale?.formpay) {
        state.sale.formpay = [
          {
            id: '',
            summ: 0
          }
        ];
      }
      const existingProduct = state?.sale?.saleproducts.map((product) => product).find((pr) => pr.id === action.payload.id);
      if (existingProduct) {
        existingProduct.count += 1;
        existingProduct.price += action.payload.price;
      } else {
        state.sale.saleproducts = [...state.sale.saleproducts, action.payload];
      }
      state.sale.price += action.payload.price;
      state.sale.formpay[0].summ += action.payload.price;
    },
    setDeleteSale: (state, action: PayloadAction<ISalesProducts>) => {
      if (!state?.sale?.saleproducts) {
        state.sale.saleproducts = [];
      }
      const existingProduct = state.sale.saleproducts.map((product) => product).find((pr) => pr.id === action.payload.id);
      const existingProductIndex = state.sale.saleproducts.findIndex((product) => product.id === action.payload.id);

      if (existingProduct) {
        existingProduct.count -= 1;
        existingProduct.price -= action.payload.price / action.payload.count;

        if (existingProduct.count === 0) {
          state.sale.saleproducts.splice(existingProductIndex, 1);
        }
      }
      state.sale.price -= action.payload.price;
    },
    setClientForSale: (state, action: PayloadAction<IClient | string>) => {
      state.sale.kontragent = typeof action.payload === 'object' ? action.payload?.id : '';
    },
    setPaymentMethod: (state, action) => {
      if (!state?.sale?.formpay) {
        state.sale.formpay = [
          {
            id: '',
            summ: 0
          }
        ];
      }
      state.sale.formpay[0].id = action.payload;
    },
    setKassa: (state, action) => {
      state.sale.kassa = action.payload;
    },
    setInitialState: () => {
      return initialState;
    },
    setChosenDate: (state, action: PayloadAction<string>) => {
      state.chosenTransactionDate = action.payload;
    },
    setTypeDatePeriod: (state, action: PayloadAction<string>) => {
      state.typeDatePeriod = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSalesAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchSalesAsync.fulfilled, (state, action) => {
        state.sale = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchSalesAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer, actions } = salesSlice;
export const { setNewSale } = actions;
