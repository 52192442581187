import styled from 'styled-components';

import {
  DURATION,
  FONT_M,
  FONT_S,
  HEIGHT_INPUT,
  HEIGHT_INPUT_M,
  HEIGHT_INPUT_S,
  HEIGHT_INPUT_NEW,
  RADIUS_XS,
  // SHADOW_INPUT,
  SHADOW_INPUT_HOGER
} from '../../../../styles';

interface ISearchProps {
  size?: 'l' | 'm' | 's' | 'sm';
  $type?: 'gray';
}
export const InputSearch = styled.div<ISearchProps>`
  width: 100%;
  max-width: 23rem;
  &.full {
    max-width: 100%;
  }
  @media (max-width: 1700px) {
    width: 100%;
    /* max-width: 15rem; */
  }
  @media (max-width: 1540px) {
    width: 100%;
    /* max-width: 10rem; */
  }
  @media (max-width: 1440px) {
    width: 100%;
    /* max-width: 15rem; */
  }
  @media (max-width: 1280px) {
    width: 100%;
    /* max-width: 12rem; */
  }
  display: block;
  position: relative;
  appearance: none;
  border-radius: ${RADIUS_XS};
  text-align: left;
  height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_S;
      }
    } else {
      return HEIGHT_INPUT_S;
    }
  }};
  min-height: ${(props) => {
    if (props.size !== undefined) {
      return HEIGHT_INPUT_NEW;
      // switch (props.size) {
      //   case 'l':
      //     return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
      //   case 'm':
      //     return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
      //   case 's':
      //     return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
      //   default:
      //     return HEIGHT_INPUT_S;
      // }
    // } else {
    //   return HEIGHT_INPUT_S;
    }
  }};
  max-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_S;
      }
    } else {
      return HEIGHT_INPUT_S;
    }
  }};
  label {
    width: 100%;
    max-width: 100%;
    display: block;
    transition-property: box-shadow, background;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    background: ${(props) => props.theme.color.greyBackground};
    color: ${(props) => props.theme.text.greyFontColor};
    position: relative;
    appearance: none;
    border-radius: ${RADIUS_XS};
    border-color: ${(props) => props.theme.color.base10};
    text-align: left;
    line-height: 1.25rem;
    height: ${(props) => {
      if (props.size !== undefined) {
        return HEIGHT_INPUT_NEW;
      //   switch (props.size) {
      //     case 'l':
      //       return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
      //     case 'm':
      //       return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
      //     case 's':
      //       return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
      //     default:
      //       return HEIGHT_INPUT_S;
        }
      // } else {
      //   return HEIGHT_INPUT_S;
      // }
    }};
    min-height: ${(props) => {
      if (props.size !== undefined) {
        return HEIGHT_INPUT_NEW;
      //   switch (props.size) {
      //     case 'l':
      //       return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
      //     case 'm':
      //       return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
      //     case 's':
      //       return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
      //     default:
      //       return HEIGHT_INPUT_S;
      //   }
      // } else {
      //   return HEIGHT_INPUT_S;
      }
    }};
    max-height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
          case 'm':
            return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
          case 's':
            return props.size ? HEIGHT_INPUT_S : HEIGHT_INPUT_M;
          default:
            return HEIGHT_INPUT_S;
        }
      } else {
        return HEIGHT_INPUT_S;
      }
    }};

    /* &:hover {
      box-shadow: ${SHADOW_INPUT_HOGER};
    } */

    &:after {
      transition-property: color;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      color: ${(props) => props.theme.color.base03};
      position: absolute;
      inset: 0;
      content: '';
      border-radius: inherit;
      border: 1px solid currentColor;
      pointer-events: none;
    }

    &:focus-within {
      span {
        position: absolute;
        line-height: 1.25rem;
        left: 2.25rem;
        z-index: 2;
      }

      &:after {
        border-width: 2px;
        color: ${(props) => props.theme.color.primary};
      }
    }

    span {
      position: absolute;
      top: 55%;
      color: ${(props) => props.theme.text.greyFontColor};
      transform: translateY(-50%);
      left: 2.25rem;
      pointer-events: none;
      font: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'l':
              return props.size ? FONT_M : FONT_S;
            case 'm':
              return props.size ? FONT_S : FONT_S;
            case 's':
              return props.size ? FONT_S : FONT_S;
            default:
              return FONT_S;
          }
        } else {
          return FONT_S;
        }
      }};
      line-height: inherit;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      transition-property: transform, top, font;
    }

    &.active {
      span {
        opacity: 0;
      }
    }

    input {
      padding-inline: 2.25rem;
      background: transparent;
      inset: 0;
      transition: box-shadow 0.01s;
      margin: 0;
      border-width: 0;
      border-radius: inherit;
      background: none;
      font: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'l':
              return props.size ? FONT_M : FONT_S;
            case 'm':
              return props.size ? FONT_S : FONT_S;
            case 's':
              return props.size ? FONT_S : FONT_S;
            default:
              return FONT_S;
          }
        } else {
          return FONT_S;
        }
      }};
      color: ${(props) => props.theme.text.text01};
      caret-color: currentColor;
      outline: none;
      appearance: none;
      word-break: keep-all;
      -webkit-text-fill-color: currentColor;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-style: solid;
      border-color: transparent;
      border-inline-start-width: 0rem;
      border-inline-end-width: 0rem;
      text-indent: 0px;
      text-align: inherit;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      text-transform: inherit;
      resize: none;
    }
  }
  .input-search-icon {
    transition: opacity 0.2s ease-out, color 0.2s ease-out;
    display: flex;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    color: ${(props) => props.theme.text.text02};
    opacity: 0.6;
    inset-inline-start: 8px;
    line-height: 1;
    z-index: 1;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  .input-search-placeholder {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    inset-inline-start: 32px;
    pointer-events: none;
    transform-origin: left center;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: ${(props) => props.theme.text.text02};
  }
`;
