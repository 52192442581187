import React from 'react';

export function SuccessFill() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM12.8435 6.70859C13.0967 6.38083 13.0363 5.90981 12.7086 5.65654C12.3808 5.40327 11.9098 5.46365 11.6565 5.79141L7.95615 10.5801L6.30747 8.74827C6.03038 8.44039 5.55616 8.41543 5.24828 8.69253C4.94039 8.96962 4.91543 9.44384 5.19253 9.75172L7.44253 12.2517C7.59132 12.417 7.80582 12.5078 8.02809 12.4995C8.25036 12.4911 8.45746 12.3846 8.59346 12.2086L12.8435 6.70859Z'
        fill='#009963'
      />
    </svg>
  );
}
