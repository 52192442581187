import { styled } from 'styled-components';
import themelight from '../../../../../../app/providers/ThemeProvider/ui/theme';

import { FlexWithAlign } from '../../../../../../utils/styleUtils';

export const ModalWrapper = styled(FlexWithAlign)`
  padding: 24px;
  width: 100%;
  .transfer-summ-input {
    height: 64px;
    label {
      height: 64px;
      min-height: 64px;
      max-height: 64px;
    }
    input {
      font-size: 18px;
    }
    .currencySymbol {
      font-size: 18px;
    }
    .currencyRubble {
      border: none;
      &::before {
        background-color: ${themelight.newColor.base08};
      }
    }
  }
`;

export const BtnsWrap = styled(FlexWithAlign)`
  margin-top: 64px;
`;

export const ModalContent = styled(FlexWithAlign)``;

export const DropDownsWrap = styled(FlexWithAlign)``;

export const IconWrap = styled.span`
  display: flex;
  padding: 10px;
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
  }
`;
