import styled from 'styled-components';

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  span {
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: 0 2px 10px 0 ${(props) => props.theme.color.base02};
    background: ${(props) => props.theme.color.base02};
    overflow: hidden;
    display: block;
    border-radius: 16px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: #eee;
      background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
      border-radius: 5px;
      background-size: 200% 100%;
      animation: 1.5s shine linear infinite;
    }
  }
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
`;
