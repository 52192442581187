import { styled } from 'styled-components';
import themelight from '../../../../../../app/providers/ThemeProvider/ui/theme';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';

export const PaymentsHistoryModalWrapper = styled(FlexWithAlign)`
  padding: 24px 24px 40px 24px;
  width: 100%;
`;
export const ModalHeader = styled(FlexWithAlign)``;

export const PaymentsHistoryModalContent = styled(FlexWithAlign)`
  width: 100%;
`;

export const PaymentsHeader = styled.div`
  padding: 12px 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 1px solid ${themelight.newColor.base08};
  font-weight: 600;
  font-size: 16px;
`;

export const PaymentsList = styled(FlexWithAlign)`
  max-height: 400px;
  overflow: auto;
`;
export const PaymentsHistoryModalPayment = styled.div`
  padding: 12px 0;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 1px solid ${themelight.newColor.base08};
`;

export const PaymentHeaderSumm = styled.div``;

export const PaymentHeaderDate = styled.div``;

export const PaymentSumm = styled.div``;

export const PaymentDate = styled.div``;

export const PaymentsEmpty = styled(FlexWithAlign)`
  min-height: 300px;
`;
