import { IBreakTemplate } from '../../../../../store/redux/user/user.interface';

export type DataItem = {
  label: string;
  value: string;
};

export const days: DataItem[] = [
  { label: 'Понедельник', value: 'monday' },
  { label: 'Вторник', value: 'tuesday' },
  { label: 'Среда', value: 'wednesday' },
  { label: 'Четверг', value: 'thursday' },
  { label: 'Пятница', value: 'friday' },
  { label: 'Суббота', value: 'saturday' },
  { label: 'Воскресенье', value: 'sunday' }
];

export interface ITimeworkValues {
  timestart: string;
  timeend: string;
  break?: IBreakTemplate[];
}

export type PromiseFactory<T> = () => Promise<T>;

export function executeSequentially<T>(promiseFactories: PromiseFactory<T>[]): Promise<T[]> {
  return promiseFactories.reduce(
    (promiseChain, currentPromiseFactory) =>
      promiseChain.then((chainResults) => currentPromiseFactory().then((currentResult) => [...chainResults, currentResult])),
    Promise.resolve([] as T[])
  );
}
