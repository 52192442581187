import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { ModalGrid } from '../../../../pages/Recording/lib/CommonRecording.styled';
import { Input, MultiSelect, PositionType, Text, TextType } from '../../../../shared/ui';
import CommonButton from '../../../../shared/ui/Button/ui/CommonButton';
import Timepicker from '../../../../shared/new-ui/NewTimePicker/ui/NewTimepicker';
import { ICabinet, ICabinetService } from '../../../../store/redux/cabinets/cabinets.interface';
import { useUpdateCabinet } from '../../../../store/redux/cabinets/hooks/useCabinetsMutation';
import { useFilialQuery } from '../../../../store/redux/filial/hooks/useFilialQuery';
import { useProductsIdsQuery } from '../../../../store/redux/products/hooks/useProductsQuery';
import { ISubproduct } from '../../../../store/redux/subproducts/subproducts.interface';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { EIcon, IconNew as IconInstance } from '../../../icons/medium-new-icons/icon';
import { Form, Wrapper } from '../create/cabinet/CreateCabinetModal.styled';
import { useSubproductAllQuery } from '../../../../store/redux/subproducts/hooks/useSubproductQuery';
import { cn } from '../../../../shared/lib';
import { Calculate } from '../create/recording/single/client-info/RecordingModalSide.styled';
import useMatchMedia from '../../../../shared/lib/hooks/useMatchMedia';

interface IProps {
  closeModal?: () => void;
  modalPayload?: any;
}
const EditCabinetModal: React.FC<IProps> = ({ modalPayload, closeModal }) => {
  const [pending, setPending] = useState(false);
  const { data: ids } = useProductsIdsQuery();
  const { data: subproductsAll } = useSubproductAllQuery(ids);
  const { data: activeFilial } = useFilialQuery();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const [nameError, setNameError] = useState(false);

  const { mutateAsync: updateCabinet } = useUpdateCabinet();
  const [selectedServices, setSelectedServices] = useState<(Pick<ISubproduct, 'id' | 'name'> | undefined)[]>([]);

  const { t } = useTranslation();
  const initialValues = {
    filial: activeFilial?.id,
    name: '',
    seatsLimited: 0,
    workHoursEnd: '',
    workHoursStart: '',
    id: '',
    services: ''
  };
  const formik = useFormik({
    initialValues: modalPayload
      ? {
          ...modalPayload,
          services: '',
          workHoursEnd: `${modalPayload.workHoursEnd}`,
          workHoursStart: `${modalPayload.workHoursStart}`
        }
      : initialValues,
    onSubmit: (values: any) => {}
  });

  const saveCabinet = async (values: ICabinet) => {
    setPending(true);
    if (!activeFilial) return;
    const res = await updateCabinet({
      ...values,
      filial: activeFilial?.id,
      id: formik.values.id,
      idservices: selectedServices.map((item) => {
        return {
          idservice: item?.id
        };
      }),
      scheduleId: ''
    });
    if (res?.status === 'ok') {
      closeModal!();
      toast.success('Кабинет обновлен');
    } else {
      setPending(false);
      toast.error(res.description);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (formik.values.name === '') {
      setNameError(true);

      return;
    }
    saveCabinet(formik.values);
  };

  useEffect(() => {
    if (modalPayload && modalPayload.idservices) {
      const { idservices } = modalPayload;
      setSelectedServices(
        subproductsAll.filter((item) => idservices.findIndex((itemService: ICabinetService) => itemService.idservice === item?.id) > -1)
      );
    }
  }, [subproductsAll]);

  useEffect(() => {
    if (nameError) {
      formik.values.name !== '' && setNameError(false);
    }
  }, [formik.values.name]);

  return (
    <Wrapper>
      {isMobile ? null : (
        <Text
          $tag={TextType.H5}
          fontWeight={700}
        >
          {t('Редактирование кабинета')}
        </Text>
      )}
      <FlexWithAlign className={'xl666'}>
        <Form onSubmit={handleSubmit}>
          <Input
            label={t('Название*')}
            value={formik.values.name}
            error={nameError && 'Введите название'}
            onChange={formik.handleChange}
            name='name'
            type='text'
            autoComplete='off'
          />
          <Calculate>
            <Input
              label={'Вместимость'}
              value={formik.values.seatsLimited}
              onChange={formik.handleChange}
              name='seatsLimited'
              min={0}
              max={99}
              onBlur={formik.handleBlur}
              type='number'
              autoComplete='off'
            />

            <FlexWithAlign
              $column
              $gap={'0'}
            >
              <button
                onClick={(e: any) => {
                  e.preventDefault();
                  formik.setFieldValue('seatsLimited', formik.values.seatsLimited + 1);
                }}
              >
                <IconInstance name={EIcon.chevronupsmall} />
              </button>

              <button
                className={cn(formik.values.seats === 0 && 'hidden')}
                onClick={(e: any) => {
                  e.preventDefault();
                  formik.values.seatsLimited >= 0 && formik.setFieldValue('seatsLimited', formik.values.seatsLimited - 1);
                }}
              >
                <IconInstance name={EIcon.chevrondownsmall} />
              </button>
            </FlexWithAlign>
          </Calculate>
          <ModalGrid>
            <Timepicker
              label={'Начало'}
              value={formik.values.workHoursStart}
              onChange={formik.handleChange}
              name='workHoursStart'
            />
            <Timepicker
              label={'Окончание'}
              value={formik.values.workHoursEnd}
              onChange={formik.handleChange}
              name='workHoursEnd'
            />
          </ModalGrid>
          <MultiSelect
            optionPosition={PositionType.TOP}
            options={subproductsAll}
            value={selectedServices}
            setValue={setSelectedServices}
            placeholder={t('Услуги')}
          />
          <FlexWithAlign
            $align='center'
            $justify='flex-start'
            className={cn(isMobile && 'column-reverse')}
          >
            <CommonButton
              onClick={closeModal}
              typeBtn='gray'
              size={'M'}
              fullWidth={isMobile}
            >
              {t('Отменить')}
            </CommonButton>
            <CommonButton
              typeBtn='primary'
              size={'M'}
              fullWidth={isMobile}
              type='submit'
              disabled={pending}
            >
              <span>{t('Сохранить')}</span>
            </CommonButton>
          </FlexWithAlign>
        </Form>
      </FlexWithAlign>
    </Wrapper>
  );
};

export default EditCabinetModal;
