export function ShuffleOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.89609 6.1C2.39904 6.1 1.99609 6.50294 1.99609 7C1.99609 7.49706 2.39904 7.9 2.89609 7.9C5.52691 7.9 7.39735 9.51159 9.27985 11.6044C8.27271 12.7876 7.32869 13.8828 6.3071 14.7116C5.22921 15.586 4.13961 16.1 2.90146 16.1C2.4044 16.1 2.00146 16.5029 2.00146 17C2.00146 17.4971 2.4044 17.9 2.90146 17.9C4.69204 17.9 6.17084 17.1399 7.44112 16.1094C8.53821 15.2194 9.5348 14.0804 10.4755 12.9768C11.4283 14.087 12.4271 15.2407 13.5246 16.1352C14.7727 17.1523 16.2242 17.9 18 17.9H18.9272L18.3636 18.4635C18.0122 18.815 18.0122 19.3849 18.3636 19.7363C18.7151 20.0878 19.2849 20.0878 19.6364 19.7363L21.7364 17.6363C22.0879 17.2849 22.0879 16.715 21.7364 16.3636L19.6364 14.2636C19.2849 13.9121 18.7151 13.9121 18.3636 14.2636C18.0122 14.6151 18.0122 15.1849 18.3636 15.5364L18.9273 16.1H18C16.7759 16.1 15.7144 15.5977 14.6617 14.7398C13.6653 13.9278 12.7453 12.8566 11.7413 11.6876L11.5053 11.413C9.39638 8.96191 6.86585 6.1 2.89609 6.1Z'
        fill='currentColor'
      />
      <path
        d='M12.3183 8.40802C13.8046 7.15973 15.7006 6.1 18 6.1H18.9272L18.3636 5.5364C18.0122 5.18492 18.0122 4.61508 18.3636 4.2636C18.7151 3.91213 19.2849 3.91213 19.6364 4.2636L21.7364 6.36358C22.0879 6.71505 22.0879 7.2849 21.7364 7.63637L19.6364 9.73635C19.2849 10.0878 18.7151 10.0878 18.3636 9.73635C18.0122 9.38488 18.0122 8.81503 18.3636 8.46355L18.9272 7.9H18C16.283 7.9 14.785 8.68696 13.4759 9.78638C13.0953 10.1061 12.5276 10.0566 12.208 9.67601C11.8883 9.29539 11.9377 8.72769 12.3183 8.40802Z'
        fill='currentColor'
      />
    </svg>
  );
}
