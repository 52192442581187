import styled from 'styled-components';
import { PageTitle } from '../../../../../utils/styleUtils';
import { DURATION } from '../../../../../styles';

export const Wrapper = styled.div``;
export const Form = styled.form`
  width: 100%;
  margin: 26px auto 0;
  display: flex;
  flex-direction: column;
  gap: 18px;
  > div {
    &.controls {
      width: 100%;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  .messangers {
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 24px;
        height: 24px;
        max-width: 24px;
        max-height: 24px;
        color: ${(props) => props.theme.text.text03};
        pointer-events: none;
        transition: color ${DURATION} ease-in-out;
      }
      &.active {
        &.telegram-btn {
          svg {
            color: ${(props) => props.theme.text.linkHover};
          }
        }
        &.whatsapp-btn {
          svg {
            color: ${(props) => props.theme.text.positive};
          }
        }
        &.viber-btn {
          svg {
            color: rgba(155, 81, 224, 1);
          }
        }
        // svg {
        //   color: ${(props) => props.theme.color.base01};
        // }
      }
    }
  }
`;
export const Box = styled.div`
  width: 100%;
  &.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;
export const ButtonInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 18px;
    height: 18px;
  }
  span {
    margin-left: 12px;
  }
`;
export const Title = styled(PageTitle)`
  margin: 0;
`;
