import React from 'react';

export function ProductsIcon() {
  return (
    <svg
      width='29'
      height='29'
      viewBox='0 0 29 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.0119 19.747L15.1719 24.227V15.267L23.0119 10.675V19.747ZM14.4999 14.035L6.77186 9.55504L9.90786 7.65104L17.7479 12.243L14.4999 14.035ZM13.8279 24.227L5.98786 19.747V10.675L13.8279 15.267V24.227ZM14.4999 5.07504L22.2279 9.55504L19.0919 11.459L11.2519 6.86704L14.4999 5.07504ZM24.0199 8.99504C23.9452 8.99504 23.8705 8.99504 23.7959 8.99504L14.8359 3.73104C14.6119 3.58171 14.3879 3.58171 14.1639 3.73104L4.97986 8.99504C4.83053 9.14437 4.75586 9.33104 4.75586 9.55504V20.083C4.75586 20.307 4.83053 20.4937 4.97986 20.643L14.1639 25.907C14.2385 25.9817 14.3505 26.019 14.4999 26.019C14.6492 26.019 14.7612 25.9817 14.8359 25.907L24.0199 20.643C24.2439 20.4937 24.3559 20.307 24.3559 20.083V9.55504C24.3559 9.33104 24.2439 9.14437 24.0199 8.99504Z'
        fill='currentColor'
      />
    </svg>
  );
}
