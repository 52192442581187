import React from 'react';

export function BlackClose() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.44776 0.911749L9.99976 8.46375L17.5518 0.911749C17.7224 0.741083 17.9571 0.655748 18.2558 0.655748C18.5544 0.655748 18.8104 0.762415 19.0238 0.97575C19.2371 1.18908 19.3438 1.44508 19.3438 1.74375C19.3438 2.04242 19.2584 2.27708 19.0878 2.44775L11.5358 9.99975L19.0878 17.5517C19.2584 17.7224 19.3438 17.9571 19.3438 18.2557C19.3438 18.5544 19.2371 18.8104 19.0238 19.0237C18.8104 19.2371 18.5544 19.3438 18.2558 19.3438C17.9571 19.3438 17.7224 19.2584 17.5518 19.0877L9.99976 11.5357L2.44776 19.0877C2.2771 19.2584 2.04243 19.3438 1.74376 19.3438C1.4451 19.3438 1.1891 19.2371 0.975762 19.0237C0.762428 18.8104 0.655762 18.5544 0.655762 18.2557C0.655762 17.9571 0.741095 17.7224 0.911762 17.5517L8.46376 9.99975L0.911762 2.44775C0.741095 2.27708 0.655762 2.04242 0.655762 1.74375C0.655762 1.44508 0.762428 1.18908 0.975762 0.97575C1.1891 0.762415 1.4451 0.655748 1.74376 0.655748C2.04243 0.655748 2.2771 0.741083 2.44776 0.911749Z'
        fill='#151618'
      />
    </svg>
  );
}
