import { createAsyncThunk } from '@reduxjs/toolkit';

import { IPersonalSiteName, IRecordingPeriod } from './personalsite.interface';

import { PersonalSiteService } from '../../../shared/api/model/service/personalsite.service';

export const fetchOnlineReecordsAsync = createAsyncThunk('personalsite/fetchIdOnlineRecords', async () => {
  return await PersonalSiteService.fetchIdOnlineRecords();
});

export const updatePersonalSiteNameAsync = createAsyncThunk(
  'personalsite/updateOnlineRecords',
  async (data: IPersonalSiteName, { dispatch }) => {
    await PersonalSiteService.updateOnlineRecords(data);
    dispatch(fetchOnlineReecordsAsync());
  }
);

export const fetchScheduleOnlineRecordsAsync = createAsyncThunk(
  'personalsite/fetchScheduleOnlineRecords',
  async ({ uniq }: { uniq: string }) => {
    return await PersonalSiteService.fetchScheduleOnlineRecords(uniq);
  }
);

export const updateRecordingPeriodAsync = createAsyncThunk(
  'personalsite/updateRecordingPeriod',
  async (data: IRecordingPeriod, { dispatch }) => {
    await PersonalSiteService.updateRecordingPeriod(data);
    console.log(data)
    dispatch(fetchScheduleOnlineRecordsAsync({ uniq: data.uniq }));
  }
);
