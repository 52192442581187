export const PaymentIcon17 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24703)'>
        <path
          d='M2.75586 6H28.2559C28.2559 6 29.7559 6 29.7559 7.5V22.5C29.7559 22.5 29.7559 24 28.2559 24H2.75586C2.75586 24 1.25586 24 1.25586 22.5V7.5C1.25586 7.5 1.25586 6 2.75586 6Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M32.75 9V25.5C32.75 25.8978 32.592 26.2794 32.3107 26.5607C32.0294 26.842 31.6478 27 31.25 27H4.25'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.75 12V28.5C35.75 28.8978 35.592 29.2794 35.3107 29.5607C35.0294 29.842 34.6478 30 34.25 30H7.25'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.0059 15C11.0059 16.1935 11.48 17.3381 12.3239 18.182C13.1678 19.0259 14.3124 19.5 15.5059 19.5C16.6993 19.5 17.8439 19.0259 18.6878 18.182C19.5318 17.3381 20.0059 16.1935 20.0059 15C20.0059 13.8065 19.5318 12.6619 18.6878 11.818C17.8439 10.9741 16.6993 10.5 15.5059 10.5C14.3124 10.5 13.1678 10.9741 12.3239 11.818C11.48 12.6619 11.0059 13.8065 11.0059 15Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.25586 21H7.25586'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.7559 9H26.7559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24703'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
