import React from 'react';

export function TelegramIcon20() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.34151 9.54567C6.27855 7.94628 8.90387 6.89186 10.2175 6.38242C13.968 4.92785 14.7473 4.67518 15.2553 4.66684C15.367 4.665 15.6168 4.69082 15.7786 4.81324C15.9152 4.91662 15.9528 5.05626 15.9708 5.15427C15.9888 5.25227 16.0112 5.47554 15.9934 5.64999C15.7902 7.64118 14.9107 12.4733 14.4633 14.7034C14.274 15.6471 13.9012 15.9635 13.5404 15.9945C12.7561 16.0618 12.1606 15.5112 11.4009 15.0469C10.2123 14.3204 9.54081 13.8681 8.38703 13.1592C7.05364 12.3399 7.91802 11.8896 8.67792 11.1537C8.87678 10.9611 12.3323 8.03039 12.3992 7.76454C12.4076 7.73129 12.4153 7.60734 12.3364 7.5419C12.2574 7.47645 12.1408 7.49883 12.0567 7.51663C11.9375 7.54186 10.0387 8.71213 6.36015 11.0274C5.82117 11.3725 5.33297 11.5407 4.89556 11.5319C4.41336 11.5221 3.48579 11.2776 2.79623 11.0686C1.95047 10.8123 1.27827 10.6768 1.33681 10.2414C1.36729 10.0146 1.70219 9.78273 2.34151 9.54567Z'
        fill='white'
      />
    </svg>
  );
}
