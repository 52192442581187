import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PaymentsService } from '../../../../shared/api/model/service/payments.service';
import { PAYMENTS } from '../../../../shared/const/query.const';
import { IPaymentCreate, IPaymentUpdate } from '../payments.interface';

export const useCreatePayment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IPaymentCreate) => PaymentsService.createPayment(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PAYMENTS] });
      }
    }
  });
};

export const useUpdatePayment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IPaymentUpdate) => PaymentsService.updatePayment(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PAYMENTS] });
      }
    }
  });
};

export const useDeletePayment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => PaymentsService.deletePayment(id),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PAYMENTS] });
      }
    }
  });
};
