import React from 'react';

export function ViberIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_235_3037)'>
        <path
          d='M13 4C12.4477 4 12 4.44772 12 5C12 5.55228 12.4477 6 13 6C14.2728 6 15.2557 6.41989 15.9179 7.08211C16.5801 7.74433 17 8.72725 17 10C17 10.5523 17.4477 11 18 11C18.5523 11 19 10.5523 19 10C19 8.27275 18.4199 6.75567 17.3321 5.66789C16.2443 4.58011 14.7272 4 13 4Z'
          fill='currentColor'
        />
        <path
          d='M5.014 8.00613C5.12827 7.1024 6.30277 5.87414 7.23488 6.01043L7.23339 6.00894C8.01251 6.15699 8.65217 7.32965 9.07373 8.10246C9.14298 8.22942 9.20635 8.34559 9.26349 8.44465C9.55041 8.95402 9.3641 9.4701 9.09655 9.68787C9.06561 9.7128 9.03317 9.73855 8.9998 9.76504C8.64376 10.0477 8.18114 10.4149 8.28943 10.7834C8.5 11.5 11 14 12.2296 14.7107C12.6061 14.9283 12.8988 14.5057 13.1495 14.1438C13.2087 14.0583 13.2656 13.9762 13.3207 13.9067C13.5301 13.6271 14.0466 13.46 14.5548 13.736C15.3138 14.178 16.0288 14.6917 16.69 15.27C17.0202 15.546 17.0977 15.9539 16.8689 16.385C16.4659 17.1443 15.3003 18.1456 14.4542 17.9421C12.9764 17.5868 7 15.27 5.08033 8.55801C4.97981 8.26236 4.99645 8.13792 5.01088 8.02991L5.014 8.00613Z'
          fill='currentColor'
        />
        <path
          d='M13 7C12.4477 7 12 7.44772 12 8C12 8.55228 12.4477 9 13 9C13.1748 9 13.4332 9.09745 13.6679 9.33211C13.9025 9.56676 14 9.82523 14 10C14 10.5523 14.4477 11 15 11C15.5523 11 16 10.5523 16 10C16 9.17477 15.5975 8.43324 15.0821 7.91789C14.5668 7.40255 13.8252 7 13 7Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.5173 23.831C7.54575 23.8467 7.57496 23.861 7.6048 23.8738C8.1475 24.1072 8.81743 23.5861 10.1573 22.544L11.4999 21.4998C14.1883 21.5888 16.5139 21.236 18.3119 20.6069C20.3226 19.9033 21.9035 18.3224 22.6071 16.3117C23.5767 13.5408 23.5767 8.45859 22.6071 5.6877C21.9035 3.67698 20.3226 2.09606 18.3119 1.39247C15.1102 0.272164 8.82987 0.293063 5.68794 1.39247C3.67721 2.09606 2.0963 3.67698 1.39271 5.6877C0.423134 8.45859 0.423134 13.5408 1.39271 16.3117C2.0963 18.3224 3.67721 19.9033 5.68794 20.6069C6.0824 20.7449 6.52359 20.8963 6.99988 20.9998C6.99988 22.6972 6.99988 23.5459 7.5173 23.831ZM8.99988 20.9105V19.7907C8.99988 19.5555 8.83588 19.3522 8.60585 19.303C7.84395 19.1401 7.08664 18.9774 6.3485 18.7191C4.91226 18.2166 3.78304 17.0873 3.28048 15.6511C2.89859 14.5597 2.66553 12.8451 2.66553 10.9997C2.66553 9.15429 2.89859 7.43963 3.28048 6.34826C3.78304 4.91203 4.91226 3.7828 6.3485 3.28024C7.61613 2.83668 9.71701 2.56258 11.9797 2.56008C14.2421 2.55758 16.356 2.82699 17.6513 3.28024C19.0875 3.7828 20.2168 4.91203 20.7193 6.34826C21.1012 7.43963 21.3343 9.15429 21.3343 10.9997C21.3343 12.8451 21.1012 14.5597 20.7193 15.6511C20.2168 17.0873 19.0875 18.2166 17.6513 18.7191C15.5196 19.465 13.2589 19.5488 11.0238 19.4826C10.907 19.4792 10.7925 19.5163 10.7003 19.588L8.99988 20.9105Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_235_3037'>
          <rect
            width='24'
            height='24'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
