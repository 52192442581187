import React from 'react';

export function CalendarRecording() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.75 0C6.16421 0 6.5 0.335786 6.5 0.75V2H11.5V0.75C11.5 0.335786 11.8358 0 12.25 0C12.6642 0 13 0.335786 13 0.75V2H15.25C16.7688 2 18 3.23122 18 4.75V15.25C18 16.7688 16.7688 18 15.25 18H2.75C1.23122 18 0 16.7688 0 15.25V4.75C0 3.23122 1.23122 2 2.75 2H5V0.75C5 0.335786 5.33579 0 5.75 0ZM5 3.5H2.75C2.05964 3.5 1.5 4.05964 1.5 4.75V7H16.5V4.75C16.5 4.05964 15.9404 3.5 15.25 3.5H13V4.25C13 4.66421 12.6642 5 12.25 5C11.8358 5 11.5 4.66421 11.5 4.25V3.5H6.5V4.25C6.5 4.66421 6.16421 5 5.75 5C5.33579 5 5 4.66421 5 4.25V3.5ZM16.5 8.5H1.5V15.25C1.5 15.9404 2.05964 16.5 2.75 16.5H15.25C15.9404 16.5 16.5 15.9404 16.5 15.25V8.5Z'
        fill='currentColor'
      />
    </svg>
  );
}
