import { FC } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { BtnsWrap, DropDownsWrap, IconWrap, ModalContent, ModalWrapper } from './TransferCashModal.styled';

import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewDropdown from '../../../../../../shared/new-ui/NewDropdown/ui/NewDropdown';
import { NewInput } from '../../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { useTransferCash } from '../../../../../../store/redux/cash-register/hooks/useCashRegisterMutation';
import { useCashRegisterAllQuery } from '../../../../../../store/redux/cash-register/hooks/useCashRegisterQuery';
import { IModalProps } from '../../../../../../store/redux/modal/modal.interface';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';
import { EIcon, IconNew } from '../../../../../icons/medium-new-icons/icon';
import { getIconByFinanceIcon } from '../../../../../icons/helper-functions/payments-settings.helper';

const TransferCashModal: FC<IModalProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { data: cashRegisters } = useCashRegisterAllQuery();
  const { mutateAsync: transferCash } = useTransferCash();
  const { currCashRegister } = useTypedSelector((state) => state.cashregister);
  const initialValues = {
    kassain: currCashRegister?.id || '',
    kassaout: currCashRegister?.id || '',
    summ: 0
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    enableReinitialize: true
  });

  const handleTransferCash = async () => {
    const res = await transferCash(formik.values);
    if (res.status === 'ok') {
      toast.success('Перевод выполнен успешно');
    } else {
      toast.error(res.description || 'Ошибка во время перевода');
    }
    closeModal();
  };

  return (
    <ModalWrapper $column>
      {cashRegisters && cashRegisters.length > 0 ? (
        <>
          <FlexWithAlign
            $gap='16px'
            $align='center'
          >
            <NewButton
              typeBtn={'close'}
              onClick={() => {
                closeModal();
              }}
            >
              <IconNew name={EIcon.close} />
            </NewButton>
            <NewText
              $tag={TextType.H2}
              fontWeight={600}
              color='title'
            >
              {t('Перевод между кассами')}
            </NewText>
          </FlexWithAlign>
          <ModalContent
            $column
            $gap='40px'
          >
            <DropDownsWrap
              $column
              $align='center'
              $gap='0px'
            >
              <NewDropdown
                options={cashRegisters.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                    iconFinance: getIconByFinanceIcon(item.icon),
                    withIconLabel: 'Касса списания',
                    withIconSum: item.summa
                  };
                })}
                placeholder={'Касса списания'}
                onChange={(option: any) => {
                  formik.setFieldValue('kassaout', option.value);
                }}
                currentValue={
                  cashRegisters
                    .map((item) => {
                      return {
                        label: item.name,
                        value: item.id
                      };
                    })
                    .find((item: any) => item.value === formik.values.kassaout)?.label || formik.values.kassaout
                }
                withIcon={true}
              />

              <IconWrap>
                <IconNew name={EIcon.remittanceicon} />
              </IconWrap>
              <NewDropdown
                options={cashRegisters.map((item) => {
                  return {
                    label: item.name,
                    value: item.id,
                    iconFinance: getIconByFinanceIcon(item.icon),
                    withIconLabel: 'Касса зачисления',
                    withIconSum: item.summa
                  };
                })}
                placeholder={'Касса зачисления'}
                onChange={(option: any) => {
                  formik.setFieldValue('kassain', option.value);
                }}
                currentValue={
                  cashRegisters
                    .map((item) => {
                      return {
                        label: item.name,
                        value: item.id
                      };
                    })
                    .find((item: any) => item.value === formik.values.kassain)?.label || formik.values.kassain
                }
                withIcon={true}
              />
            </DropDownsWrap>
            <NewInput
              name='summ'
              type='currency'
              value={formik.values.summ}
              onChange={formik.handleChange}
              label={'Сумма перевода'}
              className={'transfer-summ-input'}
            />
          </ModalContent>
          <BtnsWrap
            $align='center'
            $gap='16px'
            $justify='flex-end'
          >
            <NewButton onClick={closeModal}>{t('Отмена')}</NewButton>
            <NewButton
              typeBtn='black'
              onClick={handleTransferCash}
              disabled={
                formik.values.kassain === '' ||
                formik.values.kassaout === '' ||
                formik.values.summ <= 0 ||
                formik.values.kassain === formik.values.kassaout
              }
            >
              {t('Сохранить')}
            </NewButton>
          </BtnsWrap>
        </>
      ) : null}
    </ModalWrapper>
  );
};

export default TransferCashModal;
