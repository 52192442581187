import { FC, useEffect, useRef } from 'react';

import { INewCustomMultiCheckBox, MultiValue } from './NewCustomMultiCheckbox.props';
import { CheckBox, CheckboxWrapper, CustomCheckbox } from './NewCustomMultiCheckbox.styled';

import { IconNew, EIcon } from '../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../lib';

const NewCustomMultiCheckbox: FC<INewCustomMultiCheckBox> = (props) => {
  const { name, onChange, value, className, disabled, error, ...rest } = props;

  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = value === 'indeterminate';
    }
  }, [value]);

  const handleClick = () => {
    const newValue: MultiValue =
      value === 'unchecked' ? 'checked' : value === 'checked' ? 'unchecked' : value === 'indeterminate' ? 'checked' : 'unchecked';
    onChange(newValue);
  };

  return (
    <CheckboxWrapper
      onClick={handleClick}
      className={cn(
        className && `${className}`,
        disabled && 'disabled',
        error && 'error',
        value === 'checked' && 'active',
        value === 'indeterminate' && 'indeterminate'
      )}
    >
      <CheckBox
        type={'checkbox'}
        name={name}
        checked={value === 'checked'}
        readOnly
        // onChange={onChange}
        disabled={disabled}
        ref={checkboxRef}
        {...rest}
      />
      <CustomCheckbox>
        <IconNew name={EIcon.checkbox} />
      </CustomCheckbox>
    </CheckboxWrapper>
  );
};

export default NewCustomMultiCheckbox;
