import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TelgramBotService } from '../../../../shared/api/model/service/telegrambot.service';
import { TELEGRAM_BOT_QUERY } from '../../../../shared/const/query.const';
import { ICreateTelegramBot, ITelegramBot, ITelegramBotSpam } from '../telegrambot.interface';

export function useCreateTelegramBot() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: ICreateTelegramBot) => TelgramBotService.createTelegramBot(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [TELEGRAM_BOT_QUERY] });
      }
    }
  });
}

export function useUpdateTelegramBot() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: ITelegramBot) => TelgramBotService.updateTelegramBot(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [TELEGRAM_BOT_QUERY] });
      }
    }
  });
}

export function useSendSpamTelegramBot() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: ITelegramBotSpam) => TelgramBotService.fetchTelegramBotSpam(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [TELEGRAM_BOT_QUERY] });
      }
    }
  });
}

// export function useTelegramBotFollowersQuery(id?: string) {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: () => TelgramBotService.fetchTelegramBotFollowers(id),
//     onSettled: async (_, error) => {
//       if (error) {
//         console.log(error);
//       } else {
//         await queryClient.invalidateQueries({ queryKey: [TELEGRAM_BOT_QUERY] });
//       }
//     }
//   });
// }
