import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useFormik } from 'formik';

import { TextareaWrapper, Wrapper } from './CreateKanbanTaskModal.styled';

import { renameFioToLable } from '../../../../../pages/Recording/lib/helpers/rename.helpers';
import { useTasks } from '../../../../../pages/TaskBookPage/lib/hooks/useTasks';
import { TasksService } from '../../../../../shared/api/model/service/tasks.service';
import { useActions } from '../../../../../shared/lib/hooks/useActions';
import { useOutside } from '../../../../../shared/lib/hooks/useOutside';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { InputType, Text, Textarea, TextPType, TextType } from '../../../../../shared/ui';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import Dropdown from '../../../../../shared/ui/Dropdown/ui/Dropdown';
import { IColumn, ITask } from '../../../../../store/redux/tasks/tasks.interface';
import { MODAL_GAP, MODAL_TEXT_GAP } from '../../../../../styles';
import { FlexWithAlign, FormStyle } from '../../../../../utils/styleUtils';

interface IProps {
  modalPayload?: any;
  closeModal?: () => void;
}

const CreateKanbanTaskModal: React.FC<IProps> = (props) => {
  const { modalPayload, closeModal } = props;
  const { refetch: kanbanRefetch } = useTasks();
  const { user } = useTypedSelector((state1) => state1.user);
  const { fetchUsersAsync } = useActions();
  const { ref, isShow, setIsShow } = useOutside(!!modalPayload.id);
  const { t } = useTranslation();
  const filteredOwner = user && user?.find((item) => item.id === modalPayload?.ownertaskid);

  const fetchUsersInfo = async () => {
    await fetchUsersAsync();
  };
  useEffect(() => {
    fetchUsersInfo();
  }, []);

  const options = renameFioToLable(user);
  const initialValues: Omit<ITask, 'timecreate'> = {
    id: '',
    content: '',
    columnid: modalPayload.columnId,
    employeetaskid: user?.[0].id,
    ownertaskid: user?.[0].id,
    archived: false,
    order: (!modalPayload?.id && modalPayload?.column?.find((item: IColumn) => item.id === modalPayload.columnId)?.task?.length + 1) || 0,
    timeclose: '2006-01-02 15:04:05',
    timeend: '2006-01-02 15:04:05',
    verify: false,
    clientid: ''
  };
  const formik = useFormik({
    initialValues: modalPayload.id
      ? {
          content: modalPayload.content,
          employeetaskid: modalPayload.employeetaskid,
          ownertaskid: modalPayload.ownertaskid,
          order: modalPayload.order
        }
      : initialValues,
    onSubmit: (values: any) => {}
  });

  const { mutate: handleSubmit } = useMutation({
    mutationFn: () => TasksService.updateTask({ data: formik.values, taskId: modalPayload.id }),
    onSuccess: () => {
      kanbanRefetch();
      closeModal!();
      toast.success('Задача изменена успешно');
      setIsShow(false);
    },
    onError: () => toast.error('Произошла ошибка при обновлении задачи')
  });
  // const handleSubmit = async () => {
  //   setPending(true);
  //   try {
  //     if (modalPayload.id) {
  //       await apiPost(`/tasker/task/${modalPayload.id}`, formik.values);
  //       await fetchTasksAsync();
  //     } else {
  //       await apiPut('/tasker/task', formik.values);
  //       await fetchTasksAsync();
  //     }
  //
  //     closeModal!();
  //     setIsShow(false);
  //   } catch (error: any) {
  //     toast.error(error.message || 'Произошла ошибка');
  //   }
  // };
  useEffect(() => {
    if (!isShow) {
      handleSubmit();
    }
  }, [isShow]);

  return (
    <FlexWithAlign
      $column
      $gap={MODAL_GAP}
      ref={ref}
    >
      <Text $tag={TextType.H5}>{t('Редактирование задачи')}</Text>
      <Wrapper>
        <FormStyle onSubmit={(e) => e.preventDefault()}>
          <FlexWithAlign
            $gap={MODAL_TEXT_GAP}
            $column
          >
            <Text
              $tag={TextType.P}
              $pSize={TextPType.P17}
              color={'text02'}
            >
              {t('Исполнитель')}
            </Text>
            <Dropdown
              size={InputType.M}
              currentValue={formik.values.employeetaskid}
              labelTitle={'Исполнитель'}
              options={options}
              onChange={(option: any) => {
                formik.setFieldValue('employeetaskid', option.value);
              }}
            />
          </FlexWithAlign>
          <FlexWithAlign
            $gap={MODAL_TEXT_GAP}
            $column
          >
            <Text
              $tag={TextType.P}
              $pSize={TextPType.P17}
              color={'text02'}
            >
              {t('Описание')}
            </Text>
            <TextareaWrapper>
              <Textarea
                placeholder={t('Введите описание задачи')}
                name={'content'}
                content={formik.values.content}
                onChange={formik.handleChange}
              />
            </TextareaWrapper>
          </FlexWithAlign>
          <FlexWithAlign
            $column
            $gap={MODAL_TEXT_GAP}
          >
            <CommonButton
              fullWidth
              size={'M'}
              onClick={handleSubmit}
              typeBtn={'primary'}
            >
              {t('Сохранить')}
            </CommonButton>
            <CommonButton
              fullWidth
              size={'M'}
              typeBtn={'gray'}
              onClick={closeModal}
            >
              {t('Отменить')}
            </CommonButton>
            <FlexWithAlign
              $column
              $gap={'0'}
            >
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P13}
              >
                {filteredOwner?.fio}
              </Text>
              <FlexWithAlign
                $align={'center'}
                $gap={'2px'}
              >
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P13}
                  color={'text02'}
                >
                  {t('создал(а) эту задачу -')}
                </Text>
                <Text
                  $tag={TextType.P}
                  $pSize={TextPType.P13}
                >
                  {dayjs(modalPayload?.timecreate).utcOffset(0).format('DD.MM.YYYY HH:mm')}
                </Text>
              </FlexWithAlign>
            </FlexWithAlign>
          </FlexWithAlign>
        </FormStyle>
      </Wrapper>
    </FlexWithAlign>
  );
};

export default CreateKanbanTaskModal;
