import React from 'react';

export function RecordingSquareLine() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 15.375V19C21 20.1046 20.1046 21 19 21H15.375M21 8.625V5C21 3.89543 20.1046 3 19 3H15.375M8.625 21H5C3.89543 21 3 20.1046 3 19V15.375M3 8.625V5C3 3.89543 3.89543 3 5 3H8.625'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3 12H21'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
