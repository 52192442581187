import { Link } from 'react-router-dom';

import styled from 'styled-components';

import themelight from '../../../../app/providers/ThemeProvider/ui/theme';
import { DURATION } from '../../../../styles';

export const TabsWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${themelight.newColor.base08};
`;

export const TabWrapper = styled.div`
  position: relative;
  padding: 14.5px 0 15.5px;
  margin: 0 16px;
`;

export const TabLink = styled(Link)`
  font-size: 12px;
  text-transform: uppercase;
  line-height: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.newColor.grey03};

  &:before {
    content: '';
    width: 100%;
    height: 2px;
    background: transparent;
    left: 0;
    bottom: 0;
    position: absolute;
    transition: background ${DURATION} ease-in-out;
  }
  &.active {
    color: ${(props) => props.theme.text.text05};
    &:before {
      background: ${(props) => props.theme.text.text05};
    }
  }
`;
