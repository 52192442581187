import styled from 'styled-components';

export const WrapperCalendar = styled.div`
  /* ~~~ container styles ~~~ */
  /* ... */
  .react-calendar {
    background: ${(props) => props.theme.color.base01};
    max-width: 210px;
    min-width: 210px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05), 0px -4px 8px 0px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
  }
  .first-last-day {
    background-color: blue; /* Синий цвет для первого и последнего дня */
  }
  .other-days {
    background-color: lightblue; /* Голубой цвет для промежуточных дней */
  }
  /* ~~~ navigation styles ~~~ */
  .react-calendar__navigation {
    display: flex;
    padding: 16px;
    line-height: normal;
    align-items: center;
    justify-content: center;
    .react-calendar__navigation__label {
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.00706rem;
      width: fit-content;
    }
    abbr {
      text-decoration: none;
      text-align: center;
    }
    .react-calendar__navigation__arrow {
      display: flex;
      > svg {
        color: ${(props) => props.theme.color.accent};
      }
    }
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
  }
  .react-calendar__viewContainer {
    padding: 16px;
    border-top: none;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    .react-calendar__month-view__weekdays {
      margin-bottom: 16px;
      abbr {
        font-size: 10px;
        font-weight: 600;
        line-height: 14px;
        text-transform: uppercase;
        text-decoration: none;
        color: ${(props) => props.theme.text.text01};
        text-align: center;
      }
    }
    .react-calendar__month-view__weekdays__weekday {
      display: flex;
      justify-content: center;
      padding: 4px 0;
    }
    .react-calendar__month-view__days {
      row-gap: 0px;
    }
    .react-calendar__month-view__days__day {
      display: flex;
      justify-content: center;
      font-size: 10px;
      align-items: center;
      line-height: normal;
      text-transform: uppercase;
      max-width: 25px;
      min-width: 25px;
      max-height: 20px;
      min-height: 20px;
      abbr {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px;
        max-width: 20px;
        min-width: 20px;
        max-height: 20px;
        min-height: 20px;
        border-radius: 50%;
        border: 1px solid transparent;
      }
      &.selected {
        background-color: ${(props) => props.theme.status.infoBgHover};
      }
    }
    .react-calendar__tile--active {
      abbr {
        background-color: ${(props) => props.theme.newColor.black03};
        border: 1px solid ${(props) => props.theme.newColor.black03};
        border-radius: 50%;
        color: ${(props) => props.theme.newColor.base01};
      }
    }
    .react-calendar__tile--now {
      abbr {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 50%;
        color: ${(props) => props.theme.newColor.activeDate};
      }
      &.react-calendar__tile--active {
        abbr {
          color: ${(props) => props.theme.newColor.activeDateSecondary};
          background-color: ${(props) => props.theme.newColor.activeDateBg};
          border-color: ${(props) => props.theme.newColor.activeDateBg};
        }
      }
    }
    .react-calendar__year-view__months__month {
      padding: 6px 0;
      abbr {
        font-size: 14px;
        width: 100%;
        padding: 3px;
        height: 100%;
      }
      &.month {
        background-color: ${(props) => props.theme.newColor.activeDateBg};
        border: 1px solid ${(props) => props.theme.newColor.activeDateBg};
        color: #fff;
        border-radius: 8px;
      }
    }

    .react-calendar__tile--active.first-half {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background: ${(props) => props.theme.status.infoBgHover};
      }
      &:after {
        content: '';
        position: absolute;
        top: 0px;
        width: 65%;
        height: 100%;
        opacity: 0;
        background: ${(props) => props.theme.color.primary};
        right: -28px;
        transform: translate(-1.6rem) scaleY(0.83) scaleX(0.5) rotate(45deg);
      }
      abbr {
        z-index: 4;
        right: 0;
        position: relative;
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
      }
    }

    .react-calendar__tile--active.second-half {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 100%;
        background: ${(props) => props.theme.status.infoBgHover};
      }
      // &:after {
      //   content: '';
      //   position: absolute;
      //   top: 0px;
      //   width: 65%;
      //   height: 100%;
      //   background: ${(props) => props.theme.color.primary};
      //   left: 24px;
      //   transform: translate(-1.6rem) scaleY(0.83) scaleX(0.5) rotate(45deg);
      // }
      abbr {
        z-index: 4;
        right: 0;
        position: relative;
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
      }
    }
  }
`;
