import { ICabinet } from '../../../../store/redux/cabinets/cabinets.interface';
import { IClient } from '../../../../store/redux/clients/clients.interface';
import { ILesson } from '../../../../store/redux/lessons/lessons.interface';
import { IService } from '../../../../store/redux/subproducts/subproducts.interface';
import { IUser } from '../../../../store/redux/user/user.interface';

export const renameEmployedIdToResourceId = (resource: any) => {
  return resource.map((event: any) => {
    const resourceIds = Array.isArray(event?.anyemployedid)
      ? event.anyemployedid.map((idObject: any) => idObject.idemployed)
      : [event?.employedid];

    return {
      ...event,
      resourceIds
    };
  });
};

export const renameFioToTitle = (resource: IUser[] | undefined) => {
  return resource?.map((resource) => ({
    ...resource,
    title: resource?.fio
  }));
};

export const recordingEventType = ({
  isValue,
  renameEvents,
  clients,
  serviceObj
}: {
  renameEvents: ILesson[] | undefined;
  isValue?: string;
  clients?: IClient[];
  serviceObj?: (IService | undefined)[];
}) => {
  const searchClients = renameEvents?.filter((event: any) => {
    if (isValue !== '') {
      const matchingClients =
        clients &&
        isValue &&
        event.ClientId?.filter((client: any) =>
          clients.some((c: any) => c.id === client.idclient && c.name.toLowerCase().includes(isValue.toLowerCase()))
        );

      return matchingClients?.length > 0;
    }

    return event;
  });
  const onlySingle = renameEvents?.filter((item: any) => {
    const sas = serviceObj && serviceObj.filter((itemTwo) => itemTwo?.id === item.anysubproductid?.[0].idsubproduct);

    return sas && sas.some((item) => item?.group === 'no');
  });
  const onlyGroup = renameEvents?.filter((item: any) => {
    const sas = serviceObj && serviceObj.filter((itemTwo) => itemTwo?.id === item.anysubproductid?.[0].idsubproduct);

    return sas && sas.some((item) => item?.group === 'yes');
  });
  return { searchClients, onlySingle, onlyGroup };
};

export const renameFioToLable = (resource: any[]) => {
  return resource?.map((resource) => ({
    ...resource,
    label: resource?.fio,
    value: resource?.id
  }));
};

export const renameNameToTitle = (resources: ICabinet[] | undefined) => {
  return resources?.map((resource) => ({
    ...resource,
    title: resource?.name
  }));
};
