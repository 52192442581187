import { useFormik } from 'formik';
import { FC } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewDropdown from '../../../../../shared/new-ui/NewDropdown/ui/NewDropdown';
import { NewInput } from '../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { Loader } from '../../../../../shared/ui';
import { useCashRegisterAllQuery } from '../../../../../store/redux/cash-register/hooks/useCashRegisterQuery';
import { useCreatePayment } from '../../../../../store/redux/payments/hooks/usePaymentsMutation';
import { usePaymentsAllQuery } from '../../../../../store/redux/payments/hooks/usePaymentsQuery';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { IconNew, EIcon } from '../../../../icons/medium-new-icons/icon';
import { BtnsWrap, Form, Wrapper } from './CreatePaymentModal.styled';

interface IProps {
  closeModal: () => void;
  modalPayload?: any;
}

const CreatePaymentModal: FC<IProps> = ({ closeModal, modalPayload }) => {
  const { mutateAsync: createPayment } = useCreatePayment();
  const { data: cashRegisters, isLoading } = useCashRegisterAllQuery();
  const { data: payments, isLoading: isPaymentsLoading } = usePaymentsAllQuery();
  const { t } = useTranslation();
  const initialValues = {
    kassa:
      cashRegisters &&
      payments &&
      cashRegisters.filter((cashRegister) => !payments || payments.filter((payment) => payment.kassa === cashRegister.id).length < 3)
        .length > 0
        ? cashRegisters.filter((cashRegister) => !payments || payments.filter((payment) => payment.kassa === cashRegister.id).length < 3)[0]
            .id
        : '',
    name: modalPayload && modalPayload?.name ? modalPayload?.name : '' || ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    enableReinitialize: true
  });

  const handleCreate = async () => {
    const res = await createPayment(formik.values);
    if (res.status === 'ok') {
      toast.success('Способ оплаты создан');
    } else {
      toast.error(res.description);
    }
    closeModal();
  };
  return (
    <Wrapper $column>
      {isLoading || isPaymentsLoading ? (
        <Loader />
      ) : (
        <>
          <FlexWithAlign
            $gap='16px'
            $align='center'
          >
            <NewButton
              typeBtn='close'
              onClick={closeModal}
            >
              <IconNew name={EIcon.close} />
            </NewButton>
            <NewText
              $tag={TextType.H2}
              color='title'
              fontWeight={600}
            >
              {modalPayload && modalPayload?.name ? t('Изменить способ оплаты') : t('Добавить способ оплаты')}
            </NewText>
          </FlexWithAlign>
          <Form
            action='/'
            onSubmit={(e) => e.preventDefault()}
          >
            <NewInput
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={'Укажите название'}
            />
            {cashRegisters && cashRegisters.length > 0 ? (
              <NewDropdown
                options={cashRegisters
                  .filter((cashRegister) => !payments || payments.filter((payment) => payment.kassa === cashRegister.id).length < 3)
                  .map((item) => {
                    return {
                      label: item.name,
                      value: item.id
                    };
                  })}
                onChange={(option: any) => {
                  formik.setFieldValue('kassa', option.value);
                }}
                currentValue={
                  cashRegisters
                    .map((item) => {
                      return {
                        label: item.name,
                        value: item.id
                      };
                    })
                    .find((item: any) => item.value === formik.values.kassa)?.label || formik.values.kassa
                }
                labelTitle='Связанная касса для поступлений'
              />
            ) : (
              <NewText>{t('Для добавления способа оплаты создайте кассу')}</NewText>
            )}
          </Form>
          <BtnsWrap $justify='flex-end'>
            <NewButton
              type='button'
              onClick={closeModal}
            >
              {t('Отмена')}
            </NewButton>
            <NewButton
              type='button'
              typeBtn='black'
              disabled={formik.values.name.trim() === ''}
              onClick={handleCreate}
            >
              {t('Сохранить')}
            </NewButton>
          </BtnsWrap>
        </>
      )}
    </Wrapper>
  );
};

export default CreatePaymentModal;
