import React, { SyntheticEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { Wrapper } from './CreateServiceModal.styled';

import { ModalGrid } from '../../../../../pages/Recording/lib/CommonRecording.styled';
import { cn } from '../../../../../shared/lib';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import { Input, InputType, MultiSelect, PositionType, Text, TextPType, TextType } from '../../../../../shared/ui';
import DinamycHeight from '../../../../../shared/ui/AutoHeight/DinamycHeight';
import CommonButton from '../../../../../shared/ui/Button/ui/CommonButton';
import Dropdown from '../../../../../shared/ui/Dropdown/ui/Dropdown';
import { ICabinet } from '../../../../../store/redux/cabinets/cabinets.interface';
import { useCabinetsQuery } from '../../../../../store/redux/cabinets/hooks/useCabinetsQuery';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { useProductsIdsQuery, useProductsQuery } from '../../../../../store/redux/products/hooks/useProductsQuery';
import { IProduct } from '../../../../../store/redux/products/products.interface';
import { useSubproductAllQuery } from '../../../../../store/redux/subproducts/hooks/useSubproductQuery';
import { useCreateService, useUpdateService } from '../../../../../store/redux/subproducts/hooks/useSubproductsMutation';
import { ISubproductCabinets, ISubproductEmployee } from '../../../../../store/redux/subproducts/subproducts.interface';
import { useUsersQuery } from '../../../../../store/redux/user/hooks/useUsersQuery';
import { IUser } from '../../../../../store/redux/user/user.interface';
import { FlexWithAlign, FormStyle } from '../../../../../utils/styleUtils';
import { validationServiceWithGroupSchema } from '../../../../../utils/validation-input';
import { EIcon, IconNew as IconInstance } from '../../../../icons/medium-new-icons/icon';
import { CustomCheckboxWrap } from '../employeers/CreateEmployeersModal.styled';
import { Calculate } from '../recording/single/client-info/RecordingModalSide.styled';

interface IProps {
  closeModal?: () => void;
  edit?: boolean;
  modalPayload?: any;
  allCategories?: boolean;
}

const CreateServiceModal: React.FC<IProps> = (props) => {
  const { closeModal, edit, modalPayload, allCategories } = props;

  const { data: products } = useProductsQuery();
  const { data: ids } = useProductsIdsQuery();
  const { data: user, isFetching: isUserFetching } = useUsersQuery();
  const { data: services } = useSubproductAllQuery(ids);
  const { data: filials } = useFilialQuery();
  const { data: cabinets } = useCabinetsQuery(filials?.id);
  const { mutateAsync: createService } = useCreateService();
  const { mutateAsync: updateService, mutate: updateServiceSync } = useUpdateService();

  const { serviceCategoryId, service } = modalPayload;
  const currentServiceCategory = products?.find((item) => item.id === serviceCategoryId);
  const [transformedCategories, setTransformedCategories] = useState<any[]>();
  const [nameError, setNameError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);

  const [durationError, setDurationError] = useState(false);
  const [tarifError, setTarifError] = useState(false);

  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const [selectedEmployees, setSelectedEmployees] = useState<(Pick<IUser, 'id' | 'fio'> | undefined)[]>([]);
  const [selectedCabinets, setSelectedCabinets] = useState<(Pick<ICabinet, 'id' | 'name'> | undefined)[]>([]);

  const { t } = useTranslation();

  const [pending, setPending] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const initialValues = {
    name: '',
    comment: '',
    duration: '',
    tarif: '',
    // category: modalPayload && modalPayload.products ? modalPayload.products[0].id : currentServiceCategory?.name,
    category: modalPayload && modalPayload.products ? undefined : currentServiceCategory?.name,
    programmservice: 'no',
    lessonnumber: 0,
    group: 'no',
    seatsmin: 2,
    seatsmax: 5,
    employee: '',
    cabinets: ''
  };
  const formik = useFormik({
    initialValues: service
      ? {
          ...service,
          category: currentServiceCategory?.name,
          lessonnumber: service.lessonnumber || 0,
          employee: '',
          cabinets: ''
        }
      : initialValues,
    onSubmit: () => {},
    // validateOnMount: true,
    validateOnChange: true,
    validationSchema: validationServiceWithGroupSchema
  });
  useEffect(() => {
    if (formik.values.group === 'no') {
      const { seatsmin, seatsmax, ...newValues } = formik.values;
      formik.setValues({ ...formik.values, seatsmin: 2, seatsmax: 5 });
    } else {
      formik.setValues({ ...formik.values });
    }
  }, [formik.values.group]);

  useEffect(() => {
    if (!modalPayload.service && user) {
      setSelectedEmployees(user.filter((emp) => emp.services));
    }
    if (!modalPayload.service && cabinets) {
      setSelectedCabinets(cabinets);
    }
    if (modalPayload && modalPayload.service) {
      const { service } = modalPayload;
      if (service.employee && user) {
        setSelectedEmployees(
          user?.filter(
            (item) => service.employee.findIndex((itemEmployee: ISubproductEmployee) => itemEmployee.idemployee === item.id) > -1
          )
        );
      } else {
        setSelectedEmployees([]);
      }

      if (service.cabinets && cabinets) {
        setSelectedCabinets(
          cabinets.filter(
            (item) => service.cabinets.findIndex((itemCabinet: ISubproductCabinets) => itemCabinet.idcabinets === item.id) > -1
          )
        );
      } else {
        setSelectedCabinets([]);
      }
    }
  }, [user, cabinets]);
  useEffect(() => {
    if (modalPayload && allCategories) {
      setTransformedCategories(
        modalPayload.products.map((product: IProduct) => {
          return {
            value: product.id,
            label: product.name
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    if (modalPayload && modalPayload.service && !isUserFetching && user) {
      const { service } = modalPayload;
      if (!service.employee.every((item: ISubproductEmployee) => user.find((emp) => emp?.id === item.idemployee))) {
        const resultEmployees = user
          ?.filter((item) => service.employee.findIndex((itemEmployee: ISubproductEmployee) => itemEmployee.idemployee === item.id) > -1)
          .map((filterItem) => {
            return {
              id: filterItem.id
            };
          });
        if (resultEmployees.every((item) => item !== undefined)) {
          updateServiceSync({
            ...service,
            id: service.id,
            idparents: serviceCategoryId,
            employee: resultEmployees.map((item) => {
              return {
                idemployee: item?.id
              };
            })
          });
        }
      }
    }
  }, [isUserFetching]);
  const addService = async (values: any) => {
    setPending(true);
    const productId = serviceCategoryId || values.category;
    if (formik.values.group === 'no') {
      const { seatsmin, seatsmax, ...dataToSend } = values;

      const res = await createService({
        ...dataToSend,
        id: productId,
        duration: String(values.duration),
        tarif: String(values.tarif),
        employee: selectedEmployees.map((item) => {
          return {
            idemployee: item?.id
          };
        }),
        cabinets: selectedCabinets.map((item) => {
          return {
            idcabinets: item?.id
          };
        })
      });

      if (res?.status === 'ok') {
        closeModal?.();
        toast.success(t('Услуга успешно добавлена'));
      } else {
        setPending(false);
        toast.error(res.description);
      }
    } else if (formik.values.group === 'yes') {
      const res = await createService({
        ...values,
        id: productId,
        employee: selectedEmployees.map((item) => {
          return {
            idemployee: item?.id
          };
        }),
        cabinets: selectedCabinets.map((item) => {
          return {
            idcabinets: item?.id
          };
        }),
        duration: String(values.duration),
        tarif: String(values.tarif)
      });
      if (res?.status === 'ok') {
        // getSubproductsAll(products);
        closeModal?.();
        toast.success(t('Услуга успешно добавлена'));
      } else {
        setPending(false);
        toast.error(res.description);
      }
    }
  };

  const editService = async (values: any) => {
    setPending(true);

    if (formik.values.group === 'no') {
      const { seatsmin, seatsmax, ...dataToSend } = values;
      const res = await updateService({
        ...dataToSend,
        id: values.id,
        idparents: serviceCategoryId,
        employee: selectedEmployees.map((item) => {
          return {
            idemployee: item?.id
          };
        }),
        cabinets: selectedCabinets.map((item) => {
          return {
            idcabinets: item?.id
          };
        }),
        duration: String(values.duration),
        tarif: String(values.tarif)
      });

      if (res?.status === 'ok') {
        closeModal?.();
        toast.success(t('Услуга успешно обновлена'));
      } else {
        setPending(false);
        toast.error(res.description);
      }
    } else if (formik.values.group === 'yes') {
      const res = await updateService({
        ...values,
        id: values.id,
        idparents: serviceCategoryId,
        employee: selectedEmployees.map((item) => {
          return {
            idemployee: item?.id
          };
        }),
        cabinets: selectedCabinets.map((item) => {
          return {
            idcabinets: item?.id
          };
        }),
        duration: String(values.duration),
        tarif: String(values.tarif)
      });
      if (res?.status === 'ok') {
        // getSubproductsAll(products);
        closeModal?.();
        toast.success(t('Услуга успешно обновлена'));
      } else {
        setPending(false);
        toast.error(res.description);
      }
    }
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (
      !formik.isValid ||
      formik.values.name === '' ||
      formik.values.duration === '' ||
      formik.values.tarif === '' ||
      !formik.values.category ||
      pending
    ) {
      if (formik.values.name === '') {
        setNameError(true);
      }
      if (formik.values.duration === '') {
        setDurationError(true);
      }
      if (formik.values.tarif === '') {
        setTarifError(true);
      }
      if (formik.values.tarif === '') {
        setTarifError(true);
      }
      if (!formik.values.category) {
        setCategoryError(true);
      }

      return;
    }
    if (edit) {
      editService(formik.values);
    } else {
      addService(formik.values);
    }
  };

  useEffect(() => {
    if (nameError) {
      formik.values.name !== '' && setNameError(false);
    }
  }, [formik.values.name]);
  useEffect(() => {
    if (categoryError) {
      formik.values.category !== '' && setCategoryError(false);
    }
  }, [formik.values.category]);
  useEffect(() => {
    if (durationError) {
      formik.values.duration !== '' && setDurationError(false);
    }
  }, [formik.values.duration]);
  useEffect(() => {
    if (tarifError) {
      formik.values.tarif !== '' && setTarifError(false);
    }
  }, [formik.values.tarif]);

  return (
    <Wrapper>
      {isMobile ? null : (
        <Text
          $tag={TextType.H5}
          fontWeight={700}
        >
          {!edit ? t('Добавление услуги') : `${t('Редактирование услуги')}`}
        </Text>
      )}
      <FormStyle
        className='form'
        onSubmit={handleSubmit}
      >
        {allCategories && transformedCategories ? (
          <Dropdown
            size={InputType.M}
            onChange={(option: any) => {
              formik.setFieldValue('category', option.value);
            }}
            labelTitle={t('Название категории услуг')}
            className={'noChange'}
            options={transformedCategories}
            currentValue={transformedCategories.find((item) => item.value === formik.values.category)?.label}
            formik={formik}
            error={categoryError && 'Выберите категорию'}
          ></Dropdown>
        ) : (
          <Input
            label={t('Название категории услуг')}
            name='category'
            size={InputType.M}
            value={formik.values.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && formik.errors.email}
            disabled
          >
          </Input>
        )}

        <Input
          label={t('Название услуги*')}
          value={formik.values.name}
          onChange={formik.handleChange}
          name='name'
          size={InputType.M}
          onBlur={formik.handleBlur}
          error={(formik.touched.name && formik.errors.name) || (nameError && 'Введите название услуги')}
        ></Input>

        <FlexWithAlign>
          <CustomCheckboxWrap
            name='group'
            onClick={(e) => {
              e.preventDefault();
              formik.setFieldValue('group', 'no');
            }}
          >
            <div className={cn('check-wrapper', formik.values.group === 'no' && 'active')}>
              <span></span>
            </div>
            <FlexWithAlign
              $column
              $align={'flex-start'}
              $gap={'0'}
            >
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P11}
                color={'text02'}
              >
                {t('Тип услуги')}
              </Text>
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P13}
              >
                {t('Индивидуальная')}
              </Text>
            </FlexWithAlign>
          </CustomCheckboxWrap>
          <CustomCheckboxWrap
            onClick={(e) => {
              e.preventDefault();
              formik.setFieldValue('group', 'yes');
            }}
          >
            <div className={cn('check-wrapper', formik.values.group === 'yes' && 'active')}>
              <span></span>
            </div>
            <FlexWithAlign
              $column
              $align={'flex-start'}
              $gap={'0'}
            >
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P11}
                color={'text02'}
              >
                {t('Тип услуги')}
              </Text>
              <Text
                $tag={TextType.P}
                $pSize={TextPType.P13}
              >
                {t('Групповая')}
              </Text>
            </FlexWithAlign>
          </CustomCheckboxWrap>
        </FlexWithAlign>
        <DinamycHeight>
          <div>
            {formik.values.group === 'yes' && (
              <FlexWithAlign
                $column
                $gap={'10px'}
              >
                <ModalGrid className='hiddenRecordint'>
                  <div>
                    <Input
                      size={InputType.M}
                      label={isMobile ? t('Мин. кол-во человек') : t('Минимальное кол-во человек')}
                      value={formik.values.seatsmin}
                      name='seatsmin'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.seatsmin && formik.errors.seatsmin}
                      type='number'
                      min='2'
                    />
                  </div>
                  <div>
                    <Input
                      size={InputType.M}
                      label={isMobile ? t('Макс. кол-во человек') : t('Максимальное кол-во человек')}
                      value={formik.values.seatsmax}
                      name='seatsmax'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.seatsmax && formik.errors.seatsmax}
                      type='number'
                      min='2'
                    />
                  </div>
                </ModalGrid>
              </FlexWithAlign>
            )}
          </div>
        </DinamycHeight>
        {(formik.values.programmservice === 'yes' || isOpen) && (
          <ModalGrid>
            <Calculate className='subproductCalculate'>
              <span className='slotContent'>{formik.values.lessonnumber === 0 ? t('Количество уроков') : formik.values.lessonnumber}</span>
              <FlexWithAlign>
                <button
                  className={cn(formik.values.lessonnumber === 0 && 'hidden')}
                  onClick={(e: any) => {
                    e.preventDefault();
                    if (formik.values.lessonnumber > 1) {
                      formik.setFieldValue('lessonnumber', formik.values.lessonnumber - 1);
                    }
                  }}
                >
                  <IconInstance name={EIcon.minus} />
                </button>

                <button
                  onClick={(e: any) => {
                    e.preventDefault();
                    formik.setFieldValue('lessonnumber', formik.values.lessonnumber + 1);
                  }}
                >
                  <IconInstance name={EIcon.plus} />
                </button>
              </FlexWithAlign>
            </Calculate>
          </ModalGrid>
        )}

        <Input
          label={t('Длительность услуги(мин)*')}
          name='duration'
          min='0'
          size={InputType.M}
          max='1440'
          type='number'
          value={formik.values.duration}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={(formik.touched.duration && formik.errors.duration) || (durationError && 'Введите длительность услуги')}
        />

        <Input
          label={t('Цена услуги*')}
          name='tarif'
          size={InputType.M}
          type='currency'
          value={formik.values.tarif}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={(formik.touched.tarif && formik.errors.tarif) || (tarifError && 'Введите цену услуги')}
        />

        {user && (
          <MultiSelect
            optionPosition={PositionType.TOP}
            options={user.filter((emp) => emp.services)}
            size={InputType.M}
            value={selectedEmployees}
            setValue={setSelectedEmployees}
            placeholder={t('Сотрудники')}
          />
        )}
        {cabinets && (
          <MultiSelect
            optionPosition={PositionType.TOP}
            options={cabinets}
            size={InputType.M}
            value={selectedCabinets}
            setValue={setSelectedCabinets}
            placeholder={t('Кабинеты')}
          />
        )}

        <FlexWithAlign
          $align='center'
          $justify='flex-start'
          className={cn(isMobile && 'column-reverse')}
        >
          <CommonButton
            onClick={(e: any) => {
              e.preventDefault();
              closeModal!();
            }}
            typeBtn='gray'
            fullWidth={isMobile}
            size={'M'}
          >
            {'Отменить'}
          </CommonButton>
          <CommonButton
            typeBtn='primary'
            size={'M'}
            type='submit'
            fullWidth={isMobile}
          >
            <span>{t('Сохранить')}</span>
          </CommonButton>
        </FlexWithAlign>
      </FormStyle>
    </Wrapper>
  );
};

export default CreateServiceModal;
