import React from 'react';

export function GListLi() {
  return (
   
    <svg
      width='72'
      height='48'
      viewBox='0 0 72 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect
        width='48'
        height='48'
        fill='url(#pattern0_3949_15951)'
      />
      <rect
        width='24'
        height='48'
        transform='translate(48)'
        fill='url(#pattern1_3949_15951)'
      />
      <defs>
        <pattern
          id='pattern0_3949_15951'
          patternContentUnits='objectBoundingBox'
          width='1'
          height='1'
        >
          <use
            xlinkHref='#image0_3949_15951'
            transform='translate(-3) scale(0.0208333)'
          />
        </pattern>
        <pattern
          id='pattern1_3949_15951'
          patternContentUnits='objectBoundingBox'
          width='1'
          height='1'
        >
          <use
            xlinkHref='#image0_3949_15951'
            transform='matrix(0.0416667 0 0 0.0208333 -5 0)'
          />
        </pattern>
        <image
          id='image0_3949_15951'
          width='480'
          height='48'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAeAAAAAwCAMAAAD+Q1k8AAAAPFBMVEUAAAD////r6+v7+/v5+fn09PT9/f329vbw8PDt7e34+PhxcXGSkpKQkJClpaW0tLR+fn7k5OS1tbWurq5MmgWpAAAAAXRSTlMAQObYZgAAAelJREFUeNrtm8tuwjAQRZt5OQ8ILfz/v5YAotQ8jEUW9vieTTZX0WhOlFjx+OsZ9JUizmdS2P2bA4KdA8HOgWDnQLBzINg5EOwcCHYOBDunWsFmfMWsvvxzIPiIMcsNzFZX/hUQfGrnGIJeCGEUZqsn/xoINpag/UBXhl6DsNWST9C84KWfPalYd8FEqV86Wkc+RfOCWcKg3P2DdQjCdeRTtC7YOPTa3aF9YKshn6R1wSxK3N3BpMI15JM0Lth47LV7gPYjW/n5NK0LljBI9wAZglj5+TSNC2YJZN0DjIJw+fk0zQtW6h5CKlx+Pg0E/zU0TFNICYjzEFyP4HHabKYxauhq+e/t+br9fi9/2J2vuwMEryN4nI4KttMYCVsrr9NmuWwmfS8v8+7kd5aqBX/AJ/fPFUBXfn5S+fQDkb7/As+7xS/jFZ1B5is0N7/uJ4Dn/X5mfINfUtQqOndRxvPMWGRVJBir6PXBj47GBZf26xG/KjPBZkPjgovb/sN2YR7Y8G9dcHEjOBjZyQJDd80LLm4MFmOzOWQOvlPmYDph8L0CwTdHRSjzaAnh6EoVgq+HvSjzcBjh8Fklgp3UH4MGOas/Bg1yVn8MGuSs/hg0yFn9MWiQs/pj0CBn9cegQc7qj0GDnNW/Fr9bsifdEM47egAAAABJRU5ErkJggg=='
        />
      </defs>
    </svg>
  );
}
