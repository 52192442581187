export const ArrowRightBot = () => {
  return (
    <svg
      width='14'
      height='13'
      viewBox='0 0 14 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.06571 0.840201C7.12138 0.784422 7.1875 0.740156 7.26028 0.709934C7.33307 0.679712 7.41109 0.664125 7.4899 0.664063C7.56871 0.664001 7.64676 0.679465 7.71959 0.709573C7.79242 0.739681 7.85861 0.783842 7.91437 0.839535L13.159 6.07553C13.2149 6.13127 13.2592 6.19746 13.2894 6.27033C13.3196 6.3432 13.3352 6.42131 13.3352 6.5002C13.3352 6.57909 13.3196 6.65721 13.2894 6.73007C13.2592 6.80294 13.2149 6.86914 13.159 6.92487L7.91437 12.1609C7.80103 12.2694 7.6497 12.3292 7.4928 12.3274C7.33591 12.3257 7.18594 12.2625 7.07503 12.1515C6.96413 12.0406 6.90112 11.8905 6.8995 11.7336C6.89788 11.5767 6.95778 11.4255 7.06637 11.3122L11.285 7.1002H1.26504C1.18625 7.1002 1.10822 7.08468 1.03543 7.05453C0.962634 7.02438 0.89649 6.98018 0.840775 6.92447C0.78506 6.86875 0.740864 6.80261 0.710711 6.72981C0.680558 6.65702 0.665039 6.57899 0.665039 6.5002C0.665039 6.42141 0.680558 6.34339 0.710711 6.27059C0.740864 6.1978 0.78506 6.13165 0.840775 6.07594C0.89649 6.02022 0.962634 5.97603 1.03543 5.94587C1.10822 5.91572 1.18625 5.9002 1.26504 5.9002H11.285L7.06637 1.68887C7.01059 1.63319 6.96633 1.56708 6.93611 1.49429C6.90588 1.42151 6.8903 1.34348 6.89023 1.26467C6.89017 1.18586 6.90564 1.10781 6.93574 1.03498C6.96585 0.96215 7.01001 0.895963 7.06571 0.840201Z'
        fill='#737373'
      />
    </svg>
  );
};
