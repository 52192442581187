export const PaymentIcon8 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24635)'>
        <path
          d='M18.5198 13.125C14.1712 13.1246 9.98284 14.7665 6.79318 17.7223C3.60352 20.678 1.64789 24.7294 1.31778 29.0655C1.3054 29.2328 1.32767 29.4009 1.3832 29.5592C1.43872 29.7175 1.52632 29.8626 1.64049 29.9855C1.75467 30.1084 1.89298 30.2064 2.04677 30.2735C2.20056 30.3405 2.36652 30.375 2.53428 30.375H34.1993C34.4082 30.3749 34.6148 30.3312 34.8059 30.2466C34.9969 30.162 35.1682 30.0384 35.3087 29.8838C35.4492 29.7291 35.5559 29.5468 35.6218 29.3486C35.6878 29.1503 35.7116 28.9405 35.6918 28.7325C35.2832 24.4613 33.2975 20.4957 30.1224 17.6098C26.9472 14.7239 22.8105 13.1249 18.5198 13.125Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M33.2675 16.8757C31.474 14.7009 29.2218 12.9497 26.6721 11.7475C24.1224 10.5453 21.3384 9.92188 18.5195 9.92188C15.7006 9.92188 12.9166 10.5453 10.3669 11.7475C7.8172 12.9497 5.56495 14.7009 3.77148 16.8757'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.2695 7.875C13.2695 8.47174 13.5066 9.04403 13.9285 9.46599C14.3505 9.88795 14.9228 10.125 15.5195 10.125C16.1163 10.125 16.6886 9.88795 17.1105 9.46599C17.5325 9.04403 17.7695 8.47174 17.7695 7.875C17.7695 7.27826 17.5325 6.70597 17.1105 6.28401C16.6886 5.86205 16.1163 5.625 15.5195 5.625C14.9228 5.625 14.3505 5.86205 13.9285 6.28401C13.5066 6.70597 13.2695 7.27826 13.2695 7.875Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.2695 7.875C19.2695 8.47174 19.5066 9.04403 19.9285 9.46599C20.3505 9.88795 20.9228 10.125 21.5195 10.125C22.1163 10.125 22.6886 9.88795 23.1105 9.46599C23.5325 9.04403 23.7695 8.47174 23.7695 7.875C23.7695 7.27826 23.5325 6.70597 23.1105 6.28401C22.6886 5.86205 22.1163 5.625 21.5195 5.625C20.9228 5.625 20.3505 5.86205 19.9285 6.28401C19.5066 6.70597 19.2695 7.27826 19.2695 7.875Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24635'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
