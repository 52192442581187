import { IPersonalSiteName, IRecordingPeriod } from '../../../../store/redux/personalsite/personalsite.interface';
import { apiGet, apiPost, apiPut } from '../../interceptors';

export const PersonalSiteService = {
  async fetchIdOnlineRecords() {
    const response = await apiGet('/idonlinerecords');

    return response.data.idonline;
  },
  async updateOnlineRecords(data: IPersonalSiteName) {
    const response = await apiPost('/updateonlinerecords', data);

    return response.data;
  },
  async fetchScheduleOnlineRecords(uniq: string) {
    const response = await apiGet(`https://dev.go.telebon.ru/config?uniq=${uniq}`);

    return response.data.error ? '' : response.data;
  },
  async createOnlineRecordsSchedule(values: IRecordingPeriod) {
    const response = await apiPost(`https://dev.go.telebon.ru/config`, values);

    return response.data;
  },

  async updateRecordingPeriod(values: IRecordingPeriod) {
    const response = await apiPut(`https://dev.go.telebon.ru/config?uniq=${values.uniq}`, values);

    return response;
  }
};
