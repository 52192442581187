import { styled } from 'styled-components';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';

export const ApplyPromocodeModalWrapper = styled(FlexWithAlign)`
  padding: 24px;
`;

export const ModalHeader = styled(FlexWithAlign)``;

export const ApplyPromocodeModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const ApplyPromocodeModalFormBtn = styled(NewButton)`
  margin-left: auto;
`;
