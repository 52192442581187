import React from 'react';

export function SuccessBlack() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='5.5'
        fill='#2A2C32'
      />
      <rect
        x='0.5'
        y='0.5'
        width='23'
        height='23'
        rx='5.5'
        stroke='#2A2C32'
      />
      <path
        d='M10.0002 15.5197L5.84019 11.5197C5.73352 11.4131 5.61352 11.3731 5.48019 11.3997C5.34685 11.4264 5.22685 11.4797 5.12019 11.5597C5.01352 11.6397 4.97352 11.7464 5.00019 11.8797C5.02685 12.0131 5.06685 12.1331 5.12019 12.2397L9.68019 16.6397C9.73352 16.6931 9.84019 16.7197 10.0002 16.7197C10.1602 16.7197 10.2669 16.6931 10.3202 16.6397L19.3602 7.83973C19.4669 7.73306 19.5202 7.61306 19.5202 7.47973C19.5202 7.34639 19.4669 7.23973 19.3602 7.15973C19.2535 7.07973 19.1335 7.02639 19.0002 6.99973C18.8669 6.97306 18.7469 7.01306 18.6402 7.11973L10.0002 15.5197Z'
        fill='white'
      />
    </svg>
  );
}
