import React from 'react';

export function AnalyticIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5 3.75C11.5 3.33579 11.8358 3 12.25 3C17.2206 3 21.5 7.27944 21.5 12.25C21.5 12.6642 21.1642 13 20.75 13H12.25C11.8358 13 11.5 12.6642 11.5 12.25V3.75ZM13 4.53943V11.5H19.9606C19.5847 7.92944 16.5706 4.91528 13 4.53943ZM9.93924 4.11912C10.1026 4.49978 9.92637 4.94075 9.54571 5.10407C6.87107 6.25157 5 8.90813 5 12C5 16.1421 8.35786 19.5 12.5 19.5C15.5919 19.5 18.2484 17.6289 19.3959 14.9543C19.5592 14.5736 20.0002 14.3974 20.3809 14.5608C20.7615 14.7241 20.9377 15.165 20.7744 15.5457C19.3989 18.7519 16.2128 21 12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 8.28718 5.74809 5.10114 8.95429 3.72558C9.33495 3.56227 9.77593 3.73846 9.93924 4.11912Z'
        fill='currentColor'
      />
    </svg>
  );
}
