import React from 'react';

export function Schedule() {
  return (
    <svg
      width='16'
      height='18'
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5742 0.13259C7.83054 -0.0441968 8.16946 -0.0441968 8.4258 0.13259L14.8113 4.53636C15.5556 5.04967 16 5.89605 16 6.8002V15.25C16 16.7688 14.7688 18 13.25 18H2.75C1.23122 18 0 16.7688 0 15.25V6.8002C0 5.89605 0.444431 5.04967 1.18873 4.53636L7.5742 0.13259ZM6.5 16.5H9.5V11.75C9.5 11.6119 9.38807 11.5 9.25 11.5H6.75C6.61193 11.5 6.5 11.6119 6.5 11.75V16.5ZM11 16.5V11.75C11 10.7835 10.2165 10 9.25 10H6.75C5.7835 10 5 10.7835 5 11.75V16.5H2.75C2.05964 16.5 1.5 15.9404 1.5 15.25V6.8002C1.5 6.38922 1.70201 6.0045 2.04033 5.77118L8 1.66106L13.9597 5.77118C14.298 6.0045 14.5 6.38922 14.5 6.8002V15.25C14.5 15.9404 13.9404 16.5 13.25 16.5H11Z'
        fill='currentColor'
      />
    </svg>
  );
}
