export const Copy = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.5984 18.1436V20.8316C21.5984 21.0236 21.5184 21.1996 21.3584 21.3596C21.1984 21.5196 21.0224 21.5996 20.8304 21.5996H18.1424V20.3516H20.4464V18.1436H21.5984ZM8.44644 18.1436V20.3516H10.4624V21.5996H7.96644C7.77444 21.5996 7.59844 21.5196 7.43844 21.3596C7.27844 21.1996 7.19844 21.0236 7.19844 20.8316V18.1436H8.44644ZM16.4144 20.3516V21.5996H12.1904V20.3516H16.4144ZM16.2224 2.39961C16.4144 2.39961 16.5904 2.47961 16.7504 2.63961C16.9104 2.79961 16.9904 2.97561 16.9904 3.16761V16.2236C16.9904 16.4156 16.9104 16.5916 16.7504 16.7516C16.5904 16.9116 16.4144 16.9916 16.2224 16.9916H3.16644C2.97444 16.9916 2.79844 16.9116 2.63844 16.7516C2.47844 16.5916 2.39844 16.4156 2.39844 16.2236V3.16761C2.39844 2.97561 2.47844 2.79961 2.63844 2.63961C2.79844 2.47961 2.97444 2.39961 3.16644 2.39961H16.2224ZM21.5984 12.1916V16.4156H20.4464V12.1916H21.5984ZM15.9344 3.55161H3.64644V15.7436H15.9344V3.55161ZM20.8304 7.00761C21.0224 7.00761 21.1984 7.08761 21.3584 7.24761C21.5184 7.40761 21.5984 7.58361 21.5984 7.77561V10.4636H20.4464V8.25561H18.1424V7.00761H20.8304Z'
        fill='currentColor'
      />
    </svg>
  );
};
