import styled from 'styled-components';

import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    color: #151618;
  }
  .top-wrapper {
    padding: 0 6.6px;
    width: 100%;
    gap: 17px;
    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 43px;
    }
    > div {
      padding-top: 4px;
    }
  }
  &.top {
    margin-top: 0;
  }
  .form-wrapper {
    gap: 2px;
  }
  .sale-container {
    gap: 24px;
    margin-top: 39px;
  }
  .cancel {
    min-width: 105px;
    width: 100%;
  }
  .save-w {
    min-width: 132px;
  }
  .price-text {
    line-height: 18px;
  }
  .sale-name-text {
    line-height: 22px;
  }
  .employee-dropdown {
    max-width: 216px;
    width: 100%;
    span {
      text-transform: capitalize;
      line-height: 12px;
      font-size: 12px;
    }
  }
  .custom-select__control {
    min-height: 58px;
  }
  .custom-select__value-container {
    padding-left: 60px;
  }
`;

export const SalesWrapper = styled(FlexWithAlign)`
  > span {
    text-transform: uppercase;
    line-height: 18px;
  }
  .naimenovanie {
        line-height: 20px;
  }
`;

export const Form = styled.form`
`

export const CloseBtn = styled.div`
  padding-top: 9px;
  cursor: pointer;
`;

export const SalesItem = styled.div`
  width: 100%;
  min-height: 84px;
  height: 100%;
  border-block: 1px solid ${(props) => props.theme.color.borderColorSecond};
  display: flex;
  align-items: center;
`;

export const SalesName = styled.div`
    min-width: 310px;
    max-width: 310px;
  min-height: 40px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 13px;
  margin-top: 9px;
`;

export const EmployeeChoose = styled.div`
width: 100%;

.additionalSvg {
  position: absolute;
  top: 9px;
  left: 15px;
}
`;
