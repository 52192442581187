import { useTranslation } from 'react-i18next';

import { LogoWrap, Wrapper } from './OfflinePage.styled';

import { EIcon, IconNew } from '../../../components/icons/medium-new-icons/icon';
import { Text, TextPType, TextType } from '../../../shared/ui';

const OfflinePage = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <LogoWrap>
        <IconNew name={EIcon.logomark} />
      </LogoWrap>
      <Text
        $tag={TextType.H3}
        fontWeight={700}
      >
        {t('Нет связи')}
      </Text>
      <Text
        $tag={TextType.P}
        $pSize={TextPType.P17}
        fontWeight={600}
      >
        {'Проверьте интернет-соединение'}
      </Text>
    </Wrapper>
  );
};

export default OfflinePage;
