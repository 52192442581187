import styled from 'styled-components';

import { DURATION, SHADOW, SHADOW_HOVER, SHADOW_SIDEBAR } from '../../../../styles';
import { FlexWithAlign } from '../../../../utils/styleUtils';

interface IProps {
  $size?: 'l' | 'm' | 's';
  $vertical?: 'on' | 'off';
  $type?: 'bg' | 'transparent';
}
export const LinkItemWrapper = styled(FlexWithAlign)<IProps>`
  background: ${(props) => (props.$type === 'transparent' ? 'transparent' : props.theme.color.elevation01)};
  ${(props) => {
    if (props.$type) {
      switch (true) {
        case props.$type === 'transparent':
          return 'transparent';

        case props.$type === 'bg':
          return props.theme.color.base01;

        default:
          return props.theme.color.base01;
      }
    }
  }};
  border-radius: 16px;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  justify-content: space-between;
  position: relative;
  box-shadow: ${SHADOW};
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  flex-direction: ${(props) => {
    if (props.$type && props.$vertical) {
      const { $type, $vertical } = props;

      switch (true) {
        case $type === 'bg' && $vertical === 'on':
          return 'column-reverse';
        case $type === 'transparent' && $vertical === 'off':
          return 'column';
        case $type === 'bg' && $vertical === 'off':
          return 'row';
        case $type === 'transparent' && $vertical === 'on':
          return 'row-reverse';
        default:
          return 'row';
      }
    }
  }};
  a {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  @media (min-width: 768px) {
    &:hover {
      box-shadow: ${SHADOW_HOVER};
      transform: translateY(-0.25rem);
    }
  }
  @media (max-width: 767px) {
    gap: 0.75rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
    overflow: hidden;
    box-shadow: ${SHADOW_SIDEBAR};
    &:active {
      box-shadow: ${SHADOW_SIDEBAR};
      transform: scale3d(0.95, 0.95, 0.95);
    }
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;
