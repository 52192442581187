import styled from 'styled-components';

import themelight from '../../../../../../app/providers/ThemeProvider/ui/theme';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';

export const Wrapper = styled(FlexWithAlign)`
  padding: 24px;
`;

export const IconsList = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 24px;
`;
export const IconItem = styled.li`
  display: flex;
  width: fit-content;
`;

export const IconItemBtn = styled.button`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  border-radius: 8px;
  background-color: ${themelight.newColor.greyFA};
  min-width: 88px;
  min-height: 88px;
  &.active {
    box-shadow: 0 0 0 2px ${themelight.newColor.black} inset;
  }
`;
