export function ArrowLeftOutline48() {
  return (
    <svg
      width='48'
      height='49'
      viewBox='0 0 48 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.0637 9.01821C23.7127 9.65799 23.7735 10.6689 23.2416 11.3783L23.0818 11.5637L12.1 22.7L40.2 22.7C41.1941 22.7 42 23.5059 42 24.5C42 25.4176 41.3133 26.1749 40.4258 26.286L40.2 26.3H12.1L23.0818 37.4363C23.7216 38.0852 23.7681 39.0969 23.2261 39.7986L23.0637 39.9818C22.4148 40.6216 21.4031 40.6681 20.7014 40.1261L20.5182 39.9637L6.51821 25.7637C5.88484 25.1213 5.83206 24.1218 6.35987 23.4198L6.51821 23.2363L20.5182 9.03627C21.2162 8.32836 22.3558 8.32027 23.0637 9.01821Z'
        fill='currentColor'
      />
    </svg>
  );
}
