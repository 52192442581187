import React from 'react';

export function ComputerOutline() {
  return (
    <svg
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.100021 6.5001C0.100021 4.25989 0.100022 3.13978 0.535995 2.28414C0.919489 1.53149 1.53141 0.919565 2.28406 0.536071C3.13971 0.100098 4.25981 0.100098 6.50002 0.100098H11.5C13.7402 0.100098 14.8603 0.100098 15.716 0.536071C16.4686 0.919565 17.0806 1.53149 17.464 2.28414C17.9 3.13978 17.9 4.25989 17.9 6.5001C17.9 8.74031 17.9 9.86041 17.464 10.7161C17.0806 11.4687 16.4686 12.0806 15.716 12.4641C14.88 12.8901 13.7916 12.8999 11.6527 12.9001L12.2027 15.1001H14.1C14.5971 15.1001 15 15.5031 15 16.0001C15 16.4972 14.5971 16.9001 14.1 16.9001H3.90001C3.40296 16.9001 3.00002 16.4972 3.00002 16.0001C3.00002 15.5031 3.40296 15.1001 3.90001 15.1001H5.79732L6.34732 12.9001C4.20846 12.8999 3.12004 12.8901 2.28406 12.4641C1.53141 12.0806 0.919489 11.4687 0.535995 10.7161C0.100022 9.86041 0.100021 8.74031 0.100021 6.5001ZM11.5 1.9001H6.50002C5.35021 1.9001 4.59611 1.9015 4.01941 1.94862C3.46371 1.99402 3.23302 2.07274 3.10124 2.13988C2.68728 2.3508 2.35073 2.68736 2.13981 3.10132C2.07266 3.2331 1.99394 3.46379 1.94854 4.01948C1.90142 4.59619 1.90002 5.35029 1.90002 6.5001C1.90002 7.6499 1.90142 8.404 1.94854 8.98071C1.99394 9.53641 2.07266 9.76709 2.13981 9.89888C2.35073 10.3128 2.68728 10.6494 3.10124 10.8603C3.23302 10.9275 3.46371 11.0062 4.01941 11.0516C4.59612 11.0987 5.35022 11.1001 6.50002 11.1001H11.5C12.6498 11.1001 13.4039 11.0987 13.9806 11.0516C14.5363 11.0062 14.767 10.9275 14.8988 10.8603C15.3128 10.6494 15.6493 10.3128 15.8602 9.89888C15.9274 9.76709 16.0061 9.53641 16.0515 8.98071C16.0986 8.404 16.1 7.6499 16.1 6.5001C16.1 5.35029 16.0986 4.59619 16.0515 4.01948C16.0061 3.46379 15.9274 3.2331 15.8602 3.10132C15.6493 2.68736 15.3128 2.3508 14.8988 2.13988C14.767 2.07274 14.5363 1.99402 13.9806 1.94862C13.4039 1.9015 12.6498 1.9001 11.5 1.9001ZM8.20272 12.9001L7.65271 15.1001H10.3473L9.79732 12.9001H8.20272Z'
        fill='currentColor'
      />
    </svg>
  );
}
