import { useQueries, useQuery, UseQueryResult } from '@tanstack/react-query';

import { SubproductsService } from '../../../../shared/api/model/service/subproducts.service';
import { SUBPRODUCTS_ALL_QUERY, SUBPRODUCTS_QUERY, SUBPRODUCT_QUERY } from '../../../../shared/const/query.const';
import { IService } from '../subproducts.interface';

export function useSubproductQuery(id: string) {
  return useQuery<IService[], Error>({
    queryKey: [SUBPRODUCTS_QUERY, id],
    queryFn: () => SubproductsService.fetchSubproducts(id),
    select: (data: IService[]) => data || [],
    enabled: !!id
  });
}

export function useSubproductAllQuery(ids: (string | undefined)[] | undefined) {
  const queryResults = useQueries({
    queries: (ids ?? []).map((id) => ({
      staleTime: 18000000,
      queryKey: [SUBPRODUCT_QUERY, id],
      queryFn: () => SubproductsService.fetchSubproducts(id!),
      select: (data: IService[]) => data
    })),
    combine: (results: UseQueryResult<IService[]>[]) => {
      const combinedData = results
        .map((result) => result.data ?? [])
        .flat()
        .sort((a, b) => {
          if (a?.order === undefined || a?.order === null) return 1;
          if (b?.order === undefined || b?.order === null) return -1;

          return a.order - b.order;
        });
      const isPending = results.some((result) => result.isFetching);

      return {
        data: combinedData,
        pending: isPending,
        refetchAll: () => results.forEach((result) => result.refetch())
      };
    }
  });

  return queryResults;
}

export function useSubproductOneQuery(id: string | undefined) {
  return useQuery({
    queryKey: [SUBPRODUCT_QUERY, id],
    queryFn: () => SubproductsService.getSubproduct(id),
    select: (data: IService) => data || {},
    enabled: !!id
  });
}

export function useAllSubproducts() {
  return useQuery({
    queryKey: [SUBPRODUCTS_ALL_QUERY],
    queryFn: () => SubproductsService.getAllSubproducts(),
    staleTime: 18000000,
    select: (data: IService[]) =>
      data.sort((a, b) => {
        if (a?.order === undefined || a?.order === null) return 1;
        if (b?.order === undefined || b?.order === null) return -1;

        return a.order - b.order;
      }) || []
  });
}
