export const PaymentIcon12 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.25586 0.75H32.7559C32.7559 0.75 34.2559 0.75 34.2559 2.25V33.75C34.2559 33.75 34.2559 35.25 32.7559 35.25H4.25586C4.25586 35.25 2.75586 35.25 2.75586 33.75V2.25C2.75586 2.25 2.75586 0.75 4.25586 0.75Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.7559 30.75V35.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2559 26.25V35.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.25 6.75C16.25 7.14782 16.092 7.52936 15.8107 7.81066C15.5294 8.09196 15.1478 8.25 14.75 8.25H11.75C11.3522 8.25 10.9706 8.09196 10.6893 7.81066C10.408 7.52936 10.25 7.14782 10.25 6.75C10.25 6.35218 10.408 5.97064 10.6893 5.68934C10.9706 5.40804 11.3522 5.25 11.75 5.25H14.75C15.1478 5.25 15.5294 5.40804 15.8107 5.68934C16.092 5.97064 16.25 6.35218 16.25 6.75Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.25 9.75C16.25 10.1478 16.092 10.5294 15.8107 10.8107C15.5294 11.092 15.1478 11.25 14.75 11.25H11.75C11.3522 11.25 10.9706 11.092 10.6893 10.8107C10.408 10.5294 10.25 10.1478 10.25 9.75C10.25 9.35218 10.408 8.97064 10.6893 8.68934C10.9706 8.40804 11.3522 8.25 11.75 8.25H14.75C15.1478 8.25 15.5294 8.40804 15.8107 8.68934C16.092 8.97064 16.25 9.35218 16.25 9.75Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.75 20.25C26.75 20.6478 26.592 21.0294 26.3107 21.3107C26.0294 21.592 25.6478 21.75 25.25 21.75H22.25C21.8522 21.75 21.4706 21.592 21.1893 21.3107C20.908 21.0294 20.75 20.6478 20.75 20.25C20.75 19.8522 20.908 19.4706 21.1893 19.1893C21.4706 18.908 21.8522 18.75 22.25 18.75H25.25C25.6478 18.75 26.0294 18.908 26.3107 19.1893C26.592 19.4706 26.75 19.8522 26.75 20.25Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.75 29.25C26.75 29.6478 26.592 30.0294 26.3107 30.3107C26.0294 30.592 25.6478 30.75 25.25 30.75H22.25C21.8522 30.75 21.4706 30.592 21.1893 30.3107C20.908 30.0294 20.75 29.6478 20.75 29.25C20.75 28.8522 20.908 28.4706 21.1893 28.1893C21.4706 27.908 21.8522 27.75 22.25 27.75H25.25C25.6478 27.75 26.0294 27.908 26.3107 28.1893C26.592 28.4706 26.75 28.8522 26.75 29.25Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.25 24.75C16.25 25.1478 16.092 25.5294 15.8107 25.8107C15.5294 26.092 15.1478 26.25 14.75 26.25H11.75C11.3522 26.25 10.9706 26.092 10.6893 25.8107C10.408 25.5294 10.25 25.1478 10.25 24.75C10.25 24.3522 10.408 23.9706 10.6893 23.6893C10.9706 23.408 11.3522 23.25 11.75 23.25H14.75C15.1478 23.25 15.5294 23.408 15.8107 23.6893C16.092 23.9706 16.25 24.3522 16.25 24.75Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.75 6.75C26.75 7.14782 26.592 7.52936 26.3107 7.81066C26.0294 8.09196 25.6478 8.25 25.25 8.25H22.25C21.8522 8.25 21.4706 8.09196 21.1893 7.81066C20.908 7.52936 20.75 7.14782 20.75 6.75C20.75 6.35218 20.908 5.97064 21.1893 5.68934C21.4706 5.40804 21.8522 5.25 22.25 5.25H25.25C25.6478 5.25 26.0294 5.40804 26.3107 5.68934C26.592 5.97064 26.75 6.35218 26.75 6.75Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2559 11.25V23.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2559 5.25V0.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.7559 21.75V27.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.7559 8.25V18.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.7559 0.75V5.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
