import styled from 'styled-components';
import { FlexWithAlign, scaleIn, scaleOut } from '../../../../utils/styleUtils';
import { DURATION, SHADOW_INPUT } from '../../../../styles';

interface IStyledHTagProps {
  color?: 'blue' | 'orange' | 'gray';
  $fSize?: 'l' | 'm' | 's' | 'xs';
}

export const FilterItemWrapper = styled.div<IStyledHTagProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  min-height: 36px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s ease;
  position: relative;
  color: ${(props) => props.theme.text.text01};
  box-shadow: ${SHADOW_INPUT};
  &:hover,
  &.active {
    > svg {
      transform: translateY(2px);
    }
    &:after {
      border-width: 2px;
      color: ${(props) => props.theme.color.primary};
    }
  }
  padding: ${(props) => {
    if (props.$fSize !== undefined) {
      switch (props.$fSize) {
        case 'l':
          return props.$fSize ? '16px 32px' : '2px 8px';
        case 'm':
          return props.$fSize ? '10px 20px' : '2px 8px';
        case 's':
          return props.$fSize ? '6px 12px' : '2px 8px';
        case 'xs':
          return props.$fSize ? '2px 8px' : '2px 8px';
        default:
          return '2px 8px';
      }
    } else {
      return '2px 8px';
    }
  }};
  font-size: ${(props) => {
    if (props.$fSize !== undefined) {
      switch (props.$fSize) {
        case 'l':
          return props.$fSize ? '15px' : '11px';
        case 'm':
          return props.$fSize ? '15px' : '11px';
        case 's':
          return props.$fSize ? '13px' : '11px';
        case 'xs':
          return props.$fSize ? '11px' : '11px';
        default:
          return '11px';
      }
    } else {
      return '11px';
    }
  }};
  &:after {
    transition-property: color;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    color: ${(props) => props.theme.color.base03};
    position: absolute;
    inset: 0;
    content: '';
    border-radius: inherit;
    border: 1px solid currentColor;
    pointer-events: none;
  }
  border-radius: 12px;
  > span {
    background: ${(props) => props.theme.status.neutralBg};
    border-radius: 50%;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: center;
    padding: 2px 7.5px;
    color: ${(props) => props.theme.text.text01};
  }
  > svg {
    width: 20px;
    height: 20px;
    transition: 0.3s ease;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Options = styled(FlexWithAlign)`
  position: absolute;
  top: 120%;
  right: 0;
  transform-origin: top;
  width: 120%;
  z-index: 4;
  @media (max-width: 767px) {
    width: 100%;
  }
  &.open {
    animation: ${scaleIn} 0.3s ease;
    visibility: visible;
  }
  &.close {
    animation: ${scaleOut} 0.3s ease;
    visibility: hidden;
    //transition: 0.3s ease;
  }
  p {
    @media (max-width: 767px) {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
