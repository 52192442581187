import React, { forwardRef, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { ErrorText, IconContainer, InputContainer, InputContent, Label, TimeList } from './DatePicker.styled';
import { cn } from '../../../lib';
import { InputType } from '../../Input';
import Calendar from 'react-calendar';
import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import dayjs from 'dayjs';
import { WrapperCalendar } from '../../../../components/shared/calendar/CommonCalendar.tsyled';
import { useTranslation } from 'react-i18next';
import {
  MobileAbsoluteDropdown,
  MobileCenter
} from '../../../../pages/EmployeesPage/ui/employee-timetable/ui/EmployeeTimeTablePage.styled';

import { CalendarWrapper } from '../../../../pages/Recording/lib/recording-wrapper/header/header-calendar/RecordingCalendar';
import useMatchMedia from '../../../lib/hooks/useMatchMedia';
import { Drawer } from '../../Drawer';

type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'readOnly' | 'size'>;

interface DatePickerProps extends HTMLInputProps {
  label: string;
  type?: string;
  size?: InputType;
  value: string | any;
  onChange: any;
  name?: string;
  className?: string;
  error?: any;
  formik?: FormikProps<any>;
  timeIntervals?: number;
  ref?: any;
  onChangeDate?: any;
}

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>((props: DatePickerProps, ref) => {
  const { label, className, type, children, size, value, onChange, onChangeDate, name, error = null, formik, ...rest } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const optionsContainerRef = useRef<HTMLDivElement>(null);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const { t } = useTranslation();

  const handleLabelClick = () => {
    setIsFocused(true);
    isMobile && setIsOpen(true);
    setOptionsVisible(!optionsVisible);
  };
  const handleOptionContainerBlur = () => {
    if (!optionsVisible) {
      setIsFocused(false);
    }
  };
  const handleDateChange = (date: Date) => {
    onChangeDate && onChangeDate(date);
    onChange && onChange(date);
    setIsFocused(false);
  };
  const parsedDate = typeof value === 'string' ? dayjs(value, 'DD.MM.YYYY').toDate() : value;

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsFocused(false);
        setOptionsVisible(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <InputContainer
      ref={wrapperRef}
      size={size}
    >
      <TimeList
        $type={type}
        ref={optionsContainerRef}
        onBlur={handleOptionContainerBlur}
        className={cn(isFocused && !isMobile && 'open', className, { error: !!error })}
      >
        {isMobile ? (
          <>
            {isOpen && (
              <Drawer
                lazy
                isOpen={isOpen}
                onClose={onClose}
                header={t('Выберите дату')}
              >
                <MobileAbsoluteDropdown>
                  <MobileCenter>
                    <CalendarWrapper className={'mobile-sheet-calendar'}>
                      <WrapperCalendar>
                        <Calendar
                          nextLabel={<IconInstance name={EIcon.chevronrightoutline} />}
                          prevLabel={<IconInstance name={EIcon.chevronleftoutline} />}
                          maxDetail={'month'}
                          prevAriaLabel={t('Предыдущий месяц')}
                          nextAriaLabel={t('Следующий месяц')}
                          navigationLabel={({ date, label, locale, view }) =>
                            view === 'month' ? dayjs(date).locale('ru').format('MMMM') : view === 'year' && dayjs(date).format('YYYY')
                          }
                          onClickMonth={() => {}}
                          defaultView={'month'}
                          showNeighboringMonth={false}
                          onClickDay={handleDateChange}
                          value={parsedDate}
                        />
                      </WrapperCalendar>
                    </CalendarWrapper>
                  </MobileCenter>
                </MobileAbsoluteDropdown>
              </Drawer>
            )}
          </>
        ) : (
          <WrapperCalendar>
            <Calendar
              nextLabel={<IconInstance name={EIcon.chevronrightoutline} />}
              prevLabel={<IconInstance name={EIcon.chevronleftoutline} />}
              maxDetail={'month'}
              prevAriaLabel={t('Предыдущий месяц')}
              nextAriaLabel={t('Следующий месяц')}
              navigationLabel={({ date, label, locale, view }) =>
                view === 'month' ? dayjs(date).locale('ru').format('MMMM') : view === 'year' && dayjs(date).format('YYYY')
              }
              onClickMonth={() => {}}
              defaultView={'month'}
              showNeighboringMonth={false}
              onClickDay={handleDateChange}
              value={parsedDate}
            />
          </WrapperCalendar>
        )}
      </TimeList>

      <Label
        size={size}
        htmlFor={name}
        className={cn(isFocused && !isMobile && 'focused', className)}
      >
        <span>{label}</span>
        <InputContent onClick={handleLabelClick}>{dayjs(value).format('DD.MM.YYYY')}</InputContent>
      </Label>

      <IconContainer
        className={cn({
          active: value && value.length,
          error: !!error
        })}
      >
        <IconInstance name={EIcon.calendar} />
      </IconContainer>

      {error ? <ErrorText size={size}>{error}</ErrorText> : null}
    </InputContainer>
  );
});
