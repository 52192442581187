export const PaymentIcon27 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.7656 25.0312V29.25'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M26.2344 25.0312V29.25'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M18.5 25.0312V33.4688'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M4.4375 3.9375V20.8125H32.5625V3.9375H4.4375Z'
        stroke='currentColor'
      />
      <path
        d='M14.9844 12.375C14.9844 11.4426 15.3548 10.5484 16.0141 9.88908C16.6734 9.22977 17.5676 8.85938 18.5 8.85938C19.4324 8.85938 20.3266 9.22977 20.9859 9.88908C21.6452 10.5484 22.0156 11.4426 22.0156 12.375C22.0156 13.3074 21.6452 14.2016 20.9859 14.8609C20.3266 15.5202 19.4324 15.8906 18.5 15.8906C17.5676 15.8906 16.6734 15.5202 16.0141 14.8609C15.3548 14.2016 14.9844 13.3074 14.9844 12.375Z'
        stroke='currentColor'
      />
    </svg>
  );
};
