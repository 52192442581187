import React from 'react';

import { DefaultTelegramBackground } from '../../../../../../components/icons/medium-new-icons/unsorted/DefaultTelegramBackground';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import {
  CircleWithNumber,
  Line,
  RuleContainer,
  RulesForTelegramRegistrationContainer,
  TelegramChatButtonsContainer
} from '../Messages.styled';

const TelegramBotOffline = () => {
  return (
    <>
      <DefaultTelegramBackground></DefaultTelegramBackground>
      <div style={{ paddingTop: '19px' }}>
        <NewText
          $customSize={TextCustomType.T16}
          fontWeight={700}
        >
          Здесь появятся уведомления и сообщения из вашего бота онлайн-записи
        </NewText>
      </div>
      <RulesForTelegramRegistrationContainer>
        <RuleContainer>
          <CircleWithNumber>1</CircleWithNumber>
          <NewText $customSize={TextCustomType.T14}>Перейдите в раздел онлайн-запись</NewText>
        </RuleContainer>
        <RuleContainer>
          <CircleWithNumber>2</CircleWithNumber>
          <NewText $customSize={TextCustomType.T14}>Создайте токен по инструкции</NewText>
        </RuleContainer>
        <RuleContainer>
          <CircleWithNumber>3</CircleWithNumber>
          <NewText $customSize={TextCustomType.T14}>Получайте записи через Telegram</NewText>
        </RuleContainer>
        <Line></Line>
      </RulesForTelegramRegistrationContainer>
      <TelegramChatButtonsContainer>
        <NewButton className={'telegram-button-white'}>Инструкция</NewButton>
        <NewButton
          className={'telegram-button-black'}
          typeBtn={'black'}
        >
          Создать
        </NewButton>
      </TelegramChatButtonsContainer>
    </>
  );
};

export default TelegramBotOffline;
