import React from 'react';

export function WarningRound() {
  return (
    <svg
      width='72'
      height='72'
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='mask0_5245_24955'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='72'
        height='72'
      >
        <path
          d='M36 72C55.8823 72 72 55.8823 72 36C72 16.1177 55.8823 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_5245_24955)'>
        <path
          d='M72 0H0V72H72V0Z'
          fill='#FF9400'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.5655 39.8142L33.1891 23.7692C33.1104 22.8254 33.661 22.0781 34.5655 22.0781H37.397C38.3015 22.0781 38.8914 22.8253 38.8128 23.7692L37.4364 39.8142C37.3577 40.6794 36.7678 41.23 35.9813 41.23C35.1947 41.23 34.6442 40.6794 34.5655 39.8142ZM33.1891 46.8144C33.1891 45.2807 34.4082 44.1008 35.9813 44.1008C37.5937 44.1008 38.7735 45.2806 38.7735 46.8144V47.2077C38.7735 48.7414 37.5937 49.9213 35.9813 49.9213C34.4082 49.9213 33.1891 48.7415 33.1891 47.2077V46.8144Z'
        fill='white'
      />
    </svg>
  );
}
