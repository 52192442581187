import React, { SyntheticEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useFormik } from 'formik';

import {
  Instruction,
  InstructionContent,
  InstructionContainer,
  InstructionHeader,
  TelegramBotModalContainer,
  Token,
  InstructionItem,
  IconWrap,
  TextWrap,
} from './CreateTelegramBotModal.styled';

import { getRouteTelegramBotInfo } from '../../../../../shared/const';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import { NewInput } from '../../../../../shared/new-ui/NewInput';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { useCreateTelegramBot } from '../../../../../store/redux/telegrambot/hooks/useTelegramBotMutation';
import { apiGet } from '../../../../../utils/apiInstance';
import { validationTelegramToken } from '../../../../../utils/validation-input';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';
import BotExample from './example/BotExample';

interface IProps {
  modalPayload: any;
  closeModal: ActionCreatorWithoutPayload<'modal/closeModal'>;
}

const CreateTelegramBotModal = (props: IProps) => {
  const { modalPayload, closeModal } = props;
  const [nameError, setNameError] = useState(false);
  const [pending, setPending] = useState(false);
  const [stage, setStage] = useState(0);
  const [isSended, setIsSented] = useState(false);
  const { data: activeFilial, isLoading, refetch } = useFilialQuery();
  const nav = useNavigate();
  const { mutateAsync: createTelegramBot } = useCreateTelegramBot();
  useEffect(() => {
    if (activeFilial) {
      if (activeFilial?.tgbot !== null) {
        if (activeFilial?.tgbot !== '') {
          setStage(1);
        }
      }
    }
  }, [isLoading]);

  const initialValues = { token: '' };
  const formik = useFormik({
    initialValues,
    validationSchema: validationTelegramToken,
    onSubmit: () => {},
    validateOnBlur: true
  });

  const send = async (values: any) => {
    setPending(true);

    try {
      const key = await apiGet(`/api/keycabinet`);
      const apikey = key.data.apikey;
      if (apikey) {
        const messageData = {
          filial_id: activeFilial?.id || localStorage.getItem('currentFilialId'),
          api_key: apikey,
          bot_token: values.token.trim(),
          filial_name: activeFilial?.name || localStorage.getItem('currentFilialName')
        };
        await createTelegramBot(messageData)
          .then(() => {
            console.log('Токен отправлен.', messageData);
            toast.success('Токен отправлен.');
          })
          .catch((error: any) => {
            console.error(`Ошибка при отправке данных:`, error);
          });
      } else {
        console.log('Ошибка.');
      }
    } catch (error) {
      console.error('Ошибка при получении данных пользователя:', error);
    }

    await refetch();
    setPending(false);
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!formik.isValid || pending || formik.values.token === '') {
      setNameError(true);

      return;
    }
    try {
      await send(formik.values).then((r) => console.log('success ', activeFilial, 'res ', r));
      await refetch();
      await setStage(1);
      await setIsSented(true);
    } catch (error) {
      console.error('Ошибка при отправке:', error);
    }
  };

  const handleCloseModal = () => {
    closeModal();
    nav(getRouteTelegramBotInfo());
  };

  return (
    <TelegramBotModalContainer>
      <InstructionContainer>
        <InstructionHeader>
          <NewButton
            className='arrow'
            typeBtn={'without-border'}
            onClick={() => closeModal()}
          >
            <IconNew name={EIcon.arrowback} />
          </NewButton>
          <NewText
            $customSize={TextCustomType.T28}
            fontWeight={700}
            color='title'
          >
            Telegram Bot
          </NewText>
        </InstructionHeader>
        <InstructionContent>
          <NewText
            className='title-text'
            $customSize={TextCustomType.T16}
            fontWeight={700}
            color='title'
          >
            Создайте свой собственный уникальный бот для записи клиентов. Пошаговое руководство
          </NewText>
          <Instruction>
            <InstructionItem>
              <IconWrap>
                <IconNew name={EIcon.arrowrightbot} />
              </IconWrap>
              <TextWrap>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={400}
                  color='title'
                >
                  Откройте Telegram, найдите бота &nbsp;
                  <a
                    className='blue-text'
                    href='https://t.me/BotFather'
                    target='_blank'
                    rel='noreferrer'
                  >
                    @botfather
                  </a>
                </NewText>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={700}
                  color='title'
                >
                  Видео-инструкция
                </NewText>
              </TextWrap>
            </InstructionItem>
            <InstructionItem>
              <IconWrap>
                <IconNew name={EIcon.arrowrightbot} />
              </IconWrap>
              <TextWrap>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={400}
                  color='title'
                >
                  Введите команду <span className='blue-text'>/newbot</span>
                </NewText>
              </TextWrap>
            </InstructionItem>
            <InstructionItem>
              <IconWrap>
                <IconNew name={EIcon.arrowrightbot} />
              </IconWrap>
              <TextWrap>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={400}
                  color='title'
                >
                  Введите имя бота, так он будет отображаться у клиентов в списке чатов
                </NewText>
              </TextWrap>
            </InstructionItem>
            <InstructionItem>
              <IconWrap>
                <IconNew name={EIcon.arrowrightbot} />
              </IconWrap>
              <TextWrap>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={400}
                  color='title'
                >
                  Введите адрес бота, "персональная ссылка". Окончание должно быть на слово "bot"
                </NewText>
              </TextWrap>
            </InstructionItem>
            <InstructionItem>
              <IconWrap>
                <IconNew name={EIcon.arrowrightbot} />
              </IconWrap>
              <TextWrap>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={400}
                  color='title'
                >
                  Бот пришлет Токен - скопируйте его и вставьте ниже
                </NewText>
              </TextWrap>
            </InstructionItem>
          </Instruction>
        </InstructionContent>
        <Token onSubmit={handleSubmit}>
          <NewInput
            value={formik.values.token}
            onChange={formik.handleChange}
            name={'token'}
            onBlur={formik.handleBlur}
            error={formik.errors.token || (nameError && 'Введите Токен')}
            placeholder='Например, 7012722810:AAH7ej5N93GIczk2jbF0H8'
          ></NewInput>
          {isSended ? (
            <NewButton
              onClick={handleCloseModal}
              type='button'
              className='token-btn'
              typeBtn='black'
            >
              Продолжить
            </NewButton>
          ) : (
            <NewButton
              type='submit'
              className='token-btn'
              typeBtn='black'
              disabled={!formik.isValid || pending || formik.values.token === ''}
            >
              Подключить
            </NewButton>
          )}
        </Token>
      </InstructionContainer>
      <BotExample stage={stage}></BotExample>
    </TelegramBotModalContainer>
  );
};

export default CreateTelegramBotModal;
