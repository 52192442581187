import { EIcon } from '../../../../components/icons/medium-new-icons/icon';
import { ReactNode } from 'react';

export enum AvatarSize {
  FULL = 'full',
  XXL = '128',
  XL = '96',
  L80 = '80',
  L76 = '76',
  L = '64',
  M = '48',
  S = '40',
  X = '36',
  XS = '32',
  XXS = '24',
  XXXS = '17'
}

export enum AvatarRounded {
  FULL = 'full',
  SMALL = 'small'
}

export interface IAvatar {
  avatar?: string;
  randomBg?: boolean;
  background?: 'greyGradient' | 'blue' | 'thinBlue' | 'secondary' | 'ghost' | 'success' | 'danger' | 'white' | 'orange' | 'gray';
  svg?: EIcon;
  title?: string;
  size: AvatarSize;
  rounded: AvatarRounded;
  children?: ReactNode;
  onMouseEnter?: (arg: any) => void;
  onMouseLeave?: (arg: any) => void;
  onClick?: (arg: any) => void;
  className?: string;
}
