import React from 'react';

export function SortReverse() {
  return (
    <svg
      width='16'
      height='11'
      viewBox='0 0 16 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2103_6987)'>
        <path
          d='M7.33516 8.70008C7.46849 8.70008 7.58182 8.74008 7.67516 8.82008C7.76849 8.90008 7.81516 9.00674 7.81516 9.14008C7.81516 9.27341 7.76849 9.38008 7.67516 9.46008C7.58182 9.54008 7.46849 9.58008 7.33516 9.58008H0.975156C0.86849 9.58008 0.76849 9.54008 0.675156 9.46008C0.581823 9.38008 0.535156 9.27341 0.535156 9.14008C0.535156 9.00674 0.581823 8.90008 0.675156 8.82008C0.76849 8.74008 0.86849 8.70008 0.975156 8.70008H7.33516ZM5.09516 5.06008C5.20182 5.06008 5.30182 5.10008 5.39516 5.18008C5.48849 5.26008 5.53516 5.36674 5.53516 5.50008C5.53516 5.63341 5.48849 5.74008 5.39516 5.82008C5.30182 5.90008 5.20182 5.94008 5.09516 5.94008H0.975156C0.86849 5.94008 0.76849 5.90008 0.675156 5.82008C0.581823 5.74008 0.535156 5.63341 0.535156 5.50008C0.535156 5.36674 0.581823 5.26008 0.675156 5.18008C0.76849 5.10008 0.86849 5.06008 0.975156 5.06008H5.09516ZM7.33516 1.42008C7.46849 1.42008 7.58182 1.46008 7.67516 1.54008C7.76849 1.62008 7.81516 1.72675 7.81516 1.86008C7.81516 1.99341 7.76849 2.10008 7.67516 2.18008C7.58182 2.26008 7.46849 2.30008 7.33516 2.30008H0.975156C0.86849 2.30008 0.76849 2.26008 0.675156 2.18008C0.581823 2.10008 0.535156 1.99341 0.535156 1.86008C0.535156 1.72675 0.581823 1.62008 0.675156 1.54008C0.76849 1.46008 0.86849 1.42008 0.975156 1.42008H7.33516Z'
          fill = 'currentColor'
        />
        <path
          d='M11.5303 9.5C11.4112 9.5 11.316 9.462 11.2446 9.38599C11.1731 9.30998 11.1374 9.20863 11.1374 9.08195V2.96318L9.24456 4.97743C9.14932 5.07878 9.04813 5.12945 8.94099 5.12945C8.83384 5.12945 8.73861 5.08511 8.65527 4.99644C8.57194 4.90776 8.53027 4.80641 8.53027 4.6924C8.53027 4.57838 8.57789 4.4707 8.67313 4.36936L11.2446 1.63302C11.2922 1.58234 11.3398 1.54434 11.3874 1.519C11.4827 1.49367 11.5779 1.49367 11.6731 1.519C11.7446 1.54434 11.7922 1.58234 11.816 1.63302L14.3874 4.36936C14.4827 4.4707 14.5303 4.57838 14.5303 4.6924C14.5303 4.80641 14.4886 4.90776 14.4053 4.99644C14.3219 5.08511 14.2267 5.12945 14.1196 5.12945C14.0124 5.12945 13.9112 5.07878 13.816 4.97743L11.9231 2.96318V9.08195C11.9231 9.20863 11.8874 9.30998 11.816 9.38599C11.7446 9.462 11.6493 9.5 11.5303 9.5Z'
          fill = 'currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2103_6987'>
          <rect
            width='14.53'
            height='10'
            fill='white'
            transform='matrix(1 0 0 -1 0.530273 10.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
