import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SALE_PRODUCTS_CATEGORY_QUERY } from '../../../../shared/const/query.const';
import { SaleProductsCategoryService } from '../../../../shared/api/model/service/saleproductscategory.service';
import { ICreateSaleProductCategory, IUpdateSaleProductCategory } from '../saleproductscategory.interface';

export function useCreateSaleProductsCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: ICreateSaleProductCategory) => SaleProductsCategoryService.createSaleProductsCategory(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SALE_PRODUCTS_CATEGORY_QUERY] });
      }
    }
  });
}

export function useUpdateSaleProductsCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: IUpdateSaleProductCategory) => SaleProductsCategoryService.updateSaleProductsCategory(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SALE_PRODUCTS_CATEGORY_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SALE_PRODUCTS_CATEGORY_QUERY] });
      }
    }
  });
}

export function useDeleteSaleProductsCategory() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => SaleProductsCategoryService.deleteSaleProductsCategory(id),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SALE_PRODUCTS_CATEGORY_QUERY] });
      }
    }
  });
}
