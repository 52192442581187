export const CheckboxIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.00019 13.52L3.84019 9.51997C3.73352 9.4133 3.61352 9.3733 3.48019 9.39997C3.34685 9.42664 3.22685 9.47997 3.12019 9.55997C3.01352 9.63997 2.97352 9.74664 3.00019 9.87997C3.02685 10.0133 3.06685 10.1333 3.12019 10.24L7.68019 14.64C7.73352 14.6933 7.84019 14.72 8.00019 14.72C8.16019 14.72 8.26685 14.6933 8.32019 14.64L17.3602 5.83997C17.4669 5.7333 17.5202 5.6133 17.5202 5.47997C17.5202 5.34664 17.4669 5.23997 17.3602 5.15997C17.2535 5.07997 17.1335 5.02664 17.0002 4.99997C16.8669 4.9733 16.7469 5.0133 16.6402 5.11997L8.00019 13.52Z'
        fill='currentColor'
      />
    </svg>
  );
};
