import React, { SyntheticEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import { read, utils } from 'xlsx';

import {
  ButtonsWrap,
  ChooseItem,
  ChooseItemWrapper,
  ExportSelect,
  ExportSelectWrap,
  ExportTextarea,
  InputFile,
  LabelIcon,
  StyledButton,
  TableWrapper,
  TopTableWrapper,
  TopWrapper,
  Wrapper
} from './CreateExportModal.styled';

import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import { IColumnType, Table } from '../../../../components/shared/table/Table';
import { cn } from '../../../../shared/lib';
import { useActions } from '../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../shared/new-ui/NewText/ui/NewText';
import { TextType } from '../../../../shared/new-ui/NewText/ui/NewText.props';
import { InputType } from '../../../../shared/ui';
import CommonButton from '../../../../shared/ui/Button/ui/CommonButton';
import Dropdown from '../../../../shared/ui/Dropdown/ui/Dropdown';
import { useClientsQuery } from '../../../../store/redux/clients/hooks/useClientsQuery';
import { apiPut } from '../../../../utils/apiInstance';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { validationClientAddSchema } from '../../../../utils/validation-input';

interface IData {
  col1: string;
  col2: string;
  col3?: string;
  col4?: string;
  col5?: string;
  col6?: string;
  tags?: string[];
}
interface ITableData {
  tags?: string[];
  [key: string | number]: any;
}
interface IProps {
  closeModal?: () => void;
}

const requireColumns = [
  { value: 'name', title: 'Имя' },
  { value: 'phone', title: 'Телефон' }
];

const allColumns = [
  { value: 'name', title: 'Имя*' },
  { value: 'phone', title: 'Телефон*' },
  { value: 'email', title: 'Почта' },
  { value: 'birthday', title: 'Дата рождения' },
  { value: 'dopphone', title: 'Дополнительный телефон' },
  { value: 'sex', title: 'Пол' }
  // { value: 'filial', title: 'Филиал' }
];
const CreateExportModal: React.FC<IProps> = (props) => {
  const { closeModal } = props;
  const { t } = useTranslation();
  const { activeFilial } = useTypedSelector((state) => state.filial);
  const [tableMode, setTableMode] = useState(false);
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const [textAreaView, setTextAreaView] = useState<boolean>(false);
  const [columns, setColumns] = useState<IColumnType<ITableData>[]>([]);
  const [columnSelects, setColumnSelects] = useState<{ i: number; val: string }[]>([]);
  const [headerOptions, setHeaderOptions] = useState(
    allColumns.filter((option) => !columnSelects.find((column) => column.val === option.value))
  );
  // const [currHeaderValue, setCurrHeaderValue] = useState<boolean>(false);
  const { setImportResults } = useActions();
  const { refetch: fetchClients } = useClientsQuery();
  // const tableHeaderOptions = useMemo(() => allColumns, []);
  const resetStates = () => {
    setTableMode(false);
    setTableData([]);
    setColumns([]);
    setColumnSelects([]);
    setTextAreaView(false);
  };
  const formik = useFormik({
    initialValues: {
      exportMethod: 'textarea',
      textarea: ''
    },
    validationSchema: validationClientAddSchema,
    onSubmit: () => {},
    validateOnBlur: true
  });

  const resetSelection = (i: number) => {
    const currColumn = columnSelects.find((item) => item.i === i);
    if (currColumn) {
      setColumnSelects((prevColumns) => [...prevColumns.filter((column) => column.i !== i)]);
      setHeaderOptions((prevOptions) => [
        ...prevOptions,
        { value: currColumn?.val || '', title: allColumns.find((column) => column.value === currColumn?.val)?.title || '' }
      ]);
    }
  };
  useEffect(() => {
    if (columnSelects.length > 0) {
      setHeaderOptions(allColumns.filter((option) => !columnSelects.find((column) => column.val === option.value)));
    } else {
      setHeaderOptions(allColumns);
    }
  }, [columnSelects]);
  useEffect(() => {
    if (tableData.length > 0) {
      fillColumns(tableData);
    }
  }, [headerOptions]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleImportExcel = (e: { target: { files: any } }) => {
    const files = e.target.files;

    if (files.length) {
      const file = files?.[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event?.target?.result, { cellDates: true, dateNF: 'dd"."mm"."yyyy' });
        const wb1 = read(event?.target?.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const xlsRows = utils.sheet_to_json(wb.Sheets[sheets[0]], { raw: false, defval: '' });
          const rows = xlsRows.map((rowObj: unknown) => {
            // @ts-ignore
            const vals = Object.values(rowObj);

            return vals && vals.length > 100 ? { ...vals.slice(0, 100) } : { ...vals };
            // return {
            //   col1: vals?.[0]?.toString() ?? '',
            //   col2: vals?.[1]?.toString() ?? '',
            //   col3: vals?.[2]?.toString() ?? '',
            //   col4: vals?.[3]?.toString() ?? '',
            //   col5: vals?.[4]?.toString() ?? '',
            //   col6: vals?.[5]?.toString() ?? ''
            // };
          });

          // @ts-ignore
          setTableData(rows as ITableData[]);
          // @ts-ignore
          fillColumns(rows as ITableData[]);
          handleSubmit();
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const fillColumns = (rows: ITableData[]) => {
    const cols = Object.values(rows?.[0])
      .filter((el) => el)
      .map((el, i) => ({
        key: i.toString(),
        width: 320,
        title: (
          <ExportSelectWrap>
            {columnSelects.find((column) => column.i === i) ? (
              <FlexWithAlign
                $align='center'
                className='filter-btn'
              >
                <CommonButton
                  type='button'
                  typeBtn='gray'
                  size='XS'
                  onClick={() => resetSelection(i)}
                >
                  {t('Сбросить')}
                  <IconInstance name={EIcon.closefill} />
                </CommonButton>
              </FlexWithAlign>
            ) : (
              <></>
            )}
            <ExportSelect>
              <Dropdown
                size={InputType.M}
                currentValue={columnSelects}
                options={headerOptions}
                onChange={(selectedOption) => {
                  setColumnSelects((prev) => {
                    return selectedOption?.additional?.value
                      ? [...prev.filter((item) => item.i !== i), { i, val: selectedOption?.additional?.value }]
                      : [...prev.filter((item) => item.i !== i)];
                  });
                }}
                placeholder={'Выбрать заголовок'}
                className='dropdown-export-clients'
                isReset={true}
              />
            </ExportSelect>
          </ExportSelectWrap>
        )
      }));
    setColumns(cols);
    setTableMode(true);
  };
  const handleSubmit = (e?: SyntheticEvent) => {
    e?.preventDefault();
    if (!tableMode) {
      if (formik.values.exportMethod === 'textarea') {
        const strRows = formik.values.textarea.split('\n');
        const rows = strRows.map((row) => {
          const vals = row.split('\t');

          return {
            ...vals
            // col1: vals?.[0] ?? '',
            // col2: vals?.[1] ?? '',
            // col3: vals?.[2] ?? '',
            // col4: vals?.[3] ?? '',
            // col5: vals?.[4] ?? '',
            // col6: vals?.[5] ?? ''
          };
        });
        fillColumns(rows.filter((row) => row?.[0] && row?.[0] !== ''));
        setTableData(rows.filter((row) => row?.[0] && row?.[0] !== ''));
      }
    } else {
      if (!columnSelects.find((column) => column.val === 'name') || !columnSelects.find((column) => column.val === 'phone')) {
        const colSelects = requireColumns.filter((item) => !columnSelects.find((column) => column.val === item.value));
        toast.error(`Пожалуйста, заполните поля: ${colSelects.map((item) => item.title).join(', ')}`);

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const clients: any = [];
      const strRows = formik.values.exportMethod === 'textarea' ? formik.values.textarea.split('\n') : tableData;
      strRows.map((row) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newClient: any = {
          filial: activeFilial?.id,
          telegram: false,
          viber: false,
          whatsapp: false,
          comstart: 0,
          comfinish: 0,
          comment: '',
          birthday: '',
          state: 21
        };
        // @ts-ignore
        const rowToSend = formik.values.exportMethod === 'textarea' ? row.split('\t') : Object.values(row);
        columnSelects.map((col) => {
          const { i, val } = col;
          newClient[val] = rowToSend[i];
        });
        if (newClient.phone && newClient.phone.indexOf('7') === 0) newClient.phone = '+'.concat('', newClient.phone);
        if (newClient.dopphone && newClient.dopphone.indexOf('7') === 0) newClient.dopphone = '+'.concat('', newClient.dopphone);
        if (newClient.birthday && newClient.birthday.split('-').length > 1) newClient.birthday = newClient.birthday.split('-').join('.');
        if (newClient.phone !== undefined) {
          clients.push(newClient);
        }

        return rowToSend;
      });
      Promise.all(clients.map((client: unknown) => apiPut('/importclient', client)))
        .then(async (data) => {
          setImportResults(data);
          // toast.success('Клиенты успешно добавлены');
          fetchClients();
          closeModal?.();
        })
        .catch((e) => {
          toast.error(e);
        });
    }
  };

  return (
    <Wrapper className={cn(tableMode && 'full', textAreaView && 'paste')}>
      {!tableMode && !textAreaView && (
        <TopWrapper
          $align={'center'}
          $gap={'20px'}
        >
          <NewButton
            typeBtn='close'
            onClick={closeModal}
          >
            <IconInstance name={EIcon.close} />{' '}
          </NewButton>
          <NewText
            $tag={TextType.H2}
            fontWeight={600}
            className={'title-first'}
          >
            {t('Подготовка и загрузка списка клиентов')}
          </NewText>
        </TopWrapper>
      )}

      {tableMode && (
        <TopTableWrapper
          $gap='12px'
          $align='center'
        >
          <NewButton
            typeBtn='close'
            onClick={closeModal}
          >
            <IconInstance name={EIcon.close} />{' '}
          </NewButton>
          <NewText
            color='title'
            $tag={TextType.H2}
          >
            {t('Импорт клиентов')}
          </NewText>
        </TopTableWrapper>
      )}

      {!tableMode ? (
        <>
          {textAreaView ? (
            <>
              <TopTableWrapper
                $gap='12px'
                $align='center'
                className={cn(textAreaView && 'paste')}
              >
                <NewButton
                  typeBtn='close'
                  onClick={closeModal}
                >
                  <IconInstance name={EIcon.close} />{' '}
                </NewButton>
                <NewText
                  color='title'
                  $tag={TextType.H2}
                >
                  {t('Импорт клиентов')}
                </NewText>
              </TopTableWrapper>
              <ExportTextarea
                onBlur={formik.handleBlur}
                placeholder={t('Вставить данные')}
                value={formik.values.textarea}
                onChange={formik.handleChange}
                rows={10}
                name='textarea'
              />
              <ButtonsWrap
                $align='center'
                $justify='flex-end'
                className={cn(textAreaView && 'paste')}
              >
                <NewButton onClick={resetStates}>{t('Отмена')}</NewButton>
                <NewButton
                  typeBtn='black'
                  onClick={handleSubmit}
                >
                  {t('Продолжить')}
                </NewButton>
              </ButtonsWrap>
            </>
          ) : (
            <ChooseItemWrapper>
              <ChooseItem
                $column
                $align='center'
                $justify='flex-end'
                onClick={() => formik.setFieldValue('exportMethod', 'file')}
              >
                <LabelIcon>
                  <IconInstance name={EIcon.documenticon} />
                </LabelIcon>
                <NewText color='grey'>{t('Импортировать клиентов из существующего файла CSV или XLS')}</NewText>
                <StyledButton>{t('Загрузить файл')}</StyledButton>
                <InputFile
                  type='file'
                  name='file'
                  className='custom-file-input'
                  id='inputGroupFile'
                  required
                  onChange={handleImportExcel}
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                />
              </ChooseItem>
              <ChooseItem
                $column
                $align='center'
                $justify='flex-end'
                onClick={() => {
                  setTextAreaView(true);
                  formik.setFieldValue('exportMethod', 'textarea');
                }}
              >
                <LabelIcon>
                  <IconInstance name={EIcon.arrowupoutline} />
                </LabelIcon>
                <NewText color='grey'>{t('Скопировать и вставить данные клиентов в ручную')}</NewText>
                <StyledButton>{t('Вставить данные')}</StyledButton>
              </ChooseItem>
            </ChooseItemWrapper>
          )}
        </>
      ) : (
        <>
          {/* <FlexWithAlign $gap='22px'>
              <div>
                <div
                  className='flex sex-input-wrap'
                  role='group'
                  aria-labelledby='my-radio-group'
                >
                  <label onClick={() => formik.setFieldValue('exportMethod', 'textarea')}>
                    <Text2Xs className={'medium'}>{t('Скопировать/вставить данные')}</Text2Xs>
                    <input
                      onBlur={formik.handleBlur}
                      value={formik.values.exportMethod === 'textarea' ? 'true' : 'false'}
                      onChange={() => formik.setFieldValue('exportMethod', 'textarea')}
                      name='exportMethod'
                      type='radio'
                      defaultChecked={formik.values.exportMethod === 'textarea'}
                    />
                  </label>
                  <label onClick={() => formik.setFieldValue('exportMethod', 'file')}>
                    <Text2Xs className={'medium'}>{t('Загрузить документ')}</Text2Xs>
                    <input
                      onBlur={formik.handleBlur}
                      value={formik.values.exportMethod === 'file' ? 'true' : 'false'}
                      onChange={() => formik.setFieldValue('exportMethod', 'file')}
                      name='exportMethod'
                      type='radio'
                      defaultChecked={formik.values.exportMethod === 'file'}
                    />
                  </label>
                </div>
              </div>
            </FlexWithAlign> */}

          {/* {formik.values.exportMethod === 'textarea' ? (
              <>
                <ExportTextarea
                  onBlur={formik.handleBlur}
                  placeholder={t('Вставить данные из таблицы')}
                  value={formik.values.textarea}
                  onChange={formik.handleChange}
                  rows={10}
                  name='textarea'
                />
              </>
            ) : (
              <ExportFileWrapper>
                <input
                  type='file'
                  name='file'
                  className='custom-file-input'
                  id='inputGroupFile'
                  required
                  onChange={handleImportExcel}
                  accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                />
                <label htmlFor='inputGroupFile'>
                  <IconInstance name={EIcon.importfile} />
                  <Text2Xs className={'medium'}>Перетащите в эту область или загрузите документ до 500 строк таблицы</Text2Xs>
                </label>
              </ExportFileWrapper>
            )} */}
          <FlexWithAlign
            $column
            $gap='20px'
            className={'tableWrapper'}
          >
            <TableWrapper>
              <Table
                data={tableData}
                columns={columns}
              />
            </TableWrapper>
            <ButtonsWrap
              $align='center'
              $justify='flex-end'
            >
              <NewButton onClick={resetStates}>{t('Отмена')}</NewButton>
              <NewButton
                typeBtn='black'
                onClick={handleSubmit}
              >
                {t(`Загрузить (${tableData.filter((item) => item['1'] || item['1'] === '').length})`)}
              </NewButton>
            </ButtonsWrap>
          </FlexWithAlign>
        </>
      )}
    </Wrapper>
  );
};

export default CreateExportModal;
