import React from 'react';

export function SmallEditIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5 3.87869C18.2026 3.87869 17.9174 3.99683 17.7071 4.20712L5.40299 16.5113L4.87439 18.6256L6.98877 18.097L19.2929 5.79291C19.397 5.68878 19.4796 5.56517 19.536 5.42912C19.5923 5.29308 19.6213 5.14727 19.6213 5.00001C19.6213 4.85276 19.5923 4.70695 19.536 4.5709C19.4796 4.43486 19.397 4.31124 19.2929 4.20712C19.1888 4.103 19.0652 4.0204 18.9291 3.96405C18.7931 3.9077 18.6473 3.87869 18.5 3.87869ZM16.2929 2.79291C16.8783 2.20754 17.6722 1.87869 18.5 1.87869C18.9099 1.87869 19.3158 1.95943 19.6945 2.11629C20.0732 2.27315 20.4173 2.50306 20.7071 2.79291C20.997 3.08275 21.2269 3.42684 21.3837 3.80554C21.5406 4.18423 21.6213 4.59011 21.6213 5.00001C21.6213 5.40991 21.5406 5.81579 21.3837 6.19449C21.2269 6.57319 20.997 6.91728 20.7071 7.20712L8.20713 19.7071C8.07897 19.8353 7.91839 19.9262 7.74256 19.9702L3.74256 20.9702C3.40178 21.0553 3.04129 20.9555 2.79291 20.7071C2.54453 20.4587 2.44468 20.0983 2.52988 19.7575L3.52988 15.7575C3.57384 15.5816 3.66476 15.4211 3.79291 15.2929L16.2929 2.79291ZM11.5 20C11.5 19.4477 11.9477 19 12.5 19H21.5C22.0523 19 22.5 19.4477 22.5 20C22.5 20.5523 22.0523 21 21.5 21H12.5C11.9477 21 11.5 20.5523 11.5 20Z'
        fill='currentColor'
      />
    </svg>
  );
}
