import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { iconData } from './CashRegisterIconModal.data';
import { IconItem, IconItemBtn, IconsList, Wrapper } from './CashRegisterIconModal.styled';

import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';
import { getIconByFinanceIcon } from '../../../../../icons/helper-functions/payments-settings.helper';
import { EIcon, IconNew } from '../../../../../icons/medium-new-icons/icon';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { cn } from '../../../../../../shared/lib';

interface IProps {
  closeModal: () => void;
}

const CashRegisterIconModal: FC<IProps> = ({ closeModal }) => {
  const { chosenCashRegisterIcon } = useTypedSelector((state) => state.cashregister);
  const { setChosenCashRegisterIcon } = useActions();
  const { t } = useTranslation();

  return (
    <Wrapper
      $column
      $gap='40px'
    >
      <FlexWithAlign
        $gap='16px'
        $align='center'
      >
        <NewButton
          typeBtn='close'
          onClick={closeModal}
        >
          <IconNew name={EIcon.close} />
        </NewButton>
        <NewText
          $tag={TextType.H2}
          color='title'
          fontWeight={600}
        >
          {t('Редактирование иконки')}
        </NewText>
      </FlexWithAlign>
      <IconsList>
        {iconData.map((icon) => (
          <IconItem key={icon.name}>
            <IconItemBtn
              onClick={() => setChosenCashRegisterIcon(icon.name)}
              className={cn(icon.name === chosenCashRegisterIcon && 'active')}
            >
              {getIconByFinanceIcon(icon.name)}
            </IconItemBtn>
          </IconItem>
        ))}
      </IconsList>
    </Wrapper>
  );
};

export default CashRegisterIconModal;
