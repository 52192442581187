import React from 'react';

export function MoneySendOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.7948 3.09998C18.9466 3.09998 19.5339 3.21338 20.1451 3.54026C20.7094 3.84202 21.1576 4.29023 21.4593 4.85448C21.7611 5.41868 21.8809 5.96246 21.8976 6.94877L21.8996 7.20483V12C21.8996 12.497 21.4967 12.9 20.9996 12.9C20.5408 12.9 20.1622 12.5566 20.1066 12.1129L20.0996 12L20.0986 8.89998H3.89861L3.89961 14.7951C3.89961 15.6753 3.9588 15.9818 4.12716 16.2966C4.26116 16.5472 4.45243 16.7384 4.70299 16.8724C4.99357 17.0278 5.27711 17.0902 6.01127 17.0989L6.20447 17.1H18.8266L17.3632 15.6364C17.041 15.3142 17.0142 14.8085 17.2827 14.4557L17.3632 14.3636C17.6854 14.0414 18.1911 14.0145 18.5438 14.283L18.636 14.3636L21.636 17.3636C21.9582 17.6858 21.985 18.1914 21.7166 18.5442L21.636 18.6364L18.636 21.6364C18.2845 21.9878 17.7147 21.9878 17.3632 21.6364C17.041 21.3142 17.0142 20.8085 17.2827 20.4557L17.3632 20.3636L18.8266 18.9H6.20447C5.14118 18.9 4.55901 18.8033 3.99501 18.5314L3.85411 18.4597C3.28986 18.1579 2.84166 17.7097 2.53989 17.1455C2.23816 16.5813 2.11832 16.0375 2.10167 15.0512L2.09961 14.7951V7.20483C2.09961 6.05294 2.21301 5.46569 2.53989 4.85448C2.84166 4.29023 3.28986 3.84202 3.85411 3.54026C4.41831 3.23852 4.96209 3.11869 5.9484 3.10203L6.20447 3.09998H17.7948ZM17.7948 4.89998H6.20447C5.32431 4.89998 5.01779 4.95917 4.70299 5.12752C4.45243 5.26153 4.26116 5.45279 4.12716 5.70336C3.97175 5.99394 3.90936 6.27747 3.90068 7.01164L3.89961 7.09998H20.0976L20.0985 7.01164C20.0899 6.27747 20.0275 5.99394 19.8721 5.70336C19.7381 5.45279 19.5468 5.26153 19.2962 5.12752C19.0056 4.97212 18.7221 4.90973 17.9879 4.90105L17.7948 4.89998Z'
        fill='currentColor'
      />
    </svg>
  );
}
