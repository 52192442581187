import React from 'react';

export function BellBlueWhite() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='15.5'
        fill='white'
      />
      <rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='15.5'
        stroke='#EBEBEB'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5601 11.1757C10.8061 8.70281 12.9233 7 15.3662 7H16.6333C19.0762 7 21.1934 8.70281 21.4394 11.1757L21.4398 11.1797L21.8631 15.6757L21.8633 15.6778C22.0024 17.0707 22.6038 18.477 23.5535 19.4859C24.0724 20.0373 24.077 20.801 23.8756 21.3479C23.6737 21.8963 23.1556 22.5 22.3407 22.5H18.9583C18.7203 23.9189 17.4863 25 15.9998 25C14.5133 25 13.2793 23.9189 13.0412 22.5H9.65883C8.84393 22.5 8.32586 21.8963 8.12392 21.3479C7.92256 20.801 7.92708 20.0373 8.44605 19.4859C9.39578 18.477 9.99718 17.0707 10.1362 15.6777L10.5601 11.1757ZM22.3407 21C22.3445 21 22.3467 20.9991 22.3467 20.9991C22.3488 20.9983 22.357 20.9948 22.3699 20.9838C22.3984 20.9597 22.4387 20.909 22.468 20.8296C22.497 20.7508 22.5048 20.6712 22.4968 20.6092C22.4892 20.5498 22.4705 20.5239 22.4613 20.5141C21.2653 19.2436 20.5391 17.5187 20.3705 15.8243L20.3701 15.8203L19.9468 11.3243L19.9466 11.3223C19.784 9.69827 18.3885 8.5 16.6333 8.5H15.3662C13.611 8.5 12.2155 9.69831 12.0529 11.3224L12.0527 11.3243L11.629 15.8243C11.4605 17.5187 10.7342 19.2436 9.53827 20.5141C9.52902 20.5239 9.5103 20.5498 9.5027 20.6092C9.49475 20.6712 9.50252 20.7508 9.53153 20.8296C9.56079 20.909 9.60109 20.9597 9.62958 20.9838C9.64254 20.9948 9.65059 20.9983 9.65271 20.9991C9.65271 20.9991 9.65493 21 9.65883 21H22.3407ZM14.5851 22.5C14.791 23.0826 15.3467 23.5 15.9998 23.5C16.6529 23.5 17.2085 23.0826 17.4144 22.5H14.5851Z'
        fill='#2997FF'
      />
    </svg>
  );
}
