import { useQuery } from '@tanstack/react-query';

import { CabinetsService } from '../../../../shared/api/model/service/cabinets.service';
import { CABINETS_QUERY } from '../../../../shared/const/query.const';
import { ICabinet } from '../cabinets.interface';

export function useCabinetsQuery(id: string | undefined) {
  return useQuery({
    queryKey: [CABINETS_QUERY, id],
    queryFn: () => CabinetsService.fetchCabinets(id!),
    staleTime: 1800000000,
    select: (data: ICabinet[]) => data || [],
    enabled: !!id
  });
}
