import { apiGet, apiPost, apiPut } from '../../interceptors';
import { apiDelete } from '../../../../utils/apiInstance';
import { ILesson } from '../../../../store/redux/lessons/lessons.interface';

export const LessonsService = {
  async fetchLessons() {
    const response = await apiGet('/lessons');
    return response.data && response.data.lessons ? response.data.lessons : [];
  },
  async fetchLesson(lessonId: string) {
    const response = await apiGet(`/lessons/${lessonId}`);
    return response.data && response.data.lessons ? response.data.lessons : [];
  },
  async deleteLesson(lessonId: string) {
    return await apiDelete(`/lesson/${lessonId}`);
  },
  async createLesson(data: Omit<ILesson, 'ClientId'>) {
    return await apiPut(`/lesson`, data);
  },
  async closeLesson(lessonId: string) {
    return await apiPost(`/lesson/${lessonId}`, { stage: 4 });
  },
  async updateLessonComfirmation(data: Pick<ILesson, 'paymentFullComplete' | 'comfirmationCopmlete'>, lessonId: string) {
    return await apiPost(`/lesson/${lessonId}`, data);
  },
  async updateLesson(data: Omit<ILesson, 'ClientId'>, lessonId: string) {
    return await apiPost(`lesson/${lessonId}`, data);
  },
  async createBlockTime(data: Pick<ILesson, 'comments' | 'employedid' | 'end' | 'filialId' | 'resourceId' | 'start' | 'productId'>) {
    return await apiPost('/lessons/blocktime', data);
  },
  async updateBlockTime(
    data: Pick<ILesson, 'comments' | 'employedid' | 'end' | 'filialId' | 'resourceId' | 'start' | 'productId'>,
    lessonId: string
  ) {
    return await apiPost(`/lesson/${lessonId}`, data);
  }
};
