export const EditTimeCell = () => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.48852 1.84813C9.78718 1.54946 10.1512 1.40013 10.5805 1.40013C11.0099 1.40013 11.3925 1.5308 11.7285 1.79213L12.4565 2.52013C12.7552 2.8188 12.9045 3.19213 12.9045 3.64013C12.9045 4.08813 12.7739 4.46146 12.5125 4.76013L5.51252 11.7041L2.43252 12.6001C2.24585 12.6375 2.06852 12.5815 1.90052 12.4321C1.73252 12.2828 1.66718 12.1148 1.70452 11.9281L2.54452 8.84813L2.60052 8.73613L9.48852 1.84813ZM3.04852 9.57613L2.37652 11.9281L4.72852 11.2561L3.04852 9.57613ZM9.26452 3.02413L3.32852 8.96013L5.34452 10.9761L11.2805 5.04013L9.26452 3.02413ZM11.2805 2.35213C11.1312 2.16546 10.9259 2.07213 10.6645 2.07213C10.4032 2.07213 10.1792 2.1468 9.99252 2.29613L9.76852 2.52013L11.7845 4.53613L11.9525 4.36813C12.1392 4.18146 12.2419 3.96679 12.2605 3.72413C12.2792 3.48146 12.2139 3.2668 12.0645 3.08013L11.2805 2.35213Z'
        fill='currentColor'
      />
    </svg>
  );
};
