import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sort } from 'rambda';

import { fetchCabinetsAsync, setCabinets, setCabinetsState } from './cabinets.actions';
import { CabinetsState, ICabinet, ICabinetService } from './cabinets.interface';

import { FULFILLED, INIT, PENDING, REJECTED } from '../../../utils/state';

const initialState: CabinetsState = {
  cabinets: [],
  state: INIT,
  activeCabinet: undefined,
  cabinetServices: []
};

export const cabinetsSlice = createSlice({
  name: 'cabinets',
  initialState,
  reducers: {
    setActiveCabinet: (state, action: PayloadAction<ICabinet>) => {
      state.activeCabinet = action.payload;
    },
    setCabinetServices: (state, action: PayloadAction<ICabinetService[]>) => {
      state.cabinetServices = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCabinets, (state, action: PayloadAction<ICabinet[]>) => {
        state.cabinets = action.payload;
      })
      .addCase(setCabinetsState, (state, action: PayloadAction<CabinetsState>) => {
        state.state = action.payload.state;
        if (action.payload.cabinets) {
          state.cabinets = action.payload.cabinets;
        }
      })

      .addCase(fetchCabinetsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchCabinetsAsync.fulfilled, (state, action) => {
        const sorted = sort((a: any, b: any) => a.id - b.id, action.payload);

        state.cabinets = sorted;
        state.activeCabinet = state.cabinets[0];
        state.state = FULFILLED;
      })
      .addCase(fetchCabinetsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer, actions } = cabinetsSlice;
export const { setActiveCabinet } = actions;
