import React from 'react';

export function NewChat() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 4C10.4142 4 10.75 4.33579 10.75 4.75V9.25012H15.25C15.6642 9.25012 16 9.58591 16 10.0001C16 10.4143 15.6642 10.7501 15.25 10.7501H10.75V15.25C10.75 15.6642 10.4142 16 10 16C9.58579 16 9.25 15.6642 9.25 15.25V10.7501H4.75C4.33579 10.7501 4 10.4143 4 10.0001C4 9.58591 4.33579 9.25012 4.75 9.25012H9.25V4.75C9.25 4.33579 9.58579 4 10 4Z'
        fill='#FF7F41'
      />
    </svg>
  );
}
