import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CabinetsState, ICabinet } from './cabinets.interface';
import { CabinetsService } from '../../../shared/api/model/service/cabinets.service';

export const setCabinets = createAction<ICabinet[]>('cabinets/setCabinets');
export const setCabinetsState = createAction<CabinetsState>('cabinets/setCabinetsState');

export const setActiveCabinet = createAction<ICabinet>('cabinets/setActiveCabinet');

export const fetchCabinetsAsync = createAsyncThunk('cabinets/fetchCabinetsAsync', async (id: string) => {
  return await CabinetsService.fetchCabinets(id);
});
