import styled from 'styled-components';

import {
  RADIUS_M,
  DURATION,
  HEIGHT_INPUT,
  SHADOW_DROPDOWN,
  SHADOW_INPUT,
  FONT_S,
  FONT_M,
  FONT_XS,
  HEIGHT_INPUT_M,
  Z_INDEX
} from '../../../../styles';
import { closeDropdownMenuTop, openDropdownMenuTop } from '../../../../utils/styleUtils';
import { IInputStyles } from '../../Input/ui/Input.styled';
export const InputContainer = styled.div<IInputStyles>`
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;
  appearance: none;
  border-radius: ${RADIUS_M};
  text-align: left;
  height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  min-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  max-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
`;

export const TimeList = styled.div<{ $type?: string }>`
  transform-origin: bottom;
  top: ${(props) => (props.$type === 'bottom' ? '40px' : '-230px')};
  background: ${(props) => props.theme.color.elevation01};
  max-height: fit-content;
  overflow: hidden;
  position: absolute;
  z-index: ${Z_INDEX};
  display: flex;
  flex-direction: column;
  min-width: 210px;
  max-width: 210px;
  width: 100%;
  margin-top: 0.5rem;
  border-radius: ${RADIUS_M};
  border: 1px solid ${(props) => props.theme.color.base04};
  isolation: isolate;
  pointer-events: auto;
  box-shadow: ${SHADOW_DROPDOWN};
  visibility: hidden;
  * {
    visibility: hidden;
  }
  animation: ${closeDropdownMenuTop} ${DURATION} ease;

  padding: 0;
  &.open {
    * {
      visibility: visible;
    }
    visibility: visible;
    animation: ${openDropdownMenuTop} ${DURATION} ease;
  }
`;
export const InputContent = styled.div<IInputStyles>`
  padding: 1.25rem 1rem 0;
  background: transparent;
  inset: 0;
  transition: box-shadow 0.01s;
  margin: 0;
  border-width: 0;
  border-radius: inherit;
  background: none;
  font: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? FONT_M : FONT_S;
        case 'm':
          return props.size ? FONT_S : FONT_S;
        case 's':
          return props.size ? FONT_S : FONT_S;
        default:
          return FONT_S;
      }
    } else {
      return FONT_S;
    }
  }};
  color: ${(props) => props.theme.text.text01};
  caret-color: currentColor;
  outline: none;
  appearance: none;
  word-break: keep-all;
  -webkit-text-fill-color: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: transparent;
  border-inline-start-width: 0rem;
  border-inline-end-width: 0rem;
  text-indent: 0px;
  text-align: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-transform: inherit;
  resize: none;

  &.active {
    border-color: transparent;
  }

  &.active .error,
  &.error {
    border-color: ${(props) => props.theme.color.danger};
    &:focus + label {
      color: ${(props) => props.theme.color.danger};
    }
  }

  &:focus {
    & ~ div {
      svg {
        color: ${(props) => props.theme.color.mainLight};
      }
    }
    &[type='date'] {
      &::-webkit-datetime-edit-year-field,
      &::-webkit-datetime-edit-month-field,
      &::-webkit-datetime-edit-day-field {
        color: ${(props) => props.theme.color.mainDark};
      }
    }
  }
  &:disabled {
    opacity: 0.3;
  }
  &.error {
    border: 1.5px solid #ffe1e1;
    background: #ffe1e1;
  }
  &[type='date']::-webkit-calendar-picker-indicator {
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }

  &[type='number']::-webkit-textfield-decoration-container {
  }
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-datetime-edit-year-field,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field {
    color: transparent;
  }
`;
export const Label = styled.label<IInputStyles>`
  width: 100%;
  max-width: 100%;
  cursor: pointer;

  display: block;
  transition-property: box-shadow, background;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  background: ${(props) => props.theme.color.inverse};
  color: ${(props) => props.theme.text.text01};
  box-shadow: ${SHADOW_INPUT};
  position: relative;
  appearance: none;
  border-radius: ${RADIUS_M};
  text-align: left;
  line-height: 1.25rem;
  height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  min-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  max-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  &:hover {
    box-shadow: 0 0.125rem 0.3125rem #00000029;
  }
  &:after {
    transition-property: color;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    color: ${(props) => props.theme.color.base03};
    position: absolute;
    inset: 0;
    content: '';
    border-radius: inherit;
    border: 1px solid currentColor;
    pointer-events: none;
  }
  &.focused {
    &:after {
      border-width: 2px;
      color: ${(props) => props.theme.color.primary};
    }
  }

  &:focus-within {
    span {
      top: 18px;
      font: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'l':
              return props.size ? FONT_S : FONT_XS;
            case 'm':
              return props.size ? FONT_XS : FONT_XS;
            case 's':
              return props.size ? FONT_XS : FONT_XS;
            default:
              return FONT_XS;
          }
        } else {
          return FONT_XS;
        }
      }};
      position: absolute;
      line-height: 1.25rem;
      left: 1rem;
      z-index: 2;
    }
    &:after {
      border-width: 2px;
      color: ${(props) => props.theme.color.primary};
    }
  }
  span {
    top: 15px;
    font: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? FONT_S : FONT_XS;
          case 'm':
            return props.size ? FONT_XS : FONT_XS;
          case 's':
            return props.size ? FONT_XS : FONT_XS;
          default:
            return FONT_XS;
        }
      } else {
        return FONT_XS;
      }
    }};
    position: absolute;
    line-height: 1.25rem;
    left: 1rem;
    z-index: 2;
    transform: translateY(-50%);
    pointer-events: none;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    transition-property: transform, top, font;
    color: ${(props) => props.theme.text.text01};
  }
  &.active,
  &.error {
    span {
      color: #6c6f71;
      top: 15px;
      border-radius: 4px;
      font: ${(props) => {
        if (props.size !== undefined) {
          switch (props.size) {
            case 'l':
              return props.size ? FONT_S : FONT_XS;
            case 'm':
              return props.size ? FONT_XS : FONT_XS;
            case 's':
              return props.size ? FONT_XS : FONT_XS;
            default:
              return FONT_XS;
          }
        } else {
          return FONT_XS;
        }
      }};
      background: transparent;
      z-index: 2;
    }
  }
  &.error {
    span {
      color: ${(props) => props.theme.color.danger};
    }
  }
`;
export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0rem;
  height: 100%;
  width: fit-content;
  margin-left: auto;
  justify-content: flex-end;
  padding-right: 12px;
  border: none !important;
  outline: none !important;
  z-index: 3;
  align-items: center;
  & .age {
    padding: 4px;
    background-color: ${(props) => props.theme.color.base01};
    position: absolute;
    top: -32px;
    right: -10px;
    color: ${(props) => props.theme.color.mainLight};
    white-space: nowrap;
    &.record {
      top: 0;
      right: -24px;
    }
  }

  svg {
    color: ${(props) => props.theme.color.secondaryDark};
    display: block;
  }
  &.active {
    svg {
      color: ${(props) => props.theme.color.mainLight};
    }
  }
  &.active.error,
  &.error {
    svg {
      color: ${(props) => props.theme.color.danger};
    }
    .age {
      opacity: 0;
    }
  }
`;

export const ErrorText = styled.p<IInputStyles>`
  position: absolute;
  top: 95%;
  left: 5px;
  font: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? FONT_M : FONT_S;
        case 'm':
          return props.size ? FONT_S : FONT_S;
        case 's':
          return props.size ? FONT_S : FONT_S;
        default:
          return FONT_S;
      }
    } else {
      return FONT_S;
    }
  }};
  color: ${(props) => props.theme.color.danger};
`;
