import React from 'react';

export function QuestionEmpty() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 1.66667C5.39763 1.66667 1.66667 5.39763 1.66667 10C1.66667 14.6024 5.39763 18.3333 10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6024 1.66667 10 1.66667ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM10 6.11111C9.07953 6.11111 8.33333 6.8573 8.33333 7.77778C8.33333 8.23802 7.96024 8.61111 7.5 8.61111C7.03976 8.61111 6.66667 8.23802 6.66667 7.77778C6.66667 5.93683 8.15905 4.44444 10 4.44444C11.841 4.44444 13.3333 5.93683 13.3333 7.77778C13.3333 9.33098 12.271 10.6361 10.8333 11.0061V11.3889C10.8333 11.8491 10.4602 12.2222 10 12.2222C9.53976 12.2222 9.16667 11.8491 9.16667 11.3889V10.2778C9.16667 9.81754 9.53976 9.44444 10 9.44444C10.9205 9.44444 11.6667 8.69825 11.6667 7.77778C11.6667 6.8573 10.9205 6.11111 10 6.11111ZM10 13.3333C10.4602 13.3333 10.8333 13.7064 10.8333 14.1667V14.7222C10.8333 15.1825 10.4602 15.5556 10 15.5556C9.53976 15.5556 9.16667 15.1825 9.16667 14.7222V14.1667C9.16667 13.7064 9.53976 13.3333 10 13.3333Z'
        fill='currentColor'
      />
    </svg>
  );
}
