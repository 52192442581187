import { PropsWithChildren } from 'react';

import { SidebarContent, Wrapper } from './CommonSidebar.styled';
import CreateClientModal from './create/client/CreateClientModal';
import CreateClientSidebar from './create/client/sidebar/CreateClientSidebar';
import CreateEmployeerSidebar from './create/employeers/sidebar/CreateEmployeerSidebar';
import CreateKanbanTaskModal from './create/kanban/CreateKanbanTaskModal';
import CreateServiceSidebar from './create/service/sidebar/CreateServiceSidebar';
import CreateTimeTable from './create/timetable/CreateTimeTable';
import EditFilialTimeModal from './update/filial/EditFilialTimeModal';

import { useActions } from '../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../shared/lib/hooks/useTypedSelector';

const CommonSidebar: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { sidebarPayload, showSidebar, sidebarName } = useTypedSelector((state) => state.sidebar);
  const { closeSidebar } = useActions();

  const sidebars = {
    CREATE_EMPLOYEE: (
      <CreateEmployeerSidebar
        sidebarPayload={sidebarPayload}
        closeSidebar={closeSidebar}
      />
    ),

    EDIT_EMPLOYEE: (
      <CreateEmployeerSidebar
        sidebarPayload={sidebarPayload}
        closeSidebar={closeSidebar}
        edit={true}
      />
    ),
    ADD_TIMETABLE_SCHEDULE: (
      <CreateTimeTable
        modalPayload={sidebarPayload}
        closeModal={closeSidebar}
      />
    ),
    EDIT_FILIAL_TIME: (
      <EditFilialTimeModal
        closeModal={closeSidebar}
        modalPayload={sidebarPayload}
      />
    ),
    CREATE_KANBAN_TASK: (
      <CreateKanbanTaskModal
        closeModal={closeSidebar}
        modalPayload={sidebarPayload}
      />
    ),
    CREATE_SERVICE: (
      <CreateServiceSidebar
        sidebarPayload={sidebarPayload}
        closeSidebar={closeSidebar}
      />
    ),
    EDIT_SERVICE: (
      <CreateServiceSidebar
        sidebarPayload={sidebarPayload}
        closeSidebar={closeSidebar}
        edit={true}
      />
    ),
    CREATE_CLIENT: (
      <CreateClientModal
        closeModal={closeSidebar}
        modalPayload={sidebarPayload}
      />
    ),
    EDIT_CLIENT: (
      <CreateClientSidebar
        sidebarPayload={sidebarPayload}
        closeSidebar={closeSidebar}
        edit={true}
      />
    ),
    '': ''
  };

  return (
    <>
      {showSidebar && (
        <>
          <Wrapper>
            <SidebarContent>
              {sidebars[sidebarName]}
              {children}
            </SidebarContent>
          </Wrapper>
        </>
      )}
    </>
  );
};

export default CommonSidebar;
