export const PaymentIcon29 = () => {
  return (
    <svg
      width='17'
      height='29'
      viewBox='0 0 17 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 5.95283V2.429L15.0832 0.570325V4.68314L1 5.95283Z'
        fill='white'
        stroke='currentColor'
      />
      <path
        d='M4.61354 11.6417V12.3613L5.05968 11.7967C5.27965 11.5184 5.872 11.0031 6.7202 10.557C7.56347 10.1135 8.63791 9.75 9.81678 9.75C11.8197 9.75 13.3289 10.2879 14.342 11.3953C15.3591 12.507 15.9165 14.2362 15.9165 16.7052C15.9165 19.1741 15.3583 20.9089 14.3389 22.026C13.3236 23.1386 11.8108 23.6816 9.80296 23.6816C8.61615 23.6816 7.54129 23.3244 6.69849 22.886C5.85085 22.4452 5.25863 21.9333 5.03193 21.648L4.58619 21.087V21.8035V28.1665H0.75V10.0741H4.61354V11.6417ZM8.26338 20.5798H8.51338V20.5708C9.69986 20.5424 10.5579 20.3026 11.1143 19.6946C11.703 19.0514 11.895 18.0577 11.895 16.6946C11.895 15.3307 11.6997 14.336 11.1097 13.6915C10.5138 13.0405 9.57482 12.8094 8.26338 12.8094C6.95099 12.8094 6.00917 13.0443 5.40979 13.6962C4.81604 14.3421 4.61658 15.3364 4.61658 16.6946C4.61658 18.0532 4.81768 19.0474 5.41219 19.6931C6.01224 20.3449 6.95384 20.5798 8.26338 20.5798Z'
        fill='white'
        stroke='currentColor'
        strokeWidth='0.5'
      />
    </svg>
  );
};
