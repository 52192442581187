export const WalletIcon = () => {
  return (
    <svg
      width='15'
      height='16'
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 3.00078C0 1.78575 0.984974 0.800781 2.2 0.800781H9.4C9.73137 0.800781 10 1.06941 10 1.40078C10 1.73215 9.73137 2.00078 9.4 2.00078H2.2C1.64772 2.00078 1.2 2.4485 1.2 3.00078V3.20078H12.2C13.415 3.20078 14.4 4.18575 14.4 5.40078V13.0008C14.4 14.2158 13.415 15.2008 12.2 15.2008H2.2C0.984974 15.2008 0 14.2158 0 13.0008V3.00078ZM1.2 4.40078V13.0008C1.2 13.5531 1.64772 14.0008 2.2 14.0008H12.2C12.7523 14.0008 13.2 13.5531 13.2 13.0008V5.40078C13.2 4.8485 12.7523 4.40078 12.2 4.40078H1.2ZM10.6 8.80078C10.3791 8.80078 10.2 8.97987 10.2 9.20078C10.2 9.42169 10.3791 9.60078 10.6 9.60078C10.8209 9.60078 11 9.42169 11 9.20078C11 8.97987 10.8209 8.80078 10.6 8.80078ZM9 9.20078C9 8.31713 9.71634 7.60078 10.6 7.60078C11.4837 7.60078 12.2 8.31713 12.2 9.20078C12.2 10.0844 11.4837 10.8008 10.6 10.8008C9.71634 10.8008 9 10.0844 9 9.20078Z'
        fill='currentColor'
      />
    </svg>
  );
};
