export const PaymentIcon23 = () => {
  return (
    <svg
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33.4333 25.9354L32.4498 25.9356L28.9335 25.9359L18.2627 25.9363L7.59159 25.9365L4.07495 25.9365L3.08968 25.9365H2.8301H2.76354H2.7467H2.74246H2.7414C2.74116 25.9365 2.74104 25.9365 2.74104 26.4365L33.4333 25.9354ZM9.80496 8.5H39.7855L37.909 13.6472H7.92848L9.80496 8.5ZM6.44467 17.7182H36.4252L34.5488 22.8642H4.56827L6.44467 17.7182Z'
        fill='white'
        stroke='currentColor'
      />
    </svg>
  );
};
