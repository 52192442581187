export function DownloadCrossBadgeOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C12.497 2 12.9 2.40294 12.9 2.9V13.8191L13.6448 13.0222C13.9842 12.659 14.5537 12.6398 14.9169 12.9792C15.28 13.3186 15.2992 13.8881 14.9598 14.2513L12.6575 16.7146C12.49 16.8937 12.2567 16.9968 12.0115 16.9999C11.7663 17.0031 11.5304 16.906 11.3584 16.7312L5.26077 10.5336C4.91217 10.1792 4.9168 9.6094 5.27112 9.2608C5.62544 8.9122 6.19527 8.91683 6.54387 9.27115L11.1 13.902V2.9C11.1 2.40294 11.5029 2 12 2Z'
        fill='currentColor'
      />
      <path
        d='M4.00232 20C4.00232 19.503 4.40526 19.1 4.90232 19.1H19.0976C19.5947 19.1 19.9976 19.503 19.9976 20C19.9976 20.4971 19.5947 20.9 19.0976 20.9H4.90232C4.40526 20.9 4.00232 20.4971 4.00232 20Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 8C24 10.7614 21.7614 13 19 13C16.2386 13 14 10.7614 14 8C14 5.23858 16.2386 3 19 3C21.7614 3 24 5.23858 24 8ZM16.5637 6.83638C16.2122 6.48491 16.2122 5.91506 16.5637 5.56359C16.9151 5.21212 17.485 5.21212 17.8364 5.56359L19.0001 6.72723L20.1637 5.5636C20.5152 5.21213 21.085 5.21213 21.4365 5.5636C21.788 5.91507 21.788 6.48492 21.4365 6.83639L20.2729 8.00002L21.4364 9.1636C21.7879 9.51508 21.7879 10.0849 21.4364 10.4364C21.085 10.7879 20.5151 10.7879 20.1637 10.4364L19.0001 9.27282L17.8365 10.4364C17.485 10.7879 16.9152 10.7879 16.5637 10.4364C16.2122 10.0849 16.2122 9.51508 16.5637 9.16361L17.7273 8.00002L16.5637 6.83638Z'
        fill='currentColor'
      />
    </svg>
  );
}
