import React from 'react';

export function TapBarSchedule() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.09667 2.33325C8.58184 2.33325 8.97514 2.7115 8.97514 3.17808V4.88227C9.75437 4.86773 10.6278 4.86773 11.6106 4.86773H16.2958C17.2785 4.86773 18.152 4.86773 18.9312 4.88227V3.17808C18.9312 2.7115 19.3245 2.33325 19.8097 2.33325C20.2948 2.33325 20.6882 2.7115 20.6882 3.17808V4.95702C22.374 5.08684 23.4808 5.40547 24.2939 6.18744C25.107 6.96941 25.4384 8.03377 25.5734 9.65509L25.6663 10.4999H3.41166H2.33301V9.65509C2.468 8.03377 2.79932 6.96941 3.61243 6.18744C4.42555 5.40547 5.53231 5.08684 7.21819 4.95702V3.17808C7.21819 2.7115 7.6115 2.33325 8.09667 2.33325Z'
        fill='#0178FF'
      />
      <path
        opacity='0.5'
        d='M25.6658 16.3334V14.0001C25.6658 13.0212 25.662 11.2761 25.647 10.5H2.34371C2.32865 11.2761 2.33246 13.0212 2.33246 14.0001V16.3334C2.33246 20.7332 2.33246 22.9331 3.69929 24.2999C5.06613 25.6668 7.26602 25.6668 11.6658 25.6668H16.3324C20.7323 25.6668 22.9321 25.6668 24.299 24.2999C25.6658 22.9331 25.6658 20.7332 25.6658 16.3334Z'
        fill='#C7D9F1'
      />
      <path
        d='M21.0003 19.8334C21.0003 20.4778 20.478 21.0001 19.8337 21.0001C19.1893 21.0001 18.667 20.4778 18.667 19.8334C18.667 19.1891 19.1893 18.6667 19.8337 18.6667C20.478 18.6667 21.0003 19.1891 21.0003 19.8334Z'
        fill='#0178FF'
      />
      <path
        d='M21.0003 15.1667C21.0003 15.811 20.478 16.3333 19.8337 16.3333C19.1893 16.3333 18.667 15.811 18.667 15.1667C18.667 14.5223 19.1893 14 19.8337 14C20.478 14 21.0003 14.5223 21.0003 15.1667Z'
        fill='#0178FF'
      />
      <path
        d='M15.1663 19.8334C15.1663 20.4778 14.644 21.0001 13.9997 21.0001C13.3553 21.0001 12.833 20.4778 12.833 19.8334C12.833 19.1891 13.3553 18.6667 13.9997 18.6667C14.644 18.6667 15.1663 19.1891 15.1663 19.8334Z'
        fill='#0178FF'
      />
      <path
        d='M15.1663 15.1667C15.1663 15.811 14.644 16.3333 13.9997 16.3333C13.3553 16.3333 12.833 15.811 12.833 15.1667C12.833 14.5223 13.3553 14 13.9997 14C14.644 14 15.1663 14.5223 15.1663 15.1667Z'
        fill='#0178FF'
      />
      <path
        d='M9.33333 19.8334C9.33333 20.4778 8.81099 21.0001 8.16667 21.0001C7.52234 21.0001 7 20.4778 7 19.8334C7 19.1891 7.52234 18.6667 8.16667 18.6667C8.81099 18.6667 9.33333 19.1891 9.33333 19.8334Z'
        fill='#0178FF'
      />
      <path
        d='M9.33333 15.1667C9.33333 15.811 8.81099 16.3333 8.16667 16.3333C7.52234 16.3333 7 15.811 7 15.1667C7 14.5223 7.52234 14 8.16667 14C8.81099 14 9.33333 14.5223 9.33333 15.1667Z'
        fill='#0178FF'
      />
    </svg>
  );
}
