import { FC, useEffect, useState } from 'react';
import { cn } from '../../lib';
import NewText from '../NewText/ui/NewText';
import { INewRadioBtns } from './NewRadioBtns.props';
import { CustomRadioInput, InputWrap, RadioBtnItem, RadioBtnItemLabel, RadioBtnsList, Wrapper } from './NewRadioBtns.styled';

const NewRadioBtns: FC<INewRadioBtns> = ({ name, options, onChange, selectedValue, className }) => {
  const [currentValue, setCurrentValue] = useState<string>(selectedValue || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setCurrentValue(value);
    onChange(value);
  };
  useEffect(() => {
    if (selectedValue) {
      setCurrentValue(selectedValue);
    }
  }, [selectedValue]);

  return (
    <Wrapper className={cn(className && `${className}`)}>
      <RadioBtnsList>
        {options.map((option) => (
          <RadioBtnItem key={option.value}>
            <RadioBtnItemLabel>
              <InputWrap>
                <input
                  type='radio'
                  name={name}
                  value={option.value}
                  checked={currentValue === option.value}
                  onChange={handleChange}
                />
                <CustomRadioInput className='custom-radio-input'></CustomRadioInput>
              </InputWrap>
              <NewText color='title'>{option.label}</NewText>
            </RadioBtnItemLabel>
          </RadioBtnItem>
        ))}
      </RadioBtnsList>
    </Wrapper>
  );
};

export default NewRadioBtns;
