import React from 'react';

export function ProfileUser() {
  return (
    <svg
      viewBox='0 0 48 48'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.3 18.32c.04.07.07.13.14.2.26.52-.13 1.56-1.43 3l-.13.9c0 .92-.52 1.7-1.56 2.22 0 0-.9.52-1.55.65H23.6c-.06 0-.4-.08-.52-.13-.65-.26-1.43-.65-1.43-.65a3 3 0 01-1.55-2.21l-.13-.92c-1.04-1.43-1.43-2.34-1.17-2.99 0-.13.13-.26.26-.4.26-.12.26-.38.26-.64l-.4-2.21c-.12-.53-.12-.92.14-1.3.13-.27.39-.4.65-.53h.39c.42-.75.85-1.15 1.13-1.4l.16-.16c.78-.65 2.08-.91 3.9-.65 2.72.39 4.15 1.17 4.15 2.34 0 .13-.04.26-.07.4-.03.12-.06.25-.06.38l-.4 3.26c-.12.26 0 .52.27.65.06.06.1.13.13.2zm2.99 10.74a.4.4 0 00.26.14c1.17.52 1.56 1.43 1.43 2.73v3.9a1.6 1.6 0 01-.65 1.3A16.35 16.35 0 0124.25 40h-.52a15.4 15.4 0 01-9.08-2.86 1.6 1.6 0 01-.65-1.3v-3.91c0-1.3.39-2.21 1.56-2.73.13-.14.13-.14.26-.14l5.44-2.2a1.8 1.8 0 011.17 0c1.04.38 2.08.38 3.24 0a1.8 1.8 0 011.17 0l5.45 2.2z'
        fill='currentColor'
      ></path>
    </svg>
  );
}
