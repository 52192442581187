import React, { PropsWithChildren } from 'react';
import { AppearanceArrow, Button, ButtonOptions, ButtonWrapper } from './CommonButton.styled';
import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import { useOutside } from '../../../lib/hooks/useOutside';
import { cn } from '../../../lib';

export interface IButtonProps extends PropsWithChildren {
  color?: string;
  fullWidth?: boolean;
  padding?: string;
  className?: string;
  border?: string;
  position?: string;
  gap?: string;
  size?: 'L' | 'M' | 'S' | 'XS';
  justifyContent?: string;
  onClick?: (arg?: any) => void;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  typeBtn?: 'black' | 'primary' | 'accent' | 'gray' | 'secondary' | 'flat' | 'success' | 'danger' | 'outline'| 'white';
  onlyIcon?: boolean;
  style?: any;
  options?: { label: string; onClick: () => void }[];
  appearance?: boolean;
  onMouseEnter?: ((arg: any) => void) | undefined;
  onMouseLeave?: ((arg: any) => void) | undefined;
}

export default function CommonButton(props: IButtonProps) {
  const {
    color,
    padding,
    onMouseEnter,
    onMouseLeave,
    style,
    className,
    size,
    border,
    options,
    appearance,
    gap,
    typeBtn,
    children,
    fullWidth,
    onClick,
    disabled,
    type,
    position,
    onlyIcon
  } = props;
  const { ref, isShow, setIsShow } = useOutside(false);

  const handleMouseEnter = (arg: any) => {
    if (onMouseEnter) {
      onMouseEnter(arg);
    }
  };

  const handleMouseLeave = (arg: any) => {
    if (onMouseLeave) {
      onMouseLeave(arg);
    }
  };


  return (
    <ButtonWrapper
      $align={'center'}
      $gap={'2px'}
      $fullWidth={fullWidth}
    >
      <Button
        style={style}
        $color={color || 'mainLight'}
        $fullWidth={fullWidth}
        $onlyIcon={onlyIcon}
        $gap={gap}
        $border={border}
        onMouseEnter={onMouseEnter ? handleMouseEnter : undefined}
        onMouseLeave={onMouseLeave ? handleMouseLeave : undefined}
        onClick={onClick}
        className={className}
        disabled={disabled}
        type={type}
        $size={size}
        $typeBtn={typeBtn}
        $position={position}
        $appearance={appearance}
        $padding={padding}
      >
        {children}
      </Button>
      {appearance && (
        <AppearanceArrow
          $size={size}
          ref={ref}
          onClick={() => setIsShow(!isShow)}
          $typeBtn={typeBtn}
        >
          <IconInstance name={EIcon.chevrondownsmall} />
        </AppearanceArrow>
      )}
      {appearance && options && (
        <ButtonOptions
          className={cn(isShow ? 'open' : 'close')}
          $column
          $gap={'12px'}
        >
          {options.map((item) => (
            <CommonButton
              typeBtn={'primary'}
              key={item.label}
              size={'S'}
              onClick={item.onClick}
            >
              {item.label}
            </CommonButton>
          ))}
        </ButtonOptions>
      )}
    </ButtonWrapper>
  );
}
