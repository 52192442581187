export const ColorIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask
        id='path-1-inside-1_2547_14668'
        fill='white'
      >
        <path d='M0.00390625 3C0.00390625 1.34315 1.34705 0 3.00391 0H12.0039V12H0.00390625V3Z' />
      </mask>
      <path
        d='M0.00390625 3C0.00390625 1.34315 1.34705 0 3.00391 0H12.0039V12H0.00390625V3Z'
        fill='#FECD49'
      />
      <path
        d='M-0.996094 3C-0.996094 0.790861 0.794767 -1 3.00391 -1H12.0039V1H3.00391C1.89934 1 1.00391 1.89543 1.00391 3H-0.996094ZM12.0039 12H0.00390625H12.0039ZM-0.996094 12V3C-0.996094 0.790861 0.794767 -1 3.00391 -1V1C1.89934 1 1.00391 1.89543 1.00391 3V12H-0.996094ZM12.0039 0V12V0Z'
        fill='black'
        fillOpacity='0.15'
        mask='url(#path-1-inside-1_2547_14668)'
      />
      <mask
        id='path-3-inside-2_2547_14668'
        fill='white'
      >
        <path d='M12.0039 0H21.0039C22.6608 0 24.0039 1.34315 24.0039 3V12H12.0039V0Z' />
      </mask>
      <path
        d='M12.0039 0H21.0039C22.6608 0 24.0039 1.34315 24.0039 3V12H12.0039V0Z'
        fill='#FC6E86'
      />
      <path
        d='M12.0039 -1H21.0039C23.213 -1 25.0039 0.790861 25.0039 3H23.0039C23.0039 1.89543 22.1085 1 21.0039 1H12.0039V-1ZM24.0039 12H12.0039H24.0039ZM12.0039 12V0V12ZM21.0039 -1C23.213 -1 25.0039 0.790861 25.0039 3V12H23.0039V3C23.0039 1.89543 22.1085 1 21.0039 1V-1Z'
        fill='black'
        fillOpacity='0.15'
        mask='url(#path-3-inside-2_2547_14668)'
      />
      <mask
        id='path-5-inside-3_2547_14668'
        fill='white'
      >
        <path d='M0.00390625 12H12.0039V24H3.00391C1.34705 24 0.00390625 22.6569 0.00390625 21V12Z' />
      </mask>
      <path
        d='M0.00390625 12H12.0039V24H3.00391C1.34705 24 0.00390625 22.6569 0.00390625 21V12Z'
        fill='#82DA69'
      />
      <path
        d='M0.00390625 12H12.0039H0.00390625ZM12.0039 25H3.00391C0.794767 25 -0.996094 23.2091 -0.996094 21H1.00391C1.00391 22.1046 1.89934 23 3.00391 23H12.0039V25ZM3.00391 25C0.794767 25 -0.996094 23.2091 -0.996094 21V12H1.00391V21C1.00391 22.1046 1.89934 23 3.00391 23V25ZM12.0039 12V24V12Z'
        fill='black'
        fillOpacity='0.15'
        mask='url(#path-5-inside-3_2547_14668)'
      />
      <mask
        id='path-7-inside-4_2547_14668'
        fill='white'
      >
        <path d='M12.0039 12H24.0039V21C24.0039 22.6569 22.6608 24 21.0039 24H12.0039V12Z' />
      </mask>
      <path
        d='M12.0039 12H24.0039V21C24.0039 22.6569 22.6608 24 21.0039 24H12.0039V12Z'
        fill='#58B5FE'
      />
      <path
        d='M12.0039 12H24.0039H12.0039ZM25.0039 21C25.0039 23.2091 23.213 25 21.0039 25H12.0039V23H21.0039C22.1085 23 23.0039 22.1046 23.0039 21H25.0039ZM12.0039 24V12V24ZM25.0039 12V21C25.0039 23.2091 23.213 25 21.0039 25V23C22.1085 23 23.0039 22.1046 23.0039 21V12H25.0039Z'
        fill='black'
        fillOpacity='0.15'
        mask='url(#path-7-inside-4_2547_14668)'
      />
    </svg>
  );
};
