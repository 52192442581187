export function SortOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.6 18.3984C16.1029 18.3984 15.7 17.9955 15.7 17.4984L15.7 9.05279L13.6364 11.1164C13.2849 11.4679 12.715 11.4679 12.3636 11.1164C12.0121 10.7649 12.0121 10.1951 12.3636 9.8436L15.8929 6.31431C16.2834 5.92379 16.9166 5.92378 17.3071 6.31432L20.8364 9.84361C21.1878 10.1951 21.1878 10.7649 20.8364 11.1164C20.4849 11.4679 19.915 11.4679 19.5636 11.1164L17.5 9.0528L17.5 17.4984C17.5 17.9955 17.0971 18.3984 16.6 18.3984Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.59998 17.1184L6.59997 6.9C6.59997 6.40294 7.00291 6 7.49997 6C7.99703 6 8.39997 6.40294 8.39997 6.9L8.39999 17.1184C8.39999 17.6155 7.99704 18.0184 7.49999 18.0184C7.00293 18.0184 6.59999 17.6155 6.59998 17.1184Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7364 13.282C12.0879 13.6335 12.0879 14.2034 11.7364 14.5548L8.20709 18.0841C7.81657 18.4746 7.1834 18.4746 6.79288 18.0841L3.2636 14.5548C2.91213 14.2033 2.91213 13.6335 3.2636 13.282C3.61508 12.9305 4.18493 12.9306 4.5364 13.282L7.49999 16.2456L10.4636 13.282C10.8151 12.9306 11.3849 12.9306 11.7364 13.282Z'
        fill='currentColor'
      />
    </svg>
  );
}
