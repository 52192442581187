import { ECardTag, ECardType, ICardProps } from './Card.props';
import { cn } from '../../../lib';
import { StyledCard } from './Card.styled';
import { CloseBtn } from '../../../../components/shared/note/Note.styled';
import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';

/** Компонент карточки в приложении */
export const Card = ({ tag: Tag = ECardTag.DIV, appearance = ECardType.PRIMARY, children, className, ...props }: ICardProps) => {
  return (
    <StyledCard
      as={Tag as keyof JSX.IntrinsicElements}
      className={cn(className, 'card', `appearance__${appearance}`)}
      appearance={appearance}
      {...props}
    >
      <>
        {children}
        <CloseBtn>
          <IconInstance name={EIcon.close} />
        </CloseBtn>
      </>
    </StyledCard>
  );
};
