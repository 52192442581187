import styled from 'styled-components';
import { PageTitle } from '../../../../../utils/styleUtils';

export const ProfileManagementWrapper = styled.div`
  //max-width: 482px;
  margin: 0 auto;
  > div {
    > p {
      line-height: 16px;
      font-size: 13px;
      margin-top: 26px;
    }
  }
`;
export const Title = styled(PageTitle)`
  margin-bottom: 24px;
`;
