import { ReactNode } from 'react';

export enum TextType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  SPAN = 'span'
}

export enum TextCustomType {
  T28 = 't28',
  T24 = 't24',
  T18 = 't18',
  T16 = 't16',
  T14 = 't14',
  T12 = 't12',
  T10 = 't10'
}

export interface TextProps {
  children?: ReactNode;
  $tag?: TextType;
  className?: string;
  fontFamily?: string;
  color?: 'avatarGrey' | 'primary' | 'title' | 'white' | 'black' | 'grey' | 'greySecondary' | 'red' | 'grey73' | 'grey97' | 'green';
  fontWeight?: 400 | 600 | 700;
  $customSize?: TextCustomType;
}
