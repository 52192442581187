import React from 'react';

export function TapBarMenu() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M2.33301 7.58325C2.33301 5.10838 2.33301 3.87094 3.10185 3.1021C3.8707 2.33325 5.10813 2.33325 7.58301 2.33325C10.0579 2.33325 11.2953 2.33325 12.0642 3.1021C12.833 3.87094 12.833 5.10838 12.833 7.58325C12.833 10.0581 12.833 11.2956 12.0642 12.0644C11.2953 12.8333 10.0579 12.8333 7.58301 12.8333C5.10813 12.8333 3.8707 12.8333 3.10185 12.0644C2.33301 11.2956 2.33301 10.0581 2.33301 7.58325Z'
        fill='#6AADEF'
      />
      <path
        opacity='0.5'
        d='M15.167 20.4167C15.167 17.9419 15.167 16.7044 15.9358 15.9356C16.7047 15.1667 17.9421 15.1667 20.417 15.1667C22.8918 15.1667 24.1293 15.1667 24.8982 15.9356C25.667 16.7044 25.667 17.9419 25.667 20.4167C25.667 22.8916 25.667 24.1291 24.8982 24.8979C24.1293 25.6667 22.8918 25.6667 20.417 25.6667C17.9421 25.6667 16.7047 25.6667 15.9358 24.8979C15.167 24.1291 15.167 22.8916 15.167 20.4167Z'
        fill='#6CA9FA'
      />
      <path
        d='M2.33301 20.4167C2.33301 17.9419 2.33301 16.7044 3.10185 15.9356C3.8707 15.1667 5.10813 15.1667 7.58301 15.1667C10.0579 15.1667 11.2953 15.1667 12.0642 15.9356C12.833 16.7044 12.833 17.9419 12.833 20.4167C12.833 22.8916 12.833 24.1291 12.0642 24.8979C11.2953 25.6667 10.0579 25.6667 7.58301 25.6667C5.10813 25.6667 3.8707 25.6667 3.10185 24.8979C2.33301 24.1291 2.33301 22.8916 2.33301 20.4167Z'
        fill='#0178FF'
      />
      <path
        d='M15.167 7.58325C15.167 5.10838 15.167 3.87094 15.9358 3.1021C16.7047 2.33325 17.9421 2.33325 20.417 2.33325C22.8918 2.33325 24.1293 2.33325 24.8982 3.1021C25.667 3.87094 25.667 5.10838 25.667 7.58325C25.667 10.0581 25.667 11.2956 24.8982 12.0644C24.1293 12.8333 22.8918 12.8333 20.417 12.8333C17.9421 12.8333 16.7047 12.8333 15.9358 12.0644C15.167 11.2956 15.167 10.0581 15.167 7.58325Z'
        fill='#0179FF'
      />
    </svg>
  );
}
