import styled from 'styled-components';
import { ECardType } from './Card.props';
import { fade } from '../../../../utils/styleUtils';

interface StyledCardProps {
  as: keyof JSX.IntrinsicElements;
  appearance: ECardType;
}
export const StyledCard = styled.div<StyledCardProps>`
  background: ${(props) => props.theme.color.base01};
  max-height: 95vh;
  position: relative;
  z-index: 12;
  overflow: hidden;
  border-radius: 15px;
  animation: ${fade} .3s ease;
  &.card {
  }
  &.large {
    padding: 60px 125px;
    height: 875px;
  }
  
`;
