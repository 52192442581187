import toast from 'react-hot-toast';

import { ResendText, Wrapper } from './ResendEmail.styled';

import { AuthService } from '../../../../../shared/api/model/service/auth.service';

interface IResendEmailProps {
  email: string;
}

const ResendEmail: React.FC<IResendEmailProps> = ({ email }) => {
  const handleResendEmail = async () => {
    const res = await AuthService.resendEmail(email);
    if (res.status === 'ok') {
      toast.success('Отправлено повторное письмо с подтверждением');
    }
  };

  return (
    <Wrapper>
      <span>Для входа в аккаунт завершите регистрацию, подтвердив электронную почту</span>
      <ResendText onClick={() => handleResendEmail()}>Отправить подтверждение повторно</ResendText>
    </Wrapper>
  );
};

export default ResendEmail;
