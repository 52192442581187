import { Link } from 'react-router-dom';

import { styled } from 'styled-components';

import { FlexWithAlign } from '../../../../utils/styleUtils';

export const NewPageHeaderWrapper = styled(FlexWithAlign)`
  justify-content: space-between;
  .search {
    max-width: 290px;
  }
`;

export const TitleWrap = styled(FlexWithAlign)`
  gap: 16px;
  align-items: center;
`;

export const BackLink = styled(Link)`
  display: flex;
`;
