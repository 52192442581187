import axios from 'axios';
import { ICreateTelegramBot, ITelegramBotSpam, IUpdateTelegramBot } from '../../../../store/redux/telegrambot/telegrambot.interface';


export const TelgramBotService = {
  async fetchTelegramBot(activeFilialId?: string) {
    const response = await axios.get(`https://code10.ru/server_bot/telebot/config/${activeFilialId}`);
    return response.data || '';
  },

  async fetchTelegramBotFollowers(activeFilialId?: string) {
    const response = await axios.get(`https://code10.ru/server_bot/telebot/clients/${activeFilialId}`);
    return response.data || '';
  },

  async createTelegramBot(values: ICreateTelegramBot) {
    const response = await axios.post(`https://code10.ru/bot/telebot/add_bot`, values, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Z-Key',
        'Access-Control-Allow-Methods': 'GET, HEAD, POST, PUT, DELETE, OPTIONS'
      }
    });

    return response;
  },

  async updateTelegramBot(values: IUpdateTelegramBot) {
    const response = await axios.post(`https://code10.ru/server_bot/telebot/config`, values);

    return response.data;
  },

  async fetchTelegramBotSpam(values: ITelegramBotSpam) {
    const response = await axios.post(`https://code10.ru/server_bot/telebot/spam`, values);
    return response.data || '';
  },
};
