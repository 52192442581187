import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './ConfirmActionModal.styled';

import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { Text } from '../../../../../../shared/ui';
import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import { IActionName } from '../../../../../../store/redux/modal/modal.interface';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';

interface IProps {
  closeModal?: () => void;
  modalPayload?: any;
  actionName?: IActionName;
}

const ConfirmActionModal: React.FC<IProps> = (props) => {
  const { modalPayload, actionName } = props;
  const { t } = useTranslation();
  const { closeModal, setEndDate, setStartDate } = useActions();

  return (
    <Wrapper>
      <FlexWithAlign
        $column
        $align={'center'}
      >
        <Text className={'title'}>{t('Запись пересекается с перерывом сотрудника. Подтвердитe действия?')}</Text>
        <FlexWithAlign
          $gap={'48px'}
          $justify={'space-between'}
          $align={'center'}
        >
          <CommonButton
            typeBtn={'danger'}
            onClick={() => {
              closeModal();
            }}
          >
            {t('Да')}
          </CommonButton>
          <CommonButton
            typeBtn={'secondary'}
            onClick={() => {
              closeModal();
            }}
          >
            {t('Нет')}
          </CommonButton>
        </FlexWithAlign>
      </FlexWithAlign>
    </Wrapper>
  );
};

export default ConfirmActionModal;
