export const PaymentIcon21 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M33.2424 26.1133H21.9555C21.5994 26.1143 21.2489 26.2015 20.9337 26.3674C20.6186 26.5333 20.3483 26.773 20.1459 27.0661C19.9436 27.359 19.815 27.6967 19.7715 28.0501C19.7279 28.4036 19.7705 28.7623 19.8956 29.0957L21.8187 34.225C21.8973 34.4348 22.0379 34.6159 22.2219 34.7438C22.4058 34.8719 22.6245 34.9408 22.8486 34.9415H32.3552C32.5793 34.9408 32.798 34.8719 32.9819 34.7438C33.1659 34.6159 33.3066 34.4348 33.3852 34.225L35.3097 29.0957C35.4351 28.7617 35.4776 28.4023 35.4336 28.0483C35.3897 27.6943 35.2605 27.3562 35.0573 27.0631C34.854 26.7698 34.5828 26.5303 34.2668 26.3648C33.9506 26.1994 33.5991 26.1131 33.2424 26.1133Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.082 26.1135L24.2891 20.5957'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M33.1171 26.1135L30.9102 20.5957'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.3926 31.6309V29.4238'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.8066 31.6309V29.4238'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.2636 29.0156H1.5498'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.1139 32.3254C10.9108 32.3254 10.7461 32.1607 10.7461 31.9574C10.7461 31.7543 10.9108 31.5896 11.1139 31.5896'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M11.1143 32.3256C11.3174 32.3256 11.4821 32.1609 11.4821 31.9576C11.4821 31.7545 11.3174 31.5898 11.1143 31.5898'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M18.8826 34.6679C18.5196 34.8219 18.1293 34.901 17.735 34.9002H4.49255C3.71209 34.9002 2.96359 34.5902 2.41172 34.0383C1.85984 33.4865 1.5498 32.738 1.5498 31.9575V4.00135C1.5498 3.22089 1.85984 2.47239 2.41172 1.92051C2.96359 1.36863 3.71209 1.05859 4.49255 1.05859H17.735C18.5154 1.05859 19.2639 1.36863 19.8158 1.92051C20.3676 2.47239 20.6777 3.22089 20.6777 4.00135V19.4508'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.2637 8.41602H11.8496V12.8302H16.2637V8.41602Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.378 17.2441H5.96387V21.6583H10.378V17.2441Z'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.96387 10.6231V8.41602H8.17094'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.2637 19.4509V21.658H14.0566'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3213 15.7715H14.7927'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.7002 13.5664H8.90726'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
