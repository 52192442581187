import React, { ChangeEvent, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import ru from 'date-fns/locale/ru';

import 'dayjs/locale/ru';

import { Content, DateIntervalWrap, NavItem, NavWrap, TopDateBox, Wrapper } from './EmployeeScheduleModal.styled';
import { MainInfo } from './main-info/MainInfo';
import EmployeeSchedule from './schedule/EmployeeSchedule';

import { EIcon, IconNew as IconInstance } from '../../../../icons/medium-new-icons/icon';
import CommonCalendar from '../../../calendar/CommonCalendar';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { Avatar, AvatarRounded, AvatarSize, Text, TextPType, TextType } from '../../../../../shared/ui';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { MODAL_GAP, MODAL_GAP_L, MODAL_TEXT_GAP } from '../../../../../styles';
import DinamycHeight from '../../../../../shared/ui/AutoHeight/DinamycHeight';
import useMatchMedia from '../../../../../shared/lib/hooks/useMatchMedia';
import MobileSheetEmployee from './sheet/MobileSheetEmployee';
import dayjs from 'dayjs';
import { getMonday } from '../../../../../store/redux/calendar/calendar.actions';
import { addDateBy } from '../../../../../utils/date-events';
import { useFormik } from 'formik';
import { validationEmployeeSchema } from '../../../../../utils/validation-input';
import { useFilialQuery } from '../../../../../store/redux/filial/hooks/useFilialQuery';
import { InputUpload, UploadBlock } from './main-info/MainInfo.styled';
import { resizeAndConvertToBase64 } from '../../../../../shared/lib/helpers/convertToBase46';
import { AvatarWrapper } from '../../../../../styles/circles.styled';
import { useUpdateUser } from '../../../../../store/redux/user/hooks/useUsersMutation';
import { useProductsIdsQuery } from '../../../../../store/redux/products/hooks/useProductsQuery';
import { useSubproductAllQuery } from '../../../../../store/redux/subproducts/hooks/useSubproductQuery';

registerLocale('ru', ru);

interface IProps {
  closeModal: () => void;
  modalPayload?: any;
  stateNum?: number;
}

const EmployeeScheduleModal: React.FC<IProps> = (props) => {
  const { modalPayload, stateNum, closeModal } = props;
  const { id, fio, phone, Email, position, services: isServices, avatar } = modalPayload;
  const { data: activeFilial } = useFilialQuery();
  const { activeDate, chosenWeek } = useTypedSelector((state) => state.calendar);
  const [menuNum, setMenuNum] = useState<number>(stateNum || 1);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const { data: ids } = useProductsIdsQuery();
  const { data: subproductsAll } = useSubproductAllQuery(ids);
  const { mutateAsync: updateUser, isPending } = useUpdateUser();

  const initialValues = {
    email: '',
    fio: '',
    role: '',
    id: '',
    idfilial: activeFilial?.id,
    phone: '',
    position: '',
    offline: false,
    services: false,
    idservices: '',
    avatar: ''
  };
  const formik = useFormik({
    initialValues: modalPayload
      ? {
          email: modalPayload.Email,
          fio: modalPayload.fio,
          role: modalPayload.role,
          id: modalPayload.id,
          idfilial: modalPayload.idfilial,
          phone: modalPayload.phone,
          position: modalPayload.position,
          offline: modalPayload.offline === '' || modalPayload.offline === 'no' ? true : false,
          services: modalPayload.services,
          idservices: '',
          avatar: modalPayload?.avatar
        }
      : initialValues,
    onSubmit: (values: any) => {},
    validationSchema: validationEmployeeSchema,
    enableReinitialize: true
  });

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];

      try {
        const resizedBase64 = await resizeAndConvertToBase64(file, 200, 200);

        if (typeof resizedBase64 === 'string') {
          formik.setFieldValue('avatar', resizedBase64);
        } else {
          console.error('Error resizing and converting to base64: Failed to get a string');
        }
      } catch (error) {
        console.error('Error resizing and converting to base64:', error);
      }
    }
  };

  const handleDeleteFile = async () => {
    formik.setFieldValue('avatar', '');
  };
  const { t } = useTranslation();

  return (
    <DinamycHeight>
      <Wrapper>
        {isMobile ? null : (
          <Text
            $tag={TextType.H5}
            fontWeight={700}
          >
            {t('Карточка сотрудника')}
          </Text>
        )}
        <FlexWithAlign
          $align={'center'}
          $gap={isMobile ? '10px' : MODAL_GAP}
        >
          <UploadBlock
            $align={'center'}
            $gap={isMobile ? MODAL_TEXT_GAP : MODAL_GAP_L}
            $column={isMobile}
          >
            <FlexWithAlign
              className={'full'}
              $column
              $gap={MODAL_TEXT_GAP}
              $justify={'space-between'}
            >
              <InputUpload>
                <label htmlFor={'avatarInput'}>
                  <AvatarWrapper>
                    {formik.values.avatar ? (
                      <Avatar
                        title={modalPayload?.fio}
                        avatar={formik.values.avatar}
                        size={isMobile ? AvatarSize.L : AvatarSize.XL}
                        randomBg
                        rounded={AvatarRounded.FULL}
                      />
                    ) : (
                      <Avatar
                        title={modalPayload?.fio}
                        size={isMobile ? AvatarSize.L : AvatarSize.XL}
                        background={'secondary'}
                        svg={EIcon.profileuser}
                        rounded={AvatarRounded.FULL}
                      />
                    )}
                    {!isMobile && formik.values.avatar && (
                      <button onClick={handleDeleteFile}>
                        <IconInstance name={EIcon.closefill} />
                      </button>
                    )}
                    {!isMobile && !formik.values.avatar && (
                      <Text
                        $tag={TextType.P}
                        fontWeight={500}
                        $pSize={TextPType.P13}
                      >
                        {t('Добавьте фото')}
                      </Text>
                    )}
                    {!isMobile && formik.values.avatar && (
                      <Text
                        $tag={TextType.P}
                        fontWeight={500}
                        $pSize={TextPType.P13}
                      >
                        {t('Обновить фото')}
                      </Text>
                    )}
                  </AvatarWrapper>
                  <input
                    type='file'
                    id='avatarInput'
                    accept='image/*'
                    onChange={handleFileChange}
                  />
                </label>
              </InputUpload>
            </FlexWithAlign>
          </UploadBlock>
          <FlexWithAlign
            $column
            $gap={'0'}
          >
            <Text
              $tag={isMobile ? TextType.P : TextType.H5}
              $pSize={isMobile ? TextPType.P17 : undefined}
              fontWeight={700}
            >
              {t(fio || 'Имя')}
            </Text>
            <Text
              $tag={TextType.P}
              $pSize={isMobile ? TextPType.P13 : TextPType.P17}
              color={'text02'}
            >
              {t(`Должность: ${position ? position : 'Без должности'}`)}
            </Text>
          </FlexWithAlign>
        </FlexWithAlign>
        <NavWrap>
          <NavItem>
            <button
              className={menuNum === 1 ? 'active' : 'common'}
              onClick={() => setMenuNum(1)}
            >
              {t('Основное')}
            </button>
          </NavItem>
          <NavItem>
            <button
              className={menuNum === 2 ? 'active' : 'common'}
              onClick={() => setMenuNum(2)}
            >
              {t('График работы')}
            </button>
          </NavItem>
        </NavWrap>
        {menuNum === 2 && isMobile && <MobileSheetEmployee />}
        {menuNum === 2 && !isMobile && (
          <TopDateBox>
            <div className='date flex'>
              <IconInstance name={EIcon.calendar} />
              <DateIntervalWrap>
                {t(
                  `${dayjs(getMonday(new Date(activeDate)))
                    .locale('ru')
                    .format('DD MMMM YYYY')}`
                )}{' '}
                -{' '}
                {t(
                  `${dayjs(addDateBy(getMonday(new Date(activeDate)), 6))
                    .locale('ru')
                    .format('DD MMMM YYYY')}`
                )}
              </DateIntervalWrap>
            </div>
          </TopDateBox>
        )}
        {menuNum === 1 && (
          <>
            <MainInfo
              closeModal={closeModal}
              modalPayload={modalPayload}
              formik={formik}
              initialValues={initialValues}
            />
          </>
        )}
        {menuNum === 2 && (
          <>
            <Content>
              {!isMobile && (
                <div className='calendar'>
                  <CommonCalendar currentView={'resourceTimeGridWeek'} />
                </div>
              )}
              <div className='field'>
                <EmployeeSchedule
                  closeModal={closeModal}
                  employeeId={id}
                />
              </div>
            </Content>
          </>
        )}
      </Wrapper>
    </DinamycHeight>
  );
};

export default EmployeeScheduleModal;
