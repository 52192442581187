import React from 'react';

export function PlusOutlineIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99997 4.99998C10.497 4.99998 10.9 5.40292 10.9 5.89998V9.09998H14.1C14.597 9.09998 15 9.50292 15 9.99998C15 10.497 14.597 10.9 14.1 10.9H10.9V14.1C10.9 14.597 10.497 15 9.99997 15C9.50292 15 9.09997 14.597 9.09997 14.1V10.9H5.89997C5.40292 10.9 4.99997 10.497 4.99997 9.99998C4.99997 9.50292 5.40292 9.09998 5.89997 9.09998H9.09997V5.89998C9.09997 5.40292 9.50292 4.99998 9.99997 4.99998Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.99998 1.89998C5.52647 1.89998 1.89998 5.52647 1.89998 9.99998C1.89998 14.4735 5.52647 18.1 9.99998 18.1C14.4735 18.1 18.1 14.4735 18.1 9.99998C18.1 5.52647 14.4735 1.89998 9.99998 1.89998ZM0.0999756 9.99998C0.0999756 4.53236 4.53236 0.0999756 9.99998 0.0999756C15.4676 0.0999756 19.9 4.53236 19.9 9.99998C19.9 15.4676 15.4676 19.9 9.99998 19.9C4.53236 19.9 0.0999756 15.4676 0.0999756 9.99998Z'
        fill='currentColor'
      />
    </svg>
  );
}
