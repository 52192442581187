import React from 'react';

import { List, PersonalAreaWrapper, PortalTextWrapper } from './LicencePage.styled';

import NewPageHeader from '../../../shared/new-ui/NewPageHeader/ui/NewPageHeader';
import NewText from '../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../shared/new-ui/NewText/ui/NewText.props';
import { Padding } from '../../../utils/styleUtils';

const LicencePage = () => {
  return (
    <PersonalAreaWrapper $column>
      <NewPageHeader
        backLink={'/portal'}
        title='Лицензионной Договор'
      />
      <PortalTextWrapper>
        <List>
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            ЛИЦЕНЗИОННЫЙ ДОГОВОР (ОФЕРТА)
          </NewText>
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            Редакция 2023.12.10 от «10» Декабря 2023
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            1. Термины и определения
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            Применяемые в настоящем Договоре термины означают следующее:
            <br />
            <span className='font-weight'>Программный продукт</span> – программа для ЭВМ «Telebon»
            <br /> <span className='font-weight'>Личный кабинет</span> – инструмент доступа к Программному продукту, расположенный на
            официальном сайте Программного продукта: https://telebon.ru.
            <br /> <span className='font-weight'>Конечный пользователь</span> – любое лицо, которому Лицензиатом предоставляется доступ к
            Личному кабинету Лицензиата.
            <br /> <span className='font-weight'>Активированная учетная запись</span> – это пользователь, которого пригласили в аккаунт
            Лицензиата и не деактивировали в разделе управления сотрудниками.
            <br /> <span className='font-weight'>Установка</span> – установка Программного продукта (в том числе запись и хранение в памяти
            ЭВМ (сервера, принадлежащего Лицензиату), а также совершение иных необходимых (обычно требуемых) установочных действий с целью
            записи файлов для надлежащего функционирования Программного продукта на техническом средстве (ЭВМ) Лицензиата и дальнейшего
            использования Программного продукта на данном ЭВМ.
            <br /> <span className='font-weight'>Saas-версия</span> – версия Программного продукта, которая предоставляется без скачивания
            дистрибутива на серверах Лицензиара.
            <br /> <span className='font-weight'>Договорная территория</span> — территория Российской Федерации, республики Беларусь,
            Казахстана, Киргизии и Армении. <br />
            <span className='font-weight'>Лицензионное вознаграждение</span> – денежная сумма, уплачиваемая Лицензиатом за право
            использования Программного продукта. <br />
            <span className='font-weight'>Раздел «Тарифы»</span> – раздел сайта Программного продукта, который содержит информацию о
            размерах лицензионного вознаграждения. <br />
            <span className='font-weight'>Тариф</span> – размер лицензионного вознаграждения в соответствии с предоставленной
            функциональностью Программного продукта. <br />
            <span className='font-weight'>Учетный период использования (учетный период)</span> – подлежащий оплате период использования
            Лицензиатом Программного продукта.
            <br /> <span className='font-weight'>Модуль Программного продукта</span> – набор отдельных функций Программного продукта, не
            входящий в базовую комплектацию программы и предоставляемых Лицензиату на отдельных условиях и за отдельную плату.
            <br />
            <span className='font-weight'>Некоммерческое использование</span> – использование Программного продукта на условиях простой
            (неисключительной) лицензии на основе бесплатного Тарифа.
            <br /> <span className='font-weight'>Коммерческое использование</span> – использование Программного продукта на условиях простой
            (неисключительной) лицензии на основе платного Тарифа
            <br /> <span className='font-weight'>Бесплатный период</span> – период времени, указанный в разделе «Тарифы», в который
            Лицензиат знакомится с Программным продуктом без взимания с него платы. При этом Лицензиату предоставляется для ознакомления
            функциональность Программного продукта в соответствии с выбранным Тарифом. <br />
            <span className='font-weight'>Регистрация банковской карты</span> – операция в Личном кабинете, в рамках которой Лицензиат
            указывает данные своей кредитной/дебетовой карты. <br />
            <span className='font-weight'>Платежная система</span> – система, с помощью которой осуществляются все операции по банковским
            картам. <br />
            <span className='font-weight'>Электронная подпись</span> — усиленная квалифицированная электронная подпись, соответствующая
            требованиям Федерального закона № 63-ФЗ «Об электронной подписи» от 06.04.2011 и действующему законодательству РФ в сфере
            электронной подписи. <br />
            <span className='font-weight'>Электронный документооборот (ЭДО)</span> — процесс обмена между Сторонами в системе юридически
            значимого электронного документооборота документами, составленными в электронном виде и подписанными электронной подписью,
            используемой Сторонами. <br />
            <span className='font-weight'>Оператор ЭДО</span> — организация, обеспечивающая обмен открытой и конфиденциальной информацией по
            телекоммуникационным каналам связи в рамках электронного документооборота между Сторонами в системе ЭДО.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            2. Предмет договора
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            2.1. Лицензиар предоставляет Лицензиату право на Коммерческое или Некоммерческое использование Программного продукта на условиях
            простой (неисключительной) лицензии в целях использования программного продукта на Договорной территории в пределах прав
            использования, определенных настоящим Договором, а также предоставляет за дополнительное вознаграждение отдельные модули
            Программного продукта, оказывает дополнительные услуги в соответствии с настоящим Договором.
            <br />
            <br />
            2.2. Право на использование Программного продукта передается на основании оферты Лицензиара.
            <br />
            <br />
            2.3. Использование Программного продукта осуществляется путем открытия доступа через сеть Интернет к Программному продукту,
            расположенному на сайте: https://telebon.ru. Способы использования: установка Программного продукта или использование
            SaaS-версии Программного продукта в соответствии с функциональным предназначением.
            <br />
            <br />
            2.4. Право использования Программного продукта передается без права дальнейшего сублицензирования.
            <br />
            <br />
            2.5. За отдельную плату, подлежащую согласованию Сторонами отдельным соглашением, Лицензиар оказывает следующие услуги:
            <br />
            &emsp;• &emsp;удаленная установка программного продукта на сервер Лицензиата;
            <br />
            &emsp;• &emsp;удаленное обновление программного продукта на сервере Лицензиата;
            <br />
            &emsp;• &emsp;обучение работников Лицензиата правилам и порядку работы с программным продуктом..
            <br />
            За отдельную плату Лицензиату также могут быть предоставлены дополнительные модули Программного продукта. Размер платы
            указывается в разделе «Тарифы».
            <br />
            <br />
            2.6. Перед использованием Программного продукта Лицензиат обязан ознакомиться с условиями настоящего Договора и принять их.
            Настоящий Договор является офертой, которая может быть акцептована Лицензиатом путем совершения одного или нескольких действий,
            свидетельствующих о согласии Лицензиата с условиями Договора:
            <br />
            <p className='padding'>
              2.6.1. Путем присоединения Лицензиата к настоящему Договору в форме активных действий, а именно проставление галочки в окне «Я
              согласен со всеми условиями Лицензионного договора и безоговорочно их принимаю», одновременно с внесением своих учетных данных
              в Личном кабинете;
            </p>
            <p className='padding'>
              2.6.2. Путем оплаты лицензионного вознаграждения и начала фактического использования Лицензиатом Программного продукта при
              Коммерческом использовании.
            </p>
            <p className='padding'>
              2.6.3. Путем начала фактического использования Лицензиатом Программного продукта в Бесплатном периоде.
            </p>
            <p className='padding'>
              2.6.4. Путем начала фактического использования Лицензиатом Программного продукта при Некоммерческом использовании.
            </p>
            <p className='padding'>
              2.6.5. При постоплатной системе с момента активации и выбора Лицензиатом постоплатной системы расчетов.
            </p>
            <br />
            2.7. Каждым использованием Программного продукта, Лицензиат выражает свое согласие с условиями лицензионного договора в
            редакции, которая действовала на момент такого фактического использования. Акцептуя настоящий Договор, т.е. заключая его с
            Лицензиаром, Лицензиат соглашается со всеми его условиями и положениями без оговорок.
            <br />
            <br />
            2.8. Настоящий Договор не требует оформления в печатном виде и последующего подписания Сторонами, обладает юридической силой в
            силу акцепта Лицензиата способами, указанными в п. 2.6 Договора, и считается заключенным в простой письменной форме.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            3. Права и обязанности сторон
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            3.1.Лицензиат обязан:
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            3.1.1. Использовать Программный продукт только в пределах и способами, предусмотренными настоящим Договором;
            <br />
            <br />
            3.1.2. Уплатить лицензионное вознаграждение за Программный продукт в размере и в порядке, предусмотренными настоящим Договором.
            <br />
            <br /> 3.1.3. Воздерживаться от:
            <br />
            &emsp;• &emsp;любых модификаций и декомпилирования Программного продукта;
            <br />
            &emsp;• &emsp;воспроизведения Программного продукта, в том числе путем записи в память ЭВМ;
            <br />
            &emsp;• &emsp;распространения (копирования) Программного продукта;
            <br />
            &emsp;• &emsp;осуществления иных действий, направленных на извлечение коммерческой выгоды в отношениях с третьими лицами,
            возникшей из использования Программного продукта;
            <br />
            &emsp;• &emsp;предоставления сублицензий на использование Программного продукта;
            <br />
            &emsp;• &emsp;обнародования Программного продукта;
            <br />
            &emsp;• &emsp;внесения каких-либо изменений в объектный код Программного продукта;
            <br />
            &emsp;• &emsp;совершения любых иных действий в отношении Программного продукта, нарушающих российские и/или международные нормы
            законодательства.
            <br />
            <br />
            3.1.4. Самостоятельно отслеживать актуальные редакции тарифов, действующих в отношении использования Программного продукта и
            отображающихся в разделе «Тарифы» на сайте Программного продукта и/или в Личном кабинете Лицензиата.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            3.2. Лицензиат вправе:
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            3.2.1. Осуществлять любые действия, связанные с функционированием Программного продукта и его использованием в соответствии с
            его назначением.
            <br />
            <br />
            3.2.2. Предоставлять своим конечным пользователям удаленный доступ к программному продукту (к Личному кабинету), а также к
            информации, сформированной в результате использования Программного продукта. При этом количество предоставляемых Лицензиатов
            прав доступа к Программному продукту не должно превышать количество лицензий, приобретенных Лицензиатом у Лицензиара (кроме
            случая Некоммерческого использования). <br />
            <br />
            3.2.3 Приобретать дополнительную функциональную составляющую Программного продукта, посредством оплаты эксплуатации Модуля
            Программного продукта Лицензиата (Партнера) при помощи интерфейса Личного кабинета.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            3.3. Лицензиар обязан:
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            3.3.1. Предоставить Лицензиату права использования на Программный продукт, определенные настоящим Договором.
            <br />
            <br />
            3.3.2. В течение 3 (трех) рабочих дней с момента исполнения Лицензиатом обязанности по оплате лицензионного вознаграждения,
            предоставить Лицензиату доступ к Программному продукту в рамках его Коммерческого использования. Доступ к Программному продукту
            должен обеспечиваться ежедневно и круглосуточно, за исключением времени проведения профилактических мероприятия, составляющих не
            более 10 часов в месяц.
            <br />
            <br />
            3.3.3. Обеспечивать сопровождение Программного продукта по вопросам его надлежащей работы.
            <br />
            <br />
            3.3.4. Предоставлять информацию об обновлениях Программного продукта.
            <br />
            <br />
            3.3.5. Безвозмездно устранять сбои в работе Программного продукта, возникшие не по вине Лицензиара и не связанные с нарушением
            правил использования Программного продукта. 3.3.6 Обеспечивать конфиденциальность данных, размещенных Лицензиатом в Программном
            продукте, на весь период их нахождения на сервере Лицензиара
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            3.4. Лицензиар вправе:
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            3.4.1. В случае просрочки Лицензиатом внесения очередной части лицензионного вознаграждения, перевести использование
            Программного продукта для Лицензиата в режим «только для чтения» и/или отказаться от исполнения настоящего Договора в
            одностороннем внесудебном порядке, письменно предупредив об этом Лицензиата путем направления соответствующего уведомления в
            Личный кабинет или по электронной почте с последующей деактивацией или удалением аккаунта Лицензиата.
            <br />
            <br />
            3.4.2. Привлекать для исполнения настоящего Договора третьих лиц, оставаясь ответственным за их действия.
            <br />
            <br />
            3.4.3. В одностороннем внесудебном порядке вносить изменения в настоящий Договор, уведомляя об этом Лицензиата путем размещения
            соответствующей информации на сайте: https://telebon.ru
            <br />
            <br />
            3.4.4. В одностороннем порядке изменять количество конечных пользователей, допустимых для Лицензиата при Некоммерческом
            использовании. Актуальное количество конечных пользователей для Некоммерческого использования отображается в разделе «Тарифы» на
            сайте Программного продукта и/или в Личном кабинете Лицензиата (с учетом приоритета, установленного п. 5.1. настоящего
            Договора). Лицензиар вправе направить Лицензиату соответствующее уведомление об изменении тарифа в указанной части по
            электронной почте или через Личный кабинет. <br />
            <br />
            3.4.5 Вносить изменения в Программный продукт без уведомления Лицензиата в любое время и по любой причине, в том числе, но не
            ограничиваясь, в целях удовлетворения потребностей других лицензиатов, требований конкурентоспособности, или в целях исполнения
            требований нормативных актов. Лицензиар оставляет за собой право добавлять новые свойства и функциональные возможности Программы
            или удалять из Программы уже существующие свойства и функциональные возможности. <br />
            <br />
            3.4.6 Агрегировать, систематизировать и анализировать информацию Лицензиата, в том числе конфиденциальную, с целью создания
            информационно-аналитических отчетов и баз данных, при этом Лицензиар гарантирует нераспространение и сохранность
            конфиденциальной информации, содержащейся в отчетах и базах в соответствии с настоящим Договором и действующим законодательством
            Российской Федерации.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            4. Предоставление права использования Программного продукта
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            4.1. Порядок предоставления права использования Программного продукта указан в «Пользовательском соглашении», размещенными на
            сайте Программного продукта.
            <br />
            <br />
            4.2. При Коммерческом использовании Лицензиар предоставляет Лицензиату Бесплатный период. Длительность Бесплатного периода
            определяется и сообщается Лицензиату в момент выбора Тарифа. По окончании Бесплатного периода, Лицензиат может перейти на
            Коммерческое использование Программного продукта, оплатив вознаграждение за соответствующий Тариф.
            <br />
            <br />
            4.3. Актуальная стоимость Коммерческого использования Программного продукта указана в разделе «Тарифы» на сайте Программного
            продукта и/или в Личном кабинете Лицензиата. При окончании Бесплатного периода Лицензиату направляется уведомление в Личном
            кабинете - о необходимости подтвердить переход на Коммерческое использование программного продукта и предоставлении детальных
            учетных данных для осуществления оплаты суммы лицензионного вознаграждения. Детальные учетные данные предоставляются Лицензиатом
            посредством заполнения соответствующей формы в Личном кабинете.
            <br />
            <br />
            4.4. При заполнении детальных данных Лицензиат также указывает: в случае приобретения Saas-версии программного продукта:
            продукт, количество лицензий (количество конечных пользователей), учетный период приобретения простой (неисключительной)
            лицензии программного продукта, вид дополнительной услуги, оказание которой заказывает Лицензиат.
            <br />
            <br />
            4.5. При переходе на Коммерческое использование, Лицензиат обязан начать уплачивать лицензионное вознаграждение на выбранных
            условиях Тарифа.
            <br />
            <br />
            4.6. Отказом Лицензиата от использования программного продукта является отсутствие оплаты Лицензионного вознаграждения.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            5. Порядок расчетов
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            5.1. Лицензионное вознаграждение за предоставленные права использования программного продукта выплачиваются Лицензиатом за
            учетный период использования, либо за фактическое количество дней работы активированных учетных записей. Размер лицензионного
            вознаграждения, а также детали расчета учетного периода и/или времени работы активированных учетных записей указывается на сайте
            Программного продукта в разделе «Тарифы», и/или в Личном кабинете при активации Лицензиатом выбранного тарифа. В случае
            отсутствия размера лицензионного вознаграждения в разделе «Тарифы» на сайте программного продукта, а также в случае расхождений
            между размером лицензионного вознаграждения, указанного в разделе «Тарифы» и в Личном кабинете Лицензиата, Стороны
            руководствуются тарифами, указанными в Личном кабинете Лицензиата. В любом случае приоритет имеют тарифы, указанные в Личном
            кабинете Лицензиата. Окончательная сумма лицензионного вознаграждения указывается Лицензиаром при выборе Лицензиатом тарифа в
            Личном кабинете, а также в счете, выставляемом Лицензиату – юридическому лицу. Параметры изменения выбранного тарифа, а также
            параметры добавления/удаления конечных пользователей также указываются в разделе «Тарифы» и/или в Личном кабинете Лицензиата.
            Лицензиат несет полную ответственность за самостоятельное ознакомление с вносимыми изменениями в тарифы и обладанием актуальной
            информацией.
            <br />
            <br />
            5.2. Лицензиату - юридическому лицу выставляется счёт. Счет выставляется в электронном виде (в Личном кабинете). Счет может быть
            выставлен за учетный период использования. Начало учетного периода исчисляется с момента выбора тарифа в Личном кабинете. Счет
            выставляется каждый учетный период на основании детальных учетных данных, предоставленных Лицензиатом. Стоимость (размер
            лицензионного вознаграждения) формируется исходя из данных, указанных Лицензиатом, и выбранного тарифа.
            <br />
            <br />
            5.3. Лицензиат обязан оплатить сумму лицензионного вознаграждения в течение срока оплаты счёта, выставленного Лицензиаром (или
            требования об оплате в иной форме). Срок оплаты счёта указан в Личном кабинете Лицензиата в разделе “Оплата”. Исключением
            является случай, предусмотренный п. 5.4 настоящего Договора. В случае если денежные средства не поступят на счет Лицензиара в
            срок оплаты счёта, последний вправе перевести его в режим «только для чтения» или приостановить доступ Лицензиата к программному
            продукту. Режим «только для чтения» не позволяет совершать активные действия при использовании программного продукта (кроме
            чтения имеющихся материалов), осуществлять изменения в программном продукте, также удалять/добавлять конечных пользователей. В
            случае допущения просрочек в оплате лицензионного вознаграждения, Лицензиар также вправе применить положения п. 3.4.1. Оплата
            счёта по истечению срока оплаты, может быть признана Лицензиаром недействительной. В случае, если оплата Лицензионного
            вознаграждение не будет произведена Лицензиатом, Лицензиар оставляет за собой право удалить аккаунт Лицензиата. При оплате
            Лицензионного вознаграждения после удаления аккаунта восстановление аккаунта производится по согласованию сторон.
            <br />
            <br />
            5.4. В случае, когда Лицензиат является физическим лицом и использует для оплаты зарегистрированную на сайте Программного
            продукта кредитную/дебетовую карту, он вправе выбрать постоплатную систему оплаты лицензионного вознаграждения путем
            соответствующего выбора в Личном кабинете. В случае выбора названной системы Лицензиар ежемесячно направляет Лицензиату
            калькуляцию с указанием количества активных конечных пользователей и количества дней по каждому пользователю - в течение
            учетного периода (месяца) и с отражением суммы лицензионного вознаграждения, подлежащего оплате. Указанная сумма автоматически
            списывается с банковской карты Лицензиата, на что он дает свое согласие фактом выбора постоплатной системы расчетов. Настоящим
            Лицензиат также дает свое согласие на блокировку денежных средств на банковской карте, выбранной для расчетов с Лицензиаром, при
            активации постоплатного тарифа. Точная сумма денежных средств, подлежащих блокировке, указывается в Личном кабинете при
            активации Коммерческого использования. Заблокированные денежные средства в дальнейшем подлежат возврату Лицензиату. В случае,
            если не удается произвести списание денежных средств по причине возникновения любой ошибки, в том числе списание не дает
            провести платежная система, то Лицензиат обязан обновить данные банковской карты в Личном кабинете или запросить у Лицензиара
            ссылку на оплату задолженности. Обязанность по оплате задолженности возникает у физического лица, который осуществлял
            Регистрацию банковской карты. Лицензиат, который зарегистрировал чужую банковскую карту (не эмитированную на него лично) в своём
            Личном кабинете, несёт ответственность по оплате всех счетов в аккаунте.
            <br />
            <br />
            5.5. Все расчеты производятся в безналичной денежной форме. Моментом исполнения Лицензиатом своих обязанностей по оплате
            считается момент поступления денежных средств на расчетный счет Лицензиара.
            <br />
            <br />
            5.6. Расчеты производятся в российских рублях.
            <br />
            <br />
            5.7. Размер лицензионного вознаграждения может быть изменен Лицензиаром в одностороннем порядке на новые учетные периоды с
            предварительным уведомлением Лицензиата не менее чем за 10 (десять) календарных дней до начала их действия путем уведомления в
            Личном кабинете и/или по электронной почте и/или путем внесения изменений в раздел «Тарифы» на сайте Программного продукта.
            <br />
            <br />
            5.8. Лицензионное вознаграждение не облагается НДС в связи с применением Лицензиаром упрощенной системы налогообложения.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            6. Ответственность
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            6.1. В случае ненадлежащего исполнения обязательств по настоящему Договору Стороны несут ответственность в соответствии с
            действующим законодательством РФ.
            <br />
            <br />
            6.2. Ни одна из сторон не несет ответственности перед другой стороной за задержку или невыполнение обязательств, обусловленные
            обстоятельствами, возникшими помимо воли и желания сторон и которые нельзя ни предвидеть или ни предотвратить, включая
            объявленную или фактическую войну, гражданские волнения, эпидемии, блокаду, эмбарго, землетрясения, наводнения, пожары и другие
            стихийные бедствия.
            <br />
            <br />
            6.3. Если обстоятельства непреодолимой силы действуют на протяжении 3 (трех) последовательных месяцев, то настоящий Договор
            может быть расторгнут любой из сторон путем направления уведомления другой стороне.
            <br />
            <br />
            6.4. Лицензиат приобретает право на использование Программного продукта в объеме, оговоренном настоящим Договором, и несет
            ответственность за его использование в соответствии с рекомендациями, изложенными в эксплуатационной документации, и действующим
            законодательством Российской Федерации.
            <br />
            <br />
            6.6. Лицензиар не несет ответственность за прямые или косвенные убытки, включая упущенную выгоду, иной ущерб или вред, перерывы
            в хозяйственной деятельности, потерю деловой информации и т.п., которые могут возникнуть у Лицензиата в результате использования
            Программного продукта.
            <br />
            <br />
            6.7. Лицензиат использует предоставленные права использования программного продукта на собственный риск. Лицензиар не принимает
            на себя ответственность за соответствие функциональности Программного продукта цели его использования, за любой ущерб,
            понесенный Лицензиатом ввиду утери им своих данных, необходимых для доступа к Программному продукту, а также за качество
            предоставляемых третьими лицами сервисов, необходимых для работы с Программным продуктом. Лицензиат самостоятельно несет
            ответственность за сохранность и безопасность своего пароля.
            <br />
            <br />
            6.8. В случае утери данных Лицензиата по его вине, Лицензиар при наличии технической возможности восстанавливает данные
            Лицензиата на возмездной основе согласно заключаемому дополнительному соглашению.
            <br />
            <br />
            6.9 При отправке голосовых сообщений посредством использования программного продукта Лицензиат гарантирует, что осуществляет
            отправку исключительно лицам, давшим согласие на получение информации данным способом от Лицензиата, в порядке, установленном
            действующим законодательством Российской Федерации. При распространении информации рекламного характера посредством отправки
            сообщений через Программу Лицензиат выступает в качестве рекламораспространителя в соответствие с требованиями Федерального
            закона от 13.03.2006 N 38-ФЗ «О рекламе».
            <br />
            <br />
            6.10 Лицензиар не имеет технической возможности контролировать содержимое передаваемой Лицензиатом (Пользователями) информации,
            номера получателей сообщений, на которые осуществляется отправка информации Лицензиата, наличие согласия получателей сообщений
            Лицензиата на получение информации от Лицензиата, а также достоверность иной идентифицирующей Лицензиата информации,
            содержащейся в отправляемых сообщениях.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            7. Срок действия Договора
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            7.1. Договор вступает в силу с момента его заключения в порядке, предусмотренном в п. 2.7 (акцепта оферты).
            <br />
            <br />
            7.2. При Коммерческом использовании настоящий Договор будет действовать на протяжении всех учетных периодов, оплаченных
            Лицензиатом в рамках настоящего Договора, и автоматически пролонгируется фактом оплаты лицензионного вознаграждения за следующий
            учетный период.
            <br />
            <br />
            7.3. Лицензиар вправе отказаться от исполнения настоящего Договора в одностороннем внесудебном порядке в случае непоступления
            оплаты (лицензионного вознаграждения) за программный продукт в сроки, установленные настоящим Договором. При Некоммерческом
            использовании Лицензиар вправе отказаться от исполнения настоящего Договора в одностороннем внесудебном порядке в любое время и
            без объяснения причин, уведомив Лицензиата в Личном кабинете.
            <br />
            <br />
            7.4. Лицензиат вправе отказаться от исполнения настоящего Договора путем отказа от использования программного продукта и
            письменного уведомления об этом Лицензиара. Неуплата лицензионного вознаграждения также является отказом Лицензиата от
            использования программного продукта. В случае отказа Лицензиата от Договора (вне зависимости от причин отказа), внесенная сумма
            лицензионного вознаграждения за весь учетный период, внесенная Лицензиатом (в том числе в качестве предоплаты) не подлежит
            возврату Лицензиату.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            8. Разрешение споров
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            8.1. Настоящий Договор, а также все правоотношения Сторон, связанные с заключением, изменением, исполнением, расторжение
            настоящего Договора и связанные с ним, регулируется материальным и процессуальным правом РФ.
            <br />
            <br />
            8.2. Все споры, возникающие между Лицензиатом и Лицензиаром, Стороны будут пытаться решить путем переговоров.
            <br />
            <br />
            8.3. В случае недостижения согласия путем переговоров, Стороны применяют досудебный порядок урегулирования споров, путем
            направления письменной претензии, содержащей заявляемые требования и подтверждающие документы.
            <br />
            <br />
            8.4. В случае невозможности решить спор путем переговоров и/или досудебного урегулирования, спор передается на рассмотрение
            Арбитражного суда г. Кирова – в случае если Лицензиат – юридическое лицо, и в суд общей юрисдикции по месту нахождения
            Лицензиара – в случае если Лицензиат – физическое лицо.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            9. Персональные данные
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            9.1. Принимая условия настоящего Договора, Лицензиат, действуя свободно, своей волей и в своём интересе, даёт конкретное,
            информированное и сознательное согласие на обработку ООО « ГК «Белый Медведь», зарегистрированному по адресу 610050, город
            Киров, улица Московская, д. 199, этаж 2 ком 1, своих персональных данных, сбор статистики об IP-адресах и файлов Cookie, а также
            подтверждает факт ознакомления с Политикой в отношении обработки персональных данных (https://telebon.ru/privacy).
            <br />
            <br />
            9.2. Лицензиат выражает своё согласие на обработку следующих категорий персональных данных: фамилия, имя, отчество, адрес
            электронной почты, контактные телефоны.
            <br />
            <br />
            9.3. Обработка персональных данных может осуществляться с использованием средств автоматизации или без таковых, включая сбор,
            запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
            (предоставление, доступ), блокирование, обезличивание, удаление, уничтожение персональных данных и совершение иных действий,
            предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»
            <br />
            <br />
            9.4. Целями обработки персональных данных являются:
            <br />
            &emsp;•&emsp; Идентификации Пользователя, регистрация на Сайте и в Программном продукте.
            <br />
            &emsp;•&emsp; Использование Программного продукта и Сайта в соответствии с их назначением, заключение и исполнение договора.
            <br />
            &emsp;•&emsp; Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования
            сайта, обработка запросов и заявок от Пользователя.
            <br />
            &emsp;•&emsp; Обработки и получения платежей, оспаривания платежа. В целях дополнительной защиты от мошеннических действий
            указанные Пользователем персональные данные могут быть переданы платёжной системе, осуществляющей транзакции.
            <br />
            &emsp;•&emsp; Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с
            использованием Сайта и Программного продукта.
            <br />
            &emsp;•&emsp; Предоставления Пользователю обновлений продукции, специальных предложений, информации о ценах, новостной рассылки
            и иных сведений от имени Администрации сайта.
            <br />
            &emsp;•&emsp; Осуществления рекламной деятельности.
            <br />
            &emsp;•&emsp; Исполнение требований законодательства Российской Федерации.
            <br />
            <br />
            9.5. Полученное согласие действует со дня заключения настоящего Договора и до достижения целей обработки персональных данных,
            если иное не предусмотрено законодательством Российской Федерации, и досрочно может быть отозвано Лицензиатом путем направления
            ООО «ГК «Белый Медведь» уведомления по адресу электронной почты: hello@telebon.ru
            <br />
            <br />
            9.6. Условия обработки персональных данных, определенные в пунктах 10.1. - 10.5. настоящего Договора не распространяются на
            случай, когда установка Программного продукта осуществляется на техническом средстве (ЭВМ) Лицензиата. В данном случае Лицензиат
            самостоятельно несет ответственность за сбор, обработку и хранение персональных данных. В случае претензий со стороны третьих
            лиц и/или государственных органов к Лицензиату, Лицензиат обязуется компенсировать любые штрафы и/или ущерб от таких претензий.
            <br />
            <br />
            9.7. В процессе заключения и исполнения настоящего Договора, Лицензиар запрашивает документы, подтверждающие правомерность
            деятельности Лицензиата – юридического лица, а также документы, подтверждающие личность Лицензиата – физического лица.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            10. Электронный документооборот
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            10.1. Стороны соглашаются признавать полученные (направленные) электронные документы, равнозначными аналогичным документам на
            бумажных носителях, заверенным соответствующими подписями и оттиском печатей Сторон, при выполнении условий, определенных
            настоящим разделом.
            <br />
            <br />
            10.2. Стороны согласны обмениваться следующими электронными документами: договоры, дополнительные соглашениями и приложения к
            ним, счета, акты приема-передачи, акты выполненных работ/оказанных услуг, товарные накладные, акты сверки расчетов,
            счет-фактуры. Стороны оставляют за собой право в любой момент ввести в электронный документооборот любые иные документы, прямо
            не указанные в настоящем пункте, и применять при обмене такими документами правила, установленные Договором.
            <br />
            <br />
            10.3. Стороны обязаны информировать друг друга о невозможности обмена документами в электронном виде, подписанными усиленной
            квалифицированной электронной подписью, в случае технического сбоя внутренних систем Сторон. В этом случае в период действия
            такого сбоя Стороны производят обмен документами на бумажном носителе с подписанием собственноручной подписью уполномоченного
            лица и оттиском печати Сторон с учетом положений п. 12.3 Договора.
            <br />
            <br />
            10.4. Стороны оставляют за собой право изготавливать и обмениваться документами, предусмотренными данным разделом, на бумажных
            носителях, заверенными соответствующими подписями и оттиском печати Сторон.
            <br />
            <br />
            10.5. При выставлении, направлении и обмене электронными документами Стороны руководствуются следующим порядком:
            <br />
            <p className='padding'>
              10.5.1. Направляющая Сторона формирует электронный документ в Системе ЭДО, подписывает его посредством усиленной
              квалифицированной электронной подписи и отправляет его получающей Стороне.
            </p>
            <p className='padding'>
              10.5.2. Получающая Сторона при получении документа в электронном виде проверяет действительность сертификата усиленной
              квалифицированной электронной подписи и сохраняет документ в Системе ЭДО.
            </p>
            <p className='padding'>
              10.5.3. Получающая Сторона в течение 5 (пяти) рабочих дней с момента получения документов, направляет направляющей Стороне
              через Оператора ЭДО извещение об их получении. Получающая Сторона, ознакомившись с документом, может совершить одно из
              следующих действий:
            </p>
            &emsp; •&emsp; Подписать его посредством усиленной квалифицированной электронной подписи и отправить направляющей Стороне по
            Системе ЭДО – в том случае, если получающая Сторона согласна с содержанием документа.
            <br />
            &emsp;•&emsp; При несогласии с содержанием документа – отказать в подписании, указав причину несогласия, подписать отказ
            посредством усиленной квалифицированной электронной подписи и отправить направляющей Стороне.
            <br />
            <p className='padding'>
              10.5.4. Направляющая Сторона, получившая подписанный документ или отказ от подписания, проверяет действительность сертификата
              усиленной квалифицированной электронной подписи и сохраняет их в Системе ЭДО.
            </p>
            <br />
            10.6. Оплата за услуги при передаче Электронного документа производится за счет средств Стороны, осуществляющей отправку
            электронного документа.
            <br />
            <br />
            10.7. Передача Электронных документов через Систему ЭДО фиксируется протоколом передачи, автоматически формируемым Системой ЭДО,
            в котором отражается каждое действие с электронным документом на этапах его согласования и подписания усиленной
            квалифицированной электронной подписью. Стороны признают, что протокол передачи является достаточным доказательством факта
            получения электронных документов Стороной.
            <br />
            <br />
            10.8. При использовании усиленной квалифицированной электронной подписи Стороны обязаны:
            <p className='padding'>
              10.8.1. Обеспечивать конфиденциальность ключей электронных подписей, в частности не допускать использования принадлежащих им
              ключей электронных подписей без их согласия.
            </p>
            <p className='padding'>
              10.8.2. Уведомлять удостоверяющий центр, выдавший сертификат, и иных участников электронного взаимодействия о нарушении
              конфиденциальности ключа электронной подписи в течение не более чем 1 (одного) рабочего дня со дня получения информации о
              таком нарушении.
            </p>
            <p className='padding'>
              10.8.3. Не использовать ключ электронной подписи при наличии оснований полагать, что конфиденциальность данного ключа
              нарушена.
            </p>
            <p className='padding'>
              10.8.4. Использовать сертифицированные в соответствии с требованиями законодательства Российской Федерации средства
              электронной подписи для создания и проверки усиленной квалифицированной электронной подписи, создания ключей электронных
              подписей и ключей их проверки.
            </p>
            <br />
            10.9. Усиленная квалифицированная электронная подпись признается действительной до тех пор, пока решением суда не установлено
            иное, при одновременном соблюдении следующих условий:
            <p className='padding'>
              10.9.1. Сертификат создан и выдан аккредитованным удостоверяющим центром, аккредитация которого действительна на день выдачи
              указанного сертификата.
            </p>
            <p className='padding'>
              10.9.2. Сертификат действителен на момент подписания электронного документа (при наличии достоверной информации о моменте
              подписания электронного документа) или на день проверки действительности указанного сертификата, если момент подписания
              электронного документа не определен.
            </p>
            <p className='padding'>
              10.9.3. Имеется положительный результат проверки принадлежности владельцу сертификата, с помощью которой подписан электронный
              документ, и подтверждено отсутствие изменений, внесенных в этот документ после его подписания. При этом проверка
              осуществляется с использованием сертифицированных средств электронной подписи, и с использованием Сертификата лица,
              подписавшего электронный документ.
            </p>
            <p className='padding'>
              10.9.4. Усиленная квалифицированная электронная подпись используется с учетом ограничений, содержащихся в сертификате лица,
              подписывающего электронный документ (если такие ограничения установлены).
            </p>
            <br />
            10.10. Каждая из Сторон предоставляет заверения и гарантии, что лица, подписывающие документы от имени соответствующей Стороны с
            использованием усиленной квалифицированной электронной подписи, имеют на подписание таких документов соответствующие полномочия.
            <p className='padding'>
              10.10.1. Стороны вправе запрашивать друг у друга документы, подтверждающие полномочия лица, подписавшего Электронный документ,
              на их подписание.
            </p>
            <p className='padding'>
              10.10.2. В случае отзыва доверенности, либо иных случаев утраты или изменения объема прав лица, обладающего правом подписи
              документов, соответствующая Сторона должна в тот же день известить об этом другую Сторону в порядке, предусмотренном
              Договором, в противном случае такая Сторона не вправе в дальнейшем ссылаться на подписание документов неуполномоченным лицом.
            </p>
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            11. Заключительные положения
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            11.1. О любых изменениях в настоящий Договор Лицензиар уведомляет Лицензиата путем направления соответствующего уведомления на
            электронную почту последнего или через Личный кабинет.
            <br />
            <br />
            11.2. Документы, которыми Стороны обмениваются в процессе исполнения настоящего Договора и отправленные по факсу и/или
            электронной почте имеют силу оригиналов до получения таких документов на бумажном носителе и признаются Сторонами в качестве
            оригиналов документов.
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={700}
            color={'black'}
          >
            12. Адрес и реквизиты Лицензиара
          </NewText>
          <Padding $size='16px' />
          <NewText
            $customSize={TextCustomType.T14}
            fontWeight={400}
            color={'black'}
          >
            ООО «Группа компаний «Белый Медведь»
            <br />
            610050, г. Киров, улица Московская, дом 199, этаж 2
            <br />
            ИНН 4345410051
            <br />
            КПП 434501001
            <br />
            Тел: 8 812 507-63-33
            <br />
            <br />
            Банковские реквизиты
            <br />
            ПАО "НОРВИК БАНК"
            <br />
            <br />
            Расчетный счет: 40702810800350161643
            <br />
            Корреспондентский счет: 30101810745374525845
            <br />
            БИК: 044525845
          </NewText>
        </List>
      </PortalTextWrapper>
    </PersonalAreaWrapper>
  );
};

export default LicencePage;
