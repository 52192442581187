export function StarTarifIcon() {
  return (
    <svg
      width='33'
      height='33'
      viewBox='0 0 33 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.5528 10.8627C5.27322 11.6048 3.63344 11.9758 3.24331 13.2303C2.85318 14.4849 3.97108 15.7921 6.20688 18.4066L6.78531 19.083C7.42065 19.8259 7.73832 20.1974 7.88123 20.657C8.02414 21.1165 7.97612 21.6122 7.88006 22.6034L7.79261 23.5059C7.45459 26.9942 7.28558 28.7383 8.30695 29.5136C9.32832 30.289 10.8636 29.5821 13.9343 28.1682L14.7287 27.8025C15.6013 27.4007 16.0376 27.1998 16.5001 27.1998C16.9626 27.1998 17.3988 27.4007 18.2714 27.8025L19.0659 28.1682C22.1365 29.5821 23.6718 30.289 24.6932 29.5136C25.7146 28.7383 25.5456 26.9942 25.2076 23.5059M26.7933 18.4066C29.0291 15.7921 30.147 14.4849 29.7569 13.2303C29.3667 11.9758 27.7269 11.6048 24.4474 10.8627L23.5989 10.6708C22.6669 10.4599 22.201 10.3545 21.8268 10.0704C21.4527 9.7864 21.2127 9.35593 20.7328 8.495L20.2959 7.71119C18.607 4.68152 17.7626 3.16669 16.5001 3.16669C15.2376 3.16669 14.3932 4.68152 12.7043 7.71119'
        stroke='#526ED3'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
}
