export const resizeAndConvertToBase64 = (file: Blob, maxWidth: number, maxHeight: number) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      if (e.target && typeof e.target.result === 'string') {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (ctx) {
            let newWidth, newHeight;
            if (image.width > image.height) {
              newWidth = maxWidth;
              newHeight = (maxWidth / image.width) * image.height;
            } else {
              newHeight = maxHeight;
              newWidth = (maxHeight / image.height) * image.width;
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(image, 0, 0, newWidth, newHeight);

            const resizedBase64 = canvas.toDataURL('image/jpeg');

            resolve(resizedBase64);
          } else {
            reject(new Error('Canvas 2D context is null'));
          }
        };

        image.onerror = (error) => reject(error);
      } else {
        reject(new Error('Invalid image format'));
      }
    };

    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};
