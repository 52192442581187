import React from 'react';

export function OptionsWithCircle() {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='3'
        cy='15'
        r='2'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M8 15L12.8457 14.9863L17.6914 14.9727'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle
        cx='3'
        cy='3'
        r='2'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M8 3L12.8457 2.98633L17.6914 2.97266'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle
        cx='16.6914'
        cy='9'
        r='2'
        transform='rotate(180 16.6914 9)'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M11 9L6.5 9.00439L2 9.00879'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
}
