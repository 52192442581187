import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { usePaymentHistoryQuery } from '../../../../../../store/redux/tarif/hooks/useTarifQuery';
import { IconNew, EIcon } from '../../../../../icons/medium-new-icons/icon';
import {
  ModalHeader,
  PaymentDate,
  PaymentHeaderDate,
  PaymentHeaderSumm,
  PaymentsEmpty,
  PaymentsHeader,
  PaymentsHistoryModalContent,
  PaymentsHistoryModalPayment,
  PaymentsHistoryModalWrapper,
  PaymentsList,
  PaymentSumm
} from './PaymentsHistoryModal.styled';

import emptyImg from '../../../../../../assets/images/empty-employee-service.webp';

interface IProps {
  closeModal: () => void;
}

const PaymentsHistoryModal: FC<IProps> = ({ closeModal }) => {
  const { t } = useTranslation();
  const { data: payments, isLoading } = usePaymentHistoryQuery();
  return (
    <PaymentsHistoryModalWrapper $column>
      <ModalHeader
        $gap='16px'
        $align='center'
      >
        <NewButton
          typeBtn='close'
          onClick={closeModal}
        >
          <IconNew name={EIcon.close} />
        </NewButton>
        <NewText
          $tag={TextType.H3}
          fontWeight={700}
          color='title'
          $customSize={TextCustomType.T28}
        >
          {t('История платежей')}
        </NewText>
      </ModalHeader>
      <PaymentsHistoryModalContent
        $column
        $gap='12px'
      >
        {payments && payments.length > 0 ? (
          <>
            <PaymentsHeader>
              <PaymentHeaderSumm>{t('Сумма')}</PaymentHeaderSumm>
              <PaymentHeaderDate>{t('Дата')}</PaymentHeaderDate>
            </PaymentsHeader>
            <PaymentsList
              $column
              $gap='12px'
            >
              {payments.map((payment) => (
                <PaymentsHistoryModalPayment key={payment.payid}>
                  <PaymentSumm>{payment.summ} ₽</PaymentSumm>
                  <PaymentDate>{dayjs(payment.date).format('DD-MM-YYYY HH:mm:ss')}</PaymentDate>
                </PaymentsHistoryModalPayment>
              ))}
            </PaymentsList>
          </>
        ) : (
          <PaymentsEmpty
            $column
            $gap='16px'
            $align='center'
            $justify='center'
          >
            <img
              src={emptyImg}
              alt='payments-history'
            />
            <NewText $customSize={TextCustomType.T16}>{'Платежей пока нет'}</NewText>
          </PaymentsEmpty>
        )}
      </PaymentsHistoryModalContent>
    </PaymentsHistoryModalWrapper>
  );
};

export default PaymentsHistoryModal;
