export function ArrowLeftOutline() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2071 4.29289C13.5976 4.68342 13.5976 5.31658 13.2071 5.70711L7.91421 11H19.5C20.0523 11 20.5 11.4477 20.5 12C20.5 12.5523 20.0523 13 19.5 13H7.91421L13.2071 18.2929C13.5976 18.6834 13.5976 19.3166 13.2071 19.7071C12.8166 20.0976 12.1834 20.0976 11.7929 19.7071L4.79289 12.7071C4.40237 12.3166 4.40237 11.6834 4.79289 11.2929L11.7929 4.29289C12.1834 3.90237 12.8166 3.90237 13.2071 4.29289Z'
        fill='currentColor'
      />
    </svg>
  );
}
