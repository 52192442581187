import styled from 'styled-components';

export const LoaderWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  .showbox {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5%;
  }

  // end demo-specific

  .loader {
    position: relative;
    margin: 0px auto;
    width: 100px;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes color {
    100%,
    0% {
      stroke: ${(props) => props.theme.color.primary};
    }
    40% {
      stroke: ${(props) => props.theme.color.primary};
    }
    66% {
      stroke: ${(props) => props.theme.color.primary};
    }
    80%,
    90% {
      stroke: ${(props) => props.theme.color.primary};
    }
  }
`;
