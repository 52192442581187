import { NoteState } from './notes.interface';
import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { createSlice } from '@reduxjs/toolkit';
import { createNotesAsync, deleteNoteAsync, fetchNotesAsync } from './notes.actions';

const initialState: NoteState = {
  notes: [],
  state: PENDING
};

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotesAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchNotesAsync.fulfilled, (state, action) => {
        state.notes = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchNotesAsync.rejected, (state) => {
        state.state = REJECTED;
      })
      .addCase(createNotesAsync.fulfilled, (state) => {
        state.state = FULFILLED;
      })
      .addCase(deleteNoteAsync.fulfilled, (state) => {
        state.state = FULFILLED;
      });
  }
});

export const { reducer } = notesSlice;
