import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background: ${(props) => props.theme.color.base02};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 15px 30px 22px 15px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 100px;
`;
export const Text = styled.div`
  font-weight: bold;
  color: ${(props) => props.theme.color.mainDark};
`;
export const Date = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  position: absolute;
  bottom: 6px;
  right: 10px;
  color: ${(props) => props.theme.color.secondaryDark};
`;
export const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  &.relative{
    position: relative;
    inset: 0;
  }
  svg {
    color: ${(props) => props.theme.color.secondaryDark};
    width: 10px;
    height: 10px;
    opacity: 1;
  }
`;
