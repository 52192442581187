import { useQuery } from '@tanstack/react-query';

import { ClientsService } from '../../../../shared/api/model/service/clients.service';
import { CLIENT_QUERY, CLIENTS_QUERY } from '../../../../shared/const/query.const';
import { ClientState, IClient } from '../clients.interface';

export function useClientsQuery() {
  return useQuery({
    queryKey: [CLIENTS_QUERY],
    queryFn: () => ClientsService.fetchClients(),
    select: (data: IClient[]) => {
      return data.sort((a, b) => a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' }));
    }
  });
}

export function useClientByIdQuery(id: string | undefined) {
  return useQuery({
    queryKey: [CLIENT_QUERY, id],
    queryFn: () => ClientsService.fetchClientById(id!),
    enabled: !!id,
    select: (data: ClientState) => data.clients || []
  });
}
