import React from 'react';

export function GrayX() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.33619 5.18381L12.0002 10.8478L17.6642 5.18381C17.7922 5.05581 17.9682 4.99181 18.1922 4.99181C18.4162 4.99181 18.6082 5.07181 18.7682 5.23181C18.9282 5.39181 19.0082 5.58381 19.0082 5.80781C19.0082 6.03181 18.9442 6.20781 18.8162 6.33581L13.1522 11.9998L18.8162 17.6638C18.9442 17.7918 19.0082 17.9678 19.0082 18.1918C19.0082 18.4158 18.9282 18.6078 18.7682 18.7678C18.6082 18.9278 18.4162 19.0078 18.1922 19.0078C17.9682 19.0078 17.7922 18.9438 17.6642 18.8158L12.0002 13.1518L6.33619 18.8158C6.20819 18.9438 6.03219 19.0078 5.80819 19.0078C5.58419 19.0078 5.39219 18.9278 5.23219 18.7678C5.07219 18.6078 4.99219 18.4158 4.99219 18.1918C4.99219 17.9678 5.05619 17.7918 5.18419 17.6638L10.8482 11.9998L5.18419 6.33581C5.05619 6.20781 4.99219 6.03181 4.99219 5.80781C4.99219 5.58381 5.07219 5.39181 5.23219 5.23181C5.39219 5.07181 5.58419 4.99181 5.80819 4.99181C6.03219 4.99181 6.20819 5.05581 6.33619 5.18381Z'
        fill='currentColor'
      />
    </svg>
  );
}
