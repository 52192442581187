import { createAsyncThunk } from '@reduxjs/toolkit';
import { TarifService } from '../../../shared/api/model/service/tarif.service';
import { ISetMyTarif } from './tarif.interface';

export const getMyTarifAsync = createAsyncThunk('tarif/getMyTarif', async () => {
  return await TarifService.getMyTarif();
});
export const getMyBalanceAsync = createAsyncThunk('tarif/getMyBalance', async () => {
  return await TarifService.getBalanсe();
});
export const getHistoryAsync = createAsyncThunk('tarif/getHistory', async () => {
  return await TarifService.getHistory();
});
export const setMyTarifAsync = createAsyncThunk('tarif/setMyTarif', async (values: ISetMyTarif) => {
  return await TarifService.setMyTarif(values);
});

export const verifyPayAsync = createAsyncThunk('tarif/verifyPay', async (paymentId: string, { dispatch }) => {
  const res = await TarifService.verifyPay(paymentId);
  if (res.status === 'ok') {
    dispatch(getMyTarifAsync);
    dispatch(getHistoryAsync);
    dispatch(getMyBalanceAsync);
  }
});
