import React, { forwardRef, InputHTMLAttributes, Ref, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useMaskito } from '@maskito/react';
import { FormikProps } from 'formik';

import {
  ErrorText,
  IconContainer,
  InputContainer,
  InputContent,
  Label,
  TimeList,
  TimeListSheet,
  TimeListWrapper
} from './NewTimepicker.styled';

import { EIcon, IconNew } from '../../../../components/icons/medium-new-icons/icon';
import {
  MobileAbsoluteDropdown,
  MobileCenter
} from '../../../../pages/EmployeesPage/ui/employee-timetable/ui/EmployeeTimeTablePage.styled';
import { timeMask } from '../../../../utils/mask';
import { cn } from '../../../lib';
import useMatchMedia from '../../../lib/hooks/useMatchMedia';
import { Drawer } from '../../../ui/Drawer';
import { InputType } from '../../../ui/Input';

type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'readOnly' | 'size'>;

interface InputProps extends HTMLInputProps {
  label?: string;
  type?: string;
  size?: InputType;
  value: string | any;
  onChange: any;
  name?: string;
  className?: string;
  error?: any;
  formik?: FormikProps<any>;
  timeIntervals?: number;
  lowerLimit?: string | any;
  upperLimit?: string | any;
  noticeText?: string;
  isBreak?: boolean;
  disabled?: boolean;
  ref?: any;
}

function useCombinedRefs<T>(...refs: (Ref<T> | undefined)[]): RefObject<T> {
  const targetRef = useRef<T>(null);

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else if (ref) {
        (ref as React.MutableRefObject<T | null>).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

const NewTimepicker = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref) => {
  const {
    label,
    className,
    children,
    size,
    value,
    onChange,
    name,
    error = null,
    formik,
    lowerLimit,
    upperLimit,
    noticeText,
    isBreak,
    disabled,
    ...rest
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [isTimeListOpen, setIsTimeListOpen] = useState(false);
  const inputNumberRef = useMaskito({ options: timeMask });
  const inputNumberRef2 = useRef<HTMLDivElement>(null);
  const combinedRef = useCombinedRefs(ref, inputNumberRef, inputNumberRef2);
  const timeListRef = useRef<HTMLDivElement>(null);
  const timeListRef1 = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const selectedTimeRef = useRef<HTMLDivElement | null>(null);
  const selectedTimeRef1 = useRef<HTMLDivElement | null>(null);
  const [filteredTimeData, setFilteredTimeData] = useState<string[]>([]);
  const { t } = useTranslation();
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const timeData: string[] = [];
  const [breakInputValue, setBreakInputValue] = useState(value);

  if (lowerLimit || upperLimit) {
    const [hour, minute] = lowerLimit.split(':');
    const endHour = upperLimit && upperLimit.split(':')[0] ? Number(upperLimit.split(':')[0]) : 24;

    for (let hours = Number(hour); hours <= endHour; hours++) {
      if (hours === 24) break;
      const startMinutes = Number(hours) === Number(hour) ? Number(minute) : 0;
      const endMinutes =
        Number(hours) === Number(endHour) && upperLimit && upperLimit.split(':')[1] ? Number(upperLimit.split(':')[1]) : 60;

      for (let minutes = startMinutes; minutes <= endMinutes; minutes += 15) {
        if (hours !== endHour && minutes === 60) {
          continue;
        }
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes === 60 ? '00' : minutes.toString().padStart(2, '0');
        const timeLabel = `${formattedHours}:${formattedMinutes}`;
        timeData.push(timeLabel);
      }
    }
  } else {
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const timeLabel = `${formattedHours}:${formattedMinutes}`;
        timeData.push(timeLabel);
      }
    }
  }

  const handleTimeClick = (selectedTime: string) => {
    const event = {
      target: {
        name: name,
        value: selectedTime
      }
    } as React.ChangeEvent<HTMLInputElement>;
    setBreakInputValue(selectedTime);
    onChange(event);
    setIsTimeListOpen(false);
    isMobile && onClose();
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    setIsTimeListOpen(true);
  };

  const handleInputBlur = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setIsFocused(false);
    setTimeout(() => setIsTimeListOpen(false), 200);
    if (isBreak) {
      if (!timeData.find((time) => time === breakInputValue)) {
        setBreakInputValue('');
      }
    }
  };
  useEffect(() => {
    if (!isMobile) {
      if (isTimeListOpen && timeListRef.current && selectedTimeRef.current) {
        const selectedIndex = timeData.findIndex((time) => time.trim() === value.trim());
        if (selectedIndex !== -1) {
          const selectedTimeElement = selectedTimeRef.current;
          const scrollableWrapper = timeListRef.current.querySelector('.scrollable-wrapper');

          if (scrollableWrapper && !scrollableWrapper.getAttribute('data-scrolled')) {
            scrollableWrapper.setAttribute('data-scrolled', 'true');

            const scrollPosition = Math.max(0, selectedTimeElement.offsetTop - scrollableWrapper.clientHeight / 2);
            scrollableWrapper.scrollTop = scrollPosition;
          }
        }
      }
    }
  }, [isTimeListOpen, value, timeData, breakInputValue]);

  useEffect(() => {
    if (isMobile && isDrawerOpen) {
      const timeoutId = setTimeout(() => {
        if (timeListRef1.current && selectedTimeRef1.current) {
          const selectedIndex = timeData.findIndex((time) => time.trim() === value.trim());
          if (selectedIndex !== -1) {
            const selectedTimeElement = selectedTimeRef1.current;
            const scrollableWrapper = timeListRef1.current.querySelector('.scrollable-wrapper-mobile');

            if (scrollableWrapper && !scrollableWrapper.getAttribute('data-scrolled')) {
              scrollableWrapper.setAttribute('data-scrolled', 'true');

              const scrollPosition = Math.max(0, selectedTimeElement.offsetTop - scrollableWrapper.clientHeight / 2);
              scrollableWrapper.scrollTop = scrollPosition;
            }
          }
        }
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isDrawerOpen, isMobile, timeData, value, breakInputValue]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (name) {
      if (isBreak) {
        setBreakInputValue(e.target.value);
        if (timeData.find((time) => time === e.target.value)) {
          onChange(e);
        }
      } else {
        onChange(e);
      }

      const inputValue = e.target.value;
      const newFilteredTimeData = timeData.filter((time) => time.startsWith(inputValue));
      setFilteredTimeData(newFilteredTimeData);
    }
  };

  // useEffect(() => {
  //   if (!isTimeListOpen && isBreak) {
  //     handleSetInputValue(breakInputValue);
  //   }
  // }, [breakInputValue]);

  return (
    <InputContainer
      ref={ref}
      size={size}
      className={cn(isFocused && !isMobile && 'focused', className)}
    >
      <Label
        size={size}
        htmlFor={name}
        className={cn(
          {
            active: (value && value.length) || (value && Number(value) > 0 && !isMobile),
            error: !!error
          },
          disabled && 'disabled'
        )}
        onClick={() => {
          if (disabled) return;
          setIsDrawerOpen(true);
          setIsOpen(true);
        }}
      >
        {label && <span>{label}</span>}

        <InputContent
          size={size}
          readOnly={isMobile}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={isBreak ? breakInputValue : value}
          autoComplete={'off'}
          ref={combinedRef}
          name={name}
          onInput={handleInputChange}
          title='Введите время в формате HH:mm'
          type='text'
          id={name}
          disabled={disabled}
          {...rest}
        />
      </Label>

      {isTimeListOpen && !isMobile && (
        <TimeListWrapper>
          <TimeList
            size={size}
            className='time-list'
            ref={timeListRef}
          >
            <div className='scrollable-wrapper'>
              {(filteredTimeData.length > 0 ? filteredTimeData : timeData).map((time) => (
                <div
                  key={time}
                  onClick={() => handleTimeClick(time)}
                  className={cn({
                    'selected-time': time === value
                  })}
                  ref={(el) => {
                    if (time === value) {
                      selectedTimeRef.current = el;
                    }
                  }}
                >
                  <span>{time}</span>
                </div>
              ))}
            </div>
          </TimeList>
        </TimeListWrapper>
      )}

      <IconContainer
        className={cn({
          active: value && value.length,
          error: !!error
        })}
        onClick={() => {
          if (combinedRef.current && !isTimeListOpen) {
            combinedRef.current.focus();
          } else if (combinedRef.current && isTimeListOpen) {
            combinedRef.current.blur();
          }
        }}
      >
        <IconNew name={EIcon.arrowdown} />
      </IconContainer>

      {error ? <ErrorText size={size}>{error}</ErrorText> : null}
      {isMobile && isOpen && (
        <Drawer
          full
          isOpen={isOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            onClose();
          }}
          header={label}
        >
          <MobileAbsoluteDropdown>
            <MobileCenter>
              <TimeListSheet
                className='time-list sheet'
                ref={timeListRef1}
              >
                <div className='scrollable-wrapper-mobile'>
                  {(filteredTimeData.length > 0 ? filteredTimeData : timeData).map((time) => (
                    <div
                      key={time}
                      onClick={() => handleTimeClick(time)}
                      className={cn({
                        'selected-time': time === value
                      })}
                      ref={(el) => {
                        if (time === value) {
                          selectedTimeRef1.current = el;
                        }
                      }}
                    >
                      <span>{time}</span>
                    </div>
                  ))}
                </div>
              </TimeListSheet>
            </MobileCenter>
          </MobileAbsoluteDropdown>
        </Drawer>
      )}
    </InputContainer>
  );
});

NewTimepicker.displayName = 'NewTimepicker';

export default NewTimepicker;
