import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SaleproductsService } from '../../../../shared/api/model/service/saleproducts.service';
import { SALE_PRODUCTS_QUERY } from '../../../../shared/const/query.const';
import { ICreateSaleProduct, IUpdateSaleProduct } from '../saleproducts.interface';

export function useCreateSaleProduct() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: ICreateSaleProduct) => SaleproductsService.createSaleProduct(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SALE_PRODUCTS_QUERY] });
      }
    }
  });
}

export function useUpdateSaleProduct() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: IUpdateSaleProduct) => SaleproductsService.updateSaleProduct(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SALE_PRODUCTS_QUERY] });
      }
    }
  });
}

export function useDeleteSaleProduct() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => SaleproductsService.deleteSaleProduct(id),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SALE_PRODUCTS_QUERY] });
      }
    }
  });
}
