export const ContextMenuIcon = () => {
  return (
    <svg
      width='10'
      height='21'
      viewBox='0 0 10 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.14733 0.542969C8.67418 3.81473 6.38169 6.47718 2.26986 8.53032L1.89105 8.7153C0.894302 9.19128 0.47214 10.3852 0.948125 11.3819C1.14375 11.7916 1.47292 12.1225 1.88154 12.3203C6.23756 14.4287 8.65948 17.1696 9.14733 20.543L9.16618 13.7175C9.17142 8.92709 9.16514 3.54295 9.14733 0.542969Z'
        fill='currentColor'
      />
    </svg>
  );
};
