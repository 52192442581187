import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CashRegisterService } from '../../../../shared/api/model/service/cashregister.service';
import { CASH_REGISTER, CASH_REGISTER_ALL, SALES_QUERY, TRANSACTIONS } from '../../../../shared/const/query.const';
import { ICashInput, ICashRegisterData, ITransferCash } from '../cashRegister.interface';

export const useCashRegisterCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: ICashRegisterData) => CashRegisterService.createCashRegister(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CASH_REGISTER_ALL] });
        await queryClient.invalidateQueries({ queryKey: [CASH_REGISTER] });
      }
    }
  });
};

export const useCashRegisterUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: ICashRegisterData) => CashRegisterService.updateCashRegister(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CASH_REGISTER_ALL] });
        await queryClient.invalidateQueries({ queryKey: [CASH_REGISTER] });
      }
    }
  });
};

export const useCashRegisterDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => CashRegisterService.deleteCashRegister(id),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CASH_REGISTER_ALL] });
      }
    }
  });
};

export const useCashInput = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ICashInput) => CashRegisterService.cashInput(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CASH_REGISTER_ALL] });
        await queryClient.invalidateQueries({ queryKey: [SALES_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [TRANSACTIONS] });
      }
    }
  });
};

export const useCashOutput = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ICashInput) => CashRegisterService.cashOutput(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CASH_REGISTER_ALL] });
        await queryClient.invalidateQueries({ queryKey: [SALES_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [TRANSACTIONS] });
      }
    }
  });
};

export const useTransferCash = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ITransferCash) => CashRegisterService.transferCash(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [CASH_REGISTER_ALL] });
        await queryClient.invalidateQueries({ queryKey: [SALES_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [TRANSACTIONS] });
      }
    }
  });
};
