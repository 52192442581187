import React from 'react';

export function TelegramBlue() {
  return (
    <svg
      width='32'
      height='33'
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 32.9102C24.8366 32.9102 32 25.7467 32 16.9102C32 8.0736 24.8366 0.910156 16 0.910156C7.16344 0.910156 0 8.0736 0 16.9102C0 25.7467 7.16344 32.9102 16 32.9102Z'
        fill='url(#paint0_linear_4167_19824)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.24948 16.7354C11.9099 14.6918 15.0252 13.3709 16.5704 12.7229C21.0065 10.8787 21.9286 10.5547 22.5267 10.5547C22.6514 10.5547 22.9504 10.5796 23.1498 10.7291C23.2993 10.8538 23.3492 11.0282 23.3741 11.1528C23.399 11.2774 23.4239 11.5516 23.399 11.7759C23.1498 14.293 22.128 20.4488 21.5797 23.265C21.3554 24.4612 20.9068 24.86 20.4831 24.9098C19.561 24.9846 18.8383 24.2868 17.9411 23.7136C16.5454 22.7914 15.7479 22.2182 14.3772 21.321C12.8071 20.2743 13.8289 19.7011 14.7261 18.779C14.9504 18.5298 19.0626 14.8164 19.1373 14.4675C19.1373 14.4176 19.1623 14.2681 19.0626 14.1933C18.9629 14.1186 18.8383 14.1435 18.7386 14.1684C18.589 14.1933 16.3461 15.6886 11.9847 18.6295C11.3367 19.0781 10.7635 19.2774 10.2401 19.2774C9.66693 19.2774 8.57035 18.9534 7.74792 18.6793C6.75104 18.3553 5.95353 18.1809 6.0283 17.6326C6.10306 17.3335 6.50182 17.0344 7.24948 16.7354Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_4167_19824'
          x1='0.0237259'
          y1='16.8986'
          x2='32.0006'
          y2='16.8986'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2AABEE' />
          <stop
            offset='1'
            stopColor='#229ED9'
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
