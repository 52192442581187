import React from 'react';

export function WarningFill() {
  return (
    <svg
      width='16'
      height='15'
      viewBox='0 0 16 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.1804 1.29943C9.21393 -0.433136 6.78608 -0.433147 5.81962 1.29942L0.340829 11.1213C-0.641739 12.8827 0.627595 15 2.52121 15H13.4788C15.3724 15 16.6417 12.8827 15.6592 11.1213L10.1804 1.29943ZM7.33735 12.4219C7.33735 12.8102 7.63583 13.125 8.00401 13.125C8.3722 13.125 8.67068 12.8102 8.67068 12.4219V11.0156C8.67068 10.6273 8.37221 10.3125 8.00401 10.3125C7.63583 10.3125 7.33735 10.6273 7.33735 11.0156V12.4219ZM7.33735 7.73438C7.33735 8.1227 7.63583 8.4375 8.00401 8.4375C8.37221 8.4375 8.67068 8.1227 8.67068 7.73438L8.67068 4.45312C8.67068 4.0648 8.37221 3.75 8.00402 3.75C7.63583 3.75 7.33735 4.0648 7.33735 4.45312L7.33735 7.73438Z'
        fill='#FFB838'
      />
    </svg>
  );
}
