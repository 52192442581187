import React from 'react';

export function TrashIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 3C10.2348 3 9.98043 3.10536 9.79289 3.29289C9.60536 3.48043 9.5 3.73478 9.5 4V5H15.5V4C15.5 3.73478 15.3946 3.48043 15.2071 3.29289C15.0196 3.10536 14.7652 3 14.5 3H10.5ZM8.37868 1.87868C8.94129 1.31607 9.70435 1 10.5 1H14.5C15.2956 1 16.0587 1.31607 16.6213 1.87868C17.1839 2.44129 17.5 3.20435 17.5 4V5H21.5C22.0523 5 22.5 5.44772 22.5 6C22.5 6.55228 22.0523 7 21.5 7H20.5V20C20.5 20.7957 20.1839 21.5587 19.6213 22.1213C19.0587 22.6839 18.2957 23 17.5 23H7.5C6.70435 23 5.94129 22.6839 5.37868 22.1213C4.81607 21.5587 4.5 20.7957 4.5 20V7H3.5C2.94772 7 2.5 6.55228 2.5 6C2.5 5.44772 2.94772 5 3.5 5H7.5V4C7.5 3.20435 7.81607 2.44129 8.37868 1.87868ZM6.5 7V20C6.5 20.2652 6.60536 20.5196 6.79289 20.7071C6.98043 20.8946 7.23478 21 7.5 21H17.5C17.7652 21 18.0196 20.8946 18.2071 20.7071C18.3946 20.5196 18.5 20.2652 18.5 20V7H6.5ZM10.5 10C11.0523 10 11.5 10.4477 11.5 11V17C11.5 17.5523 11.0523 18 10.5 18C9.94772 18 9.5 17.5523 9.5 17V11C9.5 10.4477 9.94772 10 10.5 10ZM13.5 11C13.5 10.4477 13.9477 10 14.5 10C15.0523 10 15.5 10.4477 15.5 11V17C15.5 17.5523 15.0523 18 14.5 18C13.9477 18 13.5 17.5523 13.5 17V11Z'
        fill='currentColor'
      />
    </svg>
  );
}
