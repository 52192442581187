import { getCookie, setCookie } from '../../../../utils/cookies';
import { apiGet, apiPost } from '../../interceptors';
import { removeTokensStorage, saveToStorage } from '../helpers/auth.helpers';

export const AuthService = {
  async register(email: string, password: string, confirmpassword: string, phone: string) {
    const response = await apiPost('/registry', { email, password, confirmpassword, phone });

    if (response.data.token) saveToStorage(response.data);

    return response;
  },

  async login(username: string, password: string) {
    const response = await apiPost('/auth', { username, password });
    if (response.data.token) {
      setCookie('auth', response.data.token);
      setCookie('id', response.data.id);
      saveToStorage(response.data);
    }

    return response;
  },

  logout() {
    removeTokensStorage();
    localStorage.removeItem('auth');
  },

  async getUserInfo() {
    const id = getCookie('id');

    return await apiGet(`/user/${id}`);
  },

  async resendEmail(email: string) {
    const response = await apiPost('/remail', { email });

    return response.data;
  }
};
