import { styled } from 'styled-components';
import themelight from '../../../../../app/providers/ThemeProvider/ui/theme';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const ModalWrapper = styled(FlexWithAlign)`
  padding: 24px 16px;
  width: 100%;
  height: 100%;
  max-width: 484px;
  background-color: ${themelight.newColor.grey07};
  color: ${themelight.newColor.base01};
`;

export const CloseBtn = styled(NewButton)`
  background-color: transparent;
  color: ${themelight.newColor.base01};
`;
