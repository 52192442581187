import styled from 'styled-components';

import { DURATION, MODAL_GAP, Z_INDEX } from '../../../../styles';

export const SwiperRootConteiner = styled.div`
  max-width: 50vw;
  width: 100%;
  padding: 1rem 10px;
  height: auto;
  position: relative;
  overflow-x: hidden;
  transition: ${DURATION} ease-in-out;
  display: flex;
  align-content: center;
  mask-position: calc(-1 * 1.5rem - 0em) bottom, right, bottom, top;
  -webkit-mask-position: calc(-1 * 1.5rem - 0em) bottom, right, bottom, top;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SwiperItemContainer = styled.div`
  gap: ${MODAL_GAP};
  display: flex;
  height: 96%;
  transition: ${DURATION} ease-in-out;
  position: relative;

  width: 100%;
`;

export const ButtonLeftContainer = styled.div`
  z-index: ${Z_INDEX};
  display: flex;
  align-content: center;
  position: absolute;
  left: 0;
  top: 55px;
`;
export const ButtonRightConteiner = styled.div`
  z-index: ${Z_INDEX};
  display: flex;
  align-content: center;
  position: absolute;
  right: -10px;
  top: 55px;
`;
export const SwiperItem = styled.div`
  width: 148px;
  transition: ${DURATION} ease-in-out;
  display: flex;
`;
