import React from 'react';

export function Service() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.5 15V3C3.5 2.7 3.6 2.5 3.8 2.3C4 2.1 4.4 1.8 5 1.6C5.9 1.2 7.1 1 8.5 1C9.9 1 10.9 1.3 12.9 2.1C14.6 2.8 15.4 3 16.5 3C17.7 3 18.6 2.8 19.3 2.6C19.6 2.5 19.8 2.3 19.8 2.3C20.4 1.7 21.5 2.1 21.5 3V15C21.5 15.3 21.4 15.5 21.2 15.7C21 15.9 20.6 16.2 20 16.4C19.1 16.8 17.9 17 16.5 17C15.1 17 14.1 16.7 12.1 15.9C10.4 15.2 9.6 15 8.5 15C7.3 15 6.4 15.2 5.7 15.4C5.6 15.4 5.5 15.5 5.5 15.5V22C5.5 22.6 5.1 23 4.5 23C3.9 23 3.5 22.6 3.5 22V15ZM8.5 13C9.9 13 10.9 13.3 12.9 14.1C14.7 14.8 15.4 15 16.5 15C17.7 15 18.6 14.8 19.3 14.6C19.4 14.6 19.5 14.5 19.5 14.5V4.6C18.7 4.9 17.7 5 16.5 5C15.1 5 14.1 4.7 12.1 3.9C10.4 3.2 9.6 3 8.5 3C7.3 3 6.4 3.2 5.7 3.4C5.7 3.5 5.6 3.5 5.5 3.5V13.4C6.3 13.1 7.3 13 8.5 13Z'
        fill='currentColor'
      />
    </svg>
  );
}
