export const PaymentIcon7 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24582)'>
        <path
          d='M31.4656 5.03906H4.10559C2.51501 5.03906 1.22559 6.32848 1.22559 7.91906V26.6391C1.22559 28.2297 2.51501 29.5191 4.10559 29.5191H31.4656C33.0562 29.5191 34.3456 28.2297 34.3456 26.6391V7.91906C34.3456 6.32848 33.0562 5.03906 31.4656 5.03906Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.94 17.2801C19.94 18.7041 19.5178 20.0961 18.7266 21.2803C17.9354 22.4642 16.8109 23.3871 15.4953 23.932C14.1797 24.4769 12.7321 24.6196 11.3354 24.3417C9.93873 24.0639 8.65581 23.3782 7.64888 22.3712C6.64193 21.3644 5.9562 20.0815 5.67838 18.6848C5.40058 17.288 5.54315 15.8404 6.08811 14.5248C6.63306 13.2091 7.5559 12.0846 8.73994 11.2935C9.92397 10.5023 11.316 10.0801 12.74 10.0801C14.6496 10.0801 16.481 10.8386 17.8312 12.1889C19.1815 13.5392 19.94 15.3705 19.94 17.2801Z'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.8056 22.4022C19.1374 23.7291 20.9396 24.4759 22.8196 24.4801C24.7292 24.4801 26.5606 23.7215 27.9108 22.3712C29.2611 21.0211 30.0196 19.1897 30.0196 17.2801C30.0196 15.3705 29.2611 13.5392 27.9108 12.1889C26.5606 10.8386 24.7292 10.0801 22.8196 10.0801C20.9373 10.0825 19.1325 10.8301 17.7998 12.1594'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.7399 23.5442C12.5411 23.5442 12.3799 23.3829 12.3799 23.1842C12.3799 22.9854 12.5411 22.8242 12.7399 22.8242'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7402 23.5442C12.9391 23.5442 13.1002 23.3829 13.1002 23.1842C13.1002 22.9854 12.9391 22.8242 12.7402 22.8242'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7399 20.6634C12.5411 20.6634 12.3799 20.5021 12.3799 20.3034C12.3799 20.1045 12.5411 19.9434 12.7399 19.9434'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7402 20.6634C12.9391 20.6634 13.1002 20.5021 13.1002 20.3034C13.1002 20.1045 12.9391 19.9434 12.7402 19.9434'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7399 17.7845C12.5411 17.7845 12.3799 17.6232 12.3799 17.4245C12.3799 17.2256 12.5411 17.0645 12.7399 17.0645'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7402 17.7845C12.9391 17.7845 13.1002 17.6232 13.1002 17.4245C13.1002 17.2256 12.9391 17.0645 12.7402 17.0645'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7399 14.9039C12.5411 14.9039 12.3799 14.7426 12.3799 14.5439C12.3799 14.345 12.5411 14.1838 12.7399 14.1838'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7402 14.9039C12.9391 14.9039 13.1002 14.7426 13.1002 14.5439C13.1002 14.345 12.9391 14.1838 12.7402 14.1838'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7399 12.0247C12.5411 12.0247 12.3799 11.8635 12.3799 11.6647C12.3799 11.4659 12.5411 11.3047 12.7399 11.3047'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M12.7402 12.0247C12.9391 12.0247 13.1002 11.8635 13.1002 11.6647C13.1002 11.4659 12.9391 11.3047 12.7402 11.3047'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M9.86 21.96C9.66118 21.96 9.5 21.7989 9.5 21.6C9.5 21.4011 9.66118 21.24 9.86 21.24'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M9.86035 21.9602C10.0592 21.9602 10.2204 21.7991 10.2204 21.6002C10.2204 21.4014 10.0592 21.2402 9.86035 21.2402'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M15.6198 21.96C15.4209 21.96 15.2598 21.7989 15.2598 21.6C15.2598 21.4011 15.4209 21.24 15.6198 21.24'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M15.6201 21.96C15.819 21.96 15.9801 21.7989 15.9801 21.6C15.9801 21.4011 15.819 21.24 15.6201 21.24'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M18.4996 20.5208C18.3008 20.5208 18.1396 20.3596 18.1396 20.1608C18.1396 19.9619 18.3008 19.8008 18.4996 19.8008'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M18.5 20.5208C18.6989 20.5208 18.86 20.3596 18.86 20.1608C18.86 19.9619 18.6989 19.8008 18.5 19.8008'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M9.86 19.0794C9.66118 19.0794 9.5 18.9182 9.5 18.7194C9.5 18.5205 9.66118 18.3594 9.86 18.3594'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M9.86035 19.0794C10.0592 19.0794 10.2204 18.9182 10.2204 18.7194C10.2204 18.5205 10.0592 18.3594 9.86035 18.3594'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M15.6198 19.0794C15.4209 19.0794 15.2598 18.9182 15.2598 18.7194C15.2598 18.5205 15.4209 18.3594 15.6198 18.3594'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M15.6201 19.0794C15.819 19.0794 15.9801 18.9182 15.9801 18.7194C15.9801 18.5205 15.819 18.3594 15.6201 18.3594'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M18.4996 17.6399C18.3008 17.6399 18.1396 17.4788 18.1396 17.2799C18.1396 17.0811 18.3008 16.9199 18.4996 16.9199'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M18.5 17.6399C18.6989 17.6399 18.86 17.4788 18.86 17.2799C18.86 17.0811 18.6989 16.9199 18.5 16.9199'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M6.83559 20.5208C6.63677 20.5208 6.47559 20.3596 6.47559 20.1608C6.47559 19.9619 6.63677 19.8008 6.83559 19.8008'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M6.83594 20.5208C7.03476 20.5208 7.19594 20.3596 7.19594 20.1608C7.19594 19.9619 7.03476 19.8008 6.83594 19.8008'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M9.86 16.2005C9.66118 16.2005 9.5 16.0393 9.5 15.8405C9.5 15.6416 9.66118 15.4805 9.86 15.4805'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M9.86035 16.2005C10.0592 16.2005 10.2204 16.0393 10.2204 15.8405C10.2204 15.6416 10.0592 15.4805 9.86035 15.4805'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M18.4996 14.7591C18.3008 14.7591 18.1396 14.5979 18.1396 14.3991C18.1396 14.2002 18.3008 14.0391 18.4996 14.0391'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M18.5 14.7591C18.6989 14.7591 18.86 14.5979 18.86 14.3991C18.86 14.2002 18.6989 14.0391 18.5 14.0391'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M15.6198 16.2005C15.4209 16.2005 15.2598 16.0393 15.2598 15.8405C15.2598 15.6416 15.4209 15.4805 15.6198 15.4805'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M15.6201 16.2005C15.819 16.2005 15.9801 16.0393 15.9801 15.8405C15.9801 15.6416 15.819 15.4805 15.6201 15.4805'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M6.83559 17.6399C6.63677 17.6399 6.47559 17.4788 6.47559 17.2799C6.47559 17.0811 6.63677 16.9199 6.83559 16.9199'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M6.83594 17.6399C7.03476 17.6399 7.19594 17.4788 7.19594 17.2799C7.19594 17.0811 7.03476 16.9199 6.83594 16.9199'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M9.86 13.3196C9.66118 13.3196 9.5 13.1584 9.5 12.9596C9.5 12.7608 9.66118 12.5996 9.86 12.5996'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M9.86035 13.3196C10.0592 13.3196 10.2204 13.1584 10.2204 12.9596C10.2204 12.7608 10.0592 12.5996 9.86035 12.5996'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M15.6198 13.3196C15.4209 13.3196 15.2598 13.1584 15.2598 12.9596C15.2598 12.7608 15.4209 12.5996 15.6198 12.5996'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M15.6201 13.3196C15.819 13.3196 15.9801 13.1584 15.9801 12.9596C15.9801 12.7608 15.819 12.5996 15.6201 12.5996'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M6.83559 14.7591C6.63677 14.7591 6.47559 14.5979 6.47559 14.3991C6.47559 14.2002 6.63677 14.0391 6.83559 14.0391'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M6.83594 14.7591C7.03476 14.7591 7.19594 14.5979 7.19594 14.3991C7.19594 14.2002 7.03476 14.0391 6.83594 14.0391'
          stroke='currentColor'
          strokeWidth='2'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24582'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
