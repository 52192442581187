import styled from 'styled-components';

export const Wrapper = styled.form`
  display: flex;
  width: 755px;
  flex-direction: column;
  flex-grow: 1;
  .title {
    padding-top: 3px;
    padding-left: 10px;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    color: #151618;
  }
  .top-wrapper {
    padding: 24px;
    width: 100%;
  }
  &.top {
    margin-top: 24px !important;
  }

  .form-wrapper {
    gap: 6px;
  }
  .closebtn {
    padding-top: 20px;
    padding-left: 7px;
  }
`;

export const CloseBtn = styled.div`
  padding-top: 12px;
  cursor: pointer;
`;

export const CategoryInput = styled.div`
  border-radius: 8px;
  margin: 6px 24px 24px 24px;
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: flex-start;
  .reset-btn {
    width: 131px;
  }
  .create-btn {
    width: 140px;
  }
`;
