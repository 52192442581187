export const EditPen = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        d='M3.75 14.1674H4.81066L11.7959 7.1821L10.7353 6.12143L3.75 13.1067V14.1674ZM15.75 15.6674H2.25V12.4854L12.3263 2.40913C12.6192 2.11623 13.094 2.11623 13.3869 2.40913L15.5083 4.53044C15.8012 4.82334 15.8012 5.29821 15.5083 5.59111L6.93198 14.1674H15.75V15.6674ZM11.7959 5.06077L12.8566 6.12143L13.9172 5.06077L12.8566 4.00012L11.7959 5.06077Z'
        fill='currentColor'
      />
    </svg>
  );
};
