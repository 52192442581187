import React from 'react';

export function GrayPlus() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_534_3219)'>
        <path
          d='M17.1364 8.42463H9.57635V0.864626C9.57635 0.720626 9.51635 0.600626 9.39635 0.504626C9.27635 0.408626 9.14435 0.360626 9.00035 0.360626C8.85635 0.360626 8.73635 0.408626 8.64035 0.504626C8.54435 0.600626 8.49635 0.720626 8.49635 0.864626V8.42463H0.936352C0.744352 8.42463 0.600352 8.48463 0.504352 8.60463C0.408352 8.72463 0.360352 8.85663 0.360352 9.00063C0.360352 9.14463 0.408352 9.27663 0.504352 9.39663C0.600352 9.51663 0.744352 9.55263 0.936352 9.50463H8.49635V17.0646C8.49635 17.2566 8.54435 17.4006 8.64035 17.4966C8.73635 17.5926 8.85635 17.6406 9.00035 17.6406C9.14435 17.6406 9.27635 17.5926 9.39635 17.4966C9.51635 17.4006 9.57635 17.2566 9.57635 17.0646V9.50463H17.1364C17.2804 9.50463 17.4004 9.45663 17.4964 9.36063C17.5924 9.26463 17.6404 9.14463 17.6404 9.00063C17.6404 8.85663 17.5924 8.72463 17.4964 8.60463C17.4004 8.48463 17.2804 8.42463 17.1364 8.42463Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_534_3219'>
          <rect
            width='18'
            height='18'
            fill='white'
            transform='matrix(1 0 0 -1 0 18)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
