import React from 'react';

import { PersonalAreaWrapper, PortalTextWrapper } from './AgreementPage.styled';

import NewPageHeader from '../../../shared/new-ui/NewPageHeader/ui/NewPageHeader';

const AgreementPage = () => {
  return (
    <PersonalAreaWrapper $column>
      <NewPageHeader
        backLink={'/portal'}
        title='Пользовательское cоглашение'
      />
      <PortalTextWrapper>
        <p>
          <span className='font-weight'>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</span>
          <br />
          <br />
          Данный документ является предложением ООО «ГК «Белый Медведь» заключить пользовательское соглашение на следующих условиях:
          <br />
          <br />В соответствии со статьей 437 Гражданского кодекса РФ настоящее Пользовательское соглашение признается публичной офертой.
          <br />
          <br />В случае несогласия Пользователя с каким-либо условием Соглашения, Соглашение не заключается, и Пользователь не имеет права
          использовать объект интеллектуальной собственности.
          <br />
          <br />
          Соглашение считается заключенным, если Пользователь акцептовал его, поставив отметку о согласии с текстом соглашения.
          <br />
          <br />
          <span className='font-weight'>1. Термины и определения</span>
          <br />
          <br />
          Применяемые в настоящем Соглашении термины означают следующее:
          <br />
          <br />
          Лицензиар – ООО «ГК «Белый Медведь» (ИНН 4345410051, ОГРН 1154345004582). <br />
          <br />
          Лицензиат – лицо, получившее право использовать Программный продукт по соответствующему договору. <br />
          <br />
          Программный продукт – программа для ЭВМ «Telebon». <br />
          <br />
          Личный кабинет – инструмент доступа к Программному продукту, расположенный на официальном сайте Программного продукта:
          https://telebon.ru. Для входа в Личный кабинет используются учетные данные, полученные в соответствии с п. 4.1. настоящего
          Соглашения.
          <br />
          <br />
          Пользователь – любое лицо, которому предоставляется доступ к Личному кабинету. <br />
          <br />
          Активированная учетная запись – это пользователь, которого пригласили в аккаунт Пользователя и не деактивировали в разделе
          управления пользователями. <br />
          <br />
          Раздел «Тарифы» – раздел сайта Программного продукта, который содержит информацию о размерах лицензионного вознаграждения.
          <br />
          <br />
          Тариф – размер лицензионного вознаграждения в соответствии с предоставленной функциональностью Программного продукта. <br />
          <br />
          Регистрация банковской карты – операция в Личном кабинете, в рамках которой Пользователь указывает данные своей
          кредитной/дебетовой карты.
          <br />
          <br />
          Платежная система – система, с помощью которой осуществляются все операции по банковским картам.
          <br />
          <br />
          <span className='font-weight'>2. Общие правила</span>
          <br />
          <br />
          2.1 Настоящее Соглашение определяют условия использования Программного продукта Telebon на веб-сайте в сети Интернет по адресу:
          https://telebon.ru, а также права и обязанности Пользователей и Лицензиара. <br />
          <br />
          Настоящее Соглашение распространяется также на отношения, связанные с правами и интересами третьих лиц, не являющимися
          Пользователями Программного продукта, но чьи права и интересы могут быть затронуты в результате действий Пользователей
          Программного продукта. <br />
          <br />
          2.2 Настоящее Соглашение является юридически обязательным соглашением между Пользователем и Лицензиаром, предметом которого
          является предоставление Лицензиаром Пользователю права использования на условиях простой (неисключительной) лицензии Программного
          продукта в соответствии с функциональными возможностями, в объеме, определенном Лицензиаром, а также оказания иных сопутствующих
          услуг и предоставления иных сервисов. <br />
          <p className='padding'>
            2.2.1. Способы использования: <br />
            Использование SaaS-версии Программного продукта в соответствии с функциональным предназначением; Предоставление любым третьим
            лицам, входящим с Пользователем в одну группу лиц по смыслу ст. 9 Федерального закона от 26.07.2006 N 135-ФЗ «О защите
            конкуренции» право использования Программного продукта на условиях, предусмотренных Соглашением.
            <br />
            <br />
          </p>
          2.3 Пользователь обязан полностью ознакомиться с настоящим Соглашением до момента регистрации в Личном кабинете. Регистрация
          Пользователя означает полное и безоговорочное принятие Пользователем настоящего Соглашения в соответствии со ст. 438 Гражданского
          кодекса Российской Федерации. <br />
          <br />
          2.4 Настоящее Соглашение может быть изменено и (или) дополнено Лицензиаром в одностороннем порядке без какого-либо специального
          уведомления. Настоящее Соглашение являются открытым и общедоступным документом. <br />
          <br />
          2.5 Пользователь обязан регулярно проверять условия настоящего Соглашения на предмет их изменения и (или) дополнения. Продолжение
          использования Программного продукта Пользователем после внесения изменений и (или) дополнений означает принятие и согласие
          Пользователя с такими изменениями и (или) дополнениями. <br />
          <br />
          2.6 Никакие положения настоящего Соглашения не предоставляют Пользователю право на использование фирменного наименования, товарных
          знаков, доменных имен и результатов интеллектуальной деятельности, используемых в Программном продукте, за исключением случаев,
          когда такое использование допускается с письменного согласия Лицензиара.
          <br />
          <br />
          <span className='font-weight'>3. Права и обязанности Пользователя</span>
          <br />
          <br />
          3.1 Пользователь обязан:
          <br />
          <p className='padding'>
            3.1.1 Использовать Программный продукт самостоятельно только в пределах и способами, предусмотренными настоящим Соглашением;
          </p>
          <p className='padding'>
            3.1.2. Соблюдать положения законодательства Российской Федерации, настоящего Соглашения, Лицензионного договора и иных
            документов, размещаемых Лицензиаром в Программном продукте
          </p>
          <p className='padding'>
            3.1.3 Хранить в тайне и не предоставлять другим Пользователям и третьим лицам ставшие ему известными в результате общения с
            другими Пользователями и иного использования Программного продукта персональные данные (включая, но не ограничиваясь, адресами в
            адресной системе Российской Федерации, номерами телефонов, адресами электронной почты и информацию о частной жизни других
            Пользователей и третьих лиц без получения соответствующего предварительного письменного разрешения последних.
          </p>
          <p className='padding'>
            3.1.4 Воздерживаться от:
            <br />
            &emsp;• &emsp;любых модификаций и декомпилирования Программного продукта;
            <br />
            &emsp;• &emsp;воспроизведения Программного продукта, в том числе путем записи в память ЭВМ;
            <br />
            &emsp;• &emsp;распространения (копирования) Программного продукта;
            <br />
            &emsp;• &emsp;осуществления иных действий, направленных на извлечение коммерческой выгоды в отношениях с третьими лицами,
            возникшей из использования Программного продукта;
            <br />
            &emsp;• &emsp;предоставления сублицензий на использование Программного продукта;
            <br />
            &emsp;• &emsp;обнародования Программного продукта;
            <br />
            &emsp;• &emsp;внесения каких-либо изменений в объектный код Программного продукта за исключением тех, которые описаны в
            документации на Программный продукт;
            <br />
            &emsp;• &emsp;совершения любых иных действий в отношении Программного продукта, нарушающих российские и/или международные нормы
            законодательства.
          </p>
          <br />
          <br />
          3.2. Пользователь вправе:
          <br />
          <p className='padding'>
            3.2.1. Осуществлять любые действия, связанные с функционированием Программного продукта и его использованием в соответствии с
            его назначением.
          </p>
          <br />
          <span className='font-weight'>4. Порядок предоставления права использования Программного продукта</span>
          <br />
          <br />
          4.1. Для доступа к Программному продукту и получения права его использования, Пользователь обязуется пройти процедуру регистрации
          на сайте Программного продукта путем заполнения регистрационной формы. В процессе заполнения регистрационной формы, Пользователь
          самостоятельно выбирает имя и фамилию, адрес электронной почты, логин (никнейм), название компании, пароль (далее - учетные
          данные).
          <br />
          <br />
          4.2. Лицензиар не проверяет предоставленные Пользователем учетные данные на предмет их достоверности (за исключением случаев,
          предусмотренных настоящим Соглашением или дополнениями к нему) и не несет ответственность за их точность, полноту и достоверность.
          Предоставляя учетные данные, Пользователь выражает свое согласие Лицензиару на их сбор, систематизацию, накопление, хранение,
          использование, уничтожение, обработку и отображение в профиле Программного продукта при условии надлежащего обеспечения режима их
          хранения и обработки. Обработка учетных данных осуществляется в срок с момента регистрации Пользователя и до момента удаления его
          учетной записи.
          <br />
          <br />
          4.3. После заполнения всех необходимых учетных данных в регистрационной форме и нажатия кнопки «Продолжить» Пользователь завершает
          регистрацию и получает право доступа к Программному продукту и к Личному кабинету.
          <br />
          <br />
          4.4. После завершения регистрации Пользователь получает право использования Программного продукта в соответствии с выбранным
          тарифом.
          <br />
          <br />
          4.5. Лицензиар сохраняет за собой право в любое время изменять оформление Программного продукта, его содержание, список сервисов,
          изменять или дополнять используемые скрипты, программное обеспечение и другие объекты, используемые или хранящиеся в Программном
          продукте, любые серверные приложения в любое время с предварительным уведомлением или без такового.
          <br />
          <br />
          <span className='font-weight'>5. Уступка (передача) прав</span>
          <br />
          <br />
          5.1 Лицензиат, имеет право однократно уступить полностью свои права и обязанности по настоящему Договору другому конечному
          пользователю с активированной учетной записью в аккаунте Лицензиата.
          <br />
          <br />
          5.2 Уступка (передача) прав и обязанностей осуществляется только при условии полного и безоговорочного согласия нового Лицензиата
          со всеми положениями и условиями настоящего Договора.
          <br />
          <br />
          5.3. Уступка (передача) прав по настоящему Соглашению не может быть осуществлена косвенно или через какое-либо третье лицо.
          <br />
          <br />
          <span className='font-weight'>6. Условия об интеллектуальных правах</span>
          <br />
          <br />
          6.1. Исключительные права на результаты интеллектуальной деятельности (далее - РИД), используемые в Программном продукте.
          <br />
          <br />
          6.2. Все результаты интеллектуальной деятельности (далее - РИД), размещенные и используемые в Программном продукте, в том числе
          элементы дизайна, текст, графические изображения, иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты и их
          подборки (далее - Контент), являются объектами исключительных прав Лицензиара и других правообладателей, все права на эти объекты
          охраняются действующим законодательством Российской Федерации и международным законодательством.
          <br />
          <br />
          6.3. Кроме случаев, установленных настоящим Соглашением, а также действующим законодательством Российской Федерации, никакой
          Контент не может быть скопирован (воспроизведен), переработан, распространен, отображен во фрейме, опубликован, скачан, передан,
          продан или иным способом использован целиком или по частям без предварительного разрешения правообладателя.
          <br />
          <br />
          <span className='font-weight'>7. Ответственность</span>
          <br />
          <br />
          7.1. В случае ненадлежащего исполнения обязательств по настоящему Соглашению Стороны несут ответственность в соответствии с
          действующим законодательством РФ.
          <br />
          <br />
          7.2. Ни одна из сторон не несет ответственности перед другой стороной за задержку или невыполнение обязательств, обусловленные
          обстоятельствами, возникшими помимо воли и желания сторон и которые нельзя ни предвидеть или ни предотвратить, включая объявленную
          или фактическую войну, гражданские волнения, эпидемии, блокаду, эмбарго, землетрясения, наводнения, пожары и другие стихийные
          бедствия.
          <br />
          <br />
          7.3. Лицензиар не несет ответственность за прямые или косвенные убытки, включая упущенную выгоду, иной ущерб или вред, перерывы в
          хозяйственной деятельности, потерю деловой информации и т.п., которые могут возникнуть у Пользователя в результате использования
          Программного продукта.
          <br />
          <br />
          7.4. Пользователь использует предоставленные права использования программного продукта на собственный риск. Лицензиар не принимает
          на себя ответственность за соответствие функциональности Программного продукта цели его использования, за любой ущерб, понесенный
          Пользователем ввиду утери им своих данных, необходимых для доступа к Программному продукту, а также за качество предоставляемых
          третьими лицами сервисов, необходимых для работы с Программным продуктом. Пользователь самостоятельно несет ответственность за
          сохранность и безопасность своего пароля.
          <br />
          <br />
          7.5. В случае утери данных Пользователя по его вине, Лицензиар при наличии технической возможности восстанавливает данные
          Пользователя на возмездной основе.
          <br />
          <br />
          <span className='font-weight'>8. Конфиденциальность</span>
          <br />
          <br />
          8.1. Пользователь выражает своё согласие на обработку следующих категорий персональных данных: фамилия, имя, отчество, адрес
          электронной почты, контактные телефоны.
          <br />
          <br />
          8.2. Обработка персональных данных может осуществляться с использованием средств автоматизации или без таковых, включая сбор,
          запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
          (предоставление, доступ), блокирование, обезличивание, удаление, уничтожение персональных данных и совершение иных действий,
          предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».
          <br />
          <br />
          8.3. Целями обработки персональных данных являются: <br />
          <br />
          Идентификации Пользователя, регистрация на Сайте и в Программном продукте. <br />
          <br />
          Использование Программного продукта и Сайта в соответствии с их назначением, заключение и исполнение договора. <br />
          <br />
          Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования сайта, обработка
          запросов и заявок от Пользователя. <br />
          <br />
          Обработки и получения платежей, оспаривания платежа. В целях дополнительной защиты от мошеннических действий указанные
          Пользователем персональные данные могут быть переданы платёжной системе, осуществляющей транзакции. <br />
          <br />
          Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием
          Сайта и Программного продукта. <br />
          <br />
          Предоставления Пользователю обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений
          от имени Администрации сайта. <br />
          <br />
          Осуществления рекламной деятельности. <br />
          <br />
          Исполнение требований законодательства Российской Федерации.
          <br />
          <br />
          8.4. Полученное согласие действует со дня присоединения к настоящему Соглашению и до достижения целей обработки персональных
          данных, если иное не предусмотрено законодательством Российской Федерации, и досрочно может быть отозвано Пользователем путем
          направления ООО «ГК «Белый Медведь» уведомления по адресу электронной почты: support@telebon.ru
          <br />
          <br />
          8.5 Лицензиар вправе агрегировать, систематизировать и анализировать информацию Лицензиата, в том числе конфиденциальную, с целью
          создания информационно-аналитических отчетов и баз данных, при этом Лицензиар гарантирует нераспространение и сохранность
          конфиденциальной информации, содержащейся в отчетах и базах в соответствии с настоящим Договором и действующим законодательством
          Российской Федерации.
          <br />
          <br />
          8.6. Условия обработки персональных данных, определенные в пунктах 7.1.-7.5. настоящего Соглашения не распространяются на случай,
          когда установка Программного продукта осуществляется на техническом средстве (ЭВМ) Лицензиата. В данном случае Лицензиат
          самостоятельно несет ответственность за сбор, обработку и хранение персональных данных. В случае претензий со стороны третьих лиц
          и/или государственных органов к Лицензиару, Лицензиат обязуется компенсировать любые штрафы и/или ущерб от таких претензий.
          <br />
          <br />
          <span className='font-weight'>9. Заключительные положения</span>
          <br />
          <br />
          9.1. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не
          урегулированные Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации.
          <br />
          <br />
          9.2. В случае возникновения любых споров или разногласий, связанных с исполнением настоящего Соглашения, Пользователь и Лицензиар
          приложат все усилия для их разрешения путем проведения переговоров между ними. В случае, если споры не будут разрешены путем
          переговоров, споры подлежат разрешению в порядке, установленном действующим законодательством Российской Федерации по месту
          нахождения Лицензиара.
          <br />
          <br />
          9.3. Настоящее Соглашение вступает в силу для Пользователя с момента его присоединения к ним и действуют в течение неопределенного
          срока.
          <br />
          <br />
          Редакция 2023.12.10 от «10» Декабря 2023
          <br />
        </p>
      </PortalTextWrapper>
    </PersonalAreaWrapper>
  );
};

export default AgreementPage;
