export function PhoneSignalWifiBatery() {
  return (
    <svg
      width='54'
      height='10'
      viewBox='0 0 54 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13 0.9375C13 0.419733 12.6767 0 12.2778 0H11.5556C11.1567 0 10.8333 0.419733 10.8333 0.9375V9.0625C10.8333 9.58027 11.1567 10 11.5556 10H12.2778C12.6767 10 13 9.58027 13 9.0625V0.9375ZM7.96648 2H8.68871C9.08758 2 9.41093 2.42981 9.41093 2.96V9.04C9.41093 9.57019 9.08758 10 8.68871 10H7.96648C7.56761 10 7.24426 9.57019 7.24426 9.04V2.96C7.24426 2.42981 7.56761 2 7.96648 2ZM5.03351 4.16666H4.31129C3.91242 4.16666 3.58907 4.60194 3.58907 5.13888V9.02777C3.58907 9.56471 3.91242 9.99999 4.31129 9.99999H5.03351C5.43239 9.99999 5.75574 9.56471 5.75574 9.02777V5.13888C5.75574 4.60194 5.43239 4.16666 5.03351 4.16666ZM1.44444 6.16667H0.722222C0.32335 6.16667 0 6.59573 0 7.125V9.04167C0 9.57094 0.32335 10 0.722222 10H1.44444C1.84332 10 2.16667 9.57094 2.16667 9.04167V7.125C2.16667 6.59573 1.84332 6.16667 1.44444 6.16667Z'
        fill='#09101C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.5004 2.0005C25.3865 2.00059 27.2006 2.74853 28.5676 4.08973C28.6705 4.19328 28.8351 4.19197 28.9365 4.08681L29.9205 3.06196C29.9718 3.00861 30.0004 2.93636 30 2.86118C29.9996 2.78601 29.9701 2.7141 29.9182 2.66139C26.3303 -0.887128 20.6699 -0.887128 17.0819 2.66139C17.03 2.71406 17.0005 2.78595 17 2.86112C16.9995 2.9363 17.0281 3.00858 17.0794 3.06196L18.0637 4.08681C18.165 4.19213 18.3297 4.19344 18.4325 4.08973C19.7997 2.74844 21.614 2.0005 23.5004 2.0005ZM23.4978 5.42374C24.5272 5.42368 25.5198 5.83877 26.2828 6.58837C26.3861 6.69475 26.5486 6.69245 26.6492 6.58317L27.6255 5.51302C27.6769 5.45689 27.7054 5.38074 27.7047 5.30162C27.7039 5.22249 27.674 5.14699 27.6215 5.092C25.298 2.74711 21.6996 2.74711 19.3761 5.092C19.3236 5.14698 19.2936 5.22253 19.2929 5.30168C19.2923 5.38083 19.3209 5.45697 19.3724 5.51302L20.3484 6.58317C20.449 6.69245 20.6115 6.69475 20.7147 6.58837C21.4773 5.83926 22.4691 5.42421 23.4978 5.42374ZM25.4123 7.68971C25.4138 7.77516 25.3857 7.85755 25.3346 7.91742L23.6839 9.90855C23.6355 9.96707 23.5695 10 23.5007 10C23.4318 10 23.3658 9.96707 23.3175 9.90855L21.6664 7.91742C21.6154 7.85751 21.5874 7.77509 21.5889 7.68964C21.5904 7.60418 21.6213 7.52326 21.6744 7.46598C22.7286 6.40023 24.2727 6.40023 25.3269 7.46598C25.3799 7.52331 25.4108 7.60426 25.4123 7.68971Z'
        fill='#09101C'
      />
      <rect
        opacity='0.35'
        x='34.5'
        y='0.5'
        width='17.2962'
        height='9'
        rx='3.8'
        stroke='#09101C'
      />
      <path
        opacity='0.4'
        d='M53.0273 3.67969V6.81467C53.6163 6.54915 53.9993 5.93149 53.9993 5.24718C53.9993 4.56286 53.6163 3.9452 53.0273 3.67969Z'
        fill='#09101C'
      />
      <rect
        x='35.4629'
        y='1.53906'
        width='15.3688'
        height='6.92308'
        rx='2.5'
        fill='#09101C'
      />
    </svg>
  );
}
