import { IPaymentCreate, IPaymentUpdate } from '../../../../store/redux/payments/payments.interface';
import { apiGet, apiPut } from '../../../../utils/apiInstance';
import { apiDelete, apiPost } from '../../interceptors';

export const PaymentsService = {
  async fetchPayments() {
    const response = await apiGet('/payments');
    return response.data && response.data.payforms ? response.data.payforms : [];
  },
  async createPayment(data: IPaymentCreate) {
    const response = await apiPut('/payments', data);

    return response.data;
  },
  async updatePayment(data: IPaymentUpdate) {
    const { id, ...values } = data;
    const response = await apiPost(`/payments/${id}`, values);

    return response.data;
  },
  async deletePayment(id: string | undefined) {
    if (!id) return;
    const response = await apiDelete(`/payments/${id}`);

    return response.data;
  }
};
