export const PaymentIcon20 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24744)'>
        <path
          d='M20.7501 12.225C23.9601 14.505 28.1451 18.825 28.1451 23.775C28.1451 28.725 23.8251 32.25 18.5001 32.25C13.1751 32.25 8.87012 28.785 8.87012 23.775C8.87012 18.765 13.0401 14.505 16.2501 12.225L12.8451 7.5C12.6922 7.18209 12.6432 6.82409 12.7051 6.47678C12.767 6.12947 12.9367 5.81046 13.1901 5.565C13.4305 5.31463 13.7502 5.15536 14.0949 5.11434C14.4395 5.07331 14.7877 5.15306 15.0801 5.34C15.3632 5.50148 15.696 5.55251 16.0145 5.48328C16.333 5.41404 16.6146 5.22944 16.8051 4.965L17.1351 4.515C17.2835 4.28631 17.4853 4.09716 17.7231 3.96388C17.9609 3.8306 18.2276 3.7572 18.5001 3.75C18.7694 3.75055 19.0348 3.81469 19.2746 3.9372C19.5144 4.05971 19.7219 4.23713 19.8801 4.455L20.2101 4.905C20.4006 5.16944 20.6822 5.35404 21.0007 5.42328C21.3192 5.49251 21.652 5.44148 21.9351 5.28C22.2275 5.09306 22.5757 5.01331 22.9204 5.05434C23.265 5.09536 23.5848 5.25463 23.8251 5.505C24.0936 5.75381 24.2718 6.08486 24.3315 6.44602C24.3913 6.80719 24.3292 7.17797 24.1551 7.5L20.7501 12.225Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.25 12H23.75'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.25 29.9997C5.6587 29.9997 4.13258 29.3675 3.00736 28.2423C1.88214 27.1171 1.25 25.591 1.25 23.9997C1.31527 22.183 1.82875 20.4106 2.74473 18.8403C3.6607 17.2701 4.95075 15.9507 6.5 14.9997L4.64 11.7447C4.53328 11.5765 4.47662 11.3814 4.47662 11.1822C4.47662 10.983 4.53328 10.7879 4.64 10.6197C4.76032 10.4569 4.92397 10.3312 5.11227 10.2569C5.30058 10.1827 5.50596 10.1628 5.705 10.1997C6.69508 10.3989 7.71492 10.3989 8.705 10.1997C8.90404 10.1628 9.10942 10.1827 9.29773 10.2569C9.48603 10.3312 9.64968 10.4569 9.77 10.6197C9.87671 10.7879 9.93338 10.983 9.93338 11.1822C9.93338 11.3814 9.87671 11.5765 9.77 11.7447L8 14.9997'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.25 15H8'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.7498 29.9997C31.3411 29.9997 32.8672 29.3675 33.9924 28.2423C35.1176 27.1171 35.7498 25.591 35.7498 23.9997C35.6845 22.183 35.171 20.4106 34.2551 18.8403C33.3391 17.2701 32.049 15.9507 30.4998 14.9997L32.3598 11.7447C32.4665 11.5765 32.5232 11.3814 32.5232 11.1822C32.5232 10.983 32.4665 10.7879 32.3598 10.6197C32.2395 10.4569 32.0758 10.3312 31.8875 10.2569C31.6992 10.1827 31.4938 10.1628 31.2948 10.1997C30.3047 10.3989 29.2849 10.3989 28.2948 10.1997C28.0957 10.1628 27.8904 10.1827 27.7021 10.2569C27.5138 10.3312 27.3501 10.4569 27.2298 10.6197C27.1231 10.7879 27.0664 10.983 27.0664 11.1822C27.0664 11.3814 27.1231 11.5765 27.2298 11.7447L28.9998 14.9997'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M32.75 15H29'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.5 26.25V27.75'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.5 18.75V20.25'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.5 26.25H20C20.3978 26.25 20.7794 26.092 21.0607 25.8107C21.342 25.5294 21.5 25.1478 21.5 24.75C21.5 24.3522 21.342 23.9706 21.0607 23.6893C20.7794 23.408 20.3978 23.25 20 23.25H17C16.6022 23.25 16.2206 23.092 15.9393 22.8107C15.658 22.5294 15.5 22.1478 15.5 21.75C15.5 21.3522 15.658 20.9706 15.9393 20.6893C16.2206 20.408 16.6022 20.25 17 20.25H21.5'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24744'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
