import React from 'react';

export function SmallCalendar() {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.83333 1C5.10948 1 5.33333 1.22386 5.33333 1.5V2.33333H8.66667V1.5C8.66667 1.22386 8.89052 1 9.16667 1C9.44281 1 9.66667 1.22386 9.66667 1.5V2.33333H11.1667C12.1792 2.33333 13 3.15414 13 4.16667V11.1667C13 12.1792 12.1792 13 11.1667 13H2.83333C1.82081 13 1 12.1792 1 11.1667V4.16667C1 3.15414 1.82081 2.33333 2.83333 2.33333H4.33333V1.5C4.33333 1.22386 4.55719 1 4.83333 1ZM4.33333 3.33333H2.83333C2.3731 3.33333 2 3.70643 2 4.16667V5.66667H12V4.16667C12 3.70643 11.6269 3.33333 11.1667 3.33333H9.66667V3.83333C9.66667 4.10948 9.44281 4.33333 9.16667 4.33333C8.89052 4.33333 8.66667 4.10948 8.66667 3.83333V3.33333H5.33333V3.83333C5.33333 4.10948 5.10948 4.33333 4.83333 4.33333C4.55719 4.33333 4.33333 4.10948 4.33333 3.83333V3.33333ZM12 6.66667H2V11.1667C2 11.6269 2.3731 12 2.83333 12H11.1667C11.6269 12 12 11.6269 12 11.1667V6.66667Z'
        fill='#5089FD'
        stroke='#5089FD'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
