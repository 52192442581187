import React from 'react';

export function SidebarSchedule() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 12.479C8.55228 12.479 9 12.0322 9 11.481C9 10.9299 8.55228 10.483 8 10.483C7.44772 10.483 7 10.9299 7 11.481C7 12.0322 7.44772 12.479 8 12.479Z'
        fill='#467FF3'
      />
      <path
        d='M16 12.479C16.5523 12.479 17 12.0322 17 11.481C17 10.9299 16.5523 10.483 16 10.483C15.4477 10.483 15 10.9299 15 11.481C15 12.0322 15.4477 12.479 16 12.479Z'
        fill='#467FF3'
      />
      <path
        d='M12 12.479C12.5523 12.479 13 12.0322 13 11.481C13 10.9299 12.5523 10.483 12 10.483C11.4477 10.483 11 10.9299 11 11.481C11 12.0322 11.4477 12.479 12 12.479Z'
        fill='#467FF3'
      />
      <path
        d='M8 16.4711C8.55228 16.4711 9 16.0242 9 15.4731C9 14.9219 8.55228 14.4751 8 14.4751C7.44772 14.4751 7 14.9219 7 15.4731C7 16.0242 7.44772 16.4711 8 16.4711Z'
        fill='#467FF3'
      />
      <path
        d='M12 16.4711C12.5523 16.4711 13 16.0242 13 15.4731C13 14.9219 12.5523 14.4751 12 14.4751C11.4477 14.4751 11 14.9219 11 15.4731C11 16.0242 11.4477 16.4711 12 16.4711Z'
        fill='#467FF3'
      />
      <path
        d='M21.9895 16.7007C21.9895 16.8105 21.9895 16.9203 21.9895 17.0301C21.9473 17.2072 21.8574 17.3694 21.7295 17.4991L18.3895 20.8325C18.2032 21.0168 17.9518 21.1208 17.6895 21.1219C17.5579 21.1227 17.4274 21.0975 17.3056 21.0478C17.1837 20.9981 17.0729 20.925 16.9795 20.8325L15.3095 19.1658C15.1685 19.0262 15.0723 18.8479 15.0332 18.6535C14.9941 18.4592 15.0139 18.2576 15.0901 18.0745C15.1663 17.8914 15.2954 17.7351 15.4609 17.6256C15.6264 17.516 15.8209 17.4581 16.0195 17.4592C16.1518 17.4598 16.2827 17.4865 16.4046 17.5379C16.5264 17.5893 16.6369 17.6643 16.7295 17.7586L17.7295 18.7566L20.3495 16.1319C20.4429 16.0394 20.5537 15.9662 20.6756 15.9165C20.7974 15.8669 20.9279 15.8417 21.0595 15.8425C21.1911 15.8417 21.3216 15.8669 21.4434 15.9165C21.5652 15.9662 21.676 16.0394 21.7695 16.1319C21.9042 16.2918 21.9817 16.492 21.9895 16.7007Z'
        fill='#467FF3'
      />
      <path
        opacity='0.5'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22 9.48499V14.2671C22 14.4882 21.7662 14.6361 21.5516 14.5831C21.2468 14.5078 20.9281 14.4948 20.6151 14.5471C20.1441 14.6257 19.7091 14.8481 19.37 15.1836L17.66 16.8902C17.4494 16.6744 17.1974 16.5032 16.9191 16.3866C16.6408 16.27 16.3418 16.2105 16.04 16.2116C15.5862 16.2148 15.1433 16.3515 14.7668 16.6044C14.3903 16.8573 14.0968 17.2153 13.9229 17.6337C13.749 18.0521 13.7025 18.5123 13.7891 18.9569C13.8758 19.4015 14.0917 19.8108 14.41 20.1337C14.41 20.1337 15.1118 20.8303 15.663 21.3727C15.8686 21.575 15.697 22 15.4086 22H7.5C6.04131 22 4.64237 21.4217 3.61092 20.3923C2.57947 19.3629 2 17.9668 2 16.511V9.52491C1.99952 8.15611 2.51244 6.8367 3.4378 5.82638C4.36316 4.81606 5.63393 4.188 7 4.06583V4.99398C7 5.25866 7.10536 5.51251 7.2929 5.69967C7.48043 5.88683 7.73478 5.99198 8 5.99198C8.26522 5.99198 8.51957 5.88683 8.70711 5.69967C8.89464 5.51251 9 5.25866 9 4.99398V3.99597H15V4.99398C15 5.25866 15.1054 5.51251 15.2929 5.69967C15.4804 5.88683 15.7348 5.99198 16 5.99198C16.2652 5.99198 16.5196 5.88683 16.7071 5.69967C16.8946 5.51251 17 5.25866 17 4.99398V3.99597C18.3712 4.11858 19.6463 4.75089 20.5724 5.76755C21.4985 6.78422 22.008 8.11103 22 9.48499Z'
        fill='#467FF3'
      />
      <path
        d='M9 2.998V3.99601H7.5C7.33349 3.98578 7.16651 3.98578 7 3.99601V2.998C7 2.73332 7.10536 2.47947 7.2929 2.29231C7.48043 2.10515 7.73478 2 8 2C8.26522 2 8.51957 2.10515 8.70711 2.29231C8.89464 2.47947 9 2.73332 9 2.998Z'
        fill='#467FF3'
      />
      <path
        d='M17 2.998V3.99601C16.8335 3.98578 16.6665 3.98578 16.5 3.99601H15V2.998C15 2.73332 15.1054 2.47947 15.2929 2.29231C15.4804 2.10515 15.7348 2 16 2C16.2652 2 16.5196 2.10515 16.7071 2.29231C16.8946 2.47947 17 2.73332 17 2.998Z'
        fill='#467FF3'
      />
    </svg>
  );
}
