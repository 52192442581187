import { useQuery } from '@tanstack/react-query';
import { TasksService } from '../../../../shared/api/model/service/tasks.service';
import { useEffect, useState } from 'react';
import { IColumn } from '../../../../store/redux/tasks/tasks.interface';

export function useTasks() {
  const { data, refetch } = useQuery({
    queryKey: ['tasks'],
    queryFn: () => TasksService.fetchTasks()
  });

  const [items, setItems] = useState<IColumn[]>(data);

  useEffect(() => {
    setItems(data);
  }, [data]);

  return { items, setItems, refetch };
}
