import { useEffect, useRef, useState } from 'react';

import { IToolTipProps, PositionType } from './Tooltip.props';
import { ToolTipIcon, ToolTipText, TooltipWrapper } from './Tooltip.styled';

import { cn } from '../../../lib';

const Tooltip: React.FC<IToolTipProps> = (props) => {
  const refTooltip = useRef<HTMLDivElement>(null);
  const refTextTooltip = useRef<HTMLSpanElement>(null);
  const { text, icon, className, $color, $position, classNameWrapper } = props;
  const [isHover, setIsHover] = useState<boolean>(false);

  useEffect(() => {
    if (refTextTooltip && refTextTooltip?.current) {
      const tooltipTextCoords = refTextTooltip?.current.getBoundingClientRect();
      const parentCoordsObj = refTextTooltip.current.closest('.services-by-employee-wrap')?.getBoundingClientRect();

      if (tooltipTextCoords && parentCoordsObj) {
        const { top: textTop, right: textRight, bottom: textBottom, left: textLeft } = tooltipTextCoords;
        const { top: parentTop, right: parentRight, bottom: parentBottom, left: parentLeft } = parentCoordsObj;
        if (textRight > parentRight) {
          refTextTooltip?.current.classList.add('right');
        }
      }
    }
  }, [refTextTooltip.current, isHover]);

  return (
    <TooltipWrapper
      $color={$color ? $color : '#1B1F3B66'}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      ref={refTooltip}
      className={cn(classNameWrapper && `${classNameWrapper}`)}
    >
      <ToolTipIcon className='flex'>{icon}</ToolTipIcon>
      {isHover && (
        <ToolTipText
          ref={refTextTooltip}
          className={className}
          $position={$position ? $position : PositionType.TOP}
        >
          {text}
        </ToolTipText>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;
