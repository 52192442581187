import React from 'react';

export function CrossShieldOutline() {
  return (
    <svg
      width='17'
      height='20'
      viewBox='0 0 17 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.1973 0.15253C8.39305 0.0826202 8.60696 0.0826202 8.80271 0.15253L15.8027 2.65253C16.1609 2.78046 16.4 3.11975 16.4 3.5001V11.0001C16.4 13.8508 14.8318 16.0769 13.1571 17.5507C12.3144 18.2923 11.4201 18.867 10.6256 19.2599C9.86815 19.6346 9.08928 19.9001 8.50001 19.9001C7.91073 19.9001 7.13186 19.6346 6.37442 19.2599C5.57995 18.867 4.6856 18.2923 3.84294 17.5507C2.16816 16.0769 0.600006 13.8508 0.600006 11.0001V3.5001C0.600006 3.11975 0.83911 2.78046 1.1973 2.65253L8.1973 0.15253ZM2.40001 4.13435V11.0001C2.40001 13.1494 3.58185 14.9233 5.03207 16.1995C5.75191 16.8329 6.51381 17.3207 7.17246 17.6465C7.86815 17.9906 8.33928 18.1001 8.50001 18.1001C8.66073 18.1001 9.13186 17.9906 9.82755 17.6465C10.4862 17.3207 11.2481 16.8329 11.9679 16.1995C13.4182 14.9233 14.6 13.1494 14.6 11.0001V4.13435L8.50001 1.95577L2.40001 4.13435Z'
        fill='currentColor'
      />
      <path
        d='M6.6364 6.8637C6.28493 6.51223 5.71508 6.51223 5.36361 6.8637C5.01214 7.21517 5.01214 7.78502 5.36361 8.13649L7.22721 10.0001L5.36361 11.8637C5.01214 12.2152 5.01214 12.785 5.36361 13.1365C5.71508 13.488 6.28493 13.488 6.6364 13.1365L8.50001 11.2729L10.3636 13.1365C10.7151 13.488 11.2849 13.488 11.6364 13.1365C11.9879 12.785 11.9879 12.2152 11.6364 11.8637L9.7728 10.0001L11.6364 8.13649C11.9879 7.78502 11.9879 7.21517 11.6364 6.8637C11.2849 6.51223 10.7151 6.51223 10.3636 6.8637L8.50001 8.72731L6.6364 6.8637Z'
        fill='currentColor'
      />
    </svg>
  );
}
