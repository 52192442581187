import styled from 'styled-components';
import { scaleIn } from './sidebar/CreateServiceSidebar.styled';
import { checkAnim } from '../../../../../utils/styleUtils';

export const Wrapper = styled.div`
  width: 100%;
  form {
    margin-top: 26px;
  }
  .checked {
    align-items: center;
    p {
      font-size: 16px;
      color: rgba(108, 110, 124, 1);
    }
    .checkbox-container {
      display: inline-block;
      position: relative;
      padding-left: 26px;
      margin-bottom: 19px;
      cursor: pointer;
      font-size: 16px;
      user-select: none;
      .custom-checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &.green:checked ~ .checkmark {
          background-color: ${(props) => props.theme.color.primary};
        }
        &:checked ~ .checkmark {
          background-color: ${(props) => props.theme.color.primary};
        }
        &:checked ~ .checkmark:after {
          display: block;
        }
        &:checked ~ .checkmark:after {
          animation: ${checkAnim} 0.2s forwards;
        }
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #eee;
        border-radius: 4px;
        transition: background-color 0.3s;
        &:after {
          content: '';
          position: absolute;
          display: none;
          left: 7px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
  .subproductCalculate {
    animation: ${scaleIn} 0.3s ease;
    span {
      color: ${(props) => props.theme.color.secondaryDark};
    }
  }
`;

export const Box = styled.div`
  width: 100%;

  &.form {
    display: flex;
    flex-direction: column;
    gap: 28px;
    button {
      width: fit-content;
      align-self: flex-end;
    }
  }
`;

export const FormItem = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  & > div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  label {
    width: 100%;
    border: 1px solid #ecf1f7;
    height: 50px;
    border-radius: 10px;
    padding: 11px 15px;
    display: flex;
    justify-content: space-between;
  }
  &.head {
    label {
      display: none;
    }
    input {
      color: #292f51;
      font-size: 32px;
      font-weight: 700;
      line-height: normal;
      border: none;
      &.active {
        border: none;
      }
      &::placeholder {
        opacity: 0.5;
      }
    }
  }
`;

export const ButtonInner = styled.div`
  display: flex;
  gap: 12px;
`;

export const ChosenItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  border: 1px solid ${(props) => props.theme.color.secondaryDark};
  border-radius: 8px;
  .employees {
    width: fit-content;
    min-width: 100px;
  }
  .delete-btn {
    display: flex;
    color: ${(props) => props.theme.color.secondaryDark};
    svg {
      width: 14px;
      height: 14px;
    }
    &:hover,
    &:focus {
      svg {
        color: ${(props) => props.theme.color.danger};
      }
    }
  }
`;

export const ChosenItemLabel = styled.span`
  padding: 2px;
  position: absolute;
  top: -10px;
  left: 10px;
  display: inline-block;
  background-color: ${(props) => props.theme.color.base01};
  font-size: 12px;
`;

export const ChosenSearchWrap = styled.div`
  position: relative;
`;
export const ChosenItemInput = styled.input`
  padding: 4px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  border-radius: 8px;
  height: 30px;
  &.default {
    border-color: ${(props) => props.theme.color.secondaryDark};
  }
  &:hover {
    border-color: ${(props) => props.theme.color.secondaryDark};
  }
  &:focus,
  &.active {
    outline: none;
    border-color: ${(props) => props.theme.color.secondaryDark};
    border-radius: 8px 8px 0 0;
  }
`;

export const SearchResultWrap = styled.ul`
  padding: 10px 0;
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  dispaly: flex;
  flex-direction: column;
  gap: 4px;
  background-color: ${(props) => props.theme.color.base01};
  border: 1px solid ${(props) => props.theme.color.secondaryDark};
  border-radius: 0 0 8px 8px;
  z-index: 10;
`;

export const EmptySearchResult = styled.div`
  padding: 10px 4px;
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.color.base01};
  border: 1px solid ${(props) => props.theme.color.secondaryDark};
  border-radius: 0 0 8px 8px;

  z-index: 10;
`;
export const SearchResultItem = styled.li`
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.color.secondaryLightMedium};
  }
`;
export const ChosenEmployeeList = styled.ul`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
`;

export const ChosenEmployeeItem = styled.li`
  display: flex;
  padding: 4px;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.color.secondaryDark};
  border-radius: 8px;
`;

export const ChosenCabinetsList = styled.ul`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
`;

export const ChosenCabinetsItem = styled.li`
  display: flex;
  padding: 4px;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${(props) => props.theme.color.secondaryDark};
  border-radius: 8px;
`;
