import React from 'react';

export function ChatsIcon() {
  return (
    <svg
      width='29'
      height='29'
      viewBox='0 0 29 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.60938 24.2468C8.11035 24.2468 8.5498 24.0359 9.15625 23.5085L11.9775 20.9949C12.6279 21.6716 13.6035 22.032 14.8252 22.032H17.8311L20.7578 24.5457C21.3379 25.0378 21.751 25.3103 22.2256 25.3103C22.9287 25.3103 23.3418 24.8093 23.3418 24.0447V22.032H23.7461C25.9609 22.032 27.4375 20.6785 27.4375 18.4021V13.032C27.4375 10.7205 25.9346 9.33179 23.6758 9.33179H22.1465V8.7605C22.1465 6.36987 20.6787 4.95483 18.2705 4.95483H5.43848C3.10059 4.95483 1.5625 6.36987 1.5625 8.7605V16.9871C1.5625 19.3425 3.07422 20.74 5.36816 20.74H6.47559V22.99C6.47559 23.7634 6.89746 24.2468 7.60938 24.2468ZM8.04883 19.8787C8.04883 19.3513 7.72363 19.0789 7.28418 19.0789H5.64941C4.19043 19.0789 3.31152 18.2615 3.31152 16.741V8.95386C3.31152 7.43335 4.19922 6.60718 5.64941 6.60718H18.0508C19.5098 6.60718 20.3887 7.43335 20.3887 8.95386V9.33179H14.8252C12.4873 9.33179 11.0635 10.7205 11.0635 13.032V18.4021C11.0635 18.7712 11.0986 19.1052 11.1689 19.4216L8.04883 22.3396V19.8787ZM21.7773 23.4031L19.0264 20.907C18.6396 20.5554 18.3145 20.3972 17.7959 20.3972H15.0098C13.6299 20.3972 12.7949 19.6326 12.7949 18.1736V13.199C12.7949 11.7576 13.6299 10.9753 15.0098 10.9753H23.4824C24.8711 10.9753 25.7061 11.7576 25.7061 13.199V18.1736C25.7061 19.6238 24.8623 20.3972 23.4824 20.3972H22.5332C22.1025 20.3972 21.7773 20.6609 21.7773 21.1882V23.4031Z'
        fill='currentColor'
      />
    </svg>
  );
}
