export function DownloadOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2C12.4971 2 12.9 2.40294 12.9 2.9V13.8272L17.4636 9.26361C17.8151 8.91214 18.3849 8.91214 18.7364 9.26361C19.0879 9.61508 19.0879 10.1849 18.7364 10.5364L12.6364 16.6364C12.2849 16.9879 11.7151 16.9879 11.3636 16.6364L5.26361 10.5364C4.91214 10.1849 4.91214 9.61508 5.26361 9.26361C5.61508 8.91214 6.18493 8.91214 6.5364 9.26361L11.1 13.8272V2.9C11.1 2.40294 11.5029 2 12 2ZM4 20C4 19.5029 4.40294 19.1 4.9 19.1H19.1C19.5971 19.1 20 19.5029 20 20C20 20.4971 19.5971 20.9 19.1 20.9H4.9C4.40294 20.9 4 20.4971 4 20Z'
        fill='currentColor'
      />
    </svg>
  );
}
