import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NewText from '../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../shared/new-ui/NewText/ui/NewText.props';
import { FlexWithAlign } from '../../../../../utils/styleUtils';
import { EIcon, IconNew } from '../../../../icons/medium-new-icons/icon';
import { CloseBtn, ModalWrapper } from './ClientImportInfoModal.styled';

interface IProps {
  closeModal: () => void;

  closeOnOverlayClick?: boolean;
}
const ClientImportInfoModal: FC<IProps> = (props) => {
  const { closeModal } = props;
  const { t } = useTranslation();

  return (
    <ModalWrapper
      className='import-info'
      $column
      $gap='20px'
    >
      <FlexWithAlign>
        <CloseBtn
          typeBtn='close'
          onClick={closeModal}
        >
          <IconNew name={EIcon.close} />
        </CloseBtn>
        <NewText
          color='white'
          $tag={TextType.H3}
          $customSize={TextCustomType.T28}
          fontWeight={600}
        >
          {t('Как это работает')}
        </NewText>
      </FlexWithAlign>
      <FlexWithAlign
        $column
        $gap='10px'
      >
        <NewText
          color='white'
          $tag={TextType.P}
          fontWeight={600}
          $customSize={TextCustomType.T18}
        >
          {t('Импорт')}
        </NewText>
        <FlexWithAlign
          $column
          $gap='20px'
          style={{ fontSize: '14px', fontWeight: 400 }}
        >
          <>
            <NewText color='white'>{t('Для чего необходимо импортировать клиентскую базу в Telebon?')}</NewText>
            <ul>
              <li>- Автоматизация процесса. Не нужно вносить в систему каждого клиента вручную.</li>
              <li>- Экономия времени. Если у Вас большая база клиентов, то тысяча строк может появится в системе на считанные секунды.</li>
              <li>- Данные о клиенте при переносе не потеряются.</li>
              <li>- Исключается дублирование данных, что возможно при ручном переносе.</li>
            </ul>
            <p>
              Вот как это сделать:<br></br>
              <span>
                При использовании смартфона: перейдите в раздел "Профиль" - "Настройки" - "Клиенты" - "Импорт". Выберите и импортируйте
                контакты, хранящиеся в телефонной книге. При использовании ПК (компьютера или ноутбука): перейдите в раздел "Клиенты" -
                "Загрузить файл".
              </span>
              Вам будет предложено два варианта:
              <ul>
                <li>
                  - Импорт копированием. Скопируйте строки имени, номера телефона и электронной почты Клиента из Вашего Excel-файла.
                  Вставьте скопированные элементы и нажмите "Загрузить". Под каждый столбец выберите его содержание и еще раз нажмите
                  "Загрузить".
                </li>
                <li>
                  - Импорт через загрузку Excel-файла. Вставьте или перенесите Excel-файл с клиентской базой и нажмите "Загрузить". Под
                  каждый столбец выберите его содержание и еще раз нажмите "Загрузить".
                </li>
              </ul>
            </p>
            <p>
              Где взять Excel-файл для загрузки в Telebon?<br></br>
              <ul>
                <li>- Выгрузите файл c клиентской базой из другой CRM-системы, которой пользовались ранее, в формате Excel.</li>
                <li>- Возможно, Вы вели Excel-таблицу клиентской базы самостоятельно.</li>
              </ul>
            </p>
          </>
        </FlexWithAlign>
      </FlexWithAlign>
    </ModalWrapper>
  );
};

export default ClientImportInfoModal;
