import React from 'react';

export function UsersIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 11C11.4853 11 13.5 8.98528 13.5 6.5C13.5 4.01472 11.4853 2 9 2C6.51472 2 4.5 4.01472 4.5 6.5C4.5 8.98528 6.51472 11 9 11Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M19.9996 7C19.9996 8.06087 19.5782 9.07828 18.828 9.82843C18.0779 10.5786 17.0605 11 15.9996 11C15.3358 11.0039 14.6819 10.8387 14.0996 10.52C14.9079 9.50134 15.39 8.26244 15.4828 6.96534C15.5756 5.66824 15.2747 4.37333 14.6196 3.25C15.0609 3.0846 15.5283 2.99992 15.9996 3C17.0605 3 18.0779 3.42143 18.828 4.17157C19.5782 4.92172 19.9996 5.93913 19.9996 7Z'
        fill='currentColor'
      />
      <path
        d='M9 22C12.866 22 16 19.7614 16 17C16 14.2386 12.866 12 9 12C5.13401 12 2 14.2386 2 17C2 19.7614 5.13401 22 9 22Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M22.0009 16.5C22.0009 18.88 19.5509 20.82 16.4409 20.99C17.4341 19.8973 17.9896 18.4767 18.0009 17C17.9847 16.0199 17.7393 15.0573 17.2843 14.189C16.8294 13.3208 16.1775 12.5711 15.3809 12H16.0009C19.3109 12 22.0009 14 22.0009 16.5Z'
        fill='currentColor'
      />
    </svg>
  );
}
