import { FC, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ILinkedServicesModalProps } from './LinkedServicesModal.props';
import {
  BtnsWrapper,
  CategoriesBlock,
  ColorLabel,
  ContentWrapper,
  EditLink,
  EditServicesWrap,
  IconWrap,
  MainContent,
  ModalWrapper,
  ServiceItem,
  ServiceItemInfo,
  ServicesBlock,
  ServicesList,
  TopWrapper
} from './LinkedServicesModal.styled';

import { getRouteServiceCategory } from '../../../../../../shared/const';
import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import NewButton from '../../../../../../shared/new-ui/NewButton/ui/NewButton';
import NewCategoriesList from '../../../../../../shared/new-ui/NewCategoriesList/ui/NewCategoriesList';
import { INewItem } from '../../../../../../shared/new-ui/NewCategoriesList/ui/NewCategoriesList.props';
import NewCustomCheckbox from '../../../../../../shared/new-ui/NewCustomCheckbox/ui/NewCustomCheckbox';
import NewCustomMultiCheckbox from '../../../../../../shared/new-ui/NewCustomMultiCheckbox/ui/NewCustomMultiCheckbox';
import { MultiValue } from '../../../../../../shared/new-ui/NewCustomMultiCheckbox/ui/NewCustomMultiCheckbox.props';
import NewSearchInput from '../../../../../../shared/new-ui/NewSearchInput/ui/NewSearchInput';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType, TextType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { IUserServices } from '../../../../../../shared/types/user/user.interface';
import { useUpdateCabinet } from '../../../../../../store/redux/cabinets/hooks/useCabinetsMutation';
import { useProductsQuery } from '../../../../../../store/redux/products/hooks/useProductsQuery';
import { useSubproductAllQuery } from '../../../../../../store/redux/subproducts/hooks/useSubproductQuery';
import { IService } from '../../../../../../store/redux/subproducts/subproducts.interface';
import { useUpdateUser } from '../../../../../../store/redux/user/hooks/useUsersMutation';
import { getTransformedDuration } from '../../../../../../utils/helperFunctions';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';
import { IconNew, EIcon } from '../../../../../icons/medium-new-icons/icon';

const LinkedServicesModal: FC<ILinkedServicesModalProps> = (props) => {
  const { closeModal, modalPayload, edit } = props;
  const { user, create, cabinet } = modalPayload;
  const { t } = useTranslation();
  const { data: products } = useProductsQuery();
  const { data: services } = useSubproductAllQuery(products ? products.map((product) => product.id) : undefined);
  const { mutateAsync: updateUser } = useUpdateUser();
  const { mutateAsync: updateCabinet } = useUpdateCabinet();
  const [searchValue, setSearchValue] = useState<string>('');
  const [chosenServices, setChosenServices] = useState<IUserServices[]>(user && user.idservices ? user.idservices : []);
  const [categoriesItems, setCategoriesItems] = useState<INewItem[]>([]);
  const [checkboxState, setCheckboxState] = useState<MultiValue>(
    chosenServices.length === 0 ? 'unchecked' : chosenServices.length !== services.length ? 'indeterminate' : 'checked'
  );

  const [servicesList, setServicesList] = useState<(IService | undefined)[]>(services);
  const [filteredServices, setFilteredServices] = useState<(IService | undefined)[]>(services);

  const { setActiveProduct, setUserServices, setCabinetServices } = useActions();
  const { activeProduct } = useTypedSelector((state) => state.products);

  const nav = useNavigate();
  useEffect(() => {
    if (user && user.idservices) {
      setChosenServices(
        user.idservices.filter((serviceObj: IUserServices) => services.find((service) => service?.id === serviceObj.idservice))
      );
    }
    if (cabinet && cabinet.idservices) {
      setChosenServices(
        cabinet.idservices.filter((serviceObj: IUserServices) => services.find((service) => service?.id === serviceObj.idservice))
      );
    }
  }, []);

  useEffect(() => {
    if (products && services) {
      setCategoriesItems(
        products.map((product) => {
          return {
            value: product.id,
            title: product.name,
            count: services.filter((service) => service?.idparents === product.id).length
          };
        })
      );
      setServicesList(services);
      setFilteredServices(services);
    }
  }, [products, services]);

  useEffect(() => {
    if (!services) return;
    if (activeProduct.value === 'all') {
      setServicesList(services);
    } else {
      setServicesList(services.filter((service) => service?.idparents === activeProduct.value));
    }
  }, [activeProduct]);
  useEffect(() => {
    if (chosenServices.length === 0) {
      setCheckboxState('unchecked');
    } else if (
      chosenServices.filter((item) => servicesList.find((service) => service?.id === item.idservice)).length < servicesList.length
    ) {
      setCheckboxState('indeterminate');
    } else {
      setCheckboxState('checked');
    }
  }, [chosenServices, servicesList]);

  useEffect(() => {
    if (searchValue.trim() !== '') {
      setFilteredServices(
        servicesList.filter((service) => service?.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim()))
      );
    } else {
      setFilteredServices(servicesList);
    }
  }, [servicesList, searchValue]);
  const handleIndividualCheckboxChange = (serviceId: string | undefined) => {
    if (!serviceId) return;
    const isAlreadyChosen = chosenServices.some((chosenService) => chosenService.idservice === serviceId);
    if (isAlreadyChosen) {
      setChosenServices(chosenServices.filter((chosenService) => chosenService.idservice !== serviceId));
    } else {
      setChosenServices([...chosenServices, { idservice: serviceId }]);
    }
  };

  const handleChooseServices = () => {
    if (user) {
      setUserServices(chosenServices);
    } else if (cabinet) {
      setCabinetServices(chosenServices);
    }
    closeModal ? closeModal() : () => {};
  };
  const handleUpdateEmployeeServices = async () => {
    if (!modalPayload || !modalPayload.user) return;
    const { user } = modalPayload;
    const { role, ...values } = user;
    const dataObj = role === 'admin' ? { ...values, idservices: chosenServices } : { ...user, idservices: chosenServices };
    const res = await updateUser(dataObj);
    if (res.status === 'ok') {
      if (closeModal) {
        closeModal();
        setChosenServices([]);
      }
      toast.success('Услуги обновлены');
    } else {
      toast.error(res.description || 'ошибка');
    }
  };

  const handleUpdateCabinetServices = async () => {
    if (!modalPayload || !modalPayload.cabinet) return;
    const res = await updateCabinet({ ...cabinet, idservices: chosenServices });
    if (res.status === 'ok') {
      if (closeModal) {
        closeModal();
        setChosenServices([]);
      }
      toast.success('Услуги обновлены');
    } else {
      toast.error(res.description || 'ошибка');
    }
  };

  const handleUpdateServices = async () => {
    if (modalPayload && modalPayload.user) {
      handleUpdateEmployeeServices();
    } else if (modalPayload && modalPayload.cabinet) {
      handleUpdateCabinetServices();
    }
  };

  return (
    <ModalWrapper>
      <TopWrapper
        $gap='12px'
        $align='center'
      >
        <NewButton
          typeBtn='close'
          onClick={closeModal}
        >
          <IconNew name={EIcon.close} />
        </NewButton>
        <NewText
          $tag={TextType.H2}
          $customSize={TextCustomType.T28}
          color='title'
        >
          {user && user.fio
            ? user.fio.split(' ')[1]
              ? user.fio.split(' ')[1]
              : user.fio.split(' ')[0]
              ? user.fio.split(' ')[0]
              : user.fio
            : cabinet && cabinet.name
            ? cabinet.name
            : ''}
          {t(' • Услуги')}
        </NewText>
      </TopWrapper>
      <ContentWrapper
        $gap='22px'
        $column
      >
        <NewSearchInput
          name={'search-services'}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={'Поиск услуги...'}
          className={'search-services'}
        />
        <MainContent $gap='0px'>
          <CategoriesBlock
            $column
            $gap='30px'
          >
            <NewText
              $tag={TextType.H4}
              $customSize={TextCustomType.T16}
              fontWeight={600}
            >
              {t('Категории')}
            </NewText>
            <NewCategoriesList
              items={categoriesItems}
              mainLabel={'Все услуги'}
              onClick={setActiveProduct}
            />
          </CategoriesBlock>
          <ServicesBlock
            $column
            $gap='30px'
          >
            <NewText
              $tag={TextType.H4}
              $customSize={TextCustomType.T16}
              fontWeight={600}
            >
              {t(activeProduct.value === 'all' ? 'Все услуги' : activeProduct.title)}
            </NewText>
            {filteredServices && filteredServices.length > 0 ? (
              <>
                <ServicesList>
                  <ServiceItem>
                    <NewCustomMultiCheckbox
                      name='services-all'
                      value={checkboxState}
                      onChange={(newState) => {
                        setCheckboxState(newState);
                        if (newState === 'checked') {
                          setChosenServices(
                            servicesList.map((service) => {
                              return {
                                idservice: service?.id || ''
                              };
                            })
                          );
                        } else if (newState === 'unchecked') {
                          setChosenServices([]);
                        }
                      }}
                    />
                    <NewText
                      $customSize={TextCustomType.T16}
                      color='title'
                    >
                      {t('Выбрать все')}
                    </NewText>
                  </ServiceItem>
                  {filteredServices.map((service) => (
                    <ServiceItem key={service?.id}>
                      <NewCustomCheckbox
                        name={`check-${service?.id}`}
                        value={chosenServices.some((chosenService) => chosenService.idservice === service?.id)}
                        onChange={() => handleIndividualCheckboxChange(service?.id)}
                      />
                      <ServiceItemInfo>
                        <FlexWithAlign
                          $gap='16px'
                          $align='center'
                        >
                          <ColorLabel style={{ backgroundColor: service?.color }}></ColorLabel>
                          <NewText
                            $customSize={TextCustomType.T16}
                            color='title'
                          >
                            {t(service?.name || '')}
                          </NewText>
                        </FlexWithAlign>
                        <NewText
                          $customSize={TextCustomType.T12}
                          color='grey'
                        >
                          {t(getTransformedDuration(service?.duration))}
                        </NewText>
                        <NewText
                          $customSize={TextCustomType.T16}
                          fontWeight={600}
                          color='title'
                        >
                          {service?.tarif} ₽
                        </NewText>
                      </ServiceItemInfo>
                    </ServiceItem>
                  ))}
                </ServicesList>
                <EditServicesWrap
                  $gap='12px'
                  $align='center'
                >
                  <IconWrap>
                    <IconNew name={EIcon.infoicon} />
                  </IconWrap>
                  <FlexWithAlign
                    $gap='4px'
                    $align='center'
                  >
                    <NewText>{t('Чтобы добавить новую услугу перейдите в раздел ')}</NewText>{' '}
                    <EditLink
                      to={getRouteServiceCategory()}
                      onClick={(e) => {
                        e.preventDefault();
                        nav(getRouteServiceCategory());
                        closeModal ? closeModal() : () => {};
                      }}
                    >
                      {t('Настройка услуг.')}
                    </EditLink>
                  </FlexWithAlign>
                </EditServicesWrap>
              </>
            ) : null}
          </ServicesBlock>
        </MainContent>
      </ContentWrapper>
      <BtnsWrapper
        $align='center'
        $justify='space-between'
      >
        <NewButton
          typeBtn='red'
          onClick={() => setChosenServices([])}
        >
          {t('Очистить всё')}
        </NewButton>
        <FlexWithAlign
          $gap='12px'
          $align='center'
        >
          <NewButton
            onClick={() => {
              setChosenServices([]);
              closeModal ? closeModal() : () => {};
            }}
          >
            {t('Отмена')}
          </NewButton>
          <NewButton
            typeBtn='black'
            onClick={() => {
              if (create) {
                handleChooseServices();
              } else {
                handleUpdateServices();
              }
            }}
          >
            {t(`Выбрано (${chosenServices.length})`)}
          </NewButton>
        </FlexWithAlign>
      </BtnsWrapper>
    </ModalWrapper>
  );
};

export default LinkedServicesModal;
