import React from 'react';

export function Megafon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.5688 6.91836C18.3723 6.87842 18.166 6.90912 17.996 7.00822L17.9708 7.02289L14.214 8.89821H7.60156C6.83128 8.89821 6.14867 9.18371 5.66422 9.6272C5.22265 10.0314 4.90156 10.8015 4.90156 11.4941C4.90156 12.1867 5.22262 12.9566 5.66422 13.3608C5.91225 13.5879 6.21221 13.7735 6.5493 13.9007C6.87061 14.0218 7.22564 14.0898 7.60156 14.0898H8.51693H9.34554H11.3339H14.214L17.9708 15.9653L17.996 15.98C18.1117 16.0474 18.2526 16.0866 18.4016 16.0866C18.6196 16.0866 18.8022 16.006 18.9247 15.8939C19.0459 15.7829 19.1016 15.6502 19.1016 15.5205V7.46776C19.1016 7.37747 19.0738 7.28253 19.012 7.19362C18.9202 7.06165 18.7655 6.95833 18.5688 6.91836ZM17.1165 5.44014C17.6853 5.11814 18.3346 5.03682 18.9278 5.15737C19.5307 5.27988 20.1077 5.61824 20.4905 6.16864C20.7556 6.54987 20.9016 7.0007 20.9016 7.46776V15.5205C20.9016 16.1996 20.599 16.7993 20.1412 17.2185C19.6846 17.6364 19.0672 17.8837 18.4016 17.8837C17.9524 17.8837 17.5077 17.7695 17.1165 17.5481L13.7891 15.8869H11.6517H9.82391H8.47094H7.60156C7.26733 15.8869 6.94068 15.8529 6.62569 15.788C5.79286 15.6164 5.04161 15.2291 4.44774 14.6854C3.5864 13.8969 3.10156 12.6216 3.10156 11.4941C3.10156 10.3667 3.58636 9.09115 4.44774 8.30262C5.26623 7.55334 6.38362 7.1011 7.60156 7.1011H13.7891L17.1165 5.44014Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.9016 7.99966L14.9016 14.9884L13.1016 14.9884L13.1016 7.99966H14.9016Z'
        fill='currentColor'
      />
      <path
        d='M8.7774 17.6222L8.47094 15.8869H7.60156C7.26733 15.8869 6.94068 15.8529 6.62569 15.788L7.00475 17.9343C7.12205 18.5984 7.51026 19.1435 8.02694 19.4881C8.54283 19.8323 9.19211 19.9815 9.8501 19.8656C10.5081 19.7498 11.0671 19.3879 11.434 18.8884C11.8015 18.3881 11.9795 17.7433 11.8622 17.0791L11.6517 15.8869H9.82391L10.0896 17.3912C10.1161 17.5411 10.079 17.6942 9.98245 17.8256C9.88532 17.9579 9.73139 18.0617 9.53754 18.0958C9.34368 18.13 9.16348 18.085 9.0269 17.9939C8.89112 17.9033 8.80388 17.7721 8.7774 17.6222Z'
        fill='currentColor'
      />
    </svg>
  );
}
