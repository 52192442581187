import { styled } from 'styled-components';
import themelight from '../../../../../app/providers/ThemeProvider/ui/theme';
import NewButton from '../../../../../shared/new-ui/NewButton/ui/NewButton';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0px 15px 25px -6px #0000001a;
`;

export const CloseBtn = styled(NewButton)`
  &.close svg {
    max-width: 32px;
    max-height: 32px;
  }
`;

export const ColorsBtnsWrap = styled.div`
  padding: 8px;
  display: grid;
  column-gap: 20px;
  row-gap: 16px;
  grid-template-columns: repeat(11, 1fr);
`;

export const ColorsDefaultBtn = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .chosen-icon {
    display: flex;
    color: ${themelight.newColor.base01};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const ColorsBtn = styled.button`
  position: relative;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 8px;
  .chosen-icon {
    display: flex;
    color: ${themelight.newColor.base01};
  }
`;
