export const PaymentIcon2 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24538)'>
        <path
          d='M2.45 2.63653L22.391 7.61503C23.6113 7.97379 24.6884 8.70637 25.4704 9.70957C26.2524 10.7128 26.6999 11.936 26.75 13.207V31.207C26.7827 31.7544 26.6829 32.3016 26.4589 32.8022C26.235 33.3027 25.8935 33.7419 25.4636 34.0823C25.0337 34.4227 24.528 34.6543 23.9894 34.7575C23.4508 34.8607 22.8953 34.8324 22.37 34.675L5.636 30.739C4.4123 30.402 3.32763 29.6843 2.53908 28.6897C1.75053 27.6951 1.29904 26.4753 1.25 25.207V5.70703C1.25356 4.51465 1.72881 3.37213 2.57195 2.52898C3.4151 1.68584 4.55762 1.21059 5.75 1.20703H31.25C32.4425 1.2102 33.5853 1.68532 34.4285 2.52855C35.2717 3.37178 35.7468 4.51453 35.75 5.70703V22.207C35.7468 23.3995 35.2717 24.5423 34.4285 25.3855C33.5853 26.2287 32.4425 26.7039 31.25 26.707H26.75'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.7559 5.70703H29.7559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.0059 20.707C17.0059 21.5027 17.3219 22.2657 17.8845 22.8284C18.4471 23.391 19.2102 23.707 20.0059 23.707C20.8015 23.707 21.5646 23.391 22.1272 22.8284C22.6898 22.2657 23.0059 21.5027 23.0059 20.707C23.0059 19.9114 22.6898 19.1483 22.1272 18.5857C21.5646 18.0231 20.8015 17.707 20.0059 17.707C19.2102 17.707 18.4471 18.0231 17.8845 18.5857C17.3219 19.1483 17.0059 19.9114 17.0059 20.707Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.7559 13.207H29.7559'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24538'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
