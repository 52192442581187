import styled from 'styled-components';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const MobileTabs = styled.div`
  height: 80px;
  border-top: 1.5px solid ${(props) => props.theme.color.base03};
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.color.base01};
  z-index: 9;
  position: fixed;
  padding: 7px 5px 0 5px;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
  align-items: start;

  &.hidden {
    display: none;
  }
`;
export const BottomTab = styled(FlexWithAlign)`
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 76px;
  max-width: 76px;
  gap: 5px;
  .message-unread {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: flex;
    right: 10px;
    top: 0;
    z-index: 1;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.color.accent};
    p {
      color: ${(props) => props.theme.color.base01};
    }
  }
  > div {
    svg {
      filter: grayscale(0);
      width: 28px;
      height: 28px;
    }
  }
  p {
    color: #999999;
  }
  p.active {
    filter: grayscale(0);
    color: ${(props) => props.theme.text.link};
  }
`;
