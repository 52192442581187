export const BusinessSettings = () => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2547_12578)'>
        <g clipPath='url(#clip1_2547_12578)'>
          <g clipPath='url(#clip2_2547_12578)'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M7.91613 0.571322L0.845894 6.24264L0.733011 6.3425C0.376782 6.69419 0.1875 7.14983 0.1875 7.6258V16.6485L0.19313 16.7581C0.267167 17.3707 0.754099 17.8125 1.33929 17.8125H6.75C7.06066 17.8125 7.3125 17.5607 7.3125 17.25V11.25L7.31661 11.1949C7.32461 11.1343 7.4047 11.0625 7.5 11.0625H10.5L10.5551 11.0666C10.6157 11.0746 10.6875 11.1547 10.6875 11.25V17.25C10.6875 17.5607 10.9393 17.8125 11.25 17.8125H16.6607L16.7702 17.8067C17.3811 17.7298 17.8125 17.2344 17.8125 16.6485V7.6258L17.8058 7.4761C17.7567 6.97564 17.5237 6.54074 17.1553 6.2436L10.0827 0.570366L9.94475 0.469973C9.31893 0.0597082 8.50198 0.0987742 7.91613 0.571322ZM9.321 1.40702L9.39948 1.46378L16.4502 7.1202C16.5817 7.22626 16.6669 7.38531 16.6842 7.55965L16.6882 7.65366L16.6875 16.6485C16.6875 16.6708 16.6743 16.686 16.6634 16.6874L16.6251 16.6886L11.8118 16.6875L11.8125 11.25C11.8125 10.582 11.3112 10.0228 10.6524 9.94632L10.5324 9.93845L7.5 9.93752C6.83199 9.93752 6.27282 10.4388 6.1963 11.0977L6.18843 11.2176L6.1875 16.6875H1.33929C1.3286 16.6875 1.31536 16.6755 1.31291 16.6555L1.31146 16.6143L1.3125 7.6258C1.3125 7.44955 1.38214 7.28192 1.50374 7.16152L1.57281 7.10072L8.62125 1.44793C8.80471 1.29995 9.04821 1.27284 9.25312 1.36898L9.321 1.40702Z'
              fill='currentColor'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2547_12578'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_2547_12578'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip2_2547_12578'>
          <rect
            width='18'
            height='18'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
