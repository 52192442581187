export const RemittanceIcon = () => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.6018 0.75117C10.8495 0.531019 11.2287 0.553328 11.4488 0.800998L14.6488 4.401C14.8058 4.57759 14.8445 4.82987 14.7477 5.04541C14.651 5.26095 14.4367 5.39962 14.2004 5.39962H1.0004C0.66903 5.39962 0.400401 5.13099 0.400401 4.79962C0.400401 4.46825 0.66903 4.19962 1.0004 4.19962H12.8643L10.552 1.59823C10.3318 1.35056 10.3541 0.971321 10.6018 0.75117ZM0.453057 8.95382C0.549849 8.73828 0.764126 8.59962 1.0004 8.59962H14.2004C14.5318 8.59962 14.8004 8.86825 14.8004 9.19962C14.8004 9.53099 14.5318 9.79962 14.2004 9.79962H2.33651L4.64885 12.401C4.869 12.6487 4.84669 13.0279 4.59902 13.2481C4.35135 13.4682 3.97211 13.4459 3.75196 13.1982L0.551955 9.59823C0.394983 9.42164 0.356265 9.16936 0.453057 8.95382Z'
        fill='currentColor'
      />
    </svg>
  );
};
