import React from 'react';

export function ImportFileIcon() {
  return (
    <svg
      width='27'
      height='29'
      viewBox='0 0 27 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.428 3.68628C18.8984 2.15673 16.4185 2.15673 14.889 3.68628L4.63593 13.9393C2.26024 16.315 2.26024 20.1668 4.63593 22.5425L6.8751 24.7816C9.25079 27.1573 13.1025 27.1573 15.4782 24.7816L25.142 15.1178C25.5651 14.6948 26.251 14.6948 26.6741 15.1178C27.0972 15.5409 27.0972 16.2268 26.6741 16.6499L17.0103 26.3137C13.7885 29.5355 8.56487 29.5355 5.34304 26.3137L3.10387 24.0745C-0.117956 20.8527 -0.117956 15.6291 3.10387 12.4073L13.3569 2.15422C15.7326 -0.221471 19.5844 -0.221471 21.96 2.15422L23.1386 3.33273C25.5142 5.70842 25.5142 9.56017 23.1386 11.9359L13.4748 21.5997C12.2056 22.8689 10.1478 22.8689 8.87857 21.5997L7.81791 20.539C6.54871 19.2698 6.54871 17.212 7.81791 15.9428L16.8925 6.86826C17.3155 6.4452 18.0014 6.4452 18.4245 6.86826C18.8476 7.29133 18.8476 7.97726 18.4245 8.40033L9.34998 17.4749C8.92691 17.8979 8.92691 18.5839 9.34998 19.0069L10.4106 20.0676C10.8337 20.4907 11.5196 20.4907 11.9427 20.0676L21.6065 10.4038C23.136 8.87424 23.136 6.39435 21.6065 4.86479L20.428 3.68628Z'
        fill='currentColor'
      />
    </svg>
  );
}
