import styled from 'styled-components';

import { growInput } from '../../../../../utils/styleUtils';

export const MessengerFormWrapper = styled.div`
  width: 102%;
  padding-top: 0.25rem;
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  z-index: 2;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  position: relative;
  @media (max-width: 767px) {
    border-radius: 20px 20px 0 0;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
    min-height: 83px;
    position: fixed;
    bottom: 0;
    padding-top: 8px;
    background: ${(props) => props.theme.color.base01};
  }
  .chat-input-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    padding: 0 0 1.25rem;
    flex: 0 0 auto;
    position: relative;
    .rows-wrapper-wrapper {
      width: 100%;
      display: flex;
      .rows-wrapper {
        transform: translate(0) scaleX(1);
        display: flex;
        align-items: center;
        flex-direction: column;
        width: calc(100% - (3.375rem + 0.5rem));
        max-width: calc(100% - (3.375rem + 0.5rem));
        justify-content: center;
        border-radius: 0.375rem 0 0 0.375rem;
        min-height: 3.375rem;
        max-height: 30rem;
        flex: 0 0 auto;
        position: relative;
        z-index: 3;
        @media (max-width: 767px) {
          min-height: 1rem;
          width: calc(100% - (3.375rem));
          max-width: calc(100% - (3.375rem));
        }
        &:before {
          content: ' ';
          position: absolute;
          inset: 0;
          border-radius: inherit;
          background: ${(props) => props.theme.color.elevation01};
          opacity: 1;
        }
        .new-message-wrapper {
          align-items: center;
          opacity: 1;
          min-height: 3.375rem;
          display: flex;
          justify-content: space-between;
          width: 100%;
          position: relative;
          background: ${(props) => props.theme.color.elevation01};
          border-radius: 0.375rem 0 0 0.375rem;
          border: 1px solid ${(props) => props.theme.color.base10};
          border-right: none;
          padding: 0.3125rem 0.5rem;
          @media (max-width: 767px) {
            min-height: 1rem;
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
  /* @media (max-width: 1440px) {
    width: calc(100% - 100px);
  } */
  @media (max-width: 767px) {
    width: 100%;
    //padding-bottom: 70px;
  }
`;

export const BtnCircle = styled.button``;
export const BtnIcon = styled.button`
  text-align: center;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s opacity;
  &.toggle-emotion {
    flex: 0 0 auto;
    font-size: 1.5rem;
    color: #aaa;
    margin-inline: 0.125rem;
    padding: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;
    svg {
      transition: 0.3s ease;
    }
    &:hover {
      svg {
        color: ${(props) => props.theme.color.primary};
      }
    }
  }
`;
export const BtnSendContainer = styled.div`
  position: absolute;
  inset-inline-end: 0.8125rem;
  bottom: 0;
  padding-bottom: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 54px;
  > button {
    width: 100%;
    height: 100%;
    border: 1px solid ${(props) => props.theme.color.base10};
    border-left: none;
    border-radius: 0 0.375rem 0.375rem 0;
    background-color: ${(props) => props.theme.color.base01};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    &:hover {
      background-color: ${(props) => props.theme.color.white9};
    }
    svg {
      color: #fff;
      position: relative;
      left: 1.5px;
    }
    @media (max-width: 767px) {
      width: 46px;
      height: 46px;
      background: transparent;
      &:hover {
        background: transparent;
      }
      svg {
        color: ${(props) => props.theme.text.text02};
      }
    }
  }
`;

export const InputMessageContainer = styled.div`
  width: 1%;
  max-height: inherit;
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  align-self: center;
  min-height: calc(3.375rem - 0.3125rem * 2);
  @media (max-width: 767px) {
    min-height: 1rem;
  }
  display: flex;
  align-items: center;
  padding: 0 0.5625rem;

  .input-message-input {
    transition: height 0.4s linear;
    overflow-y: auto;
    height: auto;
    color: ${(props) => props.theme.text.text01};
    transition-duration: 207ms;
    caret-color: ${(props) => props.theme.text.text01};
    position: relative;
    max-height: 27.5rem;
    background: none;
    padding: 0.5rem 0;
    resize: none;
    border: none;
    font-size: 15px;
    width: 100%;
    inset: 0;
    -webkit-overflow-scrolling: touch;
    @media (max-width: 767px) {
      font-size: 13px;
    }
    > img {
      width: 18px;
      height: 18px;
    }
    &.input-field-input-fake {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      bottom: auto;
      left: 0.5625rem;
      right: 0.5625rem;
      width: auto;
      height: auto;
      z-index: -3;
    }
  }
  .input-search-placeholder {
    transform: translate(0);
    transition: opacity 0.15s ease-out 0.1s, transform 0.15s ease-out 0.01s;
    inset-inline-start: unset;
    color: ${(props) => props.theme.text.text02};
    z-index: 1;
    white-space: nowrap;
    pointer-events: none;
    transform-origin: left center;
    display: block;
    position: absolute;
    opacity: 1;
    max-width: 100%;
    padding-inline-end: 0.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
    @media (max-width: 767px) {
      font-size: 13px;
    }
    &.closed {
      opacity: 0;
      transform: translate(calc(1rem * 1));
    }
  }
  .will-animate {
    animation: ${growInput} 0.25s forwards ease-in-out;
    z-index: 1;
    white-space: nowrap;
    inset-inline-start: calc(calc(42px + 3px - 1px) + 1px);
    pointer-events: none;
    transform-origin: left center;
    color: #9e9e9e;
  }
`;
