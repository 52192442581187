import React from 'react';

export function SidebarTasks() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M22.0007 14.02C22.0007 15.6113 21.3686 17.1374 20.2433 18.2626C19.1181 19.3878 17.592 20.02 16.0007 20.02H7.5007C6.24216 20.0183 5.02225 19.5851 4.04455 18.7926C3.06685 18.0001 2.39052 16.8963 2.12841 15.6654C1.86631 14.4344 2.0343 13.1508 2.60434 12.0288C3.17438 10.9068 4.11199 10.0142 5.2607 9.49995C5.43654 7.97555 6.17234 6.57081 7.32534 5.55823C8.47835 4.54566 9.96635 3.99745 11.5007 4.01995C12.8176 4.0028 14.1063 4.40141 15.1836 5.15906C16.2608 5.91672 17.0716 6.99482 17.5007 8.23995C18.7838 8.57122 19.9211 9.31793 20.7351 10.3635C21.5492 11.4091 21.9942 12.6948 22.0007 14.02Z'
        fill='#467FF3'
      />
    </svg>
  );
}
