import React, { memo, ReactNode, useCallback, useEffect, useRef } from 'react';

import { DrawerButtons, DrawerContainer, DrawerContentWrapper, DrawerHead } from './Drawer.styled';

import { cn } from '../../../lib';
import { AnimationProvider, useAnimationLibs } from '../../../lib/components/AnimationProvider';
import { Overlay } from '../../Overlay/Overlay';
import { Portal } from '../../Portal';

interface DrawerProps {
  className?: string;
  children: ReactNode;
  header?: string;
  buttons?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  lazy?: boolean;
  full?: boolean;
}

const height = window.innerHeight;

export const DrawerContent = memo((props: DrawerProps) => {
  const { className, header, buttons, children, onClose, full, isOpen, lazy } = props;

  const { Spring, Gesture } = useAnimationLibs();
  const [{ y }, api] = Spring.useSpring(() => ({ y: full ? height : height }));
  // const appElement = document.querySelector('.App');

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      if (scrollTop === 0) {
        api.start({ y: 0, immediate: false });
      } else if (scrollTop === scrollHeight - clientHeight) {
        api.start({ y: full ? height : height, immediate: false });
      }
    }
  }, [api, full]);

  useEffect(() => {
    if (isOpen) {
      handleScroll();
      const appDrawerElement = document.querySelector('.app_drawer:not(.close)');

      // if (appElement && appDrawerElement) {
      //   appElement.classList.add('open');
      //   document.body.style.cssText = `
      //   background: #fff;
      //
      // `;
      // }
    }
  }, [isOpen, handleScroll]);

  const close = (velocity = 0) => {
    api.start({
      y: full ? height : height,
      immediate: false,
      config: { ...Spring.config.stiff, velocity },
      onResolve: onClose
    });
    // if (appElement) {
    //   appElement.classList.remove('open');
    // }
  };

  const bind = Gesture.useDrag(
    ({ last, velocity: [, vy], direction: [, dy], movement: [, my], cancel }) => {
      if (my < -70) cancel();
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true
    }
  );
  const bindHeader = Gesture.useDrag(
    ({ last, velocity: [, vy], direction: [, dy], movement: [, my], cancel }) => {
      if (my < -70) cancel();

      if (last) {
        if (my > (full ? height : height) * 0.4 || (vy > 0.4 && dy > 0)) {
          close();
        } else {
          api.start({ y: 0, immediate: false });
        }
      } else {
        api.start({ y: my, immediate: true });
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true
    }
  );

  if (!isOpen) {
    return null;
  }

  const display = y.to((py) => (py < (full ? height : height) ? 'block' : 'none'));

  return (
    <Portal element={document.getElementById('app') ?? document.body}>
      <DrawerContainer
        $full={full}
        className={cn(className, 'app_drawer')}
      >
        <Overlay onClick={close} />
        <Spring.a.div
          className={'sheet'}
          style={{
            display,
            bottom: `0`,
            y
          }}
          {...bind()}
        >
          <Spring.a.div {...bindHeader()}>
            <DrawerHead>
              <div className={cn(!header && 'only-before')}>{header}</div>
            </DrawerHead>
          </Spring.a.div>
          <DrawerContentWrapper ref={scrollContainerRef}>{children}</DrawerContentWrapper>
          <DrawerButtons>{buttons}</DrawerButtons>
        </Spring.a.div>
      </DrawerContainer>
    </Portal>
  );
});

const DrawerAsync = (props: DrawerProps) => {
  const { isLoaded } = useAnimationLibs();

  if (!isLoaded) {
    return null;
  }

  return <DrawerContent {...props} />;
};

export const Drawer = (props: DrawerProps) => {
  return (
    <AnimationProvider>
      <DrawerAsync {...props} />
    </AnimationProvider>
  );
};
