import { Link } from 'react-router-dom';

import { styled } from 'styled-components';

import themelight from '../../../../../../app/providers/ThemeProvider/ui/theme';
import { FlexWithAlign } from '../../../../../../utils/styleUtils';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
`;

export const TopWrapper = styled(FlexWithAlign)``;

export const ContentWrapper = styled(FlexWithAlign)`
  .search-services {
    border-radius: 5px;
    background-color: ${themelight.newColor.base05};
  }
`;

export const MainContent = styled(FlexWithAlign)``;

export const CategoriesBlock = styled(FlexWithAlign)`
  padding-top: 12px;
  max-width: 294px;
  width: 100%;
  border-right: 1px solid ${themelight.newColor.base08};
`;
export const ServicesBlock = styled(FlexWithAlign)`
  padding: 12px 0 0 24px;
  width: 100%;
`;

export const BtnsWrapper = styled(FlexWithAlign)``;

export const ServicesList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-right: 6px;
  max-width: 550px;
  overflow: auto;
`;

export const ServiceItem = styled.li`
  padding: 18px 0;
  display: flex;
  align-items: center;
  gap: 24px;
  border-bottom: 1px solid ${themelight.newColor.base08};
`;

export const ServiceItemInfo = styled.div`
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const ColorLabel = styled.span`
  display: flex;
  height: 32px;
  width: 3px;
`;

export const EditServicesWrap = styled(FlexWithAlign)`
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${themelight.newColor.base08};
`;

export const EditLink = styled(Link)`
  text-decoration: underline;
  font-size: 14px;
  line-height: 1.5;
`;
export const IconWrap = styled.span`
  display: flex;
`;
