export function CloudArrowUpOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.44633 10.0132C6.44633 7.71572 8.38025 5.80269 10.8292 5.80269C12.9364 5.80269 14.6701 7.22477 15.1055 9.08505C15.1985 9.48251 15.5474 9.76759 15.9554 9.77956C18.3396 9.84953 20.2015 11.7334 20.2015 13.9881C20.2015 16.2856 18.2676 18.1987 15.8186 18.1987H15.5976C15.1006 18.1987 14.6976 18.6016 14.6976 19.0987C14.6976 19.5957 15.1006 19.9987 15.5976 19.9987H15.8186C19.205 19.9987 22.0015 17.3356 22.0015 13.9881C22.0015 10.9257 19.6604 8.43556 16.669 8.03415C15.8231 5.67306 13.5151 4.00269 10.8292 4.00269C7.44288 4.00269 4.64633 6.6658 4.64633 10.0132C4.64633 10.1332 4.64996 10.2525 4.65713 10.3709C3.08166 11.2575 2.00488 12.9178 2.00488 14.8399C2.00488 17.7169 4.40723 19.9987 7.3073 19.9987H8.39841C8.89547 19.9987 9.29841 19.5957 9.29841 19.0987C9.29841 18.6016 8.89547 18.1987 8.39841 18.1987H7.3073C5.3446 18.1987 3.80488 16.667 3.80488 14.8399C3.80488 13.4582 4.67951 12.2507 5.96034 11.7383C6.3575 11.5794 6.58796 11.1629 6.5116 10.742C6.46877 10.5059 6.44633 10.2624 6.44633 10.0132ZM11.1 21.1029C11.1 21.6 11.5029 22.0029 12 22.0029C12.4971 22.0029 12.9 21.6 12.9 21.1029V13.1219L14.453 14.7268C14.7986 15.0841 15.3684 15.0934 15.7256 14.7478C16.0828 14.4022 16.0922 13.8324 15.7465 13.4752L12.6468 10.2716C12.4773 10.0964 12.244 9.99752 12.0003 9.99743C11.7566 9.99735 11.5232 10.0961 11.3536 10.2712L8.24963 13.4747C7.90375 13.8317 7.91274 14.4015 8.26972 14.7474C8.62669 15.0933 9.19647 15.0843 9.54235 14.7273L11.1 13.1197V21.1029Z'
        fill='currentColor'
      />
    </svg>
  );
}
