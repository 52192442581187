export enum AppRoutes {
  AUTH = 'auth',
  REGISTER = 'register',
  REGISTER_CONFIRM = 'register-confirm',
  RESET_PASSWORD = 'reset-password',
  NEW_PASSWORD = 'new-password',
  SUCCESS_PASSWORD = 'success-password',
  SUPPORT = 'support',
  ONLINE_RECORDING = 'online-recording',
  CLIENT = 'client',
  CALENDAR_EMPLOYEE = 'calendar-employee',
  LIST_EMPLOYEE = 'list-employee',
  CALENDAR_CABINET = 'calendar-cabinet',
  LIST_CABINET = 'list-cabinet',
  CLIENT_EDIT = 'client-edit',
  CLIENT_ADD = 'client-add',
  CLIENT_IMPORT = 'clients-import',
  TASKBOOK = 'taskbook',
  CALL_DIALOG_INIDLETIME = 'call-dialog-inidletime',
  CALL_PRODUCT = 'call-product',
  EMPLOYEES_SUMMARY = 'employees-summary',
  EMPLOYEES_TIMETABLE = 'employees-timetable',
  ANALYTICS = 'analytics',
  PROFILE = 'profile',
  PROFILE_LOGO = 'profile-logo',
  PERSONAL_AREA = 'personal-area',
  PROTAL_REDIRECT_PAYMENT = 'protal-redirect-payment',
  NOT_FOUND = 'not-found',
  MESSENGER = 'messenger',
  MESSENGER_MOBILE_CHAT = 'messenger-mobile-chat',
  MESSENGER_MOBILE_LIST = 'messenger-mobile-list',
  USERRECORDING = 'user-recording',
  USERRECORDINGALL = 'user-recording-all',
  MOREMENU = 'more-menu',
  CABINETS = 'cabinets',
  CABINETS_ADD = 'cabinets_add',
  CABINETS_EDIT = 'cabinets_edit',
  SERVICE_CATEGORY = 'service-category',
  SERVICE_ADD = 'service/add',
  SERVICE_EDIT = 'service/:serviceId/edit',
  SETTINGS = 'settings',
  PORTAL = 'protal',
  SUBSCRIBE = 'subscribe',
  BUSINESS_SETTINGS = 'business-settings',
  BUSINESS_SETTINGS_MAIN = 'business-settings-main',
  BUSINESS_SETTINGS_CATEGORY = 'business-settings-category',
  BUSINESS_SETTINGS_LOGO = 'business-settings-logo',
  BUSINESS_SETTINGS_OPERATING_MODE = 'business-settings-operating-mode',
  CASH_REGISTERS = 'cash-registers',
  CASH_REGISTER_CREATE = 'cash-register/create',
  CASH_REGISTER_EDIT = '/cash-register/edit/:cashRegisterId',
  SALES = 'sales',
  SALES_NEW = 'sales-new',
  SALES_CASH_REGISTERS = 'sales-cash-registers',
  PRODUCTS = 'products',
  PRODUCT_ADD = 'product-add',
  TELEGRAM_BOT_INFO = 'telegram-bot-info',
  EMPLOYEE_CREATE = 'employee-create',
  SUCCESS_SALE = 'success-sale',
  EMPLOYEE_EDIT = 'employee-edit',
  PERSONAL_AREA_SETTINGS = 'personal-area-settings',
  PROFILE_SECURITY = 'profile-security',
  PAYMENTS_AND_SALES = 'payments-and-sales',
  PAYMENTS_SETTINGS = 'payments-settings',
  TRANSACTIONS_ALL = 'transactions-all',
  LICENCE = 'licence',
  POLICY = 'policy',
  AGREEMENT = 'agreement',
  SALES_TRANSACTIONS = ' sales-transactions',
  PERSONAL_SITE = 'personal-site'
}
