import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';

import InnerLayout from '../../../../components/layouts/InnerLayout';
import CommonModal from '../../../../components/shared/modal/CommonModal';
import CommonSidebar from '../../../../components/shared/modal/CommonSidebar';
import { routeConfig } from '../config/routeConfig';
import { Loader } from '../../../../shared/ui';

export const AppRouter = () => {
  return (
    <>
      <Routes>
        {Object.values(routeConfig).map(({ element, path, authOnly, title }) => (
          <Route
            key={path}
            path={path}
            element={
              authOnly ? (
                <InnerLayout>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <Suspense fallback={<Loader />}>
                    <div className='page-wrapper'>{element}</div>
                  </Suspense>
                  <CommonModal />
                  <CommonSidebar />
                </InnerLayout>
              ) : (
                <>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <Suspense fallback={<Loader />}>
                    <div className='page-wrapper'>{element}</div>
                  </Suspense>
                </>
              )
            }
          />
        ))}
      </Routes>
    </>
  );
};
