import React from 'react';
import videoSrc from '../../../../../../assets/video/tutorial.mp4';

import { BotExampleContainer, CoverText, SuccessCover, TextItem, TextItemWrapper, VerticalWhiteLine, VerticalWhiteLine2 } from './BotExample.styled';
import { EIcon, IconNew } from '../../../../../icons/medium-new-icons/icon';
import NewText from '../../../../../../shared/new-ui/NewText/ui/NewText';
import { TextCustomType } from '../../../../../../shared/new-ui/NewText/ui/NewText.props';
import { useFilialQuery } from '../../../../../../store/redux/filial/hooks/useFilialQuery';

import telegramSuccess from '../../../../../../assets/images/telegramSuccess.svg';
import Lottie from 'react-lottie';

import animationData from '../../../../../../lottie/Confetti.json';
interface IProps {
  stage: number;
}

const BotExample = (props: IProps) => {
  const { stage } = props;
  const { data: activeFilial } = useFilialQuery();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <BotExampleContainer>
      {stage === 0 ? (
        <video
          controls
          autoPlay={false}
          playsInline
          muted
          preload={'metadata'}
        >
          <source
            src={videoSrc}
            type='video/mp4'
          />
        </video>
      ) : (
        <SuccessCover>
          <CoverText>
            <TextItemWrapper>
              <IconNew name={EIcon.okbluewhite} />
              <TextItem>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={600}
                  color='white'
                >
                  Активирован
                </NewText>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={400}
                  color='white'
                >
                  Бот доступен по <a className={'bot-url'} href={`https://${activeFilial?.linktgbot}` || ''}>ссылке</a>
                </NewText>
              </TextItem>
            </TextItemWrapper>
            <VerticalWhiteLine></VerticalWhiteLine>
            <TextItemWrapper className='middle-item'>
              <IconNew name={EIcon.cloudbluewhite} />
              <TextItem>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={600}
                  color='white'
                >
                  Данные обновлены
                </NewText>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={400}
                  color='white'
                >
                  Используются ваши настройки в системе Telebon
                </NewText>
              </TextItem>
            </TextItemWrapper>
            <VerticalWhiteLine2></VerticalWhiteLine2>
            <TextItemWrapper>
              <IconNew name={EIcon.bellbluewhite} />
              <TextItem>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={600}
                  color='white'
                >
                  Записи настроены
                </NewText>
                <NewText
                  $customSize={TextCustomType.T14}
                  fontWeight={400}
                  color='white'
                >
                  Новые записи автоматически попадают в журнал
                </NewText>
              </TextItem>
            </TextItemWrapper>
          </CoverText>
          <img src={telegramSuccess}></img>
          <div className='lottie'>
            <Lottie
              options={defaultOptions}
              height={641}
              style={{ borderRadius: '16px' }}
              width={310}
            />
          </div>
        </SuccessCover>
      )}
    </BotExampleContainer>
  );
};

export default BotExample;
