export function ArrowshapeLeft2outline() {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2311_4190)'>
        <path
          d='M7.71011 4.34238C8.03731 4.08838 8.09665 3.61723 7.84265 3.29003C7.58865 2.96284 7.1175 2.9035 6.7903 3.1575L2.0434 6.84248C-0.0143591 8.44405 -0.0135936 11.5555 2.04437 13.1568L6.79019 16.8423C7.11734 17.0964 7.58851 17.0371 7.84257 16.71C8.09662 16.3828 8.03737 15.9116 7.71022 15.6576L2.96439 11.9721C1.67889 10.9712 1.67927 9.02715 2.9647 8.0262L7.71011 4.34238Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.2401 4.75259C12.2401 4.54379 12.0009 4.42812 11.8377 4.55512L5.09665 9.80177C4.96805 9.90187 4.96806 10.0966 5.09668 10.1967L11.8429 15.446C12.0061 15.573 12.2453 15.4573 12.2453 15.2485V12.1129C12.2453 11.6987 12.581 11.3629 12.9953 11.3629C15.4514 11.3629 17.3372 12.2761 18.8274 13.5599C18.5613 12.1057 18.0029 10.9828 17.2568 10.1339C16.1914 8.92188 14.6763 8.19246 12.8723 7.90554C12.5081 7.84762 12.2401 7.53359 12.2401 7.16485V4.75259ZM10.9164 3.3714C12.0662 2.47654 13.7401 3.29705 13.7401 4.75259V6.54288C15.55 6.93654 17.1745 7.76833 18.3834 9.1436C19.735 10.6812 20.5002 12.8223 20.5002 15.588C20.5002 15.9046 20.3014 16.1871 20.0033 16.294C19.7053 16.4009 19.3723 16.309 19.1711 16.0646C17.8093 14.4097 16.1125 13.123 13.7453 12.8979V15.2485C13.7453 16.7039 12.0715 17.5245 10.9218 16.6298L4.17553 11.3806C3.27518 10.68 3.27509 9.31874 4.17535 8.61805L10.9164 3.3714Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2311_4190'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
