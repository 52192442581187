import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ResendText = styled.span`
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 1px solid #fff;
  transition: all 0.3s ease;
  &:hover {
    color: #5089fd;
    border-color: #5089fd;
  }
`;
