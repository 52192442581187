export const AddBreakIcon = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_354_3342)'>
        <path
          d='M13.3278 6.55173H7.44779V0.671726C7.44779 0.559726 7.40112 0.466393 7.30779 0.391726C7.21445 0.31706 7.11179 0.279726 6.99979 0.279726C6.88779 0.279726 6.79445 0.31706 6.71979 0.391726C6.64512 0.466393 6.60779 0.559726 6.60779 0.671726V6.55173H0.727785C0.578452 6.55173 0.466452 6.59839 0.391785 6.69173C0.317118 6.78506 0.279785 6.88773 0.279785 6.99973C0.279785 7.11173 0.317118 7.21439 0.391785 7.30773C0.466452 7.40106 0.578452 7.42906 0.727785 7.39173H6.60779V13.2717C6.60779 13.4211 6.64512 13.5331 6.71979 13.6077C6.79445 13.6824 6.88779 13.7197 6.99979 13.7197C7.11179 13.7197 7.21445 13.6824 7.30779 13.6077C7.40112 13.5331 7.44779 13.4211 7.44779 13.2717V7.39173H13.3278C13.4398 7.39173 13.5331 7.35439 13.6078 7.27973C13.6825 7.20506 13.7198 7.11173 13.7198 6.99973C13.7198 6.88773 13.6825 6.78506 13.6078 6.69173C13.5331 6.59839 13.4398 6.55173 13.3278 6.55173Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_354_3342'>
          <rect
            width='14'
            height='14'
            fill='white'
            transform='matrix(1 0 0 -1 0 14)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
