// user.slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IUser, IUserSchedule, IEmployeeSchedule, IUserServices } from './user.interface';
import {
  fetchUserByIdAsync,
  fetchUsersAsync,
  fetchEmployeesAsync,
  updateUserAsync,
  updateUserScheduleAsync,
  fetchUserScheduleForPeriodAsync,
  fetchUserScheduleAsync
} from './user.actions';
import { FULFILLED, PENDING, STATE } from '../../../utils/state';

interface UserState {
  user: IUser[] | [];
  employees: IUser[] | [];
  onlyUser: IUser;
  profileUser: IUser;
  activeUser: IUser;
  state: STATE;
  testOption: number;
  currEmployeeSchedule: IUserSchedule[];
  employeeSchedules: IEmployeeSchedule[];
  userServices: IUserServices[];
}

const initialState: UserState = {
  user: [],
  employees: [],
  onlyUser: {} as IUser,
  activeUser: {} as IUser,
  profileUser: {} as IUser,
  state: PENDING,
  testOption: 1,
  currEmployeeSchedule: [{ date: dayjs().format('YYYY-MM-DD'), timestart: '', timeend: '' }],
  employeeSchedules: [{ date: dayjs().format('YYYY-MM-DD'), timestart: '', timeend: '', employeeId: '' }],
  userServices: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setActiveUser: (state, action: PayloadAction<IUser>) => {
      state.activeUser = action.payload;
    },
    setUserServices: (state, action: PayloadAction<IUserServices[]>) => {
      state.userServices = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserByIdAsync.fulfilled, (state, action) => {
        state.onlyUser = action.payload;
        state.profileUser = action.payload.users;
        state.state = FULFILLED;
      })
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        state.user = action.payload.users;
        state.activeUser = action.payload.users[0];
        state.state = FULFILLED;
      })
      .addCase(fetchEmployeesAsync.fulfilled, (state, action) => {
        state.user = action.payload.users;
        state.employees = action.payload.employees;
        state.state = FULFILLED;
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
      })
      .addCase(updateUserScheduleAsync.fulfilled, (state, action) => {
        state.state = FULFILLED;
      })
      .addCase(fetchUserScheduleForPeriodAsync.fulfilled, (state, action) => {
        state.currEmployeeSchedule = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchUserScheduleAsync.fulfilled, (state, action) => {
        state.employeeSchedules = action.payload.flat(1);
        state.state = FULFILLED;
      });
  }
});

export const { reducer, actions } = userSlice;
export const { setActiveUser } = actions;
