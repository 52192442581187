export function UploadOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3636 2.36358C11.7151 2.01211 12.2849 2.01211 12.6364 2.36358L18.6364 8.36358C18.9878 8.71505 18.9878 9.2849 18.6364 9.63637C18.2849 9.98784 17.7151 9.98784 17.3636 9.63637L12.9 5.17277V16C12.9 16.497 12.497 16.9 12 16.9C11.5029 16.9 11.1 16.497 11.1 16V5.17277L6.63637 9.63637C6.2849 9.98784 5.71505 9.98784 5.36358 9.63637C5.01211 9.2849 5.01211 8.71505 5.36358 8.36358L11.3636 2.36358ZM4.09998 20C4.09998 19.5029 4.50292 19.1 4.99998 19.1H19C19.497 19.1 19.9 19.5029 19.9 20C19.9 20.497 19.497 20.9 19 20.9H4.99998C4.50292 20.9 4.09998 20.497 4.09998 20Z'
        fill='currentColor'
      />
    </svg>
  );
}
