import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FULFILLED, INIT, PENDING, REJECTED, STATE } from '../../../utils/state';
import { ISubproduct, ISubproductsAll, SubproductsState } from './subproducts.interface';
import {
  fetchSubproductsAllAsync,
  fetchSubproductsAsync,
  setState,
  setSubproducts,
  setSubproductsAll,
  setSubproductsArray
} from './subproducts.actions';

const initialState: SubproductsState = {
  subproducts: [],
  subproductsAll: [],
  subproductsArray: [],
  state: INIT
};

export const subproductsSlice = createSlice({
  name: 'subproducts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setSubproducts, (state, action: PayloadAction<ISubproduct[]>) => {
        state.subproducts = action.payload;
      })
      .addCase(setSubproductsAll, (state, action: PayloadAction<ISubproductsAll[]>) => {
        state.subproductsAll = action.payload;
      })
      .addCase(setSubproductsArray, (state, action: PayloadAction<ISubproduct[]>) => {
        state.subproductsArray = action.payload;
      })
      .addCase(setState, (state, action: PayloadAction<STATE>) => {
        state.state = action.payload;
      })
      .addCase(fetchSubproductsAsync.fulfilled, (state, action) => {
        state.subproducts = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchSubproductsAllAsync.fulfilled, (state, action) => {
        state.subproductsAll = action.payload;
        state.subproductsArray = action.payload.flatMap((item) => item.data);
        state.state = FULFILLED;
      })
      .addCase(fetchSubproductsAllAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchSubproductsAllAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = subproductsSlice;
