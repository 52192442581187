import React from 'react';

export function MoreVerticalOutline() {
  return (
    <svg
      width='4'
      height='14'
      viewBox='0 0 4 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.00001 0.666016C1.55789 0.666016 1.13388 0.841646 0.821261 1.15427C0.508638 1.46689 0.333008 1.8909 0.333008 2.33302C0.333008 2.77513 0.508637 3.19914 0.821261 3.51176C1.13388 3.82439 1.55789 4.00002 2.00001 4.00002C2.44212 4.00002 2.86613 3.82439 3.17876 3.51176C3.49138 3.19914 3.66701 2.77513 3.66701 2.33302C3.66701 1.8909 3.49138 1.46689 3.17876 1.15427C2.86613 0.841646 2.44212 0.666016 2.00001 0.666016ZM2.00001 5.33302C1.55789 5.33302 1.13388 5.50865 0.821261 5.82127C0.508638 6.13389 0.333008 6.5579 0.333008 7.00002C0.333008 7.44213 0.508637 7.86614 0.821261 8.17876C1.13389 8.49139 1.55789 8.66702 2.00001 8.66702C2.44212 8.66702 2.86613 8.49139 3.17876 8.17876C3.49138 7.86614 3.66701 7.44213 3.66701 7.00002C3.66701 6.5579 3.49138 6.13389 3.17876 5.82127C2.86613 5.50865 2.44212 5.33302 2.00001 5.33302ZM2.61877 10.1085C2.4091 10.0287 2.18548 9.99208 1.96129 10.0008C1.53067 10.0174 1.12324 10.2003 0.824483 10.5108C0.525719 10.8214 0.358829 11.2356 0.358829 11.6665C0.358829 12.0975 0.525717 12.5117 0.824483 12.8222C1.12325 13.1328 1.53068 13.3156 1.96129 13.3323C2.18547 13.341 2.40909 13.3043 2.61877 13.2245C2.82846 13.1447 3.01986 13.0234 3.18153 12.8679C3.34321 12.7123 3.47183 12.5258 3.55969 12.3193C3.64754 12.1129 3.69283 11.8909 3.69283 11.6665C3.69283 11.4422 3.64754 11.2201 3.55969 11.0137C3.47183 10.8073 3.34321 10.6207 3.18153 10.4652C3.01986 10.3096 2.82845 10.1883 2.61877 10.1085Z'
        fill='currentColor'
        fillOpacity='0.65'
      />
    </svg>
  );
}
