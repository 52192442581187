import { useQuery } from '@tanstack/react-query';

import { FilialService } from '../../../../shared/api/model/service/filial.service';
import { FILIAL_QUERY } from '../../../../shared/const/query.const';
import { IFilial } from '../filial.interface';

export function useFilialQuery() {
  return useQuery<IFilial[], Error, IFilial>({
    queryKey: [FILIAL_QUERY],
    queryFn: () => FilialService.fetchFilials(),
    staleTime: 18000000,
    select: (data: IFilial[]) => data[0]
  });
}
