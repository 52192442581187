import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import { INewDropDownProps } from './NewDropDown.props';
import { DropDownIconWrap, DropDownWrapper, Label, LabelWrap, OptionWrap } from './NewDropdown.styled';

import { EIcon, IconNew } from '../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../lib';
import { ErrorText } from '../../NewInput/ui/NewInput.styled';
import NewText from '../../NewText/ui/NewText';
import { TextCustomType } from '../../NewText/ui/NewText.props';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator
      className={'dropdownArrow'}
      {...props}
    >
      <IconNew name={EIcon.chevrondownsmall} />
    </components.DropdownIndicator>
  );
};

const CustomMenuList = (props: any) => {
  const menuListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (menuListRef.current) {
      const menuList = menuListRef.current.querySelector('.custom-select__menu-list');
      if (menuList && menuList.querySelectorAll('div').length > 0) {
        const index = Array.from(menuList.querySelectorAll('div')).findIndex((item) =>
          item.classList.contains('custom-select__option--is-selected')
        );
        //@ts-ignore
        const optionHeight = menuList.children[0].offsetHeight;
        menuList.scrollTop = index * optionHeight;
      }
    }
  }, []);

  return (
    <div ref={menuListRef}>
      <components.MenuList {...props} />
    </div>
  );
};

const CustomOption = (props: any) => (
  <components.Option {...props}>
    {props.data.iconName ? (
      <IconNew name={props.data.iconName} />
    ) : props.data.iconFinance ? (
      <DropDownIconWrap>{props.data.iconFinance}</DropDownIconWrap>
    ) : null}

    <OptionWrap
      $align='center'
      $justify='space-between'
    >
      <LabelWrap
        $column
        $gap='0px'
        $align='center'
      >
        <NewText
          color='title'
          $customSize={TextCustomType.T16}
        >
          {props.data.label}
        </NewText>
      </LabelWrap>
      {props.data.withIconSum ? (
        <NewText
          color='title'
          $customSize={TextCustomType.T16}
        >
          {props.data.withIconSum} ₽
        </NewText>
      ) : null}
    </OptionWrap>
  </components.Option>
);

const CustomSingleValue = (props: any) => (
  <components.SingleValue {...props}>
    {props.data.iconName ? (
      <IconNew name={props.data.iconName} />
    ) : props.data.iconFinance ? (
      <DropDownIconWrap>{props.data.iconFinance}</DropDownIconWrap>
    ) : null}
    <OptionWrap
      $align='center'
      $justify='space-between'
    >
      <LabelWrap
        $column
        $gap='0px'
      >
        {props.data.withIconLabel ? (
          <NewText
            color='grey'
            $customSize={TextCustomType.T12}
          >
            {props.data.withIconLabel}
          </NewText>
        ) : null}
        <NewText
          color='title'
          $customSize={TextCustomType.T16}
        >
          {props.data.label}
        </NewText>
      </LabelWrap>
      {props.data.withIconSum ? (
        <NewText
          color='title'
          $customSize={TextCustomType.T16}
        >
          {props.data.withIconSum} ₽
        </NewText>
      ) : null}
    </OptionWrap>
  </components.SingleValue>
);

const NewDropdown: FC<INewDropDownProps> = (props) => {
  const {
    additionalProps,
    getOptionLabel,
    labelTitle,
    currentValue,
    position,
    options,
    $width,
    error = null,
    className,
    disabled,
    onChange,
    placeholder,
    children,
    isReset,
    withIcon
  } = props;

  const { t } = useTranslation();
  const [uniqueId] = useState(() => 'select_' + Math.random().toFixed(5).slice(2));
  const transformedList = options?.map((option) => {
    if (option.label && (option.value || option.value === 0)) {
      return option;
    } else {
      return { value: option.id, label: option.name || option.title || option?.name || option?.fio, additional: option };
    }
  });

  const selectRef = useRef<any | null>(null);
  const menuListRef = useRef<HTMLDivElement | null>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(
    !!transformedList?.find((option) => option.value === currentValue || option.label === currentValue)
  );
  const closeMenuHandler = () => {
    const menuEl = document.querySelector<HTMLElement>(`#${uniqueId} .custom-select__menu`);
    const containerEl = menuEl?.parentElement;
    const clonedMenuEl = menuEl?.cloneNode(true) as HTMLElement;

    if (!clonedMenuEl) return;

    clonedMenuEl.classList.add('menu--close');
    clonedMenuEl.addEventListener('animationend', () => {
      containerEl?.removeChild(clonedMenuEl);
    });

    containerEl?.appendChild(clonedMenuEl!);
  };
  useEffect(() => {
    setIsFilled(!!transformedList?.find((option) => option.value === currentValue || option.label === currentValue));
  }, [currentValue, transformedList]);

  useEffect(() => {
    if (selectRef.current && isReset) {
      const currValue = selectRef.current.getValue();
      if (
        currValue &&
        currValue.length > 0 &&
        currValue[0]?.additional?.value &&
        currentValue.length > 0 &&
        !currentValue.find((item: any) => item.val === currValue[0]?.additional?.value)
      ) {
        selectRef.current.setValue(null);
      }
    }
  }, [currentValue, isReset]);
  const scrollToSelected = () => {
    if (selectRef.current && menuListRef.current) {
      const index = transformedList.findIndex((option) => option.label === currentValue || option.value === currentValue);
      const menuList = menuListRef.current.querySelector('.custom-select__menu-list');
      if (menuList && index !== -1) {
        //@ts-ignore
        const optionHeight = menuList.children[0]?.offsetHeight || 0;
        menuList.scrollTop = index * optionHeight;
      }
    }
  };

  return (
    <DropDownWrapper
      $width={$width}
      className={cn(className && `${className}`, withIcon && 'with-icon')}
    >
      {labelTitle && <Label className={cn(isFocused && 'active')}>{labelTitle}</Label>}
      <Select
        ref={selectRef}
        id={uniqueId}
        options={transformedList}
        value={transformedList?.find((option) => option.value === currentValue || option.label === currentValue)}
        placeholder={placeholder ? placeholder : `${t('Выбрать')}...`}
        classNamePrefix={cn('custom-select')}
        onChange={onChange}
        menuPlacement={position === 'top' ? 'top' : 'auto'}
        minMenuHeight={200}
        maxMenuHeight={200}
        className={className}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMenuOpen={scrollToSelected}
        onMenuClose={() => closeMenuHandler()}
        components={{ DropdownIndicator, MenuList: CustomMenuList, Option: CustomOption, SingleValue: CustomSingleValue }}
        isSearchable={false}
        noOptionsMessage={() => t('Нет доступных опций')}
        getOptionLabel={getOptionLabel as (option: any) => string}
        isDisabled={disabled}
        menuShouldScrollIntoView={true}
        {...additionalProps}
      />
      {children && <div className='additionalSvg'>{children}</div>}
      {error ? <ErrorText>{error}</ErrorText> : null}
    </DropDownWrapper>
  );
};

export default NewDropdown;
