import styled from 'styled-components';

export const Wrapper = styled.div<{ top: number; left: number }>`
  position: fixed;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  padding: 8px;
  background-color: #e3e5e8;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  z-index: 1000;
  div {
    :hover {
      background-color: #c7cbd1;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      padding: 8px;
      border-radius: 8px;
      user-select: none;
    }
  }
`;

export const Separator = styled.div`
  height: 1px;
  background-color: #c7cbd1;
  margin: 5px 0;
`;
