import { InputType } from '../../Input';

export interface IOption {
  id?: string;
  name?: string;
}

export enum PositionType {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum DirectionType {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export interface IMultiSelectProps {
  placeholder: string;
  error?: any;
  styles?: any;
  options: any[];
  direction?: DirectionType;
  size?: InputType;
  className?: string;
  setValue: any;
  value: any[];
  optionPosition: PositionType;
}
