import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChatListData, IChatMessageData } from './chat-api';
import { setChatRoomId } from './chat-reducer';

export type StatusType = 'pending' | 'ready' | 'error';
interface ChatState {
  messages: IChatMessageData[] | [] | null;
  userChats: IChatListData[] | [];
  telebotMessages: IChatMessageData[] | [];
  techChat: string;
  WroomId: string;
  chatUsers: [{ user_id: string }] | [];
  status: StatusType;
  websocketroomId: string;
  opendialog: boolean;
}

const initialState: ChatState = {
  messages: [] || null,
  userChats: [],
  opendialog: false,
  telebotMessages: [],
  techChat: '',
  WroomId: '',
  chatUsers: [],
  status: 'pending',
  websocketroomId: ''
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setOpendialog: (state: ChatState, action: PayloadAction<boolean>) => {
      state.opendialog = action.payload;
    },
    setCloseMassages: (state: ChatState) => {
      state.messages = null;
    },
    messagesChatList: (state, action: PayloadAction<IChatListData[]>) => {
      if (action.payload && Array.isArray(action.payload)) {
        state.userChats = action.payload;
        for (const chat of state.userChats) {
          if (chat.is_tech_support) {
            state.techChat = chat.chat_id;
          }
        }
      }
    },
    messagesChatMessage: (state, action: PayloadAction<IChatMessageData[]>) => {
      const firstMessage = action.payload[0];
      if (firstMessage !== undefined) {
        if (firstMessage.chat.chat_id === state.websocketroomId) {
          if (action.payload && Array.isArray(action.payload)) {
            state.messages = action.payload;
          } else {
            console.error('Ошибка формата данных. Отсутствует ожидаемый массив chatMessages.');
          }
        }
      } else {
        state.messages = [];
      }
    },

    messagesRecords: (state, action: PayloadAction<IChatMessageData[]>) => {
      state.messages = action.payload;
    },
    statusChanged: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder.addCase(setChatRoomId, (state, action: PayloadAction<string>) => {
      state.websocketroomId = action.payload;
    })
});

export const { messagesChatList, messagesChatMessage, messagesRecords, statusChanged } = chatSlice.actions;

export const { reducer } = chatSlice;
