import styled from 'styled-components';
import { DURATION, FONT_S, FONT_XS, HEIGHT_INPUT, HEIGHT_INPUT_M, RADIUS_M, SHADOW_INPUT, SHADOW_INPUT_HOGER } from '../../../../styles';
import { IInputStyles } from '../../Input/ui/Input.styled';

export const InputCardGroupWrapper = styled.div<IInputStyles>`
  max-width: 100%;
  min-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  max-height: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? HEIGHT_INPUT : HEIGHT_INPUT_M;
        case 'm':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        case 's':
          return props.size ? HEIGHT_INPUT_M : HEIGHT_INPUT_M;
        default:
          return HEIGHT_INPUT_M;
      }
    } else {
      return HEIGHT_INPUT_M;
    }
  }};
  width: 100%;
  padding-top: 0.2rem;
  align-items: center;
  position: relative;
  height: auto;
  transition: box-shadow ${DURATION} ease-in-out;
  box-shadow: ${SHADOW_INPUT};
  &:hover {
    box-shadow: ${SHADOW_INPUT_HOGER};
  }
  border-radius: ${RADIUS_M};
  padding-inline: 1rem;
  background: ${(props) => props.theme.color.inverse};
  &:after {
    transition-property: color;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    color: ${(props) => props.theme.color.base03};
    position: absolute;
    inset: 0;
    content: '';
    border-radius: inherit;
    border: 1px solid currentColor;
    pointer-events: none;
  }
  &.focused {
    &:after {
      border-width: 2px;
      color: ${(props) => props.theme.color.primary};
    }
  }
`;
export const InputCardWrapper = styled.label`
  max-width: 20%;
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  > input {
    border: none;
    inset: 0;
    outline: none;
    max-width: 100%;
    width: auto;
    font: ${FONT_S};
    background: transparent;
    color: ${(props) => props.theme.text.text01};
    &:disabled {
      color: ${(props) => props.theme.text.text01};
      cursor: initial;
    }
    &.hidden {
      visibility: hidden;
    }
  }
  > span {
    border: none;
    inset: 0;
    outline: none;
    max-width: 100%;
    width: auto;
    font: ${FONT_S};
    background: transparent;
    color: ${(props) => props.theme.text.text01};
    &:disabled {
      color: ${(props) => props.theme.text.text01};
      cursor: initial;
    }
    &.hidden {
      visibility: hidden;
    }
  }
`;
export const TitleItem = styled.div<IInputStyles>`
  max-width: 20%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.text.text02};
  font: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? FONT_S : FONT_XS;
        case 'm':
          return props.size ? FONT_XS : FONT_XS;
        case 's':
          return props.size ? FONT_XS : FONT_XS;
        default:
          return FONT_XS;
      }
    } else {
      return FONT_XS;
    }
  }};
`;
export const CardItem = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 1rem;
`;
export const ChilrdenSvg = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
`;
