import React from 'react';

export function SearchModalIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.6967 5.1967C7.10322 3.79018 9.01088 3 11 3C12.9891 3 14.8968 3.79018 16.3033 5.1967C17.7098 6.60322 18.5 8.51088 18.5 10.5C18.5 12.1528 17.9545 13.7493 16.9631 15.0489L21.2071 19.2929C21.5976 19.6834 21.5976 20.3166 21.2071 20.7071C20.8166 21.0976 20.1834 21.0976 19.7929 20.7071L15.5489 16.4631C14.2493 17.4545 12.6528 18 11 18C9.01088 18 7.10322 17.2098 5.6967 15.8033C4.29018 14.3968 3.5 12.4891 3.5 10.5C3.5 8.51088 4.29018 6.60322 5.6967 5.1967ZM11 5C9.54131 5 8.14236 5.57946 7.11091 6.61091C6.07946 7.64236 5.5 9.04131 5.5 10.5C5.5 11.9587 6.07946 13.3576 7.11091 14.3891C8.14236 15.4205 9.54131 16 11 16C12.4587 16 13.8576 15.4205 14.8891 14.3891C15.9205 13.3576 16.5 11.9587 16.5 10.5C16.5 9.04131 15.9205 7.64236 14.8891 6.61091C13.8576 5.57946 12.4587 5 11 5Z'
        fill='currentColor'
      />
    </svg>
  );
}
