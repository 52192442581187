import styled from 'styled-components';

export const BotExampleContainer = styled.div`
  height: 99%;
  border-radius: 16px;
  max-width: 310px;
  background: linear-gradient(180deg, #d9d9d9 0%, #737373 100%);
  video {
    width: 100%;
    border-radius: 16px;
  }
`;

export const SuccessCover = styled.div`
  position: relative;
  .lottie {
    position: absolute;
    z-index: 10;
    bottom: 4px;
    border-radius: 16px;
  }
`;

export const CoverText = styled.div`
  position: absolute;
  top: 294px;
  left: 19px;
  width: 280px;
  height: 262px;
  display: flex;
  flex-direction: column;
  gap: 29px;
`;

export const TextItemWrapper = styled.div`
  display: flex;
  gap: 15px;
  svg {
    min-height: 32px;
    min-width: 32px;
  }
  &.middle-item {
    margin-top: 17px;
  }
  .bot-url {
    position: relative;
    z-index: 12;
  }
`;

export const TextItem = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: underline;
  }
`;

export const VerticalWhiteLine = styled.div`
  position: absolute;
  top: 25px;
  width: 1px;
  height: 63px;
  background-color: white;
  left: 15px;
`;

export const VerticalWhiteLine2 = styled.div`
  position: absolute;
  top: 119px;
  width: 1px;
  height: 63px;
  background-color: white;
  left: 15px;
`;
