import React from 'react';

export function ArrowBack() {
  return (
    <svg
      width='28'
      height='29'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0443 3.14301C12.1418 3.24052 12.2192 3.35629 12.272 3.4837C12.3248 3.61111 12.3519 3.74768 12.3519 3.8856C12.3519 4.02351 12.3248 4.16008 12.272 4.28749C12.2192 4.41491 12.1418 4.53067 12.0443 4.62818L6.5061 10.1605H16.4519C17.5091 10.1605 18.5559 10.3687 19.5325 10.7733C20.5092 11.1778 21.3966 11.7708 22.1441 12.5183C22.8917 13.2658 23.4846 14.1532 23.8892 15.1299C24.2937 16.1066 24.5019 17.1534 24.5019 18.2105V24.535C24.5019 24.8135 24.3913 25.0806 24.1944 25.2775C23.9975 25.4744 23.7304 25.585 23.4519 25.585C23.1735 25.585 22.9064 25.4744 22.7095 25.2775C22.5126 25.0806 22.4019 24.8135 22.4019 24.535V18.2105C22.4019 16.6325 21.7751 15.1191 20.6592 14.0032C19.5434 12.8874 18.03 12.2605 16.4519 12.2605H6.5061L12.0443 17.7917C12.1419 17.8891 12.2193 18.0048 12.2722 18.1322C12.3251 18.2596 12.3523 18.3961 12.3524 18.534C12.3526 18.6719 12.3255 18.8085 12.2728 18.936C12.2201 19.0634 12.1429 19.1793 12.0454 19.2768C11.948 19.3744 11.8323 19.4519 11.7049 19.5048C11.5775 19.5576 11.441 19.5849 11.3031 19.585C11.1652 19.5851 11.0286 19.5581 10.9011 19.5054C10.7737 19.4527 10.6579 19.3755 10.5603 19.278L3.2266 11.9525C3.12907 11.855 3.05169 11.7392 2.9989 11.6118C2.94612 11.4844 2.91895 11.3478 2.91895 11.2099C2.91895 11.072 2.94612 10.9354 2.9989 10.808C3.05169 10.6806 3.12907 10.5649 3.2266 10.4673L10.5603 3.14301C10.7571 2.94638 11.024 2.83594 11.3023 2.83594C11.5805 2.83594 11.8474 2.94638 12.0443 3.14301Z'
        fill='#151618'
      />
    </svg>
  );
}
