export const SubscriptionAndBilling = () => {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5 0.0585938C15.9852 0.0585938 18 2.07338 18 4.55859C18 6.77266 16.4009 8.61335 14.2946 8.98865L14.2941 13.5586C14.2941 14.8305 13.2974 15.8696 12.0425 15.9374L11.9118 15.9409H2.38235C1.11047 15.9409 0.0713844 14.9443 0.0035251 13.6893L0 13.5586V12.3012C0 12.0345 0.19729 11.8136 0.454273 11.7771L0.525735 11.7718L2.66965 11.7565L2.67035 1.11742C2.67035 0.849397 2.86952 0.627894 3.12793 0.592838L3.19976 0.588006H11.1176C11.187 0.588006 11.2532 0.601333 11.3139 0.625567C11.9604 0.264142 12.7062 0.0585938 13.5 0.0585938ZM10.0695 1.64624L3.72847 1.64683V11.7494L10.0551 11.7057C10.3245 11.7038 10.548 11.9033 10.5834 12.1629L10.5882 12.2351V13.5586C10.5882 14.2896 11.1808 14.8821 11.9118 14.8821C12.6079 14.8821 13.1785 14.3447 13.2313 13.662L13.2353 13.5586L13.2351 9.05093C11.968 8.97736 10.8415 8.37944 10.0695 7.47095L5.55888 7.47036C5.26649 7.47036 5.02946 7.23333 5.02946 6.94095C5.02946 6.67293 5.22863 6.45142 5.48704 6.41637L5.55888 6.41153L9.3981 6.41175C9.21175 5.99994 9.0856 5.55508 9.03086 5.08839L5.55888 5.08801C5.26649 5.08801 5.02946 4.85098 5.02946 4.55859C5.02946 4.29057 5.22863 4.06907 5.48704 4.03401L5.55888 4.02918L9.03086 4.0288C9.13678 3.12578 9.51008 2.30449 10.0695 1.64624ZM9.52941 12.7673L1.05882 12.8266V13.5586C1.05882 14.2548 1.5963 14.8254 2.27892 14.8781L2.38235 14.8821H9.93106L9.91931 14.8651C9.69522 14.5241 9.55634 14.122 9.53294 13.6893L9.52941 13.5586V12.7673ZM9.26476 9.58801C9.55715 9.58801 9.79417 9.82503 9.79417 10.1174C9.79417 10.3854 9.595 10.6069 9.3366 10.642L9.26476 10.6468H5.55888C5.26649 10.6468 5.02946 10.4098 5.02946 10.1174C5.02946 9.8494 5.22863 9.62789 5.48704 9.59284L5.55888 9.58801H9.26476ZM13.5 1.11742C11.5996 1.11742 10.0588 2.65816 10.0588 4.55859C10.0588 6.45903 11.5996 7.99977 13.5 7.99977C15.4004 7.99977 16.9412 6.45903 16.9412 4.55859C16.9412 2.65816 15.4004 1.11742 13.5 1.11742Z'
        fill='currentColor'
      />
      <path
        d='M12.0156 5.62842V5.29883H12.3354V4.87402H12.0156V4.4126H12.3354V2.83789H13.8003C13.9712 2.83789 14.1258 2.86556 14.2642 2.9209C14.4041 2.97461 14.5189 3.04867 14.6084 3.14307C14.6995 3.23584 14.7695 3.34408 14.8184 3.46777C14.8672 3.58984 14.8916 3.72005 14.8916 3.8584C14.8916 4.14486 14.7931 4.38574 14.5962 4.58105C14.4009 4.77637 14.1356 4.87402 13.8003 4.87402H12.9214V5.29883H14.1567V5.62842H12.9214V6.17285H12.3354V5.62842H12.0156ZM12.9214 4.4126H13.7222C13.8882 4.4126 14.0241 4.36377 14.1299 4.26611C14.2373 4.16846 14.291 4.04069 14.291 3.88281C14.291 3.72493 14.2373 3.59717 14.1299 3.49951C14.0241 3.40186 13.8882 3.35303 13.7222 3.35303H12.9214V4.4126Z'
        fill='currentColor'
      />
    </svg>
  );
};
