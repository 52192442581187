import React from 'react';

export function DoubleCheck() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.2364 6.94081C18.5878 6.58934 18.5878 6.01949 18.2364 5.66802C17.8849 5.31655 17.3151 5.31655 16.9636 5.66802L6.5 16.1316L2.0364 11.668C1.68492 11.3166 1.11508 11.3166 0.763604 11.668C0.412132 12.0195 0.412132 12.5893 0.763604 12.9408L5.8636 18.0408C6.03239 18.2096 6.26131 18.3044 6.5 18.3044C6.7387 18.3044 6.96761 18.2096 7.1364 18.0408L18.2364 6.94081Z'
        fill='currentColor'
      />
      <path
        d='M23.2363 6.94081C23.5878 6.58934 23.5878 6.01949 23.2363 5.66802C22.8849 5.31655 22.315 5.31655 21.9636 5.66802L10.8636 16.768C10.5121 17.1195 10.5121 17.6893 10.8636 18.0408C11.2151 18.3923 11.7849 18.3923 12.1364 18.0408L23.2363 6.94081Z'
        fill='currentColor'
      />
    </svg>
  );
}
