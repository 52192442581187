import React from 'react';

export function FinansIcon() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M17.2 16.5H22.41C22.4812 16.4975 22.5521 16.5109 22.6174 16.5392C22.6828 16.5675 22.7411 16.61 22.788 16.6636C22.8349 16.7172 22.8693 16.7806 22.8887 16.8492C22.908 16.9177 22.9119 16.9898 22.9 17.06C22.6515 18.4444 21.9246 19.6974 20.8462 20.6004C19.7678 21.5033 18.4065 21.9987 17 22H7C5.4087 22 3.88258 21.3679 2.75736 20.2426C1.63214 19.1174 1 17.5913 1 16V8C1 6.4087 1.63214 4.88258 2.75736 3.75736C3.88258 2.63214 5.4087 2 7 2H17C18.4051 2.00089 19.7652 2.49486 20.8434 3.39579C21.9216 4.29672 22.6494 5.54747 22.9 6.93C22.9117 7.00071 22.9078 7.07313 22.8884 7.14215C22.8691 7.21117 22.8349 7.27512 22.7882 7.32947C22.7414 7.38382 22.6834 7.42726 22.618 7.45673C22.5527 7.48619 22.4817 7.50096 22.41 7.5H17C16.3713 7.50118 15.7499 7.63378 15.1755 7.88928C14.6011 8.14478 14.0865 8.51754 13.6646 8.98364C13.2428 9.44974 12.9231 9.99889 12.7259 10.5958C12.5288 11.1928 12.4587 11.8243 12.52 12.45C12.665 13.5826 13.2226 14.622 14.0861 15.3693C14.9496 16.1165 16.0583 16.5191 17.2 16.5Z'
        fill='currentColor'
      />
      <path
        d='M17.0006 9C16.5557 8.99991 16.1163 9.09878 15.7144 9.28944C15.3124 9.48011 14.9578 9.7578 14.6764 10.1024C14.395 10.447 14.1937 10.8499 14.0872 11.2818C13.9807 11.7138 13.9716 12.1641 14.0606 12.6C14.2186 13.29 14.6087 13.9049 15.1657 14.3417C15.7227 14.7786 16.4128 15.011 17.1206 15H22.5006C22.6324 14.9974 22.7581 14.9439 22.8513 14.8507C22.9446 14.7575 22.9981 14.6318 23.0006 14.5V9.5C23.0006 9.36739 22.9479 9.24021 22.8542 9.14645C22.7604 9.05268 22.6332 9 22.5006 9H17.0006ZM17.0006 13C16.8028 13 16.6095 12.9414 16.445 12.8315C16.2806 12.7216 16.1524 12.5654 16.0767 12.3827C16.001 12.2 15.9812 11.9989 16.0198 11.8049C16.0584 11.6109 16.1537 11.4327 16.2935 11.2929C16.4334 11.153 16.6115 11.0578 16.8055 11.0192C16.9995 10.9806 17.2006 11.0004 17.3833 11.0761C17.566 11.1518 17.7222 11.28 17.8321 11.4444C17.942 11.6089 18.0006 11.8022 18.0006 12C18.0006 12.2652 17.8953 12.5196 17.7077 12.7071C17.5202 12.8946 17.2658 13 17.0006 13Z'
        fill='white'
      />
    </svg>
  );
}
