import { ClassificatorsState } from './classificators.interface';
import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { createSlice } from '@reduxjs/toolkit';
import { fetchClassificatorsAsync } from './classificators.actions';

const initialState: ClassificatorsState = {
  classificators: [],
  state: PENDING
};

export const classificatorsSlice = createSlice({
  name: 'classificators',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassificatorsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchClassificatorsAsync.fulfilled, (state, action) => {
        state.classificators = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchClassificatorsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = classificatorsSlice;
