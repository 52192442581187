import React from 'react';

export function EditHumanIcon() {
  return (
    <svg
      width='20'
      height='22'
      viewBox='0 0 20 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.8993 10.1519C17.3685 9.68269 18.1293 9.68269 18.5985 10.1519L19.448 11.0016C19.9172 11.4708 19.9172 12.2316 19.448 12.7008L18.5985 13.5504L16.0498 11.0016L16.8993 10.1519Z'
        fill='currentColor'
      />
      <path
        d='M14.8729 12.1776L17.4216 14.7265L12.2164 19.9321C11.6888 20.4597 11.0457 20.8573 10.3379 21.0932L8.275 21.7809C7.99322 21.8748 7.72514 21.6067 7.81907 21.3249L8.5067 19.2619C8.74263 18.554 9.14012 17.9109 9.66768 17.3832L14.8729 12.1776Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.99988 2C7.36371 2 6.0683 3.3222 6.0683 5C6.0683 6.6778 7.36371 8 8.99988 8C10.6772 8 12.0683 6.63665 12.0683 5C12.0683 3.36334 10.6772 2 8.99988 2ZM4.2683 5C4.2683 2.36998 6.32822 0.199997 8.99988 0.199997C11.6304 0.199997 13.8683 2.32883 13.8683 5C13.8683 7.67116 11.6304 9.8 8.99988 9.8C6.32822 9.8 4.2683 7.63002 4.2683 5ZM3.93935 14.4001C2.69089 15.0474 1.983 15.9389 1.82697 17.032C1.7674 17.4493 1.79914 17.9414 1.97167 18.2855C2.0504 18.4425 2.15238 18.5582 2.2859 18.6394C2.42007 18.7211 2.6387 18.8 2.99984 18.8L5.39984 18.8C5.8969 18.8 6.29984 19.2029 6.29984 19.7C6.29984 20.197 5.8969 20.6 5.39984 20.6L2.99984 20.6C2.36687 20.6 1.81207 20.4583 1.34989 20.1769C0.887059 19.8952 0.569754 19.5053 0.362656 19.0924C-0.0364533 18.2965 -0.0425298 17.391 0.0450295 16.7776C0.304467 14.9601 1.49595 13.6394 3.11086 12.8021C4.3461 12.1617 5.8614 11.7817 7.5138 11.642C8.00059 11.6008 8.49928 11.5804 9.00622 11.5804L10.5053 11.636C11.0671 11.636 11.4552 12.0701 11.4053 12.6037C11.3553 13.1373 11.0019 13.4838 10.5053 13.448L9.00622 13.3804C8.4894 13.3804 7.99079 13.4037 7.5138 13.4491C6.10453 13.5833 4.88389 13.9104 3.93935 14.4001Z'
        fill='currentColor'
      />
    </svg>
  );
}
