export function ArrowDownToSquareOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.8973 2.9C12.8973 2.40295 12.4943 2 11.9973 2C11.5002 2 11.0973 2.40294 11.0973 2.9L11.0972 13.9268L9.4366 12.264C9.08535 11.9123 8.5155 11.9119 8.1638 12.2632C7.81211 12.6144 7.81174 13.1843 8.16298 13.536L11.3604 16.7375C11.5291 16.9065 11.7581 17.0014 11.9969 17.0015C12.2356 17.0016 12.4647 16.9068 12.6335 16.738L15.836 13.5365C16.1876 13.185 16.1877 12.6152 15.8362 12.2637C15.4848 11.9121 14.915 11.9121 14.5634 12.2635L12.8972 13.9292L12.8973 2.9Z'
        fill='currentColor'
      />
      <path
        d='M16.1399 6.99998C16.5898 6.99998 16.7462 7.00118 16.8681 7.01383C18.092 7.14066 19.0594 8.10807 19.1863 9.33196C19.1989 9.45395 19.2001 9.61026 19.2001 10.0602V15.5C19.2001 16.4949 19.1994 17.187 19.1554 17.7255C19.1123 18.2535 19.0321 18.5541 18.9167 18.7804C18.6675 19.2696 18.2697 19.6673 17.7805 19.9166C17.5542 20.0319 17.2536 20.1122 16.7256 20.1553C16.1872 20.1993 15.4951 20.2 14.5001 20.2H9.50232C8.50738 20.2 7.8153 20.1993 7.27682 20.1553C6.74884 20.1122 6.44827 20.0319 6.22195 19.9166C5.73272 19.6673 5.33497 19.2696 5.0857 18.7804C4.97039 18.5541 4.89015 18.2535 4.84702 17.7255C4.80302 17.187 4.80232 16.4949 4.80232 15.5V9.68871C4.80232 9.60199 4.80237 9.57183 4.80284 9.54821C4.83063 8.15281 5.95516 7.02828 7.35055 7.00049C7.37418 7.00002 7.40433 6.99998 7.49106 6.99998H8.09581C8.59286 6.99998 8.99581 6.59703 8.99581 6.09998C8.99581 5.60292 8.59286 5.19998 8.09581 5.19998H7.4806C7.40829 5.19997 7.35867 5.19997 7.31471 5.20085C4.95328 5.24788 3.05022 7.15093 3.00319 9.51237C3.00232 9.55634 3.00232 9.60593 3.00232 9.67825V15.5384C3.00231 16.4859 3.0023 17.2517 3.05299 17.8721C3.10523 18.5114 3.21573 19.0752 3.48189 19.5976C3.90373 20.4255 4.57685 21.0986 5.40476 21.5204C5.92713 21.7866 6.49095 21.8971 7.13024 21.9493C7.75066 22 8.51641 22 9.46391 22H14.5385C15.486 22 16.2518 22 16.8722 21.9493C17.5115 21.8971 18.0753 21.7866 18.5977 21.5204C19.4256 21.0986 20.0987 20.4255 20.5206 19.5976C20.7867 19.0752 20.8972 18.5114 20.9495 17.8721C21.0001 17.2517 21.0001 16.4859 21.0001 15.5384L21.0001 10.0059C21.0002 9.63081 21.0002 9.37347 20.9767 9.14642C20.762 7.07521 19.1249 5.43806 17.0537 5.22341C16.8266 5.19988 16.5693 5.19992 16.1942 5.19997L15.8945 5.19998C15.3975 5.19998 14.9945 5.60292 14.9945 6.09998C14.9945 6.59703 15.3975 6.99998 15.8945 6.99998H16.1399Z'
        fill='currentColor'
      />
    </svg>
  );
}
