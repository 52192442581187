export const SHADOW_MODAL = '0 1.125rem 1.875rem rgba(0, 0, 0, .48)';
export const SHADOW = '0 .25rem 1.5rem rgba(0, 0, 0, .12)';
export const SHADOW_HOVER = '0 .75rem 2.25rem rgba(0, 0, 0, .2)';
export const SHADOW_SIDEBAR = '.25rem 0 1.5rem rgba(0, 0, 0, .12)';
export const SHADOW_NAVIGATION = '0 .125rem 1rem rgba(0, 0, 0, .08)';
export const SHADOW_MODAL_MOBILE = '0 -1rem 1.75rem rgba(0, 0, 0, .24)';

export const SHADOW_INPUT = '0 0.125rem 0.1875rem #0000001a';

export const SHADOW_INPUT_HOGER = '0 0.125rem 0.3125rem #00000029';
export const SHADOW_DROPDOWN = '0 1.5rem 1rem rgba(0, 0, 0, .03), 0 .75rem .75rem rgba(0, 0, 0, .04), 0 .25rem .375rem rgba(0, 0, 0, .05)';
