import styled from 'styled-components';

interface IStyledHTagProps {
  fontFamily?: string;
  fontWeight?: 400 | 600 | 700;
  color?: 'avatarGrey' | 'primary' | 'title' | 'white' | 'black' | 'grey' | 'greySecondary' | 'red' | 'grey73' | 'grey97' | 'green';
  $tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  $customSize?: 't10' | 't12' | 't14' | 't16' | 't18' | 't24' | 't28';
}

export const StyledText = styled.span<IStyledHTagProps>`
  font-family: ${({ fontFamily }) => fontFamily || 'inherit'};
  margin: 0;
  color: ${(props) => {
    if (props.color !== undefined) {
      switch (props.color) {
        case 'avatarGrey':
          return props.color ? props.theme.newColor.grey06 : props.theme.newText.textMain;
        case 'primary':
          return props.color ? props.theme.newColor.primary : props.theme.newText.textMain;
        case 'white':
          return props.color ? props.theme.newColor.base01 : props.theme.newText.textMain;
        case 'black':
          return props.color ? props.theme.newText.textMain : props.theme.newText.textMain;
        case 'grey':
          return props.color ? props.theme.newText.textGrey : props.theme.newText.textMain;
        case 'greySecondary':
          return props.color ? props.theme.newText.textGreySecondary : props.theme.newText.textMain;
        case 'grey73':
          return props.color ? props.theme.newText.textGrey73 : props.theme.newText.textMain;
        case 'red':
          return props.color ? props.theme.newText.textRed : props.theme.newText.textMain;
        case 'title':
          return props.color ? props.theme.newText.textTitle : props.theme.newText.textMain;
        case 'grey97':
          return props.color ? props.theme.newColor.grey06 : props.theme.newText.textMain;
        case 'green':
          return props.color ? props.theme.newColor.green : props.theme.newText.textMain;
        default:
          return props.theme.newText.textMain;
      }
    } else {
      return props.theme.newText.textMain;
    }
  }};
  font-size: ${(props) => {
    if (props.$tag !== undefined && props.$tag !== 'p' && props.$tag !== 'span' && !props.$customSize) {
      switch (props.$tag) {
        case 'h1':
          return props.$tag ? '28px' : '20px';
        case 'h2':
          return props.$tag ? '24px' : '20px';
        case 'h3':
          return props.$tag ? '24px' : '20px';
        case 'h4':
          return props.$tag ? '18px' : '20px';
        case 'h5':
          return props.$tag ? '18px' : '20px';
        case 'h6':
          return props.$tag ? '18px' : '20px';
        default:
          return '20px';
      }
    } else if (props.$customSize !== undefined) {
      switch (props.$customSize) {
        case 't28':
          return props.$customSize ? '28px' : '14px';
        case 't24':
          return props.$customSize ? '24px' : '14px';
        case 't18':
          return props.$customSize ? '18px' : '14px';
        case 't16':
          return props.$customSize ? '16px' : '14px';
        case 't14':
          return props.$customSize ? '14px' : '14px';
        case 't12':
          return props.$customSize ? '12px' : '14px';
        case 't10':
          return props.$customSize ? '10px' : '14px';
        default:
          return '14px';
      }
    } else {
      return '14px';
    }
  }};
  font-weight: ${(props) => {
    if (props.$tag !== undefined && !props.fontWeight) {
      switch (props.$tag) {
        case 'h1':
          return props.$tag ? 700 : 600;
        case 'h2':
          return props.$tag ? 700 : 600;
        case 'h3':
          return props.$tag ? 700 : 600;
        case 'h4':
          return props.$tag ? 700 : 600;
        case 'h5':
          return props.$tag ? 600 : 600;
        case 'h6':
          return props.$tag ? 600 : 600;
        default:
          return props.fontWeight || 400;
      }
    } else {
      return props.fontWeight || 400;
    }
  }};
  line-height: ${(props) => {
    if (props.$tag !== undefined && props.$customSize !== 't10') {
      switch (props.$tag) {
        case 'h1':
          return props.$tag ? '1.5' : '1.2';
        case 'h2':
          return props.$tag ? '1.5' : '1.2';
        case 'h3':
          return props.$tag ? '1.5' : '1.2';
        case 'h4':
          return props.$tag ? '1.5' : '1.2';
        case 'h5':
          return props.$tag ? '1.5' : '1.2';
        case 'h6':
          return props.$tag ? '1.5' : '1.2';
        default:
          return '1.5';
      }
    } else if (props.$customSize === 't10') {
      return '100%';
    } else {
      return 'normal';
    }
  }};
  &.clients-fio {
    line-height: 18px;
  }
  &.clients-tel {
    line-height: 17px;
  }
  &.clients-lesson {
    line-height: 17px;
    border-radius: 4px;
    padding: 3px 10px;
    background-color: ${(props) => props.theme.newColor.base05};
  }
  &.clients-time {
    margin-right: 12px;
  }
  &.messenger-avatar {
    color: ${(props) => props.theme.newColor.grey06};
  }
  &.client-empty {
    color: ${(props) => props.theme.color.gray2A};
  }
  &.filtered-client-empty {
    line-height: 38px;
    color: ${(props) => props.theme.color.gray2A};
  }
`;
