import React from 'react';

export function LogoIcon() {
  return (
    <svg
      width='109'
      height='18'
      viewBox='0 0 109 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.7335 0.346154V3.60989H8.06044V17.6538H4.64835V3.60989H0V0.346154H12.7335Z'
        fill='#292F51'
      />
      <path
        d='M19.2867 14.3901H26.5806V17.6538H15.8746V0.346154H26.457V3.60989H19.2867V7.29396H25.8389V10.5082H19.2867V14.3901Z'
        fill='#292F51'
      />
      <path
        d='M33.6341 14.3901H40.2357V17.6538H30.222V0.346154H33.6341V14.3901Z'
        fill='#292F51'
      />
      <path
        d='M46.8225 14.3901H54.1165V17.6538H43.4104V0.346154H53.9928V3.60989H46.8225V7.29396H53.3747V10.5082H46.8225V14.3901Z'
        fill='#292F51'
      />
      <path
        d='M68.2166 8.67857C69.7331 9.5522 70.4914 10.8709 70.4914 12.6346C70.4914 14.1016 69.9721 15.3049 68.9337 16.2445C67.8952 17.1841 66.6177 17.6538 65.1013 17.6538H57.7579V0.346154H64.582C66.0655 0.346154 67.31 0.807692 68.3155 1.73077C69.3375 2.63736 69.8485 3.79945 69.8485 5.21703C69.8485 6.6511 69.3046 7.80495 68.2166 8.67857ZM64.582 3.53571H61.1699V7.34341H64.582C65.1095 7.34341 65.5463 7.16209 65.8925 6.79945C66.2551 6.43681 66.4364 5.98352 66.4364 5.43956C66.4364 4.8956 66.2633 4.44231 65.9172 4.07967C65.571 3.71703 65.126 3.53571 64.582 3.53571ZM65.1013 14.4643C65.6782 14.4643 66.1562 14.2747 66.5353 13.8956C66.9144 13.5 67.104 13.0055 67.104 12.4121C67.104 11.8352 66.9144 11.3571 66.5353 10.978C66.1562 10.5824 65.6782 10.3846 65.1013 10.3846H61.1699V14.4643H65.1013Z'
        fill='#292F51'
      />
      <path
        d='M88.5277 15.4038C86.7804 17.1346 84.6541 18 82.1486 18C79.6431 18 77.5167 17.1346 75.7694 15.4038C74.0387 13.6566 73.1733 11.522 73.1733 9C73.1733 6.47802 74.0387 4.35165 75.7694 2.62088C77.5167 0.873626 79.6431 0 82.1486 0C84.6541 0 86.7804 0.873626 88.5277 2.62088C90.2749 4.35165 91.1486 6.47802 91.1486 9C91.1486 11.522 90.2749 13.6566 88.5277 15.4038ZM78.1678 13.0797C79.2392 14.1346 80.5661 14.6621 82.1486 14.6621C83.731 14.6621 85.0579 14.1346 86.1293 13.0797C87.2008 12.0082 87.7365 10.6484 87.7365 9C87.7365 7.35165 87.2008 5.99176 86.1293 4.92033C85.0579 3.8489 83.731 3.31319 82.1486 3.31319C80.5661 3.31319 79.2392 3.8489 78.1678 4.92033C77.0964 5.99176 76.5606 7.35165 76.5606 9C76.5606 10.6484 77.0964 12.0082 78.1678 13.0797Z'
        fill='#292F51'
      />
      <path
        d='M104.797 0.346154H108.209V17.6538H105.613L98.1951 7.07143V17.6538H94.783V0.346154H97.3791L104.797 10.9038V0.346154Z'
        fill='#292F51'
      />
    </svg>
  );
}
