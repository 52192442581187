export function ArrowUturnLeftOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2364 3.2636C10.5878 3.61508 10.5878 4.18492 10.2364 4.5364L7.67277 7.1H12C15.2585 7.1 17.9 9.74152 17.9 13C17.9 16.2585 15.2585 18.9 12 18.9H7.89998C7.40292 18.9 6.99998 18.4971 6.99998 18C6.99998 17.5029 7.40292 17.1 7.89998 17.1H12C14.2643 17.1 16.1 15.2644 16.1 13C16.1 10.7356 14.2643 8.9 12 8.9H7.67278L10.2364 11.4636C10.5878 11.815 10.5878 12.3849 10.2364 12.7364C9.8849 13.0878 9.31505 13.0878 8.96358 12.7364L4.86358 8.6364C4.6948 8.46762 4.59998 8.2387 4.59998 8C4.59998 7.76131 4.6948 7.53239 4.86358 7.3636L8.96358 3.2636C9.31505 2.91213 9.8849 2.91213 10.2364 3.2636Z'
        fill='currentColor'
      />
    </svg>
  );
}
