import { styled } from 'styled-components';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const Wrapper = styled(FlexWithAlign)`
  padding: 24px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const BtnsWrap = styled(FlexWithAlign)`
  margin-top: 64px;
`;
