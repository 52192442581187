import React from 'react';

import {
  getRouteCalendarEmployee,
  getRouteClient,
  getRouteEmployeesSummary,
  getRouteProfile,
  getRouteSales,
  getRouteSettings
} from '../../../../shared/const';
import { useTypedSelector } from '../../../../shared/lib/hooks/useTypedSelector';
import { useFilialQuery } from '../../../../store/redux/filial/hooks/useFilialQuery';
import { useProfileUserQuery } from '../../../../store/redux/user/hooks/useUsersQuery';
import { getCookie } from '../../../../utils/cookies';
import { EIcon, IconNew as IconInstance } from '../../../icons/medium-new-icons/icon';

export function useSubmenuData() {
  const { websocketroomId, userChats } = useTypedSelector((state) => state.chat);
  const { data: profileUser } = useProfileUserQuery(getCookie('id'));
  const { data: activeFilial } = useFilialQuery();
  const isTechSupport = profileUser?.Email === 'hello@telebon.ru' && activeFilial?.name === 'Служба Поддержки';
  const chatPath = isTechSupport
    ? websocketroomId
      ? websocketroomId
      : 'telebot'
    : userChats.find((chat) => chat.chat_id === websocketroomId)?.is_tech_support
    ? 'support'
    : websocketroomId
    ? websocketroomId
    : 'support';

  const submenu = [
    {
      title: 'Расписание',
      icon: <IconInstance name={EIcon.appointments} />,
      to: getRouteCalendarEmployee(),
      tool: 'Расписание'
    },
    {
      title: 'Продажи',
      icon: <IconInstance name={EIcon.payments} />,
      to: getRouteSales(),
      tool: 'Продажи'
    },
    {
      title: 'Клиенты',
      icon: <IconInstance name={EIcon.clients} />,
      to: getRouteClient(),
      tool: 'Клиенты'
    },
    {
      title: 'Товары',
      icon: <IconInstance name={EIcon.products} />,
      to: '/products',
      tool: 'Товары'
    },
    {
      title: 'Чаты',
      icon: <IconInstance name={EIcon.chats} />,
      to: `/messenger/${chatPath}`,
      tool: 'Чаты'
    },

    {
      title: 'Сотрудники ресурсы',
      icon: <IconInstance name={EIcon.employees} />,
      to: getRouteEmployeesSummary(),
      endTab: true,
      tool: 'Сотрудники и ресурсы'
    },
    {
      title: 'Профиль',
      icon: <IconInstance name={EIcon.profile} />,
      to: getRouteProfile(),
      tool: 'Профиль'
    },
    {
      title: 'Настройки',
      icon: <IconInstance name={EIcon.setting} />,
      to: getRouteSettings(),
      tool: 'Настройки'
    }
  ];

  return { submenu };
}
