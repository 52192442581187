import styled from 'styled-components';
import { MODAL_GAP } from '../../../../../../styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  //max-width: 482px;
  margin: 0 auto;
  width: 100%;
  gap: ${MODAL_GAP};
  //.kassa .custom-select__value-container.custom-select__value-container--has-value {
  //  left: 26px;
  //}
  //.kassa > div:first-child {
  //  left: 41px;
  //}
  .icon-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    left: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  &.info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(240 / 934 * 100%);
    flex-shrink: 0;
  }
  &.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(670 / 934 * 100%);
  }
`;

export const FormItem = styled.div`
  padding: 10px 18px;
  display: flex;
  gap: 12px;
  align-items: center;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.color.base03};
  & > div {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  label {
    padding: 0;
    position: initial;
    width: fit-content;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: ${(props) => props.theme.color.mainDark};
    &.active {
      color: ${(props) => props.theme.color.mainDark};
      & ~ div svg {
        color: inherit;
      }
    }
    & ~ div svg {
      opacity: 0.5;
    }
  }
  //input {
  //  padding-left: 0;
  //  border: none;
  //  height: 16px;
  //  color: #292f51;
  //  font-size: 14px;
  //  line-height: 16px;
  //  letter-spacing: 0.14px;
  //  &:focus::placeholder {
  //    opacity: 0;
  //  }
  //}
`;

export const ButtonInner = styled.div`
  display: flex;
  gap: 12px;
`;
