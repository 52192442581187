import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import { IProps } from './Dropdown.props';
import { Label, WrapperDropdown } from './Dropdown.styled';

import { EIcon, IconNew as IconInstance } from '../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../lib';
import { ErrorText } from '../../Input/ui/Input.styled';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator
      className={'dropdownArrow'}
      {...props}
    >
      <IconInstance name={EIcon.chevrondownsmall} />
    </components.DropdownIndicator>
  );
};

const Dropdown: React.FC<IProps> = ({
  additionalProps,
  getOptionLabel,
  labelTitle,
  currentValue,
  position,
  options,
  size,
  $width,
  error = null,
  className,
  disabled,
  onChange,
  placeholder,
  children,
  isReset
}) => {
  const { t } = useTranslation();
  const [uniqueId] = useState(() => 'select_' + Math.random().toFixed(5).slice(2));
  const transformedList = options?.map((option) => {
    if (option.label && (option.value || option.value === 0)) {
      return option;
    } else {
      return { value: option.id, label: option.name || option.title || option?.name || option?.fio, additional: option };
    }
  });

  const selectRef = useRef<any | null>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(
    !!transformedList?.find((option) => option.value === currentValue || option.label === currentValue)
  );

  const closeMenuHandler = () => {
    const menuEl = document.querySelector<HTMLElement>(`#${uniqueId} .custom-select__menu`);
    const containerEl = menuEl?.parentElement;
    const clonedMenuEl = menuEl?.cloneNode(true) as HTMLElement;

    if (!clonedMenuEl) return;

    clonedMenuEl.classList.add('menu--close');
    clonedMenuEl.addEventListener('animationend', () => {
      containerEl?.removeChild(clonedMenuEl);
    });

    containerEl?.appendChild(clonedMenuEl!);
  };

  useEffect(() => {
    setIsFilled(!!transformedList?.find((option) => option.value === currentValue || option.label === currentValue));
  }, [currentValue, transformedList]);

  useEffect(() => {
    if (selectRef.current && isReset) {
      const currValue = selectRef.current.getValue();
      if (
        currValue &&
        currValue.length > 0 &&
        currValue[0]?.additional?.value &&
        currentValue.length > 0 &&
        !currentValue.find((item: any) => item.val === currValue[0]?.additional?.value)
      ) {
        selectRef.current.setValue(null);
      }
    }
  }, [currentValue]);

  return (
    <WrapperDropdown
      size={size}
      className={cn(isFilled && 'filled', isFocused && 'focused', { error: !!error }, className)}
      $width={$width}
    >
      <Label size={size}>{labelTitle}</Label>
      <Select
        ref={selectRef}
        id={uniqueId}
        options={transformedList}
        value={transformedList?.find((option) => option.value === currentValue || option.label === currentValue)}
        placeholder={placeholder ? placeholder : `${t('Выбрать')}...`}
        classNamePrefix={cn('custom-select')}
        onChange={onChange}
        menuPlacement={position === 'top' ? 'top' : 'auto'}
        minMenuHeight={200}
        maxMenuHeight={200}
        className={className}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMenuClose={() => closeMenuHandler()}
        components={{ DropdownIndicator }}
        isSearchable={false}
        noOptionsMessage={() => t('Нет доступных опций')}
        getOptionLabel={getOptionLabel as (option: any) => string}
        isDisabled={disabled}
        {...additionalProps}
      />

      {children && <div className='additionalSvg'>{children}</div>}
      {error ? <ErrorText>{error}</ErrorText> : null}
    </WrapperDropdown>
  );
};

export default Dropdown;
