import styled from 'styled-components';
import { FONT_S, HEIGHT_INPUT_M, MODAL_TEXT_GAP, RADIUS_M } from './assets';
import { DURATION } from './duration';
import { SHADOW_INPUT, SHADOW_INPUT_HOGER } from './shadows';

export const CheckboxGroupWrapper = styled.div`
  max-width: 33rem;
  width: min-content;
  font: ${FONT_S};
  position: relative;
  display: flex;
  height: ${HEIGHT_INPUT_M};
  isolation: isolate;
  box-shadow: ${SHADOW_INPUT};
  border-radius: ${RADIUS_M};
  transition: box-shadow ${DURATION} ease-in-out;
  &:hover {
    box-shadow: ${SHADOW_INPUT_HOGER};
  }
  .checkbox-wrapper {
    display: inline-block;
    vertical-align: top;
    z-index: 1;
    min-width: 140px;
    @media (max-width: 767px) {
      min-width: 110px;
    }
    &:nth-child(n) {
      border-radius: 0;
    }
    &:first-child {
      border-top-left-radius: ${RADIUS_M};
      border-bottom-left-radius: ${RADIUS_M};
    }
    &:last-child {
      border-top-right-radius: ${RADIUS_M};
      border-bottom-right-radius: ${RADIUS_M};
    }
    &:not(:last-child) {
      margin-right: -0.125rem;
    }
    * {
      flex: 1 1 0;
      min-width: 0;
    }
    > label {
      flex-direction: row-reverse;
      padding: 0 0 0 1rem;
      gap: ${MODAL_TEXT_GAP};
      align-items: center;
      display: flex;
      position: relative;
      box-sizing: border-box;
      border-radius: inherit;
      cursor: pointer;
      user-select: none;
      background: ${(props) => props.theme.color.base01};
      color: ${(props) => props.theme.text.text01};
      transition-property: color, background, opacity;
      transition-duration: ${DURATION};
      transition-timing-function: ease-in-out;
      height: 100%;
      width: 100%;
      @media (max-width: 767px) {
        gap: 0;
      }
      &:hover {
        background: ${(props) => props.theme.color.base02};
      }
      > input {
        position: absolute;
        inset: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      &:after {
        color: ${(props) => props.theme.color.base04};
        transition: color ${DURATION} ease-in-out;
        position: absolute;
        inset: 0;
        content: '';
        border-radius: inherit;
        border: 1px solid currentColor;
        pointer-events: none;
        user-select: none;
      }
    }

    button {
      width: 24px;
      max-width: 24px;
      height: 24px;
      position: relative;
      pointer-events: none;
      @media (max-width: 767px) {
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
`;
