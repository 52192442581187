export const NewDownLoadIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6188_19958)'>
        <path
          d='M14.9141 9.66383C15.0421 9.66383 15.1488 9.70649 15.2341 9.79183C15.3195 9.87716 15.3835 9.96249 15.4261 10.0478V14.3998C15.4261 14.6558 15.3301 14.8798 15.1381 15.0718C14.9461 15.2638 14.7221 15.3812 14.4661 15.4238H1.60213C1.34613 15.4238 1.12213 15.3278 0.930125 15.1358C0.738125 14.9438 0.620792 14.7198 0.578125 14.4638V10.1118C0.578125 9.98383 0.631458 9.87716 0.738125 9.79183C0.844792 9.70649 0.962125 9.66383 1.09013 9.66383C1.21813 9.66383 1.32479 9.70649 1.41013 9.79183C1.49546 9.87716 1.53813 9.96249 1.53813 10.0478V14.3998C1.53813 14.4425 1.55946 14.4638 1.60213 14.4638H14.4021C14.4448 14.4638 14.4661 14.4425 14.4661 14.3998V10.1118C14.4661 9.98383 14.5088 9.87716 14.5941 9.79183C14.6795 9.70649 14.7861 9.66383 14.9141 9.66383ZM8.00213 0.511827C8.13013 0.511827 8.24746 0.565161 8.35413 0.671827C8.46079 0.778494 8.51413 0.917161 8.51413 1.08783V9.40783L11.9061 6.33583C11.9915 6.20783 12.1088 6.14383 12.2581 6.14383C12.4075 6.14383 12.5355 6.19716 12.6421 6.30383C12.7488 6.41049 12.8021 6.53849 12.8021 6.68783C12.8021 6.83716 12.7381 6.97583 12.6101 7.10383L8.38613 11.0718C8.25813 11.1572 8.13013 11.1998 8.00213 11.1998C7.87413 11.1998 7.74613 11.1572 7.61813 11.0718L3.39413 7.10383C3.26613 6.97583 3.20213 6.83716 3.20213 6.68783C3.20213 6.53849 3.25546 6.41049 3.36213 6.30383C3.46879 6.19716 3.59679 6.14383 3.74613 6.14383C3.89546 6.14383 4.01279 6.20783 4.09813 6.33583L7.49013 9.40783V1.08783C7.49013 0.917161 7.54346 0.778494 7.65013 0.671827C7.75679 0.565161 7.87413 0.511827 8.00213 0.511827Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_6188_19958'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='matrix(1 0 0 -1 0 16)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
