import React from 'react';

export function WeekIcon() {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.167 29.3335H13.8337C8.80534 29.3335 6.29119 29.3335 4.72909 27.7714C3.16699 26.2093 3.16699 23.6951 3.16699 18.6668V16.0002C3.16699 10.9718 3.16699 8.45769 4.72909 6.89559C6.29119 5.3335 8.80534 5.3335 13.8337 5.3335H19.167C24.1953 5.3335 26.7095 5.3335 28.2716 6.89559C29.8337 8.45769 29.8337 10.9718 29.8337 16.0002V18.6668C29.8337 23.6951 29.8337 26.2093 28.2716 27.7714C27.4007 28.6423 26.2338 29.0277 24.5003 29.1982'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M9.83301 5.3335V3.3335'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M23.167 5.3335V3.3335'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M29.167 12H22.667H14.8337M3.16699 12H8.33366'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M24.4997 22.6667C24.4997 23.403 23.9027 24 23.1663 24C22.43 24 21.833 23.403 21.833 22.6667C21.833 21.9303 22.43 21.3333 23.1663 21.3333C23.9027 21.3333 24.4997 21.9303 24.4997 22.6667Z'
        fill='currentColor'
      />
      <path
        d='M24.4997 17.3333C24.4997 18.0697 23.9027 18.6667 23.1663 18.6667C22.43 18.6667 21.833 18.0697 21.833 17.3333C21.833 16.597 22.43 16 23.1663 16C23.9027 16 24.4997 16.597 24.4997 17.3333Z'
        fill='currentColor'
      />
      <path
        d='M17.8337 22.6667C17.8337 23.403 17.2367 24 16.5003 24C15.7639 24 15.167 23.403 15.167 22.6667C15.167 21.9303 15.7639 21.3333 16.5003 21.3333C17.2367 21.3333 17.8337 21.9303 17.8337 22.6667Z'
        fill='currentColor'
      />
      <path
        d='M17.8337 17.3333C17.8337 18.0697 17.2367 18.6667 16.5003 18.6667C15.7639 18.6667 15.167 18.0697 15.167 17.3333C15.167 16.597 15.7639 16 16.5003 16C17.2367 16 17.8337 16.597 17.8337 17.3333Z'
        fill='currentColor'
      />
      <path
        d='M11.1667 22.6667C11.1667 23.403 10.5697 24 9.83333 24C9.09695 24 8.5 23.403 8.5 22.6667C8.5 21.9303 9.09695 21.3333 9.83333 21.3333C10.5697 21.3333 11.1667 21.9303 11.1667 22.6667Z'
        fill='currentColor'
      />
      <path
        d='M11.1667 17.3333C11.1667 18.0697 10.5697 18.6667 9.83333 18.6667C9.09695 18.6667 8.5 18.0697 8.5 17.3333C8.5 16.597 9.09695 16 9.83333 16C10.5697 16 11.1667 16.597 11.1667 17.3333Z'
        fill='currentColor'
      />
    </svg>
  );
}
