export const capitalizeEveryWord = (str: string): string => {
  return str?.replace(/(^|\s)\S/g, (match: string) => match.toUpperCase());
};

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()/.,#+-])[A-Za-z\d@$!%*?&()/.,#+-]{8,}$/;

export const formatPhoneNumber = (phoneNumber: string) => {
  const numericPhone = phoneNumber.replace(/\D/g, '');

  const formattedPhone = numericPhone.indexOf('+') === 0 ? numericPhone : '+'.concat('', numericPhone);

  const formattedNumber = formattedPhone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3-$4-$5');

  return formattedNumber;
};

// export const pathRegex = /^\/password\/[a-zA-Z0-9_-]+(?:\/[a-zA-Z0-9_-]+)?$/;
export const pathRegex = /^\/password/;
