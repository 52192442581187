import React from 'react';

export function ModuleSettingsIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
    >
      <path
        d='M6.56675 3.9C6.56675 3.40294 6.1638 3 5.66675 3C5.16969 3 4.76675 3.40294 4.76675 3.9V5.09997H3.56675C3.06969 5.09997 2.66675 5.50291 2.66675 5.99997C2.66675 6.49703 3.06969 6.89997 3.56675 6.89997H4.76675V20.1C4.76675 20.5971 5.16969 21 5.66675 21C6.1638 21 6.56675 20.5971 6.56675 20.1V6.89997H7.76675C8.2638 6.89997 8.66675 6.49703 8.66675 5.99997C8.66675 5.50291 8.2638 5.09997 7.76675 5.09997H6.56675V3.9Z'
        fill='currentColor'
      />
      <path
        d='M13.5667 3.90235C13.5667 3.40529 13.1638 3.00235 12.6667 3.00235C12.1697 3.00235 11.7667 3.40529 11.7667 3.90235V13.1H10.5667C10.0697 13.1 9.66675 13.5029 9.66675 14C9.66675 14.4971 10.0697 14.9 10.5667 14.9H11.7667V20.0977C11.7667 20.5947 12.1697 20.9977 12.6667 20.9977C13.1638 20.9977 13.5667 20.5947 13.5667 20.0977V14.9H14.7621C15.2591 14.9 15.6621 14.4971 15.6621 14C15.6621 13.5029 15.2591 13.1 14.7621 13.1H13.5667V3.90235Z'
        fill='currentColor'
      />
      <path
        d='M19.6666 3.00236C20.1636 3.00236 20.5666 3.4053 20.5666 3.90236V9.10001H21.7644C22.2614 9.10001 22.6644 9.50296 22.6644 10C22.6644 10.4971 22.2614 10.9 21.7644 10.9H20.5666V20.1016C20.5666 20.5986 20.1636 21.0016 19.6666 21.0016C19.1695 21.0016 18.7666 20.5986 18.7666 20.1016V10.9H17.569C17.072 10.9 16.669 10.4971 16.669 10C16.669 9.50296 17.072 9.10001 17.569 9.10001H18.7666V3.90236C18.7666 3.4053 19.1695 3.00236 19.6666 3.00236Z'
        fill='currentColor'
      />
    </svg>
  );
}
