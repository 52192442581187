import React from 'react';

export function Services2() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 11.0044H3C2.44772 11.0044 2 11.4521 2 12.0044C2 12.5567 2.44772 13.0044 3 13.0044H21C21.5523 13.0044 22 12.5567 22 12.0044C22 11.4521 21.5523 11.0044 21 11.0044ZM21 4.00439H3C2.44772 4.00439 2 4.45211 2 5.00439C2 5.55668 2.44772 6.00439 3 6.00439H21C21.5523 6.00439 22 5.55668 22 5.00439C22 4.45211 21.5523 4.00439 21 4.00439ZM3 20.0044H13C13.5523 20.0044 14 19.5567 14 19.0044C14 18.4521 13.5523 18.0044 13 18.0044H3C2.44772 18.0044 2 18.4521 2 19.0044C2 19.5567 2.44772 20.0044 3 20.0044Z'
        fill='currentColor'
      />
    </svg>
  );
}
