export const EmptyPhoto = () => {
  return (
    <svg
      width='33'
      height='26'
      viewBox='0 0 33 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2680_13645)'>
        <path
          d='M16.98 9.9C15.6466 9.9 14.5133 10.35 13.58 11.25C12.6466 12.15 12.18 13.25 12.18 14.55C12.18 15.85 12.6466 16.95 13.58 17.85C14.5133 18.75 15.6466 19.2167 16.98 19.25C18.3133 19.2833 19.4466 18.8333 20.38 17.9C21.3133 16.9667 21.78 15.85 21.78 14.55C21.78 13.25 21.3133 12.15 20.38 11.25C19.4466 10.35 18.3133 9.9 16.98 9.9ZM29.78 5.2H25.98C25.7133 5.2 25.4466 5.11667 25.18 4.95C24.9133 4.78333 24.7466 4.56667 24.68 4.3L23.68 1.4C23.6133 1.13333 23.4466 0.916666 23.18 0.75C22.9133 0.583332 22.6466 0.5 22.38 0.5H11.58C11.3133 0.5 11.0466 0.583332 10.78 0.75C10.5133 0.916666 10.3466 1.13333 10.28 1.4L9.27998 4.3C9.21331 4.56667 9.04665 4.78333 8.77998 4.95C8.51331 5.11667 8.24665 5.2 7.97998 5.2H4.17998C3.31331 5.2 2.56331 5.5 1.92998 6.1C1.29665 6.7 0.97998 7.43333 0.97998 8.3V22.4C0.97998 23.2667 1.29665 24 1.92998 24.6C2.56331 25.2 3.31331 25.5 4.17998 25.5H29.78C30.6466 25.5 31.3966 25.2 32.03 24.6C32.6633 24 32.98 23.2667 32.98 22.4V8.3C32.98 7.43333 32.6633 6.7 32.03 6.1C31.3966 5.5 30.6466 5.2 29.78 5.2ZM16.98 22.4C15.5133 22.4 14.18 22.05 12.98 21.35C11.78 20.65 10.8133 19.7 10.08 18.5C9.34665 17.3 8.97998 15.9833 8.97998 14.55C8.97998 13.1167 9.34665 11.8167 10.08 10.65C10.8133 9.48333 11.78 8.53333 12.98 7.8C14.18 7.06667 15.5133 6.7 16.98 6.7C18.4466 6.7 19.78 7.06667 20.98 7.8C22.18 8.53333 23.1466 9.48333 23.88 10.65C24.6133 11.8167 24.98 13.1167 24.98 14.55C24.98 15.9833 24.6133 17.3 23.88 18.5C23.1466 19.7 22.18 20.65 20.98 21.35C19.78 22.05 18.4466 22.4 16.98 22.4ZM28.68 10.5C28.3466 10.5 28.08 10.4 27.88 10.2C27.68 10 27.58 9.75 27.58 9.45C27.58 9.15 27.68 8.88333 27.88 8.65C28.08 8.41667 28.33 8.3 28.63 8.3C28.93 8.3 29.1966 8.41667 29.43 8.65C29.6633 8.88333 29.78 9.15 29.78 9.45C29.78 9.75 29.6633 10 29.43 10.2C29.1966 10.4 28.9466 10.5 28.68 10.5Z'
          fill='#BCBCC2'
        />
      </g>
      <defs>
        <clipPath id='clip0_2680_13645'>
          <rect
            width='32'
            height='25'
            fill='white'
            transform='matrix(1 0 0 -1 0.97998 25.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
