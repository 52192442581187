import styled from 'styled-components';

import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    color: #151618;
  }
  .top-wrapper {
    padding: 0 6.6px;
    width: 100%;
    gap: 22px;
  }
  &.top {
    margin-top: 0;
  }
  .form-wrapper {
    gap: 22px;
  }
  .names-container {
    padding-top: 14px;
  }
`;

export const UserCardWrapper = styled(FlexWithAlign)`
  max-height: 8.875rem;
  height: 142px;

  .note-input {
    height: 142px;
    label {
    }
    input {
      height: 142px;
    }
  }
  .not-trim {
    span {
      font-size: 10px;
      font-weight: 400;
      line-height: 10px;
      text-transform: uppercase;
      color: ${(props) => props.theme.newText.textGrey};
    }
  }
  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  :focus,
  :focus-within span {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    text-transform: uppercase;
    color: ${(props) => props.theme.newText.textGrey};
  }
  :focus,
  :focus-within input {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-transform: none;
    color: ${(props) => props.theme.text.text05};
  }
`;

export const CloseBtn = styled.div`
  padding-top: 2px;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 17px;
`;
