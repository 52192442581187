export function ArrowCalendarPrev() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.2238 6.52754C20.3945 6.52754 20.5651 6.61287 20.7358 6.78354C20.9065 6.9542 20.9918 7.1462 20.9918 7.35954C20.9918 7.57287 20.9065 7.76487 20.7358 7.93554L12.9278 16.1275L20.7358 24.4475C20.9065 24.5329 20.9918 24.6822 20.9918 24.8955C20.9918 25.1089 20.9065 25.3009 20.7358 25.4715C20.5651 25.6422 20.3731 25.7275 20.1598 25.7275C19.9465 25.7275 19.7545 25.6422 19.5838 25.4715L11.2638 16.7675C11.0931 16.5969 11.0078 16.4049 11.0078 16.1915C11.0078 15.9782 11.0931 15.7862 11.2638 15.6155L19.5838 6.78354C19.7545 6.61287 19.9678 6.52754 20.2238 6.52754Z'
        fill='currentColor'
      />
    </svg>
  );
}
