import React, { useEffect, useState } from 'react';

import { useBooleanState } from 'webrix/hooks';

import { ToastBar, Toaster } from 'react-hot-toast';

import { EIcon, IconNew as IconInstance } from '../components/icons/medium-new-icons/icon';

import '../styles/normalize.css';
import '../styles/global.css';
import 'dayjs/locale/ru';
import { AppRouter } from './providers/router';
import { AppContainer, LoadingAnimationStart } from './App.styled';

import OfflinePage from '../pages/OfflinePage/ui/OfflinePage';
import useMatchMedia from '../shared/lib/hooks/useMatchMedia';
import { Portal, Text, TextPType, TextType } from '../shared/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRouteCalendarEmployee } from '../shared/const';

function App() {
  const [loading, setLoading] = useState(false);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    if (pathname === '/') {
      navigate(getRouteCalendarEmployee());
    }
  }, []);
  const { value: online, setFalse: setOffline, setTrue: setOnline } = useBooleanState(navigator.onLine);
  // const previousOnline = usePrevious(online);
  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <>
      {online ? (
        <AppContainer className={`App `}>
          {loading && isMobile ? (
            <Portal>
              <LoadingAnimationStart>
                <div className='wrapp'>
                  <div className='text'>
                    <IconInstance name={EIcon.logo} />
                  </div>
                  <div className='logo'>
                    <IconInstance name={EIcon.logomark} />
                  </div>
                </div>
              </LoadingAnimationStart>
            </Portal>
          ) : (
            <AppRouter />
          )}
          <Portal>
            <Toaster
              toastOptions={{
                position: isMobile ? 'top-center' : 'top-right',
                success: {
                  icon: <IconInstance name={EIcon.notifysuccess} />,
                  className: 'notify'
                },
                error: {
                  icon: <IconInstance name={EIcon.notifyerror} />,
                  className: 'notify error'
                }
              }}
            >
              {(t) => (
                <div className='toast-container'>
                  <ToastBar toast={t}>
                    {({ icon, message }) => (
                      <>
                        <div className={'notify-icon'}>{icon}</div>
                        <Text
                          small
                          $tag={TextType.P}
                          $pSize={TextPType.P13}
                        >
                          {message}
                        </Text>
                      </>
                    )}
                  </ToastBar>
                </div>
              )}
            </Toaster>
          </Portal>
        </AppContainer>
      ) : (
        <OfflinePage />
      )}
    </>
  );
}

export default App;
