import React from 'react';

export function UserOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13 1H11C10.4477 1 10 1.44772 10 2V4C10 4.55228 10.4477 5 11 5H13C13.5523 5 14 4.55228 14 4V2C14 1.44772 13.5523 1 13 1Z'
        fill='white'
      />
      <path
        opacity='0.5'
        d='M21 7V19C21 20.0609 20.5786 21.0783 19.8284 21.8284C19.0783 22.5786 18.0609 23 17 23H7C5.93913 23 4.92172 22.5786 4.17157 21.8284C3.42143 21.0783 3 20.0609 3 19V7C3 5.93913 3.42143 4.92172 4.17157 4.17157C4.92172 3.42143 5.93913 3 7 3H8C8.13261 3 8.25979 3.05268 8.35355 3.14645C8.44732 3.24021 8.5 3.36739 8.5 3.5V4C8.5 4.66304 8.76339 5.29893 9.23223 5.76777C9.70107 6.23661 10.337 6.5 11 6.5H13C13.663 6.5 14.2989 6.23661 14.7678 5.76777C15.2366 5.29893 15.5 4.66304 15.5 4V3.5C15.5 3.36739 15.5527 3.24021 15.6464 3.14645C15.7402 3.05268 15.8674 3 16 3H17C18.0609 3 19.0783 3.42143 19.8284 4.17157C20.5786 4.92172 21 5.93913 21 7Z'
        fill='currentColor'
      />
      <path
        d='M12.1002 13.22C13.2656 13.22 14.2102 12.2753 14.2102 11.11C14.2102 9.94468 13.2656 9 12.1002 9C10.9349 9 9.99023 9.94468 9.99023 11.11C9.99023 12.2753 10.9349 13.22 12.1002 13.22Z'
        fill='white'
      />
      <path
        d='M12.0996 19C14.1707 19 15.8496 17.8807 15.8496 16.5C15.8496 15.1193 14.1707 14 12.0996 14C10.0285 14 8.34961 15.1193 8.34961 16.5C8.34961 17.8807 10.0285 19 12.0996 19Z'
        fill='white'
      />
    </svg>
  );
}
