export function OkBlueWhite() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='15.5'
        fill='white'
      />
      <rect
        x='0.5'
        y='0.5'
        width='31'
        height='31'
        rx='15.5'
        stroke='#EBEBEB'
      />
      <path
        d='M19.2336 13.1448L15.483 17.2754L13.7655 15.3949C13.5862 15.1985 13.3003 15.204 13.1269 15.4072C12.9536 15.6104 12.9584 15.9343 13.1377 16.1306L15.17 18.3562C15.3454 18.5483 15.6238 18.5479 15.7987 18.3552L19.8632 13.8785C20.042 13.6815 20.0461 13.3576 19.8722 13.155C19.6983 12.9524 19.4124 12.9478 19.2336 13.1448Z'
        fill='#2A2C32'
      />
      <mask
        id='mask0_4365_17432'
        maskUnits='userSpaceOnUse'
        x='13'
        y='13'
        width='7'
        height='6'
      >
        <path
          d='M19.2336 13.1448L15.483 17.2754L13.7655 15.3949C13.5862 15.1985 13.3003 15.204 13.1269 15.4072C12.9536 15.6104 12.9584 15.9343 13.1377 16.1306L15.17 18.3562C15.3454 18.5483 15.6238 18.5479 15.7987 18.3552L19.8632 13.8785C20.042 13.6815 20.0461 13.3576 19.8722 13.155C19.6983 12.9524 19.4124 12.9478 19.2336 13.1448Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_4365_17432)'>
        <path
          d='M21.0034 11H11.0034V21H21.0034V11Z'
          fill='#2997FF'
        />
      </g>
      <circle
        cx='16'
        cy='16'
        r='7.5'
        stroke='#2997FF'
      />
    </svg>
  );
}
