import styled from 'styled-components';

import { DURATION, MODAL_TEXT_GAP } from '../../../../styles';
import { fade } from '../../../../utils/styleUtils';

interface CircleProps {
  circleColor?: string;
  sizeGroupAvatar?: string;
}

export const SidebarSlider = styled.div`
  height: 100%;
  min-width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
`;
export const SidebarSliderItem = styled.div`
  background-color: ${(props) => props.theme.color.base01};
  display: flex;
  flex-direction: column;
  grid-row-start: 1;
  grid-column-start: 1;
  overflow: hidden;
  h1 {
    margin: 24px 0 2px 24px;
  }
`;
export const SidebarHeadder = styled.div`
  background-color: ${(props) => props.theme.color.base01};
  display: flex;
  gap: ${MODAL_TEXT_GAP};
  align-items: center;
  justify-content: space-between;
  padding-inline: 24px 18px;
  padding-block: 22px 18px;
  flex: 0 0 auto;
  user-select: none;
  cursor: default;
  @media (max-width: 767px) {
    flex-direction: row-reverse;
    min-height: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }
`;
export const SidebarContent = styled.div`
  justify-content: center;
  display: flex;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  flex: 1 1 auto;
  .chatlist-conteiner {
    max-height: 100%;
    position: relative;
    background-color: ${(props) => props.theme.color.base01};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation-fill-mode: forwards;
    .connection-status-bottom {
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      position: absolute;
      inset: 0;
      overflow: hidden;
      background-color: ${(props) => props.theme.color.base01};
      transform: translateY(92px - 92px);
      height: 100%;
      display: flex;
      flex-direction: column;
      .tabs-container {
        position: relative;
        flex: 1 1 auto;
        min-width: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        .scrollabel {
          display: block;
          overflow-y: auto;
          background-color: transparent;
          position: absolute;
          grid-row-start: 1;
          grid-column-start: 1;
          width: 100%;
          height: 100%;
          &::-webkit-scrollbar {
            width: 4px;
            display: none;
            position: absolute;
          }
          &::-webkit-scrollbar-track {
            display: none;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 12px;
            background-color: #c0c2c5;
            border: 2px solid #c0c2c5;
            transition: opacity 0.1s ease-in-out;
          }
          &:hover {
            padding-right: 0;
            &::-webkit-scrollbar {
              display: block;
            }
          }
        }
      }
    }
  }
`;
export const BtnConteiner = styled.div`
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 2.5rem;
  border-radius: 50%;
  height: 2.5rem;
  flex: 0 0 auto;
  display: flex;
  background-color: ${(props) => props.theme.newColor.primary};
  box-shadow: -1px 14px 20px 0px #0000004d;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    margin-left: auto;
    width: fit-content;
  }
  .sidebar-back-button {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    inset: 0;
    z-index: 2;
    margin: 0;
    margin-inline-start: 0.5rem;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1;
    border-radius: 50%;
    color: #aaaaaa;
    background-color: transparent;
    border: none;
    padding: 0.5rem;
    transition: color 0.15s ease-in-out, opacity 0.15s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MobileBtn = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.theme.text.text01};
  }
`;
export const ChatList = styled.div`
  height: 100%;
  ul {
    /* padding: 0 0.5rem; */
    overflow-y: auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background-color: ${(props) => props.theme.color.elevation01};
  }
`;

export const ChatItem = styled.div`
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: bottom left;
  animation: ${fade} 0.2s ease-in;
  padding-inline-start: 76px !important;
  padding-top: 0;
  border-bottom: 1px solid ${(props) => props.theme.newColor.base08};
  padding-bottom: 0;
  cursor: pointer;
  overflow: hidden;
  min-height: 65px;
  padding-block: 0.5625rem;
  padding-inline: 1rem 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
  background: unset;
  -webkit-user-drag: none;
  transition: background ${DURATION} ease-in-out;
  @media (max-width: 767px) {
    border-bottom: 1px solid ${(props) => props.theme.color.base03};
  }
  &.active {
    background-color: ${(props) => props.theme.color.base04};
  }
  &:not(&.active)&:hover {
    background: ${(props) => props.theme.color.greyBackground};
  }
  .title {
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 auto;
  }
  /* @media (min-width: 600px) {
    border-radius: 10px;
  } */
  > a {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
  &.unread-msg {
    background: ${(props) => props.theme.color.greyBackground};
  }
`;

export const DislogSubtitle = styled.div`
  pointer-events: none;
  position: relative;
  order: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dialog-subtitle-flex {
    margin-top: 0;
    font-size: 16px;
    display: flex;
    position: relative;
    pointer-events: none;
    color: ${(props) => props.theme.text.text02};
    white-space: nowrap;
    > .content {
      flex: unset;
      max-width: none;
      white-space: nowrap;
      font-weight: 400;
      font-size: 10px;
      display: flex;
      height: 18px;
      line-height: 18px;
      color: ${(props) => props.theme.text.text02};
      @media (max-width: 767px) {
        font-weight: 500;
      }
      > div {
        display: flex;
        align-items: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 11px;
        color: ${(props) => props.theme.text.greyFontColor};
        img {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
`;

export const DialogTitle = styled.div`
  pointer-events: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  order: 0;
  .user-title {
    font-size: 14px;
    position: relative;
    display: flex;
    font-weight: 400;
    line-height: 20px;
    align-items: center;
    color: #aaaaaa;
    .peer-title {
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .dialog-title-details {
    margin-top: -4px;
    padding: 5px 12px 0 0;
    position: relative;
    pointer-events: none;
    flex: 0 0 auto;
    .message-unread {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 20px;
      background: ${(props) => props.theme.color.accent};
    }
    p {
      color: #8a8a8a;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
`;

export const DialogAvatar = styled.div`
  pointer-events: none;
  flex: 0 0 auto;
  width: 40px;
  height: 40px;
  inset-inline-start: 24px;
  margin: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.newColor.baseGradient};
  border-radius: 50%;
  line-height: 23px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  user-select: none;
  color: ${(props) => props.theme.newColor.grey06};
  img {
    width: 40px;
    height: 40px;
    border-radius: inherit;
    display: block;
    object-fit: cover;
  }
  &.chat-avatar {
  inset-inline-start: 0px;
    img {
      object-fit: cover;
    }
  }
  &.avatar-msg-side {
    
  inset-inline-start: 0px;
  }
`;

export const Circle = styled.div<CircleProps>`
  position: relative;
  min-width: ${(props: any) => props.sizeGroupAvatar || '33px'};
  max-width: ${(props: any) => props.sizeGroupAvatar || '33px'};
  height: ${(props: any) => props.sizeGroupAvatar || '33px'};
  border: 1px solid transparent;
  border-radius: 100%;
  /* background: ${(props) => props.circleColor || 'transparent'}; */
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0;
    text-align: center;
    user-select: none;
  }
  &:hover {
    border-radius: 12px;
    svg {
      height: 35px;
    }
    .marker {
      height: 16px;
      background-color: #5089fd;
    }
    .chatName {
      display: flex;
    }
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  svg {
    transition: 0.3s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 35px;
  }
  &:first-child {
    svg {
      color: #fff;
    }
  }
  &.active {
    .marker {
      background-color: #5089fd;
      height: 30px;
    }
  }
  .marker {
    position: absolute;
    left: -12px;
    background-color: transparent;
    height: 3px;
    width: 5px;
    border-radius: 0 10px 10px 0;
    transition: height 0.3s ease, background-color 0.3s ease;
    z-index: 10;
  }
  .chatName {
    position: absolute;
    left: 55px;
    background-color: #ffffff;
    height: auto;
    width: auto;
    border-radius: 8px;
    z-index: 2;
    padding: 10px;
    display: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-right-color: #ffffff;
      border-left: 0;
      margin-top: -6px;
      margin-left: -6px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      white-space: nowrap;
    }
  }
`;

export const TableRow = styled.div`
  height: 100%;
  width: 240px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: #f2f3f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #c0c2c5;
    border: 2px solid #c0c2c5;
  }
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const TableBody = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column-reverse;
  flex: 1 1 auto;
  padding-right: 6px;
  border-right: 1px solid ${(props) => props.theme.color.secondaryLightMedium};
  max-width: 344px;
  width: 100%;
  transition: transform 0.2s ease-in-out, filter 0.2s ease-in-out;
  grid-column-start: 1;
  grid-row-start: 1;
  height: 100%;
  position: relative;
  min-height: 100%;
  max-height: 100%;
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    height: 100vh;
    width: 1px;
    inset-inline-end: -1px;
    top: 0;
    z-index: 3;
  }
  @media (max-width: 1600px) {
    max-width: 344px;
  }
  @media (max-width: 1130px) {
    max-width: 290px;
  }
  @media (max-width: 767px) {
    max-width: 250px;
  }
`;
