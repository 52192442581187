import React from 'react';

export function TicketIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.0057 0.10437C14.0209 0.10437 16.9016 3.38268 16.9016 7.63595V9.2356C16.9016 9.4643 17.0948 9.76741 17.6221 10.3114C17.6897 10.3812 17.7534 10.4459 17.8924 10.5867C18.9737 11.6833 19.4016 12.3151 19.4016 13.3316C19.4016 13.7761 19.3497 14.1226 19.1338 14.5378C18.6776 15.4154 17.7105 15.9044 16.2647 15.9044L14.3529 15.9054C13.7237 18.2379 12.2225 19.5046 10.0016 19.5046C7.75696 19.5046 6.24748 18.2108 5.63037 15.8305L5.64956 15.9044H3.7384C2.25045 15.9044 1.27291 15.4021 0.836225 14.4938C0.645269 14.0966 0.601562 13.7741 0.601562 13.3316C0.601562 12.3151 1.02944 11.6833 2.11068 10.5867C2.24975 10.4459 2.31342 10.3812 2.38105 10.3114C2.90831 9.76741 3.10156 9.4643 3.10156 9.2356V7.63595C3.10156 3.38434 5.98928 0.10437 10.0057 0.10437ZM12.4675 15.9062H7.5356C7.99154 17.1529 8.77734 17.7046 10.0016 17.7046C11.2258 17.7046 12.0116 17.1529 12.4675 15.9062ZM10.0057 1.90437C7.03803 1.90437 4.90156 4.33104 4.90156 7.63595V9.2356C4.90156 10.0882 4.51212 10.699 3.67357 11.5642C3.6003 11.6398 3.53115 11.71 3.39244 11.8505C2.64556 12.608 2.40156 12.9683 2.40156 13.3316C2.40156 13.5237 2.41511 13.6236 2.45849 13.7138C2.56578 13.937 2.89147 14.1044 3.7384 14.1044H16.2647C17.0848 14.1044 17.4181 13.9359 17.5368 13.7076C17.5862 13.6125 17.6016 13.5099 17.6016 13.3316C17.6016 12.9683 17.3576 12.608 16.6107 11.8505C16.472 11.71 16.4028 11.6398 16.3296 11.5642C15.491 10.699 15.1016 10.0882 15.1016 9.2356V7.63595C15.1016 4.32872 12.9713 1.90437 10.0057 1.90437Z'
        fill='currentColor'
      />
    </svg>
  );
}
