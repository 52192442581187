import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TarifService } from '../../../../shared/api/model/service/tarif.service';
import { MYBALANCE_QUERY, MYTARIF_QUERY, PAYMENT_HISTORY_QUERY } from '../../../../shared/const/query.const';
import { ISetMyTarif } from '../tarif.interface';

export function useChangeTarif() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ISetMyTarif) => TarifService.setMyTarif(data),

    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [MYTARIF_QUERY] });
      }
    }
  });
}

export function useVerifyPayment() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentId: string) => TarifService.verifyPay(paymentId),

    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [PAYMENT_HISTORY_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [MYBALANCE_QUERY] });
      }
    }
  });
}

export function useApplyPromocode() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (promocode: string) => TarifService.applyPromocode(promocode),

    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [MYTARIF_QUERY] });
      }
    }
  });
}
