import styled, { keyframes } from 'styled-components';
import { FlexWithAlign } from '../../../../../utils/styleUtils';

export const fadeIn = keyframes`
  0% {
    transform: translate(calc((0.375rem + 0.5rem) * 1 * -1)) scale(0);
    opacity: 0;
  }
  100% {
    transform: translate(0) scale(1);
    opacity: 1;
  }
`;

export const fadeInReverse = keyframes`
  0% {
    transform: translate(calc((-0.375rem + 0.5rem) * 1 * -1)) scale(0);
    opacity: 0;
  }
  100% {
    transform: translate(0) scale(1);
    opacity: 1;
  }
`;

export const MessageBubble = styled.div`
  width: 100%;
  margin: 0 auto;
  flex: 1 1 auto;
  position: relative;
  z-index: 1;
  height: 100%;
  transform: translate3d(0, 0, 0);
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  .bubbles-remove-container {
    position: absolute;
    inset: 0;
    pointer-events: none;
    overflow: hidden;
  }
`;
export const Scrollable = styled.div`
  overflow-y: auto;
  height: auto;
  display: block;
  margin: 0 auto;
  padding: 0 4px 0 0;
  overflow-x: hidden;
  max-height: 100%;
  position: absolute;
  inset: 0;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #c0c2c5;
    border: 2px solid #c0c2c5;
    transition: opacity 0.1s ease-in-out;
  }
  -webkit-overflow-scrolling: touch;
  &:hover {
    .scrollable-thumb-container {
      .scrollable-thumb {
        opacity: 1;
      }
    }
  }
  .bubbles-date-group {
    position: relative;
  }
  .telegram {
    position: relative;
  }
`;

export const Wrapper = styled(FlexWithAlign)`
  position: relative;
`;

export const BubblesGroup = styled.div`
  position: relative;
`;

export const TelegramDefaultMessageContainer = styled.div`
  display: flex;
  position: absolute;
  right: 73px;
  top: 188px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1260px) {
    height: fit-content;
  }
  .bot-online {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const RulesForTelegramRegistrationContainer = styled.div`
  display: flex;
  margin-top: 67px;
  flex-direction: column;
  gap: 30px;
  width: 277px;
  height: 156px;
  position: relative;
`;

export const TelegramChatButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 30px;
  margin-top: 95px;
  flex-direction: row;
  .telegram-button-white {
    width: 261px;
  }
  .telegram-button-black {
    width: 261px;
  }
`;

export const RuleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CircleWithNumber = styled.div`
  background-color: ${(props) => props.theme.newColor.base01};
  color: ${(props) => props.theme.newText.textTitle};
  border: 1px solid ${(props) => props.theme.newColor.base08};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
`;

export const Line = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.newColor.base08};
  height: 170px;
  width: 1px;
  top: -11px;
  left: 25px;
  z-index: -1;
`;

export const NotTextChat = styled.div`
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.newText.textGrey};
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
`;
export const Message = styled.div`
  position: relative;
  z-index: 1;
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  .bubble-content-wrapper {
    display: flex;
    min-height: 84px;
    flex-direction: row;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: end;
    width: 100%;
    .avatar {
      position: absolute;
      top: 21px;
      left: 1px;
      padding-right: 8px;
    }
    .buttle-content {
      user-select: text;
      width: calc(100% - 48px);
      height: 100%;
      padding: 16px 9px 16px 0;
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 2;
      background: ${(props) => props.theme.newColor.base01};
      .time-and-name-container {
        /* visibility: hidden; */
        font-size: 12px;
        height: 20px;
        min-width: 22px;
        user-select: none;
        line-height: 20px;
        vertical-align: middle;
        pointer-events: none;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        direction: ltr;
        float: right;
        padding-inline-end: 8px;
        .user-name {
          color: ${(props) => props.theme.text.text04};
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
        }
        .time-inner {
          display: flex;
          white-space: nowrap;
          height: 12px;
          visibility: visible;
        }
      }
      .message {
        max-width: 480px;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        min-height: 22px;
        word-break: break-word;
        white-space: pre-wrap;
        position: relative;
        > div {
          display: flex;
          align-items: center;
          > img {
            width: 18px;
            height: 18px;
            &[alt='any'] {
              max-width: 420px;
              width: 100%;
              height: auto;
            }
          }
        }
      }
      .colored-name {
        font-weight: 700;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        .title-flex {
          display: flex;
          align-items: center;
          max-width: inherit;
          overflow: inherit;
          .peer-title {
            cursor: pointer;
            flex: 0 0 auto;
            max-width: 100%;
          }
        }
      }
    }
  }
  &.is-in {
    display: flex;
    flex-direction: row;
    /* animation: ${fadeInReverse} 0.2s ease-in; */
    &:before {
      left: 0px;
      /* background: ${(props) => props.theme.color.base03}; */
    }
    .buttle-content {
      background: transparent;
      color: ${(props) => props.theme.text.text04};
      .message {
        padding: 8px 5px 0 0;
      }
      .time-inner {
        color: ${(props) => props.theme.text.greyFontColor};
      }
    }
  }
  &.is-out {
    /* animation: ${fadeIn} 0.2s ease-in; */
    &:before {
      right: -4px;
      /* background: ${(props) => props.theme.text.link}; */
    }
    .message {
      padding: 8px 5px 0 0;
    }
    .buttle-content {
      color: ${(props) => props.theme.text.text04};
      .time-inner {
        color: ${(props) => props.theme.text.greyFontColor};
      }
    }
  }
`;

export const DateSeparator = styled.div`
  position: relative;
  top: 0.1875rem;
  padding-bottom: 0.3125rem;
  z-index: 2;
  transition: 0.3s ease;
  opacity: 0.99999;
  pointer-events: none;
  font-weight: 500;
  .bubble-content {
    cursor: pointer;
    pointer-events: all;
    background-color: transparent;
    border-radius: 0.875rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    z-index: 2;
    box-shadow: none;
    .service-msg {
      color: ${(props) => props.theme.newText.textTitle};
      font-size: 12px;
      line-height: 16px;
      border-radius: inherit;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      word-break: break-word;
    }
  }
`;
