import { apiDelete, apiGet, apiPut } from '../../interceptors';

export const NotesService = {
  async fetchNotes() {
    const response = await apiGet('/notes');

    return response.data && response.data.notes ? response.data.notes : [];
  },
  async createNotes(data: { text: string }) {
    const response = await apiPut('/note', data);

    return response.data;
  },
  async deleteNote(data: { id: string }) {
    const response = await apiDelete(`/note/${data.id}`);

    return response.data;
  }
};
