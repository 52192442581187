import { useQuery } from '@tanstack/react-query';
import { TarifService } from '../../../../shared/api/model/service/tarif.service';
import { MYBALANCE_QUERY, MYTARIF_QUERY, PAYMENT_HISTORY_QUERY } from '../../../../shared/const/query.const';
import { IBalance, ITarif, ITarifHistory, ITarifHistoryData } from '../tarif.interface';

export function useMyTarifQuery() {
  return useQuery<ITarif, Error, ITarif>({
    queryKey: [MYTARIF_QUERY],
    staleTime: 1800000000,
    queryFn: () => TarifService.getMyTarif(),
    select: (data: ITarif) => data || {}
  });
}

export function usePaymentHistoryQuery() {
  return useQuery<ITarifHistoryData, Error, ITarifHistory[]>({
    queryKey: [PAYMENT_HISTORY_QUERY],
    queryFn: () => TarifService.getHistory(),
    select: (data: ITarifHistoryData) => data.History || []
  });
}

export function useMyBalanceQuery() {
  return useQuery<IBalance, Error, number>({
    queryKey: [MYBALANCE_QUERY],
    staleTime: 1800000000,
    queryFn: () => TarifService.getBalanсe(),
    select: (data: IBalance) => data.balance
  });
}
