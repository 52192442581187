export const PaymentIcon30 = () => {
  return (
    <svg
      width='36'
      height='26'
      viewBox='0 0 36 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.1816 13.0107L28.1817 13.0107V13C28.1817 10.0851 25.8823 7.65458 23.038 7.65458C20.1937 7.65458 17.8951 10.0852 17.8951 13C17.8951 15.9148 20.1937 18.3454 23.038 18.3454C25.8915 18.3454 28.1195 15.9072 28.1816 13.0107ZM11.0783 13C11.0783 6.10462 16.4266 0.5 23.038 0.5C29.5947 0.5 35 6.10624 35 13C35 19.8937 29.5933 25.5 23.0366 25.5C16.4786 25.5 11.0783 19.9514 11.0783 13ZM6.53578 22.1862L1.1717 4.27708H10.0783V13V22.1862H6.53578Z'
        fill='white'
        stroke='currentColor'
      />
    </svg>
  );
};
