import styled from 'styled-components';

interface IStyledHTagProps {
  $background?: 'base07' | 'accent' | 'successBg' | 'infoBg' | 'warningBg' | 'errorBg' | 'neutralBg';
  $size?: 'l' | 'm' | 's' | 'xs';
  $type?: 'svg' | 'circle' | 'empty';
}

export const BadgeItemWrapper = styled.div<IStyledHTagProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s ease;
  position: relative;
  font-weight: 500;
  border-radius: 30px;
  letter-spacing: ${(props) => (props.$size === 's' || props.$size === 'xs' ? '0.4px' : 'initial')};
  color: ${(props) =>
    props.$background === 'base07' || props.$background === 'accent' ? props.theme.color.base01 : props.theme.text.text01};
  padding: ${(props) => {
    if (props.$size !== undefined) {
      switch (props.$size) {
        case 'l':
          return props.$size ? '6px 12px' : '6px 12px';
        case 'm':
          return props.$size ? '2px 8px' : '6px 12px';
        case 's':
          return props.$size ? '2px 6px' : '6px 12px';
        case 'xs':
          return props.$size ? '0 5px' : '6px 12px';
        default:
          return '6px 12px';
      }
    } else {
      return '6px 12px';
    }
  }};
  font-size: ${(props) => {
    if (props.$size !== undefined) {
      switch (props.$size) {
        case 'l':
          return props.$size ? '13px' : '13px';
        case 'm':
          return props.$size ? '13px' : '13px';
        case 's':
          return props.$size ? '11px' : '13px';
        case 'xs':
          return props.$size ? '11px' : '13px';
        default:
          return '13px';
      }
    } else {
      return '13px';
    }
  }};
  line-height: ${(props) => {
    if (props.$size !== undefined) {
      switch (props.$size) {
        case 'l':
          return props.$size ? '20px' : '20px';
        case 'm':
          return props.$size ? '20px' : '20px';
        case 's':
          return props.$size ? '16px' : '20px';
        case 'xs':
          return props.$size ? '16px' : '20px';
        default:
          return '20px';
      }
    } else {
      return '20px';
    }
  }};
  background: ${(props) => {
    if (props.$background !== undefined) {
      switch (props.$background) {
        case 'accent':
          return props.$background ? props.theme.color.accent : props.theme.color.base07;
        case 'base07':
          return props.$background ? props.theme.color.base07 : props.theme.color.base07;
        case 'successBg':
          return props.$background ? props.theme.color.successBg : props.theme.color.base07;
        case 'errorBg':
          return props.$background ? props.theme.color.errorBg : props.theme.color.base07;
        case 'infoBg':
          return props.$background ? props.theme.color.infoBg : props.theme.color.base07;
        case 'neutralBg':
          return props.$background ? props.theme.color.neutralBg : props.theme.color.base07;
        case 'warningBg':
          return props.$background ? props.theme.color.warningBg : props.theme.color.base07;
        default:
          return props.theme.color.base07;
      }
    } else {
      return '6px 12px';
    }
  }};
`;
