import React from 'react';

export function UserAddedOutline() {
  return (
    <svg
      width='24'
      height='18'
      viewBox='0 0 24 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.85 4.95005C18.85 3.37523 17.5748 2.10005 16 2.10005C14.4252 2.10005 13.15 3.37523 13.15 4.95005C13.15 6.52487 14.4252 7.80005 16 7.80005C17.5748 7.80005 18.85 6.52487 18.85 4.95005ZM20.65 4.95005C20.65 7.51898 18.5689 9.60005 16 9.60005C13.4311 9.60005 11.35 7.51898 11.35 4.95005C11.35 2.38112 13.4311 0.300049 16 0.300049C18.5689 0.300049 20.65 2.38112 20.65 4.95005ZM9.90001 15.4286C9.90001 16.1975 9.80924 16.1 10.2353 16.1H21.7647C22.1908 16.1 22.1 16.1975 22.1 15.4286C22.1 13.536 19.3218 12.4 16 12.4C12.6782 12.4 9.90001 13.536 9.90001 15.4286ZM16 10.6C20.2272 10.6 23.9 12.1018 23.9 15.4286C23.9 17.1642 23.2145 17.9 21.7647 17.9H10.2353C8.78554 17.9 8.10001 17.1642 8.10001 15.4286C8.10001 12.1018 11.7728 10.6 16 10.6ZM9.6364 5.86365C9.98787 6.21512 9.98787 6.78497 9.6364 7.13645L4.6364 12.1364C4.28493 12.4879 3.71508 12.4879 3.36361 12.1364L0.86361 9.63645C0.512138 9.28497 0.512138 8.71513 0.86361 8.36365C1.21508 8.01218 1.78493 8.01218 2.1364 8.36365L4.00001 10.2273L8.36361 5.86365C8.71508 5.51218 9.28493 5.51218 9.6364 5.86365Z'
        fill='currentColor'
      />
    </svg>
  );
}
