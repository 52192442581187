export const RADIUS_M = '0.75rem';
export const RADIUS_S = '0.5rem';
export const RADIUS_XS = '0.313rem';

export const BACKDROP = 'none';

export const Z_INDEX = '11';

export const HEIGHT_S = '2rem';

export const HEIGHT_INPUT = '3.5rem';
export const HEIGHT_INPUT_M = '2.75rem';
export const HEIGHT_INPUT_NEW_M = '2.875rem';
export const HEIGHT_INPUT_S = '2.25rem';
export const HEIGHT_INPUT_NEW = '2.625rem';

export const FONT_M =
  'normal 1rem/1.25rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';
export const FONT_S =
  'normal .875rem/1.375rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';
export const FONT_XS =
  'normal .75rem/1rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';
export const MODAL_GAP = '1.25rem';
export const MODAL_TEXT_GAP = '0.75rem';
export const MODAL_GAP_M = '2rem';
export const MODAL_GAP_L = '2.5rem';
export const FONT_HEADING_1 =
  'bold 3.125rem/3.5rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';
export const FONT_HEADING_2 =
  'bold 2.75rem/3rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';
export const FONT_HEADING_3 =
  'bold 2.25rem/2.5rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';
export const FONT_HEADING_4 =
  'bold 1.75rem/2rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';

export const FONT_HEADING_5 =
  'bold 1.5rem/1.75rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';
export const FONT_HEADING_6 =
  'bold 1.25rem/1.5rem "Proxima Nova", -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", sans-serif';
