import { ReactNode } from 'react';

export enum LinkSize {
  L = 'l',
  M = 'm',
  S = 's'
}

export enum LinkPosition {
  ON = 'on',
  OFF = 'off'
}

export enum LinkType {
  BG = 'bg',
  TRANSPARENT = 'transparent'
}

export interface LinkProps {
  children?: ReactNode;
  $size?: LinkSize;
  className?: string;
  to?: string;
  $type?: LinkType;
  $vertical?: LinkPosition;
  icon?: any;
  title: string;
  description?: string;
  onClick?: (arg: any) => void;
}
