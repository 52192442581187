import { createAsyncThunk } from '@reduxjs/toolkit';

import { CalendarState } from './calendar.interface';
import { setMonthDays } from './calendar.slice';

import { generateDaysArray } from '../../../utils/date-events';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import dayjs from 'dayjs';
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

export const getMonday = (date: Date): Date => {
  const today = new Date(date);
  const first = today.getDate() - today.getDay() + 1;
  return new Date(today.setDate(first));
};

export const getMonthFirstAndLastDays = (date: Date): [Date, Date] => {
  const today = new Date(date);

  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return [firstDay, lastDay];
};

export const fetchMonthDays = createAsyncThunk('calendar/fetchMonthDays', async (_, { getState }) => {
  const state = getState() as { calendar: CalendarState };
  const [firstDay, lastDay] = getMonthFirstAndLastDays(new Date(state.calendar.activeDate));

  return generateDaysArray(firstDay, lastDay);
});

export const getMonthDays = createAsyncThunk('calendar/getMonthDays', async (_, { getState, dispatch }) => {
  const state = getState() as { calendar: CalendarState };
  const [firstDay, lastDay] = getMonthFirstAndLastDays(new Date(state.calendar.activeDate));
  const monthDays = generateDaysArray(firstDay, lastDay);
  dispatch(setMonthDays(monthDays.map((date) => date.toString())) as any);
});

export const getMondayNew = (date: Date): Date => {
  const today = dayjs(date);
  const monday = today.isoWeekday(1);
  return monday.toDate();
};
