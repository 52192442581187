export const DeleteService = () => {
  return (
    <svg
      width='72'
      height='72'
      viewBox='0 0 72 72'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2547_16874)'>
        <g clipPath='url(#clip1_2547_16874)'>
          <g clipPath='url(#clip2_2547_16874)'>
            <path
              d='M36 72C55.8823 72 72 55.8823 72 36C72 16.1177 55.8823 0 36 0C16.1177 0 0 16.1177 0 36C0 55.8823 16.1177 72 36 72Z'
              fill='#FFEBEB'
            />
            <path
              d='M31.9143 34.3628C32.5337 34.3145 33.0752 34.7774 33.1235 35.3968L33.7406 43.2904C33.7891 43.9098 33.3262 44.4512 32.7068 44.4997C32.0873 44.5481 31.5459 44.0852 31.4975 43.4657L30.8804 35.5721C30.832 34.9527 31.2949 34.4113 31.9143 34.3628Z'
              fill='#DE003B'
            />
            <path
              d='M41.1195 35.5721C41.168 34.9527 40.7051 34.4113 40.0857 34.3628C39.4662 34.3145 38.9248 34.7774 38.8764 35.3968L38.2593 43.2904C38.2109 43.9098 38.6738 44.4512 39.2932 44.4997C39.9126 44.5481 40.4541 44.0852 40.5024 43.4657L41.1195 35.5721Z'
              fill='#DE003B'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M42.1651 26.599V23.9836C42.1651 22.6204 40.9615 21.7461 39.7801 21.7461H32.22C31.0385 21.7461 29.835 22.6204 29.835 23.9836V26.599H23.625C23.0037 26.599 22.5 27.1026 22.5 27.724C22.5 28.3453 23.0037 28.849 23.625 28.849H24.3673L26.2709 47.959C26.4428 49.6849 27.8948 50.9995 29.6293 50.9995H42.3707C44.1051 50.9995 45.5572 49.6849 45.7291 47.959L47.6327 28.849H48.375C48.9963 28.849 49.5 28.3453 49.5 27.724C49.5 27.1026 48.9963 26.599 48.375 26.599H42.1651ZM32.085 24.0316V26.599H39.9151V24.0316C39.892 24.0161 39.8471 23.9961 39.7801 23.9961H32.22C32.1529 23.9961 32.1082 24.0161 32.085 24.0316ZM45.3715 28.849H26.6285L28.5098 47.7359C28.5671 48.3112 29.0511 48.7495 29.6293 48.7495H42.3707C42.9488 48.7495 43.4329 48.3112 43.4902 47.736L45.3715 28.849Z'
              fill='#DE003B'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2547_16874'>
          <rect
            width='72'
            height='72'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_2547_16874'>
          <rect
            width='72'
            height='72'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip2_2547_16874'>
          <rect
            width='72'
            height='72'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
