import React from 'react';

export function NewSend() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.818 12.1968L5.28601 13.4528C5.19942 13.4673 5.11815 13.5043 5.05039 13.5601C4.98263 13.6159 4.93077 13.6886 4.90001 13.7708L2.30301 20.7278C2.05501 21.3678 2.72401 21.9778 3.33801 21.6708L21.338 12.6708C21.4627 12.6086 21.5675 12.5128 21.6408 12.3943C21.7141 12.2758 21.753 12.1392 21.753 11.9998C21.753 11.8605 21.7141 11.7239 21.6408 11.6054C21.5675 11.4868 21.4627 11.3911 21.338 11.3288L3.33801 2.32884C2.72401 2.02184 2.05501 2.63284 2.30301 3.27184L4.90101 10.2288C4.93162 10.3113 4.98343 10.3841 5.0512 10.4401C5.11897 10.4962 5.2003 10.5333 5.28701 10.5478L12.819 11.8028C12.8654 11.811 12.9074 11.8352 12.9376 11.8712C12.9679 11.9072 12.9845 11.9528 12.9845 11.9998C12.9845 12.0469 12.9679 12.0925 12.9376 12.1285C12.9074 12.1645 12.8654 12.1887 12.819 12.1968H12.818Z'
        fill='#8C8B88'
      />
    </svg>
  );
}
