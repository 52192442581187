import { ReactElement } from 'react';
import { EIcons } from '../medium-new-icons/iconPayments';

export const getIconByFinanceIcon = (financeIcon: string): ReactElement => {
  switch (financeIcon) {
    case '1':
      return EIcons.icon1;
    case '2':
      return EIcons.icon2;
    case '3':
      return EIcons.icon3;
    case '4':
      return EIcons.icon4;
    case '5':
      return EIcons.icon5;
    case '6':
      return EIcons.icon6;
    case '7':
      return EIcons.icon7;
    case '8':
      return EIcons.icon8;
    case '9':
      return EIcons.icon9;
    case '10':
      return EIcons.icon10;
    case '11':
      return EIcons.icon11;
    case '12':
      return EIcons.icon12;
    case '13':
      return EIcons.icon13;
    case '14':
      return EIcons.icon14;
    case '15':
      return EIcons.icon15;
    case '16':
      return EIcons.icon16;
    case '17':
      return EIcons.icon17;
    case '18':
      return EIcons.icon18;
    case '19':
      return EIcons.icon19;
    case '20':
      return EIcons.icon20;
    case '21':
      return EIcons.icon21;
    case '22':
      return EIcons.icon22;
    case '23':
      return EIcons.icon23;
    case '24':
      return EIcons.icon24;
    case '25':
      return EIcons.icon25;
    case '26':
      return EIcons.icon26;
    case '27':
      return EIcons.icon27;
    case '28':
      return EIcons.icon28;
    case '29':
      return EIcons.icon29;
    case '30':
      return EIcons.icon30;
    case '31':
      return EIcons.icon31;
    case '32':
      return EIcons.icon32;
    case '33':
      return EIcons.icon33;
    case '34':
      return EIcons.icon34;
    case '35':
      return EIcons.icon35;
    default:
      return EIcons.icon35;
  }
};
