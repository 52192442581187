import { keyframes, styled } from 'styled-components';
import { ITextProps, IWrapperProps } from './Tooltip.props';

const showTooltipFromTop = keyframes`
  0%{
    transform: translateY(-20px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;
const showTooltipFromBottom = keyframes`
  0%{
    transform: translateY(20px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;
export const TooltipWrapper = styled.div<IWrapperProps>`
  position: relative;
  display: flex;
  color: ${(props) => props.$color};
  cursor: pointer;
`;

export const ToolTipIcon = styled.span`
  svg {
    width: 15px;
    height: 15px;
    transition: all 0.3s ease;
  }
  &:hover {
    svg {
      color: ${(props) => props.theme.color.primary};
    }
  }
`;

export const ToolTipText = styled.span<ITextProps>`
  position: absolute;
  ${(props) => {
    if (props.$position) {
      switch (props.$position) {
        case 'top':
          return 'bottom: calc(100% + 9px);';
        case 'bottom':
          return 'top: calc(100% + 9px);';
        default:
          return 'bottom: calc(100% + 9px);';
      }
    }
    return 'bottom: calc(100% + 9px);';
  }}
  left: -12px;
  display: flex;
  padding: 12px 16px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.color.base01};
  color: ${(props) => props.theme.color.sidebarMedium90};
  min-width: 320px;
  max-width: 320px;
  z-index: 5;
  box-shadow: ${(props) => {
    if (props.$position) {
      switch (props.$position) {
        case 'top':
          return `0px 4px 6px 0px ${props.theme.color.gray0D}, 0px 12px 12px 0px ${props.theme.color.black4}`;
        case 'bottom':
          return `0px -4px 6px 0px ${props.theme.color.gray0D}, 0px -8px 12px 0px ${props.theme.color.black4}`;
        default:
          return `0px 4px 6px 0px ${props.theme.color.gray0D}, 0px 12px 12px 0px ${props.theme.color.black4}`;
      }
    }
    return `0px 4px 6px 0px ${props.theme.color.gray0D}, 0px 12px 12px 0px ${props.theme.color.black4}`;
  }};

  &::after {
    content: '';
    position: absolute;
    ${(props) => {
      if (props.$position) {
        switch (props.$position) {
          case 'top':
            return 'top: calc(100% - 4px);';
          case 'bottom':
            return 'bottom: calc(100% - 4px);';
          default:
            return 'top: calc(100% - 4px);';
        }
      }
      return 'top: calc(100% - 4px);';
    }}
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    background-color: ${(props) => props.theme.color.base01};
    border-radius: 2px;
    z-index: 1;
  }
  // &.right {
  //   min-width: 240px;
  //   max-width: 240px;
  //   left: -130px;
  //   &::after {
  //     left: 134px;
  //   }
  // }
  &.time-table {
    min-width: 220px;
    width: 100%;
  }
  animation: ${(props) => {
      if (props.$position) {
        switch (props.$position) {
          case 'top':
            return showTooltipFromTop;
          case 'bottom':
            return showTooltipFromBottom;
          default:
            return showTooltipFromTop;
        }
      }
      return showTooltipFromTop;
    }}
    0.3s ease;
  &.fit {
    min-width: max-content;
    max-width: max-content;
  }
  @media (max-width: 767px) {
    padding: 8px 12px;
    min-width: 240px;
    max-width: 240px;
    left: -130px;
    &::after {
      left: 134px;
    }
    &.time-table {
      left: -80px;
      &::after {
        left: 84px;
      }
    }
  }
`;
