import styled from 'styled-components';
import { RADIUS_M, DURATION, HEIGHT_INPUT, SHADOW_INPUT, FONT_S, HEIGHT_INPUT_M, FONT_XS } from '../../../../styles';

export const TextareaWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: block;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  appearance: none;
  min-height: 4.75rem;
  max-height: 8rem;
  border-radius: ${RADIUS_M};
  color: ${(props) => props.theme.text.text01};
  visibility: visible;

  transition-property: box-shadow, background;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  background: ${(props) => props.theme.color.inverse};
  color: ${(props) => props.theme.text.text01};
  box-shadow: ${SHADOW_INPUT};

  height: 100%;
`;
export const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: auto;
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  box-sizing: border-box;
  overflow: hidden;
  cursor: text;
  &:focus-within {
    > div {
      &:after {
        border-width: 2px;
        color: ${(props) => props.theme.color.primary};
      }
    }
    .placeholder {
      font: ${FONT_XS};
      transform: translateY(-0.625rem);
    }
  }
  &.filled {
    .placeholder {
      font: ${FONT_XS};
      transform: translateY(-0.625rem);
    }
  }
`;
export const Wrapper = styled.div`
  padding: calc((${HEIGHT_INPUT} - 2.625rem) / 2) 0;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: inherit;
  box-sizing: border-box;
  display: block;
  &:after {
    transition-property: color;
    transition-duration: ${DURATION};
    transition-timing-function: ease-in-out;
    color: ${(props) => props.theme.color.base03};
    position: absolute;
    inset: 0;
    content: '';
    border-radius: 12px;
    border: 1px solid currentColor;
    pointer-events: none;
  }
`;
export const Icons = styled.div``;
export const Placeholder = styled.div`
  padding: 0 1rem;
  font: ${FONT_S};
  transition-property: transform, font-size, letter-spacing;
  transition-duration: ${DURATION};
  transition-timing-function: ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  user-select: none;
  color: ${(props) => props.theme.text.text02};
  pointer-events: none;
  will-change: transform;
  transform: translateY(0);
  position: absolute;
  top: calc(${HEIGHT_INPUT_M} / 2 - 0.625rem);
  left: 0;
  max-width: 100%;
  border: solid transparent;
  border-inline-start-width: 0rem;
  border-inline-end-width: 0rem;
  box-sizing: border-box;
`;
export const Scrollbar = styled.div`
  border-top: 0.75rem solid transparent;
  height: calc(100% - 1rem);
  display: flex;
  min-height: calc(100% - 1rem);
  width: 100%;
  position: relative;
  isolation: isolate;
  overflow: auto;
  cursor: text;
  color: ${(props) => props.theme.text.text01};
`;
export const Controls = styled.div`
  color: ${(props) => props.theme.text.text01};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: calc(100% - 1px);
  min-height: calc(100% - 1px);
  max-width: calc(100% - 1px);
  max-height: calc(100% - 1px);
  float: left;
  margin-inline-end: calc(-100% + 1px);
  pointer-events: none;
`;
export const Content = styled.div`
  isolation: isolate;
  flex: 1;
  flex-basis: auto;
  width: 100%;
  height: max-content;
  display: block;
  .input-wrapper {
    min-height: inherit;
    width: 100%;
    flex: 1;
    display: block;
    .relative {
      position: relative;
      min-height: inherit;
      display: block;
      .pseudo-content {
        padding: 0 1rem;
        white-space: pre-wrap;
        word-wrap: break-word;
        pointer-events: none;
        color: transparent;
        overflow: hidden;
        border: solid transparent;
        border-top: 0;
        border-bottom: 0;
        border-inline-start-width: 0;
        border-inline-end-width: 0;
        display: block;
        > span {
          white-space: pre-wrap;
          word-wrap: break-word;
          pointer-events: none;
          color: transparent;
          &.pseudo-content__extra {
            background-color: ${(props) => props.theme.status.error};
          }
          &.caret {
            display: inline-block;
            height: 1rem;
            width: 0;
          }
        }
      }
      > textarea {
        padding: 0 1rem;
        margin: 0;
        border-width: 0;
        border-radius: inherit;
        background: none;
        font: ${FONT_S};
        color: inherit;
        caret-color: currentColor;
        appearance: none;
        word-break: keep-all;
        -webkit-text-fill-color: currentColor;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        resize: none;
        overflow: hidden;
        outline: none;
        border-style: solid;
        border-color: transparent;
        border-inline-start-width: 0;
        border-inline-end-width: 0;
      }
    }
  }
`;
