import React from 'react';

export function TapBarRecordingGray() {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.5'
        d='M13.9997 23.3663V21H9.33301V23.3663C9.33301 23.6896 9.33301 23.8513 9.44367 23.9167C9.55434 23.982 9.70568 23.9097 10.0084 23.7651L11.4576 23.0727C11.5601 23.0238 11.6113 22.9994 11.6663 22.9994C11.7214 22.9994 11.7726 23.0238 11.8751 23.0728L13.3243 23.7651C13.627 23.9097 13.7784 23.982 13.889 23.9167C13.9997 23.8513 13.9997 23.6896 13.9997 23.3663Z'
        fill='#999999'
      />
      <path
        d='M9.33366 21H8.66397C7.39974 21 6.95776 21.0066 6.61907 21.0794C6.02011 21.2082 5.49942 21.4843 5.11979 21.8571C4.99176 21.9829 4.92775 22.0458 4.78011 22.4463C4.63247 22.847 4.65585 23.0169 4.70261 23.3567C4.71087 23.4166 4.71975 23.4752 4.72933 23.5324C4.85682 24.2933 5.09001 24.6962 5.44768 24.9832C5.80534 25.2702 6.30751 25.4574 7.25578 25.5597C8.23193 25.665 9.52569 25.6667 11.3807 25.6667H16.55C18.405 25.6667 19.6988 25.665 20.6749 25.5597C21.6232 25.4574 22.1253 25.2702 22.483 24.9832C22.8406 24.6962 23.0738 24.2933 23.2014 23.5324C23.3085 22.8933 23.3296 22.0845 23.3337 21H14.0003V23.3663C14.0003 23.6896 14.0003 23.8513 13.8896 23.9167C13.779 23.982 13.6277 23.9097 13.3249 23.7651L11.8757 23.0728C11.7733 23.0238 11.7221 22.9994 11.667 22.9994C11.6119 22.9994 11.5607 23.0238 11.4583 23.0727L10.009 23.7651C9.70633 23.9097 9.55499 23.982 9.44431 23.9167C9.33366 23.8513 9.33366 23.6896 9.33366 23.3663V21Z'
        fill='#999999'
      />
      <path
        opacity='0.5'
        d='M5.51537 3.18879C5.87167 2.82949 6.3719 2.59524 7.31653 2.46716C8.28893 2.33532 9.57773 2.33325 11.4256 2.33325H16.575C18.4229 2.33325 19.7117 2.33532 20.6842 2.46716C21.6288 2.59524 22.129 2.82949 22.4853 3.18879C22.8416 3.5481 23.0738 4.05255 23.2009 5.00515C23.3316 5.98577 23.3337 7.28545 23.3337 9.14894V20.9999H8.66397C7.39975 20.9999 6.95777 21.0066 6.61907 21.0794C6.02012 21.2082 5.49942 21.4842 5.11979 21.8571C4.99176 21.9828 4.92775 22.0457 4.78011 22.4462C4.6954 22.6761 4.66699 22.8301 4.66699 22.9836V9.14894C4.66699 7.28545 4.66905 5.98577 4.79978 5.00515C4.92679 4.05255 5.15908 3.5481 5.51537 3.18879Z'
        fill='#999999'
      />
      <path
        d='M8.45801 8.16675C8.45801 7.6835 8.84976 7.29175 9.33301 7.29175H18.6663C19.1496 7.29175 19.5413 7.6835 19.5413 8.16675C19.5413 8.64999 19.1496 9.04175 18.6663 9.04175H9.33301C8.84976 9.04175 8.45801 8.64999 8.45801 8.16675Z'
        fill='#999999'
      />
      <path
        d='M9.33301 11.375C8.84976 11.375 8.45801 11.7668 8.45801 12.25C8.45801 12.7332 8.84976 13.125 9.33301 13.125H15.1663C15.6496 13.125 16.0413 12.7332 16.0413 12.25C16.0413 11.7668 15.6496 11.375 15.1663 11.375H9.33301Z'
        fill='#999999'
      />
    </svg>
  );
}
