import { ICashInput, ICashRegisterData, ITransferCash } from '../../../../store/redux/cash-register/cashRegister.interface';
import { apiDelete, apiGet, apiPost, apiPut } from '../../interceptors';

export const CashRegisterService = {
  async fetchCashRegisters() {
    const response = await apiGet('/listkassa');

    return response.data || [];
  },
  async fetchCashRegisterById(id: string | undefined) {
    if (!id) return;
    const response = await apiGet(`/kassa/${id}`);

    return response.data || [];
  },
  async createCashRegister(data: ICashRegisterData) {
    const response = await apiPut('/kassa', data);

    return response.data;
  },

  async updateCashRegister(data: ICashRegisterData) {
    const { id, ...values } = data;
    if (!id) return;
    const response = await apiPost(`/kassa/${id}`, values);

    return response.data;
  },
  async deleteCashRegister(id: string | undefined) {
    if (!id) return;
    const response = await apiDelete(`/kassa/${id}`);

    return response.data;
  },
  async cashInput(data: ICashInput) {
    const response = await apiPost('/cashinkass', data);

    return response.data;
  },
  async cashOutput(data: ICashInput) {
    const response = await apiPost('/cashoutkass', data);

    return response.data;
  },
  async transferCash(data: ITransferCash) {
    const response = await apiPost('/kassinkass', data);

    return response.data;
  }
};
