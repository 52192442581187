import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecordingState } from './recording.interface';
import { ILesson } from '../lessons/lessons.interface';

const initialState: RecordingState = {
  isShowOption: false,
  isSideClient: '',
  isId: '',
  ispathname: '',
  isCabinetId: '',
  enteredPhone: '',
  enteredName: '',
  istimelineempty: false,
  isEmployeeId: '',
  isPaymentType: '',
  serviceError: false,
  employeeError: false,
  clientError: false,
  chatsub: 0,
  value: '',
  isPaymentComplete: 'no',
  isLocalDay: false,
  startDate: null,
  endDate: null,
  renameEvents: [],
  searchedValue: '',
  isSidebarOpen: false
};

export const recordingSlice = createSlice({
  name: 'recording',
  initialState,
  reducers: {
    setIsLocalDay: (state: RecordingState, action: PayloadAction<boolean>) => {
      state.isLocalDay = action.payload;
    },
    setIsShowOption: (state: RecordingState, action: PayloadAction<boolean>) => {
      state.isShowOption = action.payload;
    },
    setRenameEvents: (state: RecordingState, action: PayloadAction<ILesson[] | undefined>) => {
      state.renameEvents = action.payload;
    },
    setServiceError: (state: RecordingState, action: PayloadAction<boolean>) => {
      state.serviceError = action.payload;
    },
    setClientError: (state: RecordingState, action: PayloadAction<boolean>) => {
      state.clientError = action.payload;
    },
    setSearchedValue: (state: RecordingState, action: PayloadAction<string>) => {
      state.searchedValue = action.payload;
    },
    setIspathname: (state: RecordingState, action: PayloadAction<string>) => {
      state.ispathname = action.payload;
    },
    setValue: (state: RecordingState, action: PayloadAction<any>) => {
      state.value = action.payload;
    },
    setIsId: (state: RecordingState, action: PayloadAction<string>) => {
      state.isId = action.payload;
    },
    setIsCabinetId: (state: RecordingState, action: PayloadAction<string>) => {
      state.isCabinetId = action.payload;
    },
    setStartDate: (state: RecordingState, action: PayloadAction<string | null>) => {
      state.startDate = action.payload ? action.payload : null;
    },
    setEndDate: (state: RecordingState, action: PayloadAction<string | null>) => {
      state.endDate = action.payload ? action.payload : null;
    },

    setIsTimelineEmpty: (state: RecordingState, action: PayloadAction<boolean>) => {
      state.istimelineempty = action.payload;
    },
    setIsEmployeeId: (state: RecordingState, action: PayloadAction<string>) => {
      state.isEmployeeId = action.payload;
    },

    setChatSub: (state: RecordingState, action: PayloadAction<number>) => {
      state.chatsub = action.payload;
    },

    setIsSideClient: (state: RecordingState, action: PayloadAction<any>) => {
      state.isSideClient = action.payload;
    },

    setIsSidebaropen: (state: RecordingState, action: PayloadAction<boolean>) => {
      state.isSidebarOpen = action.payload;
    }
  }
});

export const { reducer } = recordingSlice;
