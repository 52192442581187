import React from 'react';

export function RegArrowRight() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.92786 19.2969C8.73586 19.2969 8.57586 19.2329 8.44786 19.1049C8.31986 18.9769 8.25586 18.8329 8.25586 18.6729C8.25586 18.5129 8.31986 18.4009 8.44786 18.3369L14.3039 12.0969L8.44786 5.95287C8.31986 5.82487 8.25586 5.68087 8.25586 5.52087C8.25586 5.36087 8.31986 5.21687 8.44786 5.08887C8.57586 4.96087 8.71986 4.89687 8.87986 4.89687C9.03986 4.89687 9.18386 4.96087 9.31186 5.08887L15.5519 11.7129C15.6799 11.8409 15.7439 11.9849 15.7439 12.1449C15.7439 12.3049 15.6799 12.4489 15.5519 12.5769L9.31186 19.1049C9.18386 19.2329 9.05586 19.2969 8.92786 19.2969Z'
        fill='currentColor'
      />
    </svg>
  );
}
