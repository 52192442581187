export const PaymentIcon33 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.35938 32.0631V31.0078H10.4147V32.0631H9.35938Z'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M17.7969 32.0631V31.0078H18.8521V32.0631H17.7969Z'
        stroke='currentColor'
        strokeWidth='2'
      />
      <path
        d='M3.03125 3.9375H9.35938V25.0312H26.2344L31.8594 10.9688H14.9631'
        stroke='currentColor'
      />
    </svg>
  );
};
