export const iconCodes = [
  {
    link: 'https://i.ibb.co/2cYvbxC/1f610.png',
    alt: '😐'
  },
  {
    link: 'https://i.ibb.co/mS7cLnq/1f44c.png',
    alt: '👌'
  },
  {
    link: 'https://i.ibb.co/8NpVycr/1f600.png',
    alt: '😀'
  },
  {
    link: 'https://i.ibb.co/ns7P55h/1f603.png',
    alt: '😃'
  },
  {
    link: 'https://i.ibb.co/Nm5k2df/1f604.png',
    alt: '😄'
  },
  {
    link: 'https://i.ibb.co/W0mnGtY/1f601.png',
    alt: '😁'
  },
  {
    link: 'https://i.ibb.co/fDHPv3R/1f606.png',
    alt: '😆'
  },
  {
    link: 'https://i.ibb.co/xHVRMr5/1f605.png',
    alt: '😅'
  },
  {
    link: 'https://i.ibb.co/7j4xpxz/1f923.png',
    alt: '🤣'
  },
  {
    link: 'https://i.ibb.co/YfNcyS0/1f602.png',
    alt: '😂'
  },
  {
    link: 'https://i.ibb.co/W01X86y/1f642.png',
    alt: '🙂'
  },
  {
    link: 'https://i.ibb.co/Z2LCSSZ/1f643.png',
    alt: '🙃'
  },
  {
    link: 'https://i.ibb.co/kgYZnSS/1fae0.png',
    alt: '🫠'
  },
  {
    link: 'https://i.ibb.co/thLPPdG/1f609.png',
    alt: '😉'
  },
  {
    link: 'https://i.ibb.co/rdNTqpG/1f60a.png',
    alt: '😊'
  },
  {
    link: 'https://i.ibb.co/VQf10kr/1f607.png',
    alt: '😇'
  },
  {
    link: 'https://i.ibb.co/xMc9GvF/1f970.png',
    alt: '🥰'
  },
  {
    link: 'https://i.ibb.co/2FdGjV4/1f60d.png',
    alt: '😍'
  },
  {
    link: 'https://i.ibb.co/tHCGDVF/1f929.png',
    alt: '🤩'
  },
  {
    link: 'https://i.ibb.co/pjXNKVW/1f618.png',
    alt: '😘'
  },
  {
    link: 'https://i.ibb.co/bvbNthm/1f617.png',
    alt: '😗'
  },
  {
    link: 'https://i.ibb.co/C9Hs2Fv/263a.png',
    alt: '☺️'
  },
  {
    link: 'https://i.ibb.co/xSSth1S/1f61a.png',
    alt: '😚'
  },
  {
    link: 'https://i.ibb.co/X4H4y6Q/1f619.png',
    alt: '😙'
  },
  {
    link: 'https://i.ibb.co/zh4WFkW/1f972.png',
    alt: '🥲'
  },
  {
    link: 'https://i.ibb.co/6ZR1B3m/1f60b.png',
    alt: '😋'
  },
  {
    link: 'https://i.ibb.co/vBdgfk1/1f61b.png',
    alt: '😛'
  },
  {
    link: 'https://i.ibb.co/jTcWqYb/1f61c.png',
    alt: '😜'
  },
  {
    link: 'https://i.ibb.co/b7LXQBk/1f92a.png',
    alt: '🤪'
  },
  {
    link: 'https://i.ibb.co/B2b4SQh/1f61d.png',
    alt: '😝'
  },
  {
    link: 'https://i.ibb.co/SsjbW0d/1f911.png',
    alt: '🤑'
  },
  {
    link: 'https://i.ibb.co/cL2pGT0/1f917.png',
    alt: '🤗'
  },
  {
    link: 'https://i.ibb.co/WPx7Nm2/1f92d.png',
    alt: '🤭'
  },
  {
    link: 'https://i.ibb.co/283N8gh/1fae2.png',
    alt: '🫢'
  },
  {
    link: 'https://i.ibb.co/9tpJGD0/1fae3.png',
    alt: '🫣'
  },

  {
    link: 'https://i.ibb.co/mFtjc3x/1f92b.png',
    alt: '🤫'
  },
  {
    link: 'https://i.ibb.co/Pgh89fv/1f914.png',
    alt: '🤔'
  },
  {
    link: 'https://i.ibb.co/m401g6t/1fae1.png',
    alt: '🫡'
  },
  {
    link: 'https://i.ibb.co/YjKrJ1M/1f910.png',
    alt: '🤐'
  },
  {
    link: 'https://i.ibb.co/hcp5ych/1f928.png',
    alt: '🤨'
  },
  {
    link: 'https://i.ibb.co/RQMrb8v/1f611.png',
    alt: '😑'
  },
  {
    link: 'https://i.ibb.co/VxxDNjf/1f636.png',
    alt: '😶'
  },
  {
    link: 'https://i.ibb.co/3sZV1rp/1fae5.png',
    alt: '🫥'
  },
  {
    link: 'https://i.ibb.co/CJdDfFb/1f636-200d-1f32b-fe0f.png',
    alt: '😶&zwj;🌫️'
  },
  {
    link: 'https://i.ibb.co/TYnBkQc/1f60f.png',
    alt: '😏'
  },
  {
    link: 'https://i.ibb.co/SJ0dk2W/1f612.png',
    alt: '😒'
  },
  {
    link: 'https://i.ibb.co/02KS3sL/1f644.png',
    alt: '🙄'
  },
  {
    link: 'https://i.ibb.co/Y3zLVLF/1f62c.png',
    alt: '😬'
  },
  {
    link: 'https://i.ibb.co/dDmVPrj/1f623.png',
    alt: '😮&zwj;💨'
  },
  {
    link: 'https://i.ibb.co/qJgmD1X/1f925.png',
    alt: '🤥'
  },
  {
    link: 'https://i.ibb.co/DtyxR4d/1fae8.png',
    alt: '🫨'
  },
  {
    link: 'https://i.ibb.co/YQFZ7sq/1f60c.png',
    alt: '😌'
  },
  {
    link: 'https://i.ibb.co/31D5k5z/1f614.png',
    alt: '😔'
  },
  {
    link: 'https://i.ibb.co/CBXMCm7/1f62a.png',
    alt: '😪'
  },
  {
    link: 'https://i.ibb.co/98bsjXw/1f924.png',
    alt: '🤤'
  },
  {
    link: 'https://i.ibb.co/yqxLTVs/1f634.png',
    alt: '😴'
  },
  {
    link: 'https://i.ibb.co/Vxhnmxx/1f637.png',
    alt: '😷'
  },
  {
    link: 'https://i.ibb.co/ZXh95Hv/1f912.png',
    alt: '🤒'
  },
  {
    link: 'https://i.ibb.co/Hpqwgc8/1f915.png',
    alt: '🤕'
  },
  {
    link: 'https://i.ibb.co/Hdf4n84/1f922.png',
    alt: '🤢'
  },
  {
    link: 'https://i.ibb.co/3h6fXxk/1f92e.png',
    alt: '🤮'
  },
  {
    link: 'https://i.ibb.co/nwxjJLx/1f927.png',
    alt: '🤧'
  },
  {
    link: 'https://i.ibb.co/9ypVsLr/1f975.png',
    alt: '🥵'
  },
  {
    link: 'https://i.ibb.co/Tr9C9jK/1f976.png',
    alt: '🥶'
  },
  {
    link: 'https://i.ibb.co/d2mFgbz/1f974.png',
    alt: '🥴'
  },
  {
    link: 'https://i.ibb.co/qm8zYWb/1f635.png',
    alt: '😵'
  },
  {
    link: 'https://i.ibb.co/0m7MCWy/1f635-200d-1f4ab.png',
    alt: '😵‍💫'
  },
  {
    link: 'https://i.ibb.co/DtrVBwL/1f92f.png',
    alt: '🤯'
  },
  {
    link: 'https://i.ibb.co/PGp4T28/1f920.png',
    alt: '🤠'
  },
  {
    link: 'https://i.ibb.co/CQWrRyn/1f973.png',
    alt: '🥳'
  },
  {
    link: 'https://i.ibb.co/nMzMhbv/1f978.png',
    alt: '🥸'
  },
  {
    link: 'https://i.ibb.co/wcSt0yT/1f60e.png',
    alt: '😎'
  },
  {
    link: 'https://i.ibb.co/5rnVx2y/1f913.png',
    alt: '🤓'
  },
  {
    link: 'https://i.ibb.co/F5PwSYy/1f9d0.png',
    alt: '🧐'
  },
  {
    link: 'https://i.ibb.co/3C3Bn5T/1f615.png',
    alt: '😕'
  },
  {
    link: 'https://i.ibb.co/zHPy1XX/1fae4.png',
    alt: '🫤'
  },
  {
    link: 'https://i.ibb.co/VY8FQxW/1f61f.png',
    alt: '😟'
  },
  {
    link: 'https://i.ibb.co/Fn0dd2Q/1f641.png',
    alt: '🙁'
  },
  {
    link: 'https://i.ibb.co/z85KcRp/1f62e.png',
    alt: '😮'
  },
  {
    link: 'https://i.ibb.co/dfywnJr/1f62f.png',
    alt: '😮'
  },
  {
    link: 'https://i.ibb.co/WnPz8qG/2639-fe0f.png',
    alt: '☹️'
  },
  {
    link: 'https://i.ibb.co/YW8y7sc/1f632.png',
    alt: '😲'
  },
  {
    link: 'https://i.ibb.co/hD3dPkr/1f633.png',
    alt: '😳'
  },
  {
    link: 'https://i.ibb.co/jfbT2WN/1f97a.png',
    alt: '🥺'
  },
  {
    link: 'https://i.ibb.co/26FHDYN/1f979.png',
    alt: '🥹'
  },
  {
    link: 'https://i.ibb.co/y5rRXdc/1f626.png',
    alt: '😦'
  },
  {
    link: 'https://i.ibb.co/Gspy4xg/1f627.png',
    alt: '😧'
  },
  {
    link: 'https://i.ibb.co/7S7YkpL/1f628.png',
    alt: '😨'
  },
  {
    link: 'https://i.ibb.co/4N42ynm/1f630.png',
    alt: '😰'
  },
  {
    link: 'https://i.ibb.co/tLtbQGS/1f625.png',
    alt: '😥'
  },
  {
    link: 'https://i.ibb.co/cDvK6MX/1f622.png',
    alt: '😢'
  },
  {
    link: 'https://i.ibb.co/QJdCv2n/1f62d.png',
    alt: '😭'
  },
  {
    link: 'https://i.ibb.co/1z3DYck/1f631.png',
    alt: '😱'
  },
  {
    link: 'https://i.ibb.co/JycM1yX/1f616.png',
    alt: '😖'
  },
  {
    link: 'https://i.ibb.co/dDmVPrj/1f623.png',
    alt: '😣'
  },
  {
    link: 'https://i.ibb.co/KjZzCzL/1f61e.png',
    alt: '😞'
  },
  {
    link: 'https://i.ibb.co/6mJhNXL/1f613.png',
    alt: '😓'
  },
  {
    link: 'https://i.ibb.co/7CRq6CB/1f629.png',
    alt: '😩'
  },
  {
    link: 'https://i.ibb.co/6RQhR0t/1f62b.png',
    alt: '😫'
  },
  {
    link: 'https://i.ibb.co/s2nYs1z/1f971.png',
    alt: '🥱'
  },
  {
    link: 'https://i.ibb.co/SJFdZY2/1f624.png',
    alt: '😤'
  },
  {
    link: 'https://i.ibb.co/hsjY2Ps/1f621.png',
    alt: '😡'
  },
  {
    link: 'https://i.ibb.co/QbgJN2V/1f620.png',
    alt: '😠'
  },
  {
    link: 'https://i.ibb.co/w0gqhSC/1f92c.png',
    alt: '🤬'
  },
  {
    link: 'https://i.ibb.co/Jsfd2gY/1f608.png',
    alt: '😈'
  },
  {
    link: 'https://i.ibb.co/SQH6GhX/1f47f.png',
    alt: '👿'
  },
  {
    link: 'https://i.ibb.co/6J3C7Hb/1f44b.png',
    alt: '👋'
  },
  {
    link: 'https://i.ibb.co/VDXQpn4/1f91a.png',
    alt: '🤚'
  },
  {
    link: 'https://i.ibb.co/NszDSPr/1f590-fe0f.png',
    alt: '🖐️'
  },
  {
    link: 'https://i.ibb.co/fNBmyp9/270b.png',
    alt: '✋'
  },
  {
    link: 'https://i.ibb.co/qCXtx8v/1f596.png',
    alt: '🖖'
  },
  {
    link: 'https://i.ibb.co/j63Gtjj/1faf1.png',
    alt: '🫱'
  },
  {
    link: 'https://i.ibb.co/8Bfm7f8/1faf2.png',
    alt: '🫲'
  },
  {
    link: 'https://i.ibb.co/MM5DFRv/1faf3.png',
    alt: '🫳'
  },
  {
    link: 'https://i.ibb.co/QF6r747/1faf4.png',
    alt: '🫴'
  },
  {
    link: 'https://i.ibb.co/n0fhTrx/1faf7.png',
    alt: '🫷'
  },
  {
    link: 'https://i.ibb.co/CWKBVYC/1faf8.png',
    alt: '🫸'
  },
  {
    link: 'https://i.ibb.co/8PMcHRg/1f90c.png',
    alt: '🤌'
  },
  {
    link: 'https://i.ibb.co/bsMxMpG/1f90f.png',
    alt: '️🤏'
  },
  {
    link: 'https://i.ibb.co/YBbsgnm/270c-fe0f.png',
    alt: '✌️'
  },
  {
    link: 'https://i.ibb.co/TH3PfKd/1f91e.png',
    alt: '🤞'
  },
  {
    link: 'https://i.ibb.co/HzyRJLR/1faf0.png',
    alt: '🫰'
  },
  {
    link: 'https://i.ibb.co/mBXX10q/1f91f.png',
    alt: '🤟'
  },
  {
    link: 'https://i.ibb.co/4JRxr8Y/1f918.png',
    alt: '🤘'
  },
  {
    link: 'https://i.ibb.co/GPCvNVt/1f919.png',
    alt: '🤙'
  },
  {
    link: 'https://i.ibb.co/tbX6SfG/1f448.png',
    alt: '👈'
  },
  {
    link: 'https://i.ibb.co/Ms9GjbD/1f449.png',
    alt: '👉'
  },
  {
    link: 'https://i.ibb.co/BZSg1kc/1f446.png',
    alt: '👆'
  },
  {
    link: 'https://i.ibb.co/tbt0Tkc/1f595.png',
    alt: '🖕'
  },
  {
    link: 'https://i.ibb.co/Rh078Qc/1f447.png',
    alt: '👇'
  },
  {
    link: 'https://i.ibb.co/pQKqFT2/261d-fe0f.png',
    alt: '☝️'
  },
  {
    link: 'https://i.ibb.co/br5rsCK/1faf5.png',
    alt: '🫵'
  },
  {
    link: 'https://i.ibb.co/jwJ6HdS/1f44d.png',
    alt: '👍'
  },
  {
    link: 'https://i.ibb.co/BBkxgyd/1f44e.png',
    alt: '👎'
  },
  {
    link: 'https://i.ibb.co/5h39SKQ/270a.png',
    alt: '✊'
  },
  {
    link: 'https://i.ibb.co/HHNRn0d/1f44a.png',
    alt: '👊'
  },
  {
    link: 'https://i.ibb.co/KVSFd1J/1f91b.png',
    alt: '🤛'
  },
  {
    link: 'https://i.ibb.co/r7jxYpG/1f91c.png',
    alt: '🤜'
  },
  {
    link: 'https://i.ibb.co/VHd5chC/1f44f.png',
    alt: '👏'
  },
  {
    link: 'https://i.ibb.co/vv05sX3/1f64c.png',
    alt: '🙌'
  },
  {
    link: 'https://i.ibb.co/9pHMrNg/1faf6.png',
    alt: '🫶'
  },
  {
    link: 'https://i.ibb.co/s6d7T1Z/1f450.png',
    alt: '👐'
  },
  {
    link: 'https://i.ibb.co/56PvwqM/1f932.png',
    alt: '🤲'
  },
  {
    link: 'https://i.ibb.co/kKPkKVL/1f91d.png',
    alt: '🤝'
  },
  {
    link: 'https://i.ibb.co/pZsPsts/1f64f.png',
    alt: '🙏'
  },
  {
    link: 'https://i.ibb.co/16kj8cw/270d-fe0f.png',
    alt: '✍️'
  },
  {
    link: 'https://i.ibb.co/GMBrbzT/1f485.png',
    alt: '💅'
  },
  {
    link: 'https://i.ibb.co/YchXSht/1f4aa.png',
    alt: '💪'
  },
  {
    link: 'https://i.ibb.co/vj80KC9/1f9b5.png',
    alt: '🦵'
  },
  {
    link: 'https://i.ibb.co/kMhVqbb/1f9b6.png',
    alt: '🦶'
  },
  {
    link: 'https://i.ibb.co/zn10WXj/1f442.png',
    alt: '👂'
  },
  {
    link: 'https://i.ibb.co/54L4Tt9/1f443.png',
    alt: '👃'
  }
];
