import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Emoji from './emoji/Emoji';
import { BtnCircle, BtnIcon, BtnSendContainer, InputMessageContainer, MessengerFormWrapper } from './MessengerForm.styled';

import { EIcon, IconNew as IconInstance } from '../../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../../../shared/lib';
import { useOutside } from '../../../../../shared/lib/hooks/useOutside';
import { useTypedSelector } from '../../../../../shared/lib/hooks/useTypedSelector';
import { sendMessage } from '../../../../../store/redux/websocket/chat-reducer';
import { apiGet } from '../../../../../utils/apiInstance';
import { getCookie } from '../../../../../utils/cookies';

const MessengerForm: FC = () => {
  const { roomId } = useParams();

  const { status, websocketroomId } = useTypedSelector((state) => state.chat);
  const { user } = useTypedSelector((state) => state.user);
  const { activeFilial } = useTypedSelector((state) => state.filial);
  const dispatch = useDispatch();
  const inputMessageInputRef = useRef<HTMLDivElement>(null);
  const { ref, isShow, setIsShow } = useOutside(false);
  const [content, setContent] = useState('');
  const [contentStorage, setContentStorage] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (websocketroomId && contentStorage[websocketroomId]) {
      if (inputMessageInputRef.current) {
        inputMessageInputRef.current.focus();
        inputMessageInputRef.current.innerText = contentStorage[websocketroomId];
        setContent(contentStorage[websocketroomId]);
      }
    } else {
      if (inputMessageInputRef.current) {
        inputMessageInputRef.current.innerText = '';
      }
    }
  }, [websocketroomId]);

  const handleContentChange = () => {
    if (inputMessageInputRef.current) {
      const childNodes = Array.from(inputMessageInputRef.current.childNodes);
      const sanitizedContent = childNodes
        .map((node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            return node.textContent || '';
          } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'IMG') {
            const imgNode = node as HTMLImageElement;

            return `<img src="${imgNode.src}" alt="${imgNode.alt}">`;
          }

          return '';
        })
        .join('');

      setContentStorage((prevContent) => ({
        ...prevContent,
        [websocketroomId]: sanitizedContent
      }));

      setContent(sanitizedContent);
    }
  };

  const handleSelectEmoji = (emojiSrc: string, altText: string) => {
    if (inputMessageInputRef.current && emojiSrc) {
      const emojiNode = document.createElement('img');
      emojiNode.src = emojiSrc;
      emojiNode.alt = altText;

      inputMessageInputRef.current.appendChild(emojiNode);
      handleContentChange();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      content !== '' && sendMessageHandler(e);
    }
  };

  const sendMessageHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    const messageContent = content.trim();
    if (messageContent === '') {
      return;
    }

    const messageData = {
      type: 'send_message',
      data: {
        chat_id: websocketroomId,
        sender_id: getCookie('id'),
        content: messageContent,
        sender_fio: user.find((user) => user.id === getCookie('id'))?.fio || 'Пользователь'
      }
    };
    dispatch(sendMessage(JSON.stringify(messageData)) as any);
    if (inputMessageInputRef.current) {
      inputMessageInputRef.current.innerHTML = '';
    }
    setContentStorage((prevContent) => ({
      ...prevContent,
      [websocketroomId]: ''
    }));
    setContent('');
  };

  async function sendTelebot(e: React.FormEvent) {
    e.preventDefault();
    const messageContent = content.trim();

    if (messageContent === '') {
      return;
    }
    apiGet(`/api/keycabinet`)
      .then((key) => {
        const apikey = key.data.apikey;
        if (apikey) {
          const messageData = {
            type: 'is_bot_exist',
            data: {
              filial_id: activeFilial?.id || localStorage.getItem('currentFilialId'),
              bot_token: messageContent,
              api_key: apikey,
              filial_name: activeFilial?.name || localStorage.getItem('currentFilialName')
            }
          };
          dispatch(sendMessage(JSON.stringify(messageData)) as any);
          console.log('Запрос get_records отправлен.');
        } else {
          console.log('У пользователя нет необходимой роли для отправки запроса get_records.');
        }
      })
      .catch((error) => {
        console.error('Ошибка при получении данных пользователя:', error);
      });
  }

  return (
    <MessengerFormWrapper>
      <div className={'chat-input-container'}>
        <div className={'rows-wrapper-wrapper'}>
          <div className={'rows-wrapper'}>
            <div className='new-message-wrapper'>
              <div ref={ref}>
                {isShow && (
                  <div>
                    <Emoji onSelectEmoji={handleSelectEmoji} />
                  </div>
                )}
                <BtnIcon
                  className={'toggle-emotion'}
                  tabIndex={-1}
                  onClick={() => setIsShow(!isShow)}
                >
                  <IconInstance name={EIcon.smile} />
                </BtnIcon>
              </div>
              <InputMessageContainer>
                <div
                  ref={inputMessageInputRef}
                  onKeyDown={handleKeyDown}
                  onInput={handleContentChange}
                  className='input-message-input scrollable scrollable-y no-scrollbar'
                  contentEditable={true}
                  dir={'auto'}
                  tabIndex={5}
                  autoFocus
                ></div>
                <span className={cn(content.length !== 0 && 'closed', 'input-search-placeholder')}>Введите сообщение...</span>
                {/*<div*/}
                {/*  className={'input-message-input id-empty input-field-insendTelebotput-fake'}*/}
                {/*  contentEditable={'true'}*/}
                {/*  dir={'auto'}*/}
                {/*  tabIndex={-1}*/}
                {/*></div>*/}
              </InputMessageContainer>
            </div>
          </div>
        </div>
        <div className={'fake-wrapper fake-rows-wrapper'}></div>
        <div className={'fake-wrapper fake-selection-wrapper'}></div>
        <BtnCircle></BtnCircle>
        <BtnIcon></BtnIcon>
        <BtnSendContainer>
          <button
            onClick={(e) => {
              content !== '' && (roomId === 'telebot' ? sendTelebot(e) : sendMessageHandler(e));
            }}
            disabled={status !== 'ready'}
          >
            <IconInstance name={EIcon.newsend} />
          </button>
        </BtnSendContainer>
      </div>
    </MessengerFormWrapper>
  );
};

export default MessengerForm;
