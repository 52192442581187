import React from 'react';

export function VkIcon() {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.8329 0.111328H7.38588C1.50588 0.111328 0.109375 1.50783 0.109375 7.38783V13.8348C0.109375 19.7148 1.50588 21.1113 7.38588 21.1113H13.8329C19.7129 21.1113 21.1094 19.7148 21.1094 13.8348V7.38783C21.1094 1.50783 19.7129 0.111328 13.8329 0.111328ZM17.0669 15.0948H15.5339C14.9564 15.0948 14.7779 14.6223 13.7594 13.5828C12.8354 12.7113 12.4364 12.5853 12.2159 12.5853C11.9114 12.5853 11.8169 12.6693 11.8169 13.1103V14.4858C11.8169 14.8533 11.7014 15.0843 10.7249 15.0843C9.10787 15.0843 7.31237 14.0973 6.05237 12.2808C4.15187 9.61383 3.63737 7.59783 3.63737 7.19883C3.63737 6.97833 3.71087 6.76833 4.15187 6.76833H5.69537C6.08387 6.76833 6.23087 6.93633 6.37787 7.35633C7.13387 9.56133 8.39388 11.4513 8.90837 11.4513C9.10787 11.4513 9.19187 11.3568 9.19187 10.8738V8.61633C9.13937 7.58733 8.58287 7.49283 8.58287 7.12533C8.58287 6.93633 8.72987 6.76833 8.97137 6.76833H11.3759C11.7014 6.76833 11.8169 6.93633 11.8169 7.33533V10.3698C11.8169 10.6953 11.9534 10.8108 12.0584 10.8108C12.2474 10.8108 12.4154 10.6953 12.7619 10.3383C13.8644 9.10983 14.6519 7.20933 14.6519 7.20933C14.7569 6.98883 14.9249 6.77883 15.3344 6.77883H16.8359C17.2979 6.77883 17.4029 7.02033 17.2979 7.34583C17.1089 8.23833 15.2399 10.8738 15.2609 10.8738C15.0929 11.1363 15.0299 11.2518 15.2609 11.5563C15.4184 11.7768 15.9539 12.2388 16.3109 12.6483C16.9619 13.3938 17.4659 14.0133 17.6024 14.4438C17.7179 14.8743 17.5079 15.0948 17.0669 15.0948Z'
        fill='white'
      />
    </svg>
  );
}
