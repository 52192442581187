export const PaymentIcon1 = () => {
  return (
    <svg
      width='31'
      height='32'
      viewBox='0 0 31 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.7695 15.1964V5.98467H17.9248C18.5612 5.95429 19.1969 6.05788 19.7895 6.28855C20.3822 6.51921 20.9181 6.87166 21.3617 7.32241C22.203 8.27831 22.6343 9.51901 22.5646 10.7825C22.5646 13.7101 20.8996 15.1757 17.5697 15.1795L12.7695 15.1964ZM17.8389 1.03447C19.963 0.995458 22.0696 1.4195 24.008 2.27626C25.6643 2.99861 27.0662 4.18836 28.0367 5.69526C29.0073 7.20216 29.5029 8.95865 29.4612 10.743C29.4612 13.6499 28.453 15.8851 26.3795 17.5502C24.3059 19.2153 21.3387 20.1128 17.6785 20.1128H11.727V23.3809H18.7096V27.2587H11.727V30.9727H5.99896V27.2699H1.55396V23.3922H5.99896V20.1241H1.55396V16.2237H5.99896V1.03447H17.8389ZM11.727 16.2237H17.5697C21.4762 16.2237 23.6128 14.3046 23.6128 10.7994C23.6734 9.26412 23.1248 7.76595 22.0834 6.62249C21.5471 6.06551 20.8973 5.62667 20.1766 5.33482C19.4559 5.04297 18.6807 4.90472 17.9019 4.92915H11.727V16.2181V16.2237ZM17.8389 0.00153803H4.93926V15.1964H0.5V21.1513H4.93926V22.3649H0.5V28.2803H4.93926V32H12.7695V28.2803H19.7521V22.3649H12.7695V21.1513H17.6785C21.608 21.1513 24.7279 20.2219 27.0382 18.363C29.3485 16.5041 30.5018 13.9772 30.4979 10.7825C30.5382 8.80147 29.9854 6.85267 28.9087 5.17931C27.8319 3.50596 26.2789 2.18216 24.4434 1.37314C22.3726 0.438069 20.1165 -0.0304903 17.8389 0.00153803Z'
        fill='currentColor'
      />
    </svg>
  );
};
