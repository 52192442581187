export function ArrowRight() {
  return (
    <svg
      width='8'
      height='16'
      viewBox='0 0 8 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.927981 15.2969C0.735981 15.2969 0.575981 15.2329 0.447981 15.1049C0.319981 14.9769 0.255981 14.8329 0.255981 14.6729C0.255981 14.5129 0.319981 14.4009 0.447981 14.3369L6.30398 8.09687L0.447981 1.95288C0.319981 1.82487 0.255981 1.68087 0.255981 1.52087C0.255981 1.36088 0.319981 1.21688 0.447981 1.08887C0.575981 0.960875 0.719981 0.896874 0.879981 0.896874C1.03998 0.896874 1.18398 0.960875 1.31198 1.08887L7.55198 7.71287C7.67998 7.84088 7.74398 7.98487 7.74398 8.14488C7.74398 8.30487 7.67998 8.44887 7.55198 8.57687L1.31198 15.1049C1.18398 15.2329 1.05598 15.2969 0.927981 15.2969Z'
        fill='#151618'
      />
    </svg>
  );
}
