export const PaymentIcon18 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24712)'>
        <path
          d='M34.1817 1.57227H2.81867C1.99385 1.57227 1.3252 2.24092 1.3252 3.06574V29.9483C1.3252 30.7731 1.99385 31.4418 2.81867 31.4418H34.1817C35.0065 31.4418 35.6752 30.7731 35.6752 29.9483V3.06574C35.6752 2.24092 35.0065 1.57227 34.1817 1.57227Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.9479 12.761C28.9479 13.7513 28.5545 14.701 27.8543 15.4011C27.1541 16.1013 26.2044 16.4947 25.2141 16.4947C24.224 16.4947 23.2743 16.1013 22.5741 15.4011C21.8739 14.701 21.4805 13.7513 21.4805 12.761C21.4805 11.7708 21.8739 10.8211 22.5741 10.1209C23.2743 9.4207 24.224 9.02734 25.2141 9.02734C26.2044 9.02734 27.1541 9.4207 27.8543 10.1209C28.5545 10.8211 28.9479 11.7708 28.9479 12.761Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.1947 6.05273H7.29914C6.47432 6.05273 5.80566 6.72139 5.80566 7.54621V25.468C5.80566 26.2928 6.47432 26.9614 7.29914 26.9614H31.1947C32.0196 26.9614 32.6883 26.2928 32.6883 25.468V7.54621C32.6883 6.72139 32.0196 6.05273 31.1947 6.05273Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.31152 31.4414V34.4284H8.79196V31.4414'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.208 31.4414V34.4284H32.6885V31.4414'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.9741 19.8678C22.7678 19.8678 22.6006 19.7005 22.6006 19.4944C22.6006 19.2882 22.7678 19.1211 22.9741 19.1211'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M22.9746 19.8678C23.1807 19.8678 23.348 19.7005 23.348 19.4944C23.348 19.2882 23.1807 19.1211 22.9746 19.1211'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M22.9741 23.6002C22.7678 23.6002 22.6006 23.4331 22.6006 23.2269C22.6006 23.0206 22.7678 22.8535 22.9741 22.8535'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M22.9746 23.6002C23.1807 23.6002 23.348 23.4331 23.348 23.2269C23.348 23.0206 23.1807 22.8535 22.9746 22.8535'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M27.4544 23.6002C27.2483 23.6002 27.0811 23.4331 27.0811 23.2269C27.0811 23.0206 27.2483 22.8535 27.4544 22.8535'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M27.4551 23.6002C27.6613 23.6002 27.8284 23.4331 27.8284 23.2269C27.8284 23.0206 27.6613 22.8535 27.4551 22.8535'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M27.4544 19.8678C27.2483 19.8678 27.0811 19.7005 27.0811 19.4944C27.0811 19.2882 27.2483 19.1211 27.4544 19.1211'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M27.4541 19.8678C27.6604 19.8678 27.8275 19.7005 27.8275 19.4944C27.8275 19.2882 27.6604 19.1211 27.4541 19.1211'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M25.2143 13.1471C25.0081 13.1471 24.8408 12.98 24.8408 12.7738C24.8408 12.5676 25.0081 12.4004 25.2143 12.4004'
          stroke='currentColor'
          strokeWidth='2'
        />
        <path
          d='M25.2139 13.1471C25.4201 13.1471 25.5872 12.98 25.5872 12.7738C25.5872 12.5676 25.4201 12.4004 25.2139 12.4004'
          stroke='currentColor'
          strokeWidth='2'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24712'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
