export function DoorArrowLeftOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.7537 20.3656C17.9915 20.7732 17.2419 20.8999 15.6722 20.8999H13.0189C13.0126 20.9 13.0063 20.9 13 20.9C12.5029 20.9 12.1 20.4971 12.1 20C12.1 19.503 12.5029 19.1 13 19.1C13 19.1 13 19.1 13 19.1L15.6722 19.0999C17.1589 19.0999 17.5428 18.9719 17.9048 18.7783C18.2407 18.5987 18.4989 18.3405 18.6785 18.0047C18.8721 17.6427 19 17.2588 19 15.7721L19 8.22762C19 6.74096 18.8721 6.35702 18.6785 5.99504C18.4989 5.65919 18.2407 5.401 17.9048 5.22138C17.5428 5.02779 17.1589 4.89985 15.6722 4.89985L13.0189 4.89985C13.0126 4.89998 13.0063 4.90005 13 4.90005C12.5029 4.90005 12.1 4.4971 12.1 4.00005C12.1 3.50299 12.5029 3.10005 13 3.10005C13 3.10005 13 3.10005 13 3.10005L15.6722 3.09985C17.2419 3.09985 17.9915 3.22651 18.7537 3.63412C19.4032 3.98149 19.9184 4.49663 20.2657 5.14616C20.6733 5.90831 20.8 6.65797 20.8 8.22762V15.7721C20.8 17.3417 20.6733 18.0914 20.2657 18.8535C19.9184 19.5031 19.4032 20.0182 18.7537 20.3656Z'
        fill='currentColor'
      />
      <path
        d='M9.3636 15.3636L11.8271 12.9001H3.9C3.40294 12.9001 3 12.4971 3 12.0001C3 11.503 3.40294 11.1001 3.9 11.1001H11.8273L9.3636 8.63636C9.01213 8.28489 9.01213 7.71504 9.3636 7.36357C9.71508 7.01209 10.2849 7.01209 10.6364 7.36357L14.6364 11.3636C14.9879 11.715 14.9879 12.2849 14.6364 12.6364L10.6364 16.6364C10.2849 16.9878 9.71508 16.9878 9.3636 16.6364C9.01213 16.2849 9.01213 15.715 9.3636 15.3636Z'
        fill='currentColor'
      />
    </svg>
  );
}
