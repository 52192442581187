import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FilialService } from '../../../../shared/api/model/service/filial.service';
import { FILIAL_QUERY } from '../../../../shared/const/query.const';
import { IFilial } from '../filial.interface';

export const useUpdateFilial = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IFilial) => FilialService.updateFilial(data),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [FILIAL_QUERY] });
      }
    }
  });
};
