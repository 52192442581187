import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SubproductsService } from '../../../../shared/api/model/service/subproducts.service';
import { SUBPRODUCTS_ALL_QUERY, SUBPRODUCTS_QUERY, SUBPRODUCT_QUERY } from '../../../../shared/const/query.const';
import { IService, ISubproductDeleteData } from '../subproducts.interface';

export function useCreateService() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: IService) => SubproductsService.createSubproduct(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCT_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCTS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCTS_ALL_QUERY] });
      }
    }
  });
}

export function useUpdateService() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: IService) => SubproductsService.updateSubproduct(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCT_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCTS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCTS_ALL_QUERY] });
      }
    }
  });
}

export function useDeleteService() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (values: ISubproductDeleteData) => SubproductsService.deleteSubproduct(values),
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCT_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCTS_QUERY] });
        await queryClient.invalidateQueries({ queryKey: [SUBPRODUCTS_ALL_QUERY] });
      }
    }
  });
}
