export const PaymentIcon16 = () => {
  return (
    <svg
      width='25'
      height='36'
      viewBox='0 0 25 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.538 18.958H7.1801L7.0647 19.2968L5.4971 23.899H1.70965L8.78373 3.76575L8.78442 3.76377C9.13448 2.75398 9.486 1.94907 10.029 1.38851C10.5461 0.854705 11.2811 0.5 12.506 0.5C13.7284 0.5 14.4525 0.859693 14.9573 1.3941C15.489 1.95702 15.8258 2.76096 16.1632 3.75964C16.1632 3.75977 16.1633 3.7599 16.1633 3.76003L22.9258 23.908H18.8116L17.3475 19.3064L17.2366 18.958H16.871H7.538ZM12.272 4.9H11.912L11.7978 5.24137L8.48583 15.1414L8.26549 15.8H8.96H15.53H16.2132L16.0066 15.1488L12.8656 5.24879L12.7549 4.9H12.389H12.272ZM1 31.577H23.661V35.5H1V31.577Z'
        fill='white'
        stroke='currentColor'
      />
    </svg>
  );
};
