import React from 'react';

export function SwitchIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 0.5C13.6944 0.5 17.5 4.30558 17.5 9C17.5 13.6944 13.6944 17.5 9 17.5C4.30558 17.5 0.5 13.6944 0.5 9C0.5 4.30558 4.30558 0.5 9 0.5ZM6.00759 12.5003L2.93671 12.5004C3.90526 14.1745 5.54721 15.4102 7.49214 15.8372C6.83474 14.7424 6.33983 13.6301 6.00759 12.5003ZM15.0633 12.5004L11.9924 12.5003C11.6602 13.6301 11.1653 14.7424 10.5089 15.8363C12.4528 15.4102 14.0947 14.1745 15.0633 12.5004ZM10.42 12.5002H7.58C7.9109 13.4847 8.38383 14.4591 9 15.4244C9.61617 14.4591 10.0891 13.4847 10.42 12.5002ZM5.6637 7.00046L2.28989 6.99995C2.10128 7.63368 2 8.30501 2 9C2 9.69535 2.10139 10.367 2.29019 11.001L5.66387 11.0005C5.5547 10.3393 5.5 9.67234 5.5 9C5.5 8.328 5.55465 7.66142 5.6637 7.00046ZM10.8129 6.99999H7.18706C7.06224 7.66184 7 8.3284 7 9C7 9.67194 7.0623 10.3388 7.18725 11.001H10.8128C10.9377 10.3388 11 9.67194 11 9C11 8.3284 10.9378 7.66184 10.8129 6.99999ZM15.7101 6.99995L12.3363 7.00046C12.4454 7.66142 12.5 8.328 12.5 9C12.5 9.67234 12.4453 10.3393 12.3361 11.0005L15.7098 11.001C15.8986 10.367 16 9.69535 16 9C16 8.30501 15.8987 7.63368 15.7101 6.99995ZM7.49109 2.16368L7.36278 2.19251C5.47409 2.64509 3.88307 3.86326 2.93614 5.50058L6.00729 5.50067C6.33953 4.37058 6.83455 3.25788 7.49109 2.16368ZM9 2.57564C8.38363 3.54126 7.91057 4.51595 7.57966 5.50078H10.4203C10.0894 4.51595 9.61637 3.54126 9 2.57564ZM10.5079 2.16281L10.5597 2.24896C11.1911 3.31568 11.6691 4.39993 11.9927 5.50067L15.0639 5.50058C14.0954 3.82601 12.4532 2.58988 10.5079 2.16281Z'
        fill='currentColor'
      />
    </svg>
  );
}
