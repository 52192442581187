import React from 'react';

export function ManagementIcon() {
  return (
    <svg
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.879597 15.7664C-0.292508 14.5954 -0.292507 12.6968 0.879599 11.5257L3.01817 9.38909C3.17331 9.23409 3.22938 9.00728 3.17913 8.79381C3.06197 8.29616 3 7.77724 3 7.24386C3 3.51933 6.02207 0.5 9.75 0.5C10.578 0.5 11.3712 0.648956 12.1042 0.921511C12.4842 1.0628 12.5606 1.5452 12.2746 1.83251L9.67477 4.4442C9.56283 4.55664 9.49999 4.70884 9.49999 4.8675V6.9C9.49999 7.23137 9.76862 7.5 10.1 7.5H12.1288C12.2874 7.5 12.4396 7.43718 12.552 7.32528L15.1676 4.72232C15.4548 4.43648 15.9369 4.51274 16.0784 4.89247C16.351 5.62461 16.5 6.41685 16.5 7.24386C16.5 10.9684 13.4779 13.9877 9.75 13.9877C9.22381 13.9877 8.71168 13.9276 8.22013 13.8138C8.00753 13.7645 7.78205 13.8207 7.62768 13.9749L5.47935 16.1213C4.30724 17.2923 2.40688 17.2923 1.23478 16.1213L0.879597 15.7664ZM9.75 12.4877C12.6508 12.4877 15 10.1387 15 7.24386C15 7.16558 14.9983 7.08774 14.9949 7.01039L13.6101 8.3885C13.2166 8.78014 12.684 9 12.1288 9H10.1C8.94019 9 7.99999 8.0598 7.99999 6.9V4.8675C7.99999 4.3122 8.21993 3.7795 8.6117 3.38595L9.9862 2.00519C9.90794 2.00174 9.8292 2 9.75 2C6.84922 2 4.5 4.34904 4.5 7.24386C4.5 7.66067 4.54839 8.06427 4.63922 8.4501C4.79831 9.12591 4.6318 9.89728 4.07835 10.4502L1.93978 12.5869C1.3541 13.172 1.3541 14.1201 1.93977 14.7053L2.29496 15.0601C2.88139 15.646 3.83274 15.646 4.41917 15.0601L6.5675 12.9138C7.11817 12.3636 7.88509 12.1965 8.55844 12.3524C8.93983 12.4407 9.33848 12.4877 9.75 12.4877Z'
        fill='currentColor'
      />
    </svg>
  );
}
