import React from 'react';

export function MailStackOutline() {
  return (
    <svg
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.19999 2.1999C5.19999 1.20579 6.00588 0.399902 6.99999 0.399902H13C13.9941 0.399902 14.8 1.20579 14.8 2.1999H5.19999Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.81402 4.5625C3.59346 4.19218 4.36816 3.9999 6.49861 3.9999H13.5014C15.6318 3.9999 16.4065 4.19218 17.186 4.5625C17.9537 4.92723 18.5561 5.46247 18.9667 6.14446C19.3836 6.83689 19.6 7.52511 19.6 9.41775V12.9821C19.6 14.8747 19.3836 15.5629 18.9667 16.2553C18.5561 16.9373 17.9537 17.4726 17.186 17.8373C16.4065 18.2076 15.6318 18.3999 13.5014 18.3999H6.49861C4.36816 18.3999 3.59346 18.2076 2.81402 17.8373C2.04633 17.4726 1.44385 16.9373 1.03328 16.2553C0.616432 15.5629 0.399994 14.8747 0.399994 12.9821V9.41775C0.399994 7.52511 0.616432 6.83689 1.03328 6.14446C1.44385 5.46247 2.04633 4.92723 2.81402 4.5625ZM14.0038 10.5688L17.4526 7.12009C17.5604 7.30534 17.6266 7.4611 17.6797 7.70853C17.7473 8.0235 17.8 8.52392 17.8 9.41775V12.9821C17.8 13.8759 17.7473 14.3763 17.6797 14.6913C17.6222 14.9594 17.5492 15.1199 17.4246 15.327C17.209 15.6851 16.8844 15.9878 16.4135 16.2115C15.9811 16.4169 15.5135 16.5999 13.5014 16.5999H6.49861C4.48644 16.5999 4.01884 16.4169 3.58647 16.2115C3.1156 15.9878 2.79097 15.6851 2.5754 15.327C2.45076 15.1199 2.37781 14.9594 2.32029 14.6913C2.25273 14.3763 2.19999 13.8759 2.19999 12.9821V9.41775C2.19999 8.52392 2.25273 8.0235 2.32029 7.70853C2.37336 7.4611 2.43957 7.30534 2.5474 7.1201L5.99614 10.5688C6.56752 11.1402 7.0348 11.6075 7.4456 11.9563C7.87081 12.3172 8.29434 12.6038 8.79483 12.7664C9.57812 13.0209 10.4219 13.0209 11.2052 12.7664C11.7056 12.6038 12.1292 12.3172 12.5544 11.9563C12.9652 11.6075 13.4325 11.1402 14.0038 10.5688ZM7.24228 9.26939C7.8468 9.87392 8.26242 10.2885 8.61052 10.5841C8.95059 10.8728 9.16656 10.9945 9.35106 11.0545C9.77283 11.1915 10.2272 11.1915 10.6489 11.0545C10.8334 10.9945 11.0494 10.8728 11.3895 10.5841C11.7376 10.2885 12.1532 9.87392 12.7577 9.26939L16.0041 6.02304C15.5931 5.89403 14.9444 5.7999 13.5014 5.7999H6.49861C5.05554 5.7999 4.40689 5.89403 3.99593 6.02304L7.24228 9.26939Z'
        fill='currentColor'
      />
    </svg>
  );
}
