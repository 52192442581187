import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { useFormik } from 'formik';

import { Wrapper } from './CreateKassaTransactionModal.styled';

import CommonButton from '../../../../../../shared/ui/Button/ui/CommonButton';
import { apiPost } from '../../../../../../utils/apiInstance';
import { FlexWithAlign, FormStyle } from '../../../../../../utils/styleUtils';
import Dropdown from '../../../../../../shared/ui/Dropdown/ui/Dropdown';
import { useTypedSelector } from '../../../../../../shared/lib/hooks/useTypedSelector';
import { useActions } from '../../../../../../shared/lib/hooks/useActions';
import { Input, InputType, Text, Textarea, TextType } from '../../../../../../shared/ui';
import { MODAL_GAP } from '../../../../../../styles';
import useMatchMedia from '../../../../../../shared/lib/hooks/useMatchMedia';
import { cn } from '../../../../../../shared/lib';

interface IProps {
  closeModal?: () => void;
}

const CreateKassaTransactionModal: React.FC<IProps> = (props) => {
  const { closeModal } = props;
  const { t } = useTranslation();
  const [kassaInError, setKassaInError] = useState(false);
  const [kassaOutError, setKassaOutError] = useState(false);
  const [summError, setSummError] = useState(false);
  const [pending, setPending] = useState(false);
  const { kassa } = useTypedSelector((state) => state.finance);
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);

  const { fetchListKassaAsync } = useActions();
  const initialValues = {
    kassain: '',
    kassaout: '',
    comment: '',
    summ: parseInt('')
  };

  const fetchData = async () => {
    await fetchListKassaAsync();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values: any) => {}
  });
  const filteredSumm = kassa?.find((item) => item.id === formik.values.kassaout)?.summa;

  const transactionKassa = async (values: any) => {
    setPending(true);
    const res = await apiPost('/kassinkass', values);
    if (res?.status === 200) {
      setPending(false);
      fetchListKassaAsync();
      closeModal!();
      toast.success(`Перевод средств выполнен`);
    } else {
      setPending(false);
      toast.error(res.data.description);
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (
      formik.values.kassain === '' ||
      formik.values.kassaout === '' ||
      formik.values.summ === 0 ||
      formik.values.summ === undefined ||
      !formik.values.summ ||
      pending
    ) {
      if (formik.values.kassain === '') {
        setKassaInError(true);
      }
      if (formik.values.kassaout === '') {
        setKassaOutError(true);
      }
      if (formik.values.summ === 0 || formik.values.summ === undefined || !formik.values.summ) {
        setSummError(true);
      }

      return;
    }
    transactionKassa(formik.values);
  };

  useEffect(() => {
    if (kassaInError) {
      formik.values.kassain !== '' && setKassaInError(false);
    }
  }, [formik.values.kassain]);

  useEffect(() => {
    if (kassaOutError) {
      formik.values.kassaout !== '' && setKassaOutError(false);
    }
  }, [formik.values.kassaout]);
  useEffect(() => {
    if (summError) {
      (formik.values.summ !== 0 || formik.values.summ !== undefined) && setSummError(false);
    }
  }, [formik.values.summ]);

  return (
    <Wrapper>
      {isMobile ? null : (
        <Text
          $tag={TextType.H5}
          fontWeight={700}
        >
          {t('Перевод средств')}
        </Text>
      )}

      <FormStyle onSubmit={handleSubmit}>
        <FlexWithAlign
          $column
          $gap={MODAL_GAP}
          $align={'center'}
        >
          <Dropdown
            size={InputType.M}
            onChange={(option: any) => {
              formik.setFieldValue('kassaout', option.value);
            }}
            onBlur={formik.handleBlur}
            options={kassa}
            getOptionLabel={(option) => (
              <FlexWithAlign
                $align={'center'}
                $gap={'4px'}
              >
                <span>{option.label},</span>
                <span>
                  {new Intl.NumberFormat('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                  }).format(option.additional?.summa)}
                </span>
              </FlexWithAlign>
            )}
            error={kassaOutError && 'Выберите кассу списания'}
            className={'kassa'}
            currentValue={formik.values.kassaout}
            labelTitle={t('Касса списания*')}
            formik={formik}
          >
            {/*<IconWrapper className={'h22 white icon-wrapper'}>*/}
            {/*  <IconInstance name={EIcon.finans} />*/}
            {/*</IconWrapper>*/}
          </Dropdown>
          <Dropdown
            size={InputType.M}
            onChange={(option: any) => {
              formik.setFieldValue('kassain', option.value);
            }}
            error={kassaInError && 'Выберите кассу зачисления'}
            onBlur={formik.handleBlur}
            options={kassa}
            getOptionLabel={(option) => (
              <FlexWithAlign
                $align={'center'}
                $gap={'4px'}
              >
                <span>{option.label},</span>
                <span>
                  {new Intl.NumberFormat('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                  }).format(option.additional?.summa)}
                </span>
              </FlexWithAlign>
            )}
            className={'kassa'}
            currentValue={formik.values.kassain}
            labelTitle={t('Касса зачисления*')}
            formik={formik}
          >
            {/*<IconWrapper className={'h22 white icon-wrapper'}>*/}
            {/*  <IconInstance name={EIcon.finans} />*/}
            {/*</IconWrapper>*/}
          </Dropdown>
        </FlexWithAlign>

        <Input
          size={InputType.M}
          label={t('Сумма списания*')}
          type={'currency'}
          error={summError && 'Укажите сумму списания'}
          name='summ'
          max={filteredSumm}
          value={formik.values.summ}
          onChange={formik.handleChange}
        />
        <FlexWithAlign className='formElement textarea'>
          <Textarea
            placeholder={'Комментарий'}
            name={'comment'}
            content={formik.values.comment}
            onChange={formik.handleChange}
          />
        </FlexWithAlign>

        <FlexWithAlign
          $justify={'flex-start'}
          $gap={MODAL_GAP}
          className={cn(isMobile && 'column-reverse')}
        >
          <CommonButton
            typeBtn='gray'
            type='submit'
            fullWidth={isMobile}
            size={'M'}
            onClick={(e) => {
              e.preventDefault();
              closeModal?.();
            }}
          >
            {t('Отменить')}
          </CommonButton>
          <CommonButton
            typeBtn='primary'
            type='submit'
            fullWidth={isMobile}
            size={'M'}
          >
            {t('Перевести средства')}
          </CommonButton>
        </FlexWithAlign>
      </FormStyle>
    </Wrapper>
  );
};

export default CreateKassaTransactionModal;
