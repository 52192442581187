import styled from 'styled-components';
import { scaleIn } from '../../../../../../components/shared/modal/create/service/sidebar/CreateServiceSidebar.styled';
export const EmojiWrapper = styled.div`
  position: absolute;
  bottom: 120%;
  left: 20px;
  display: flex;
  gap: 10px;
  max-width: 420px;
  flex-wrap: wrap;
  background: ${(props) => props.theme.color.elevation01};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05), 0px -4px 8px 0px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 8px;
  max-height: 300px;
  overflow-y: auto;
  animation: ${scaleIn} .3s ease;
  transform-origin: bottom left;
  > img {
    width: 26px;
    height: 26px;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      transform: scale(1.2);
    }
  }
  @media (max-width: 767px) {
    transform-origin: right center;
  }
`;
