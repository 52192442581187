import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import { animated, useSpring } from '@react-spring/web';

import useMatchMedia from '../../lib/hooks/useMatchMedia';
import { useTypedSelector } from '../../lib/hooks/useTypedSelector';
import { getRouteCalendarCabinet, getRouteCalendarEmployee } from '../../const';

interface IProps {
  children: ReactNode;
}

const AnimatedPage: FC<IProps> = ({ children }) => {
  const location = useLocation();
  const { ispathname } = useTypedSelector((state) => state.recording);
  const [isMobile] = useMatchMedia(['(max-width: 767px)']);
  const currentPath = location.pathname;
  const isAlltoMore = ispathname !== '/more' && currentPath === '/more';
  const isGraphToReco = ispathname === '/timetable' && currentPath === getRouteCalendarEmployee();
  const isRecoToMess = ispathname === getRouteCalendarEmployee() && currentPath === '/messenger-list';
  const isGraphToMess = ispathname === '/timetable' && currentPath === '/messenger-list';
  const isEmplToCab = ispathname === getRouteCalendarEmployee() && currentPath === getRouteCalendarCabinet();

  const springProps = useSpring({
    from: { x: isGraphToReco || isRecoToMess || isGraphToMess || isEmplToCab || isAlltoMore ? '100%' : '-100%' },
    to: {
      x: isGraphToReco || isRecoToMess || isGraphToMess || isEmplToCab || isAlltoMore ? '0' : '0'
    },
    config: { duration: 100 }
  });

  return (
    <>{!isMobile ? <>{children}</> : <animated.div style={{ ...springProps, height: '100%', width: '100%' }}>{children}</animated.div>}</>
  );
};

export default AnimatedPage;
