import { PaymentIcon1 } from './payments/PaymentIcon1';
import { PaymentIcon10 } from './payments/PaymentIcon10';
import { PaymentIcon11 } from './payments/PaymentIcon11';
import { PaymentIcon12 } from './payments/PaymentIcon12';
import { PaymentIcon13 } from './payments/PaymentIcon13';
import { PaymentIcon14 } from './payments/PaymentIcon14';
import { PaymentIcon15 } from './payments/PaymentIcon15';
import { PaymentIcon16 } from './payments/PaymentIcon16';
import { PaymentIcon17 } from './payments/PaymentIcon17';
import { PaymentIcon18 } from './payments/PaymentIcon18';
import { PaymentIcon19 } from './payments/PaymentIcon19';
import { PaymentIcon2 } from './payments/PaymentIcon2';
import { PaymentIcon20 } from './payments/PaymentIcon20';
import { PaymentIcon21 } from './payments/PaymentIcon21';
import { PaymentIcon22 } from './payments/PaymentIcon22';
import { PaymentIcon23 } from './payments/PaymentIcon23';
import { PaymentIcon24 } from './payments/PaymentIcon24';
import { PaymentIcon25 } from './payments/PaymentIcon25';
import { PaymentIcon26 } from './payments/PaymentIcon26';
import { PaymentIcon27 } from './payments/PaymentIcon27';
import { PaymentIcon28 } from './payments/PaymentIcon28';
import { PaymentIcon29 } from './payments/PaymentIcon29';
import { PaymentIcon3 } from './payments/PaymentIcon3';
import { PaymentIcon30 } from './payments/PaymentIcon30';
import { PaymentIcon31 } from './payments/PaymentIcon31';
import { PaymentIcon32 } from './payments/PaymentIcon32';
import { PaymentIcon33 } from './payments/PaymentIcon33';
import { PaymentIcon34 } from './payments/PaymentIcon34';
import { PaymentIcon35 } from './payments/PaymentIcon35';
import { PaymentIcon4 } from './payments/PaymentIcon4';
import { PaymentIcon5 } from './payments/PaymentIcon5';
import { PaymentIcon6 } from './payments/PaymentIcon6';
import { PaymentIcon7 } from './payments/PaymentIcon7';
import { PaymentIcon8 } from './payments/PaymentIcon8';
import { PaymentIcon9 } from './payments/PaymentIcon9';

export const EIcons = {
  icon1: <PaymentIcon1 />,
  icon2: <PaymentIcon2 />,
  icon3: <PaymentIcon3 />,
  icon4: <PaymentIcon4 />,
  icon5: <PaymentIcon5 />,
  icon6: <PaymentIcon6 />,
  icon7: <PaymentIcon7 />,
  icon8: <PaymentIcon8 />,
  icon9: <PaymentIcon9 />,
  icon10: <PaymentIcon10 />,
  icon11: <PaymentIcon11 />,
  icon12: <PaymentIcon12 />,
  icon13: <PaymentIcon13 />,
  icon14: <PaymentIcon14 />,
  icon15: <PaymentIcon15 />,
  icon16: <PaymentIcon16 />,
  icon17: <PaymentIcon17 />,
  icon18: <PaymentIcon18 />,
  icon19: <PaymentIcon19 />,
  icon20: <PaymentIcon20 />,
  icon21: <PaymentIcon21 />,
  icon22: <PaymentIcon22 />,
  icon23: <PaymentIcon23 />,
  icon24: <PaymentIcon24 />,
  icon25: <PaymentIcon25 />,
  icon26: <PaymentIcon26 />,
  icon27: <PaymentIcon27 />,
  icon28: <PaymentIcon28 />,
  icon29: <PaymentIcon29 />,
  icon30: <PaymentIcon30 />,
  icon31: <PaymentIcon31 />,
  icon32: <PaymentIcon32 />,
  icon33: <PaymentIcon33 />,
  icon34: <PaymentIcon34 />,
  icon35: <PaymentIcon35 />
};
