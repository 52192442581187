import React from 'react';

export function TelegramIcon() {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.51239 14.3184C9.41795 11.9193 13.3559 10.3377 15.3263 9.5735C20.9521 7.39166 22.1211 7.01265 22.883 7.00013C23.0506 6.99738 23.4253 7.03611 23.668 7.21974C23.873 7.3748 23.9294 7.58426 23.9563 7.73128C23.9833 7.87829 24.0169 8.21319 23.9902 8.47487C23.6854 11.4617 22.3662 18.7098 21.6951 22.055C21.4111 23.4705 20.852 23.9451 20.3107 23.9916C19.1343 24.0925 18.2409 23.2667 17.1015 22.5702C15.3186 21.4805 14.3113 20.8021 12.5807 19.7387C10.5806 18.5097 11.8772 17.8343 13.017 16.7304C13.3153 16.4415 18.4986 12.0455 18.5989 11.6467C18.6115 11.5968 18.6231 11.4109 18.5046 11.3127C18.3862 11.2146 18.2114 11.2481 18.0852 11.2748C17.9064 11.3127 15.0581 13.0681 9.54035 16.541C8.73187 17.0587 7.99958 17.3109 7.34347 17.2977C6.62016 17.2831 5.2288 16.9163 4.19447 16.6028C2.92583 16.2183 1.91753 16.015 2.00533 15.362C2.05106 15.0218 2.55341 14.674 3.51239 14.3184Z'
        fill='white'
      />
    </svg>
  );
}
