import { IAuthResponse, ITokens } from '../../../../store/redux/auth/auth.interface';
import { delCookie, getCookie } from '../../../../utils/cookies';

export const saveTokensStorage = (data: ITokens) => {
  getCookie(data.token);
};

export const saveToStorage = (data: IAuthResponse) => {
  saveTokensStorage(data);
};

export const removeTokensStorage = () => {
  delCookie('auth');
  delCookie('id');
};
