import React from 'react';

export function ClockIcon() {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.5 1C6.42472 1 1.5 5.92472 1.5 12C1.5 18.0753 6.42472 23 12.5 23C18.5753 23 23.5 18.0753 23.5 12C23.5 5.92472 18.5753 1 12.5 1ZM3.5 12C3.5 7.02928 7.52928 3 12.5 3C17.4707 3 21.5 7.02928 21.5 12C21.5 16.9707 17.4707 21 12.5 21C7.52928 21 3.5 16.9707 3.5 12ZM13.5 6C13.5 5.44772 13.0523 5 12.5 5C11.9477 5 11.5 5.44772 11.5 6V12C11.5 12.3788 11.714 12.725 12.0528 12.8944L16.0528 14.8944C16.5468 15.1414 17.1474 14.9412 17.3944 14.4472C17.6414 13.9532 17.4412 13.3526 16.9472 13.1056L13.5 11.382V6Z'
        fill='currentCOlor'
      />
    </svg>
  );
}
