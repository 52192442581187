import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActionName, ISidebarName, SidebarState } from './sidebar.interface';

const initialState: SidebarState = {
  showSidebar: false,
  sidebarName: '',
  sidebarPayload: undefined,
  actionName: '',
  classSidebar: undefined
};

export const sidebarSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openSidebar: (
      state,
      action: PayloadAction<{
        sidebarName: ISidebarName;
        sidebarPayload?: any;
        isDanger?: boolean;
        actionName?: IActionName;
        classSidebar?: string;
      }>
    ) => {
      state.showSidebar = true;
      state.sidebarName = action.payload.sidebarName;
      state.sidebarPayload = action.payload.sidebarPayload || undefined;
      state.actionName = action.payload.actionName || '';
    },
    closeSidebar: (state) => {
      state.showSidebar = false;
    }
  }
});

export const { reducer } = sidebarSlice;
