export const ColorBigIcon = () => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2547_16155)'>
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0H28V28H0V8Z'
          fill='#FFCD4B'
        />
        <path
          d='M0 28H28V56H8C3.58172 56 0 52.4183 0 48V28Z'
          fill='#82DB69'
        />
        <path
          d='M28 0H48C52.4183 0 56 3.58172 56 8V28H28V0Z'
          fill='#FD6D84'
        />
        <path
          d='M28 28H56V48C56 52.4183 52.4183 56 48 56H28V28Z'
          fill='#5CB3FA'
        />
      </g>
      <defs>
        <clipPath id='clip0_2547_16155'>
          <rect
            width='56'
            height='56'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
