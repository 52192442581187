import React from 'react';
import { useTranslation } from 'react-i18next';

import { TRANSLATIONS_API } from '../../../shared/const';
import { Text, TextType } from '../../../shared/ui';
import CommonButton from '../../../shared/ui/Button/ui/CommonButton';

export const ErrorPage = () => {
  const { t } = useTranslation(TRANSLATIONS_API.error.translate);

  const handleReloadPage = () => {
    location.reload();
  };

  return (
    <div>
      <Text $tag={TextType.H1}>{t(TRANSLATIONS_API.error.components.error_page_title)} произошла непредвиденная ошибка</Text>
      <CommonButton onClick={handleReloadPage}>{t(TRANSLATIONS_API.error.components.error_page_button)}</CommonButton>
    </div>
  );
};
