export const PaymentIcon19 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24732)'>
        <path
          d='M35.7559 12.75L26.7559 18.75H19.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.7559 17.25L26.7559 23.25H19.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M35.7559 21.75L26.7559 27.75H19.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.25 27.75H8.75V14.25C8.75 14.0171 8.80422 13.7875 8.90836 13.5792C9.0125 13.3709 9.16371 13.1897 9.35 13.05L16.25 8.25H26.75L19.85 13.05C19.6637 13.1897 19.5125 13.3709 19.4084 13.5792C19.3042 13.7875 19.25 14.0171 19.25 14.25V27.75Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.75586 18.75H1.25586'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.75586 23.25H1.25586'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.75586 27.75H1.25586'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.7559 8.25H35.7559L26.7559 14.25H19.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.75586 14.25H1.25586L10.2559 8.25H16.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24732'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
