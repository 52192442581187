import { FULFILLED, PENDING, REJECTED } from '../../../utils/state';
import { createSlice } from '@reduxjs/toolkit';
import { LessonsState } from './lessons.interface';
import { fetchLessonsAsync } from './lessons.actions';

const initialState: LessonsState = {
  lessons: [],
  state: PENDING
};

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLessonsAsync.pending, (state) => {
        state.state = PENDING;
      })
      .addCase(fetchLessonsAsync.fulfilled, (state, action) => {
        state.lessons = action.payload;
        state.state = FULFILLED;
      })
      .addCase(fetchLessonsAsync.rejected, (state) => {
        state.state = REJECTED;
      });
  }
});

export const { reducer } = lessonsSlice;
