import React from 'react';

export function RightWithSquareIcon() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.53884 5.26397C8.18737 4.9125 7.61752 4.9125 7.26605 5.26397C6.91457 5.61544 6.91457 6.18529 7.26605 6.53676L9.72926 8.99998L7.26409 11.4651C6.91262 11.8166 6.91262 12.3865 7.26409 12.7379C7.61556 13.0894 8.18541 13.0894 8.53688 12.7379L11.6384 9.63637C11.9899 9.2849 11.9899 8.71505 11.6384 8.36358L8.53884 5.26397Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.5384 2.36144e-07H6.4616C5.5141 -8.69404e-06 4.74834 -1.59107e-05 4.12792 0.0506743C3.48863 0.102906 2.92481 0.213413 2.40244 0.479572C1.57453 0.901415 0.901415 1.57453 0.479572 2.40244C0.213413 2.92481 0.102906 3.48863 0.0506744 4.12792C-1.59144e-05 4.74834 -8.69404e-06 5.51409 2.36144e-07 6.46158V11.5384C-8.69404e-06 12.4859 -1.59107e-05 13.2517 0.0506743 13.8721C0.102906 14.5114 0.213413 15.0752 0.479572 15.5976C0.901415 16.4255 1.57453 17.0986 2.40244 17.5204C2.92481 17.7866 3.48863 17.8971 4.12792 17.9493C4.74834 18 5.51409 18 6.46159 18H11.5384C12.4859 18 13.2517 18 13.8721 17.9493C14.5114 17.8971 15.0752 17.7866 15.5976 17.5204C16.4255 17.0986 17.0986 16.4255 17.5204 15.5976C17.7866 15.0752 17.8971 14.5114 17.9493 13.8721C18 13.2517 18 12.4859 18 11.5384V6.46159C18 5.51409 18 4.74834 17.9493 4.12792C17.8971 3.48863 17.7866 2.92481 17.5204 2.40244C17.0986 1.57453 16.4255 0.901415 15.5976 0.479572C15.0752 0.213413 14.5114 0.102906 13.8721 0.0506744C13.2517 -1.59144e-05 12.4859 -8.69404e-06 11.5384 2.36144e-07ZM3.21963 2.08338C3.44595 1.96807 3.74652 1.88783 4.2745 1.8447C4.81298 1.8007 5.50506 1.8 6.5 1.8H11.5C12.4949 1.8 13.187 1.8007 13.7255 1.8447C14.2535 1.88783 14.5541 1.96807 14.7804 2.08338C15.2696 2.33265 15.6673 2.7304 15.9166 3.21963C16.0319 3.44595 16.1122 3.74652 16.1553 4.2745C16.1993 4.81298 16.2 5.50506 16.2 6.5V11.5C16.2 12.4949 16.1993 13.187 16.1553 13.7255C16.1122 14.2535 16.0319 14.5541 15.9166 14.7804C15.6673 15.2696 15.2696 15.6673 14.7804 15.9166C14.5541 16.0319 14.2535 16.1122 13.7255 16.1553C13.187 16.1993 12.4949 16.2 11.5 16.2H6.5C5.50506 16.2 4.81298 16.1993 4.2745 16.1553C3.74652 16.1122 3.44595 16.0319 3.21963 15.9166C2.7304 15.6673 2.33265 15.2696 2.08338 14.7804C1.96807 14.5541 1.88783 14.2535 1.8447 13.7255C1.8007 13.187 1.8 12.4949 1.8 11.5V6.5C1.8 5.50506 1.8007 4.81298 1.8447 4.2745C1.88783 3.74652 1.96807 3.44595 2.08338 3.21963C2.33265 2.7304 2.7304 2.33265 3.21963 2.08338Z'
        fill='currentColor'
      />
    </svg>
  );
}
