import React from 'react';

export function Planet() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.53703 8.25H5.0128C5.08326 6.19424 5.44133 4.32004 6.0041 2.89148C6.13584 2.55704 6.28139 2.24042 6.44097 1.94791C3.78379 2.91234 1.82637 5.33582 1.53703 8.25ZM9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM9 1.5C8.82831 1.5 8.58572 1.5853 8.28378 1.90949C7.98082 2.23477 7.67346 2.74635 7.39971 3.44126C6.91658 4.66766 6.58375 6.34835 6.51373 8.25H11.4863C11.4163 6.34835 11.0834 4.66766 10.6003 3.44126C10.3265 2.74635 10.0192 2.23477 9.71622 1.90949C9.41428 1.5853 9.17169 1.5 9 1.5ZM12.9872 8.25C12.9167 6.19424 12.5587 4.32004 11.9959 2.89148C11.8642 2.55704 11.7186 2.24042 11.559 1.94791C14.2162 2.91234 16.1736 5.33582 16.463 8.25H12.9872ZM11.4863 9.75H6.51373C6.58375 11.6516 6.91658 13.3323 7.39971 14.5587C7.67346 15.2537 7.98082 15.7652 8.28378 16.0905C8.58572 16.4147 8.82831 16.5 9 16.5C9.17169 16.5 9.41428 16.4147 9.71622 16.0905C10.0192 15.7652 10.3265 15.2537 10.6003 14.5587C11.0834 13.3323 11.4163 11.6516 11.4863 9.75ZM11.559 16.0521C11.7186 15.7596 11.8642 15.443 11.9959 15.1085C12.5587 13.68 12.9167 11.8058 12.9872 9.75H16.463C16.1736 12.6642 14.2162 15.0877 11.559 16.0521ZM6.44097 16.0521C6.28139 15.7596 6.13584 15.443 6.0041 15.1085C5.44133 13.68 5.08326 11.8058 5.0128 9.75H1.53703C1.82637 12.6642 3.78379 15.0877 6.44097 16.0521Z'
        fill='currentColor'
      />
    </svg>
  );
}
