import React from 'react';

export function ExportIcon() {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18.5C8.58579 18.5 8.25 18.1642 8.25 17.75L8.25 5.98582L5.26402 8.79615C4.96239 9.08004 4.48774 9.06565 4.20385 8.76402C3.91996 8.46239 3.93435 7.98774 4.23598 7.70385L8.48598 3.70385C8.77476 3.43205 9.22523 3.43205 9.51402 3.70385L13.764 7.70385C14.0657 7.98774 14.08 8.46239 13.7962 8.76402C13.5123 9.06565 13.0376 9.08004 12.736 8.79615L9.75 5.98582L9.75 17.75C9.75 18.1642 9.41421 18.5 9 18.5ZM18 1.25C18 1.66421 17.6642 2 17.25 2L0.75 2C0.335787 2 0 1.66421 0 1.25C0 0.835787 0.335787 0.5 0.75 0.5L17.25 0.5C17.6642 0.5 18 0.835787 18 1.25Z'
        fill='#6C6E7C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18.5C8.58579 18.5 8.25 18.1642 8.25 17.75L8.25 5.98582L5.26402 8.79615C4.96239 9.08004 4.48774 9.06565 4.20385 8.76402C3.91996 8.46239 3.93435 7.98774 4.23598 7.70385L8.48598 3.70385C8.77476 3.43205 9.22523 3.43205 9.51402 3.70385L13.764 7.70385C14.0657 7.98774 14.08 8.46239 13.7962 8.76402C13.5123 9.06565 13.0376 9.08004 12.736 8.79615L9.75 5.98582L9.75 17.75C9.75 18.1642 9.41421 18.5 9 18.5ZM18 1.25C18 1.66421 17.6642 2 17.25 2L0.75 2C0.335787 2 0 1.66421 0 1.25C0 0.835787 0.335787 0.5 0.75 0.5L17.25 0.5C17.6642 0.5 18 0.835787 18 1.25Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18.5C8.58579 18.5 8.25 18.1642 8.25 17.75L8.25 5.98582L5.26402 8.79615C4.96239 9.08004 4.48774 9.06565 4.20385 8.76402C3.91996 8.46239 3.93435 7.98774 4.23598 7.70385L8.48598 3.70385C8.77476 3.43205 9.22523 3.43205 9.51402 3.70385L13.764 7.70385C14.0657 7.98774 14.08 8.46239 13.7962 8.76402C13.5123 9.06565 13.0376 9.08004 12.736 8.79615L9.75 5.98582L9.75 17.75C9.75 18.1642 9.41421 18.5 9 18.5ZM18 1.25C18 1.66421 17.6642 2 17.25 2L0.75 2C0.335787 2 0 1.66421 0 1.25C0 0.835787 0.335787 0.5 0.75 0.5L17.25 0.5C17.6642 0.5 18 0.835787 18 1.25Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18.5C8.58579 18.5 8.25 18.1642 8.25 17.75L8.25 5.98582L5.26402 8.79615C4.96239 9.08004 4.48774 9.06565 4.20385 8.76402C3.91996 8.46239 3.93435 7.98774 4.23598 7.70385L8.48598 3.70385C8.77476 3.43205 9.22523 3.43205 9.51402 3.70385L13.764 7.70385C14.0657 7.98774 14.08 8.46239 13.7962 8.76402C13.5123 9.06565 13.0376 9.08004 12.736 8.79615L9.75 5.98582L9.75 17.75C9.75 18.1642 9.41421 18.5 9 18.5ZM18 1.25C18 1.66421 17.6642 2 17.25 2L0.75 2C0.335787 2 0 1.66421 0 1.25C0 0.835787 0.335787 0.5 0.75 0.5L17.25 0.5C17.6642 0.5 18 0.835787 18 1.25Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18.5C8.58579 18.5 8.25 18.1642 8.25 17.75L8.25 5.98582L5.26402 8.79615C4.96239 9.08004 4.48774 9.06565 4.20385 8.76402C3.91996 8.46239 3.93435 7.98774 4.23598 7.70385L8.48598 3.70385C8.77476 3.43205 9.22523 3.43205 9.51402 3.70385L13.764 7.70385C14.0657 7.98774 14.08 8.46239 13.7962 8.76402C13.5123 9.06565 13.0376 9.08004 12.736 8.79615L9.75 5.98582L9.75 17.75C9.75 18.1642 9.41421 18.5 9 18.5ZM18 1.25C18 1.66421 17.6642 2 17.25 2L0.75 2C0.335787 2 0 1.66421 0 1.25C0 0.835787 0.335787 0.5 0.75 0.5L17.25 0.5C17.6642 0.5 18 0.835787 18 1.25Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18.5C8.58579 18.5 8.25 18.1642 8.25 17.75L8.25 5.98582L5.26402 8.79615C4.96239 9.08004 4.48774 9.06565 4.20385 8.76402C3.91996 8.46239 3.93435 7.98774 4.23598 7.70385L8.48598 3.70385C8.77476 3.43205 9.22523 3.43205 9.51402 3.70385L13.764 7.70385C14.0657 7.98774 14.08 8.46239 13.7962 8.76402C13.5123 9.06565 13.0376 9.08004 12.736 8.79615L9.75 5.98582L9.75 17.75C9.75 18.1642 9.41421 18.5 9 18.5ZM18 1.25C18 1.66421 17.6642 2 17.25 2L0.75 2C0.335787 2 0 1.66421 0 1.25C0 0.835787 0.335787 0.5 0.75 0.5L17.25 0.5C17.6642 0.5 18 0.835787 18 1.25Z'
        fill='black'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 18.5C8.58579 18.5 8.25 18.1642 8.25 17.75L8.25 5.98582L5.26402 8.79615C4.96239 9.08004 4.48774 9.06565 4.20385 8.76402C3.91996 8.46239 3.93435 7.98774 4.23598 7.70385L8.48598 3.70385C8.77476 3.43205 9.22523 3.43205 9.51402 3.70385L13.764 7.70385C14.0657 7.98774 14.08 8.46239 13.7962 8.76402C13.5123 9.06565 13.0376 9.08004 12.736 8.79615L9.75 5.98582L9.75 17.75C9.75 18.1642 9.41421 18.5 9 18.5ZM18 1.25C18 1.66421 17.6642 2 17.25 2L0.75 2C0.335787 2 0 1.66421 0 1.25C0 0.835787 0.335787 0.5 0.75 0.5L17.25 0.5C17.6642 0.5 18 0.835787 18 1.25Z'
        fill='black'
        fillOpacity='0.2'
      />
    </svg>
  );
}
