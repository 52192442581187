import styled from 'styled-components';

import { FlexWithAlign } from '../../../../utils/styleUtils';

export const ButtonWrapper = styled(FlexWithAlign)`
  gap: 12px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

export const Btn = styled.button`
  position: relative;
  display: flex;
  width: 52px;
  height: 32px;
  border: 1px solid #0000001a;
  border-radius: 16px;
  flex-shrink: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0px 2px 4px 1px #7d7d7d1a inset;
  .circle {
    position: absolute;
    top: 1px;
    left: 1px;
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #0000001a;
    background-color: ${(props) => props.theme.color.base01};
    transition: all 0.3s ease;
    box-shadow: 0px 4px 7px 0px #0000001a;
  }
  &.active {
    background-color: ${(props) => props.theme.color.toggleBtnBg};

    .circle {
      transform: translateX(20px);
    }
  }
`;
