export const PaymentIcon10 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24644)'>
        <path
          d='M4.25586 29.1504V17.1504'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.75586 29.1504H10.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.25586 35.1504H35.7559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.25586 32.1504H35.7559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.75586 17.1504H10.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.7559 29.1504H22.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.7559 17.1504H22.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.75586 29.1504V17.1504'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.2559 29.1504V17.1504'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.7559 29.1504V17.1504'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.7559 29.1504H34.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.7559 17.1504H34.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.2559 29.1504V17.1504'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M32.7559 29.1504V17.1504'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.97474 12.8068C2.85117 12.9027 2.76068 13.0348 2.71593 13.1847C2.67117 13.3345 2.6744 13.4946 2.72515 13.6426C2.7759 13.7905 2.87163 13.9189 2.99896 14.0098C3.12628 14.1006 3.27883 14.1494 3.43524 14.1493H33.5852C33.7415 14.1496 33.894 14.1011 34.0214 14.0106C34.1488 13.9201 34.2448 13.792 34.2958 13.6443C34.3469 13.4965 34.3505 13.3366 34.3062 13.1867C34.2619 13.0368 34.1719 12.9045 34.0487 12.8083L19.0142 1.01075C18.8823 0.907602 18.7197 0.851563 18.5522 0.851562C18.3848 0.851563 18.2222 0.907602 18.0902 1.01075L2.97474 12.8068Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24644'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
