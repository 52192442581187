import { FC } from 'react';
import { EIcon, IconNew } from '../../../../components/icons/medium-new-icons/icon';
import { cn } from '../../../lib';
import { INewCustomCheckBox } from './NewCustomCheckbox.props';
import { CheckBox, CheckboxWrapper, CustomCheckbox } from './NewCustomCheckbox.styled';

const NewCustomCheckbox: FC<INewCustomCheckBox> = (props) => {
  const { name, onChange, value, className, disabled, error, ...rest } = props;
  return (
    <CheckboxWrapper className={cn(className && `${className}`, disabled && 'disabled', error && 'error', value && 'active')}>
      <CheckBox
        type={'checkbox'}
        name={name}
        checked={value}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
      <CustomCheckbox>
        <IconNew name={EIcon.checkbox} />
      </CustomCheckbox>
    </CheckboxWrapper>
  );
};

export default NewCustomCheckbox;
