export function ReplayOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.7258 4.04221C13.0833 3.6973 13.0924 3.12907 12.7461 2.77304C12.3997 2.417 11.8292 2.40799 11.4716 2.7529L8.77417 5.35535C8.59893 5.52442 8.50001 5.75703 8.50001 6.00001C8.50001 6.243 8.59893 6.4756 8.77417 6.64467L11.4717 9.2471C11.8292 9.59201 12.3997 9.58299 12.7461 9.22696C13.0924 8.87092 13.0833 8.30269 12.7258 7.95778L11.5432 6.81685C12.6004 6.73875 13.6638 6.93285 14.63 7.38548C15.7856 7.92678 16.746 8.81145 17.3801 9.91877C18.0143 11.0261 18.2914 12.3021 18.1735 13.5727C18.0556 14.8433 17.5486 16.0466 16.7215 17.0184C15.8945 17.9901 14.7878 18.683 13.5524 19.0025C12.3169 19.322 11.013 19.2525 9.81858 18.8036C8.62412 18.3546 7.59728 17.548 6.87819 16.4939C6.1591 15.4397 5.78279 14.1894 5.8006 12.9134C5.80754 12.4164 5.41027 12.0079 4.91326 12.001C4.41625 11.994 4.00772 12.3913 4.00078 12.8883C3.97779 14.5347 4.46336 16.148 5.39121 17.5082C6.31907 18.8684 7.64402 19.9092 9.18527 20.4885C10.7265 21.0678 12.409 21.1574 14.003 20.7452C15.5971 20.3329 17.0252 19.4389 18.0923 18.185C19.1594 16.9311 19.8137 15.3785 19.9658 13.739C20.1179 12.0995 19.7604 10.453 18.9421 9.02421C18.1238 7.59542 16.8846 6.45391 15.3936 5.75546C14.2436 5.21675 12.9869 4.96184 11.7283 5.00461L12.7258 4.04221Z'
        fill='currentColor'
      />
    </svg>
  );
}
