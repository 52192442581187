export interface Theme {
  color: any;
  text: any;
  status: any;
}

const themeDark: Theme = {
  //BASE PALETTE
  color: {
    //Page primary background and icon fill for color inverted background
    base01: '#121212',
    // Page secondary background, tile on primary background and background of static marker icons
    base02: '#202020',

    // Hovered backgrounds for tiles
    base03: '#232325',

    // Table borders, islands and blocks
    base04: '#2C2C2E',

    // Table borders, islands and blocks
    base05: '#5C636B',

    // Hovered color for interactive interface icons with --tui-base-05
    base06: '#C7C9CC',

    // For blocks with inverted background color
    base07: '#DDDFE0',

    // Alternative interface icons color
    base08: '#F6F7F8',

    // Icons color over inverted background
    base09: '#FFFFFF',

    // Background of buttons, marker icons
    primary: '#526ED3',

    // Hovered state background of buttons, marker icons
    primaryHover: '#6C86E2',

    // Active state background of buttons
    primaryActive: '#314692',

    // Background of input field and secondary buttons
    secondary: 'rgba(255, 255, 255, .16)',

    // Hovered state background of input field and secondary buttons
    secondaryHover: '#6488B452',

    // Active state background of input field and secondary buttons
    secondaryActive: '#6488B466',

    // Background of accent icons or buttons
    accent: '#FF7043',

    // Hovered state background of accent icons or buttons
    accentHover: '#FF9A94',

    // Active state background of accent icons or buttons
    accentActive: '#E7716A',

    // Background of accent icons or buttons
    clear: '#FFFFFF29',

    // Hovered state background of accent icons or buttons
    clearHover: '#FFFFFF3D',

    // Active state background of accent icons or buttons
    clearActive: '#FFFFFF66',

    inverse: 'rgba(255, 255, 255, .16)',

    inverseHover: 'rgba(255, 255, 255, .24)',

    inverseActive: 'rgba(255, 255, 255, .4)',

    elevation01: '#202020',

    thinBlue: '#EDF5FF',
    secondaryBlue: '#EFF3FF',
    hoverBlue: '#6C86E2',
    darkBlue: '#314692',
    lightBg: '#f0f0f0',
    text: '#fff',
    mainLight: '#5089FD',
    secondLight: '#246BFD',
    mainDark: '#292F51',
    mainDarkFull: '#333333',
    dark: '#141414',
    black: '#000000',
    black4: '#0000000A',
    sidebarGray: '#1B1F3B',
    sidebarGray65: '#1B1F3BA6',
    sidebarActive: '#191c39',
    sidebarMedium: '#191C30',
    sidebarMedium90: '#191C30E5',
    success: '#4AC99B',
    warning: '#EFDC31',
    yellow: '#E4AF09',
    orange: '#FF7F41',
    danger: '#FF453A',
    secondaryLight: '#F9F9F9',
    secondaryMedium: '#EAEBEE',
    secondaryLightMedium: '#E5E9EB',
    secondaryUltraLight: '#79818C1F',
    secondaryDark: '#9497A8',
    secondaryDark66: '#666666',
    secondaryFullDark: '#59616B',
    secondaryGrey: '#6C6E7C',
    secondaryMediumLight: '#F5F6F8',
    secondaryMediumDark: '#84879D',
    fieldGray: '#ECF1F7',
    disabled: '#717681',
    active: '#0062FF',
    greyD9: '#D9D9D9',
    grayE0: '#E0E0E0'
  },
  //TEXT
  text: {
    //Primary text and hedings
    text01: '#ffffff',

    //Secondary text and descriptions
    text02: 'rgba(255, 255, 255, .72)',

    //Inactive and additional points, minor information and helpers
    text03: 'rgba(255, 255, 255, .6)',

    //Link and secondary buttons text
    link: '#6788ff',

    //Hovered state of link and secondary buttons text
    linkHover: '#6C86E2',

    //Positive values: income, stock price grow, etc.
    positive: '#3AA981',

    //Hovered state of positive values: income, stock price grow, etc.
    positiveHover: '#7AC5AA',

    //Negative values: expense, stock price fall, etc.
    negative: '#DD4C1E',

    //Hovered state of negative values: expense, stock price fall, etc.
    negativeHover: '#E38163'
  },
  //STATUS
  status: {
    //Icons or other elements with success status
    successFill: '#4AC99B',

    //Hovered state of background for elements with success status
    successBgHover: '#4AC99B66',

    //Background for elements with success status
    successBg: '#4AC99B52',

    //Icons or other elements with error status
    errorFill: '#FF8C67',

    //Hovered state of background for elements with error status
    errorBgHover: '#F4572566',

    //Background for elements with error status
    errorBg: '#F4572552',

    //Icons or other elements with info status
    infoFill: '#70B6F6',

    //Hovered status of background icons or other elements with info status
    infoBgHover: '#70B6F666',

    //Icons or other elements with warning status
    infoBg: '#70B6F652',

    //Icons or other elements with warning status
    warningFill: '#FFC700',

    //Hovered status of background icons or other elements with warning status
    warningBgHover: '#FFC70066',

    //Icons or other elements with neutral status
    warningBg: '#FFC70052',

    //Icons or other elements with neutral status
    neutralFull: '#959BA4',

    //Hovered status of background icons or other elements with neutral status
    neutralBgHover: '#959BA466',

    //Background icons or other elements with neutral status
    neutralBg: '#959BA452'
  }
};

export default themeDark;
