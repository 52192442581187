import React from 'react';

export function MinusFill() {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='18'
        height='18'
        rx='9'
        fill='#EFDC31'
      />
      <path
        d='M15.075 8.32426L9.675 8.32422H8.325L2.92496 8.32426C2.55219 8.32426 2.25 8.62645 2.25 8.99922C2.25 9.37199 2.55219 9.67418 2.92496 9.67418L8.325 9.67347H9.675L15.075 9.67418C15.4478 9.67418 15.75 9.37199 15.75 8.99922C15.75 8.62645 15.4478 8.32426 15.075 8.32426Z'
        fill='white'
      />
    </svg>
  );
}
