import { OverlayWrapper } from './Overlay.styled';
import { ModalType } from '../Modal/ui/Modal.props';

interface OverlayProps {
  className?: string;
  onClick?: () => void;
  type?: ModalType;
}

export const Overlay = (props: OverlayProps) => {
  const { className, type, onClick } = props;

  return (
    <OverlayWrapper
      className={className}
      $justify={'center'}
      $type={type}
      onClick={onClick}
    />
  );
};
