import React from 'react';

export function TapBarChatGray() {
  return (
    <svg
      width='29'
      height='28'
      viewBox='0 0 29 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.334 17.5C23.334 23.299 18.633 28 12.834 28C11.2124 28 9.67663 27.6324 8.30554 26.976C7.83429 26.7504 7.30039 26.6712 6.79568 26.8062L5.18651 27.2368C3.91818 27.5761 2.75786 26.4158 3.09722 25.1475L3.52777 23.5383C3.66282 23.0336 3.5836 22.4997 3.358 22.0284C2.70159 20.6573 2.33398 19.1216 2.33398 17.5C2.33398 11.701 7.03499 7 12.834 7C18.633 7 23.334 11.701 23.334 17.5ZM8.24023 18.8125C8.9651 18.8125 9.55273 18.2249 9.55273 17.5C9.55273 16.7751 8.9651 16.1875 8.24023 16.1875C7.51537 16.1875 6.92773 16.7751 6.92773 17.5C6.92773 18.2249 7.51537 18.8125 8.24023 18.8125ZM12.834 18.8125C13.5589 18.8125 14.1465 18.2249 14.1465 17.5C14.1465 16.7751 13.5589 16.1875 12.834 16.1875C12.1091 16.1875 11.5215 16.7751 11.5215 17.5C11.5215 18.2249 12.1091 18.8125 12.834 18.8125ZM17.4277 18.8125C18.1526 18.8125 18.7402 18.2249 18.7402 17.5C18.7402 16.7751 18.1526 16.1875 17.4277 16.1875C16.7028 16.1875 16.1152 16.7751 16.1152 17.5C16.1152 18.2249 16.7028 18.8125 17.4277 18.8125Z'
        fill='#999999'
      />
      <path
        opacity='0.6'
        d='M22.819 19.1718C22.97 19.1097 23.119 19.0436 23.2657 18.9736C23.6738 18.7791 24.1362 18.7108 24.5733 18.8273L25.967 19.1984C27.0654 19.4911 28.0704 18.4906 27.7766 17.3971L27.4036 16.0096C27.2866 15.5745 27.3553 15.114 27.5506 14.7077C28.1192 13.5255 28.4375 12.2014 28.4375 10.8033C28.4375 5.80329 24.3661 1.75 19.3436 1.75C15.5522 1.75 12.3026 4.05998 10.9375 7.34339C11.1727 7.3287 11.4099 7.32124 11.6488 7.32124C17.8302 7.32124 22.8413 12.3099 22.8413 18.4637C22.8413 18.7016 22.8338 18.9377 22.819 19.1718Z'
        fill='#999999'
      />
    </svg>
  );
}
