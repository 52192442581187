import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { ITabs } from './Tabs.props';
import { TabLink, TabsWrapper, TabWrapper } from './Tabs.styled';

import { cn } from '../../../lib';

export const Tabs: FC<ITabs> = ({ tab, background, className }) => {
  const { pathname } = useLocation();

  return (
    <TabsWrapper
      style={{ backgroundColor: background ? background : 'transparent' }}
      className={cn(className && `${className}`)}
    >
      {tab.map((item) => (
        <TabWrapper key={item.title}>
          <TabLink
            className={cn(pathname === item.to ? 'active' : '')}
            to={item.to}
          >
            {item.title}
          </TabLink>
        </TabWrapper>
      ))}
    </TabsWrapper>
  );
};
