import { FC, useState } from 'react';

import { INewCheckButton } from './NewCheckButton.props';
import { CheckButton, CheckButtonChecked, CheckButtonLetter } from './NewCheckButton.styled';

import { EIcon, IconNew } from '../../../../components/icons/medium-new-icons/icon';
import { firstTwoLetters } from '../../../../pages/MessengerPage/lib/messenger.helper';
import { cn } from '../../../lib';

const NewCheckButton: FC<INewCheckButton> = (props) => {
  const { className, name, checked, onCheck } = props;
  const [chosen, setChosen] = useState<boolean>(false);

  return (
    <CheckButton
      onClick={onCheck}
      className={cn(!name && 'empty-name', className && `${className}`)}
    >
      <CheckButtonLetter className={cn(!name && 'empty-name')}>
        {name ? firstTwoLetters(name) : <IconNew name={EIcon.employeesicon} />}
      </CheckButtonLetter>
      {checked && (
        <CheckButtonChecked>
          <IconNew name={EIcon.chosentimecell} />
        </CheckButtonChecked>
      )}
    </CheckButton>
  );
};

export default NewCheckButton;
