import React, { FC, memo, ChangeEvent, useEffect, useState, useRef, useCallback } from 'react';
import { CardItem, ChilrdenSvg, InputCardGroupWrapper, InputCardWrapper, TitleItem } from './InputCardGroup.styled';
import { cn } from '../../../lib';
import { IInputCardGroupProps } from './InputCardGroup.props';
import CurrencyInput, { CurrencyInputOnChangeValues } from 'react-currency-input-field';

export const InputCardGroup: FC<IInputCardGroupProps> = memo(
  ({ labels, values, onChange, size, inputTypes = [], inputFormat = [], children, titles, disabledFields = [] }: IInputCardGroupProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleOutsideClick = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
          setIsFocused(false);
        }
      };

      document.addEventListener('click', handleOutsideClick);

      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);

    const handleLabelClick = useCallback(() => {
      setIsFocused(true);
    }, []);

    const handleInputChange = useCallback(
      (label: string, inputValue: string) => {
        onChange(label, inputValue);
      },
      [onChange, labels]
    );

    const handleInputChange2 = (
      label: string,
      value: string | undefined,
      name: string | undefined,
      values: CurrencyInputOnChangeValues | undefined
    ) => {
      if (value !== undefined && name !== undefined) {
        onChange(label, values?.float || '');
      }
    };

    return (
      <InputCardGroupWrapper
        size={size}
        ref={wrapperRef}
        className={cn(isFocused && 'focused')}
        onClick={handleLabelClick}
      >
        <CardItem>
          {titles.map((item, index) => (
            <TitleItem
              size={size}
              key={index}
            >
              {item}
            </TitleItem>
          ))}
        </CardItem>
        <CardItem>
          {labels.map((label, index) => (
            <InputCardWrapper key={index}>
              {inputTypes[index] === 'currency' ? (
                <CurrencyInput
                  placeholder='Введите сумму'
                  suffix={' ₽'}
                  allowNegativeValue={false}
                  type={'text'}
                  onValueChange={(value, name, values) => handleInputChange2(label, value, name, values)}
                  name={label}
                  disabled={disabledFields.includes(label)}
                  value={values[label] || ''}
                />
              ) : (
                <input
                  disabled={disabledFields.includes(label)}
                  type='text'
                  name={label}
                  placeholder={label}
                  tabIndex={0}
                  value={values[label] || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(label, e.target.value)}
                />
              )}
            </InputCardWrapper>
          ))}
        </CardItem>
        <ChilrdenSvg>{children}</ChilrdenSvg>
      </InputCardGroupWrapper>
    );
  }
);

InputCardGroup.displayName = 'InputCardGroup';
