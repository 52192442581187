export const PaymentIcon3 = () => {
  return (
    <svg
      width='37'
      height='36'
      viewBox='0 0 37 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6189_24544)'>
        <path
          d='M8.75 23.3142L4.25 24.8142L5.75 20.3142L21.2 4.87473C21.4813 4.59352 21.8628 4.43555 22.2605 4.43555C22.6582 4.43555 23.0397 4.59352 23.321 4.87473L24.2 5.75373C24.4812 6.03502 24.6392 6.41648 24.6392 6.81423C24.6392 7.21197 24.4812 7.59343 24.2 7.87473L8.75 23.3142Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.75586 20.3145L8.75586 23.3145'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.2559 25.5645H31.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.2559 21.0645H31.2559'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.5 12.0645H34.25C34.6478 12.0645 35.0294 12.2225 35.3107 12.5038C35.592 12.7851 35.75 13.1666 35.75 13.5645V30.0645C35.75 30.4623 35.592 30.8438 35.3107 31.1251C35.0294 31.4064 34.6478 31.5645 34.25 31.5645H2.75C2.35218 31.5645 1.97064 31.4064 1.68934 31.1251C1.40804 30.8438 1.25 30.4623 1.25 30.0645V13.5645C1.25 13.1666 1.40804 12.7851 1.68934 12.5038C1.97064 12.2225 2.35218 12.0645 2.75 12.0645H9.5'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.0059 9.06445L20.0059 12.0645'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.25 9.06423L16.7 5.62473C16.9813 5.34352 17.3628 5.18555 17.7605 5.18555C18.1582 5.18555 18.5397 5.34352 18.821 5.62473L19.6355 6.43923'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_6189_24544'>
          <rect
            width='36'
            height='36'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
